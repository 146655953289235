import Typography from "@material-ui/core/Typography";
import React from 'react';
import PropTypes from 'prop-types';

const HowToInsertAssetIntoContainer = props => {
  const { classes } = props;

  return (
    <>
      <Typography>
        Insert any asset of your choice into a container, be it images or text. Once inserted,
        the container and its assets function as a unified block of content. Simply drag your
        preferred asset over the highlighted container and drop it to attach.
      </Typography>
    </>
  );
};

HowToInsertAssetIntoContainer.propTypes = {
  classes: PropTypes.object
};

export default HowToInsertAssetIntoContainer;