import {
  Dialog, DialogContent,
  DialogTitle,
  FormControlLabel,
  IconButton,
  Radio,
  RadioGroup,
  TextField, Tooltip,
  withStyles
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import SearchIcon from "@material-ui/icons/Search";
import {IconContext} from "editor/context/IconContext";
import IconDraggableDialogStyle from "editor/components/dialogs/IconDraggableDialog/IconDraggableDialogStyle";
import {EditorContext} from "editor/Editor";
import PropTypes from "prop-types";
import React, {useCallback, useContext, useEffect, useMemo, useState} from 'react';
import { FixedSizeList } from 'react-window';
import AutoSizer from 'react-virtualized-auto-sizer';
import {chunkArray} from "utils/utils";

const IconDraggableDialog = props => {
  const {
    classes,
    open,
    setOpen,
    data,
  } = props;

  const ICONS_PER_ROW = 14;

  const iconData = useContext(IconContext);
  const {findIconByDisplayName} = iconData;
  const editorData = useContext(EditorContext);
  const { dispatch, editorReducerState } = editorData;

  const renderIconRow = useCallback((row) => {
    return row.map((Icon, index) => (
      <Icon
        key={Icon.displayName}
        className={classes.iconStyle}
        fontSize={'large'}
        data-displayName={Icon.displayName}
        onClick={() => selectIcon(Icon.displayName)}
      />
    ))
  }, [iconData])

  const renderedFilledIconList =
   useMemo(() => chunkArray(iconData.filledIconList, ICONS_PER_ROW).map((Icon) =>
     renderIconRow(Icon)), [iconData])

  const renderedSharpIconList =
   useMemo(() => chunkArray(iconData.sharpIconList, ICONS_PER_ROW).map((Icon) =>
     renderIconRow(Icon)), [iconData])

  const renderedOutlinedIconList =
    useMemo(() => chunkArray(iconData.outlinedIconList, ICONS_PER_ROW).map((Icon) =>
      renderIconRow(Icon)), [iconData])

  const renderedRoundedIconList =
    useMemo(() => chunkArray(iconData.roundedIconList, ICONS_PER_ROW).map((Icon) =>
      renderIconRow(Icon)), [iconData])

  const renderedTwoToneIconList =
    useMemo(() => chunkArray(iconData.twoToneIconList, ICONS_PER_ROW).map((Icon) =>
      renderIconRow(Icon)), [iconData])


  const findElementInTree = editorReducerState.editorActions.findElementInTree


  const [completedSelection, setCompletedSelection] = useState(false)
  const [value, setValue] = useState("filled")
  const [searchQuery, setSearchQuery] = useState('')
  const [searchedIcons, setSearchedIcons] = useState([])
  const [selectedIcon, setSelectedIcon] = useState(null)
  const [selectedIconList, setSelectedIconList] = useState(renderedFilledIconList)
  const [chunkedRawIconList, setChunkedRawIconList] = useState(
    chunkArray(iconData.filledIconList, ICONS_PER_ROW)
  )
  const [selectedRawIconList, setSelectedRawIconList] = useState(iconData.filledIconList)

  useEffect(() => {
    searchIcons(searchQuery)
  }, [selectedIconList])

  useEffect(() => {
    let treeElement = null

    try{
      if(selectedIcon && findElementInTree && !completedSelection) {
        treeElement = findElementInTree(data.editorId)
        treeElement.props.selectedIcon = selectedIcon

        dispatch({
          type: 'replaceAsset',
          payload: {
            originalElemId: data.editorId,
            newTreeElem: treeElement
          }
        })
        setCompletedSelection(true)
        setSelectedIcon(null)
        handleClose()
      }
    }
    catch (error) {
      console.error(error)
    }
  }, [editorReducerState, findElementInTree, selectedIcon])

  useEffect(() => {
    setCompletedSelection(false)
  }, [data])

  useEffect(() => {
    setSelectedIconList(getSelectedIconList())
    setSelectedRawIconList(getRawSelectedIconList());
    setChunkedRawIconList(chunkArray(getRawSelectedIconList(), ICONS_PER_ROW))
  }, [value, iconData])

  const handleClose = () => {
    setOpen(false)

    if(data?.editorId && !selectedIcon) {
      dispatch({
        type: 'deleteAsset',
        payload: {
          editorId: data.editorId
        }
      })
    }
  };

  const changeSelectedIconList = (e) => {
    setValue(e.target.value)
  };

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value)
    searchIcons(e.target.value)
  }

  const searchIcons = (query) => {
    // break up search query
    if(query.trim().length === 0) {
      setSearchedIcons([]);
      return;
    }

    let splitQuery = query.split(' ')
    let newSearchedIcons = getRawSelectedIconList().filter(icon => {
      for(let word of splitQuery) {
        if(icon.displayName.toLowerCase().includes(word.toLowerCase())) {
          return true
        }
      }
      return false
    })

    setSearchedIcons(chunkArray(newSearchedIcons, ICONS_PER_ROW))
  }

  const selectIcon = (iconDisplayName) => {
    let Icon = findIconByDisplayName(iconDisplayName)

    setSelectedIcon(<Icon/>)
    setOpen(false)
  }

  const getRawSelectedIconList = () => {
    if(value === 'filled') {
      return iconData.filledIconList
    } else if(value === 'sharp') {
      return iconData.sharpIconList
    } else if(value === 'outlined') {
      return iconData.outlinedIconList
    } else if(value === 'rounded') {
      return iconData.roundedIconList
    } else if(value === 'two tone') {
      return iconData.twoToneIconList
    }
  };

  const getSelectedIconList = () => {
    if(value === 'filled') {
      return renderedFilledIconList
    } else if(value === 'sharp') {
      return renderedSharpIconList
    } else if(value === 'outlined') {
      return renderedOutlinedIconList
    } else if(value === 'rounded') {
      return renderedRoundedIconList
    } else if(value === 'two tone') {
      return renderedTwoToneIconList
    }
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
    >
      <DialogTitle>
        Insert Icon

        <TextField
          variant={"outlined"}
          color={"primary"}
          placeholder={'Search Icons'}
          className={classes.searchBar}
          InputProps={{
            classes: {
              notchedOutline: classes.notchedOutline,
            },
            startAdornment: <SearchIcon fontSize={'small'} className={classes.searchIcon}/>
          }}
          value={searchQuery}
          onChange={handleSearchChange}
        />

        <IconButton
          onClick={handleClose}
          className={classes.closeIcon}
          size={'small'}
        >
          <CloseIcon/>
        </IconButton>

        <RadioGroup value={value} onChange={changeSelectedIconList} className={classes.iconType}>
          <FormControlLabel control={<Radio/>} label={"Filled"} value="filled" />
          <FormControlLabel control={<Radio/>} label={"Outlined"} value="outlined" />
          <FormControlLabel control={<Radio/>} label={"Rounded"} value="rounded"/>
          <FormControlLabel control={<Radio/>} label={"Two tone"} value="two tone"/>
          <FormControlLabel control={<Radio/>} label={"Sharp"} value="sharp"/>
        </RadioGroup>

      </DialogTitle>
      <DialogContent
        className={classes.dialogContent}
      >
        <div
          className={classes.iconListContainer}
        >
          <div
            className={classes.iconList}
          >
            <AutoSizer>
              {({height, width}) => (
                <FixedSizeList
                  height={height}
                  width={width}
                  className={classes.iconList}
                  itemCount={
                    searchedIcons.length > 0 ?
                      searchedIcons.length
                      :
                      chunkedRawIconList.length
                  }
                  itemSize={34}
                >
                  {({index, style}) => {
                    let chunk = searchedIcons.length > 0 ?
                      searchedIcons[index] : chunkedRawIconList[index];

                    return (
                      <div style={style}>
                        {renderIconRow(chunk)}
                      </div>
                    )
                  }}
                </FixedSizeList>
              )}
            </AutoSizer>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
};

IconDraggableDialog.whyDidYouRender = true;

IconDraggableDialog.propTypes = {
  classes: PropTypes.object,
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
  data: PropTypes.object,
};

export default React.memo(withStyles(IconDraggableDialogStyle)(IconDraggableDialog));
