import {Switch, withStyles} from '@material-ui/core';
import DraggableWrapper from "editor/draggables/DraggableWrapper/DraggableWrapper";
import formatStyles from "editor/draggables/formatStyles";
import PropTypes from 'prop-types';
import React, {useMemo} from 'react';
import SwitchDraggableStyle from './SwitchDraggableStyle';

const SwitchDraggable = props => {
  const {
    classes,
    style,
    editorId,
    draggableProps,
    isChildElem,
    absolutePositioning
  } = props;

  const {draggableStyles, elementStyles} =
    useMemo(() => formatStyles(style), [style]);

  return (
    <>
      <DraggableWrapper
        resizeable
        editorId={editorId}
        style={{
          ...draggableStyles,
          ...draggableProps?.style,
          position: absolutePositioning ? 'absolute' : 'static'
        }}
        isChildElem={isChildElem}
      >
        <Switch
          style={{
            ...elementStyles,
            position: 'static',
          }}
          className={classes.switch}
          checked
          data-editorid={editorId}
          data-asset={true}
        />
      </DraggableWrapper>
    </>
  );
};

SwitchDraggable.editorSettings = [
  {
    type: 'prop',
    key: 'breakpoints',
    tooltip: 'Make an asset visible at the chosen breakpoints: desktop, tablet, and/or mobile.',
    default: {
      visibility: {
        desktop: true,
        tablet: true,
        mobile: true
      },
      styleLink: {
        tablet: true,
        mobile: true,
      }
    },
    label: null,
    inputType: 'breakpoint'
  },

  {
    type: 'layout',
    key: 'twoCol',
    tooltip: 'Accepted units: px, %, em, rem, vw, vh',
    label: 'Position/Size',
    elements: [
      {type: 'style', key: 'left', default: '0', label: 'X'},
      {type: 'style', key: 'top', default: '0', label: 'Y'},
      {type: 'style', key: 'width', default: '300px', label: 'W'},
      {type: 'style', key: 'height', default: '300px', label: 'H'},
    ]
  },

  {
    type: 'prop',
    key: 'absolutePositioning',
    tooltip: 'If this is checked, your asset\'s CSS will be written with "position: absolute"',
    label: 'Absolute Positioning',
    default: false,
    inputType: 'boolean'
  },

  //{type: 'prop', key: 'classList', default: null, label: null, inputType: 'class'},

  {type: 'style', key: 'color', default: '#FFFFFF', label: 'Fill', inputType: 'color'},
  {type: 'style', key: 'border', default: 'none', label: null, inputType: 'border'},
  {type: 'style', key: 'opacity', default: '1', label: 'Opacity'},
  //{type: 'prop', key: 'test', default: '123', label: 'Test Prop'},

  {type: 'style', key: 'boxShadow', default: 'none', label: 'Box Shadow', inputType: 'boxShadow'},
  {type: 'style', key: 'transform', default: 'none', label: 'Rotation', inputType: 'rotation'},

  {
    type: 'layout',
    key: 'twoCol',
    elements: [
      {type: 'style', key: 'padding', default: '0px', label: 'Padding', horizontal: false},
      {type: 'style', key: 'margin', default: '0px', label: 'Margin', horizontal: false},
    ]
  },
];

SwitchDraggable.propTypes = {
  classes: PropTypes.object,
  style: PropTypes.object.isRequired,
  draggableProps: PropTypes.object,
  editorId: PropTypes.string.isRequired,
  isChildElem: PropTypes.object,
};
export default withStyles(SwitchDraggableStyle)(SwitchDraggable);
