import {EditorContext} from "editor/Editor";
import React, {useContext, useEffect, useState} from 'react';

import {
  FormControl, Tooltip, Typography,
  withStyles
} from '@material-ui/core';
import PropTypes from 'prop-types';

import StyledTextFieldStyle from './StyledTextFieldStyle';
import TextField from "@material-ui/core/TextField";
import cn from 'classnames';

const StyledTextField = props => {
  const {
    className,
    formControlClassName,
    classes,
    label,
    setting,
    fullWidth = true,
    horizontal = false,
    ...rest
  } = props;

  const editorData = useContext(EditorContext);
  const {editorReducerState, dispatch} = editorData;

  const [isDisabled, setIsDisabled] = useState(false);
  const [disabledReason, setDisabledReason] = useState(null);
  const absolutelyPositioned = editorReducerState?.editorState?.elementSelected?.data?.props?.absolutePositioning;

  // add a special case for x/y settings, since those
  // are always using this input and need to be disabled
  // when drag is disabled.

  // if we find we need to do this more, we'll need to come up with
  // a better solution
  useEffect(() => {
    if(setting && ['top', 'left'].includes(setting?.key) && setting?.type === 'style') {
      // this outer if statement checks if this is a x/y input,
      // now check if it should be disabled
      console.log('test', absolutelyPositioned)
      if(!absolutelyPositioned) {
        setIsDisabled(true);
        setDisabledReason('This input is disabled if "Enable Dragging" is also disabled.')
      }
      else {
        setIsDisabled(false);
      }
    }
  }, [setting, absolutelyPositioned])

  const renderedTextField = (
    <FormControl
      fullWidth={fullWidth}
      className={`${classes.formControl} ${formControlClassName} `}
    >
      <Typography className={classes.label}>
        {label}
      </Typography>

      <TextField
        className={className ? cn(classes.styledTextFieldRoot, className) : classes.styledTextFieldRoot}
        InputProps={{
          disableUnderline: true,
        }}
        inputProps={{
          className: classes.input
        }}
        disabled={isDisabled}
        {...rest}
        label={null}
      />

    </FormControl>
  )

  return (
    isDisabled && disabledReason ? (
      <Tooltip title={disabledReason}>
        <div>
          {renderedTextField}
        </div>
      </Tooltip>
    ) : renderedTextField
  );
};

StyledTextField.propTypes = {
  classes: PropTypes.object,
  className: PropTypes.string,
  formControlClassName: PropTypes.string,
  fullWidth: PropTypes.bool,
  horizontal: PropTypes.bool,
  label: PropTypes.string,
};

export default withStyles(StyledTextFieldStyle)(StyledTextField);
