import IconButton from "@material-ui/core/IconButton";
import {EditorContext} from "editor/Editor";
import {useEditorDialog} from "hooks/useEditorDialog";
import setData from "lodash-es/_setData";
import React, {useContext, useEffect, useRef, useState} from 'react';
import {
  Fab,
  withStyles,
  Tooltip,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import ActionBarStyle from './ActionBarStyle';
import DeleteIcon from '@material-ui/icons/Delete';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import HelpIcon from '@material-ui/icons/Help';

const ActionBar = props => {
  const {
    classes,
    editorId,
  } = props;

  const editorData = useContext(EditorContext);
  const { dispatch, editorReducerState } = editorData;
  const { editorSettings } = editorReducerState;
  const { zoomLevel } = editorSettings;
  const {
    copyFromTree,
    moveUpInOrder,
    moveDownInOrder,
    findElementInTree
  } = editorReducerState.editorActions;
  const confirmDialog = useEditorDialog('confirmation');
  const helpDialog = useEditorDialog('help');

  const selfRef = useRef();

  const buttonStyles = {
    width: `${26 * (1/zoomLevel)}px`,
    height: `${26 * (1/zoomLevel)}px`,
    borderRadius: `${17 * (1/zoomLevel)}px`,
    minWidth: `${25 * (1/zoomLevel)}px`,
    minHeight: `${25 * (1/zoomLevel)}px`
  }

  const actionBarPos = `${(40* (1/zoomLevel) * -1)}px`;

  // useEffect(() => {
  //   const selfTop = selfRef.current.getBoundingClientRect().top;
  //   const editorTop = editorReducerState.editorState.editorBounds.top;
  //
  //   if(selfTop <= editorTop) {
  //     selfRef.current.style.top = 'auto';
  //     selfRef.current.style.bottom = '-45px';
  //   }
  //   else {
  //     selfRef.current.style.top = '-45px';
  //     selfRef.current.style.bottom = 'auto';
  //   }
  //
  //   //setIsActionBarOutOfBounds(selfTop <= editorTop);
  // }, [editorReducerState.editorState.elementSelected.currentBounds]);

  const handleDeleteClick = () => {
    confirmDialog.openDialog();
    confirmDialog.appendDialogData({
      onSuccess: deleteFromTree,
      text: 'Are you sure you want to delete this asset?'
    });
  }

  const deleteFromTree = () => {
    dispatch({
      type: 'deleteAsset',
      payload: {
        editorId
      }
    })

    confirmDialog.closeDialog();
  }

  const handleMoveUpInOrder = (e) => {
    e.stopPropagation();
    moveUpInOrder(editorId)
  }

  const handleMoveDownInOrder = (e) => {
    e.stopPropagation();
    moveDownInOrder(editorId)
  }

  const handleHelpClick = () => {
    let elem = findElementInTree(editorId);
    console.log('help elem', elem)
    helpDialog.appendDialogData({...elem.tag.helpQuestionData});
    helpDialog.openDialog();
  }

  return (
    <div className={classes.root} ref={selfRef} style={{top: actionBarPos}}>
      <Tooltip
        title={'Move up in order'}
      >
        <Fab
          onClick={handleMoveUpInOrder}
          className={classes.iconButton}
          size={'small'}
          variant={'circular'}
          style={buttonStyles}
        >
          <ArrowUpwardIcon fontSize={'small'} style={{transform: `scale(${1/zoomLevel})`}}/>
        </Fab>
      </Tooltip>

      <Tooltip
        title={'Move down in order'}
      >
        <Fab
          onClick={handleMoveDownInOrder}
          className={classes.iconButton}
          size={'small'}
          variant={'circular'}
          style={buttonStyles}
        >
          <ArrowDownwardIcon fontSize={'small'} style={{transform: `scale(${1/zoomLevel})`}}/>
        </Fab>
      </Tooltip>

      <Tooltip
        title={'Copy'}
      >
        <Fab
          onClick={e => copyFromTree()}
          className={classes.iconButton}
          size={'small'}
          variant={'circular'}
          style={buttonStyles}
        >
          <FileCopyIcon fontSize={'small'} style={{transform: `scale(${1/zoomLevel})`}}/>
        </Fab>
      </Tooltip>

      <Tooltip
        title={'Delete'}
      >
        <Fab
          onClick={handleDeleteClick}
          className={classes.deleteIconButton}
          size={'small'}
          variant={'circular'}
          style={buttonStyles}
        >
          <DeleteIcon fontSize={'small'} style={{transform: `scale(${1/zoomLevel})`}}/>
        </Fab>
      </Tooltip>

      <Tooltip
        title={'Help'}
      >
        <Fab
          onClick={handleHelpClick}
          className={classes.iconButton}
          size={'small'}
          variant={'circular'}
          style={buttonStyles}
        >
          <HelpIcon fontSize={'small'} style={{transform: `scale(${1/zoomLevel})`}}/>
        </Fab>
      </Tooltip>

    </div>
  );
};

ActionBar.propTypes = {
  classes: PropTypes.object,
  editorId: PropTypes.string.isRequired,
};

export default withStyles(ActionBarStyle)(ActionBar);
