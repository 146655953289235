//import './wdyr';
import {createTheme, MuiThemeProvider} from '@material-ui/core';
import * as Sentry from "@sentry/react";
import {BrowserTracing} from "@sentry/tracing";
import Loading from "components/Common/Loading/Loading";
import ScrollToTop from "components/ScrollToTop/scrollToTop";
import GlobalDialogProvider from "context/GlobalDialogContext";
import StripeProvider from "context/StripeContext";
import UserProvider from "context/UserContext";
import {createBrowserHistory} from 'history';
import "index.css";
import MainPageLayout from "layout/MainPageLayout/MainPageLayout";
import React, {useEffect, Suspense, lazy} from 'react';
import 'dotenv/config'

// pages we don't want to lazy load
import TemplatePage from 'pages/TemplatePage/TemplatePage';

import ReactDOM from 'react-dom';
import {pageview as ReactGAPageView} from "react-ga";
import {BrowserRouter as Router, Route, Switch, useLocation} from 'react-router-dom';
import {ToastContainer} from "react-toastify";
import APPLICATION_SETTINGS from "settings";

import {MuiTheme} from './assets/theme';
import ApplicationSettingsContextProvider from "./context/ApplicationSettingsContext";

const hist = createBrowserHistory();
let theme = createTheme(MuiTheme);

const AccountPage = lazy(() => lazyRetry(() => import('pages/AccountPage/AccountPage')))
const BlogPage = lazy(() => lazyRetry(() => import('pages/BlogPage/BlogPage')))
const BlogPostPreviewPage = lazy(() => lazyRetry(() => import('pages/BlogPostPreviewPage/BlogPostPreviewPage')))
const ConfirmPasswordResetPage = lazy(() => lazyRetry(() => import('pages/ConfirmPasswordResetPage/ConfirmPasswordResetPage')))
const ContactPage = lazy(() => lazyRetry(() => import('pages/ContactPage/ContactPage')))
const DashboardPage = lazy(() => lazyRetry(() => import('pages/DashboardPage/DashboardPage')))
const ExpandedBlogPostPage = lazy(() => lazyRetry(() => import('pages/ExpandedBlogPostPage/ExpandedBlogPostPage')))
const HomePage = lazy(() => lazyRetry(() => import('pages/HomePage/HomePage')))
const LoginPage = lazy(() => lazyRetry(() => import('pages/LoginPage/LoginPage')))
const PaymentPage = lazy(() => lazyRetry(() => import('pages/PaymentPage/PaymentPage')))
const PricingPage = lazy(() => lazyRetry(() => import('pages/PricingPage/PricingPage')))
const PrivacyPolicyPage = lazy(() => lazyRetry(() => import('pages/PrivacyPolicyPage/PrivacyPolicyPage')))
const ProjectPage = lazy(() => lazyRetry(() => import('pages/ProjectVersionPage/ProjectVersionPage')))
const RegisterPage = lazy(() => lazyRetry(() => import('pages/RegisterPage/RegisterPage')))
const RequestPasswordResetPage = lazy(() => lazyRetry(() => import('pages/RequestPasswordResetPage/RequestPasswordResetPage')))
const SubmitBugPage = lazy(() => lazyRetry(() => import('pages/SubmitBugPage/SubmitBugPage')))
const SubmitFeedbackPage = lazy(() => lazyRetry(() => import('pages/SubmitFeedbackPage/SubmitFeedbackPage')))
const TempPage = lazy(() => lazyRetry(() => import('pages/TempPage/TempPage')))
//const TemplatePage = lazy(() => lazyRetry(() => import('pages/TemplatePage/TemplatePage')));
const TermsOfServicePage = lazy(() => lazyRetry(() => import('pages/TermsOfServicePage/TermsOfServicePage')))
const TestPage = lazy(() => lazyRetry(() => import('pages/TestPage/TestPage')))



//TODO: THIS DOESNT WORK
// console.log('initializing font awesome', fab)
// library.add(fab, faDiscord)

// a function to retry loading a chunk to avoid chunk load error for out of date code
const lazyRetry = function(componentImport) {
  return new Promise((resolve, reject) => {
    // check if the window has already been refreshed
    const hasRefreshed = JSON.parse(
      window.sessionStorage.getItem('retry-lazy-refreshed') || 'false'
    );
    // try to import the component
    componentImport().then((component) => {
      window.sessionStorage.setItem('retry-lazy-refreshed', 'false'); // success so reset the refresh
      resolve(component);
    }).catch((error) => {
      if (!hasRefreshed) { // not been refreshed yet
        window.sessionStorage.setItem('retry-lazy-refreshed', 'true'); // we are now going to refresh
        return window.location.reload(); // refresh the page
      }
      reject(error); // Default error behaviour as already tried refresh
    });
  });
};


if(!APPLICATION_SETTINGS.DEBUG) {
  console.log('initializing production mode...')

  Sentry.init({
    dsn: "https://1aacfd205a4f4756a30c3819712c402e@o1137830.ingest.sentry.io/6191061",
    integrations: [new BrowserTracing()],

    // We recommend adjusting this value in production, or using tracesSampler
    // for finer control
    tracesSampleRate: 0.2,
  });

  if(!window.console) window.console = {};
  var methods = ["log", "error", "debug", "warn", "info"];
  for(var i=0;i<methods.length;i++){
    console[methods[i]] = function(){};
  }
}

let App = props => {

  // useEffect(() => {
  //   console.log('page view', location.pathname + location.search);
  //   ReactGAPageView(location.pathname + location.search);
  // }, [location]);



  useEffect(() => {
    // initialization code

    // adds MouseEvent.path to solve a compatibility issue with Firefox/Safari
    // without this code the application will crash after dragging anything on those browsers

    // https://stackoverflow.com/questions/36845515/mouseevent-path-equivalent-in-firefox-safari
    if (!("path" in Event.prototype))
      Object.defineProperty(Event.prototype, "path", {
        get: function() {
          var path = [];
          var currentElem = this.target;
          while (currentElem) {
            path.push(currentElem);
            currentElem = currentElem.parentElement;
          }
          if (path.indexOf(window) === -1 && path.indexOf(document) === -1)
            path.push(document);
          if (path.indexOf(window) === -1)
            path.push(window);
          return path;
        }
      });

  }, [])

  useEffect(() => {
    const link = document.createElement("link")
    link.rel = 'stylesheet'
    link.href =
      "https://fonts.googleapis.com/css2?family=Andada+Pro:wght@400;500;600&family=Anton&family=Archivo:wght@300;400;500;600&family=Baskervville&family=BioRhyme:wght@300;400&family=Cormorant:wght@300;400;500;600&family=Encode+Sans:wght@300;400;500;600&family=Epilogue:wght@300;400;500;600&family=Hahmlet:wght@300;400;500;600&family=Inter:wght@300;400;500;600&family=JetBrains+Mono:wght@300;400;500;600&family=Lato:wght@300;400&family=Libre+Baskerville&family=Lora:wght@400;500;600&family=Manrope:wght@300;400;500;600&family=Montserrat:wght@300;400;500;600&family=Nunito:wght@300;400;500;600&family=Old+Standard+TT&family=Open+Sans:wght@300;400;500;600&family=Oswald:wght@300;400;500;600&family=Oxygen:wght@300;400&family=Playfair+Display:wght@400;500;600&family=Poppins:wght@300;400;500;600&family=Raleway:wght@300;400;500;600&family=Sora:wght@300;400;500;600&family=Source+Sans+Pro:wght@300;400;600&family=Spectral:wght@300;400;500;600&family=Work+Sans:wght@300;400;500;600&display=swap"
    document.head.appendChild(link)
  }, [])

  return (
    <MuiThemeProvider theme={theme}>
      <ApplicationSettingsContextProvider>
        <UserProvider>
          <StripeProvider>
            <GlobalDialogProvider>
              <Router>
                <Suspense fallback={<Loading background={'dark'} show={true}/>} >
                  <ScrollToTop/>
                  <Switch>
                    <Route exact path={'/temp'} component={TempPage} />
                    <Route exact path={'/editor'} component={TestPage}/>
                    <MainPageLayout>
                      <Switch>
                        <Route exact path={'/account'} component={AccountPage}/>
                        <Route exact path={'/blog'} component={BlogPage}/>
                        <Route exact path={'/blog/preview/:id'} component={BlogPostPreviewPage}/>
                        <Route exact path={'/password-reset'} component={ConfirmPasswordResetPage}/>
                        <Route exact path={'/contact'} component={ContactPage}/>
                        <Route exact path={'/dashboard'} component={DashboardPage}/>
                        <Route exact path={'/blog/:id'} component={ExpandedBlogPostPage}/>
                        <Route exact path={'/'} component={HomePage}/>
                        <Route exact path={'/login'} component={LoginPage}/>
                        <Route exact path={'/payment'} component={PaymentPage}/>
                        <Route exact path={'/pricing'} component={PricingPage}/>
                        <Route exact path={'/privacy-policy'} component={PrivacyPolicyPage}/>
                        <Route exact path={'/project'} component={ProjectPage}/>
                        <Route exact path={'/register'} component={RegisterPage}/>
                        <Route exact path={'/forgot-password'} component={RequestPasswordResetPage}/>
                        <Route exact path={'/submit-bug'} component={SubmitBugPage}/>
                        <Route exact path={'/submit-feedback'} component={SubmitFeedbackPage}/>
                        <Route exact path={'/templates'} component={TemplatePage}/>
                        <Route exact path={'/terms-of-service'} component={TermsOfServicePage}/>

                      </Switch>
                    </MainPageLayout>
                  </Switch>
                </Suspense>
              </Router>
            </GlobalDialogProvider>
          </StripeProvider>
        </UserProvider>
        <ToastContainer/>
      </ApplicationSettingsContextProvider>
    </MuiThemeProvider>
  )
}

App = Sentry.withProfiler(App);


ReactDOM.render(
   <App/>,
  document.getElementById('root')
);
