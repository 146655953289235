const iconWidth = 32
const iconMargin = 2

const IconDraggableDialogStyle = {
  iconListContainer: {
    color: "black",
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    height: "500px",
    width: "calc(100% - 8px)",
    justifyContent: "center",
    alignItems: "flex-start",
    overflowY: "auto",
    padding: "0 16px",
  },

  iconStyle: {
    padding: "5px",
    margin: "2px",
    width: iconWidth + 'px',
    color: 'white'
  },

  searchBar: {
    marginBottom: "10px",
    marginLeft: "50px",


    '& .MuiInputBase-input': {
      width: "310px",
      height: "5px",
    },
  },

  notchedOutline: {
    borderColor: "white !important",
  },

  searchIcon: {
    marginRight: "3px",
  },

  iconList: {
    width: (14 * (iconWidth + (2 * iconMargin))) + 5 + 'px',
    minHeight: "100%",

    '&::-webkit-scrollbar': {
      height: '100px',
      width: '2px',
      position: 'relative',
      marginRight: '2px',
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: 'white',
    },
    '&::-webkit-scrollbar-track': {
      backgroundColor: '#262626',
      opacity: '0.48'
    },
  },

  dialogContent: {
    padding: 0,
    paddingBottom: "16px",
    overflowY: "hidden",
    width: "100%",
  },

  iconType: {
    display: "flex",
    flexDirection: "row",
    marginBottom: "5px",
    justifyContent: "center",
    alignItems: "center",
  },

  closeIcon: {
    color: "white",
    position: "relative",
    left: "20px",
    bottom: "5px",

    '&:hover':{
      background: "#3a3a4a",
      transition: "0.3s",
    },
  },
};

export default IconDraggableDialogStyle;
