import Typography from "@material-ui/core/Typography";
import React from 'react';

const AltTextImage = () => {
  return (
    <>
      <Typography style={{marginBottom: '8px'}}>
        Alt text is a good way to give to help Google read your image and improve SEO. To add alt text to an image:
      </Typography>
      <Typography>
        1. Go to media settings panel.
      </Typography>
      <Typography>
        2. Locate setting that says "Alt Text'.
      </Typography>
      <Typography>
        3. Give image alt text by giving a short description of your image.
      </Typography>
    </>
  );
};

AltTextImage.propTypes = {

};

export default AltTextImage;