const MainPageLayoutStyle = theme => ({
  root: {
    minHeight: "100vh",
    background: "linear-gradient(to right, #0E0E0E, #17131F)",
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: 'flex-start',
  },

  contentRoot: {
    flex: 1,
    display: "flex",
    width: "100%",
    marginTop: "100px",
    marginBottom: "100px",

    [theme.breakpoints.down('sm')]: {
      marginTop: "50px",
      marginBottom: "50px",
    },
  },
});

export default MainPageLayoutStyle;