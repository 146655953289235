const APPLICATION_SETTINGS = {
  DEBUG: process.env.REACT_APP_DEBUG === 'true',

  // TODO: this is the test key, use a real key in production!
  //STRIPE_API_KEY: "pk_test_51Jv926F7w3GiuDHmSouz4Hvdtow7dy3pb2DNPrdq6gaZtxPrR6KBsenLZBsZDKmHQXMDzcgKrPtzlsV63F0d8gZ300DQQyUmiK",
  STRIPE_API_KEY: process.env.REACT_APP_STRIPE_PK,

  // USE STAGING URL NOT THE LIVE SERVER
  DJANGO_API_URL: process.env.REACT_APP_DJANGO_API_URL,
  WORDPRESS_API_URL: 'https://wordpress-1003785-3538279.cloudwaysapps.com/',

  //WORDPRESS_API_URL: process.env.REACT_APP_WORDPRESS_API_URL,
  //DJANGO_API_URL: "http://localhost:8010/api",

  //DJANGO_API_URL: 'https://nimbus-editor.herokuapp.com/api',

  CONSTANTS: {
    SUPPORTED_FONTS: [
      {name: 'Andada Pro', value: 'Andada Pro'},
      {name: 'Anton', value: 'Anton'},
      {name: 'Archivo', value: 'Archivo'},
      {name: 'Baskervville', value: 'Baskervville'},
      {name: 'BioRhyme', value: 'BioRhyme'},
      {name: 'Comic Sans', value: 'Comic Sans'},
      {name: 'Cormorant', value: 'Cormorant'},
      {name: 'Encode Sans', value: 'Encode Sans'},
      {name: 'Epilogue', value: 'Epilogue'},
      {name: 'Hahmlet', value: 'Hahmlet'},
      {name: 'Inter', value: 'Inter'},
      {name: 'JetBrains Mono', value: 'JetBrains Mono'},
      {name: 'Lato', value: 'Lato'},
      {name: 'Libre Baskerville', value: 'Libre Baskerville'},
      {name: 'Lora', value: 'Lora'},
      {name: 'Manrope', value: 'Manrope'},
      {name: 'Montserrat', value: 'Montserrat'},
      {name: 'Nunito', value: 'Nunito'},
      {name: 'Old Standard TT', value: 'Old Standard TT'},
      {name: 'Oswald', value: 'Oswald'},
      {name: 'Playfair Display', value: 'Playfair Display'},
      {name: 'Poppins', value: 'Poppins'},
      {name: 'Raleway', value: 'Raleway'},
      {name: 'Roboto', value: 'Roboto'},
      {name: 'Sora', value: 'Sora'},
      {name: 'Spectral', value: 'Spectral'},
      {name: 'Work Sans', value: 'Work Sans'},
      {name: 'Source Sans Pro', value: 'Source Sans Pro'},
      {name: 'Oxygen', value: 'Oxygen'},
      {name: 'Open Sans', value: 'Open Sans'},
    ],
    SUPPORTED_FONT_SIZES: [
      {name: '8px', value: '8px'},
      {name: '10px', value: '10px'},
      {name: '12px', value: '12px'},
      {name: '14px', value: '14px'},
      {name: '16px', value: '16px'},
      {name: '18px', value: '18px'},
      {name: '20px', value: '20px'},
      {name: '22px', value: '22px'},
      {name: '24px', value: '24px'},
      {name: '26px', value: '26px'},
      {name: '28px', value: '28px'},
      {name: '30px', value: '30px'},
      {name: '34px', value: '34px'},
      {name: '38px', value: '38px'},
      {name: '42px', value: '42px'},
      {name: '46px', value: '46px'},
      {name: '50px', value: '50px'}
    ],
    SUPPORTED_FONT_WEIGHTS: [
      {name: 'Light', value: '300'},
      {name: 'Regular', value: '400'},
      {name: 'Semi-Bold', value: '500'},
      {name: 'Bold', value: '600'}
    ]
  },
};

export default APPLICATION_SETTINGS;
