import {IconButton, withStyles} from '@material-ui/core';
import DesktopWindowsIcon from "@material-ui/icons/DesktopWindows";
import LinkIcon from '@material-ui/icons/Link';
import LinkOffIcon from '@material-ui/icons/LinkOff';
import SmartphoneIcon from "@material-ui/icons/Smartphone";
import TabletIcon from "@material-ui/icons/Tablet";
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import cn from "classnames";
import ElementSettingsGroupLabel from "components/ElementSettingGroupLabel/ElementSettingsGroupLabel";
import PropTypes from 'prop-types';
import React, {useEffect, useState} from 'react';
import CanvasBreakpointInputStyle from './CanvasBreakpointInputStyle';

const CanvasBreakpointInput = props => {
  const {
    classes,
    value,
    setting,
    onChange,
  } = props;

  const [visibility, setVisibility] = useState({
    desktop: true,
    tablet: true,
    mobile: true
  });

  const [styleLink, setStyleLink] = useState({
    tablet: true,
    mobile: true
  })

  useEffect(() => {
    setVisibility(value?.visibility)
    setStyleLink(value?.styleLink)
  }, [value])

  const handleVisibilityChange = (e, type) => {
    const newVisibility = {...visibility, [type]: !visibility[type]}
    setVisibility(newVisibility)
    onChange({
      visibility: {...newVisibility},
      styleLink: {...styleLink},
    })
  };

  const handleStyleLinkChange = (type) => {
    const newStyleLink = {...styleLink, [type]: !styleLink[type]}
    setStyleLink(newStyleLink)
    onChange({
      visibility: {...visibility},
      styleLink: {...newStyleLink},
    })
  }

  return (
    <div className={classes.root} id={'canvas-breakpoint'}>
      <ElementSettingsGroupLabel setting={setting}>
        Canvas Breakpoints
      </ElementSettingsGroupLabel>

      <div className={cn(classes.inputContainer, classes.breakpointContainer)}>
        <IconButton
          onClick={(e) => handleVisibilityChange(e,'desktop')}
          id={'joyride-visibility'}
          className={classes.iconButton}
        >
          <DesktopWindowsIcon className={cn(classes.icon, visibility?.desktop && classes.active)}/>

          {visibility?.desktop
            ?
            <VisibilityIcon className={cn(classes.visibilityIcon, classes.active)}/>
            :
            <VisibilityOffIcon className={cn(classes.visibilityIcon, classes.inactive)}/>
          }

        </IconButton>

        {/* disconnects shared styles between desktop and tablet */}
        <IconButton
          onClick={() => handleStyleLinkChange('tablet')}
          id={'joyride-style'}
          className={classes.iconButton}
        >
          {styleLink?.tablet ? <LinkIcon className={classes.icon}/> : <LinkOffIcon className={classes.icon}/>}
        </IconButton>

        <IconButton
          onClick={(e) => handleVisibilityChange(e,'tablet')}
          className={classes.iconButton}
        >
          <TabletIcon className={cn(classes.icon, visibility?.tablet && classes.active)}/>

          {visibility?.tablet
            ?
            <VisibilityIcon className={cn(classes.visibilityIcon, classes.active)}/>
            :
            <VisibilityOffIcon className={cn(classes.visibilityIcon, classes.inactive)}/>
          }

        </IconButton>

        {/* disconnects shared styles between desktop and mobile */}
        <IconButton
          onClick={() => handleStyleLinkChange('mobile')}
          className={classes.iconButton}
        >
          {styleLink?.mobile ? <LinkIcon className={classes.icon}/> : <LinkOffIcon className={classes.icon}/>}
        </IconButton>

        <IconButton
          onClick={(e) => handleVisibilityChange(e,'mobile')}
          className={classes.iconButton}
        >
          <SmartphoneIcon className={cn(classes.icon, visibility?.mobile && classes.active)}/>

          {visibility?.mobile
            ?
            <VisibilityIcon className={cn(classes.visibilityIcon, classes.active)}/>
            :
            <VisibilityOffIcon className={cn(classes.visibilityIcon, classes.inactive)}/>
          }

        </IconButton>

      </div>
    </div>
  );
};

CanvasBreakpointInput.propTypes = {
  classes: PropTypes.object,
  onChange: PropTypes.func.isRequired,
  setting: PropTypes.object.isRequired,
  value: PropTypes.object,
};

export default withStyles(CanvasBreakpointInputStyle)(CanvasBreakpointInput);
