import {Tooltip, withStyles} from '@material-ui/core';
import Collapse from "@material-ui/core/Collapse";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import LockIcon from '@material-ui/icons/Lock';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import {arrayMoveMutable} from "array-move";
import {MuiTheme as theme} from "assets/theme";
import cn from 'classnames';
import LayerContextMenu from "editor/components/contextMenus/LayerContextMenu/LayerContextMenu";
import {EditorLayersContext} from "editor/context/EditorLayersContext";
import DraggableWrapper from "editor/draggables/DraggableWrapper/DraggableWrapper";
import {EditorContext} from "editor/Editor";
import BaseComponentSerializer from "editor/serializers/BaseComponentSerializer";
import PropTypes from 'prop-types';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import CodeIcon from '@material-ui/icons/Code';
import React, {useCallback, useContext, useEffect, useLayoutEffect, useRef, useState} from 'react';

import LayerLabelStyle from './LayerLabelStyle';

const LayerLabel = React.forwardRef((props, componentRef) => {
  const {
    classes,
    children,
    level, // used in CSS
    icon,
    label,
    layerLabelId,
    elementEditorId,
    elementTreeBranch,
    parentLayerId,
    hasSiblings,
    branchRefs,
  } = props;

  const editorData = useContext(EditorContext)
  const {editorReducerState, dispatch} = editorData;
  const {editorState, editorActions} = editorReducerState;
  const {elementSelected, elementTree, lockedAssets} = editorState;

  const treeActions = elementTree.actions;
  const selfRef = useRef();

  const layerRootElem = document.getElementById('layers-root');

  const {layerReducerState, editorLayerDispatch} = useContext(EditorLayersContext);

  const [shouldShowArrow, setShouldShowArrow] = useState(false)
  const [shouldExpand, setShouldExpand] = useState(true)
  const [isSelected, setIsSelected] = useState(false)
  const [contextMenuCoords, setContextMenuCoords] = useState({
    x: null,
    y: null
  })
  const [isLocked, setIsLocked] = useState(false);
  const [isVisible, setIsVisible] = useState(false);
  const [isComponent, setIsComponent] = useState(false);

  const [DOMSiblings, setDOMSiblings] = useState([]);

  const layerLabelWasClicked = useRef(false);
  const DOMSiblingPositions = useRef([]);
  const placeholderCreated = useRef(false);

  const currentBreakpoint = editorReducerState.editorState.canvasSettings.currentCanvasBreakpoint;
  const currentTab = editorReducerState.editorState.currentTab;

  const LAYER_LABEL_GUIDE_CSS = `solid 1px ${theme.palette.gridLines.main}`

  const startingPosition = useRef(null);

  // a node created to keep the place of the icon when dragged since the icon gets removed from page flow
  const placeholderNode = useRef(null);

  //this is for tracking if context menu is opened - does not open and close context menu
  const [contextMenuOpened, setContextMenuOpened] = useState(null)

  // this is a filter supplied to our dom utility functions see
  // https://stackoverflow.com/questions/4378784/how-to-find-all-siblings-of-the-currently-selected-dom-object
  const DOMElementFilter = (elem) => {
    // currently only count divs
    switch (elem.nodeName.toUpperCase()) {
      case 'DIV':
        return true;
      default:
        return false;
    }
  }

  useEffect(() => {
    setIsLocked(lockedAssets.includes(elementEditorId))
  }, [lockedAssets]);

  useEffect(() => {
    if(elementTreeBranch?.baseData?.breakpoints?.visibility?.[currentBreakpoint] === false) {
      setIsVisible(false);
    }
    else {
      setIsVisible(true);
    }

    if(elementTreeBranch?.baseData?.isComponent) {
      setIsComponent(true);
    }
    else {
      setIsComponent(false);
    }
  }, [elementTreeBranch, currentBreakpoint])

  useLayoutEffect(() => {
    if (selfRef.current && currentTab === 'layers') {
      setNewStartingPosition();
    }
  }, [selfRef.current, elementTree, currentTab])

  // useEffect(() => {
  //   let domSiblings =
  //     layerReducerState.branchPositions.filter(elem => elem.parentLayerId === parentLayerId)
  //
  //   let newDOMSiblingPositions = [];
  //   let newDomSiblings = [];
  //   for(let sib of domSiblings) {
  //     newDOMSiblingPositions.push(sib.offsetTop + (sib.offsetHeight/2));
  //     newDomSiblings.push({
  //       ref: sib,
  //       layerLabelId: sib.getAttribute('data-layerlabelid'),
  //       position: sib.offsetTop + (sib.offsetHeight/2)
  //     })
  //   }
  //
  //   newDomSiblings.push({
  //     ref: selfRef.current,
  //     layerLabelId: layerLabelId,
  //     position: startingPosition.current
  //   })
  //
  //   DOMSiblingPositions.current = [...newDOMSiblingPositions];
  //
  //   setDOMSiblings([...newDomSiblings]);
  // }, [elementTree])

  useEffect(() => {
    setShouldShowArrow(!!children);
  }, [children])

  useEffect(() => {
    setIsSelected(elementEditorId === elementSelected.value);

    if(elementEditorId === elementSelected.value && !layerLabelWasClicked.current) {
      selfRef.current.scrollIntoView({
        block: 'center',
        behavior: 'smooth'
      })
    }

    layerLabelWasClicked.current = false;

  }, [elementSelected, elementEditorId])

  useEffect(() => {
    setContextMenuOpened(isContextMenuOpen())
  }, [contextMenuCoords])

  const collapseLabel = () => {
    setShouldExpand(!shouldExpand)
  }

  const selectClickedAsset = () => {
    if (elementSelected.value !== elementEditorId) {
      layerLabelWasClicked.current = true;
      dispatch({type: 'selectAssetById', payload: {editorId: elementEditorId}})
    }
  }

  const closeContextMenu = useCallback(() => {
    setContextMenuCoords({
      x: null,
      y: null,
    })
  }, [setContextMenuCoords])

  const openContextMenu = useCallback((e) => {
    e.preventDefault()

    setContextMenuCoords({
      x: e.clientX - 2,
      y: e.clientY - 4,
    })
  }, [setContextMenuCoords])

  const isContextMenuOpen = () => {
    return !(contextMenuCoords.x === null && contextMenuCoords.y === null);
  }

  const handleStart = (eventHandler, data) => {
    // create a duplicate, empty div so that elements don't shift, then delete this on drag end
    let node = data.node;
    console.log('node', node)

    if (level === 0) {
      selectClickedAsset();
      return;
    }
  }

  const isElementHoveringAContainer = (elemPos, closestSibling) => {
    if (!closestSibling) return;

    let closestSiblingEditorElem = treeActions.findElementInTree(closestSibling.elementEditorId)
    let isValidContainer = editorActions.isElementAContainer(closestSiblingEditorElem)

    console.log('is element hovering a container test', {
      elemPos,
      closestSibling,
      closestSiblingEditorElem,
      isValidContainer
    })

    return ( // true or false
      elemPos > (closestSibling.position - 10) && elemPos < (closestSibling.position + 10)
      && isValidContainer
      && closestSibling.elementEditorId !== elementEditorId
    )
  }

  const handleDragging = (eventHandler, data) => {
    let node = data.node;
    let elemPos = (data.node.offsetTop + (data.node.offsetHeight / 2));

    node.classList.add(classes.isDragging)

    if (!placeholderCreated.current) {
      let bounds = node.getBoundingClientRect();
      let height = bounds.height;
      let width = bounds.width;

      let newPlaceholderNode = document.createElement('DIV');

      newPlaceholderNode.style.width = `${width}px`;
      newPlaceholderNode.style.height = `${height}px`;
      node.after(newPlaceholderNode);
      placeholderNode.current = newPlaceholderNode;

      placeholderCreated.current = true;
    }

    let closestSiblingIndex = findNewLayerPosition(elemPos);

    let selfSiblingIndex = layerReducerState.branchPositions.findIndex(
      elem => elem.position === startingPosition.current
    );

    // we need an extra step where we compare self and the closest siblings' positions
    // and check these things:
    // 1.) is the closest sibling a container?
    // 2.) is the position of self within 10px of the sibling
    // 3.) is the closest container not itself?
    // if all are true, then highlight and later insert the element on drop rather than re-order

    // compare elemPos with closest siblings position
    let closestSibling = layerReducerState.branchPositions[closestSiblingIndex];
    if (!closestSibling) return;

    clearLayerLabelGuides();

    console.log('dragging label test', {
      isHovering: isElementHoveringAContainer(elemPos, closestSibling),
      selfSiblingIndex,
      closestSiblingIndex,
      test: node.offsetParent,
      scrollTest: layerRootElem.scrollTop
    })

    let selfElem = treeActions.findElementInTree(elementEditorId);
    let closestSiblingEditorElem =
      treeActions.findElementInTree(closestSibling.elementEditorId);

    // check condition 1, 2, and 3 to see if we should insert
    if (
      isElementHoveringAContainer(elemPos, closestSibling)
      && !treeActions.isDirectChildTreeElement(selfElem, closestSiblingEditorElem)
    ) {
      // condition 1, 2, and 3 are true so apply the container stylings
      closestSibling.ref.classList.add(
        classes.hoveredContainer
      )
    } else {
      if (selfSiblingIndex < closestSiblingIndex) {
        closestSibling.ref.style.borderBottom =
          LAYER_LABEL_GUIDE_CSS;
      } else if (selfSiblingIndex === closestSiblingIndex) {
        closestSibling.ref.style.borderBottom = 'none';
        closestSibling.ref.style.borderTop = 'none';
      } else {
        closestSibling.ref.style.borderTop =
          LAYER_LABEL_GUIDE_CSS;
      }
    }
  }

  const handleDrop = (eventHandler, data) => {
    // // data give us a deltaX and deltaY but it's not accurate, calculate our own delta
    let delta = Math.abs(
      startingPosition.current -
      (data.node.offsetTop + (data.node.offsetHeight / 2))
    );

    let elemPos = (data.node.offsetTop + (data.node.offsetHeight / 2));
    //let elemPos = (data.node.offsetTop + (data.node.offsetHeight / 2)) + layerRootElem.scrollTop;

    console.log('delta', {
      delta,
      startingPosition: startingPosition.current,
      offsetTop: data.node.offsetTop,
      height: data.node.offsetHeight / 2,
      scroll: layerRootElem.scrollTop,
      data
    })

    data.node.classList.remove(classes.isDragging)

    if (delta <= 20) {
      // this way we click OR drag, not both
      selectClickedAsset();
      removePlaceholder();
      snapBackToOriginalPosition(data.node);
      clearLayerLabelGuides();
      return;
    }

    // find where this was dropped, and re-organize the tree branch children accordingly
    // first step, find where it was dropped
    let closestSiblingIndex = findNewLayerPosition(elemPos);
    let closestSibling = layerReducerState.branchPositions[closestSiblingIndex];
    let selfBranchPos = layerReducerState.branchPositions.find(elem => elem.layerLabelId === layerLabelId);
    let closestSiblingEditorElem =
      treeActions.findElementInTree(closestSibling.elementEditorId);

    let selfElem = treeActions.findElementInTree(elementEditorId)

    // let closestSiblingIndex = newDOMSiblings.findIndex(elem => elem.pos === closest);
    // if(isClosestNegative) {
    //   closestSiblingIndex += 1;
    // }

    // we need to check these things:
    // is the element being dragged out of it's current container?
    // is the element being dragged onto a valid container?
    let isDroppedOnSibling = treeActions.isSiblingElement(selfElem, closestSiblingEditorElem)
    let isHoveringAContainer = isElementHoveringAContainer(elemPos, closestSibling)
    let isDroppedOnValidContainer = (
      editorActions.isElementAContainer(closestSiblingEditorElem)
      && isHoveringAContainer
    )

    // only check direct children so that elements can move up the tree inside
    // of a parent

    // TODO: this was crashing see https://trello.com/c/83QDOVX8/468-crashing-while-dragging-layer-label
    let isDroppedOnParent;
    let isDroppedOnChild;

    try {
      isDroppedOnParent = (
        treeActions.isDirectChildTreeElement(selfElem, closestSiblingEditorElem)
        && isDroppedOnValidContainer
      )
    }
    catch (e) {
      console.error('An error occurred with handleDrop()', e);
      isDroppedOnParent = false;
    }

    try {
      isDroppedOnChild = treeActions.isDirectChildTreeElement(closestSiblingEditorElem, selfElem)
    }
    catch (e) {
      console.error('An error occurred with handleDrop()', e);
      isDroppedOnChild = false;
    }

    if (
      isHoveringAContainer
      && isDroppedOnValidContainer
      && !isDroppedOnParent
      && !isDroppedOnChild
    ) {
      // insert the element
      dispatch({
        type: 'insertAsset',
        payload: {
          toBeInsertedEditorId: selfElem.id,
          parentEditorId: closestSiblingEditorElem.id
        }
      })
    } else if (isDroppedOnSibling || isDroppedOnParent) {
      let domSiblings =
        layerReducerState.branchPositions.filter(elem => elem.parentLayerId === parentLayerId)

      if(!domSiblings.includes(elem => elem.layerLabelId === layerLabelId)) {
        domSiblings.push(selfBranchPos)
      }

      let siblingIndex = findNewSiblingLayerPosition(elemPos)

      let selfSiblingIndex = domSiblings.findIndex(
        elem => elem.layerLabelId === layerLabelId
      );

      // TODO: we need to find out if the closest sibling is an actual sibling
      // if so, then proceed with below otherwise we need new functionality

      // now we have the index of the label being dragged (selfSiblingIndex) and the index of the
      // sibling to be replaced. We want to edit the actual editor tree now and simply re-arrange the parent's
      // children

      // use the editorId of the element this label corresponds to to find that element's parent so we can
      // get it's children array to rearrange

      let elementTreeParent = treeActions.findParentInTree(elementEditorId);

      // clone the parent...

      // TODO: do we need this?
      //let newElementTreeParent = cloneDeep(elementTreeParent);

      let newElementTreeParent = {...elementTreeParent};

      // now we need to set it's position, we do this by using a helper
      // function on the base component serializer
      let serializer = new BaseComponentSerializer(
        newElementTreeParent,
        editorReducerState
      )

      // this keeps the parent in place
      let position = serializer.getDraggablePosition();

      if (newElementTreeParent?.draggableProps?.style) {
        newElementTreeParent.draggableProps.style = {
          ...newElementTreeParent.draggableProps.style,
          ...position,
          position: 'static'
        }
      } else {
        newElementTreeParent.draggableProps = {};
        newElementTreeParent.draggableProps.style = {...position, position: 'static'};
      }

      // re-arrange the children - this returns an array of 1 always, so get the 0 index

      // this is mutated so we don't need to re-assign
      arrayMoveMutable(newElementTreeParent.children, selfSiblingIndex, siblingIndex)

      // let elemToMove = newElementTreeParent.children.splice(selfSiblingIndex)[0];
      // newElementTreeParent.children.splice(closestSiblingIndex, 0, elemToMove);

      if (newElementTreeParent.id === 'editor-root') {
        // we're editing top level elements, we can't replace the editor root
        // so we need to set it differently

        dispatch({
          type: 'setEditorState',
          payload: {
            elementTree: {
              value: {'editor-root': newElementTreeParent}
            }
          }
        })
      } else {
        dispatch({
          type: 'replaceAsset',
          payload: {
            originalElemId: elementTreeParent.id,
            newTreeElem: newElementTreeParent
          }
        });
      }
    } else if (!closestSibling.parentLayerId) {
      // closest layer label has no parent, that means this layer label should be raised to the
      // top level

      // this fixes editor crashes because closestSiblingEditorElem can be undefined in rare cases
      if(closestSiblingEditorElem?.id) {
        let parentOfTargetLayer = treeActions.findParentInTree(closestSiblingEditorElem?.id);

        // parentOfTargetLayer should always be the editor-root in this if statement

        dispatch({
          type: 'insertAsset',
          payload: {
            toBeInsertedEditorId: selfElem.id,
            parentEditorId: parentOfTargetLayer.id
          }
        })
      }
    }

    let newSelfElem = document.querySelector(`[data-layerlabelid='${layerLabelId}']`)
    selfRef.current = newSelfElem;

    // this needs to be done AFTER the delta/center is calculated or else you'll always get 0
    removePlaceholder();
    snapBackToOriginalPosition(data.node);
    clearLayerLabelGuides();

    setNewStartingPosition(newSelfElem);
  }

  const setNewStartingPosition = (currentSelfElem = selfRef.current) => {
    let offsetTest = {
      top: currentSelfElem.offsetTop
    };

    console.log('offset test', offsetTest)

    let rect = currentSelfElem.getBoundingClientRect();

    console.log('rect test', {
      top: rect.top,
      ref: selfRef.current
    })

    editorLayerDispatch({
      type: 'setLayerLabelPosition',
      payload: {
        layerLabelId,
        elementEditorId,
        ref: currentSelfElem,
        position: (currentSelfElem.offsetTop + (currentSelfElem.offsetHeight / 2)),
        parentLayerId
      }
    })

    startingPosition.current = currentSelfElem.offsetTop + (currentSelfElem.offsetHeight / 2);
  }

  const getIndex = (arr, num) => {
    arr.push({position: num})
    let returnVal = arr.sort((a, b) => {
      return a.position - b.position;
    }).findIndex(elem => elem.position === num);

    return returnVal
  }

  const findNewSiblingLayerPosition = (yPos) => {
    let domSiblings =
      layerReducerState.branchPositions.filter(elem => elem.parentLayerId === parentLayerId)

    domSiblings = domSiblings.filter(elem => elem.layerLabelId !== layerLabelId)

    return getIndex(domSiblings, yPos);
  }


  const findNewLayerPosition = (yPos) => {
    let newDOMSiblingPositions = [...layerReducerState.branchPositions]
    newDOMSiblingPositions = newDOMSiblingPositions.filter(
      branch => branch.layerLabelId !== layerLabelId
    );

    return getIndex(newDOMSiblingPositions, yPos);
  }

  const removePlaceholder = () => {
    if (placeholderNode.current) {
      placeholderNode.current.remove()
      placeholderNode.current = null;
      placeholderCreated.current = false;
    }
  }

  const clearLayerLabelGuides = () => {
    // TODO: is there a better way to do this?
    for (let i of layerReducerState.branchPositions) {
      i.ref.style.borderTop = 'none';
      i.ref.style.borderBottom = 'none';
      i.ref.classList.remove(classes.hoveredContainer)
    }
  }

  const snapBackToOriginalPosition = (labelNode) => {
    labelNode.style.position = 'static';
  };

  const handleTopLevelClick = () => {
    if (level === 0) {
      selectClickedAsset();
    }
  }

  const unlockAsset = () => {
    dispatch({
      type: 'toggleAssetLock',
      payload: {
        editorId: elementEditorId
      }
    })
  }

  return (
    <div
      className={classes.layerLabelRoot}
    >
      <DraggableWrapper
        onStart={handleStart}
        onStop={handleDrop}
        onDrag={handleDragging}
        canBeDraggedOutOfEditor
        doesNotRenderGridLines
        resizeable={false}
        editorDraggable={false}
        absolutePositioning
      >
        <div
          className={cn(classes.layerLabelInner, isSelected && classes.selectedLayerLabel)}
          onContextMenu={openContextMenu}
          onClick={handleTopLevelClick}
          data-layerlabelid={layerLabelId}
          ref={el => {
            branchRefs.current[layerLabelId] = el
            selfRef.current = el;
          }}
        >
          <div className={classes.iconNodeContainer}>
            {icon}
          </div>

          <Typography
            variant={'subtitle1'}
            className={classes.title}
          >
            {label}
          </Typography>

          {isLocked && (
            <Tooltip title={'This asset is locked.'}>
              <LockIcon
                color={"primary"}
                size={"small"}
                className={classes.statusIcon}
                onClick={unlockAsset}
              />
            </Tooltip>
          )}

          {!isVisible && (
            <Tooltip title={'This asset is not visible.'}>
              <VisibilityOffIcon
                color={'primary'}
                size={'small'}
                className={cn(classes.statusIcon, classes.notVisibleIcon)}
              />
            </Tooltip>
          )}

          {isComponent && (
            <Tooltip title={'This asset is a component.'}>
              <CodeIcon
                color={'primary'}
                size={'small'}
                className={cn(classes.statusIcon, classes.notVisibleIcon)}
              />
            </Tooltip>
          )}

          <IconButton
            color={"primary"}
            size={"small"}
            onClick={openContextMenu}
            //TODO: FIX THIS
            className={cn(classes.menuButton, (isSelected || contextMenuOpened) && classes.selectedMenuButton)}
          >
            <MoreVertIcon/>

          </IconButton>

          {shouldShowArrow && (
            <div className={classes.arrowContainer} onClick={collapseLabel}>
              {shouldExpand ? (
                <ArrowDropDownIcon fontSize={'small'}/>
              ) : (
                <ArrowDropUpIcon fontSize={'small'}/>
              )}
            </div>
          )}
        </div>
      </DraggableWrapper>

      <div className={classes.childrenContainer}>
        <Collapse in={shouldExpand}>
          {children}
        </Collapse>
      </div>

      <LayerContextMenu
        contextMenuCoords={contextMenuCoords}
        onClose={closeContextMenu}
        hasSiblings={hasSiblings}
        DOMSiblings={DOMSiblings}
        startingPosition={startingPosition.current}
        elementEditorId={elementEditorId}
        setNewStartingPosition={setNewStartingPosition}
      />
    </div>
  );
});

LayerLabel.propTypes = {
  classes: PropTypes.object,
  icon: PropTypes.element,

  children: PropTypes.oneOfType(
    [
      PropTypes.element,
      PropTypes.string,
      PropTypes.oneOf([null])
    ]).isRequired,
  level: PropTypes.number.isRequired,
  label: PropTypes.string.isRequired,
  elementEditorId: PropTypes.string.isRequired,
  layerLabelId: PropTypes.string.isRequired,
  parentLayerId: PropTypes.string,
  hasSiblings: PropTypes.bool.isRequired,
  branchRefs: PropTypes.object,
};

export default React.memo(withStyles(LayerLabelStyle)(LayerLabel));
