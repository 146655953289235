import ElementSettingsGroupLabel from "components/ElementSettingGroupLabel/ElementSettingsGroupLabel";
import StyledTextField from "components/StyledTextField/StyledTextField";
import React, {useEffect, useState} from 'react';
import {
  Checkbox,
  withStyles
} from '@material-ui/core';
import PropTypes from 'prop-types';
import LinkInputStyle from './LinkInputStyle';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import cn from 'classnames'

const LinkInput = props => {
  const {
    classes,
    onChange,
    value,
    setting,
    onFocus,
    onBlur
  } = props;

  const [href, setHref] = useState('')
  const [checked, setChecked] = useState(false)

  useEffect(() => {
    if(value) {
      setChecked(true);
      setHref(value);
    }
    else {
      setChecked(false);
      setHref('');
    }
  }, [value])

  const changeHref = (e) => {
    setHref(e.target.value);
    onChange(e.target.value);
  }

  const handleChange = (event) => {
    setChecked(event.target.checked)
  };

  return (
    <div className={classes.root}>
      <ElementSettingsGroupLabel setting={setting}>
        Link
      </ElementSettingsGroupLabel>

      <div className={classes.header}>
        <Checkbox
          checked={checked}
          onChange={handleChange}
          color={'secondary'}
          size={'medium'}
          className={classes.checkbox}
          checkedIcon={
            <CheckBoxIcon
              className={cn(classes.checkboxIcon, classes.checkedIcon)}
            />
          }
          icon={
            <CheckBoxOutlineBlankIcon
              className={classes.checkboxIcon}
            />
          }
        />
        <StyledTextField
          className={classes.input}
          value={href}
          onChange={changeHref}
          onFocus={onFocus}
          onBlur={onBlur}
          fullWidth
          disabled={!checked}
          placeholder={'ex. /home or www.google.com'}
        />
      </div>
    </div>
  );
};

LinkInput.propTypes = {
  classes: PropTypes.object,
  onChange: PropTypes.func.isRequired,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
  value: PropTypes.string,
  setting: PropTypes.object.isRequired,
};

export default withStyles(LinkInputStyle)(LinkInput);
