import HowToChangeButtonText from "editor/components/dialogs/HelpDialog/content/assets/button/HowToChangeButtonText";
import HowToCustomizeButtonStyle
  from "editor/components/dialogs/HelpDialog/content/assets/button/HowToCustomizeButtonStyle";
import HowToSetUpButtonLink from "editor/components/dialogs/HelpDialog/content/assets/button/HowToSetUpButtonLink";
import ContainersForDevelopers
  from "editor/components/dialogs/HelpDialog/content/assets/container/ContainersForDevelopers";
import HowToInsertAssetIntoContainer
  from "editor/components/dialogs/HelpDialog/content/assets/container/HowToInsertAssetIntoContainer";
import HowToPositionAssetsInContainer
  from "editor/components/dialogs/HelpDialog/content/assets/container/HowToPositionAssetsInContainer";
import HowToStyleContainers from "editor/components/dialogs/HelpDialog/content/assets/container/HowToStyleContainers";
import WhatAreContainersUsedFor
  from "editor/components/dialogs/HelpDialog/content/assets/container/WhatAreContainersUsedFor";
import HowToAddFormFields from "editor/components/dialogs/HelpDialog/content/assets/form/HowToAddFormFields";
import HowToEditFormFields from "editor/components/dialogs/HelpDialog/content/assets/form/HowToEditFormFields";
import HowToStyleForm from "editor/components/dialogs/HelpDialog/content/assets/form/HowToStyleForm";
import DifferentIconOptions from "editor/components/dialogs/HelpDialog/content/assets/icon/DifferentIconOptions";
import HowToSetUpIconLink from "editor/components/dialogs/HelpDialog/content/assets/icon/HowToSetUpIconLink";
import StylingIcon from "editor/components/dialogs/HelpDialog/content/assets/icon/StylingIcon";
import DifferentIconButtonOptions
  from "editor/components/dialogs/HelpDialog/content/assets/iconButton/DifferentIconButtonOptions";
import HowToSetUpIconButtonLink
  from "editor/components/dialogs/HelpDialog/content/assets/iconButton/HowToSetUpIconButtonLink";
import StylingIconButton from "editor/components/dialogs/HelpDialog/content/assets/iconButton/StylingIconButton";
import AltTextImage from "editor/components/dialogs/HelpDialog/content/assets/media/AltTextImage";
import HowToChangeImageSize from "editor/components/dialogs/HelpDialog/content/assets/media/HowToChangeImageSize";
import HowToUploadImage from "editor/components/dialogs/HelpDialog/content/assets/media/HowToUploadImage";
import NimbusImageLibrary from "editor/components/dialogs/HelpDialog/content/assets/media/NimbusImageLibrary";
import WhereDoUploadedImagesGoTo
  from "editor/components/dialogs/HelpDialog/content/assets/media/WhereDoUploadedImagesGoTo";
import CanIHyperlinkText from "editor/components/dialogs/HelpDialog/content/assets/text/CanIHyperlinkText";
import HowToMoveText from "editor/components/dialogs/HelpDialog/content/assets/text/HowToMoveText";
import HowToStyleOrChangeText from "editor/components/dialogs/HelpDialog/content/assets/text/HowToStyleOrChangeText";
import HowLongDoesExportingTake
  from "editor/components/dialogs/HelpDialog/content/editor/exporting/HowLongDoesExportingTake";
import HowToExportProject from "editor/components/dialogs/HelpDialog/content/editor/exporting/HowToExportProject";
import WhatIsExporting from "editor/components/dialogs/HelpDialog/content/editor/exporting/WhatIsExporting";
import WhatToDoWithExportedCode
  from "editor/components/dialogs/HelpDialog/content/editor/exporting/WhatToDoWithExportedCode";
import HowToAddAssetToSite from "editor/components/dialogs/HelpDialog/content/general/HowToAddAssetToSite";
import HowToMakeResponsiveSite from "editor/components/dialogs/HelpDialog/content/general/HowToMakeResponsiveSite";
import HowToMoveAroundCanvas from "editor/components/dialogs/HelpDialog/content/general/HowToMoveAroundCanvas";
import SavePreviewExport from "editor/components/dialogs/HelpDialog/content/general/SavePreviewExport";
import React from "react";

const questions = {
  general: [
    { title: 'How do I move around the canvas?', answer: (
        <HowToMoveAroundCanvas/>
      ),
      answerText: '1. To pan up and down, Scroll.\n' +
        '2. To pan left and right, Scroll + Shift.\n' +
        '3. To zoom in, Scroll Up + Shift + ⌘ Command (Mac) or Ctrl (Windows) \n' +
        '4. To zoom out, Scroll Down + Shift + ⌘ Command (Mac) or Ctrl (Windows) \n' +
        'You can also use the zoom buttons found in the top-right of the editor or underneath the \'View\' tab.'
    },
    { title: 'How do I add assets to my site?', answer: (
        <HowToAddAssetToSite/>
      ),
      answerText: 'Assets are accessible via the tab located on the left side of the canvas. Click the \'plus\' button at the top of this ' +
        'tab to reveal a menu of available assets. To place an asset onto the canvas—your site\'s building area—simply drag and drop it.'
    },
    { title: 'How do I make my site responsive on all devices?', answer: (
        <HowToMakeResponsiveSite/>
      ),
      answerText: 'To make a site responsive on all devices, you first need to select what type of device you want to work on. In the bottom-right ' +
        'corner of the canvas there is a device widget that let\'s you select between desktop, tablet, and mobile devices. Upon selecting a device, ' +
        'the canvas will change in size to reflect the screen size of that device. \n' +
        'The next step is making assets on your site responsive. For instance, say on desktop I wanted an image to appear in the header of my site, but ' +
        'on mobile I want the image to be removed. I would select the image asset, and at the top of the right-hand settings panel there is a widget that ' +
        'controls visibility on devices and whether or not you want to share styles between devices. If I wanted an image to appear on desktop and tablet devices,' +
        ' but not on mobile, I would click on the mobile device visibility icon to make the image not visible on that device. The icon will appear white and have a' +
        ' no visibility icon next to it to represent that the image will not be visible on that device. Now, say that I want the image to appear on desktop and tablet ' +
        'devices, but I want the image to be smaller on tablet. I would click on the link icon between the desktop and tablet, breaking the link between these devices' +
        ' and making it so that styles applied to the image on desktop are not shared between the styles applied on tablet devices. The link icon will appear white and ' +
        'have a line over the link, meaning the link is broken. I can then make my image bigger on desktop and smaller on tablet sizes.'
    },
    { title: 'Save, preview, and export.', answer: (
        <SavePreviewExport/>
      ),
      answerText: 'Saving:\n' +
        'Navigate to File + Save to store your project. Nimbus keeps a record of all saved versions for each project on your dashboard. Access your dashboard and click on your' +
        ' project to find all saved versions, allowing you to work on any previous iterations.\n' +
        '\n' +
        'Previewing: \n' +
        'Click the preview button located at the top-right corner of the editor. A dialog will prompt you to confirm the preview. Once your site is ready, either an email will be' +
        ' sent to you with a preview link or you can find the link in the same dialog.\n' +
        '\n' +
        'Exporting: \n' +
        'Click the export button at the top-right corner of the editor. A dialog will appear asking for export confirmation. After a brief wait, your exportable code will be ready.' +
        ' Click the completed link to download it, or check your email for the link. For more details on exporting a project, click here.'
  },
  ],
  container: [
    { title: 'What are containers used for?',
      answer: (
        <WhatAreContainersUsedFor/>
      ),
      answerText: 'Containers serve to establish your site\'s structure. They allow you to organize your content in rows or columns by aligning boxes adjacent to each other. ' +
        'Tip: To make your containers responsive across all screen sizes while saving time, opt for a page section from the public component panel that automatically organizes ' +
        'a container into columns.'
        //TODO: CHANGE TIP TO MENTION LAYOUTS ONCE FEATURE ADDED
    },
    { title: 'How do I insert an asset into a container?',
      answer: (
        <HowToInsertAssetIntoContainer/>
      ),
      answerText: 'Insert any asset of your choice into a container, be it images or text. Once inserted, the container and its assets function as a unified block of content. ' +
        'Simply drag your preferred asset over the highlighted container and drop it to attach.'
    },
    { title: 'How do I position assets inside of the container?',
      answer: (
        <HowToPositionAssetsInContainer />
      ),
      answerText: 'Utilize our alignment widget to position assets within a container. This widget becomes accessible on the right-hand settings panel when a \n' +
        'container is selected. It enables precise positioning of all assets, whether you aim for a top-left alignment or centered arrangement. \n' +
        'The widget also lets you set the container\'s layout as either a row or a column. This structured approach ensures that Nimbus generates high-quality code.' +
        'Note for Developers: Nimbus employs flexbox for container styling instead of using position: relative in the CSS.'
        // TODO: TALK ABOUT THIS ONCE ADDED: You can also find the alignment widget on the action bar above the container when it is selected.
  },
    { title: 'How do I style a container?',
      answer: (
       <HowToStyleContainers/>
      ),
      answerText: 'Upon selecting a container, a settings panel appears on the right-hand side of the editor. This panel offers various customization options, ' +
        'such as setting the width and height, selecting a fill color, and styling the border. Tip: You can specify width and height in either pixel values or ' +
        'percentages. For a full-width box, simply enter \'100%\' as the width.'
      // TODO: REMOVE TIP ONCE UNIT LAYOUT ADDED

    },
    { title: 'Note for Developers:',
      answer: (
        <ContainersForDevelopers/>
      ),
      answerText: 'Containers operate similarly to div elements in coding. They employ flexbox for positioning child elements within them. ' +
        'To set up the flexbox properties, you can take advantage of our alignment widget (see alignment question for details). This method of positioning ensures' +
        ' that Nimbus generates high-quality code upon project exportation. Unlike the use of position: relative, Nimbus opts for flexbox to more accurately mirror' +
        ' how a web developer would code a website.'
    },
  ],
  text: [
    { title: 'How do I move text around on my site?',
      answer: (
        <HowToMoveText/>
      ),
      answerText: ' To move your text around, simply drag it on your page and position it inside of a container utilizing the alignment widget. To learn more about aligning content inside of ' +
        'containers, click here.'
       //TODO: CHANGE LINK TO ALIGNEMNT SETTING HELP OPTION ONCE CREATED
    },
    { title: 'Can I hyperlink text?',
      answer: (
        <CanIHyperlinkText/>
      ),
      answerText: 'Yes, you can link text to other pages of your site, external URLs and more. \n' +
        '1. Select the text in which you want to link.\n' +
        '2. In the text settings, click to expand the \'Effects\' section.\n' +
        '3. Click the checkbox under \'Link\' to confirm that you want to make this text into a link.\n' +
        '4. Enter the additional details for the link.'
      //TODO: CHANGE TO MENTION LINK SETTINGS ON ACTION BAR ONCE THIS FEATURE IS ADDED
    },
    { title: 'How do I change what the text says or looks like?',
      answer: (
        <HowToStyleOrChangeText/>
      ),
      answerText: '"To change what the text says, follow these steps:\n' +
        '1. Select the text to pull up its settings on the right-hand side settings panel. \n' +
        '2. Locate the setting that says \'Text\'. \n' +
        '3. Type in what you want the text to be.\n' +
        'You can also use the text settings panel to alter the font, text style, text size, color, alignment, and more. "'
    }
  ],
  button: [
    { title: 'How do I change button text?',
      answer: (
        <HowToChangeButtonText/>
      ),
      answerText: '"To change the text inside of the button, follow these steps:\n' +
        '1. Select the button, which will bring up its settings in the right-hand panel. \n' +
        '2. Locate the setting that says \'Button Text\'. \n' +
        '3. Input the new text you desire for the button.',
    },
    { title: 'How do I set up the button link?',
      answer: (
        <HowToSetUpButtonLink/>
      ),
      answerText: 'You can link buttons to other pages of your site, external URLs and more. \n' +
        '1. Select the button asset in which you want to link.\n' +
        '2. In the button settings, click to expand the \'Effects\' section.\n' +
        '3. Click the checkbox under \'Link\' to confirm that you want to make this text into a link.\n' +
        '4. Enter the additional details for the link.'
    },
    { title: 'How to customize button styles:',
      answer: (
        <HowToCustomizeButtonStyle/>
      ),
      answerText: 'When having a button selected, the button settings panel will appear on the right-hand side of the editor.\n' +
        'In this panel, you can customize the button to match your design. You have many options to choose from, such as:\n' +
        'size, text, color, and effects.'
    }
  ],
  media: [
    { title: 'How do I upload an image?',
      answer: (
        <HowToUploadImage/>
      ),
      answerText: '"To upload an image, follow these steps:\n' +
        '1. Select the media asset and drag and drop it to the desired location on the canvas.\n' +
        '2. A media dialog will open with the \'Upload Media\' tab selected.\n' +
        '3. Under \'Upload Media\', click or drop your image file onto the dropzone.\n' +
        '4. The image will automatically upload and be stored in the \'Your Images\' tab, serving as your personal image library.'
    },
    { title: 'Where did the image I uploaded go to?',
      answer: (
        <WhereDoUploadedImagesGoTo/>
      ),
      answerText: '"Once you have uploaded your image file under the \'Upload Media\' tab, all images you have uploaded will appear under the \'Your Images\' tab in the media dialog. To access this location, follow these steps:\n' +
        '1. Select the media asset and drag and drop it to the desired location on the canvas.\n' +
        '2. A Media dialog will open with the \'Upload Media\' tab selected.\n' +
        '3. To locate your image library, go to the \'Your Images\' tab."'
    },
    { title: 'Can I use Nimbus\' free library of images on my site?',
      answer: (
        <NimbusImageLibrary/>
      ),
      answerText: 'Yes, users with an upgraded account can freely utilize Nimbus\' image library, sourced from Unsplash.'
    },
    { title: 'How to change size of image:',
      answer: (
        <HowToChangeImageSize/>
      ),
      answerText: '"To change to size of an image, you can either:\n' +
        '1. Give an image a set width and height under the media settings.\n' +
        'OR\n' +
        '2. Just drag the resize handles to make the image the size you want. "',
    },
    { title: 'Do I need to add alt text to my image?',
      answer: (
        <AltTextImage/>
      ),
      answerText: 'Alt text is a good way to give to help Google read your image and improve SEO. To add alt text to an image:\n' +
        '1. Go to media settings panel.\n' +
        '2. Locate setting that says "Alt Text\'.\n' +
        '3. Give image alt text by giving a short description of your image.',
    }
  ],
  icon: [
    { title: 'Styling the icon:',
      answer: (
        <StylingIcon/>
      ),
      answerText: 'When having an icon selected, the icon settings panel will appear on the right-hand side of the editor. ' +
        'In this panel, you can customize the icon to match your design. You have many options to choose from, such as: size, color, and effects. '
    },
    { title: 'What are the different icon options?',
      answer: (
        <DifferentIconOptions/>
      ),
      answerText: 'Nimbus utilizes Material-UI Icons to provide you with an expansive library of icons. To find the icon you want, follow these steps:\n' +
        '1. Select the icon asset and drag and drop it in the desired location on the canvas.\n' +
        '2. An icon dialog will appear, featuring a comprehensive library of icons.\n' +
        '3. Use the search bar to find a specific icon or scroll through the available options.\n' +
        '4. Icons can be filtered by style for different appearances, including filled, outlined, rounded, two-tone, and sharp.'
    },
    { title: 'How do I set up the icon link?',
      answer: (
        <HowToSetUpIconLink/>
      ),
      answerText: 'You can link icons to other pages of your site, external URLs and more. \n' +
        '1. Select the icon asset in which you want to link.\n' +
        '2. In the icon settings, click to expand the \'Effects\' section.\n' +
        '3. Click the checkbox under \'Link\' to confirm that you want to make this text into a link.\n' +
        '4. Enter the additional details for the link.'
      //TODO: CHANGE TO MENTION LINK SETTINGS ON ACTION BAR ONCE THIS FEATURE IS ADDED
    },
  ],
  iconButton: [
    { title: 'How do I set up the icon button link?',
      answer: (
        <HowToSetUpIconButtonLink/>
      ),
      answerText: 'You can link icon buttons to other pages of your site, external URLs and more. \n' +
        '1. Select the icon button asset in which you want to link.\n' +
        '2. In the icon settings, click to expand the \'Effects\' section.\n' +
        '3. Click the checkbox under \'Link\' to confirm that you want to make this text into a link.\n' +
        '4. Enter the additional details for the link.'
    },
    { title: 'What are the different icon button options?',
      answer: (
        <DifferentIconButtonOptions/>
      ),
      answerText: 'Nimbus utilizes Material-UI Icons to provide you with an expansive library of icons. To find the icon you want for your icon button, follow these steps:\n' +
        '1. Select the icon button asset and drag and drop it in the desired location on the canvas.\n' +
        '2. An icon dialog will appear, featuring a comprehensive library of icons.\n' +
        '3. Use the search bar to find a specific icon or scroll through the available options.\n' +
        '4. Icons can be filtered by style for different appearances, including filled, outlined, rounded, two-tone, and sharp.'
    },
    { title: 'Styling the icon button:',
      answer: (
        <StylingIconButton/>
      ),
      answerText: 'When having an icon button selected, a settings panel will appear on the right-hand side of the editor.\n' +
        'In this panel, you can customize the icon button to match your design. You have many options to choose from, such as: size, color, and effects.'
    }
  ],
  exporting: [
    { title: 'What is exporting?',
      answer: (
        <WhatIsExporting/>
      ),
      answerText: 'Exporting a project allows you to turn what you built inside of the editor into high-quality React code. You are then free to take this code and use it as you please!'
    },
    { title: 'How do I export a project?',
      answer: (
        <HowToExportProject/>
      ),
      answerText: 'You can export a project by clicking on the export button at the top-right corner of the editor. A dialog will appear and ask if you would like to export your project. After a few minutes,' +
        ' the code will be available to export. You can click on the completed link to download your code, or you can click on the link in the email we have sent to you! '
    },
    { title: 'How long does exporting my project take?',
      answer: (
        <HowLongDoesExportingTake/>
      ),
      answerText: 'The time required to export a project varies based on its complexity. However, most projects are usually exported within a few minutes. You will receive an email containing a link to your ' +
        'downloadable code once it\'s ready, or you can periodically check the export dialog.'
    },
    { title: 'What do I do with the code once I\'ve exported?',
      answer: (
        <WhatToDoWithExportedCode/>
      ),
      answerText: 'Upon successful export, the code is entirely yours to use as you wish. You are not restricted to Nimbus for further development or deployment.'
    }
  ],
  form: [
    { title: 'How do I style my form?',
      answer: (
        <HowToStyleForm/>
      ),
      answerText: 'When having a form selected, a settings panel will appear on the right-hand side of the editor. In this panel, you can customize the form to match your design.' +
        ' You can change settings on the form itself and/or any of the assets inside of the form, such as inputs or buttons.',
    },
    { title: 'How do I add more fields to my form?',
      answer: (
        <HowToAddFormFields/>
      ),
      answerText: 'To add fields to the form, follow these steps:\n' +
        '1. Drag and drop the form asset to your preferred location on the canvas.\n' +
        '2. A form dialog will appear with an \'Add Input\' button.\n' +
        '3. Click on the \'Add Input\' button to introduce a new input to the form.\n' +
        '4. Choose from Text Field, Radio Button, Checkbox, or Submit Button to add to your form.\n' +
        '5. Fill in the required details for your chosen input and click \'Submit\'.\n' +
        '\n' +
        'What Each Input Requires:\n' +
        '\n' +
        'Text Field:\n' +
        'Name: This is the unique identifier for the text field. It\'s what your system uses to recognize this specific field.\n' +
        'Label: This is the text that appears above the text box, telling users what information they should enter.\n' +
        'Placeholder: This is sample text inside the text box that gives users a hint about what type of information they should input.\n' +
        '\n' +
        'Radio Button:\n' +
        'Name: This is the unique identifier for each radio button in a group.\n' +
        'Label: This is the text next to the radio button, indicating what the option represents.\n' +
        '\n' +
        'Checkbox:\n' +
        'Name: This is the unique identifier for the checkbox.\n' +
        'Label: This is the text next to the checkbox, indicating what the option is for.\n' +
        '\n' +
        'Submit Button:\n' +
        'Text: This is the text that will appear on the button users click to submit the form.\n' +
        '\n' +
        '6. Once you\'ve filled out the necessary details for each input, you have two options:\n' +
        '     1. Add More Fields: Click \'Add Input\' again to continue adding more fields to the form.\n' +
        '     2. Finalize the Form: Click \'Submit\' to complete the form creation process and close the form dialog.\n' +
        '\n' +
        'Adding Fields After Finalizing:\n' +
        'If you decide you want to add more fields after you\'ve closed the form dialog, it\'s no problem! Simply drag the input type you want—like a button or input—from the asset panel and drop it into the existing form.'
    },
    { title: 'How do I edit my form fields?',
      answer: (
        <HowToEditFormFields/>
      ),
      answerText: 'Editing Fields Before Finalizing the Form:\n' +
        'While still in the form dialog, you have several ways to modify an input:\n' +
        '\n' +
        'Delete: To remove an input, click the trash can icon.\n' +
        'Edit: Click the pencil icon to access the input\'s details page. Here, you can modify the required fields for that specific input.\n' +
        'Re-order: Use the black up and down arrows to change the sequence of the form fields.\n' +
        '\n' +
        'Editing Fields After Finalizing the Form:\n' +
        'If the form has already been created and you\'ve exited the form dialog, you still have options to modify an input:\n' +
        '\n' +
        'Delete: Select the input within the form to reveal an action bar above it. Click the trash can icon to remove the input.\n' +
        'Edit: Click the input within the form to display its settings on the right-hand panel. This allows you to adjust the required fields like placeholder and name, as well as to change its appearance.\n' +
        'Re-order: Select the input within the form to reveal an action bar above it. Use the up or down arrows to change the sequence of the form fields.\n'
    }
  ]
};

export default questions;