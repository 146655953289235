import MenuItem from "@material-ui/core/MenuItem";
import ElementSettingsGroupLabel from "components/ElementSettingGroupLabel/ElementSettingsGroupLabel";
import StyledTextField from "components/StyledTextField/StyledTextField";
import React, {useEffect, useRef, useState} from 'react';
import {
  withStyles
} from '@material-ui/core';
import PropTypes from 'prop-types';
import RotationInputStyle from './RotationInputStyle';

const RotationInput = props => {
  const {
    classes,
    onChange,
    onBlur,
    onFocus,
    value,
    setting,
  } = props;

  const [rotation, setRotation] = useState('0')
  const [unit, setUnit] = useState('deg')
  const mounted = useRef(false);

  const generateRotationCSS = () => {
    let generatedCSS = `rotate(${rotation}${unit})`;

    if(generatedCSS !== value) {
      onChange(generatedCSS);
    }
  }

  useEffect(() => {
    if(mounted.current) {
      generateRotationCSS();
    }
    else {
      mounted.current = true;
    }
  }, [rotation, unit]);

  useEffect(() => {
    let formattedValue = value.split('(');
    let newUnit;
    let newRotation;

    if(formattedValue.length === 2) {
      formattedValue = formattedValue[1].split(')')[0];

      newUnit = formattedValue.replace(/\d+/, "");
      newRotation = formattedValue.replace(/[^\d.-]/g, "");
    }
    else {
      newUnit = 'deg';
      newRotation = '0';
    }

    setUnit(newUnit);
    setRotation(newRotation);

    console.log('test')
  }, [value])

  // we need a separate useEffect because this only needs to run  unmount
  useEffect(() => {
    return () => {
      mounted.current = false;
    }
  }, [])


  return (
    <div>
      <ElementSettingsGroupLabel setting={setting}>
        Rotation
      </ElementSettingsGroupLabel>

      <div className={classes.root}>
        <StyledTextField
          formControlClassName={classes.input}
          value={rotation}
          onChange={e => setRotation(e.target.value)}
          onBlur={onBlur}
          onFocus={onFocus}
        />

        <StyledTextField
          select
          formControlClassName={classes.input}
          fullWidth
          value={unit}
          onChange={e => setUnit(e.target.value)}
          onBlur={onBlur}
          onFocus={onFocus}
        >
          <MenuItem value={'deg'}>
            Degrees
          </MenuItem>
          <MenuItem value={'rad'}>
            Radians
          </MenuItem>
          <MenuItem value={'grad'}>
            Gradians
          </MenuItem>
        </StyledTextField>
      </div>
    </div>
  );
};

RotationInput.propTypes = {
  classes: PropTypes.object,
  onChange: PropTypes.func.isRequired,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
  setting: PropTypes.object.isRequired,
};

export default withStyles(RotationInputStyle)(RotationInput);
