import {outlinedBlackBorderButton, purpleButton} from "assets/theme";

const SubscriptionStyle = theme => ({
  purpleButton: {
    ...purpleButton,
  },

  outlinedButton: {
    ...outlinedBlackBorderButton,
    marginLeft: "8px"
  },

  buttonContainer: {
    display: "flex",
    flexDirection: "row",
    marginTop: "8px",

    [theme.breakpoints.down('sm')]: {
      display: "none"
    },
  },

  status: {
    marginBottom: "8px",
  },

  activeSubscription: {
    color: theme.palette.secondary.main,
    fontWeight: '600'
  },

  nonActiveSubscription: {
    color: theme.palette.error.main
  },

  mobileButtonContainer: {
    display: "none",

    [theme.breakpoints.down('sm')]: {
      display: "flex",
      flexDirection: "row"
    },
  },
});

export default SubscriptionStyle;
