const TwoColLayoutStyle = theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'flex-start'
  },

  innerContainer: {
    display: 'grid',
    width: '100%',
    gridTemplateColumns: 'calc(50% - 4px) calc(50% - 4px)',
    gridTemplateRows: '50% 50%',
    gridGap: '8px',
    gridColumnGap: '8px',
    marginTop: '8px'
  }
});

export default TwoColLayoutStyle;
