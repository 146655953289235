const SubmitBugDialogStyle = theme => ({
  dialog: {
    height: '420px',
    minWidth: "450px",
  },

  dialogTitle: {
    width: "100%",

    '& .MuiTypography-root': {
      display: "flex",
      flexDirection: "row",
      width: "100%",
      justifyContent: "space-between",
    },
  },
  closeIcon: {
    color: "white",

    '&:hover':{
      background: "#3a3a4a",
      transition: "0.3s",
    },
  },

});

export default SubmitBugDialogStyle;