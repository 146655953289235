import Typography from "@material-ui/core/Typography";
import React from 'react';

const HowToCustomizeButtonStyle = () => {
  return (
    <>
      <Typography>
        When having a button selected, the button settings panel will appear on the right-hand side of the editor.
        In this panel, you can customize the button to match your design. You have many options to choose from, such as:
        size, text, color, and effects.
      </Typography>
    </>
  );
};

HowToCustomizeButtonStyle.propTypes = {

};

export default HowToCustomizeButtonStyle;