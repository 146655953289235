import {EditorContext} from "editor/Editor";
import {useSerializerUtils} from "editor/serializers/useSerializerUtils";
import {useAssetConfig} from "hooks/useAssetConfig";
import React, {useContext, useEffect, useMemo, useState} from "react";
import {capitalizeFirstLetter} from "utils/utils";

export const useComponentVariants = (category, source) => {
  const {editorReducerState, dispatch} = useContext(EditorContext);
  const savedCategories = editorReducerState.editorState.savedComponentCategories;
  const publicCategories = editorReducerState.editorState.publicComponentCategories;
  const layoutCategories = editorReducerState.editorState.layoutCategories;

  const categoriesToUse = useMemo(() => {
    switch (source) {
      case 'public': return publicCategories;
      case 'saved': return savedCategories;
      case 'layouts': return layoutCategories;
      default: return [];
    }
  }, [source, savedCategories, publicCategories]);

  const [variantsResult, setVariantsResult] = useState([]);

  console.log('asset panel saved categories', savedCategories)

  const {
    recursivelyDeserializeChildren
  } = useSerializerUtils(editorReducerState);

  const currentBreakpoint = editorReducerState.editorState.canvasSettings.currentCanvasBreakpoint;

  const assetConfig = useAssetConfig();

  useEffect(() => {
    if(categoriesToUse?.[category?.name]) {
      console.log('get component variants')

      let formattedVariants = [];

      for (let variant of categoriesToUse[category.name]) {
        let serializer = getComponentSerializer(variant.data.tag, variant.data);

        let test = serializer.deserialize()

        test.children = recursivelyDeserializeChildren(variant.data, editorReducerState);

        let formattedVariantName = variant.name;

        let componentData = {...variant}
        delete componentData?.['data']

        formattedVariants.push({
          elementToCreate: {
            ...test,
            baseData: {
              ...test.baseData,
              ownedByUser: variant?.['owned_by_user'],
              isComponent: true,
              componentData: {
                ...componentData,
              }
            },
            props: {
              ...test?.props,
              ...variant.data?.breakpointData?.[currentBreakpoint]
            },
            breakpointData: {...variant.data?.breakpointData},
          },
          id: variant.id,
          image: variant.screenshot,
          variantName: variant.name,
          formattedVariantName: formattedVariantName,
        })
      }

      setVariantsResult(formattedVariants);
    }
  }, [categoriesToUse])

  if(!category?.name) return null;



  const getComponentSerializer = (tag, elem=null) => {
    console.log('get one of these serializers', Object.keys(assetConfig))
    for(let assetKey of Object.keys(assetConfig)) {
      if(assetKey === tag) {
        return new assetConfig[assetKey].serializer(elem, editorReducerState);
      }
    }
  }

  console.log('category test', {categoryName: category.name, savedCategories, test: savedCategories?.[category.name]})


  return variantsResult;
}
