import Button from "@material-ui/core/Button";
import CloseIcon from "@material-ui/icons/Close";
import {EditorContext} from "editor/Editor";
import {Form, Formik} from "formik";
import {useEditorDialog} from "hooks/useEditorDialog";
import React, {useContext, useEffect, useState} from 'react';

import {
  Dialog, DialogContent, DialogTitle, IconButton, TextField, Typography,
  withStyles
} from '@material-ui/core';
import PropTypes from 'prop-types';

import AddClassDialogStyle from './AddClassDialogStyle';

const AddClassDialog = props => {
  const {
    classes,
    open,
    setOpen,
    data
  } = props;

  const editorData = useContext(EditorContext);
  const {editorReducerState, dispatch} = editorData;
  const editorActions = editorReducerState.editorActions;

  const [errorMessage, setErrorMessage] = useState('');

  const onSubmit = async (values, {setErrors}) => {
    console.log('add a class here', values, data)
    console.log('placeholder');

    if(!data.editorId) {
      setErrorMessage('The element to copy styles from does not exist.');
      return;
    }

    // find the element by the supplied id
    const element = editorActions.findElementInTree(data.editorId)

    const payload = {
      classNameToAdd: values.classToAdd,
      classData: element.breakpointData,
      assetTagName: element.tagName
    }

    dispatch({
      type: 'addClass',
      payload: payload
    })

    if(editorData?.successCallback) {
      editorData.successCallback(payload);
    }

    handleClose();
  };

  const handleClose = () => {
    setOpen(false);
    setErrorMessage('');
  }

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      maxWidth={'md'}
      classes={{paper: classes.dialog}}
    >
      <DialogTitle className={classes.dialogTitle}>
        Add a Class

        <IconButton
          onClick={handleClose}
          className={classes.closeIcon}
          size={'small'}
        >
          <CloseIcon/>
        </IconButton>
      </DialogTitle>

      <DialogContent>
        <Formik
          initialValues={{
            'classToAdd': ''
          }}
          enableReinitialize
          onSubmit={onSubmit}
        >
          {({
              values,
              errors,
              handleChange
            }) => (
            <Form className={classes.form}>
              {errorMessage &&
                <Typography
                  variant={'body1'}
                  className={classes.errorMessage}
                >
                  {errorMessage}
                </Typography>
              }

              <TextField
                fullWidth
                color={"primary"}
                variant={"outlined"}
                name={'classToAdd'}
                value={values.classToAdd}
                autoFocus
                onChange={handleChange}
                error={!!errors.classToAdd}
                helperText={errors.classToAdd}
                InputProps={{
                  classes: {
                    notchedOutline: classes.notchedOutline,
                  },
                }}
              />

              <Button
                className={classes.purpleButton}
                variant={'contained'}
                color={'secondary'}
                type={'submit'}
              >
                Submit
              </Button>
            </Form>
          )}
        </Formik>
      </DialogContent>
    </Dialog>
  );
};

AddClassDialog.propTypes = {
  classes: PropTypes.object,
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
  data: PropTypes.object,
};

export default withStyles(AddClassDialogStyle)(AddClassDialog);
