import {createTheme, MuiThemeProvider, Typography, withStyles} from '@material-ui/core';
import {arrayMoveImmutable, arrayMoveMutable} from "array-move";
import {UserContext} from "context/UserContext";
import getFormDraggableAssetConfig from "editor/components/dialogs/FormDraggableDialog/formDraggableAssetConfig";
import ReactJoyride from "editor/components/ReactJoyride/ReactJoyride";
import {DEFAULT_TREE} from "editor/constants/editorConstants";
import EditorDialogsProvider from "editor/context/EditorDialogsProvider";
import EditorHotkeysProvider from "editor/context/EditorHotkeysProvider";
import IconProvider from "editor/context/IconContext";

import EditorStyle from 'editor/EditorStyle';
import EditorCanvas from "editor/layout/EditorCanvas/EditorCanvas";
import EditorSettings from "editor/layout/EditorSettings/EditorSettings";
import TabBar from "editor/layout/TabBar/TabBar";
import {useSerializerUtils} from "editor/serializers/useSerializerUtils";

import {useEventHandler} from "hooks/eventHooks/useEventHandler";
import {useProjectSerializer} from "hooks/serializerHooks/useProjectSerializer";
import {useAssetConfig} from "hooks/useAssetConfig";
import html2canvas from "html2canvas";
import {cloneDeep} from "lodash-es";
import React, {createContext, useCallback, useContext, useEffect, useMemo, useReducer, useRef, useState} from 'react';
import {useHistory, useLocation} from 'react-router-dom';
import {toast} from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import {User} from "services";
import APPLICATION_SETTINGS from "settings";
import {generateId, handleNetworkError, isFunction} from "utils/utils";
import {getTopmostEditorElementFromPath} from "../lib/draggables/draggables";
import {insertElement, renderTree} from "editor/actions/core";
import initializeElementTreeActions from "./elementTree/ElementTreeActions";
import ElementSettings from "./layout/ElementSettings/ElementSettings";
import Toolbox from "./layout/Toolbox/Toolbox";
import {diff} from 'deep-object-diff';
import generateNewAssetLayerLabel from "editor/actions/assets/generateAssetLayerLabel";
import createAsset from "editor/actions/assets/createAsset";
import replaceAsset from "editor/actions/assets/replaceAsset";
import insertAsset from "editor/actions/assets/insertAsset";


export const EditorContext = createContext();

const Editor = props => {
  const {
    children,
    classes
  } = props;

  const [elementBeingDraggedState, setElementBeingDraggedState] = useState(null);
  const [elementSelected, setElementSelected] = useState(null);
  const [elementSelectedBounds, setElementSelectedBounds] = useState(null);

  const [editorBounds, setEditorBounds] = useState(null)
  const [editorNode, setEditorNode] = useState(null);
  const [editorVerticalCenterWithOffset, setEditorVerticalCenterWithOffset] = useState(null);
  const [editorHorizontalCenterWithOffset, setEditorHorizontalCenterWithOffset] = useState(null);
  const [editorCenters, setEditorCenters] = useState(null);

  const assetConfig = useAssetConfig();
  const {userData} = useContext(UserContext)

  const zoomLevel = useRef(1.0)

  // only has a value if an existing project was picked from the dashboard
  const [currentProjectData, setCurrentProjectData] = useState(null);

  const editorGridLineNodes = useRef({
    horizontal: null,
    vertical: null
  });

  const [pageData, setPageData] = useState([{
    pageName: `Page 1`,
    labelId: `Page-${generateId()}`,
    route: `/page1`,
    tree: {...DEFAULT_TREE},
    layerLabelsInUse: [],
    canvasSettings: {
      currentCanvasBreakpoint: 'desktop',
      currentCanvasBackgroundColor: '#FFFFFF',
      currentAlignment: {
        alignment: 'top-left',
        flexDirection: 'column'
      },
      canvasHeight: '1080px',
      style: {
        flexDirection: 'column',
        justifyContent: 'flex-start',
        alignItems: 'flex-start'
      }
    },
    ref: null
  }])

  const [selectedPage, setSelectedPage] = useState(pageData[0]);
  const [treeElements, setTreeElements] = useState(selectedPage.tree);

  const history = useHistory()
  const treeActions = useRef();
  const location = useLocation();

  // logic to check if the user is even logged in, if not we want to redirect them
  // useEffect(() => {
  //   if(userData && userData?.loggedIn === false) {
  //     history.push('/register', {})
  //   }
  // }, [userData])

  const DEFAULT_THEME = {
    palette: {
      // values retrieved from https://mui.com/material-ui/customization/default-theme/
      primary: {
        main: '#1976d2',
        light: '#42a5f5',
        dark: '#1565c0',
        contrastText: '#ffffff'
      },
      secondary: {
        main: '#9c27b0',
        light: '#ba68c8',
        dark: '#7b1fa2',
        contrastText: '#ffffff'
      },
      error: {
        main: '#d32f2f',
        light: '#ef5350',
        dark: '#c62828',
        contrastText: '#ffffff'
      },
      warning: {
        main: '#ed6c02',
        light: '#ff9800',
        dark: '#e65100',
        contrastText: '#ffffff'
      },
      success: {
        main: '#2e7d32',
        light: '#4caf50',
        dark: '#1b5e20',
        contrastText: '#ffffff'
      }
    }
  }

  const [tree, setTree] = useState(renderTree(
    {...DEFAULT_TREE},
  ));

  // TODO: we'll probably want to refactor this to work with any "container" types
  const renderGridLine = (gridLineType, shouldRender) => {
    /**
     * @param {string} gridLineType - "horizontal" or "vertical" determines which grid line to render
     * @param {boolean} shouldRender - should we render the line
     */

    if (gridLineType === 'horizontal') {
      if (shouldRender && !editorGridLineNodes.current.horizontal) {
        let gridLineNode = document.createElement('div');

        // editorNode.appendChild(gridLineNode)
        //
        // gridLineNode.classList.add(classes.gridLineStyle);
        //
        // gridLineNode.style.height = `1px`;
        // gridLineNode.style.width = `${editorBounds.width}px`;
        // gridLineNode.style.top = `${editorCenters.horizontal}px`;
        // gridLineNode.style.left = `${editorBounds.x}px`

        dispatch({
          type: 'renderGridline',
          payload: {
            gridLineNode,
            gridLineType
          }
        })

        // whatever the left margin of the editor is - as of this code it was

        editorGridLineNodes.current = {...editorGridLineNodes.current, horizontal: gridLineNode};
      } else if (!shouldRender && editorGridLineNodes.current.horizontal) {
        editorGridLineNodes.current.horizontal.remove();
        editorGridLineNodes.current = {...editorGridLineNodes.current, horizontal: null};
      }
    } else if (gridLineType === 'vertical') {
      if (shouldRender && !editorGridLineNodes.current.vertical) {
        let gridLineNode = document.createElement('div');

        dispatch({
          type: 'renderGridline',
          payload: {
            gridLineNode,
            gridLineType
          }
        })

        // editorNode.appendChild(gridLineNode)
        //
        // gridLineNode.classList.add(classes.gridLineStyle);
        //
        // gridLineNode.style.height = `${editorBounds.height}px`;
        // gridLineNode.style.width = `1px`;
        // gridLineNode.style.left = `${editorCenters.vertical}px`;
        // gridLineNode.style.position = 'absolute'
        //
        // gridLineNode.style.top = `${editorBounds.top}px`;

        editorGridLineNodes.current = {...editorGridLineNodes.current, vertical: gridLineNode};
      } else if (!shouldRender && editorGridLineNodes.current.vertical) {
        editorGridLineNodes.current.vertical.remove();
        editorGridLineNodes.current = {...editorGridLineNodes.current, vertical: null};
      }
    }
  }

  const isElementSelected = (editorId) => {
    /**
     * @param {Node} elemRef - A reference to the element, don't forget the ".current"!
     */

    let elementSelected = editorReducerState.editorState.elementSelected.value;

    if (elementSelected) {
      return elementSelected === editorId;
    }

    return false;
  }

  const getEditorBounds = () => {
    let editorNode = document.getElementById('editor-root');
    let zoomContainerNode = document.getElementById('zoom-container')

    let initEditorBounds = editorNode.getBoundingClientRect()
    let zoomContainerBounds = zoomContainerNode.getBoundingClientRect()

    initEditorBounds = {
      left: initEditorBounds.left - zoomContainerBounds.left,
      right: initEditorBounds.right - zoomContainerBounds.left,
      top: initEditorBounds.top - zoomContainerBounds.top,
      bottom: initEditorBounds.bottom - zoomContainerBounds.top,
      width: initEditorBounds.width,
      height: initEditorBounds.height
    };

    return initEditorBounds;
  }

  const recursivelyRenameChildren = (children, layerLabelsInUse, useNumbersIfNeeded=true) => {
    let labelsInUse = [...layerLabelsInUse];

    let returnData = children.map(child => {
      if(child.children && child.children.length > 0) {
        let newLayerLabel = generateNewAssetLayerLabel(
          child.baseData.layerLabel || child.baseData.baseLayerLabel,
          child.tagName,
          0,
          labelsInUse,
          useNumbersIfNeeded
        )

        labelsInUse.push(newLayerLabel);

        let res = recursivelyRenameChildren(child.children, labelsInUse);
        labelsInUse = res.layerLabelsInUse;

        return {
          ...child,
          baseData: {
            ...child.baseData,
            layerLabel: newLayerLabel,
          },
          props: {
            ...child.props,
            'layerLabel': newLayerLabel
          },
          children: res.data
        }
      } else {
        let newLayerLabel = generateNewAssetLayerLabel(
          child.baseData.layerLabel || child.baseData.baseLayerLabel,
          child.tagName,
          0,
          labelsInUse,
          useNumbersIfNeeded
        )

        labelsInUse.push(newLayerLabel);

        return {
          ...child,
          baseData: {
            ...child.baseData,
            layerLabel: newLayerLabel,
          },
          props: {
            ...child.props,
            'layerLabel': newLayerLabel
          }
        }
      }
    })

    return {data: returnData, layerLabelsInUse: labelsInUse}
  }

  const recursivelyDeleteAssetLabels = (children, labelsInUse) => {
    let newLabelsInUse = [...labelsInUse];

    let data = children.map(child => {
      if(child.children && child.children.length > 0) {
        newLabelsInUse.splice(newLabelsInUse.indexOf(child.baseData.layerLabel), 1);

        let res = recursivelyDeleteAssetLabels(child.children, newLabelsInUse);

        newLabelsInUse = res.newLabelsInUse

        return {
          ...child,
          baseData: {
            ...child.baseData,
          },
          props: {
            ...child.props,
          },
          children: res.children,
        }
      } else {

        newLabelsInUse.splice(newLabelsInUse.indexOf(child.baseData.layerLabel), 1);

        return {
          ...child,
          baseData: {
            ...child.baseData,
          },
          props: {
            ...child.props,
          }
        }
      }
    })

    return {data, newLabelsInUse}
  }

  const saveComponentToLibrary = (
    editorId,
    name,
    category,
    isPublic=false,
    onSuccess=null,
    onFail=null
  ) => {
    takeScreenshotOfComponentAsync(editorId)
      .then(screenshot => {
        let elem = treeActions.current.findElementInTree(editorId);

        let serializer = getComponentSerializer(elem.tagName, elem);
        let serializedData = serializer.serialize();

        let serializedChildren = recursivelySerializeChildren(elem)

        serializedData.children = serializedChildren;

        console.log('create component on back-end', elem);

        User.createComponent(serializedData, name, category, screenshot, isPublic)
          .then(response => {
            console.log('create component success!', response)
            toast.success('Component saved successfully!')


            dispatch({
              type: 'replaceAsset',
              payload: {
                originalElemId: editorId,
                newTreeElem: {
                  ...elem,
                  baseData: {
                    ...elem.baseData,
                    componentData: {
                      ...response.data.component
                    }
                  }
                }
              }
            })

            dispatch({
              type: 'setComponentCategories',
              payload: {
                savedComponentCategories: response.data.categories
              }
            })

            if (onSuccess && isFunction(onSuccess)) {
              onSuccess();
            }
          })
          .catch(error => {
            handleNetworkError(error);

            if (onFail && isFunction(onFail)) {
              onFail();
            }
          })
      })
      .catch(error => {
        console.error('error', error)
      })
  }

  const measureElementBounds = (editorId, offsetEditorBounds=false) => {
    let node = treeActions.current.getElementFromTree(editorId);

    if (node) {
      let {top, right, bottom, left, width, height, x, y} = node.getBoundingClientRect();

      let newWidth = width * (1/zoomLevel.current)
      let newHeight = height * (1/zoomLevel.current)

      if(offsetEditorBounds) {
        let zoomContainerNode = document.getElementById('zoom-container');
        let zoomContainerBounds = zoomContainerNode.getBoundingClientRect();

        x = (x - zoomContainerBounds.left) * (1/zoomLevel.current);
        y = (y - zoomContainerBounds.top) * (1/zoomLevel.current);
      }

      let values = {
        top: Math.round(top * 10) / 10,
        right: Math.round(right * 10) / 10,
        bottom: Math.round(bottom * 10) / 10,
        left: Math.round(left * 10) / 10,
        width: Math.round(newWidth * 10) / 10,
        height: Math.round(newHeight * 10) / 10,
        x: Math.round(x * 10) / 10,
        y: Math.round(y * 10) / 10,
      };

      return values;
    }

    return null;
  }

  // const generateNewAssetCounts = (operationType='add', assetType, state) => {
  //   let newAssetCounts;
  //
  //   let lowercaseAssetType = assetType.toLowerCase();
  //
  //   if(Object.keys(state.editorState.assetCounts).includes(lowercaseAssetType)) {
  //     newAssetCounts = {
  //       ...state.editorState.assetCounts,
  //       [lowercaseAssetType]:
  //         operationType === 'add' ?
  //           state.editorState.assetCounts[lowercaseAssetType] + 1
  //           :
  //           state.editorState.assetCounts[lowercaseAssetType] - 1
  //     }
  //   }
  //   else {
  //     newAssetCounts = {
  //       ...state.editorState.assetCounts,
  //       [lowercaseAssetType]:
  //         operationType === 'add' ?
  //           1
  //           :
  //           0
  //     }
  //   }
  //
  //   return newAssetCounts;
  // }

  const selectNewAsset = (newEditorId) => {
    // TODO: check if the element is locked
    dispatch({type: 'selectAssetById', payload: {editorId: newEditorId}})

    // if(!lockedAssets.includes(newEditorId)) {
    //   setElementSelected(newEditorId);
    //
    //   let {top, right, bottom, left, width, height, x, y} = elem.getBoundingClientRect();
    //   setElementSelectedBounds({top, right, bottom, left, width, height, x, y})
    // }
  }



  const findElementInTree = (editorId) => {
    return treeActions.current.findElementInTree(editorId);
  }

  const isElementAContainer = (treeElem) => {
    let validContainerElements = editorReducerState.editorState.defaults.validContainerElements;

    return validContainerElements.includes(treeElem?.tagName);
  }

  const copyFromTree = (editorId) => {
    console.log('editorid', editorId)
    if (editorId) {
      let treeElementToCopy = treeActions.current.findElementInTree(editorId);

      return treeElementToCopy;
    }
  }

  const loadProject = (projectData) => {
    // copy project details except for data for display purposes
    let projectDetails = {...projectData};
    delete projectDetails.data;

    let projectToDeserialize = projectData;
    let deserializedProject = projectSerializer.deserialize(projectToDeserialize);

    console.log('deserialized project', deserializedProject)

    let editorStatePayload = {
      layerLabelsInUse: [
        ...deserializedProject?.data?.layerLabelsInUse
      ],
      canvasSettings: {
        ...deserializedProject?.data?.pages?.[0].canvasSettings,
      },
      elementTree: {
        value: deserializedProject.pages[0].tree
      },
      editorPages: {
        value: deserializedProject.pages,
        selectedPage: deserializedProject.pages[0].labelId
      },
      currentProjectData: {
        value: projectDetails
      },
      themeSettings: {
        ...DEFAULT_THEME,
        ...deserializedProject?.data?.themeSettings
      }
    };

    let newCanvasSettings = {
      ...deserializedProject?.data?.pages?.[0].canvasSettings
    }

    dispatch({
      type: 'setEditorState',
      payload: editorStatePayload
    });

    dispatch({
      type: 'changeCanvasData',
      payload: {
        breakpointToUse: newCanvasSettings.currentCanvasBreakpoint,
        backgroundColor: newCanvasSettings.currentCanvasBackgroundColor,
        canvasHeight: newCanvasSettings.canvasHeight,
        currentAlignment: newCanvasSettings.currentAlignment,
        style: newCanvasSettings.style,
      }
    })
  }

  const saveProject = async (projectSerializer, showToast=true, callback=null) => {
    console.log('save project')
    const versionId = editorReducerState.editorSettings.versionId
    const projectId = editorReducerState.editorSettings.projectId


    let currentCanvasSettings = editorReducerState.editorState.canvasSettings;

    dispatch({
      type: 'changeCanvasData',
      payload: {
        breakpointToUse: 'desktop',
        backgroundColor: currentCanvasSettings.currentCanvasBackgroundColor,
        canvasHeight: currentCanvasSettings.canvasHeight,
        currentAlignment: currentCanvasSettings.currentAlignment,
        style: currentCanvasSettings.style,
      }
    })


    let projectData = {
      ...await projectSerializer.serializeProject()
    }

    User.saveProject(projectData, projectId, versionId).then(response => {
      console.log('success!', response)

      // we set to local storage here so we can persist after a reload
      window.localStorage.setItem('versionId', response.data.versionId);
      window.localStorage.setItem('projectId', response.data.projectId);

      dispatch({
        type: 'setProjectAndVersionIds',
        payload: {
          versionId: response.data.versionId,
          projectId: response.data.projectId
        }
      })

      if(showToast) {
        toast.success('Project saved successfully!')
      }
      console.log('project saved successfully!')

      if(callback) {
        callback()
      }
    }).catch(handleNetworkError)
  }

  const _generateNewIdsForChildren = (children) => {
    // loop through all children and sub children and generate new IDs
    if (!children || children.length === 0) {
      return [];
    }

    let modifiedChildren = [];
    for (let idx in children) {
      let newGeneratedId = generateId();
      let newChild =
        {
          ...children[idx],
          id: newGeneratedId,
          baseData: {
            ...children[idx]?.baseData,
            key: newGeneratedId,
            editorId: newGeneratedId
          },
          props: {
            ...children[idx].props,
            key: newGeneratedId,
            editorId: newGeneratedId
          }
        };

      newChild.children = _generateNewIdsForChildren(newChild.children);

      modifiedChildren.push(newChild);
    }

    return modifiedChildren
  }

  const pasteFromClipboard = (userClipboard) => {
    console.log('paste from clipboard', userClipboard)

    if (userClipboard) {
      let modifiedClipboard = {...userClipboard, children: _generateNewIdsForChildren(userClipboard.children)}
      dispatch({
        type: 'createAsset',
        payload: {
          parentElem: treeActions.current.getEditorRoot(),
          elemToCreate: {...modifiedClipboard, id: generateId()}
        }
      })
    }
  }

  const handleZoom = (e) => {
    if (e.ctrlKey) {
      e.preventDefault();

      // discern between mouse wheel up and mouse wheel down
      if(e.deltaY < 0) {
        dispatch({type: 'zoomIn'})
      }
      else if(e.deltaY > 0) {
        dispatch({type: 'zoomOut'})
      }

    }
  }

  const changeAllTreeElementPropsToCanvasSize = (children, newCanvasSize) => {
    if(children.length === 0) {
      return children;
    }

    let newChildrenElems = [];
    for(let childElem of children) {
      // change the props to the corresponding canvas size
      childElem.props = {
        ...childElem.baseData,
        ...childElem.breakpointData[newCanvasSize],
        style: {
          ...childElem.breakpointData[newCanvasSize].style,
          display: childElem?.baseData?.breakpoints?.visibility?.[newCanvasSize] ? 'flex' : 'none',
        }
      }

      childElem.children = changeAllTreeElementPropsToCanvasSize(
        childElem.children,
        newCanvasSize
      );

      newChildrenElems.push(childElem);
    }

    return newChildrenElems;
  }

  const moveAssetInOrder = (elementEditorId, relativeToIndex) => {
    dispatch({
      type: 'moveAssetInOrder',
      payload: {
        elementEditorId,
        relativeToIndex
      }
    })
  }

  const moveUpInOrder = (elementEditorId) => {
    moveAssetInOrder(elementEditorId, -1);
  }

  const moveDownInOrder = (elementEditorId) => {
    moveAssetInOrder(elementEditorId, 1);
  }

  const moveToStartInOrder = (elementEditorId) => {
    let elementTreeParent = treeActions.current.findParentInTree(elementEditorId);

    let selfSiblingIndex = elementTreeParent.children.findIndex(
      elem => elem.id === elementEditorId
    );

    moveAssetInOrder(elementEditorId, -selfSiblingIndex);
  }

  const moveToEndInOrder = (elementEditorId) => {
    let elementTreeParent = treeActions.current.findParentInTree(elementEditorId);

    let selfSiblingIndex = elementTreeParent.children.findIndex(
      elem => elem.id === elementEditorId
    );

    moveAssetInOrder(elementEditorId, (elementTreeParent.children.length - 1) - selfSiblingIndex);
  }

  const applyStylesToCanvas = (canvasSettings) => {
    const {
      breakpointToUse=editorReducerState.editorState.canvasSettings.currentCanvasBreakpoint,
      backgroundColor=editorReducerState.editorState.canvasSettings.backgroundColor,
      canvasHeight=editorReducerState.editorState.canvasSettings.canvasHeight,
      currentAlignment=editorReducerState.editorState.canvasSettings.currentAlignment,
      style=editorReducerState.editorState.canvasSettings.style
    } = canvasSettings;

    const canvasNode = document.getElementById('editor-root');
    const canvasInner = document.getElementById('canvas-inner');

    let parsedCanvasHeight = parseInt(canvasHeight.replace(/\D/g,''));

    let resultCanvasHeight;
    let resultCanvasWidth;

    switch(breakpointToUse) {
      case "desktop":
        canvasNode.style.width = '1920px';
        canvasNode.style.height = canvasHeight || '1080px';

        resultCanvasHeight = parsedCanvasHeight || 1080;
        resultCanvasWidth = 1920;

        break;
      case "tablet":
        canvasNode.style.width = '760px';
        canvasNode.style.height = canvasHeight || '1024px';

        resultCanvasHeight = parsedCanvasHeight || 1024;
        resultCanvasWidth = 760;

        break;
      case "mobile":
        canvasNode.style.width = '360px';
        canvasNode.style.height = canvasHeight || '740px';

        resultCanvasHeight = parsedCanvasHeight || 740;
        resultCanvasWidth = 360;

        break;
    }

    if(canvasHeight) {
      // TODO: we need to fix canvas heights made without a 'px'
      canvasInner.style.height = parseInt(canvasHeight.split('px')[0]) + 400 + 'px'
    }

    console.log('result canvas', {
      resultCanvasHeight,
      resultCanvasWidth
    })

    return {
      resultCanvasHeight,
      resultCanvasWidth
    }
  }

  // TODO: we want to delete all references to state here, this should be hardcoded values only
  let editorContext = {
    actionStack: [],
    redoStack: [],
    editorSettings: {
      // how close to the center you have to be to trigger the grid appearing
      gridVerticalOffset: 60,
      gridHorizontalOffset: 60,
      globalDragDisable: {
        value: false,
      },
      zoomLevel: 1.0,
      versionId: location?.state?.version?.id,
      projectId: location?.state?.projectId,
      templateId: location?.state?.templateId
    },
    editorState: {
      // "desktop", "mobile", or "tablet"
      canvasSettings: {
        currentCanvasBreakpoint: 'desktop',
        currentCanvasBackgroundColor: '#FFFFFF',
        currentAlignment: {alignment: 'top-left', flexDirection: 'column'},
        canvasHeight: '1080px',
        style: {}
      },
      themeSettings: {...DEFAULT_THEME},
      defaults: {
        tree: {...DEFAULT_TREE},
        // use corresponding tag names (NOT layer labels) for this
        validContainerElements: ['ContainerDraggable', 'FormDraggable']
      },
      assetCounts: {

      },
      // an object containing all of the style information for a class
      currentClasses: {},
      layerLabelsInUse: [],
      userIsTyping: false,
      lockedAssets: [],
      userClipboard: null,
      showAssetPanel: false,
      assetPanelSelectedAsset: null,
      currentTab: null,
      showJoyrideOverride: false,
      savedComponentCategories: {},
      layoutCategories: {},
      publicComponentCategories: {},
      currentProjectData: {
        value: currentProjectData,
        set: setCurrentProjectData
      },
      elementBeingDraggedState: {
        value: elementBeingDraggedState,
        set: setElementBeingDraggedState
      },
      elementSelected: {
        value: elementSelected,
        data: {},
        currentBounds: elementSelectedBounds,
        setCurrentBounds: setElementSelectedBounds,
        set: setElementSelected
      },
      editorBounds: editorBounds,
      // used to "snap" elements vertically
      editorVerticalCenterWithOffset: editorVerticalCenterWithOffset,
      // used to "snap" elements horizontally
      editorHorizontalCenterWithOffset: editorHorizontalCenterWithOffset,
      editorNode: editorNode,
      editorPages: {
        value: pageData,
        set: setPageData,
        selectedPage: pageData[0].labelId,
      },

      editorVerticalCenter: editorCenters ? editorCenters.vertical : null,
      editorHorizontalCenter: editorCenters ? editorCenters.horizontal : null,
      elementTree: {
        // element tree functions
        actions: {
          ...initializeElementTreeActions({...treeElements}),
        },

        // the element tree, every element created will be in this tree
        value: {...DEFAULT_TREE},
      }
    },
    editorActions: {
      renderTree,
      renderGridLine,
      isElementSelected,
      insertElement,
      copyFromTree,
      pasteFromClipboard,
      measureElementBounds,
      selectNewAsset,
      findElementInTree,
      isElementAContainer,
      generateNewAssetLayerLabel,
      saveProject,
      saveComponentToLibrary,
      moveAssetInOrder,
      moveUpInOrder,
      moveDownInOrder,
      moveToStartInOrder,
      moveToEndInOrder
    }
  };

  let reducerInitialState = cloneDeep(editorContext);

  const editorReducer = useCallback((state, action) => {
    switch (action.type) {
      case 'redo': {
        if(state.redoStack.length === 0) {
          return state;
        }

        let actionToRedo = state.redoStack[state.redoStack.length - 1];

        state.redoStack.splice(state.redoStack.length - 1, 1)

        state.actionStack.push(actionToRedo)

        switch (actionToRedo.action) {
          case 'editorRootChange': {
            let return_val = {
              ...state,
              editorState: {
                ...state.editorState,
                elementTree: {
                  ...state.editorState.elementTree,
                  value: {
                    'editor-root': actionToRedo.newRoot
                  }
                }
              }
            }

            return return_val;
          }
          case 'createComponent': {
            const {parentElem, elementToCreate, selectComponentAfterCreation=true} = actionToRedo;

            let currentCanvasBreakpoint = state.editorState.canvasSettings.currentCanvasBreakpoint;

            let foundAssetConfigElem = Object.values(assetConfig).find(
              elem => elem.tagName === elementToCreate.tagName
            )

            // then get it's editor id
            let topMostElemEditorId = treeActions.current.getNodeEditorId(parentElem);

            // exit if the icon was dragged out of the editor
            if (!topMostElemEditorId) return;

            // find in the element tree and append the node this icon represents to the editor
            let foundParentTreeElem = treeActions.current.findElementInTree(
              topMostElemEditorId,
              state.editorState.elementTree.value['editor-root']
            );

            let newElemName = generateNewAssetLayerLabel(
              elementToCreate.baseData.layerLabel || elementToCreate.baseData.baseLayerLabel,
              elementToCreate.tagName,
              0,
              state.editorState.layerLabelsInUse,
              true
            )

            let modifiedChildren = _generateNewIdsForChildren([...elementToCreate.children]);

            let labelsInUse = [...state.editorState.layerLabelsInUse, newElemName];

            let res = recursivelyRenameChildren(modifiedChildren, labelsInUse);
            let renamedChildren = res.data;
            labelsInUse = res.layerLabelsInUse;

            // look at what's selected and either paste into it, or paste into it's nearest parent
            // that is a valid container
            let elemSelectedId = elementToCreate.baseData.editorId;

            let elemToInsertInto = foundParentTreeElem
            let editorRoot = state.editorState.elementTree.value['editor-root']

            let maxDepth = 20;
            let count = 0;
            while (!isElementAContainer(elemToInsertInto)) {
              if(!elemToInsertInto?.id || count > maxDepth) {
                elemToInsertInto = editorRoot;
                break;
              }

              elemToInsertInto = treeActions.current.findParentInTree(elemToInsertInto?.id);

              count++;
            }

            let newElemId = generateId();

            let modifiedElem = {
              ...elementToCreate,
              props: {
                ...elementToCreate.props,
                ...elementToCreate.breakpointData[currentCanvasBreakpoint],
                layerLabel: newElemName,
              },
              baseData: {
                ...elementToCreate.baseData,
                layerLabel: newElemName,
                isComponent: true,
                editorId: newElemId
              },
              id: newElemId,
              children: renamedChildren
            }

            if(currentCanvasBreakpoint !== 'desktop') {
              let modifiedChildren = changeAllTreeElementPropsToCanvasSize(modifiedElem.children, currentCanvasBreakpoint);
              modifiedElem.children = modifiedChildren;
            }

            console.log('modified elem', modifiedElem)

            let newTreeElements = treeActions.current.addToTree(
              elemToInsertInto,
              modifiedElem,
              undefined,
              state.editorState.elementTree.value['editor-root'],
            );

            let return_val = {
              ...state,
              editorState: {
                ...state.editorState,
                layerLabelsInUse: [...labelsInUse, newElemName],
                elementTree: {
                  ...state.editorState.elementTree,
                  value: {
                    "editor-root": newTreeElements
                  }
                }
              }
            }

            if(selectComponentAfterCreation) {
              return_val = {
                ...return_val,
                editorState: {
                  ...return_val.editorState,
                  elementSelected: {
                    ...return_val.editorState.elementSelected,
                    value: newElemId,
                    data: modifiedElem
                  }
                }
              }
            }

            return_val.actionStack.push({
              action: 'createComponent',
              elementId: newElemId,
              layerLabel: newElemName,
              elementToCreate: elementToCreate,
              parentElem: parentElem,
              selectAssetAfterCreation: selectComponentAfterCreation,
              //state_diff: state_diff
            });

            console.log(`reducer action "${action.type}" was called`, {state, action, return_val})
            return return_val;
          }
          case 'createAsset': {
            let return_val = createAsset(state, treeActions, actionToRedo.parentElem, actionToRedo.elementToCreate, actionToRedo.selectAssetAfterCreation);

            console.log('asset redone and created')

            return return_val;
          }
          case 'replaceAsset': {
            const {originalElem, element} = actionToRedo;

            let return_val = replaceAsset(state, treeActions, originalElem.id, element);

            return return_val
          }
          case 'insertAsset': {
            const {toBeInsertedEditorId, parentEditorId, originalParent, originalIndex} = actionToRedo;

            console.log('redo insert')

            let return_val = insertAsset(state, treeActions, zoomLevel, toBeInsertedEditorId, parentEditorId);

            return return_val;
          }
        }
      }

      case 'undo': {
        if(state.actionStack.length === 0) {
          return state;
        }

        // get the first action on the state.actionStack
        // apply the inverse of that action to the state
        let actionToUndo = state.actionStack[state.actionStack.length - 1];

        // remove action from stack
        state.actionStack.splice(state.actionStack.length - 1, 1)

        // add action to the redo stack
        state.redoStack.push(actionToUndo)

        switch (actionToUndo.action) {
          case 'editorRootChange': {
            let return_val = {
              ...state,
              editorState: {
                ...state.editorState,
                elementTree: {
                  ...state.editorState.elementTree,
                  value: {
                    'editor-root': actionToUndo.originalRoot
                  }
                }
              }
            }

            return return_val;
          }
          case 'createComponent': {
            let elemToDelete = treeActions.current.findElementInTree(actionToUndo.elementId);

            let newElementTree = treeActions.current.deleteFromTree(
              elemToDelete,
              state.editorState.elementTree.value['editor-root']
            );

            let elementSelected = state.editorState.elementSelected.value === actionToUndo.elementId;

            let elementSelectedObj = {};
            if (elementSelected === null) {
              elementSelectedObj = {
                ...state.editorState.elementSelected,
                value: null,
                data: {},
                bounds: null
              }
            } else {
              elementSelectedObj = {
                ...state.editorState.elementSelected,
              }
            }

            // Delete the asset's layer label from the list of labels in use
            let newLayerLabelsInUse = [
              ...state.editorState.layerLabelsInUse.filter(
                label => label !== actionToUndo.layerLabel
              )
            ];

            // Create a new state with the asset removed
            let newState = {
              ...state,
              editorState: {
                ...state.editorState,
                elementTree: {
                  ...state.editorState.elementTree,
                  value: {
                    'editor-root': newElementTree
                  },
                  actions: {
                    ...state.editorState.elementTree.actions,
                    ...initializeElementTreeActions(newElementTree)
                  }
                },
                elementSelected: {
                  ...elementSelectedObj
                },
                layerLabelsInUse: newLayerLabelsInUse
                // Ensure to update any other part of the state affected by createAsset
              }
            };

            return newState;
          }

          case 'createAsset': {
            let elemToDelete = treeActions.current.findElementInTree(actionToUndo.elementId);

            let newElementTree = treeActions.current.deleteFromTree(
              elemToDelete,
              state.editorState.elementTree.value['editor-root']
            );

            let elementSelected = state.editorState.elementSelected.value === actionToUndo.elementId
              ? null
              : state.editorState.elementSelected;

            let elementSelectedObj = {};
            if (elementSelected === null) {
              elementSelectedObj = {
                ...state.editorState.elementSelected,
                value: null,
                data: {},
                bounds: null
              }
            } else {
              elementSelectedObj = {
                ...state.editorState.elementSelected,
              }
            }

            // Delete the asset's layer label from the list of labels in use
            let newLayerLabelsInUse = [
              ...state.editorState.layerLabelsInUse.filter(
                label => label !== actionToUndo.layerLabel
              )
            ];

            // Create a new state with the asset removed
            let newState = {
              ...state,
              editorState: {
                ...state.editorState,
                elementTree: {
                  ...state.editorState.elementTree,
                  value: {
                    'editor-root': newElementTree
                  },
                  actions: {
                    ...state.editorState.elementTree.actions,
                    ...initializeElementTreeActions(newElementTree)
                  }
                },
                elementSelected: {
                  ...elementSelectedObj
                },
                layerLabelsInUse: newLayerLabelsInUse
                // Ensure to update any other part of the state affected by createAsset
              }
            };

            return newState;
          }
          case 'replaceAsset': {
            const {originalElem, element} = actionToUndo;

            let elementId = element.id;

            let newElementTree = treeActions.current.replaceElementInTree(
              elementId,
              originalElem,
              state.editorState.elementTree.value['editor-root']
            );

            let newLayerLabelsInUse = [
              ...state.editorState.layerLabelsInUse.filter(label => label !== originalElem.props.layerLabel),
              originalElem?.props?.layerLabel
            ];

            let elementSelectedObj = state.editorState.elementSelected.value === elementId
              ? { ...state.editorState.elementSelected, value: originalElem.id, data: originalElem }
              : { ...state.editorState.elementSelected };

            let newState = {
              ...state,
              editorState: {
                ...state.editorState,
                elementSelected: elementSelectedObj,
                layerLabelsInUse: newLayerLabelsInUse,
                elementTree: {
                  ...state.editorState.elementTree,
                  value: {'editor-root': newElementTree}
                },
              }
            };

            return newState;
          }
          case 'insertAsset': {
            const {toBeInsertedEditorId, parentEditorId, originalParent, originalIndex} = actionToUndo;

            console.log('remove self from elem', {toBeInsertedEditorId, parentEditorId});
            const rootTreeElem = state.editorState.elementTree.value['editor-root'];

            // Find the parent and the element to be removed in the element tree
            let originalParentElem = originalParent === 'editor-root' ? rootTreeElem : treeActions.current.findElementInTree(originalParent, rootTreeElem);

            let toBeRemovedTreeElem = treeActions.current.findElementInTree(toBeInsertedEditorId, rootTreeElem);

            if (!toBeRemovedTreeElem) {
              return state; // Return the unchanged state if the element to be removed doesn't exist
            }

            // Remove the element from its parent
            let updatedTree = treeActions.current.deleteFromTree(toBeRemovedTreeElem, rootTreeElem);

            // Insert the element back into the tree under it's original parent
            let newUpdatedTree = treeActions.current.addToTree(originalParentElem, toBeRemovedTreeElem, originalIndex, updatedTree, 'desktop');

            // Update the element tree in the state
            let return_val = {
              ...state,
              editorState: {
                ...state.editorState,
                elementTree: {
                  ...state.editorState.elementTree,
                  value: {'editor-root': newUpdatedTree}
                }
              }
            };

            return return_val;
          }
          default:
            return state; // For actions not handled, return the current state
        }
      }

      case 'moveAssetInOrder': {
        const { elementEditorId, relativeToIndex } = action.payload;

        // relativeToIndex needs a number that represents how much to move this asset in the order
        // a value of 1 means move it down in the order by 1, conversely, a value of -1 means move it up

        // now we have the index of the label being dragged (selfSiblingIndex) and the index of the
        // sibling to be replaced. We want to edit the actual editor tree now and simply re-arrange the parent's
        // children

        // use the editorId of the element this label corresponds to to find that element's parent so we can
        // get its children array to rearrange

        let elementTreeParent = treeActions.current.findParentInTree(elementEditorId);

        let selfSiblingIndex = elementTreeParent.children.findIndex(
          elem => elem.id === elementEditorId
        );

        // clone the parent...
        let newElementTreeParent = {...elementTreeParent};

        // re-arrange the children using arrayMoveImmutable to avoid mutating the original parent
        newElementTreeParent.children = arrayMoveImmutable(
          elementTreeParent.children,
          selfSiblingIndex,
          selfSiblingIndex + relativeToIndex
        );

        let return_val = {...state};

        // let elemToMove = newElementTreeParent.children.splice(selfSiblingIndex)[0];
        // newElementTreeParent.children.splice(closestSiblingIndex, 0, elemToMove);
        if (newElementTreeParent.id === 'editor-root') {
          // we're editing top level elements, we can't replace the editor root
          // so we need to set it differently

          return_val = {
            ...state,
            editorState: {
              ...state.editorState,
              elementTree: {
                value: {'editor-root': newElementTreeParent}
              }
            }
          };

          return_val.actionStack.push({
            action: 'editorRootChange',
            originalRoot: state.editorState.elementTree.value['editor-root'],
            newRoot: newElementTreeParent,
            //state_diff: state_diff
          });
        } else {
          return_val = replaceAsset(state, treeActions, newElementTreeParent.id, newElementTreeParent);
        }

        console.log(`reducer action "${action.type}" was called`, {state, action, return_val});
        return return_val;
      }

      case 'selectAssetById': {
        const {editorId} = action.payload;

        if(state.editorState.lockedAssets.includes(editorId)) return state;

        let bounds;
        if(editorId) {
          bounds = measureElementBounds(editorId, true);
        }
        else {
          bounds = null
        }

        let element = treeActions.current.findElementInTree(editorId);

        let return_val = {
          ...state,
          editorState: {
            ...state.editorState,
            userIsTyping: false,
            currentTab: 'layers',
            elementSelected: {
              ...state.editorState.elementSelected,
              value: editorId,
              data: element,
              currentBounds: bounds
            }
          }
        };

        console.log(`reducer action "${action.type}" was called`, {state, action, return_val})
        return return_val
      }

      case 'replaceAsset': {
        const {originalElemId, newTreeElem} = action.payload;
        console.log('reducer replace asset called', {action})

        let return_val = replaceAsset(state, treeActions, originalElemId, newTreeElem);

        console.log(`reducer action "${action.type}" was called`, {state, action, return_val})
        return return_val;
      }

      case 'deleteAsset': {
        const {editorId} = action.payload;

        const rootTreeElem = state.editorState.elementTree.value['editor-root'];

        let searchResult = treeActions.current.findElementInTree(editorId, rootTreeElem);

        let originalElemName = searchResult?.baseData?.layerLabel

        //let originalElemName = searchResult?.props.layerLabel;
        let labelsInUse = state.editorState.layerLabelsInUse;

        if(searchResult?.children) {
          let res = recursivelyDeleteAssetLabels(searchResult.children, labelsInUse);
          labelsInUse = res.newLabelsInUse;
        }

        let newElementTree = treeActions.current.deleteFromTree(searchResult, rootTreeElem);

        let return_val = {
          ...state,
          editorState: {
            ...state.editorState,
            // seems to work better if we just only increment
            // assetCounts: {
            //   ...generateNewAssetCounts('subtract', originalElemName, state)
            // },
            layerLabelsInUse: [
              ...labelsInUse.filter(
                layerLabel => layerLabel !== originalElemName
            )],
            elementSelected: {
              ...state.editorState.elementSelected,
              data: {},
              value: null,
              currentBounds: null
            },
            elementTree: {
              ...state.editorState.elementTree,
              value: {'editor-root': newElementTree}
            }
          }
        };

        return return_val;
      }

      case 'changeCanvasData': {
        const {
          breakpointToUse=state.editorState.canvasSettings.currentCanvasBreakpoint,
          backgroundColor=state.editorState.canvasSettings.backgroundColor,
          canvasHeight=state.editorState.canvasSettings.canvasHeight,
          currentAlignment=state.editorState.canvasSettings.currentAlignment,
          style=state.editorState.canvasSettings.style
        } = action.payload;

        let {
          resultCanvasHeight,
          resultCanvasWidth,
        } = applyStylesToCanvas(action.payload);

        let newElementTree = {...state.editorState.elementTree};

        // only do these if the canvas size changes
        if(breakpointToUse !== state.editorState.canvasSettings.currentCanvasBreakpoint) {
          // recursively change all props on all children
          let newChildren = changeAllTreeElementPropsToCanvasSize(
            newElementTree.value['editor-root'].children,
            breakpointToUse
          );

          newElementTree.value['editor-root'].children = newChildren;
          newElementTree.value['editor-root'].props.style = {
            ...style
          }

          setTree(renderTree(newElementTree.value))
        }

        const selectedPageId = state.editorState.editorPages.selectedPage;
        const foundPage = state.editorState.editorPages.value.find(
          elem => elem.labelId === selectedPageId
        );

        const newCanvasSettings = {
          currentCanvasBreakpoint: breakpointToUse,
          currentCanvasBackgroundColor: backgroundColor,
          canvasHeight: canvasHeight,
          currentAlignment,
          style
        };

        foundPage.canvasSettings = {
          ...newCanvasSettings
        }

        let formattedEditorBounds = {
          height: resultCanvasHeight,
          width: resultCanvasWidth,
          right: resultCanvasWidth,
          left: 0,
          top: 0,
          bottom: resultCanvasHeight
        };

        return {
          ...state,
          editorState: {
            ...state.editorState,
            elementTree: newElementTree,
            canvasSettings: newCanvasSettings,
            editorBounds: formattedEditorBounds
          }
        };
      }

      case 'moveAssetToParent': {
        const {editorId} = action.payload;

        // can we rewrite this to switch an element to another target element rather than it's parent?

        const rootTreeElem = state.editorState.elementTree.value['editor-root'];

        let parent = treeActions.current.findParentInTree(editorId, rootTreeElem)

        let parentsParent = treeActions.current.findParentInTree(parent.id, rootTreeElem)

        let elem = treeActions.current.findElementInTree(editorId, rootTreeElem);

        let newParent = {
          ...parent,
          children: [...parent.children.filter(elem => elem.id !== editorId)]
        }

        let newParentsParent;
        let newElementTree;

        if(parentsParent.id === 'editor-root') {
          // if the parent is the editor root, we need to find and then edit the element
          // we're moving so that it can be given position absolute
          let newElem = {
            ...elem,
            props: {
              ...elem.props,
              style: {
                ...elem.props.style,
                //position: 'absolute'
              }
            }
          }

          newParentsParent = {
            ...parentsParent,
            children: [
              ...parentsParent.children.filter(elem => elem.id !== parent.id),
              newParent,
              newElem
            ]
          }

          newElementTree = newParentsParent;
        }
        else {
          newParentsParent = {
            ...parentsParent,
            children: [
              ...parentsParent.children.filter(elem => elem.id !== parent.id),
              newParent,
              elem
            ]
          }

          newElementTree = treeActions.current.replaceElementInTree(
            parentsParent.id,
            newParentsParent,
            rootTreeElem
          );
        }


        let return_val = {
          ...state,
          editorState: {
            ...state.editorState,
            elementTree: {
              ...state.editorState.elementTree,
              value: {'editor-root': newElementTree}
            }
          }
        }

        console.log(`reducer action "${action.type}" was called`, {state, action, return_val})

        return return_val
      }

      case 'insertAsset': {
        const {toBeInsertedEditorId, parentEditorId} = action.payload;

        let return_val = insertAsset(state, treeActions, zoomLevel, toBeInsertedEditorId, parentEditorId);

        return return_val;
      }

      case 'addLabelInUse':
        const {labelToAdd} = action.payload;

        let return_val = {
          ...state,
          editorState: {
            ...state.editorState,
            layerLabelsInUse: [
              ...state.editorState.layerLabelsInUse,
              labelToAdd
            ]
          }
        }

        console.log(`reducer action "${action.type}" was called`, {state, action, return_val})

        return return_val;

      case 'addPage': {

        let newEditorPages = [...state.editorState.editorPages.value];

        let pageCount = 0;
        for(let i of newEditorPages) {
          if(i.tree !== undefined) {
            pageCount++;
          }
        }

        newEditorPages.push({
          pageName: `Page ${pageCount + 1}`,
          route: `/page${pageCount + 1}`,
          // TODO: rename this to pageId? it's being used in more than just labels
          labelId: `Page-${generateId()}`,
          tree: cloneDeep(DEFAULT_TREE),
          layerLabelsInUse: [],
          canvasSettings: {
            currentCanvasBreakpoint: 'desktop',
            currentCanvasBackgroundColor: '#FFFFFF',
            currentAlignment: {
              alignment: 'top-left',
              flexDirection: 'column'
            },
            canvasHeight: '1080px',
            style: {}
          },
          ref: null
        })

        let return_val = {
          ...state,
          editorState: {
            ...state.editorState,
            editorPages: {
              ...state.editorState.editorPages,
              value: [
                ...newEditorPages
              ]
            }
          }
        }

        console.log(`reducer action "${action.type}" was called`, {state, action, return_val})

        return return_val;
      }

      case 'setPages': {
        const {newPages} = action.payload;

        console.log('new pages disaptch test', newPages)

        let return_val = {
          ...state,
          editorState: {
            ...state.editorState,
            editorPages: {
              ...state.editorState.editorPages,
              value: [
                ...newPages
              ]
            }
          }
        }

        console.log(`reducer action "${action.type}" was called`, {state, action, return_val})

        return return_val;
      }

      case 'changePage': {
        const {newSelectedPageLabelId} = action.payload;

        let newPages = [...state.editorState.editorPages.value];

        let currentSelectedPage = state.editorState.editorPages.selectedPage;

        let oldPage = newPages.find(elem => elem.labelId === currentSelectedPage);

        // page might not be found if it's deleted
        if(oldPage) {
          oldPage.canvasSettings.currentCanvasBreakpoint = 'desktop';

          let oldPageChildren = changeAllTreeElementPropsToCanvasSize(
            state.editorState.elementTree.value['editor-root'].children,
            'desktop'
          );

          oldPage.tree['editor-root'].children = oldPageChildren;
          oldPage.layerLabelsInUse = [...state.editorState.layerLabelsInUse];
        }

        let foundPage = newPages.find(elem => elem.labelId === newSelectedPageLabelId);

        // make old version compatible
        if(!foundPage?.layerLabelsInUse) {
          foundPage.layerLabelsInUse = [];
        }

        let foundPageCanvasSettings = {
          breakpointToUse: 'desktop',
          backgroundColor: foundPage.canvasSettings.currentCanvasBackgroundColor,
          canvasHeight: foundPage.canvasSettings.canvasHeight,
          currentAlignment: foundPage.canvasSettings.currentAlignment,
          style: foundPage.canvasSettings.style
        };

        applyStylesToCanvas(foundPageCanvasSettings);

        let tree = {...foundPage.tree};
        let newChildren = [...foundPage.tree['editor-root'].children];

        if('desktop' !== state.editorState.canvasSettings.currentCanvasBreakpoint) {
          console.log('change page and apply breakpoint styles')

          // recursively change all props on all children
          newChildren = changeAllTreeElementPropsToCanvasSize(
            foundPage.tree['editor-root'].children,
            'desktop'
          );

          tree['editor-root'].children = newChildren;

          setTree(renderTree(tree))
        }

        if(!foundPage) return state;

        let return_val = {
          ...state,
          editorState: {
            ...state.editorState,
            layerLabelsInUse: [...foundPage.layerLabelsInUse],
            editorPages: {
              ...state.editorState.editorPages,
              value: newPages,
              selectedPage: foundPage.labelId,
            },
            canvasSettings: {
              ...foundPageCanvasSettings,
              currentCanvasBreakpoint: 'desktop',
            },
            elementSelected: {
              ...state.editorState.elementSelected,
              data: {},
              value: null,
              currentBounds: null
            },
            elementTree: {
              ...state.editorState.elementTree,
              value: {
                ...tree
              }
            }
          }
        }

        console.log(`reducer action "${action.type}" was called`, {state, action, return_val})

        return return_val;
      }

      case 'changeAssetChildren': {
        const {editorId, newChildren} = action.payload;

        let element = treeActions.current.findElementInTree(
          editorId,
          state.editorState.elementTree.value['editor-root']
        );

        element.children = [...newChildren];

        return state
      }

      case 'createAsset': {
        const {parentElem, elementToCreate, selectAssetAfterCreation=true} = action.payload;

        let return_val = createAsset(state, treeActions, parentElem, elementToCreate, selectAssetAfterCreation);

        console.log(`reducer action "${action.type}" was called`, {state, action, return_val})
        return return_val
      }

      case 'updateElementSelectedBounds': {
        const {bounds} = action.payload;

        let return_val = {
          ...state,
          editorState: {
            ...state.editorState,
            elementSelected: {
              ...state.editorState.elementSelected,
              currentBounds: bounds
            }
          }
        };

        return return_val;
      }

      case 'zoomOut': {
        // check if we hit the max zoom
        if(state.editorSettings.zoomLevel <= 0.2) {
          return state;
        }

        let newZoomLevel = parseFloat((state.editorSettings.zoomLevel - 0.1).toFixed(1));

        let return_val = {
          ...state,
          editorSettings: {
            ...state.editorSettings,
            zoomLevel: newZoomLevel
          }
        }

        // sync with state so we can use this locally in this file
        zoomLevel.current = newZoomLevel;

        console.log(`reducer action "${action.type}" was called`, {state, action, return_val})
        return return_val;
      }

      case 'zoomIn': {
        if(state.editorSettings.zoomLevel >= 1.0) {
          return state;
        }

        let newZoomLevel = parseFloat((state.editorSettings.zoomLevel + 0.1).toFixed(1));

        let return_val = {
          ...state,
          editorSettings: {
            ...state.editorSettings,
            zoomLevel: newZoomLevel
          }
        }

        // sync with state so we can use this locally in this file
        zoomLevel.current = newZoomLevel;

        console.log(`reducer action "${action.type}" was called`, {state, action, return_val})
        return return_val;
      }

      case 'setZoomLevel' : {
        const {zoomLevel} = action.payload;

        let return_val = {
          ...state,
          editorSettings: {
            zoomLevel
          }
        }

        console.log(`reducer action "${action.type}" was called`, {state, action, return_val})
        return return_val;
      }

      case 'updateAssetBounds': {
        // TODO: can we refactor this to update any asset props?
        const {editorId, bounds, widthHasChanged=true, heightHasChanged=true} = action.payload;

        let elem = treeActions.current.findElementInTree(
          editorId,
          state.editorState.elementTree.value['editor-root']
        );

        let propsToAppend = {
            style: {
              ...elem.props.style,
              'width': widthHasChanged ? bounds.width + 'px' : elem.props.style.width,
              'height': heightHasChanged ? bounds.height + 'px' : elem.props.style.height,
              'left': bounds.x + 'px',
              'top': bounds.y + 'px',
            },
          };

        let elementTree = treeActions.current.appendToTreeElemProps(
          elem,
          propsToAppend,
          state.editorState.elementTree.value['editor-root']
        );

        let return_val = {
          ...state,
          editorState: {
            ...state.editorState,
            elementTree: {
              value: {'editor-root': elementTree}
            }
          }
        }

        return return_val;
      }

      case 'toggleAssetLock': {
        const {editorId} = action.payload;

        let newLockedAssets = [...state.editorState.lockedAssets];
        if (!newLockedAssets.includes(editorId)) {
          // elem is not locked, so lock it
          newLockedAssets.push(editorId);

          console.log('new locked assets', newLockedAssets)
        } else {
          // elem is locked, so unlock it
          let elemIndex = newLockedAssets.indexOf(editorId);
          newLockedAssets.splice(elemIndex, 1);

          console.log('new locked assets', newLockedAssets)
        }

        let return_val = {
          ...state,
          editorState: {
            ...state.editorState,
            lockedAssets: newLockedAssets,
            elementSelected: {
              ...state.editorState.elementSelected,
              data: {},
              value: null
            }
          }
        };

        console.log(`reducer action "${action.type}" was called`, {state, action, return_val})
        return return_val;
      }

      case 'setLayoutComponents': {
        const {layoutCategories} = action.payload;

        let return_val= {
          ...state,
          editorState: {
            ...state.editorState,
            layoutCategories: layoutCategories
          }
        }

        console.log(`reducer action "${action.type}" was called`, {state, action, return_val})

        return return_val;
      }

      case 'setPublicComponents': {
        const {componentCategories} = action.payload;

        let return_val = {
          ...state,
          editorState: {
            ...state.editorState,
            publicComponentCategories: componentCategories
          }
        }

        console.log(`reducer action "${action.type}" was called`, {state, action, return_val})

        return return_val;
      }

      case 'saveComponentCategories': {
        const {componentCategories} = action.payload;

        let return_val = {
          ...state,
          editorState: {
            ...state.editorState,
            savedComponentCategories: componentCategories
          }
        }

        console.log(`reducer action "${action.type}" was called`, {state, action, return_val})

        return return_val;
      }

      case 'createComponent': {
        const {parentElem, elementToCreate, selectComponentAfterCreation=true} = action.payload;

        let currentCanvasBreakpoint = state.editorState.canvasSettings.currentCanvasBreakpoint;

        let foundAssetConfigElem = Object.values(assetConfig).find(
          elem => elem.tagName === elementToCreate.tagName
        )

        // then get it's editor id
        let topMostElemEditorId = treeActions.current.getNodeEditorId(parentElem);

        // exit if the icon was dragged out of the editor
        if (!topMostElemEditorId) return;

        // find in the element tree and append the node this icon represents to the editor
        let foundParentTreeElem = treeActions.current.findElementInTree(
          topMostElemEditorId,
          state.editorState.elementTree.value['editor-root']
        );

        let newElemName = generateNewAssetLayerLabel(
          elementToCreate.baseData.layerLabel || elementToCreate.baseData.baseLayerLabel,
          elementToCreate.tagName,
          0,
          state.editorState.layerLabelsInUse,
          true
        )

        let modifiedChildren = _generateNewIdsForChildren([...elementToCreate.children]);

        let labelsInUse = [...state.editorState.layerLabelsInUse, newElemName];

        let res = recursivelyRenameChildren(modifiedChildren, labelsInUse);
        let renamedChildren = res.data;
        labelsInUse = res.layerLabelsInUse;

        // look at what's selected and either paste into it, or paste into it's nearest parent
        // that is a valid container
        let elemSelectedId = elementToCreate.baseData.editorId;

        let elemToInsertInto = foundParentTreeElem
        let editorRoot = state.editorState.elementTree.value['editor-root']

        let maxDepth = 20;
        let count = 0;
        while (!isElementAContainer(elemToInsertInto)) {
          if(!elemToInsertInto?.id || count > maxDepth) {
            elemToInsertInto = editorRoot;
            break;
          }

          elemToInsertInto = treeActions.current.findParentInTree(elemToInsertInto?.id);

          count++;
        }

        let newElemId = generateId();


        let modifiedElem = {
          ...elementToCreate,
          props: {
            ...elementToCreate.props,
            ...elementToCreate.breakpointData[currentCanvasBreakpoint],
            layerLabel: newElemName,
            editorId: newElemId
          },
          baseData: {
            ...elementToCreate.baseData,
            layerLabel: newElemName,
            isComponent: true,
            editorId: newElemId
          },
          id: newElemId,
          children: renamedChildren
        }

        if(currentCanvasBreakpoint !== 'desktop') {
          let modifiedChildren = changeAllTreeElementPropsToCanvasSize(modifiedElem.children, currentCanvasBreakpoint);
          modifiedElem.children = modifiedChildren;
        }

        console.log('modified elem', modifiedElem)

        let newTreeElements = treeActions.current.addToTree(
          elemToInsertInto,
          modifiedElem,
          undefined,
          state.editorState.elementTree.value['editor-root'],
        );

        let return_val = {
          ...state,
          editorState: {
            ...state.editorState,
            layerLabelsInUse: [...labelsInUse, newElemName],
            elementTree: {
              ...state.editorState.elementTree,
              value: {
                "editor-root": newTreeElements
              }
            }
          }
        }

        if(selectComponentAfterCreation) {
          return_val = {
            ...return_val,
            editorState: {
              ...return_val.editorState,
              elementSelected: {
                ...return_val.editorState.elementSelected,
                value: newElemId,
                data: modifiedElem
              }
            }
          }
        }

        return_val.actionStack.push({
          action: 'createComponent',
          elementId: newElemId,
          layerLabel: newElemName,
          elementToCreate: elementToCreate,
          parentElem: parentElem,
          selectComponentAfterCreation,
          //state_diff: state_diff
        });

        console.log(`reducer action "${action.type}" was called`, {state, action, return_val})
        return return_val;
      }

      case 'pasteClipboard': {
        if (!state.editorState.userClipboard) return state;

        let foundAssetConfigElem = Object.values(assetConfig).find(
          elem => elem.tagName === state.editorState.userClipboard.tagName
        )

        let newElemName = generateNewAssetLayerLabel(
          state.editorState.userClipboard?.baseData?.layerLabel || foundAssetConfigElem.sharedProps.layerLabel,
          foundAssetConfigElem.tagName,
          0,
          state.editorState.layerLabelsInUse
        )

        // look at what's selected and either paste into it, or paste into it's nearest parent
        // that is a valid container
        let elemSelectedId = state.editorState.elementSelected.value;

        let elemToInsertInto = treeActions.current.findElementInTree(elemSelectedId);
        let elemToInsertIntoDomNode = treeActions.current.getElementFromTree(elemSelectedId);

        let editorRoot = state.editorState.elementTree.value['editor-root']
        let labelsInUse = [...state.editorState.layerLabelsInUse, newElemName];

        let maxDepth = 20;
        let count = 0;
        while (!isElementAContainer(elemToInsertInto) || elemToInsertInto.id === state.editorState.userClipboard.id) {
          if(!elemToInsertInto?.id || count > maxDepth) {
            elemToInsertInto = editorRoot;
            break;
          }

          elemToInsertInto = treeActions.current.findParentInTree(elemToInsertInto?.id);
          elemToInsertIntoDomNode = treeActions.current.getElementFromTree(elemToInsertInto?.id)

          count++;
        }

        let modifiedChildren = _generateNewIdsForChildren([...state.editorState.userClipboard.children]);

        let res = recursivelyRenameChildren(modifiedChildren, labelsInUse, false);
        let renamedChildren = res.data;
        labelsInUse = res.layerLabelsInUse;

        let newElemId = generateId()

        let modifiedClipboard = {
          ...state.editorState.userClipboard,
          props: {
            ...state.editorState.userClipboard.props,
            style: {
              ...state.editorState.userClipboard.props.style,
              //position: 'absolute'
            },
            editorId: newElemId,
            key: newElemId,
            layerLabel: newElemName,
          },
          baseData: {
            ...state.editorState.userClipboard.baseData,
            key: newElemId,
            editorId: newElemId,
            layerLabel: newElemName,
          },
          id: newElemId,

          children: renamedChildren
        }

        let newTreeElements = treeActions.current.addToTree(
          elemToInsertInto,
          modifiedClipboard,
          undefined,
          state.editorState.elementTree.value['editor-root'],
        );

        let return_val = {
          ...state,
          editorState: {
            ...state.editorState,
            layerLabelsInUse: [...labelsInUse],
            elementTree: {
              ...state.editorState.elementTree,
              value: {
                "editor-root": newTreeElements
              }
            }
          }
        }

        return_val.actionStack.push({
          action: 'createAsset',
          elementId: newElemId,
          layerLabel: newElemName,
          elementToCreate: state.editorState.userClipboard,
          parentElem: elemToInsertIntoDomNode,
          selectAssetAfterCreation: true,
          //state_diff: state_diff
        });

        console.log(`reducer action "${action.type}" was called`, {state, action, return_val})
        return return_val;
      }

      case 'setUserClipboard': {
        const {clipboard} = action.payload;

        let return_val = {
          ...state,
          editorState: {
            ...state.editorState,
            userClipboard: clipboard
          }
        }

        console.log(`reducer action "${action.type}" was called`, {state, action, return_val})
        return return_val;
      }

      case 'setTree': {
        console.log('set tree', action.payload)
      }

      case 'setTreeActions': {
        const {...actions} = action.payload;

        console.log('set tree actions called reducer')

        let return_val = {
          ...state,
          editorState: {
            ...state.editorState,
            elementTree: {
              ...state.editorState.elementTree,
              actions: {
                ...state.editorState.elementTree.actions,
                ...initializeElementTreeActions(state.editorState.elementTree.value)
              }
            }
          }
        }

        return return_val;
      }

      case 'setEditorState': {
        // this can be a part of the editorState, can contain any number of matching keys
        const newEditorStatePartial = {...action.payload};

        let return_val = {
          ...state,
          editorState: {
            ...state.editorState,
            ...newEditorStatePartial
          }
        }

        console.log(`reducer action "${action.type}" was called`, {state, action, return_val})
        return return_val;
      }

      case 'renderGridline': {
        const {gridLineNode, gridLineType} = action.payload;

        state.editorState.editorNode.appendChild(gridLineNode);

        gridLineNode.classList.add(classes.gridLineStyle);
        gridLineNode.style.position = 'absolute'

        if(gridLineType === 'horizontal') {
          gridLineNode.style.height = `${(1/zoomLevel.current)}px`;
          gridLineNode.style.width = `${state.editorState.editorBounds.width}px`;
          gridLineNode.style.top = `${state.editorState.editorHorizontalCenter}px`;
          gridLineNode.style.left = `${state.editorState.editorBounds.left}px`
        }
        else {
          gridLineNode.style.height = `${state.editorState.editorBounds.height}px`;
          gridLineNode.style.width = `${(1/zoomLevel.current)}px`;
          gridLineNode.style.left = `${state.editorState.editorVerticalCenter}px`;
          gridLineNode.style.top = `${state.editorState.editorBounds.top}px`;
        }

        return state;
      }

      case 'setProjectAndVersionIds': {
        const {versionId, projectId} = action.payload;

        let return_val = {
          ...state,
          editorSettings: {
            ...state.editorSettings,
            versionId,
            projectId
          }
        }

        console.log(`reducer action "${action.type}" was called`, {state, action, return_val})
        return return_val;
      }

      case 'setTemplateId': {
        const {templateId} = action.payload;

        let return_val = {
          ...state,
          editorSettings: {
            ...state.editorSettings,
            templateId
          }
        }

        console.log(`reducer action "${action.type}" was called`, {state, action, return_val})
        return return_val;
      }

      case 'addClass': {
        const {classNameToAdd, classData, assetTagName} = action.payload;

        let return_val = {
          ...state,
          editorState: {
            ...state.editorState,
            currentClasses: {
              ...state.editorState.currentClasses,
              [classNameToAdd]: {
                assetTagName,
                data: {
                  ...classData
                }
              }
            },
          }
        };

        return return_val;
      }

      case 'renameProject': {
        const {name} = action.payload;

        let return_val = {
          ...state,
          editorState: {
            ...state.editorState,
            currentProjectData: {
              ...state.editorState.currentProjectData,
              value: {
                ...state.editorState.currentProjectData.value,
                name: name
              }
            }
          }
        }

        console.log(`reducer action "${action.type}" was called`, {state, action, return_val})
        return return_val;
      }

      case 'renamePage': {
        const {name, lastClickedPage} = action.payload;

        let newPageData = [...state.editorState.editorPages.value]
        let foundPage = newPageData.find(elem => elem.labelId === lastClickedPage)
        foundPage.pageName = name
        console.log('new page data', {newPageData, foundPage})

        let return_val = {
          ...state,
          editorState: {
            ...state.editorState,
            editorPages: {
              ...state.editorState.editorPages,
              value: newPageData
            }
          }
        }
        console.log(`reducer action "${action.type}" was called`, {state, action, return_val})
        return return_val
      }

      case 'deletePage': {
        const {lastClickedPage} = action.payload;

        let newPageData = [...state.editorState.editorPages.value]
        let foundPageIndex = newPageData.findIndex(elem => elem.labelId === lastClickedPage.labelId)
        newPageData.splice(foundPageIndex, 1)

        let selectedPage = {...state.editorState.editorPages.selectedPage}
        let elementTree = {...state.editorState.elementTree}

        if(lastClickedPage.labelId === selectedPage.labelId) {
          if(newPageData.length > 0) {
            selectedPage = newPageData[0]
            elementTree = {
              ...elementTree,
              value: {...newPageData[0].tree}
            }
          }
        }

        let return_val = {
          ...state,
          editorState: {
            ...state.editorState,
            editorPages: {
              ...state.editorState.editorPages,
              value: newPageData,
              selectedPage
            },
            elementTree
          }
        }
        console.log(`reducer action "${action.type}" was called`, {state, action, return_val})
        return return_val
      }

      case 'startJoyrideOverride': {
        let return_val = {
          ...state,
          editorState: {
           ...state.editorState,
           showJoyrideOverride: true
          }
        }

        console.log(`reducer action "${action.type}" was called`, {state, action, return_val})

        return return_val;
      }

      case 'endJoyrideOverride': {
        const {layerLabelsInUseBeforeJoyride} = action.payload;

        let return_val = {
          ...state,
          editorState: {
            ...state.editorState,
            showJoyrideOverride: false,
            layerLabelsInUse: [...layerLabelsInUseBeforeJoyride]
          }
        }

        console.log(`reducer action "${action.type}" was called`, {state, action, return_val})
        return return_val;
      }

      case 'setCurrentTab': {
        const {currentTab} = action.payload;

        let return_val = {
          ...state,
          editorState: {
            ...state.editorState,
            currentTab
          }
        }

        console.log(`reducer action "${action.type}" was called`, {state, action, return_val});

        return return_val;
      }

      case 'toggleAssetPanel': {
        const {showAssetPanel, assetPanelSelectedAsset} = action.payload;

        let return_val = {
          ...state,
          editorState: {
            ...state.editorState,
            showAssetPanel,
            assetPanelSelectedAsset
          }
        }

        console.log(`reducer action "${action.type}" was called`, {state, action, return_val})
        return return_val;
      }

      case 'setComponentCategories': {
        const {savedComponentCategories} = action.payload;

        let return_val = {
          ...state,
          editorState: {
            ...state.editorState,
            savedComponentCategories: {...savedComponentCategories},
            assetPanelSelectedAsset: null,
          }
        }

        console.log(`reducer action "${action.type}" was called`, {state, action, return_val})
        return return_val;
      }

      case 'changeTheme': {
        const {newTheme} = action.payload;

        let return_val = {
          ...state,
          editorState: {
            ...state.editorState,
            themeSettings: {
              ...state.editorState.themeSettings,
              ...newTheme
            }
          }
        }

        console.log(`reducer action "${action.type}" was called`, {state, action, return_val})
        return return_val;
      }

      default:
        return state
    }
  }, [])

  const [editorReducerState, dispatch] = useReducer(editorReducer, reducerInitialState);

  const {
    getComponentSerializer,
    takeScreenshotOfComponentAsync,
    recursivelySerializeChildren
  } = useSerializerUtils(editorReducerState);

  const projectSerializer = useProjectSerializer(editorReducerState, dispatch);

  const handleWindowClick = e => {
    // e.path gives a path of DOM elements from where the user clicked.
    // reverse the path so it goes from top to bottom instead of bottom to top
    // grab the first element with an editor id that is not the editor itself
    let clickedInsideEditor = false;

    // if the user clicks outside of the editor bounds, do nothing
    for (let elem of e.path) {
      try {
        if (
          elem.getAttribute('id') === 'editor-root'
          ||
          elem.getAttribute('id') === 'canvas-inner'
        ) {
          clickedInsideEditor = true;
          break;
        }
      } catch (error) {

      }
    }

    if (!clickedInsideEditor) {
      return;
    }

    let elementToBeSelected = getTopmostEditorElementFromPath(e.path);
    let elementToBeSelectedId = elementToBeSelected ? elementToBeSelected.getAttribute('data-editorid') : null;

    if (elementToBeSelectedId !== editorReducerState.editorState.elementSelected.value) {
      if (elementToBeSelected) {
        // check if the element is locked
        if (!editorReducerState.editorState.lockedAssets.includes(elementToBeSelectedId)) {
          const {top, right, bottom, left, width, height, x, y} = elementToBeSelected.getBoundingClientRect();
          let newBounds = {top, right, bottom, left, width, height, x, y};
          console.log('measure element', {elementToBeSelected, newBounds})

          // TODO: set bounds here as well
          selectNewAsset(elementToBeSelectedId);
        }
      } else {
        selectNewAsset(null);
      }
    }
  }

  useEffect(() => {
    // on render, set the editor bounds
    let editorNode = document.getElementById('editor-root');
    let zoomContainerNode = document.getElementById('zoom-container')

    let initEditorBounds = getEditorBounds();

    const horizontalOffset = editorReducerState.editorSettings.gridHorizontalOffset;
    const verticalOffset = editorReducerState.editorSettings.gridVerticalOffset;

    let editorHorizontalCenter = (initEditorBounds.top + (initEditorBounds.height / 2));
    let initEditorHorizontalCenterWithOffset = {
      top: editorHorizontalCenter - horizontalOffset / 2,
      bottom: editorHorizontalCenter + horizontalOffset / 2
    };

    let editorVerticalCenter = initEditorBounds.left + (initEditorBounds.width / 2);
    let initEditorVerticalCenterWithOffset = {
      left: editorVerticalCenter - verticalOffset / 2,
      right: editorVerticalCenter + verticalOffset / 2
    }

    console.log('gridline centers', {editorVerticalCenter, editorHorizontalCenter})

    // TODO: the grid lines are not rendering right, are these values correct?

    dispatch({
      type: 'setEditorState',
      payload: {
        editorVerticalCenterWithOffset: initEditorVerticalCenterWithOffset,
        editorHorizontalCenterWithOffset: initEditorHorizontalCenterWithOffset,
        editorVerticalCenter: editorVerticalCenter,
        editorHorizontalCenter: editorHorizontalCenter,
        editorBounds: initEditorBounds,
        editorNode: editorNode
      }
    });

    // used internally in editor.js
    setEditorNode(editorNode);
  }, [
    editorReducerState.editorSettings.gridHorizontalOffset,
    editorReducerState.editorSettings.gridVerticalOffset
  ]);

  // custom hook that registers the click event and handles it with optional dependencies
  // it will completely de-register and re-register the event every time any dependencies change
  useEventHandler('mousedown', handleWindowClick, [editorReducerState.editorState.elementSelected.value]);

  // can we write this more elegantly? we can't use "useEventHandler" because we need to target
  // a div, targeting document or window will not work
  useEffect(() => {
    document.getElementById('canvas-outer').addEventListener('wheel', handleZoom, true)

    return () => {
      document.getElementById('canvas-outer').removeEventListener('wheel', handleZoom, true)
    }
  }, [])

  useEffect(() => {
    console.log('reducer tree rendered', editorReducerState.editorState.elementTree.value)
    setTree(renderTree(editorReducerState.editorState.elementTree.value))
  }, [editorReducerState.editorState.elementTree.value])

  useEffect(() => {
    // TODO: not sure if this is needed, but we need a way to keep the state from getting stale
    //  is there a better way?
    console.log('set new tree actions')
    dispatch({
      type: 'setTreeActions', payload: {
        treeElements: editorReducerState.editorState.elementTree.value
      }
    })

    // TODO: this might not be a good idea
    // used locally
    treeActions.current = {
      ...initializeElementTreeActions(editorReducerState.editorState.elementTree.value)
    }
  }, [
    editorReducerState.editorState.elementTree.value
  ]);

  // load project
  useEffect(() => {
    console.log('editor props', {props, location})

    console.log('loading...', {
      localStorageProjectId: window.localStorage.getItem('projectId'),
      localStorageVersionId: window.localStorage.getItem('versionId'),
      oldProjectId: editorReducerState.editorSettings.projectId,
      oldVersionId: editorReducerState.editorSettings.versionId
    })

    // check if there is anything in local storage and use that first
    let projectId = window.localStorage.getItem('projectId')
      || editorReducerState.editorSettings.projectId;

    let versionId = window.localStorage.getItem('versionId')
      || editorReducerState.editorSettings.versionId;

    let templateId = window.localStorage.getItem('templateId')
      || editorReducerState.editorSettings.templateId;

    console.log('tempalte id', templateId)

    if (projectId && versionId) {
      User.getVersionById(projectId, versionId).then(response => {
        loadProject(response.data);
      }).catch(error => {
        console.log('error', error);

        if(userData) {
          toast.error('An error occurred loading your project.')
        }
      });
    }
    else if(templateId) {
      console.log('load template')
      User.getTemplateById(templateId).then(response => {
        loadProject(response.data);
      }).catch(handleNetworkError)
    }
  }, [])

  const value = useMemo(() => (
    {...editorContext, editorReducerState, dispatch}
  ), [editorContext, editorReducerState, dispatch])

  const userDefinedTheme = createTheme({
    ...editorReducerState.editorState.themeSettings
  });

  useEffect(() => {
    console.log('should we get components?', userData)

    User.listLayouts().then(response => {
      console.log('Layouts:', response.data);
      dispatch({
        type: 'setLayoutComponents',
        payload: {
          layoutCategories: response.data
        }
      });
    }).catch(handleNetworkError);

    if(userData) {
      console.log('get components')
      User.listComponentCategories().then(response => {
        console.log('components retrieved!', response.data)

        dispatch({
          type: 'setComponentCategories',
          payload: {
            savedComponentCategories: response.data
          }
        })
      }).catch(error => handleNetworkError(error, false, false))
    }
  }, [userData])

  const editorChildren = useMemo(() => {
    console.log('editor children rendered')

    return (
      <EditorDialogsProvider>

        <ReactJoyride
          showJoyrideOverride={editorReducerState.editorState.showJoyrideOverride}
        />

        <EditorHotkeysProvider>
          <div className={classes.mobileBlocker}>
            <Typography
              variant='h4'
              className={classes.mobileBlockerText}
            >
              We currently do not support mobile devices. Please login on a desktop computer.
            </Typography>
          </div>

          <div className={classes.editorOuterContainer}>
            <EditorSettings/>

            <div className={classes.editorInnerContainer}>
              <TabBar/>
              <Toolbox/>

              <EditorCanvas tree={tree} userDefinedTheme={userDefinedTheme}/>

              <ElementSettings/>
            </div>
          </div>
        </EditorHotkeysProvider>
      </EditorDialogsProvider>
    )
  }, [editorReducerState, tree])

  return (
    <IconProvider>
      <EditorContext.Provider value={value}>
        {editorChildren}
      </EditorContext.Provider>
    </IconProvider>
  );
};

Editor.propTypes = {};

export default withStyles(EditorStyle)(Editor);
