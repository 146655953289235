const MobileUserMenuStyle = theme => ({
  internalLinksContainer: {
    display: "flex",
    width: "100%",
    justifyContent: "center",
    flexDirection: "column"
  },

  internalLinks: {
    textDecoration: "none",
    marginRight: "50px",
    color: "white",
    fontFamily: "Roboto",
    fontSize: "16px",
    fontWeight: "500",
    transition: "0.3s",
    marginBottom: "20px",
    cursor: "pointer",

    '&:hover': {
      color: "#8A56FF",
    },
  },
});

export default MobileUserMenuStyle;