import DraggableWrapper from "editor/draggables/DraggableWrapper/DraggableWrapper";
import formatStyles from "editor/draggables/formatStyles";
import {useEditorDialog} from "hooks/useEditorDialog";
import React, {useEffect, useMemo, useState} from 'react';
import {withStyles} from '@material-ui/core';
import PropTypes from 'prop-types';
import FormDraggableStyle from './FormDraggableStyle';

const FormDraggable = props => {
  const {
    classes,
    style,
    editorId,
    draggableProps,
    children,
    absolutePositioning,

    // universally provided to all assets
    isChildElem
  } = props;

  const [hasOpened, setHasOpened] = useState(false)
  const formDraggableDialog = useEditorDialog('formDraggable');

  const {draggableStyles, elementStyles} =
    useMemo(() => formatStyles(style), [style]);

  useEffect(() => {
    if(formDraggableDialog && !hasOpened && children.length === 0) {

      handleOpen()
      setHasOpened(true)
    }
  }, [])

  const handleOpen = () => {
    formDraggableDialog.openDialog();
    formDraggableDialog.setDialogData({editorId});
  }

  return (
    <>
      <DraggableWrapper
        resizeable
        editorId={props.editorId}
        style={{
          ...draggableStyles,
          ...draggableProps?.style,
          position: absolutePositioning ? 'absolute' : 'static'
        }}
        isChildElem={isChildElem}
      >
        <div
          style={{
            ...elementStyles,
            position: 'static'
          }}
          className={classes.formRoot}
          data-editorid={editorId}
          data-asset={true}
          data-container={'true'}
        >
          {children}
        </div>
      </DraggableWrapper>
    </>
  );
};

FormDraggable.serializedName = 'FormDraggable';

FormDraggable.editorSettings = [
  {
    type: 'prop',
    key: 'breakpoints',
    tooltip: 'Make an asset visible at the chosen breakpoints: desktop, tablet, and/or mobile.',
    default: {
      visibility: {
        desktop: true,
        tablet: true,
        mobile: true
      },
      styleLink: {
        tablet: true,
        mobile: true,
      }
    },
    label: null,
    inputType: 'breakpoint'
  },

  {
    type: 'prop',
    key: 'alignment',
    tooltip: 'Use this to align all child assets inside of this asset.',
    default: {
      alignment: 'top-left',
      flexDirection: 'column'
    },
    inputType: 'alignment',
    label: 'Alignment'
  },

  {
    type: 'layout',
    key: 'twoCol',
    tooltip: 'Accepted units: px, %, em, rem, vw, vh',
    label: 'Position/Size',
    elements: [
      {type: 'style', key: 'left', default: '0px', label: 'X'},
      {type: 'style', key: 'top', default: '0px', label: 'Y'},
      {type: 'style', key: 'width', default: 'auto', label: 'W'},
      {type: 'style', key: 'height', default: 'auto', label: 'H'},
    ]
  },

  {
    type: 'prop',
    key: 'absolutePositioning',
    tooltip: 'If this is checked, your asset\'s CSS will be written with "position: absolute"',
    label: 'Absolute Positioning',
    default: false,
    inputType: 'boolean'
  },

  {
    type: 'style',
    key: 'background',
    default: 'transparent',
    label: null,
    inputType: 'color',
    tooltip: 'Accepted values are hexcodes, rgba values (e.g. "rgba(255,255,255,1)") and the value "transparent"',
    inputOptions: {
      defaultColor: 'transparent'
    }
  },

  {
    type: 'collection',
    label: 'Border',
    children: [
      {type: 'style', key: 'border', default: 'none', label: null, inputType: 'border'},
      {type: 'style', key: 'borderRadius', default: '0px', label: 'Border Radius'},

      {
        type: 'collection',
        label: 'Detailed Border',
        children: [
          {type: 'style', key: 'borderTop', default: 'none', label: 'Top', inputType: 'border'},
          {type: 'style', key: 'borderBottom', default: 'none', label: 'Bottom', inputType: 'border'},
          {type: 'style', key: 'borderLeft', default: 'none', label: 'Left', inputType: 'border'},
          {type: 'style', key: 'borderRight', default: 'none', label: 'Right', inputType: 'border'},
        ]
      }
    ]
  },

  {
    type: 'collection',
    label: 'Padding',
    children: [
      {type: 'style', key: 'paddingTop', default: '0px', label: 'Top', horizontal: false},
      {type: 'style', key: 'paddingBottom', default: '0px', label: 'Bottom', horizontal: false},
      {type: 'style', key: 'paddingLeft', default: '0px', label: 'Left', horizontal: false},
      {type: 'style', key: 'paddingRight', default: '0px', label: 'Right', horizontal: false},
    ]
  },

  {
    type: 'collection',
    label: 'Margin',
    children: [
      {type: 'style', key: 'marginTop', default: '0px', label: 'Top', horizontal: false},
      {type: 'style', key: 'marginBottom', default: '0px', label: 'Bottom', horizontal: false},
      {type: 'style', key: 'marginLeft', default: '0px', label: 'Left', horizontal: false},
      {type: 'style', key: 'marginRight', default: '0px', label: 'Right', horizontal: false},
    ]
  },

  {
    type: 'collection',
    label: 'Effects',
    children: [
      {type: 'style', key: 'opacity', default: '1', label: 'Opacity'},
      {type: 'style', key: 'boxShadow', default: 'none', label: 'Box Shadow', inputType: 'boxShadow'},
    ]
  },
];

FormDraggable.helpQuestionData = {
  category: 'form',
  helpAssetHeader: {
    assetType: 'Form',
    assetShortDescription: 'Build a form to collect information from your website\'s visitors. You can start from scratch or choose from ' +
      'our public component library of form templates.'
  }
}

FormDraggable.propTypes = {
  classes: PropTypes.object,
  style: PropTypes.object.isRequired,
  draggableProps: PropTypes.object,
  editorId: PropTypes.string.isRequired,
  isChildElem: PropTypes.bool,
  children: PropTypes.array,
};

export default withStyles(FormDraggableStyle)(FormDraggable);
