import Typography from "@material-ui/core/Typography";
import React from 'react';

const CanIHyperlinkText = () => {
  return (
    <>
      <Typography style={{marginBottom: '8px'}}>
        Yes, you can link text to other pages of your site, external URLs and more.
      </Typography>
      {/*TODO: ADD THIS ONCE LINK IS ADDED TO ACTION BAR*/}
      {/*<Typography>*/}
      {/*  1. Click on the link icon in the action bar above the textbox.*/}
      {/*</Typography>*/}
      {/*<Typography>*/}
      {/*  2. Select a link type and enter in the additional details.*/}
      {/*</Typography>*/}

      <Typography>
        1. Select the text asset in which you want to link.
      </Typography>
      <Typography>
        2. In the text settings, click to expand the 'Effects' section.
      </Typography>
      <Typography>
        3. Click the checkbox under 'Link' to confirm that you want to make this text into a link.
      </Typography>
      <Typography>
        4. Enter the additional details for the link.
      </Typography>
    </>
  );
};

CanIHyperlinkText.propTypes = {

};

export default CanIHyperlinkText;