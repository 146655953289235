import Button from "@material-ui/core/Button";
import CloseIcon from "@material-ui/icons/Close";
import {EditorContext} from "editor/Editor";
import {Form, Formik} from "formik";
import {cloneDeep} from "lodash-es";
import React, {useContext, useEffect, useState} from 'react';

import {
  Dialog, DialogContent, DialogTitle, IconButton, TextField, Typography,
  withStyles
} from '@material-ui/core';
import PropTypes from 'prop-types';

import RenameAssetDialogStyle from './RenameAssetDialogStyle';

const RenameAssetDialog = props => {
  const {
    classes,
    open,
    setOpen,
    data
  } = props;

  const editorData = useContext(EditorContext);
  const {editorReducerState, dispatch} = editorData;
  const layerLabelsInUse = editorReducerState.editorState.layerLabelsInUse;
  const [errorMessage, setErrorMessage] = useState('');

  const reservedLayerNames = ['root'];

  const [busy, setBusy] = useState(false);

  const onSubmit = async (values, {setErrors}) => {
    setErrorMessage('');
    setBusy(true);

    // check if asset name is available
    let newLayerLabelsInUse = layerLabelsInUse.filter(
      layerLabel => layerLabel !== data?.foundElement?.props?.layerLabel
    )

    // now check if it's not a reserved layer name
    if(!reservedLayerNames.includes(values.assetName)) {
      if(!newLayerLabelsInUse.includes(values.assetName)) {

        // clone deep seems to be needed here and doesn't slow too much
        let newTreeElem = cloneDeep(data.foundElement);

        newTreeElem.props.layerLabel = values.assetName;

        try {
          newTreeElem.baseData.layerLabel = values.assetName;
        }
        catch (e) {}

        dispatch({
          type: 'replaceAsset',
          payload: {
            originalElemId: data?.foundElement?.id,
            newTreeElem
          }
        })

        handleClose();
      }
      else {
        setErrorMessage('There is an already an asset with this name. Names must be unique.')
      }
    }
    else {
      setErrorMessage('This name is a reserved name. Please use a different name.')
    }

    setBusy(false);
  };

  const handleClose = () => {
    setOpen(false);
    setErrorMessage('');
  }

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      maxWidth={'md'}
      classes={{paper: classes.dialog}}
    >
      <DialogTitle className={classes.dialogTitle}>
        Rename Asset

        <IconButton
          onClick={handleClose}
          className={classes.closeIcon}
          size={'small'}
        >
          <CloseIcon/>
        </IconButton>
      </DialogTitle>

      <DialogContent>
        <Formik
          initialValues={{
            'assetName': data?.foundElement?.props?.layerLabel
          }}
          enableReinitialize
          onSubmit={onSubmit}
        >
          {({
              values,
              errors,
              handleChange
            }) => (
            <Form className={classes.form}>
              {errorMessage &&
                <Typography
                  variant={'body1'}
                  className={classes.errorMessage}
                >
                  {errorMessage}
                </Typography>
              }

              <TextField
                fullWidth
                color={"primary"}
                variant={"outlined"}
                name={'assetName'}
                value={values.assetName}
                autoFocus
                onChange={handleChange}
                error={!!errors.assetName}
                helperText={errors.assetName}
                InputProps={{
                  classes: {
                    notchedOutline: classes.notchedOutline,
                  },
                }}
              />

              <Button
                className={classes.purpleButton}
                variant={'contained'}
                color={'secondary'}
                disabled={busy}
                type={'submit'}
              >
                Submit
              </Button>
            </Form>
          )}
        </Formik>
      </DialogContent>
    </Dialog>
  );
};

RenameAssetDialog.propTypes = {
  classes: PropTypes.object,
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
  data: PropTypes.object,
};

export default withStyles(RenameAssetDialogStyle)(RenameAssetDialog);
