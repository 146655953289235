import ElementSettingsGroupLabel from "components/ElementSettingGroupLabel/ElementSettingsGroupLabel";
import InformationIcon from "components/InformationIcon/InformationIcon";
import {EditorContext} from "editor/Editor";
import ElementSettingsInput from "editor/layout/ElementSettings/Inputs/ElementSettingsInput";
import React, {useContext, useEffect, useMemo} from 'react';

import {withStyles} from '@material-ui/core';
import PropTypes from 'prop-types';

import TwoColLayoutStyle from 'editor/layout/ElementSettings/InputLayouts/TwoColLayout/TwoColLayoutStyle';
import InputGroupHeader from "editor/layout/ElementSettings/InputGroups/InputGroupHeader/InputGroupHeader";

const TwoColLayout = props => {
  const {
    classes,
    setting,
    key,
    name,
    initialValues,
    submitForm,
    setInitialValues,
  } = props;

  const editorData = useContext(EditorContext);
  const {editorReducerState, dispatch} = editorData;

  useEffect(() => {
    console.log('two col layout props', {props, editorReducerState})
  }, [props])

  const settingElements = useMemo(() => (
    setting.elements.map((key, index) => {
      const settingElem = setting.elements[index];
      return (
        <ElementSettingsInput
          setting={settingElem}

          initialValues={initialValues}
          setInitialValues={setInitialValues}
          submitForm={submitForm}

          key={settingElem.key}
          options={{
            horizontal: settingElem.horizontal ?? true,
            label: settingElem.label
          }}
        />
      );
    })
  ), [setting, initialValues])

  return (
    <div className={classes.root}>
      {setting.label &&
        <ElementSettingsGroupLabel setting={setting}>
          {setting.label}
        </ElementSettingsGroupLabel>
      }

      <div className={classes.innerContainer}>
        {settingElements}
      </div>
    </div>
  );
};

TwoColLayout.defaultProps = {
  elementSettings: []
}

TwoColLayout.propTypes = {
  classes: PropTypes.object,
  setting: PropTypes.object.isRequired,
  formikBag: PropTypes.object.isRequired,
};

TwoColLayout.whyDidYouRender = true;

export default withStyles(TwoColLayoutStyle)(TwoColLayout);
