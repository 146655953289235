import {Card, CardContent, Checkbox, FormControlLabel, withStyles} from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import cn from "classnames";
import NotificationCardStyle from "components/AccountSettings/Profile/NotificationCard/NotificationCardStyle";
import {UserContext} from "context/UserContext";
import React, {useContext} from 'react';
import {Form, Formik} from "formik";
import PropTypes from 'prop-types';
import {User} from "services";
import {handleNetworkError} from "utils/utils";

const NotificationCard = props => {
  const {
    pageClasses,
    classes
  } = props;

  const {userData, setUserData} = useContext(UserContext);

  const onSubmit = async (values, {setErrors}) => {
    console.log('values', values)

    User.changeNotificationStatus(values.newsletter)
      .then(response => {
        let newUserData = {...userData};
        newUserData.shouldNotify = values.newsletter;
        setUserData(newUserData);
      })
      .catch(handleNetworkError)
  };

  return (
    <Card className={pageClasses.pageCard}>
      <CardContent>
        <Typography
          variant={'h6'}
          className={pageClasses.pageContentTitle}
        >
          Notifications
        </Typography>

        <Formik
            initialValues={{
              newsletter: userData?.shouldNotify
            }}

            onSubmit={onSubmit}
          >
            {({
              values,
              errors,
              handleChange,
              setFieldValue,
              submitForm,
            }) => (
            <Form className={pageClasses.cardContent}>
              <div className={classes.checkboxContainer}>
                <FormControlLabel
                  control={
                    <Checkbox
                      size={'small'}
                      checked={values.newsletter}
                      checkedIcon={
                        <CheckBoxIcon
                          className={cn(classes.checkboxIcon, classes.checkedIcon)}
                        />
                      }
                      icon={
                        <CheckBoxOutlineBlankIcon
                          className={classes.checkboxIcon}
                        />
                      }
                      onChange={() => {
                        setFieldValue('newsletter', !values.newsletter);
                        submitForm()
                      }}
                      name={'newsletter'}
                    />
                  }
                  label={'Receive emails & updates from Nimbus'}
                />
              </div>
            </Form>
          )}
          </Formik>
      </CardContent>
    </Card>
  );
};

NotificationCard.propTypes = {
  pageClasses: PropTypes.object,
  classes: PropTypes.object,
};

export default withStyles(NotificationCardStyle)(NotificationCard);
