import React from 'react';
import { generateId, isObjectEmpty } from 'utils/utils';

export const renderTree = (treeToBeRendered) => {
  // NOTE: this function is super jank and should probably be rewritten,
  // make sure when this is called the initial value is {'editor-root': treeElements}

  let elemArr = [];

  const hasChildren = (treeElem) => {
    return !!treeElem.children && treeElem.children.length > 0;
  };

  // loop through the sibling elements then combine them together
  let treeKeys = Object.keys(treeToBeRendered);
  for(let [treeElemIndex, treeElemKey] of treeKeys.entries()) {
    // console.log('tree elem index:', treeElemIndex);
    // console.log('tree elem key:', treeElemKey);
    let treeElem = treeToBeRendered[treeElemKey];

    const defaultProps = {
      key: treeElemIndex,
      // TODO: I think we need to require an ID instead of generating here, otherwise search won't work
      "data-editorid": treeElem.id || generateId()
    };

    // if there are children, call this function again
    let children = [];
    if(hasChildren(treeElem)) {
      console.log('has children', treeElem)
      children = renderTree(treeElem.children);
    }

    // create the element
    let createdElem = React.createElement(
      treeElem.tag,
      treeElem.props && !isObjectEmpty(treeElem.props) ?
        {
          ...defaultProps,
          ...treeElem.props,
          draggableProps: treeElem.draggableProps ? {...treeElem.draggableProps} : null
        }
        : defaultProps,
      children
    );

    elemArr.push(createdElem);
  }

  return elemArr
};
