import * as icons from "@material-ui/icons";
import IconButtonDraggable from "editor/draggables/IconButtonDraggable/IconButtonDraggable";
import BaseComponentSerializer from "editor/serializers/BaseComponentSerializer";
import {useAssetConfig} from "hooks/useAssetConfig";
import React from "react";

class IconButtonDraggableSerializer extends BaseComponentSerializer {
  constructor(elem, editorData) {
    super(elem, editorData);
    this.propsToRemove = ['layerIconNode', 'key'];
    this.assetConfig = useAssetConfig();

    let newIconList = []
    for (let i of Object.keys(icons)) {
      newIconList.push(icons[i])
    }

    this.iconList = newIconList;
  }

  findIconByDisplayName(name) {
    return this.iconList.find(elem => elem.displayName === name)
  }


  formatIconProps(propsToFormat=this.elem.props) {
    let returnVal = {
      ...this.formatProps(propsToFormat),
      // override selectedIcon so it saves properly
      // for the icon we should only save the display name
      selectedIcon: propsToFormat.selectedIcon?.type?.displayName
    };
    return returnVal;
  }

  deserialize() {
    let SelectedIcon = this.findIconByDisplayName(this?.elem?.props?.selectedIcon);

    return {
      tag: IconButtonDraggable,
      tagName: 'IconButtonDraggable',
      props: {
        selectedIcon: <SelectedIcon/>,
        //layerLabel: this.assetConfig.IconDraggable.props.layerLabel,
        layerIconNode: this.assetConfig.IconButtonDraggable.sharedProps.layerIconNode
      },
      baseData: {
        ...this.elem?.baseData,
        selectedIcon: <SelectedIcon/>,
        layerIconNode: this.assetConfig.IconButtonDraggable.sharedProps.layerIconNode
      }
    }
  }

  serialize() {
    return {
      tag: 'IconButtonDraggable',
      id: this.elem.id,
      draggableProps: {
        style: {
          ...this.getDraggablePosition()
        }
      },
      props: {
        ...this.formatIconProps(),
        //style: {...this.getElementStyles()}
      },
      baseData: {
        ...this.formatIconProps(this.elem.baseData)
      },
      breakpointData: {
        ...this.formatIconProps(this.elem.breakpointData)
      }
    };
  }
}

export default IconButtonDraggableSerializer;
