import {FormControl, IconButton, withStyles} from '@material-ui/core';
import Tooltip from "@material-ui/core/Tooltip";
import CancelIcon from "@material-ui/icons/Cancel";
import cn from 'classnames';
import ElementSettingsGroupLabel from "components/ElementSettingGroupLabel/ElementSettingsGroupLabel";
import StyledTextField from "components/StyledTextField/StyledTextField";
import ColorPickerBox from "editor/components/ColorPickerBox/ColorPickerBox";
import PropTypes from 'prop-types';
import React, {useEffect, useMemo, useRef, useState} from 'react';

import ColorPickerInputStyle from './ColorPickerInputStyle';

const ColorPickerInput = props => {
  const {
    classes,
    onChange,
    onFocus,
    onBlur,
    defaultColor,
    showTitle,
    showTextField,
    setting,
    className,
    label,
    value,
    removable=true,
    forceHexcode=false,
    ...rest
  } = props;

  // check if it's undefined ONLY because null is a valid default
  const DEFAULT_COLOR = defaultColor === undefined ? '#FFFFFF' : defaultColor;

  // also opens the color picker when not null
  const [anchorEl, setAnchorEl] = useState(null);
  const [error, setError] = useState('');

  const [currentColor, setCurrentColor] = useState(DEFAULT_COLOR);

  useEffect(() => {
    setCurrentColor(value);

    if(forceHexcode && value) {
      let reg = /^#([0-9a-f]{3}){1,2}$/i;

      if(!(reg.test(value))) {
        setError('Themes only support valid hexcodes. RGB and named colors (i.e. "red" are not supported in themes.')
      }
      else {
        setError('');
      }
    }

  }, [value]);

  const handleClick = (e) => {
    setAnchorEl(e.currentTarget);
  }

  const handleColorBoxChange = (color) => {
    let colorToSet = color;

    onChange(colorToSet);
    setCurrentColor(colorToSet);
  }

  const handleTextInputChange = (e) => {
    let colorToSet = e.target.value;

    if(forceHexcode) {
      let reg = /^#([0-9a-f]{3}){1,2}$/i;

      if(!(reg.test(colorToSet))) {
        setError('Themes only support valid hexcodes. RGB and named colors (i.e. "red") are not supported in themes.')
      }
    }

    onChange(colorToSet);
    setCurrentColor(colorToSet);
  }

  const removeColor = (e) => {
    // prevents opening the color picker when removing
    e.stopPropagation();

    setCurrentColor(DEFAULT_COLOR);
    onChange(DEFAULT_COLOR);
  }

  const renderedPopover = useMemo(() => {
    return (
      <ColorPickerBox
        anchorEl={anchorEl}
        setAnchorEl={setAnchorEl}
        onChange={handleColorBoxChange}
      />
    )
  }, [anchorEl, setAnchorEl])

  return (
    <div className={cn(classes.root, className)}>
      {showTitle &&
        <ElementSettingsGroupLabel setting={setting}>
          {label || 'Fill'}
        </ElementSettingsGroupLabel>
      }

      <FormControl
        className={classes.innerContainer}
        fullWidth={showTextField}
      >
        <FormControl className={classes.formControl} fullWidth>
          {/*<Tooltip title={'Click to change color'}>*/}
            <div className={classes.colorButtonContainer}>
              {/*Color Button*/}
              <div
                className={classes.colorButton}
                style={{backgroundColor: currentColor}}
                onClick={handleClick}
              >
                {removable && currentColor !== DEFAULT_COLOR && (
                  <IconButton className={classes.removeColor} onClick={removeColor}>
                    <div className={classes.removeColorInner}>
                      <CancelIcon className={classes.removeColorIcon}/>
                    </div>
                  </IconButton>
                )}
              </div>
            </div>
          {/*</Tooltip>*/}

          {showTextField &&
            <StyledTextField
              className={classes.colorPickerText}
              fullWidth
              onChange={handleTextInputChange}
              onFocus={onFocus}
              onBlur={onBlur}
              value={value}
              error={!!error}
              helperText={error}
              {...rest}
            />
          }
        </FormControl>

        {renderedPopover}

      </FormControl>
    </div>
  );
};

ColorPickerInput.defaultProps = {
  showTitle: true,
  showTextField: true
}

ColorPickerInput.propTypes = {
  classes: PropTypes.object,
  defaultColor: PropTypes.string,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
  onChange: PropTypes.func.isRequired,
  showTitle: PropTypes.bool,
  showTextField: PropTypes.bool,
  className: PropTypes.string,
  value: PropTypes.string,
  setting: PropTypes.object.isRequired,
  label: PropTypes.string,
};

export default React.memo(withStyles(ColorPickerInputStyle)(ColorPickerInput));
