import CloseIcon from "@material-ui/icons/Close";
import DraggableToolboxAsset from "editor/draggables/DraggableToolboxAsset/DraggableToolboxAsset";
import {EditorContext} from "editor/Editor";
import {useAssetVariants} from "hooks/useAssetVariants";
import {useComponentVariants} from "hooks/useComponentVariants";
import {usePublicVariants} from "hooks/usePublicVariants";
import React, {useContext, useEffect, useRef, useState} from 'react';
import {
  Button, IconButton,
  Paper, Typography,
  withStyles
} from '@material-ui/core';
import PropTypes from 'prop-types';

import AssetPanelStyle from './AssetPanelStyle';

const AssetPanel = props => {
  const { classes } = props;

  const rootRef = useRef(null);
  // because a click outside will immediately close the asset panel, we need a ref
  // to check if it JUST changed or if the user actually wants to close it
  const assetPanelWasOpen = useRef(false);

  const {editorReducerState, dispatch} = useContext(EditorContext);
  const showAssetPanel = editorReducerState.editorState.showAssetPanel;
  const assetPanelSelectedAsset = editorReducerState.editorState.assetPanelSelectedAsset;

  const [shouldShow, setShouldShow] = useState(false);

  const assetVariants = useAssetVariants(assetPanelSelectedAsset?.tagName);
  const componentVariants = useComponentVariants(assetPanelSelectedAsset, 'saved');
  const publicVariants = usePublicVariants(assetPanelSelectedAsset);

  console.log('asset panel test', {
    assetPanelSelectedAsset,
    assetVariants,
    componentVariants,
    publicVariants,
  })

  const handleClose = () => {
    setShouldShow(false)
  }

  const handleClickOutside = (e) => {

    console.log('clicked outside!', {
      test1: rootRef.current,
      test2: !rootRef.current.contains(e.target),
      shouldShow
    })

    if (rootRef.current && !rootRef.current.contains(e.target) && shouldShow) {
      // clicked outside and is open
      setShouldShow(false);
    }
  }

  useEffect(() => {
    console.log('asset panel selected asset', assetPanelSelectedAsset)
  }, [assetPanelSelectedAsset])

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [shouldShow, rootRef])

  useEffect(() => {
    if(assetPanelWasOpen.current) {
      assetPanelWasOpen.current = showAssetPanel;
    }
    else {
      setShouldShow(showAssetPanel);
      assetPanelWasOpen.current = showAssetPanel;
    }

  }, [showAssetPanel])

  useEffect(() => {
    if(!shouldShow) {
      dispatch({
        type: 'toggleAssetPanel',
        payload: {
          showAssetPanel: false,
          assetPanelSelectedAsset: null
        }
      })
    }
  }, [shouldShow])

  return (
    <div
      className={classes.root}
      ref={rootRef}
      style={{
        display: shouldShow ? 'flex' : 'none'
      }}
    >
      <Paper className={classes.panelRoot}>
        <div className={classes.closeButtonContainer}>
          <IconButton size={'small'} className={classes.closeButton}>
            <CloseIcon onClick={handleClose}/>
          </IconButton>
        </div>

        <Typography variant={'h6'} align={'center'} className={classes.assetPanelTitle}>
          Drag a variant from here to create it on the page
        </Typography>

        <div className={classes.draggableComponentsInnerContainer}>
          {publicVariants && publicVariants.map((variant) => {
            return (
              <DraggableToolboxAsset
                key={variant.id}
                iconNode={
                  variant?.image
                }
                textNode={
                  variant.formattedVariantName
                }
                variantData={variant}
                isComponent
                elementToCreate={{...variant.elementToCreate}}
              />
            )
          })}

          {assetVariants && assetVariants.map((data) => {
            const variant = assetPanelSelectedAsset.variants[data.variantName];
            return (
              <DraggableToolboxAsset
                key={data.variantName}
                iconNode={
                  variant?.image
                }
                textNode={
                  data.formattedVariantName
                }
                variantData={variant}
                elementToCreate={{...data}}
              />
            )
          })}

          {componentVariants && componentVariants.map((variant) => {
            return (
              <DraggableToolboxAsset
                key={variant.id}
                iconNode={
                  variant?.image
                }
                textNode={
                  variant.formattedVariantName
                }
                variantData={variant}
                isComponent
                elementToCreate={{...variant.elementToCreate}}
              />
            )
          })}
        </div>
      </Paper>
    </div>
  );
};

AssetPanel.propTypes = {
  classes: PropTypes.object
};

export default withStyles(AssetPanelStyle)(AssetPanel);
