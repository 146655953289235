import {Card, CardActions, CardContent, TextField} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import cn from "classnames";
import {UserContext} from "context/UserContext";
import {Form, Formik} from "formik";
import React, {useContext, useState} from 'react';
import PropTypes from 'prop-types';
import {toast} from "react-toastify";
import {User} from "services";
import {handleNetworkError} from "utils/utils";

const ContactInfoCard = props => {
  const {
    pageClasses,
  } = props;

  const [busy, setBusy] = useState(false);
  const {userData, setUserData} = useContext(UserContext)

  const onContactInfoSubmit = async (values, {setErrors}) => {
    console.log('submit new contact info', values);

    setBusy(true)

    User.update({
      ...values
    })
    .then(response => {
      console.log('success!', response);
      setUserData({...response.data});
      toast.success('User details changed successfully!');
    })
    .catch(handleNetworkError)
      .finally(() => {
        setBusy(false);
      })
  };

  return (
    <Card className={pageClasses.pageCard}>
      <CardContent>
        <Typography
          variant={'h6'}
          className={pageClasses.pageContentTitle}
        >
          Contact Information
        </Typography>

        <Formik
          initialValues={{
            name: userData.name,
            email: userData.email
          }}

          onSubmit={onContactInfoSubmit}
        >
          {({
              values,
              errors,
              handleChange
            }) => (
            <Form className={pageClasses.cardContent}>
              <div className={pageClasses.colContainer}>
                <Typography
                  variant={'subtitle1'}
                  className={cn(pageClasses.pageContentTitle, pageClasses.colTitle)}
                >
                  Name
                </Typography>
                <TextField
                  variant={'outlined'}
                  size={'small'}
                  name={'name'}
                  fullWidth
                  onChange={handleChange}
                  value={values.name}
                  error={!!errors.name}
                  helperText={errors.name}
                  className={pageClasses.textfield}
                  InputProps={{
                    classes: {
                      notchedOutline: pageClasses.notchedOutline,
                    },
                  }}
                />
              </div>

              <div className={pageClasses.colContainer}>
                <Typography
                  variant={'subtitle1'}
                  className={cn(pageClasses.pageContentTitle, pageClasses.colTitle)}                  >
                  Email
                </Typography>
                <TextField
                  variant={'outlined'}
                  size={'small'}
                  name={'email'}
                  fullWidth
                  onChange={handleChange}
                  value={values.email}
                  className={pageClasses.textfield}
                  error={!!errors.email}
                  helperText={errors.email}
                  InputProps={{
                    classes: {
                      notchedOutline: pageClasses.notchedOutline,
                    },
                  }}
                />
              </div>

              <CardActions className={pageClasses.buttonContainer}>
                <Button
                  type={'submit'}
                  variant={'contained'}
                  disabled={busy}
                  className={pageClasses.purpleButton}
                >
                  Submit
                </Button>
              </CardActions>
            </Form>
          )}
        </Formik>
      </CardContent>
    </Card>
  );
};

ContactInfoCard.propTypes = {
  pageClasses: PropTypes.object,
};

export default ContactInfoCard;
