import {Card, CardContent, withStyles} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import cn from "classnames";
import PaymentStyle from "components/AccountSettings/ManageSubscription/Payment/PaymentStyle";
import {UserContext} from "context/UserContext";
import StripeFormDialog from "dialogs/StripeFormDialog/StripeFormDialog";
import PropTypes from 'prop-types';
import React, {useContext, useState} from 'react';

const Payment = props => {
  const {
    pageClasses,
    classes,
  } = props;

  const [open, setOpen] = useState(false)

  const {userData} = useContext(UserContext);

  const handleClick = (e) => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false);
  }

  return (
    <Card className={pageClasses.pageCard}>
      <CardContent>
        <Typography
          variant={'h6'}
          className={pageClasses.pageContentTitle}
        >
          Payment
        </Typography>

        {/*get card last 4 digits*/}
        <Typography
          variant={'subtitle1'}
          className={cn(pageClasses.pageContentTitle, pageClasses.colTitle)}
        >
          Card ending in{' '}
          {userData.last4 || 'No saved card details'}
        </Typography>

        {/*open payment dialog*/}
        <Button
          variant={'contained'}
          className={classes.purpleButton}
          onClick={handleClick}
        >
          Change Credit Card
        </Button>
      </CardContent>

      <StripeFormDialog
        open={open}
        setOpen={setOpen}
        onSuccess={handleClose}
        isEditingCard
      />
    </Card>
  );
};

Payment.propTypes = {
  pageClasses: PropTypes.object,
  classes: PropTypes.object
};

export default withStyles(PaymentStyle)(Payment);
