const LoginRegisterDialogStyle = theme => ({
  dialogTitle: {
    width: "100%",

    '& .MuiTypography-root': {
      display: "flex",
      flexDirection: "row",
      width: "100%",
      justifyContent: "flex-end",
    },
  },

  closeIcon: {
    color: "white",

    '&:hover':{
      background: "#3a3a4a",
      transition: "0.3s",
    },
  },
});

export default LoginRegisterDialogStyle;
