const DraggableWrapperStyle = theme => ({
  root: {
    width: 'fit-content',
    height: 'fit-content',
    border: 'inset 1px black',

    '&:hover': {
      cursor: 'pointer'
    },
  },
  draggableContainer: {
    height: 'max-content'
  },
});

export default DraggableWrapperStyle;
