const AddClassDialogStyle = theme => ({
  root: {
    display: "flex",
    flexDirection: "column",
  },

  closeIcon: {
    color: "white",

    '&:hover':{
      background: "#3a3a4a",
      transition: "0.3s",
    },
  },

  dialog: {
    minWidth: "350px",
  },

  errorMessage: {
    color: theme.palette.error.main,
    width: '300px',
    margin: 'auto',
    marginBottom: '8px'
  },

  dialogTitle: {
    width: "100%",


    '& .MuiTypography-root': {
      display: "flex",
      flexDirection: "row",
      width: "100%",
      justifyContent: "space-between",
    },
  },

  form: {
    display: "flex",
    flexDirection: "column",
  },

  purpleButton: {
    width: "100px",
    marginLeft: "auto",
    marginTop: "10px",
  },

  notchedOutline: {
    borderColor: "white",
  },
});

export default AddClassDialogStyle;
