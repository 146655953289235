import {purpleButton} from "assets/theme";

const EditorCanvasStyle = theme => ({
  canvasOuter: {
    height: 'calc(100vh - 75px)',
    width: '50%',
    margin: '10px',
    marginTop: '0px',
    display: 'flex',
    flexWrap: 'wrap',
    flexDirection: 'row',
    position: 'relative',
    flex: 1,
    alignItems: 'flex-start',
    overflow: 'auto',

    '&::-webkit-scrollbar': {
      width: '8px',
      height: '8px',
      position: 'relative',
      marginLeft: '9.33px',
      borderRadius: '10px'
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: 'white',
      borderRadius: '10px',
    },
    '&::-webkit-scrollbar-track': {
      backgroundColor: '#262626',
      opacity: '0.48',
      borderRadius: '10px'
    },
  },

  canvasInner: {
    width: '2500px',
    height: '1500px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },

  zoomContainer: {
    margin: '100px',
    position: 'relative'
  },

});

export default EditorCanvasStyle;
