import {IconButton, withStyles} from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import ElementSettingsGroupLabel from "components/ElementSettingGroupLabel/ElementSettingsGroupLabel";
import StyledTextField from "components/StyledTextField/StyledTextField";
import {EditorContext} from "editor/Editor";
import {useEditorDialog} from "hooks/useEditorDialog";
import PropTypes from 'prop-types';
import AddBoxIcon from '@material-ui/icons/AddBox';
import React, {useContext, useEffect, useState} from 'react';
import ClassInputStyle from './ClassInputStyle';

const ClassInput = props => {
  const {
    classes,
    setting,
    onBlur,
    onFocus,
  } = props;

  const addClassDialog = useEditorDialog('addClass')

  const editorData = useContext(EditorContext);
  const {editorReducerState, dispatch} = editorData;
  const currentClassData = editorReducerState.editorState.currentClasses;

  const [classOptions, setClassOptions] = useState([]);

  const handleClassAddSuccess = (data) => {
    console.log('class added successfully')
  }

  const handleOpenDialog = () => {
    addClassDialog.appendDialogData({
      editorId: editorReducerState?.editorState?.elementSelected?.value,
      successCallback: data => handleClassAddSuccess(data)
    })
    addClassDialog.openDialog();
  }

  useEffect(() => {
    let formattedOptions = [];
    for(let [key, value] of Object.entries(currentClassData)) {
      formattedOptions.push({name: key})
    }
    setClassOptions([...formattedOptions])
  }, [currentClassData])

  return (
    <div className={classes.root}>
      <ElementSettingsGroupLabel setting={setting}>
        Class
      </ElementSettingsGroupLabel>

      <div className={classes.comboboxContainer}>
        <Autocomplete
          freesolo
          size={'small'}
          classes={{root: classes.autocompleteRoot}}
          options={classOptions.map((option) => option.name)}
          renderInput={(params) => (
            <StyledTextField
              {...params}
              className={classes.input}
              variant="outlined"
              placeholder="Classes"
              onBlur={onBlur}
              onFocus={onFocus}
            />
          )}
        />

        <div>
          <IconButton
            className={classes.addButton}
            onClick={handleOpenDialog}
          >
            <AddBoxIcon className={classes.addButtonIcon}/>
          </IconButton>
        </div>
      </div>

    </div>
  );
};

ClassInput.propTypes = {
  classes: PropTypes.object,
  setting: PropTypes.object.isRequired,
  onBlur: PropTypes.func,
  onFocus: PropTypes.func
};

export default withStyles(ClassInputStyle)(ClassInput);
