import React, {createContext, useCallback, useEffect, useMemo, useReducer} from 'react';
import PropTypes from 'prop-types';

export const EditorLayersContext = createContext();

const EditorLayersProvider = props => {
  const {
    children
  } = props;

  const initState = {
    branchPositions: []
  }

  const editorLayerReducer = useCallback((state, action) => {
    switch (action.type) {
      case 'setLayerLabelPosition': {
        const {layerLabelId, position, ref, elementEditorId, parentLayerId} = action.payload;

        let return_val = {
          ...state,
          branchPositions: [
            ...state.branchPositions.filter(elem => elem.elementEditorId !== elementEditorId),
            {
              layerLabelId,
              ref,
              position,
              elementEditorId,
              parentLayerId
            }
          ].sort((a, b) => {
            return a.position - b.position;
          })
        };

        //console.log(`editor layer reducer action "${action.type}" was called`, {state, action, return_val})
        return return_val
      }
    }
  }, [])

  const [layerReducerState, dispatch] = useReducer(editorLayerReducer, initState);

  useEffect(() => {
    console.log('layer reducer state changed', layerReducerState)
  }, [layerReducerState])

  const value = {
    layerReducerState,
    editorLayerDispatch: dispatch
  }

  return (
    <EditorLayersContext.Provider
      value={value}
    >
      {children}
    </EditorLayersContext.Provider>
  );
};

EditorLayersProvider.propTypes = {
  children: PropTypes.object.isRequired
};

export default React.memo(EditorLayersProvider);
