import {Button, IconButton, Paper, withStyles} from '@material-ui/core';
import DesktopWindowsIcon from '@material-ui/icons/DesktopWindows';
import SmartphoneIcon from '@material-ui/icons/Smartphone';
import TabletIcon from '@material-ui/icons/Tablet';
import cn from "classnames";
import ElementSettingsGroupLabel from "components/ElementSettingGroupLabel/ElementSettingsGroupLabel";
import {EditorContext} from "editor/Editor";
import ElementSettingsInput from "editor/layout/ElementSettings/Inputs/ElementSettingsInput";
import {useEditorDialog} from "hooks/useEditorDialog";
import PropTypes from 'prop-types';
import React, {useContext, useEffect, useState} from 'react';
import CanvasSettingsStyle from './CanvasSettingsStyle';

const CanvasSettings = props => {
  const {
    classes,
    id,
    className,
  } = props;

  const [selectedSize, setSelectedSize] = useState('desktop')
  const editorData = useContext(EditorContext);
  const {editorReducerState, dispatch} = editorData;
  const currentCanvasSettings = editorReducerState.editorState.canvasSettings;
  const themeDialog = useEditorDialog('theme');

  const [initialValues, setInitialValues] = useState({
    canvasSize: currentCanvasSettings.currentCanvasBreakpoint,
    canvasBackground: currentCanvasSettings.currentCanvasBackgroundColor,
    canvasHeight: currentCanvasSettings.canvasHeight,
    currentAlignment: currentCanvasSettings.currentAlignment,
    style: currentCanvasSettings.style
  });

  const colorPickerSetting =
    {
      type: 'none',
      key: 'canvasBackground',
      default: '#181A1E',
      label: 'Canvas Background',
      inputType: 'color'
    }

  const alignmentSetting =
    {
      type: 'prop',
      key: 'alignment',
      tooltip: 'Use this to align all child assets inside of this asset.',
      default: null,
      inputType: 'alignment',
      label: 'Alignment'
    }

  const canvasHeightSetting =
    {
      type: 'none',
      tooltip: 'Exceeding the default height of the canvas will result in a scrollbar',
      key: 'canvasHeight',
      default: null,
      label: 'Canvas Height',
    }

  useEffect(() => {
    setSelectedSize(editorReducerState.editorState.canvasSettings.currentCanvasBreakpoint)
  }, [editorReducerState.editorState.canvasSettings.currentCanvasBreakpoint])

  useEffect(() => {
    // set initial values from the current canvas settings
    setInitialValues({
      canvasSize: currentCanvasSettings.currentCanvasBreakpoint,
      canvasBackground: currentCanvasSettings.currentCanvasBackgroundColor,
      canvasHeight: currentCanvasSettings.canvasHeight,
      currentAlignment: currentCanvasSettings.currentAlignment,
      style: currentCanvasSettings.style
    })

    const canvasNode = document.getElementById('editor-root')

    for(let [key, value] of Object.entries(currentCanvasSettings.style)) {
      canvasNode.style[key] = value;
    }

    canvasNode.style.backgroundColor = currentCanvasSettings.currentCanvasBackgroundColor;
  }, [currentCanvasSettings])

  const submitForm = (newValues) => {
    console.log('values', newValues)
    setSelectedSize(newValues.canvasSize)

    dispatch({
      type: 'changeCanvasData',
      payload: {
        //breakpointToUse: newValues.canvasSize,
        backgroundColor: newValues.canvasBackground,
        canvasHeight: newValues.canvasHeight,
        currentAlignment: initialValues.currentAlignment,
        style: {
          ...initialValues.style
        }
      }
    })
  };


  const handleAlignmentChange = (placeholder, inputValues) => {
    dispatch({
      type: 'changeCanvasData',
      payload: {
        //breakpointToUse: initialValues.canvasSize,
        backgroundColor: initialValues.canvasBackground,
        canvasHeight: initialValues.canvasHeight,
        currentAlignment: placeholder,
        style: {
          ...inputValues
        }
      }
    })
  }

  const handleOpenThemeDialog = () => {
    themeDialog.openDialog();
  }

  return (
    <Paper id={id} className={className}>
      <form className={classes.form}>
        <div className={classes.inputContainer}>
          <ElementSettingsInput
            setting={alignmentSetting}
            key={'alignment'}
            initialValues={initialValues}
            setInitialValues={setInitialValues}
            loadValuesFromAsset={false}
            valueOverride={initialValues.currentAlignment}
            options={{
              isCanvasInput: true,
              onCanvasChange: (placeholder, inputValues) =>
                handleAlignmentChange(placeholder, inputValues),
            }}
          />
        </div>

        <div className={classes.inputContainer}>
          <ElementSettingsInput
            setting={colorPickerSetting}
            key={'backgroundColor'}
            loadValuesFromAsset={false}
            valueOverride={initialValues.canvasBackground}
            initialValues={initialValues}
            setInitialValues={setInitialValues}
            submitForm={submitForm}
            options={{
              removable: true,
              defaultColor: '#FFFFFF'
            }}
          />
        </div>

        <div className={classes.inputContainer}>
          <ElementSettingsInput
            setting={canvasHeightSetting}
            key={'canvasHeight'}
            loadValuesFromAsset={false}
            valueOverride={initialValues.canvasHeight}
            initialValues={initialValues}
            setInitialValues={setInitialValues}
            submitForm={submitForm}
          />
        </div>

        {/* This is actually not connected to the rest of the canvas settings
           We should make this better later
        */}

        {/*disabled button b/c added theme tab*/}
        {/*<Button*/}
        {/*  onClick={handleOpenThemeDialog}*/}
        {/*  variant={'contained'}*/}
        {/*  color={'secondary'}*/}
        {/*  id={'canvas-theme'}*/}
        {/*>*/}
        {/*  Customize Theme*/}
        {/*</Button>*/}
      </form>
    </Paper>
  );
};

CanvasSettings.propTypes = {
  classes: PropTypes.object,
};

export default withStyles(CanvasSettingsStyle)(CanvasSettings);
