const ContainerDraggableStyle = {
  root: {
    width: '100%',
    height: '100%',
    background: 'white',
    display: 'flex',
    backgroundSize: 'cover',
    minHeight: '35px',
    minWidth: '35px',

    backgroundImage: props => (
      props.selectedMedia ?
        `url("${props.selectedMedia}")` :
        'none'
    ),

    '&:hover': props => ({
      background: props.hoverColor
    }),
  },

  editable: {
    width: '100%',
    height: '100%',
    minHeight: '20x',
    minWidth: '35px',
  },

  default: {
    width: '200px',
    height: '200px'
  }
};

export default ContainerDraggableStyle;
