import CloseIcon from "@material-ui/icons/Close";
import AccountSettings from "components/AccountSettings/AccountSettings";
import React from 'react';
import {Dialog, DialogContent, DialogTitle, IconButton, withStyles} from '@material-ui/core';
import PropTypes from 'prop-types';
import AccountSettingsDialogStyle from './AccountSettingsDialogStyle';

const AccountSettingsDialog = props => {
  const {
    classes,
    open,
    setOpen,
    data,
  } = props;

  const handleClose = () => {
    setOpen(false)
  }

  return (
    <Dialog
      open={open}
      maxWidth={'lg'}
      classes={{paper: classes.dialog}}
    >
      <DialogTitle className={classes.dialogTitle}>
        Account Settings

        <IconButton
          onClick={handleClose}
          className={classes.closeIcon}
          size={'small'}
        >
          <CloseIcon/>
        </IconButton>
      </DialogTitle>

      <DialogContent className={classes.dialogContent}>
        <AccountSettings
          inEditor
          pageToOpen={data?.pageToOpen || 0}
        />
      </DialogContent>

    </Dialog>
  );
};

AccountSettingsDialog.propTypes = {
  classes: PropTypes.object,
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
  data: PropTypes.object,
};

export default withStyles(AccountSettingsDialogStyle)(AccountSettingsDialog);
