import React from "react";

import TwoColLayout from "editor/layout/ElementSettings/InputLayouts/TwoColLayout/TwoColLayout";
import FontLayout from "editor/layout/ElementSettings/InputLayouts/FontLayout/FontLayout";
import {generateId} from "utils/utils";

const ElementSettingsInputLayout = props => {
  const {
    setting,
    layoutKey,
    initialValues,
    submitForm,
    setInitialValues,
    options={},
  } = props;

  // const {
  //   values,
  //   setFieldValue,
  //   handleChange
  // } = formikBag;

  const defaultProps = {
    key: layoutKey,
    name: layoutKey,
    setting,
    initialValues,
    submitForm,
    setInitialValues,
    ...options
  }

  // TODO: ADD NEW ELEMENT SETTINGS LAYOUTS HERE

  if(layoutKey === 'twoCol') {
    return (
      <TwoColLayout
        {...defaultProps}
      />
    )
  }
  else if(layoutKey === 'font') {
    return (
      <FontLayout
        {...defaultProps}
      />
    )
  }
  else {
    console.log('element settings input layout not found', {layoutKey})
    return null;
  }
};

export default ElementSettingsInputLayout;
