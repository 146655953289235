import React from "react";
import MediaDraggable from "editor/draggables/MediaDraggable/MediaDraggable";
import BaseComponentSerializer from "editor/serializers/BaseComponentSerializer";
import {useAssetConfig} from "hooks/useAssetConfig";

class MediaDraggableSerializer extends BaseComponentSerializer {
  constructor(elem, editorData) {
    super(elem, editorData);
    this.propsToRemove = ['layerIconNode', 'key'];
    this.assetConfig = useAssetConfig();
  }

  deserialize() {
    return {
      tag: MediaDraggable,
      tagName: 'MediaDraggable',
      props: {
        //layerLabel: this.assetConfig.MediaDraggable.props.layerLabel,
        layerIconNode: this.assetConfig.MediaDraggable.sharedProps.layerIconNode
      },
      baseData: {
        ...this.elem?.baseData,
        layerIconNode: this.assetConfig.MediaDraggable.sharedProps.layerIconNode
      }
    }
  }

  serialize() {
    return {
      tag: 'MediaDraggable',
      id: this.elem.id,
      draggableProps: {
        style: {
          ...this.getDraggablePosition()
        }
      },
      props: {
        ...this.formatProps({...this.elem.props}),
        //style: {...this.getElementStyles()}
      },
      baseData: {
        ...this.formatProps(this.elem.baseData)
      },
      breakpointData: {
        ...this.formatProps(this.elem.breakpointData)
      }
    };
  }
}

export default MediaDraggableSerializer;
