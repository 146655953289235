import ContainerDraggable from "editor/draggables/ContainerDraggable/ContainerDraggable";
import {instanceOf} from "prop-types";
import {toast} from "react-toastify";

export const isFunction = (functionToCheck) => {
  return functionToCheck && {}.toString.call(functionToCheck) === '[object Function]';
};

export const generateId = () => {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
    let r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
    return v.toString(16);
  });
};

export const unCamelCase = (str) => {
  return str
    // insert a space between lower & upper
    .replace(/([a-z])([A-Z])/g, '$1 $2')
    // space before last upper in a sequence followed by lower
    .replace(/\b([A-Z]+)([A-Z])([a-z])/, '$1 $2$3')
    // uppercase the first character
    .replace(/^./, function(str){ return str.toUpperCase(); })
}

export const capitalizeFirstLetter = (string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export const isObjectEmpty = (obj) => {
  return Object.keys(obj).length === 0;
};

export const getElementsAtClientCoordinates = (clientX, clientY) => {
  return document.elementsFromPoint(clientX, clientY);
};

export const splitCamelCaseToString = (s) => {
  return s.split(/(?=[A-Z])/).map(function(p) {
    return p.charAt(0).toUpperCase() + p.slice(1);
  }).join(' ');
};

// returns true if it is a DOM node
export const isNode = (o) => {
  return (
    typeof Node === "object" ? o instanceof Node :
      o && typeof o === "object" && typeof o.nodeType === "number" && typeof o.nodeName==="string"
  );
}

// returns true if it is a DOM element
export const isElement = (o) => {
  return (
    typeof HTMLElement === "object" ? o instanceof HTMLElement : //DOM2
      o && typeof o === "object" && o !== null && o.nodeType === 1 && typeof o.nodeName==="string"
  );
}

export const getNextDOMSiblings = (elem, filter) => {
  let sibs = [];
  while (elem = elem.nextSibling) {
    if (elem.nodeType === 3) continue; // text node
    if (!filter || filter(elem)) sibs.push(elem);
  }
  return sibs;
}

export const getPreviousDOMSiblings = (elem, filter) => {
  let sibs = [];
  while (elem = elem.previousSibling) {
    if (elem.nodeType === 3) continue; // text node
    if (!filter || filter(elem)) sibs.push(elem);
  }
  return sibs;
}

export const getAllDOMSiblings = (elem, filter) => {
  let sibs = [];
  elem = elem.parentNode.firstChild;
  do {
    if (elem.nodeType === 3) continue; // text node
    if (!filter || filter(elem)) sibs.push(elem);
  } while (elem = elem.nextSibling)
  return sibs;
}

export const chunkArray = (arr, size) => {
  let newArr = [];
  for(let i = 0; i < arr.length; i += size) {
    newArr.push(arr.slice(i, i+size));
  }
  return newArr;
}

export const handleNetworkError = (error, returnMessage=false, useToast=true) => {
  console.log('error handler error', error, error.response);
  if (
    error.response
    &&
    error.response.data?.non_field_errors
    &&
    error.response.data?.non_field_errors[0]
  ) {
    // The request was made and the server responded with a status code
    // that falls out of the range of 2xx

    let message = error.response.data?.non_field_errors[0]

    if(useToast) {
      toast.error(message);
    }
    else if(returnMessage) {
      return message;
    }

  } else if (error.request) {
    // The request was made but no response was received
    // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
    // http.ClientRequest in node.js

    let message = 'An error occurred communicating with the server, ' +
      'please check your internet connection.'

    if(useToast) {
      toast.error(message);
    }
    else if(returnMessage) {
      return message;
    }
  } else {
    // Something happened in setting up the request that triggered an Error

    let message = 'A network error occurred.';

    console.log('Error', error.message);

    if(useToast) {
      toast.error(message);
    }
    else if(returnMessage) {
      return message;
    }
  }
  console.log('network error', error);
}
