const ToolboxIconStyle = theme => ({
  root: {
    background: "rgba(43, 43, 55, 1)",
    borderRadius: "9px",
    transition: "0.3s",
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    cursor: 'pointer',
    height: '44px',

    '&:hover': {
      background: "#3a3a4a",
    },
  },

  publicComponent: {
    background: theme.palette.secondary.main,

    '&:hover': {
      background: theme.palette.secondary.dark,
    }
  },

  dropdownIconContainer: {
    marginLeft: 'auto',
    marginRight: '8px'
  },

  button: {
    color: 'white',
    fontFamily: "Roboto",
    padding: "8px",
    paddingLeft: "16px",
    display: "flex",
    alignItems: "center",
    cursor: 'pointer',

    '& .MuiButton-label': {
      justifyContent: "flex-start"
    }
  },

  icon: {
    display: "inline-block",
    marginRight: "8px",

    '& > *': {
      fontSize: "24px"
    }
  },

});

export default ToolboxIconStyle;
