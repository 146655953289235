const IconButtonDraggableStyle = theme => ({
  root: (props) => ({
    '&:hover': {
      'background': props.hoverColor,
    }
  }),

  iconButton: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: props => props?.style?.width || '50px',
    height: props => props?.style?.height || '50px',

    '& svg': {
      fill: props => props.style?.color || '#fff',
      width: props => props?.style?.width || '50px',
      height: props => props?.style?.height || '50px',
    }
  },
});

export default IconButtonDraggableStyle;
