import {IconButton, withStyles} from '@material-ui/core';
import FormatItalicIcon from '@material-ui/icons/FormatItalic';
import FormatUnderlinedIcon from '@material-ui/icons/FormatUnderlined';
import StrikethroughSIcon from '@material-ui/icons/StrikethroughS';
import cn from "classnames";
import ElementSettingsGroupLabel from "components/ElementSettingGroupLabel/ElementSettingsGroupLabel";
import {EditorContext} from "editor/Editor";
import PropTypes from 'prop-types';
import React, {useContext, useEffect, useRef, useState} from 'react';
import TextStyleInputStyle from './TextStyleInputStyle';

const TextStyleInput = props => {
  const {
    classes,
    onChange,
    value,
  } = props;

  const DEFAULT_SELECTED_STYLES = {
    italic: false,
    underline: false,
    lineThrough: false
  };

  const [buttonClicked, setButtonClicked] = useState('');
  const [selectedStyles, setSelectedStyles] = useState({...DEFAULT_SELECTED_STYLES})
  const editorData = useContext(EditorContext);
  const {editorReducerState, dispatch} = editorData;
  const editorTreeActions = editorReducerState.editorState.elementTree.actions;
  const elementSelected = editorReducerState.editorState.elementSelected.value;

  const isMounted = useRef(false);

  const buttonTypeList = [
    {
      type: 'italic',
      icon: (
        <FormatItalicIcon
          className={classes.icon}
        />
      )
    },
    {
      type: 'underline',
      icon: (
        <FormatUnderlinedIcon
          className={classes.icon}
        />
      )
    },
    {
      type: 'lineThrough',
      icon: (
        <StrikethroughSIcon
          className={classes.icon}
        />
      )
    }
  ];

  const applyChanges = () => {
    let stylesToSet = {};

    if(!buttonClicked) return

    for (const [key, value] of Object.entries(selectedStyles)) {
      console.log(`${key}: ${value}`);

      if(key === 'italic' && value) {
        stylesToSet.fontStyle = 'italic'
      }
      else if(key === 'underline' && value) {
        if(selectedStyles.lineThrough) {
          stylesToSet.textDecoration = 'underline line-through'
        }
        else {
          stylesToSet.textDecoration = 'underline'
        }
      }
      else if(key === 'lineThrough' && value) {
        if(selectedStyles.underline) {
          stylesToSet.textDecoration = 'underline line-through'
        }
        else {
          stylesToSet.textDecoration = 'line-through'
        }
      }
    }

    let element = editorTreeActions.findElementInTree(
      elementSelected
    );

    console.log('styles to set', {...stylesToSet})
    console.log('old styles', element.props.style, stylesToSet)
    let resultStyles = {...element.props.style, ...stylesToSet}

    if('textDecoration' in element.props.style && !('textDecoration' in stylesToSet)) {
      resultStyles.textDecoration = 'none';
    }

    if('fontStyle' in element.props.style && !('fontStyle' in stylesToSet)) {
      resultStyles.fontStyle = 'normal';
    }

    onChange(selectedStyles, resultStyles);
  }

  const handleClick = (newButtonClicked) => {
    console.log('new buttonClicked', newButtonClicked)

    if(!newButtonClicked) return

    const newSelectedStyles = {...selectedStyles}
    newSelectedStyles[newButtonClicked] = !newSelectedStyles[newButtonClicked]
    setSelectedStyles(newSelectedStyles)

    setButtonClicked(newButtonClicked)
  }

  useEffect(() => {
    if(value) {
      setSelectedStyles(value)
    }
    else {
      // value is null, so set it to the default
      setSelectedStyles({...DEFAULT_SELECTED_STYLES})
    }
    console.log('value', value)
  }, [value])

  useEffect(() => {
    console.log('selected styles', selectedStyles)
    if(isMounted.current) {
      applyChanges()
    }
    else {
      isMounted.current = true;
    }
  }, [selectedStyles])

  return (
    <div className={classes.outerButtonContainer}>
      <ElementSettingsGroupLabel>
        Text Style
      </ElementSettingsGroupLabel>

      <div className={classes.buttonContainer}>
        {buttonTypeList.map((data, index) => (
          <IconButton
            onClick={() => handleClick(data.type)}
            className={cn(
              classes.button,
              selectedStyles[data.type] && classes.activeButton
            )}
            key={data.type}
          >
            {data.icon}
          </IconButton>
        ))}
      </div>
    </div>
  );
};

TextStyleInput.propTypes = {
  classes: PropTypes.object
};

export default withStyles(TextStyleInputStyle)(TextStyleInput);
