import {CardNumberElement} from "@stripe/react-stripe-js";
import React from 'react';
import {FormControl, InputLabel, FormHelperText, withStyles} from '@material-ui/core';
import StripeCardInputStyle from 'components/StripeInputs/StripeCardInput/StripeCardInputStyle';

const StripeCardInput = (props) => {
  // Replace with actual logic to determine if there's an error
  const {classes} = props;

  const hasError = false;
  const errorMessage = "Invalid input";

  const options = {
    style: {
      base: {
        fontSize: '16px',
        fontFamily: 'Roboto, Open Sans, Segoe UI, sans-serif',
        color: '#FFFFFF',
        '::placeholder': {
          color: '#747374',
          fontWeight: '600'
        },
      },
      invalid: {
        color: '#E51414',
      },
    },
  };

  return (
    <div className={classes.inputRoot}>
      <InputLabel
        shrink
        className={classes.inputLabel}
      >
        Credit Card Number
      </InputLabel>

      <FormControl error={hasError} className={classes.formControl}>
        <div className={classes.stripeInput}>
          <CardNumberElement options={options}/>
        </div>
        {hasError && <FormHelperText>{errorMessage}</FormHelperText>}
      </FormControl>
    </div>

  );
};

export default withStyles(StripeCardInputStyle)(StripeCardInput);
