import Typography from "@material-ui/core/Typography";
import React from 'react';

const WhatAreContainersUsedFor = props => {
  return (
    <>
      <Typography>
        Containers serve to establish your site's structure. They allow you to organize your content in rows or columns by aligning boxes adjacent to each other.
      </Typography>
      <Typography style={{marginTop: '8px'}}>
        <strong>Tip:</strong> To make your containers responsive across all screen sizes while saving time, opt for a page section from the public component panel
        that automatically organizes a container into columns.
      </Typography>

      {/*TODO: CHANGE TIP TO MENTION LAYOUTS ONCE FEATURE ADDED */}
    </>
  );
};

WhatAreContainersUsedFor.propTypes = {
  
};

export default WhatAreContainersUsedFor;