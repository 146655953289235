import {FormControl, FormHelperText, InputLabel, withStyles} from "@material-ui/core";
import StripeCardInputStyle from "components/StripeInputs/StripeCardInput/StripeCardInputStyle";
import StripeCVCInputStyle from "components/StripeInputs/StripeCVCInput/StripeCVCInputStyle";
import React from 'react';
import {CardCvcElement, CardNumberElement} from '@stripe/react-stripe-js';

const StripeCVCInput = (props) => {
  const {classes} = props;

  const hasError = false;
  const errorMessage = "Invalid input";

  const options = {
    style: {
      base: {
        fontSize: '16px',
        fontFamily: 'Roboto, Open Sans, Segoe UI, sans-serif',
        color: '#FFFFFF',
        '::placeholder': {
          color: '#747374',
          fontWeight: '600'
        },
      },
      invalid: {
        color: '#E51414',
      },
    },
  };

  return (
    <div className={classes.inputRoot}>
      <InputLabel shrink className={classes.inputLabel}>CVC</InputLabel>

      <FormControl error={hasError} className={classes.formControl}>
        <div className={classes.stripeInput}>
          <CardCvcElement options={options}/>
        </div>
        {hasError && <FormHelperText>{errorMessage}</FormHelperText>}
      </FormControl>
    </div>
  );
};

export default withStyles(StripeCVCInputStyle)(StripeCVCInput);
