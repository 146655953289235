import StripeFormDialog from "dialogs/StripeFormDialog/StripeFormDialog";
import AccountSettingsDialog from "editor/components/dialogs/AccountSettingsDialog/AccountSettingsDialog";
import AddClassDialog from "editor/components/dialogs/AddClassDialog/AddClassDialog";
import ConfirmationDialog from "editor/components/dialogs/ConfirmationDialog/ConfirmationDialog";
import ExportDialog from "editor/components/dialogs/ExportDialog/ExportDialog";
import FormDraggableDialog from "editor/components/dialogs/FormDraggableDialog/FormDraggableDialog";
import HelpDialog from "editor/components/dialogs/HelpDialog/HelpDialog";
import IconDraggableDialog from "editor/components/dialogs/IconDraggableDialog/IconDraggableDialog";
import loginRegisterDialog from "editor/components/dialogs/LoginRegisterDialog/LoginRegisterDialog";
import LoginRegisterDialog from "editor/components/dialogs/LoginRegisterDialog/LoginRegisterDialog";
import MediaDraggableDialog from "editor/components/dialogs/MediaDraggableDialog/MediaDraggableDialog";
import PreviewDialog from "editor/components/dialogs/PreviewDialog/PreviewDialog";
import RenameAssetDialog from "editor/components/dialogs/RenameAssetDialog/RenameAssetDialog";
import RenamePageDialog from "editor/components/dialogs/RenamePageDialog/RenamePageDialog";
import RenameProjectDialog from "editor/components/dialogs/RenameProjectDialog/RenameProjectDialog";
import RoutingDialog from "editor/components/dialogs/RoutingDialog/RoutingDialog";
import SaveComponentDialog from "editor/components/dialogs/SaveComponentDialog/SaveComponentDialog";
import SubmitBugDialog from "editor/components/dialogs/SubmitBugDialog/SubmitBugDialog";
import SubmitFeedbackDialog from "editor/components/dialogs/SubmitFeedbackDialog/SubmitFeedbackDialog";
import ThemeDialog from "editor/components/dialogs/ThemeDialog/ThemeDialog";
import FormDraggableDialogContext from "editor/context/FormDraggableDialogContext";
import PropTypes from 'prop-types';
import React, {createContext, useMemo, useState} from "react";

export const EditorDialogContext = createContext();

const EditorDialogsProvider = props => {
  const {children} = props;

  // dialogs
  const [iconDraggableOpen, setIconDraggableOpen] = useState(false);
  const [iconDraggableData, setIconDraggableData] = useState({
    editorId: null
  });

  const [mediaDraggableOpen, setMediaDraggableOpen] = useState(false);
  const [mediaDraggableData, setMediaDraggableData] = useState({
    editorId: null
  });

  const [renameProjectDialogOpen, setRenameProjectDialogOpen] = useState(false);
  const [renameProjectDialogData, setRenameProjectDialogData] = useState({});

  const [confirmationDialogOpen, setConfirmationDialogOpen] = useState(false);
  const [confirmationDialogData, setConfirmationDialogData] = useState({
    onSuccess: () => {
      setConfirmationDialogOpen(false)
    },
    text: 'Are you sure?'
  });

  const [routingDialogOpen, setRoutingDialogOpen] = useState(false);
  const [routingDialogData, setRoutingDialogData] = useState({});

  const [accountSettingsDialogOpen, setAccountSettingsDialogOpen] = useState(false);
  const [accountSettingsDialogData, setAccountSettingsDialogData] = useState({});

  const [formDraggableOpen, setFormDraggableOpen] = useState(false);
  const [formDraggableData, setFormDraggableData] = useState({});

  const [submitFeedbackOpen, setSubmitFeedbackOpen] = useState(false);
  const [submitFeedbackData, setSubmitFeedbackData] = useState({});

  const [submitBugOpen, setSubmitBugOpen] = useState(false);
  const [submitBugData, setSubmitBugData] = useState({});

  const [renameAssetOpen, setRenameAssetOpen] = useState(false);
  const [renameAssetData, setRenameAssetData] = useState({});

  const [exportDialogOpen, setExportDialogOpen] = useState(false);
  const [exportDialogData, setExportDialogData] = useState({});

  const [renamePageDialogOpen, setRenamePageDialogOpen] = useState(false);
  const [renamePageDialogData, setRenamePageDialogData] = useState({});

  const [addClassDialogOpen, setAddClassDialogOpen] = useState(false);
  const [addClassDialogData, setAddClassDialogData] = useState({});

  const [themeDialogOpen, setThemeDialogOpen] = useState(false);
  const [themeDialogData, setThemeDialogData] = useState({});

  const [stripeFormDialogOpen, setStripeFormDialogOpen] = useState(false);
  const [stripeFormDialogData, setStripeFormDialogData] = useState({});

  const [loginFormDialogOpen, setLoginFormDialogOpen] = useState(false);
  const [loginFormDialogData, setLoginFormDialogData] = useState({});

  const [saveComponentOpen, setSaveComponentOpen] = useState(false);
  const [saveComponentData, setSaveComponentData] = useState({});

  const [previewDialogOpen, setPreviewDialogOpen] = useState(false);
  const [previewDialogData, setPreviewDialogData] = useState({});

  const [helpDialogOpen, setHelpDialogOpen] = useState(false);
  const [helpDialogData, setHelpDialogData] = useState({});

  const activeDialogs = [
    {
      // required
      type: 'mediaDraggable',
      open: mediaDraggableOpen,
      setOpen: setMediaDraggableOpen,

      // extra data
      dialogData: mediaDraggableData,
      setDialogData: setMediaDraggableData
    },

    //{type: 'iconDraggable', openDialog: false, setOpen: setOpen},

    {
      // required
      type: 'iconDraggable',
      open: iconDraggableOpen,
      setOpen: setIconDraggableOpen,

      // extra data
      dialogData: iconDraggableData,
      setDialogData: setIconDraggableData,
    },
    {
      // required
      type: 'renameProject',
      open: renameProjectDialogOpen,
      setOpen: setRenameProjectDialogOpen,

      // extra data
      dialogData: renameProjectDialogData,
      setDialogData: setRenameProjectDialogData,
    },
    {
      // required
      type: 'confirmation',
      open: confirmationDialogOpen,
      setOpen: setConfirmationDialogOpen,

      // extra data
      dialogData: confirmationDialogData,
      setDialogData: setConfirmationDialogData,
    },
    {
      type: 'routing',
      open: routingDialogOpen,
      setOpen: setRoutingDialogOpen,
      dialogData: routingDialogData,
      setDialogData: setRoutingDialogData
    },
    {
      type: 'accountSettings',
      open: accountSettingsDialogOpen,
      setOpen: setAccountSettingsDialogOpen,
      dialogData: accountSettingsDialogData,
      setDialogData: setAccountSettingsDialogData
    },
    {
      type: 'formDraggable',
      open: formDraggableOpen,
      setOpen: setFormDraggableOpen,
      dialogData: formDraggableData,
      setDialogData: setFormDraggableData
    },
    {
      type: 'submitFeedback',
      open: submitFeedbackOpen,
      setOpen: setSubmitFeedbackOpen,
      dialogData: submitFeedbackData,
      setDialogData: setSubmitFeedbackData
    },
    {
      type: 'submitBug',
      open: submitBugOpen,
      setOpen: setSubmitBugOpen,
      dialogData: submitBugData,
      setDialogData: setSubmitBugData
    },
    {
      type: 'renameAsset',
      open: renameAssetOpen,
      setOpen: setRenameAssetOpen,
      dialogData: renameAssetData,
      setDialogData: setRenameAssetData
    },
    {
      type: 'export',
      open: exportDialogOpen,
      setOpen: setExportDialogOpen,
      dialogData: exportDialogData,
      setDialogData: setExportDialogData,
    },
    {
      type: 'renamePage',
      open: renamePageDialogOpen,
      setOpen: setRenamePageDialogOpen,
      dialogData: renamePageDialogData,
      setDialogData: setRenamePageDialogData,
    },
    {
      type: 'addClass',
      open: addClassDialogOpen,
      setOpen: setAddClassDialogOpen,
      dialogData: addClassDialogData,
      setDialogData: setAddClassDialogData,
    },
    {
      type: 'theme',
      open: themeDialogOpen,
      setOpen: setThemeDialogOpen,
      dialogData: themeDialogData,
      setDialogData: setThemeDialogData
    },
    {
      type: 'stripeForm',
      open: stripeFormDialogOpen,
      setOpen: setStripeFormDialogOpen,
      dialogData: stripeFormDialogData,
      setDialogData: setStripeFormDialogData
    },
    {
      type: 'loginRegister',
      open: loginFormDialogOpen,
      setOpen: setLoginFormDialogOpen,
      dialogData: loginFormDialogData,
      setDialogData: setLoginFormDialogData
    },
    {
      type: 'saveComponent',
      open: saveComponentOpen,
      setOpen: setSaveComponentOpen,
      dialogData: saveComponentData,
      setDialogData: setSaveComponentData
    },
    {
      type: 'preview',
      open: previewDialogOpen,
      setOpen: setPreviewDialogOpen,
      dialogData: previewDialogData,
      setDialogData: setPreviewDialogData
    },
    {
      type: 'help',
      open: helpDialogOpen,
      setOpen: setHelpDialogOpen,
      dialogData: helpDialogData,
      setDialogData: setHelpDialogData
    }
  ]

  const iconDraggableDialog = useMemo(() => (
    <IconDraggableDialog
      open={iconDraggableOpen}
      setOpen={setIconDraggableOpen}
      data={iconDraggableData}
    />
  ), [iconDraggableOpen, iconDraggableData])

  return (
    <EditorDialogContext.Provider value={activeDialogs}>
      <MediaDraggableDialog
        open={mediaDraggableOpen}
        setOpen={setMediaDraggableOpen}
        data={mediaDraggableData}
      />

      {iconDraggableDialog}

      <RenameProjectDialog
        open={renameProjectDialogOpen}
        setOpen={setRenameProjectDialogOpen}
        data={renameProjectDialogData}
      />
      <ConfirmationDialog
        open={confirmationDialogOpen}
        setOpen={setConfirmationDialogOpen}
        data={confirmationDialogData}
      />
      <RoutingDialog
        open={routingDialogOpen}
        setOpen={setRoutingDialogOpen}
        data={routingDialogData}
      />
      <AccountSettingsDialog
        open={accountSettingsDialogOpen}
        setOpen={setAccountSettingsDialogOpen}
        data={accountSettingsDialogData}
      />

      <FormDraggableDialogContext>
        <FormDraggableDialog
          open={formDraggableOpen}
          setOpen={setFormDraggableOpen}
          data={formDraggableData}
        />
      </FormDraggableDialogContext>

      <SubmitFeedbackDialog
        open={submitFeedbackOpen}
        setOpen={setSubmitFeedbackOpen}
        data={submitFeedbackData}
      />
      <SubmitBugDialog
        open={submitBugOpen}
        setOpen={setSubmitBugOpen}
        data={submitBugData}
      />
      <RenameAssetDialog
        open={renameAssetOpen}
        setOpen={setRenameAssetOpen}
        data={renameAssetData}
      />
      <ExportDialog
        open={exportDialogOpen}
        setOpen={setExportDialogOpen}
        data={exportDialogData}
      />
      <RenamePageDialog
        open={renamePageDialogOpen}
        setOpen={setRenamePageDialogOpen}
        data={renamePageDialogData}
      />
      <AddClassDialog
        open={addClassDialogOpen}
        setOpen={setAddClassDialogOpen}
        data={addClassDialogData}
      />
      <ThemeDialog
        open={themeDialogOpen}
        setOpen={setThemeDialogOpen}
        data={themeDialogData}
      />
      <StripeFormDialog
        open={stripeFormDialogOpen}
        setOpen={setStripeFormDialogOpen}
        data={stripeFormDialogData}
      />
      <LoginRegisterDialog
        open={loginFormDialogOpen}
        setOpen={setLoginFormDialogOpen}
        data={loginFormDialogData}
      />
      <SaveComponentDialog
        open={saveComponentOpen}
        setOpen={setSaveComponentOpen}
        data={saveComponentData}
      />
      <PreviewDialog
        open={previewDialogOpen}
        setOpen={setPreviewDialogOpen}
        data={previewDialogData}
      />
      <HelpDialog
        open={helpDialogOpen}
        setOpen={setHelpDialogOpen}
        data={helpDialogData}
        setData={setHelpDialogData}
      />
      {children}
    </EditorDialogContext.Provider>
  );
};

EditorDialogsProvider.whyDidYouRender = true;

EditorDialogsProvider.propTypes = {
  children: PropTypes.array.isRequired
};

export default EditorDialogsProvider;
