const CheckboxDraggableStyle = theme => ({
  checkbox: (props) => ({
    position: 'static',
    padding: '0',
  }),

  checkboxIcon: {
    width: '40px',
    height: '40px'
  }
});

export default CheckboxDraggableStyle;
