import {purpleButton} from "assets/theme";

const PaymentStyle = theme => ({
  purpleButton: {
    ...purpleButton,
    marginTop: "8px",
  },

});

export default PaymentStyle;