import Typography from "@material-ui/core/Typography";
import React from 'react';
import PropTypes from 'prop-types';

const HowToStyleContainers = props => {
  return (
    <>
      {/*TODO: REMOVE TIP ONCE UNIT LAYOUT ADDED*/}
      <Typography>
        Upon selecting a container, a settings panel appears on the right-hand side of the editor.
        This panel offers various customization options, such as setting the width and height, selecting a fill color, and styling the border.
      </Typography>
      <Typography style={{marginTop: '8px'}}>
        <strong>Tip:</strong> You can specify width and height in either pixel values or percentages. For a full-width box, simply enter '100%' as the width.
      </Typography>
    </>
  );
};

HowToStyleContainers.propTypes = {

};

export default HowToStyleContainers;