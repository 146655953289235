const AssetPanelStyle = theme => ({
  root: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "flex-start",
    position: "absolute",
    zIndex:999,
    right: 'calc((100% * -1) + 30px)',
  },

  closeButton: {
    color: 'white',
  },

  closeButtonContainer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    marginTop: '-2px'
  },

  panelRoot: {
    width: '270px',
    display: 'flex',
    padding: '8px',
    justifyContent: 'flex-start',
    flexDirection: 'column',
    alignItems: 'flex-start',
    background: '#1F212A',
    height: 'calc(100vh - 55px)',
  },

  assetPanelTitle: {
    width: '100%',
    flex: 0,
    marginBottom: '8px'
  },

  draggableComponentsInnerContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: "flex-start",
    alignItems: "flex-start",
    width: '100%',
    overflowY: 'auto',
    flex: 1,

    '&::-webkit-scrollbar': {
      height: '2px',
      width: '2px',
      position: 'relative',
      marginLeft: '9.33px',
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: 'white',
    },
    '&::-webkit-scrollbar-track': {
      backgroundColor: '#262626',
      opacity: '0.48'
    },

    '& > *': {
      margin: '8px'
    }
  },
});

export default AssetPanelStyle;
