const ThemePanelStyle = theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    overflowY: "auto",
    height: '100%',

    '&::-webkit-scrollbar': {
      height: '2px',
      width: '2px',
      position: 'relative',
      marginLeft: '9.33px',
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: 'white',
    },
    '&::-webkit-scrollbar-track': {
      backgroundColor: '#262626',
      opacity: '0.48'
    },
  },

  title: {
    marginTop: '6px',
    marginBottom: '10px',
    marginLeft: '6px',
  },

  innerContainer: {
    backgroundColor: "#181A1E",
    margin: '6px',
    padding: "6px",
    borderRadius: "5px",

    display: 'flex',
    flexDirection: 'column',
    flex: 1,

    '& > *': {
      margin: '3px'
    }
  },

  colorPickerSection: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    width: '100%'
  },

  arrowContainer: {
    position: 'relative',
    top: '5px',
    left: '2px',
    marginRight: 'auto',
    cursor: 'pointer'
  },

  colorPickerSectionHeader: {
    background: "rgba(43, 43, 55, 1)",
    borderRadius: "9px",
    transition: "0.3s",
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    cursor: 'pointer',
    height: '44px',
    width: '100%',

    '&:hover': {
      background: "#3a3a4a",
    },
  },


  button: {
    color: 'white',
    fontFamily: "Roboto",
    padding: "8px",
    paddingLeft: "16px",
    display: "flex",
    alignItems: "center",
    cursor: 'pointer',

    '& .MuiButton-label': {
      justifyContent: "flex-start"
    }
  },

  dropdownIconContainer: {
    marginLeft: 'auto',
    marginRight: '8px'
  },

  colorPickerSectionInnerHeader: {
    fontSize: '15px',
    fontWeight: '400',
    fontFamily: 'Roboto'
  },

  form: {
    width: '100%'
  },

  formInner: {
    width: '100%',
    padding: '6px 6px'
  },

  colorPickerInput: {
    maxWidth: '350px'
  },
});

export default ThemePanelStyle;