import {getTopmostEditorElementFromPath} from "lib/draggables/draggables";

export const insertElement = elementArr => {
  /**
   * @param {Array} elementArr - An array of DOM objects representing where the new element is "landing"
   *
   * insertElement is designed to handle the "dropping" of elements either onto the editor or onto
   * container elements. It is a "set it and forget it" callback function that will handle everything else.
   */

  let editorElem = getTopmostEditorElementFromPath(elementArr);
  console.log('inserting onto:', editorElem);
  console.log('test', editorElem?.getBoundingClientRect());


}
