const MediaDraggableDialogStyle = theme => ({
  closeIcon: {
    color: "white",

    '&:hover':{
      background: "#3a3a4a",
      transition: "0.3s",
    },
  },

  dialog: {
    height: "85%",
    maxHeight: "720px",
    minHeight: "600px",
  },

  dialogTitle: {
    width: "100%",

    '& .MuiTypography-root': {
      display: "flex",
      flexDirection: "row",
      width: "100%",
      justifyContent: "space-between",
      alignItems: 'center'
    },
  },

  dialogContent: {
    padding: 0,
    paddingBottom: "16px",
    height: "100%"
  },

  outerDialogContentContainer: {
    height: "100%",
    padding: "0 16px",
    marginRight: "8px",
  },

  innerDialogContentContainer: {
    background: "#F5F0FF",
    minHeight: "100%",
    width: "700px",
    height: "100%",
    color: "black",
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "flex-start",
    borderRadius: "3px",
  },

  listContainer: {
    width: "200px",
    padding: 0,
    height: "100%",
    background: "rgba(138, 86, 255, 0.2)",
  },

  icon: {
    color: "black",
  },

  helpIcon: {
    marginRight: '8px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',

    '& > *': {
      fontSize: '30px',
      width: '30px',
      height: '30px',
      transition: '0.3s',
      cursor: 'pointer',
      color: '#D2D2D2',

      '&:hover': {
        color: theme.palette.secondary.main
      }
    }
  },

  dialogTitleButtons: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
  },

  listOuterContainer: {
    background: "rgba(138, 86, 255, 0.2)",
    height: "100%",
  },

  dialogContentTitle: {
    color: "#404040",
  },

  divider: {
    borderBottom: "1px solid rgba(125, 125, 125, 0.6)",
    width: "100%",
  },

  pageContainer: {
    flex: "1",
    display: "flex",
    padding: "20px",
    height: "100%",
  },

  activeListItem: {
    background: theme.palette.secondary.main,
    color: "white",

    '& .MuiSvgIcon-root': {
      color: "white",
    },
  },
});

export default MediaDraggableDialogStyle;
