import {PhotoAlbumOutlined, PhotoLibraryOutlined} from "@material-ui/icons";
import CloseIcon from "@material-ui/icons/Close";
import CloudUploadOutlinedIcon from "@material-ui/icons/CloudUploadOutlined";
import HelpIcon from "@material-ui/icons/Help";
import cn from "classnames";
import {UserContext} from "context/UserContext";
import OurImages from "editor/components/dialogs/MediaDraggableDialog/OurImages/OurImages";
import UploadMedia from "editor/components/dialogs/MediaDraggableDialog/UploadMedia/UploadMedia";
import YourImages from "editor/components/dialogs/MediaDraggableDialog/YourImages/YourImages";
import {EditorContext} from "editor/Editor";
import {useEditorDialog} from "hooks/useEditorDialog";
import React, {useContext, useEffect, useMemo, useState} from 'react';
import {
  Dialog, DialogContent, DialogTitle, IconButton, List, ListItem, ListItemIcon, ListItemText, Tooltip,
  withStyles
} from '@material-ui/core';
import PropTypes from 'prop-types';
import {Unsplash, User} from "services";
import {handleNetworkError} from "utils/utils";
import MediaDraggableDialogStyle from './MediaDraggableDialogStyle';

const MediaDraggableDialog = props => {
  const {
    classes,
    open,
    setOpen,
    data,
  } = props;

  const [page, setPage] = useState(0)
  const [selectedImage, setSelectedImage] = useState(null)
  const [completedSelection, setCompletedSelection] = useState(false)
  const [initialOurImagesCount, setInitialOurImagesCount] = useState(0);
  const [initialOurImagesList, setInitialOurImagesList] = useState([]);
  const [initialYourImagesList, setInitialYourImagesList] = useState([]);
  const [yourImageList, setYourImageList] = useState([])

  const helpDialog = useEditorDialog('help');
  const editorData = useContext(EditorContext);
  const {userData, setUserData} = useContext(UserContext);
  const userIsSubscribed = userData?.subscriptionStatus;

  const {editorReducerState, dispatch} = editorData;

  const treeActions
    = editorReducerState.editorState.elementTree.actions;

  useEffect(() => {
    let treeElement = null

    try{
      console.log('treeElement', treeElement)
      if(selectedImage && treeActions.findElementInTree && treeActions.replaceElementInTree && !completedSelection) {
        treeElement = treeActions.findElementInTree(data.editorId)
        treeElement.props.selectedMedia = selectedImage

        if(data?.handleMediaSelectedCallback) {
          data.handleMediaSelectedCallback(selectedImage)
        }

        console.log('add to tree from media dialog')

        dispatch({type: 'replaceAsset', payload: {originalElemId: data.editorId, newTreeElem: treeElement}})

        setSelectedImage(null);
        setCompletedSelection(true)
        handleClose()
      }
    }
    catch (error) {
      console.error(error)
    }
  }, [editorReducerState, treeActions, selectedImage, data, dispatch])

  useEffect(() => {
    setCompletedSelection(false)
  }, [data])

  // load initial data
  useEffect(() => {
    if(userIsSubscribed) {
      getUnsplashMedia();
    }

    getUserMedia();
  }, [userIsSubscribed])

  const handleClose = () => {
    setOpen(false)
    setSelectedImage(null)

    if(data?.editorId && !selectedImage && !(!!data?.handleMediaSelectedCallback)) {
      console.log('delete asset')
      dispatch({type: 'deleteAsset', payload: {editorId: data.editorId}})
    }
  }

  const handlePageChange = (page) => {
    setPage(page)
  };


  const getUnsplashMedia = () => {
    Unsplash.getAllPhotos()
      .then(response => {
        console.log("initial unsplash load successful!", response)
        setInitialOurImagesCount(response.data.count);
        setInitialOurImagesList(response.data.results)
      })
      .catch(handleNetworkError)
  }

  // const getUserMedia = () => {
  //   if(busy) return;
  //
  //   setBusy(true)
  //
  //   User.getMedia().then((response) => {
  //     console.log('success', response)
  //
  //     setYourImageList(response.data.library)
  //   }).catch((error) => {
  //     console.log('error', error)
  //     setYourImageList([])
  //   }).finally(() => {
  //     setBusy(false)
  //   })
  // }

  const getUserMedia = () => {
    User.getMedia().then((response) => {
      console.log('initial your images load successful!', response)

      setYourImageList(response.data.library)
    }).catch(error => {
      console.log('An error occurred getting user media', error);
    })
  }

  const handleHelpClick = () => {
    helpDialog.appendDialogData({
      category: 'media'
    })
    helpDialog.openDialog();
  }

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      maxWidth={'lg'}
      classes={{paper: classes.dialog}}
    >
      <DialogTitle className={classes.dialogTitle}>
        Insert Media

        <div className={classes.dialogTitleButtons}>
          <div onClick={handleHelpClick} className={classes.helpIcon}>
            <Tooltip title={'Need Help?'}>
              <HelpIcon/>
            </Tooltip>
          </div>

          <IconButton
            onClick={handleClose}
            className={classes.closeIcon}
            size={'small'}
          >
            <CloseIcon/>
          </IconButton>
        </div>
      </DialogTitle>

      <DialogContent className={classes.dialogContent}>
        <div className={classes.outerDialogContentContainer}>
          <div className={classes.innerDialogContentContainer}>

            <div className={classes.listOuterContainer}>
              <List className={classes.listContainer}>
                <ListItem
                  button={page !== 0}
                  onClick={() => {handlePageChange(0)}}
                  className={page === 0 ? classes.activeListItem : null}
                >
                  <ListItemIcon className={classes.icon}>
                    <CloudUploadOutlinedIcon/>
                  </ListItemIcon>
                  <ListItemText primary={"Upload Media"}/>
                </ListItem>

                <ListItem
                  button={page !== 1}
                  onClick={() => {handlePageChange(1)}}
                  className={page === 1 ? classes.activeListItem : null}
                >
                  <ListItemIcon className={classes.icon}>
                    <PhotoAlbumOutlined/>
                  </ListItemIcon>
                  <ListItemText primary={"Your Images"}/>
                </ListItem>

                <ListItem
                  button={page !== 2}
                  onClick={() => {handlePageChange(2)}}
                  className={page === 2 ? classes.activeListItem : null}
                >
                  <ListItemIcon className={classes.icon}>
                    <PhotoLibraryOutlined/>
                  </ListItemIcon>
                  <ListItemText primary={"Our Images"}/>
                </ListItem>
              </List>
            </div>

            <div className={classes.pageContainer}>
              <UploadMedia
                dialogClasses={classes}
                style={{display: page === 0 ? "flex": "none"}}
                setImageList={setYourImageList}
              />
              <YourImages
                dialogClasses={classes}
                setSelectedImage={setSelectedImage}
                style={{display: page === 1 ? "flex": "none"}}
                initialImages={initialYourImagesList}
                currentImageList={yourImageList}
                setCurrentImageList={setYourImageList}
                setPage={setPage}
              />
              <OurImages
                dialogClasses={classes}
                setSelectedImage = {setSelectedImage}
                style={{display: page === 2 ? "flex": "none"}}
                initialImages={initialOurImagesList}
                initialImageCount={initialOurImagesCount}
              />
            </div>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
};

MediaDraggableDialog.propTypes = {
  classes: PropTypes.object,
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
  data: PropTypes.object,
};

export default withStyles(MediaDraggableDialogStyle)(MediaDraggableDialog);
