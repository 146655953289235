const NotificationCardStyle = theme => ({
  checkboxContainer: {
    color: "#404040",
  },

  checkboxIcon: {
    color: '#363744'
  },

  checkedIcon: {
    color: theme.palette.secondary.main
  },
});

export default NotificationCardStyle;