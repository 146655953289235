import CheckBoxIcon from "@material-ui/icons/CheckBox";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import Autocomplete from "@material-ui/lab/Autocomplete";
import cn from "classnames";
import ElementSettingsGroupLabel from "components/ElementSettingGroupLabel/ElementSettingsGroupLabel";
import StyledTextField from "components/StyledTextField/StyledTextField";
import React, {useEffect, useState} from 'react';
import {Checkbox, withStyles} from '@material-ui/core';
import PropTypes from 'prop-types';
import CursorInputStyle from './CursorInputStyle';
import cursorOptions from "editor/layout/ElementSettings/Inputs/CursorInput/CursorTypes";

const CursorInput = props => {
  const {
    classes,
    onChange,
    onBlur,
    onFocus,
    value,
    setting,
  } = props;

  const [cursorType, setCursorType] = useState('none')
  const [touched, setTouched] = useState(false);
  const [checked, setChecked] = useState(false)

  useEffect(() => {
    console.log('cursor type changed', cursorType)
    if(touched) {
      onChange(cursorType)
    }
  }, [cursorType, touched])

  useEffect(() => {
    console.log('cursor input value changed', value)
    setCursorType(value);

    if(value && value !== 'none') {
      setChecked(true);
    }
    else {
      setChecked(false)
    }
  }, [value])

  useEffect(() => {
    return () => {
      setTouched(false);
    }
  })

  const handleCheckboxChange = (event) => {
    setChecked(event.target.checked)
  };

  const handleAutoCompleteChange = (e, value, reason) => {
    setTouched(true);
    setCursorType(value);
  }

  return (
    <div className={classes.root}>
      <ElementSettingsGroupLabel setting={setting}>
        Cursor
      </ElementSettingsGroupLabel>

      <div className={classes.header}>
        <Checkbox
          checked={checked}
          onChange={handleCheckboxChange}
          color={'secondary'}
          size={'medium'}
          className={classes.checkbox}
          checkedIcon={
            <CheckBoxIcon
              className={cn(classes.checkboxIcon, classes.checkedIcon)}
            />
          }
          icon={
            <CheckBoxOutlineBlankIcon
              className={classes.checkboxIcon}
            />
          }
        />
        <Autocomplete
          freeSolo
          disabled={!checked}
          size={'small'}
          value={cursorType}
          ListboxProps={{className: classes.listBox}}
          classes={{root: classes.autocompleteRoot}}
          options={
            cursorOptions.map((option) => option)
          }
          onChange={handleAutoCompleteChange}
          renderInput={(params) => (
            <StyledTextField
              {...params}
              className={classes.input}
              variant="outlined"
              placeholder="Cursor Type"
              onFocus={onFocus}
              onBlur={onBlur}
            />
          )}
        />
      </div>
    </div>
  );
};

CursorInput.propTypes = {
  classes: PropTypes.object,
  onChange: PropTypes.func.isRequired,
  setting: PropTypes.object.isRequired,
  value: PropTypes.string,
};

export default withStyles(CursorInputStyle)(CursorInput);
