import {FormControl, FormHelperText, InputLabel, withStyles} from "@material-ui/core";
import StripeExpirationInputStyle from "components/StripeInputs/StripeExpirationInput/StripeExpirationInputStyle";
import React from 'react';
import {CardExpiryElement} from '@stripe/react-stripe-js';

const StripeExpirationInput = (props) => {
  const {classes} = props;

  const hasError = false;
  const errorMessage = "Invalid input";

  const options = {
    style: {
      base: {
        fontSize: '16px',
        fontFamily: 'Roboto, Open Sans, Segoe UI, sans-serif',
        color: '#FFFFFF',
        '::placeholder': {
          color: '#747374',
          fontWeight: '600'
        },
      },
      invalid: {
        color: '#E51414',
      },
    },
  };

  return (
    <div className={classes.inputRoot}>
      <InputLabel
        shrink
        className={classes.inputLabel}
      >
        Expiration
      </InputLabel>

      <FormControl
        error={hasError}
        className={classes.formControl}
      >
        <div className={classes.stripeInput}>
          <CardExpiryElement options={options}/>
        </div>
        {hasError && <FormHelperText>{errorMessage}</FormHelperText>}
      </FormControl>
    </div>
  );
};

export default withStyles(StripeExpirationInputStyle)(StripeExpirationInput);
