import {DEFAULT_TREE} from "editor/constants/editorConstants";
import {useSerializerUtils} from "editor/serializers/useSerializerUtils";
import React from "react";
import {useAssetConfig} from "hooks/useAssetConfig";

import {useScreenshot} from "use-react-screenshot";

import html2canvas from "html2canvas";

import {generateId} from "utils/utils";

import APPLICATION_SETTINGS from "settings";

export const useProjectSerializer = (editorData = null, dispatch) => {

  const editorNode = editorData?.editorState.editorNode;
  const [image, takeScreenshot] = useScreenshot()
  const getImage = () => takeScreenshot(editorNode)
  const assetConfig = useAssetConfig();

  const {
    recursivelySerializeChildren,
    recursivelyDeserializeChildren,
    getComponentSerializer
  } = useSerializerUtils(editorData);

  const takeCustomScreenshot = async () => {

    let editorNodeBounds = editorNode.getBoundingClientRect();
    let zoomLevel = editorData.editorSettings.zoomLevel;

    let calculatedWidth = editorNodeBounds.width * (1/zoomLevel);
    let calculatedHeight = editorNodeBounds.height * (1/zoomLevel);

    editorNode.style.fontFeatureSettings = '"liga" 0';

    let reactRoot = document.getElementById('root');
    let clonedZoomContainer = document.querySelector('#zoom-container').cloneNode(true);

    clonedZoomContainer.style.transform = "scale(1.0)";
    clonedZoomContainer.style.position = 'absolute';
    clonedZoomContainer.style.top = '100000px'
    clonedZoomContainer.id = 'zoom-container-screenshot';

    // this stops the container from freaking out when we create the copied zoom container
    let previousRootOverflow = reactRoot.style.overflow;
    let previousHtmlOverflow = document.documentElement.style.overflow;

    reactRoot.style.overflow = 'hidden';
    document.documentElement.style.overflow = 'hidden';

    reactRoot.append(clonedZoomContainer);

    console.log('cloned zoom container', {
      clonedZoomContainer,
    })

    let clonedEditorNode = clonedZoomContainer.querySelector('#editor-root');

    console.log('html2canvas options', {
      width: calculatedWidth > 1900 ? 1900 : calculatedWidth,
      height: calculatedHeight > 1600 ? 1600 : calculatedHeight,
      useCORS: false,
      allowTaint: false,
      proxy: `${APPLICATION_SETTINGS.DJANGO_API_URL}/image-proxy/`
    })

    let canvas = (await html2canvas(clonedEditorNode, {
      width: calculatedWidth > 1900 ? 1900 : calculatedWidth,
      height: calculatedHeight > 1600 ? 1600 : calculatedHeight,
      useCORS: false,
      allowTaint: false,
      proxy: `${APPLICATION_SETTINGS.DJANGO_API_URL}/image-proxy/`
    }))

    let dataUrl = canvas.toDataURL('image/jpeg', 0.6);

    console.log('dataUrl', dataUrl);

    clonedZoomContainer.remove();

    reactRoot.style.overflow = previousRootOverflow;
    document.documentElement.style.overflow = previousHtmlOverflow;

    return dataUrl;

    // let screenshot = (await toCanvas(editorNode, {
    //   cacheBust: true
    // })).toDataURL()
  }

  const deserialize = (project) => {
    // convert from serialized data to de-serialized data
    // and add the elements to the project
    console.log('de serialize and add to project', {project})

    // pull out "addToTree"
    //const {addToTree} = editorData.editorState.elementTree.actions;

    let deserializedPages = [];
    let currentPageCount = 1;

    for (let page of project.data.pages) {

      let deserializedPageElements = [];
      for (let topLevelElem of page.tree) {
        // every page has a string representation of a component, convert to the
        // actual react class
        let deserializedTopLevelElem = {...topLevelElem};

        // replace the string version of the tag with a real react component
        //deserializedTopLevelElem.tag = getComponentInstanceFromTag(topLevelElem.tag);

        let serializer = getComponentSerializer(deserializedTopLevelElem.tag, deserializedTopLevelElem);
        let componentData = serializer.deserialize();

        deserializedTopLevelElem = {
          ...deserializedTopLevelElem,
          ...componentData,
          props: {
            ...deserializedTopLevelElem.props,
            ...componentData.props
          },
          baseData: {
            ...deserializedTopLevelElem.baseData,
            ...componentData.baseData
          }
        };

        console.log('deserialized top level elem', deserializedTopLevelElem);

        let deserializedChildren = recursivelyDeserializeChildren(topLevelElem)

        let newDeserializedPageElem = {
          ...deserializedTopLevelElem,
          children: deserializedChildren,
        };

        deserializedPageElements.push(newDeserializedPageElem);
      }

      deserializedPages.push({
        ...page,
        ref: null,
        tree: {
          'editor-root': {
            ...DEFAULT_TREE['editor-root'],
            children: deserializedPageElements
          }
        }
      });
    }
    return {
      ...project,
      pages: deserializedPages
    };
  }

  const takeScreenshotOfProject = async () => {
    return await takeCustomScreenshot()
  }



  const serializeProject = async (takeScreenshot=true) => {
    if (!editorData) {
      throw new Error('Editor Context is required to serialize and was not provided.')
    }

    const pageData = [...editorData.editorState.editorPages.value];
    const settingsData = {...editorData.editorSettings};
    const assetCounts = {
      ...editorData.editorState.assetCounts
    }
    const canvasData = {
      ...editorData.editorState.canvasSettings
    }
    const layerLabelsInUse = [
      ...editorData.editorState.layerLabelsInUse
    ]
    const currentProjectData = {
      ...editorData.editorState.currentProjectData.value
    }
    const themeSettings = {
      ...editorData.editorState.themeSettings
    }

    console.log('editor data when serializing', {
      editorData,
      pageData,
      settingsData,
      assetCounts,
      canvasData,
      layerLabelsInUse,
      currentProjectData,
      themeSettings
    });

    let screenshot = null;
    if(takeScreenshot) {
       screenshot = await takeScreenshotOfProject();
    }

    // TODO: we need to loop through each "asset" in pageData, find the corresponding serializer,
    // then serialize and move on
    let serializedPages = [];

    for (let page of pageData) {
      // in each page, remove "ref" because it just corresponds to a label, which
      // we'll recreate on load
      delete page.ref;

      console.log('save page test', {page, editorData});

      // if the page is the same as the selected page, copy the current element tree to the page's
      // without this, the page will save it's old tree
      if (page.labelId === editorData.editorState.editorPages.selectedPage) {
        console.log('page is the same as the selected page', {page, editorData})
        page.tree = {...editorData.editorState.elementTree.value};
      }

      // now loop through children and serialize
      let serializedAssets = [];
      for (let childElem of page.tree['editor-root'].children) {
        console.log('child elem', childElem);

        //let serializer = new ContainerDraggableSerializer(childElem, editorData);
        let serializer = getComponentSerializer(childElem.tag.serializedName, childElem);
        console.log('serializer', serializer);

        // component is serialized, now serialize and attach children
        let serializedComponent = serializer.serialize();

        // serialize children
        let serializedChildren = recursivelySerializeChildren(childElem);

        serializedComponent.children = serializedChildren;

        // for(let i of childElem.children) {
        //   let childSerializer = getComponentSerializer(i.tag.serializedName, i);
        //   let serializedChild = childSerializer.serialize();
        //   console.log('serialized child', serializedChild);
        // }

        serializedAssets.push(serializedComponent);
      }

      serializedPages.push({
        ...page,
        canvasSettings: {
          ...page.canvasSettings,
          currentCanvasBreakpoint: 'desktop'
        },
        tree: serializedAssets,
      });
    }

    const returnData = {
      data: {
        pages: serializedPages,
        settings: settingsData,
        assetCounts,
        canvasData,
        layerLabelsInUse,
        currentProjectData,
        themeSettings
      },
      name: editorData.editorState.currentProjectData?.value?.name || 'New Project',
      screenshot,
    };

    console.log('use project serializer return data', returnData)

    return returnData;
  }

  return {
    serializeProject,
    deserialize
  };
};
