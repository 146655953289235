import {withStyles} from '@material-ui/core';
import Typography from "@material-ui/core/Typography";
import DraggableWrapper from 'editor/draggables/DraggableWrapper/DraggableWrapper';
import formatStyles from "editor/draggables/formatStyles";
import PropTypes from 'prop-types';
import React, {useMemo, useEffect} from 'react';
import ContainerDraggableStyle from './ContainerDraggableStyle';

const ContainerDraggable = props => {
  const {
    classes,
    style,
    children,
    draggableProps,
    link,
    locked,
    container,
    htmlId,
    absolutePositioning,
    editorId,
    // universally provided to all assets to remove position: absolute when a child
    isChildElem
  } = props;

  const {draggableStyles, elementStyles} =
    useMemo(() => formatStyles(style), [style]);

  useEffect(() => {
    console.log('container draggable styles', {draggableStyles, elementStyles})
  }, [draggableStyles, elementStyles])

  return (
    <DraggableWrapper
      resizeable
      container
      editorId={editorId}
      absolutePositioning={absolutePositioning}
      editorDraggable
      // needed to stop snapping back on style change
      isChildElem={isChildElem}
      style={{
        ...draggableProps?.style,
        ...draggableStyles,
        position: absolutePositioning ? 'absolute' : 'static'
      }}
    >
      <div
        className={classes.root}
        data-editorid={editorId}
        data-asset={true}
        id={htmlId}
        data-container={'true'}
        style={{
          ...elementStyles,

          // this should always stay static
          position: 'static',
        }}
      >
        {children}
      </div>
    </DraggableWrapper>
  );
};

ContainerDraggable.propTypes = {
  classes: PropTypes.object,
  style: PropTypes.object.isRequired,
  draggableProps: PropTypes.object,
  editorId: PropTypes.string.isRequired,
  isChildElem: PropTypes.bool,
  children: PropTypes.array,
};

ContainerDraggable.serializedName = 'ContainerDraggable';

ContainerDraggable.editorSettings = [
  {
    type: 'prop',
    key: 'breakpoints',
    tooltip: 'Make an asset visible at the chosen breakpoints: desktop, tablet, and/or mobile.',
    default: {
      visibility: {
        desktop: true,
        tablet: true,
        mobile: true
      },
      styleLink: {
        tablet: true,
        mobile: true,
      }
    },
    label: null,
    inputType: 'breakpoint'
  },

  // {
  //   type: 'prop',
  //   key: 'class',
  //   tooltip: 'Use this to share styles between assets',
  //   default: null,
  //   inputType: 'class',
  //   label: 'Class'
  // },

  {
    type: 'prop',
    key: 'alignment',
    tooltip: 'Use this to align all child assets inside of this asset.',
    default: null,
    inputType: 'alignment',
    label: 'Alignment'
  },

  {
    type: 'layout',
    key: 'twoCol',
    tooltip: 'Accepted units: px, %, em, rem, vw, vh',
    label: 'Position/Size',
    elements: [
      {type: 'style', key: 'left', default: '0px', label: 'X'},
      {type: 'style', key: 'top', default: '0px', label: 'Y'},
      {type: 'style', key: 'width', default: '300px', label: 'W'},
      {type: 'style', key: 'height', default: '300px', label: 'H'},
    ]
  },

  {
    type: 'prop',
    key: 'absolutePositioning',
    tooltip: 'If this is checked, your asset\'s CSS will be written with "position: absolute"',
    label: 'Absolute Positioning',
    default: false,
    inputType: 'boolean'
  },

  // {type: 'prop', key: 'classList', default: null, label: null, inputType: 'class'},

  {
    type: 'style',
    key: 'backgroundColor',
    default: '#FFFFFF',
    label: null,
    inputType: 'color',
    tooltip: 'Accepted values are hexcodes, rgba values (e.g. "rgba(255,255,255,1)") and the value "transparent"'
  },

  {
    type: 'collection',
    label: 'Background Image',
    children: [
      {
        type: 'prop',
        key: 'selectedMedia',
        default: null,
        label: 'Background Image',
        inputType: 'imageFill',
        tooltip: 'You cannot have a background color and a background image, so fill will be set to transparent.'
      },
      {
        type: 'style',
        key: 'backgroundSize',
        default: 'cover',
        label: 'Background Size',
        inputType: 'backgroundSize',
        tooltip: 'Percentage (e.g. "100%" or "100% 100%") and length (px values e.g. "300px") are accepted values.'
      },
      {
        type: 'style',
        key: 'backgroundPosition',
        default: 'top left',
        label: 'Background Position',
        inputType: 'backgroundPosition',
      },
    ]
  },

  {
    type: 'collection',
    label: 'Border',
    children: [
      {type: 'style', key: 'border', default: 'none', label: null, inputType: 'border'},
      {type: 'style', key: 'borderRadius', default: '0px', label: 'Border Radius'},

      {
        type: 'collection',
        label: 'Detailed Border',
        children: [
          {type: 'style', key: 'borderTop', default: 'none', label: 'Top', inputType: 'border'},
          {type: 'style', key: 'borderBottom', default: 'none', label: 'Bottom', inputType: 'border'},
          {type: 'style', key: 'borderLeft', default: 'none', label: 'Left', inputType: 'border'},
          {type: 'style', key: 'borderRight', default: 'none', label: 'Right', inputType: 'border'},
        ]
      }
    ]
  },

  {
    type: 'collection',
    label: 'Padding',
    children: [
      {type: 'style', key: 'paddingTop', default: '0px', label: 'Top', horizontal: false},
      {type: 'style', key: 'paddingBottom', default: '0px', label: 'Bottom', horizontal: false},
      {type: 'style', key: 'paddingLeft', default: '0px', label: 'Left', horizontal: false},
      {type: 'style', key: 'paddingRight', default: '0px', label: 'Right', horizontal: false},
    ]
  },

  {
    type: 'collection',
    label: 'Margin',
    children: [
      {type: 'style', key: 'marginTop', default: '0px', label: 'Top', horizontal: false},
      {type: 'style', key: 'marginBottom', default: '0px', label: 'Bottom', horizontal: false},
      {type: 'style', key: 'marginLeft', default: '0px', label: 'Left', horizontal: false},
      {type: 'style', key: 'marginRight', default: '0px', label: 'Right', horizontal: false},
    ]
  },

  {
    type: 'collection',
    label: 'Effects',
    children: [
      {type: 'style', key: 'opacity', default: '1', label: 'Opacity'},
      {type: 'style', key: 'boxShadow', default: 'none', label: 'Box Shadow', inputType: 'boxShadow'},
      {
        type: 'prop',
        key: 'hoverColor',
        default: '',
        label: 'Hover Color',
        inputType: 'color',
        inputOptions: {
          defaultColor: ''
        }
      },
      {type: 'prop', key: 'link', default: null, label: null, inputType: 'link'},
      {type: 'style', key: 'cursor', default: null, label: null, inputType: 'cursor'},
      {type: 'style', key: 'transform', default: 'none', label: 'Rotation', inputType: 'rotation'},
    ]
  },
];

ContainerDraggable.helpQuestionData = {
  category: 'container',
  helpAssetHeader: {
    assetType: 'Container',
    assetShortDescription: 'Leverage boxes to organize your site. Insert various assets into these boxes to form movable blocks of content.'
  }
}


export default withStyles(ContainerDraggableStyle)(ContainerDraggable);
