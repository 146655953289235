const TextStyleInputStyle = theme => ({
  outerButtonContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "flex-start",
    width: "100%"
  },

  buttonContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    width: "60%",
  },

  button: {
    color: theme.palette.primary.main,

    '&:hover': {
      color: theme.palette.secondary.main
    },
  },

  activeButton: {
    color: theme.palette.secondary.main
  },

  icon: {
    width: "24px",
    height: "24px",
    fontSize: "24px",
  },
});

export default TextStyleInputStyle;