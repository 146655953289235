import Button from "@material-ui/core/Button";
import FormDraggableDialogInputCard
  from "editor/components/dialogs/FormDraggableDialog/FormDraggableDialogInputCard/FormDraggableInputCard";
import {FormDraggableDialogContext} from "editor/context/FormDraggableDialogContext";
import {EditorContext} from "editor/Editor";
import {Form, Formik} from "formik";
import React, {useContext} from 'react';

import {
  DialogActions,
  DialogContent,
  withStyles
} from '@material-ui/core';
import PropTypes from 'prop-types';

import FormDraggableMainPageStyle from './FormDraggableMainPageStyle';

const FormDraggableMainPage = props => {
  const {
    classes,
    handleClose,
  } = props;

  const {
    formDraggableReducerState,
    formDraggableDispatch
  } = useContext(FormDraggableDialogContext);

  const editorData = useContext(EditorContext);
  const {editorReducerState, dispatch} = editorData;

  const {currentFields} = formDraggableReducerState;

  const switchToAddInputPage = () => {
    formDraggableDispatch({
      type: 'changePage',
      payload: {
        page: 'addInput'
      }
    })
  }

  const onSubmit = () => {
    formDraggableDispatch({
      type: 'generateForm',
      payload: {
        currentEditorState: editorReducerState
      }
    });

    handleClose(false);
  };

  return (
    <>
      <DialogContent className={classes.dialogContent}>
        <div className={classes.fieldsContainer}>
          {currentFields.map((data, index) => (
            <FormDraggableDialogInputCard key={index} {...data}/>
          ))}

          <Button
            onClick={switchToAddInputPage}
            className={classes.addInputButton}
          >
            + Add Input
          </Button>
        </div>
      </DialogContent>

      <DialogActions>
        <div className={classes.submitContainer}>
          <Button
            type={'submit'}
            variant={'contained'}
            color={'secondary'}
            className={classes.submitButton}
            onClick={onSubmit}
            disabled={currentFields.length === 0}
          >
            Submit
          </Button>
        </div>
      </DialogActions>
    </>
  );
};

FormDraggableMainPage.propTypes = {
  classes: PropTypes.object,
};

export default withStyles(FormDraggableMainPageStyle)(FormDraggableMainPage);
