const RoutingDialogStyle = theme => ({
  dialogTitle: {
    width: "100%",

    '& .MuiTypography-root': {
      display: "flex",
      flexDirection: "row",
      width: "100%",
      justifyContent: "space-between",
    },
  },
  closeIcon: {
    color: "white",

    '&:hover':{
      background: "#3a3a4a",
      transition: "0.3s",
    },
  },

  notchedOutline: {
    borderColor: "white",
  },

  tableCell: {
    border: "none",
  },

  tableBody: {
    '&::-webkit-scrollbar': {
      height: '100px',
      width: '2px',
      position: 'relative',
      marginRight: '2px',
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: 'white',
    },
    '&::-webkit-scrollbar-track': {
      backgroundColor: '#262626',
      opacity: '0.48'
    },
  },
});

export default RoutingDialogStyle;
