const YourImagesStyle = {
  root: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: 'flex-start',
    width: "100%",
    height: "100%",
  },

  imageContainer: {
    position: "relative",

    '&:hover button': {
      opacity: "1",
    }
  },

  img: {
    '&:hover': {
      transition: "0.5s",
      filter: "brightness(70%)",
      cursor: "pointer",
    },
  },

  deleteIconButton: {
    position: "absolute",
    top: 3,
    right: 3,
    zIndex: 100,
    background: "#FF4755",
    color: "white",
    transition: "0.3s",
    fontSize: "16px",
    opacity: "0",

    '&:hover': {
      background: "#CD404B",
    },
  },

  deleteIcon: {
    width: "18px",
    height: "18px"
  },

  noImagesFound: {
    fontSize: "20px",
    fontWeight: "500",
    color: "rgba(125, 125, 125, 0.8)"
  },

  uploadLink: {
    textDecoration: 'underline',
    cursor: 'pointer'
  }
};

export default YourImagesStyle;
