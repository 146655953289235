import {withStyles} from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import ToolboxIcon from "editor/components/ToolboxIcon/ToolboxIcon";
import {EditorContext} from "editor/Editor";
import LayoutPanelStyle from "editor/layout/LayoutPanel/LayoutPanelStyle";
import React, {useContext, useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {User} from "services";
import {handleNetworkError} from "utils/utils";

const LayoutPanel = props => {
  const {
    classes,
    publicComponents,
    show
  } = props;

  const {editorReducerState, dispatch} = useContext(EditorContext);

  const categories = editorReducerState.editorState.layoutCategories;

  return (
    <div className={classes.root} style={{display: show ? 'flex' : 'none'}}>
      <Typography variant={'h5'} className={classes.title}>
        Layouts
      </Typography>

      <div className={classes.innerContainer}>
        {categories && Object.keys(categories).map((key, index) => {
          let category = {
            name: key,
            variants: categories[key]
          }

          console.log('categories', {categories, publicComponents})

          if (category.variants.length === 0) {
            return null
          }

          return (
            <ToolboxIcon
              component={category}
              className={classes.componentRoot}
              key={category.id}
              publicComponents={publicComponents}
              isLayout={true}
            />
          )
        })}
      </div>
    </div>
  );
};

LayoutPanel.propTypes = {
  classes: PropTypes.object,

  show: PropTypes.bool.isRequired
};

export default withStyles(LayoutPanelStyle)(LayoutPanel);
