const DraggableToolboxAssetStyle = theme => ({
  root: {
    background: "rgba(43, 43, 55, 1)",
    borderRadius: "9px",
    transition: "0.3s",
    maxHeight: "auto",
    width: "200px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    margin: '8px auto',

    '&:hover': {
      background: "#3a3a4a",
      cursor: "pointer"
    },
  },

  button: {
    color: 'white',
    fontFamily: "Roboto",
    padding: "8px 12px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "flex-start",
    height: "100%",
    width: "100%",
    fontSize: '16px !important'
  },

  imageContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: '100%'
  },

  text: {
    height: '32px',
    flexGrow: 0,
    marginBottom: '4px',
    fontSize: '17px',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    position: 'relative',
    top: '2px',

    width: '190px',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  },

  titleRow: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    marginBottom: '6px'
  },

  deleteButton: {
    background: '#1E2329',
    color: 'white',
    height: '26px',
    width: '26px',
    transition: '0.3s',
    cursor: 'pointer',

    '&:hover': {
      background: '#0F1114',
    }
  },

  image: {
    width: '100%',
    height: '100%',
    objectFit: 'scale-down',
  }
});

export default DraggableToolboxAssetStyle;
