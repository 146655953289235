import React, { useRef, useImperativeHandle } from 'react';
import { useTheme } from "@material-ui/core/styles";

const StripeInput = React.forwardRef((props, ref) => {
  const { component: StripeComponent, options, ...otherProps } = props;
  const theme = useTheme();
  const elementRef = useRef();

  useImperativeHandle(ref, () => ({
    focus: () => {
      // Implement focus logic using Stripe Element's focus method
      if (elementRef.current) {
        elementRef.current.focus();
      }
    },
    // Provide a dummy 'value' to satisfy Material-UI's expectations
    get value() {
      return "Stripe Element Value"; // Adjust as necessary
    },
    set value(val) {} // You can potentially handle dynamic value changes here
  }));

  return (
    <StripeComponent
      onReady={(element) => {
        elementRef.current = element; // Now elementRef is the Stripe Element
        console.log("Stripe Element is ready:", element);
      }}
      options={{
        ...options,
        style: {
          base: {
            color: theme.palette.text.primary,
            fontSize: "16px",
            fontFamily: theme.typography.fontFamily,
            "::placeholder": {
              color: 'rgba(255, 255, 255, 0.42)'
            },
            // Add any additional styling you need
          },
          invalid: {
            color: theme.palette.error.main
          }
        }
      }}
      {...otherProps} // Include other props
    />
  );
});

export default StripeInput;
