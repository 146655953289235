const NimbusLogoStyle = theme => ({
  logoContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
  },

  logo: {
    height: props => props.size === 'small' ? '20px' : '30px',
    marginRight: "12px"
  },

  nimbus: {
    color: "white",
    fontFamily: "Montserrat",
    fontWeight: "600",
    fontSize: props => props.size === 'small' ? '1.15rem' : '1.3rem'
  },
});

export default NimbusLogoStyle;