const ContactFooterStyle = theme => ({
  root: {
    width: "100%",
    backgroundColor: "#100F12",
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "flex-start",
    color: "white",

    [theme.breakpoints.down('sm')]: {
      height: "100%",
      alignItems: "center"
    },
  },

  rowContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "flex-start",
    width: "75%",
    margin: "16px",

    [theme.breakpoints.down('sm')]: {
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      textAlign: "center"
    },
  },

  colContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center",
    width: "200px",
    margin: "8px",

    [theme.breakpoints.down('sm')]: {
      marginBottom: "20px",
    },
  },

  internalLinks: {
    textDecoration: "none",
    fontFamily: "Roboto",
    color: "white",
    fontSize: "16px",
    fontWeight: "400",
    transition: "0.3s",
    marginBottom: "15px",
    cursor: 'pointer',

    '&:hover': {
      color: "#8A56FF",
    },
  },

  title: {
    fontFamily: "Roboto",
    fontSize: "21px",
    fontWeight: "500",
    marginBottom: "25px"
  },

  logoContainer: {
    marginBottom: "20px",
  },

  socialContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
  },

  socialLogo: {
    margin: "5px",
    fontSize: "16px",
    transition: "0.3s",
    textDecoration: "none",
    color: "white",

    '&:hover': {
      color: "#8A56FF",
    },
  },

  discord: {
    width: "24",
    height: "28px",
  },
});

export default ContactFooterStyle;
