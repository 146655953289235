import {IconButton, Tooltip, withStyles} from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import DeleteIcon from "@material-ui/icons/Delete";
import MediaGrid from "editor/components/dialogs/MediaDraggableDialog/MediaGrid/MediaGrid";
import YourImagesStyle from "editor/components/dialogs/MediaDraggableDialog/YourImages/YourImagesStyle";
import PropTypes from "prop-types";
import React, {useEffect, useState} from 'react';
import {toast} from "react-toastify";
import {User} from "services";
import {handleNetworkError} from "utils/utils";

const YourImages = props => {
  const {
    dialogClasses,
    classes,
    style,
    setSelectedImage,
    currentImageList,
    initialImages=[],
    setCurrentImageList,
    setPage
  } = props;

  const [busy, setBusy] = useState(false);
  const [imageList, setImageList] = useState(currentImageList)

  useEffect(() => {
    console.log('current images', currentImageList)
    setImageList(currentImageList)
  }, [currentImageList])

  const handleClick = (src) => {
    setSelectedImage(src)
  }

  const getMedia = () => {
    if(busy) return;

    setBusy(true)

    User.getMedia().then((response) => {
      console.log('success', response)

      setImageList(response.data.library)
    }).catch(handleNetworkError).finally(() => {
      setBusy(false)
    })
  }

  const handleDelete = (e, id) => {
    e.preventDefault()
    console.log('User', User)

    setBusy(true)

    User.deleteMedia(id).then((response) => {
      console.log('Success!', response)
      const newImageList = [...imageList]
      const indexToDelete = newImageList.findIndex((elem) => elem.id === id)
      newImageList.splice(indexToDelete, 1)
      setImageList(newImageList)
      setCurrentImageList(newImageList);
    }).catch(handleNetworkError).finally(() => {
      setBusy(false)
    })
  }

  const goToUpload = () => {
    setPage(0)
  }

  return (
    <div className={classes.root} style={{...style}}>
      <Typography
        variant={'subtitle1'}
        className={dialogClasses.dialogContentTitle}
      >
        Your Images
      </Typography>

      <div className={dialogClasses.divider}/>

      <MediaGrid>
        {imageList.map((data, index) => (
          <div key={data.image} className={classes.imageContainer}>

            <Tooltip title={'Delete'}>
              <IconButton
                onClick={(e) => {handleDelete(e, data.id)}}
                size={'small'}
                disabled={busy}
                className={classes.deleteIconButton}
              >
                <DeleteIcon className={classes.deleteIcon}/>
              </IconButton>
            </Tooltip>

            <img src={data.image} onClick={() => handleClick(data.image)} className={classes.img}/>
          </div>
        ))}

        {/* If the user has no images... */}
        {imageList.length === 0 && (
          <Typography
            className={classes.noImagesFound}
            variant={'body1'}
          >
            No images found,{' '}
            <span
              className={classes.uploadLink}
              onClick={goToUpload}
            >
              upload!
            </span>
          </Typography>
        )}
      </MediaGrid>

    </div>
  );
};

YourImages.propTypes = {
  dialogClasses: PropTypes.object,
  classes: PropTypes.object,
  style: PropTypes.object,
  setSelectedImage: PropTypes.func,
  currentImageList: PropTypes.array,
  initialImages: PropTypes.array,
  setPage: PropTypes.func.isRequired,
};

export default withStyles(YourImagesStyle)(YourImages);
