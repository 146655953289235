import CheckBoxIcon from "@material-ui/icons/CheckBox";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import cn from "classnames";
import ElementSettingsGroupLabel from "components/ElementSettingGroupLabel/ElementSettingsGroupLabel";
import React, {useEffect, useState} from 'react';

import {
  Checkbox,
  withStyles
} from '@material-ui/core';
import PropTypes from 'prop-types';

import BooleanInputStyle from './BooleanInputStyle';

const BooleanInput = props => {
  const {
    classes,
    onChange,
    onBlur,
    onFocus,
    value,
    setting,
  } = props;

  const [checked, setChecked] = useState(false);

  const handleCheckboxChange = (e) => {
    setChecked(e.target.checked)
    onChange(e.target.checked);
  }

  useEffect(() => {
    setChecked(value);
  }, [value])

  return (
    <div className={classes.root}>
      <ElementSettingsGroupLabel setting={setting}>
        {setting.label}
      </ElementSettingsGroupLabel>

      <div>
        <Checkbox
          checked={checked}
          onChange={handleCheckboxChange}
          color={'secondary'}
          size={'large'}
          className={classes.checkbox}
          checkedIcon={
            <CheckBoxIcon
              className={cn(classes.checkboxIcon, classes.checkedIcon)}
            />
          }
          icon={
            <CheckBoxOutlineBlankIcon
              className={classes.checkboxIcon}
            />
          }
        />
      </div>
    </div>
  );
};

BooleanInput.propTypes = {
  classes: PropTypes.object
};

export default withStyles(BooleanInputStyle)(BooleanInput);
