const InformationIconStyle = theme => ({
  info: {
    color: theme.palette.common.white,
    position: "relative",
    top: "5px",
    left: "3px",
    fontSize: "20px"
  }
});

export default InformationIconStyle;