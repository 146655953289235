import {GlobalDialogContext} from "context/GlobalDialogContext";
import React, {useCallback, useContext, useEffect, useRef} from "react";

export const useGlobalDialog = (dialogType, dialogData=null, replaceData=false) => {
  const activeDialogs = useContext(GlobalDialogContext);
  const currentDialog = useRef(null);

  const _getCurrentDialog = useCallback((type) => {
    try {
      return activeDialogs.find(elem => elem.type === type);
    } catch {
      return null
    }
  }, [activeDialogs]);

  currentDialog.current = _getCurrentDialog(dialogType);

  if(currentDialog.current && dialogData) {
    if(replaceData) {
      currentDialog.current.setDialogData(dialogData);
    }
    else {
      let newDialogData = {...currentDialog.current.dialogData, ...dialogData};
      currentDialog.current.setDialogData(newDialogData);
    }
  }

  const openDialog = () => {
    if(!currentDialog.current) throw new Error('No dialog to openDialog.');
    currentDialog.current.setOpen(true);
  }

  const closeDialog = () => {
    if(!currentDialog.current) throw new Error('No dialog to closeDialog.');
    currentDialog.current.setOpen(false);
  }

  const appendDialogData = (data, replace=false) => {
    if(!currentDialog.current) throw new Error('No dialog to set data to.');

    if(currentDialog.current && data) {
      if(replace) {
        currentDialog.current.setDialogData(data);
      }
      else {
        let newDialogData = {...currentDialog.current.dialogData, ...data};
        currentDialog.current.setDialogData(newDialogData);
      }
    }
  }

  if(!currentDialog.current) {
    console.error('useGlobalDialog() no dialog found with type', dialogType);
    return null;
  }

  return {
    openDialog,
    closeDialog,
    appendDialogData,
    ...currentDialog.current
  }
};
