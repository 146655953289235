import {Button, Typography, withStyles} from '@material-ui/core';
import {UserContext} from "context/UserContext";
import PropTypes from 'prop-types';
import React, {useContext, useEffect} from 'react';
import {NavLink} from "react-router-dom";
import {User} from "services";
import CookieBarStyle from './CookieBarStyle';

const CookieBar = props => {
  const {
    classes,
    open,
    setOpen
  } = props;

  const {userData, setUserData} = useContext(UserContext);

  const handleAccept = () => {
    User.acceptCookiePolicy()
      .then(response => {
        console.log('cookie policy accepted');

        let newUserData = {...userData};
        newUserData.cookiePolicyAccepted = true;
        setUserData(newUserData)
      })
      .catch(error => {
        console.error('An error occurred connecting to the server');
      })

    setOpen(false);
  }

  useEffect(() => {
    if(userData?.cookiePolicyAccepted === true) {
      setOpen(false);
    }
  }, [userData])

  return (
    <div className={classes.root}>
      <div>
        <Typography className={classes.text}>
          We use cookies to give you the best experience.
          To learn more about our cookies, please read our{' '}
          <NavLink
            to={'/privacy-policy'}
            className={classes.link}
          >
            Privacy Policy.
          </NavLink>
        </Typography>
      </div>

      <div className={classes.buttonContainer}>
        <Button
          onClick={handleAccept}
          className={classes.acceptCookiesButton}
        >
          Accept cookies
        </Button>
      </div>

    </div>
  );
};

CookieBar.propTypes = {
  classes: PropTypes.object
};

export default withStyles(CookieBarStyle)(CookieBar);
