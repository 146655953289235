import Typography from "@material-ui/core/Typography";
import React from 'react';

const NimbusImageLibrary = () => {
  return (
    <>
      <Typography>
        Yes, users with an upgraded account can freely utilize Nimbus' image library, sourced from Unsplash.
      </Typography>
    </>
  );
};

NimbusImageLibrary.propTypes = {

};

export default NimbusImageLibrary;