const TemplatePageStyle = theme => ({
  root: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: 'center',
    width: "100%",
    height: "100%",
  },

  projectNameContainer: {
    marginBottom: "50px",
    color: "white",
    textAlign: 'center'
  },

  gridContainer: {
    display: "grid",
    marginLeft: "200px",
    marginRight: "200px",
    width: "85%",
    gridTemplateColumns: "repeat(auto-fit, minmax(300px, 350px))",
    gridAutoRows: "400px",
    gap: "50px",
    justifyContent: "center",

    [theme.breakpoints.down('md')]: {
      gridTemplateColumns: "1fr 1fr",
    },

    [theme.breakpoints.down('sm')]: {
      gridTemplateColumns: "1fr",
    },
  },

  blankCanvasContainer: {
    backgroundColor: "#1B1C20",
    borderRadius: "17px",
    color: "white",
    padding: "12px",
    textAlign: "center",
    justifyContent: "center",
    alignItems: "center",
    display: "flex",
    flexDirection: "column",
    cursor: "pointer",
    transition: "0.3s",

    '&:hover': {
      filter: 'brightness(85%)'
    },
  },

  pencil: {
    width: "63px",
    height: "63px",
    color: "#8A56FF",
  },

  projectContainer: {
    backgroundColor: "#1B1C20",
    borderRadius: "17px",
    color: "white",
    padding: "12px",
    cursor: 'pointer',
    transition: '0.3s',

    '&:hover': {
      filter: 'brightness(85%)'
    },
  },

  projectImage: {
    width: "100%",
    height: '300px',
    objectFit: 'cover',
    objectPosition: 'center top'
  },
});

export default TemplatePageStyle;