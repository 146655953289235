import {Card, CardActions, CardContent, TextField} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import cn from "classnames";
import {Form, Formik} from "formik";
import PropTypes from "prop-types";
import React, {useState} from 'react';
import {toast} from "react-toastify";
import {User} from "services";
import {handleNetworkError} from "utils/utils";

const ChangePasswordCard = props => {
  const {
    pageClasses,
  } = props;

  const [busy, setBusy] = useState(false);

  const onChangePasswordSubmit = async (values, {setErrors}) => {
    console.log('change password', values);

    setBusy(true)

    User.authenticatedPasswordChange(values.password, values.newPassword)
      .then(response => {
        console.log('success!', response);
        toast.success('Password successfully changed!')
      })
      .catch(handleNetworkError)
      .finally(() => {
        setBusy(false);
      })
  };

  return (
    <Card className={pageClasses.pageCard}>
      <CardContent>
        <Typography
          variant={'h6'}
          className={pageClasses.pageContentTitle}
        >
          Change Password
        </Typography>

        <Formik
          initialValues={{
            password: '',
            newPassword: '',
            confirmNewPassword: '',
          }}

          onSubmit={onChangePasswordSubmit}
        >
          {({
              values,
              errors,
              handleChange
            }) => (
            <Form className={pageClasses.cardContent}>
              <div className={pageClasses.colContainer}>
                <Typography
                  variant={'subtitle1'}
                  className={cn(pageClasses.pageContentTitle, pageClasses.colTitle)}
                >
                  Current Password
                </Typography>
                <TextField
                  variant={'outlined'}
                  size={'small'}
                  name={'password'}
                  fullWidth
                  type={'password'}
                  onChange={handleChange}
                  value={values.password}
                  error={!!errors.password}
                  helperText={errors.password}
                  className={pageClasses.textfield}
                  InputProps={{
                    classes: {
                      notchedOutline: pageClasses.notchedOutline,
                    },
                  }}
                />
              </div>

              <div className={pageClasses.colContainer}>
                <Typography
                  variant={'subtitle1'}
                  className={cn(pageClasses.pageContentTitle, pageClasses.colTitle)}
                >
                  New Password
                </Typography>
                <TextField
                  variant={'outlined'}
                  size={'small'}
                  name={'newPassword'}
                  fullWidth
                  type={'password'}
                  onChange={handleChange}
                  value={values.newPassword}
                  error={!!errors.newPassword}
                  helperText={errors.newPassword}
                  className={pageClasses.textfield}
                  InputProps={{
                    classes: {
                      notchedOutline: pageClasses.notchedOutline,
                    },
                  }}
                />
              </div>

              <div className={pageClasses.colContainer}>
                <Typography
                  variant={'subtitle1'}
                  className={cn(pageClasses.pageContentTitle, pageClasses.colTitle)}
                >
                  Confirm New Password
                </Typography>
                <TextField
                  variant={'outlined'}
                  size={'small'}
                  name={'confirmNewPassword'}
                  fullWidth
                  type={'password'}
                  onChange={handleChange}
                  value={values.confirmNewPassword}
                  error={!!errors.confirmNewPassword}
                  helperText={errors.confirmNewPassword}
                  className={pageClasses.textfield}
                  InputProps={{
                    classes: {
                      notchedOutline: pageClasses.notchedOutline,
                    },
                  }}
                />
              </div>

              <CardActions className={pageClasses.buttonContainer}>
                <Button
                  type={'submit'}
                  variant={'contained'}
                  disabled={busy}
                  className={pageClasses.purpleButton}
                >
                  Submit
                </Button>
              </CardActions>
            </Form>
          )}
        </Formik>
      </CardContent>
    </Card>
  );
};

ChangePasswordCard.propTypes = {
  pageClasses: PropTypes.object,
};

export default ChangePasswordCard;
