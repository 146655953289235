import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Button, Tooltip, withStyles} from '@material-ui/core';
import {faGripVertical} from "@fortawesome/free-solid-svg-icons";
import {faGripHorizontal} from "@fortawesome/free-solid-svg-icons";
import {ToggleButton, ToggleButtonGroup} from "@material-ui/lab";
import cn from "classnames";
import ElementSettingsGroupLabel from "components/ElementSettingGroupLabel/ElementSettingsGroupLabel";
import {EditorContext} from "editor/Editor";
import PropTypes from 'prop-types';
import React, {useContext, useEffect, useRef, useState} from 'react';
import AlignmentInputStyle from './AlignmentInputStyle';

const AlignmentInput = props => {
  const {
    classes,
    onChange,
    setting,
    value,
    name,
    label,
    variant,

    // this is a special case for the canvas alignment input
    // this makes it so we don't measure before submitting, since
    // we don't need to measure the canvas
    isCanvasInput=false,

    // this needs to be used in tandem with above so we can skip
    // the normal onChange
    onCanvasChange=null
  } = props;

  const [flexDirection, setFlexDirection] = useState('row')
  const [alignment, setAlignment] = useState('top-left')
  const editorData = useContext(EditorContext);
  const {editorReducerState, dispatch} = editorData;
  const editorTreeActions = editorReducerState.editorState.elementTree.actions;
  const editorActions = editorReducerState.editorActions;
  const selectedElemBounds = editorReducerState.editorState.elementSelected.currentBounds;
  const elementSelected = editorReducerState.editorState.elementSelected.value;

  const isMounted = useRef(false);
  const loadedCurrentValues = useRef(false);

  // generate a list based on whether the flexDirection is a row or container
  // this is written as a function so we can supply a flex direction to compare values
  // this is mostly used in the useEffect that handles the value prop
  // (used for preserving the value when de-selecting an asset)
  const generateButtonTypeList = (suppliedFlexDirection=flexDirection) => {
    return [
      {
        type: "top-left",
        stylesToSet: {
          display: 'flex',
          justifyContent: 'flex-start',
          alignItems: 'flex-start'
        }
      },
      {
        type: "top-center",
        stylesToSet: {
          display: 'flex',
          justifyContent: suppliedFlexDirection === 'row' ? 'center': 'flex-start',
          alignItems: suppliedFlexDirection === 'row' ? 'flex-start': 'center',
        }
      },
      {
        type: "top-right",
        stylesToSet: {
          display: 'flex',
          justifyContent: suppliedFlexDirection === 'row' ? 'flex-end': 'flex-start',
          alignItems: suppliedFlexDirection === 'row' ? 'flex-start': 'flex-end',
        }
      },
      {
        type: "center-left",
        stylesToSet: {
          display: 'flex',
          justifyContent: suppliedFlexDirection === 'row' ? 'flex-start': 'center',
          alignItems: suppliedFlexDirection === 'row' ? 'center': 'flex-start',
        }
      },
      {
        type: "center",
        stylesToSet: {
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center'
        }
      },
      {
        type: "center-right",
        stylesToSet: {
          display: 'flex',
          justifyContent: suppliedFlexDirection === 'row' ? 'flex-end': 'center',
          alignItems: suppliedFlexDirection === 'row' ? 'center': 'flex-end',
        }
      },
      {
        type: "bottom-left",
        stylesToSet: {
          display: 'flex',
          justifyContent: suppliedFlexDirection === 'row' ? 'flex-start': 'flex-end',
          alignItems: suppliedFlexDirection === 'row' ? 'flex-end': 'flex-start',
        }
      },
      {
        type: "bottom-center",
        stylesToSet: {
          display: 'flex',
          justifyContent: suppliedFlexDirection === 'row' ? 'center': 'flex-end',
          alignItems: suppliedFlexDirection === 'row' ? 'flex-end': 'center',
        }
      },
      {
        type: "bottom-right",
        stylesToSet: {
          display: 'flex',
          justifyContent: 'flex-end',
          alignItems: 'flex-end'
        }
      },
    ]
  }

  const buttonTypeList = generateButtonTypeList(flexDirection);

  const handleFlexDirection = (e, newAlignment) => {
    if(newAlignment) {
      setFlexDirection(newAlignment)
    }
  }

  const handleAlignmentClick = (buttonType) => {
    setAlignment(buttonType)
  };

  const applyChanges = () => {
    let stylesToSet = buttonTypeList.find(elem => elem.type === alignment).stylesToSet;
    //let newBounds;

    if(!isCanvasInput || !onCanvasChange) {
      // the normal method of applying
      //newBounds = editorActions.measureElementBounds(elementSelected);

      // no need to round, this function already does that
      //stylesToSet.width = newBounds.width + 'px';
      //stylesToSet.height = newBounds.height + 'px';

      let resultStyles = {...stylesToSet, flexDirection};

      onChange({alignment, flexDirection}, resultStyles);
    }
    else {
      let resultStyles = {...stylesToSet, flexDirection};

      onCanvasChange({alignment, flexDirection}, resultStyles);
    }
  }

  useEffect(() => {
    if(isMounted.current) {
      if(!loadedCurrentValues.current) {
        applyChanges()
      }
    }
    else {
      isMounted.current = true;
    }

    loadedCurrentValues.current = false;
  }, [flexDirection, alignment])

  useEffect(() => {
    console.log('alignment input value', value);

    if(value?.alignment && value?.alignment !== alignment) {
      loadedCurrentValues.current = true;
    }
    else if(value?.flexDirection && value?.flexDirection !== flexDirection) {
      loadedCurrentValues.current = true;
    }

    if(value?.alignment) {
      setAlignment(value?.alignment);
    }
    else {
      setAlignment('top-left');
    }

    if(value?.flexDirection) {
      setFlexDirection(value?.flexDirection);

    }
    else {
      setFlexDirection('row')
    }
  }, [value])

  return (
    <div className={classes.column}>
      <ElementSettingsGroupLabel setting={setting}>
        Alignment
      </ElementSettingsGroupLabel>

      <div className={classes.row}>
        <div className={classes.gridContainer}>
          {buttonTypeList.map((data, index) => (
            <Button
              onClick={() => handleAlignmentClick(data.type)}
              className={cn(
                classes.button,
                data.type === alignment && classes.selectedAlignment
              )}
              key={data.type}
            />
          ))}
        </div>

        <div className={classes.flexDirectionContainer}>
          <ToggleButtonGroup
            value={flexDirection}
            exclusive
            onChange={handleFlexDirection}
          >
            <ToggleButton
              value="row"
              classes={{
                selected: classes.selected
              }}
            >
              <Tooltip title={'Row'}>
                <div>
                  <FontAwesomeIcon icon={faGripHorizontal} className={classes.icon}/>
                </div>
              </Tooltip>
            </ToggleButton>
            <ToggleButton
              value="column"
              classes={{
                selected: classes.selected
              }}
            >
              <Tooltip title={'Column'}>
                <div>
                  <FontAwesomeIcon icon={faGripVertical} className={classes.icon}/>
                </div>
              </Tooltip>
            </ToggleButton>
          </ToggleButtonGroup>
        </div>
      </div>
    </div>
  );
};

AlignmentInput.propTypes = {
  classes: PropTypes.object,
  onChange: PropTypes.func.isRequired,
  setting: PropTypes.object.isRequired,
};

export default withStyles(AlignmentInputStyle)(AlignmentInput);
