import Typography from "@material-ui/core/Typography";
import React from 'react';

const HowToChangeImageSize = () => {
  return (
    <>
      <Typography style={{marginBottom: '8px'}}>
        To change to size of an image, you can either:
      </Typography>
      <Typography>
        1. Give an image a set width and height under the media settings.
      </Typography>
      <Typography style={{marginBottom: '8px', marginTop: '8px'}}>
        OR
      </Typography>
      <Typography>
        2. Just drag the resize handles to make the image the size you want.
      </Typography>
    </>
  );
};

HowToChangeImageSize.propTypes = {

};

export default HowToChangeImageSize;