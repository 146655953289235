import {CircularProgress, TextField, withStyles} from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import SearchIcon from "@material-ui/icons/Search";
import {Pagination} from "@material-ui/lab";
import {UserContext} from "context/UserContext";
import MediaGrid from "editor/components/dialogs/MediaDraggableDialog/MediaGrid/MediaGrid";
import OurImagesStyle from "editor/components/dialogs/MediaDraggableDialog/OurImages/OurImagesStyle";
import React, {useContext, useEffect, useRef, useState} from 'react';
import {toast} from "react-toastify";
import {Unsplash} from "services";
import PropTypes from "prop-types";
import LockIcon from '@material-ui/icons/Lock';

const OurImages = props => {
  const {
    classes,
    dialogClasses,
    style,
    setSelectedImage,
    initialImages = [],
    initialImageCount = 0
  } = props;

  const [images, setImages] = useState(initialImages)
  const [searchQuery, setSearchQuery] = useState('')
  const [loading, setLoading] = useState(false)
  const [count, setCount] = useState(initialImageCount);
  const [pageNumber, setPageNumber] = useState(1);
  const hasMounted = useRef(false);

  const timeOut = useRef(null);

  const {userData} = useContext(UserContext);

  const UNSPLASH_UTM = 'utm_source=nimbuseditor&utm_medium=referral'

  const handleUnsplashError = (error) => {
    console.log('error', error)
    toast.error("An error has occurred connecting to Unsplash")
  }

  useEffect(() => {
    setPageNumber(1);
  }, [searchQuery])

  useEffect(() => {
    // since we run an initialization function in MediaDraggableDialog.jsx
    // we don't run this to run on load as well
    if(userData?.subscriptionStatus) {
      if (timeOut.current) {
        clearTimeout(timeOut.current)
      }

      timeOut.current = setTimeout(() => {
        let pageToRequest = pageNumber || 1;

        console.log('page to request', pageToRequest)

        if (!searchQuery) {
          Unsplash.getAllPhotos(pageToRequest).then((response) => {
            console.log("Success!", response)
            setCount(response.data.count);
            setImages(response.data.results)
          }).catch(handleUnsplashError)
            .finally(() => {
              setLoading(false)
            })
        } else {
          Unsplash.searchPhotos(searchQuery, pageToRequest).then((response) => {
            console.log("Success!", response)
            setImages(response.data.results)
            setCount(response.data.count);
          }).catch(handleUnsplashError)
            .finally(() => {
              setLoading(false)
            })
        }
      }, 1000)
    }
  }, [searchQuery, pageNumber, userData])

  const triggerUnsplashDownload = (downloadLink) => {
    // this is used to keep us in compliance with unsplash's guidelines
    Unsplash.triggerDownload(downloadLink)
      .then(response => {
        console.log('unsplash download triggered!', response);
      })
      .catch(error => {
        console.log('error triggering unsplash download', error);
      })
  }

  const changeUnsplashPage = (e, page) => {
    setPageNumber(page);
    setLoading(true)
  }

  const handleClick = (data) => {
    console.log('handle unsplash click', data)
    triggerUnsplashDownload(data.links.downloadLocation)

    setSelectedImage(data.urls.small)
  }

  return (
    <div className={classes.root} style={{...style}}>
      <Typography
        variant={'subtitle1'}
        className={dialogClasses.dialogContentTitle}
      >
        Our Images (provided by{' '}
        <a
          className={classes.providedBy}
          href={'https://unsplash.com/'}
        >

          Unsplash
        </a>)
      </Typography>

      <div className={dialogClasses.divider}/>

      {userData?.subscriptionStatus &&
        <TextField
          variant={"outlined"}
          fullWidth
          placeholder={'Search Our Images'}
          className={classes.searchBar}
          InputProps={{
            classes: {
              notchedOutline: classes.notchedOutline,
            },
            startAdornment: <SearchIcon fontSize={'small'} className={classes.searchIcon}/>
          }}
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
        />
      }

      {userData?.subscriptionStatus && loading &&
        <div className={classes.loadingScreen}>
          <CircularProgress
            color={'secondary'}
            size={80}
          />
        </div>
      }

      {userData?.subscriptionStatus && !loading &&
        <MediaGrid>
          {(images.map((data, index) => (
            <div key={data.urls.regular} className={classes.imgContainer}>
              <img
                src={data.urls.regular}
                className={classes.img}
                onClick={() => handleClick(data)}
              />

              <div className={classes.attribution}>
                <Typography variant={'subtitle2'} className={classes.attributionText}>
                  Photo by{' '}
                  <a
                    href={data.user.links.html + '?' + UNSPLASH_UTM}
                    className={classes.attributionLink}
                  >
                    {data.user.name}
                  </a>
                </Typography>
              </div>
            </div>
          )))}
        </MediaGrid>
      }

      {!userData?.subscriptionStatus &&
        <div className={classes.notSubscribed}>
          <LockIcon className={classes.notSubscribedIcon}/>

          <Typography variant={'body1'} align={'center'} className={classes.notSubscribedText}>
            This feature is for Premium users only. Please upgrade to use this feature.
          </Typography>
        </div>
      }

      {userData?.subscriptionStatus &&
        <div className={classes.paginationContainer}>
          <Pagination
            count={count}
            page={pageNumber}
            siblingCount={1}
            onChange={changeUnsplashPage}
            color={'secondary'}
            size={'large'}
            boundaryCount={1}
          />
        </div>
      }
    </div>
  );
};

OurImages.propTypes = {
  classes: PropTypes.object,
  dialogClasses: PropTypes.object,
  style: PropTypes.object,
  setSelectedImage: PropTypes.func.isRequired,
  initialImages: PropTypes.array,
  initialImageCount: PropTypes.number,
};

export default withStyles(OurImagesStyle)(OurImages);
