const ElementSettingCollectionStyle = theme => ({
  root: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    flexDirection: 'column',
    width: '100%',
    paddingBottom: '4px',
  },

  label: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    flexDirection: 'row',
    width: '100%',
    fontSize: '15px',
    cursor: 'pointer',
  },

  collapseInner: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'center',
    width: '100%',
    padding: "5px",
  },

  iconContainer: {
    marginLeft: 'auto'
  },

  collapse: {
    width: '100%'
  },

  inputContainer: {
    margin: '4px 0',
    width: '100%'
  },

  innerCollectionContainer: {
    marginTop: '10px',
    width: '100%'
  }
});

export default ElementSettingCollectionStyle;
