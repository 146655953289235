const UploadMediaStyle =  {
  root: {
    display: "flex",
    flex: 1,
    flexDirection: "column",
  },

  dropZone: {
    border: "2px dashed rgba(125, 125, 125, 1)",
    textAlign: "center",
    padding: "5px",
    marginTop: "20px",
    marginBottom: "20px",
    fontFamily: "Roboto",
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    position: "relative",
    minHeight: "300px",
  },

  dropZoneContainer: {
    display: "flex",
    flex: 1,
  },

  uploadButton: {
    width: "200px",
    marginLeft: "auto",
  },

  dropZoneText: {
    position: "absolute",
    transform: "translate(-50%, -50%)",
    left: "50%",
    top: "50%",
    fontSize: "25px",
    fontWeight: "500",
    color: "rgba(125, 125, 125, 0.8)"
  }
};

export default UploadMediaStyle;