import DocumentDialog from "dialogs/DocumentDialog/DocumentDialog";
import PropTypes from 'prop-types';
import React, {createContext, useMemo, useState} from "react";

export const GlobalDialogContext = createContext();

const GlobalDialogProvider = props => {
  const {children} = props;

  // dialogs
  const [documentDialogOpen, setDocumentDialogOpen] = useState(false);
  const [documentDialogData, setDocumentDialogData] = useState({});

  const activeDialogs = [
    {
      // required
      type: 'document',
      open: documentDialogOpen,
      setOpen: setDocumentDialogOpen,

      // extra data
      dialogData: documentDialogData,
      setDialogData: setDocumentDialogData
    },
  ]

  return (
    <GlobalDialogContext.Provider value={activeDialogs}>
      <DocumentDialog
        open={documentDialogOpen}
        setOpen={setDocumentDialogOpen}
        data={documentDialogData}
      />

      {children}
    </GlobalDialogContext.Provider>
  );
};

GlobalDialogProvider.whyDidYouRender = true;

GlobalDialogProvider.propTypes = {
  children: PropTypes.array.isRequired
};

export default GlobalDialogProvider;
