import {Button, IconButton, withStyles} from '@material-ui/core';
import CancelIcon from "@material-ui/icons/Cancel";
import ElementSettingsGroupLabel from "components/ElementSettingGroupLabel/ElementSettingsGroupLabel";
import {EditorContext} from "editor/Editor";
import {useEditorDialog} from "hooks/useEditorDialog";
import PropTypes from 'prop-types';
import React, {useContext, useEffect, useRef, useState} from 'react';
import ImageFillInputStyle from './ImageFillInputStyle';

const ImageFillInput = props => {
  const {
    classes,
    setting,
    value,
    onChange,
  } = props;

  const mediaDraggableDialog = useEditorDialog('mediaDraggable');
  const editorData = useContext(EditorContext)
  const {dispatch, editorReducerState} = editorData
  let elementSelectedData = editorReducerState.editorState.elementSelected.data;
  let currentBreakpoint = editorReducerState.editorState.canvasSettings.currentCanvasBreakpoint;
  let editorId = editorReducerState.editorState.elementSelected.value
  const [selectedImage, setSelectedImage] = useState(null)

  // a flag to not set the value back if we are already deleting
  const isDeletingImage = useRef(false);

  useEffect(() => {
    // if this flag is set to true, ignore this call and reset it
    if(isDeletingImage.current === true) {
      isDeletingImage.current = false;
    }
    else {
      // image fill is an interesting special case
      // the "value" of image fill is always null, but
      // the real "value" of this input comes from the
      // selected element's "selectedMedia" prop
      // we need to read this prop in order to check
      // the actual value of this input
      if(
        elementSelectedData
        &&
        elementSelectedData?.breakpointData?.[currentBreakpoint]?.selectedMedia
      ) {
        setSelectedImage(elementSelectedData?.breakpointData?.[currentBreakpoint]?.selectedMedia);
      }
    }
  }, [elementSelectedData]);

  const handleMediaSelectedCallback = (newSelectedImage) => {
    setSelectedImage(newSelectedImage);

    onChange(newSelectedImage, {
      backgroundColor: 'transparent'
    });
  }

  const handleOpen = () => {
    mediaDraggableDialog.openDialog();
    console.log('mediaDraggable', mediaDraggableDialog)
    mediaDraggableDialog.setDialogData({editorId, handleMediaSelectedCallback});
  }

  const handleRemove = () => {
    isDeletingImage.current = true;
    onChange(null, {})
    setSelectedImage(null)
  }

  return (
    <div className={classes.root}>
      <ElementSettingsGroupLabel setting={setting}>
       Image Fill
      </ElementSettingsGroupLabel>

      <div className={classes.rowContainer}>
        {selectedImage && (
          <IconButton onClick={handleRemove}>
            <CancelIcon className={classes.cancelIcon}/>
          </IconButton>
        )}
        <Button
          color={'secondary'}
          variant={'contained'}
          className={classes.uploadButton}
          onClick={handleOpen}
        >
          Select
        </Button>
      </div>
    </div>
  );
};

ImageFillInput.propTypes = {
  classes: PropTypes.object,
  setting: PropTypes.object.isRequired,
};

export default withStyles(ImageFillInputStyle)(ImageFillInput);
