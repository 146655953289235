import measureElementBounds from "editor/actions/utils/measureElementBounds";

const insertAsset = (state, treeActions, zoomLevel, toBeInsertedEditorId, parentEditorId) => {

  console.log('insert self into elem', {toBeInsertedEditorId, parentEditorId})
  const rootTreeElem = state.editorState.elementTree.value['editor-root'];


// find in the element tree and append the node this icon represents to the editor
  let parent = treeActions.current.findElementInTree(parentEditorId, rootTreeElem);

  let toBeInsertedTreeElem = treeActions.current.findElementInTree(
    toBeInsertedEditorId,
    rootTreeElem
  );

  // The original parent is used for undoing the action
  let originalParent = treeActions.current.findParentInTree(toBeInsertedEditorId, rootTreeElem);

  // we also want to get original index of the element in the parent
  let originalIndex = originalParent.children.findIndex((child) => child.id === toBeInsertedEditorId);

  if(!toBeInsertedTreeElem) {
    return state;
  }

  let {width, height} = measureElementBounds(toBeInsertedEditorId, treeActions, zoomLevel, true)

  if(parentEditorId === 'editor-root') {
    toBeInsertedTreeElem.draggableProps = {
      ...toBeInsertedTreeElem?.draggableProps,
      style: {
        ...toBeInsertedTreeElem?.draggableProps?.style,
        //position: 'absolute'
      }
    }
  }
  else {
    toBeInsertedTreeElem.draggableProps = {
      ...toBeInsertedTreeElem?.draggableProps,
      style: {
        ...toBeInsertedTreeElem?.draggableProps?.style,
        //position: 'static'
      }
    }
  }

  toBeInsertedTreeElem.props = {
    ...toBeInsertedTreeElem.props,
    isChildElem: parentEditorId !== 'editor-root',
    style: {
      ...toBeInsertedTreeElem.props.style,
      width,
      height,
      //position: parentEditorId === 'editor-root' ? 'absolute' : 'static'
    }
  }

  let postDeleteTreeElems = treeActions.current.deleteFromTree(toBeInsertedTreeElem, rootTreeElem);

  let newElementTree = treeActions.current.addToTree(
    parent,
    {...toBeInsertedTreeElem},
    undefined,
    postDeleteTreeElems
  );

  let return_val = {
    ...state,
    editorState: {
      ...state.editorState,
      elementTree: {
        ...state.editorState.elementTree,
        value: {'editor-root': newElementTree}
      }
    }
  };

  return_val.actionStack.push({
    action: 'insertAsset',
    toBeInsertedEditorId: toBeInsertedEditorId,
    parentEditorId: parentEditorId,
    originalParent: originalParent.id,
    originalIndex: originalIndex,
    //state_diff: state_diff
  });

  return return_val;
}

export default insertAsset;