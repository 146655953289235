import {IconButton, withStyles} from '@material-ui/core';
import DraggableWrapper from "editor/draggables/DraggableWrapper/DraggableWrapper";
import formatStyles from "editor/draggables/formatStyles";
import {useEditorDialog} from "hooks/useEditorDialog";
import PropTypes from 'prop-types';
import React, {useEffect, useMemo, useState} from 'react';
import IconButtonDraggableStyle from './IconButtonDraggableStyle';

const IconButtonDraggable = props => {
  const {
    classes,
    style,
    editorId,
    selectedIcon,
    draggableProps,
    isChildElem,
    absolutePositioning
  } = props;

  const [hasOpened, setHasOpened] = useState(false)
  const iconDraggableDialog = useEditorDialog('iconDraggable');

  const {draggableStyles, elementStyles} =
    useMemo(() => formatStyles(style), [style]);

  const selectedIconProps = {
    style: {
      ...elementStyles,
      height: '100%',
      width: '100%',
      position: 'static',
    },
    "data-editorid": editorId,
    className: classes.root
  }

  const RenderedIcon = selectedIcon ? React.cloneElement(selectedIcon, selectedIconProps) : <div/>

  useEffect(() => {
    if(iconDraggableDialog && !hasOpened && !selectedIcon) {
      handleOpen()
      setHasOpened(true)
    }
  }, [iconDraggableDialog]);

  const handleOpen = () => {
    console.log('icon draggable dialog', iconDraggableDialog)

    iconDraggableDialog.openDialog();
    iconDraggableDialog.setDialogData({editorId});
  }

  return (
    <>
      <DraggableWrapper
        resizeable
        editorId={props.editorId}
        style={{
          ...draggableStyles,
          ...draggableProps?.style,
          position: absolutePositioning ? 'absolute' : 'static'
        }}
        isChildElem={isChildElem}
      >
        <IconButton
          className={classes.iconButton}
          data-editorid={editorId}
          data-asset={true}
        >
          {RenderedIcon}
        </IconButton>
      </DraggableWrapper>
    </>
  );
};


IconButtonDraggable.editorSettings = [
  {
    type: 'prop',
    key: 'breakpoints',
    tooltip: 'Make an asset visible at the chosen breakpoints: desktop, tablet, and/or mobile.',
    default: {
      visibility: {
        desktop: true,
        tablet: true,
        mobile: true
      },
      styleLink: {
        tablet: true,
        mobile: true,
      }
    },
    label: null,
    inputType: 'breakpoint'
  },

  {
    type: 'layout',
    key: 'twoCol',
    tooltip: 'Accepted units: px, %, em, rem, vw, vh',
    label: 'Position/Size',
    elements: [
      {type: 'style', key: 'left', default: '0', label: 'X'},
      {type: 'style', key: 'top', default: '0', label: 'Y'},
      {type: 'style', key: 'width', default: '50px', label: 'W'},
      {type: 'style', key: 'height', default: '50px', label: 'H', requiresUnits: true},
    ]
  },

  {
    type: 'prop',
    key: 'absolutePositioning',
    tooltip: 'If this is checked, your asset\'s CSS will be written with "position: absolute"',
    label: 'Absolute Positioning',
    default: false,
    inputType: 'boolean'
  },


  //{type: 'prop', key: 'classList', default: null, label: null, inputType: 'class'},

  {
    type: 'style',
    key: 'color',
    default: '#000000',
    label: 'Icon Color',
    inputType: 'color',
    inputOptions: {
      defaultColor: '#000000'
    }
  },

  {
    type: 'style',
    key: 'backgroundColor',
    default: '',
    label: 'Background Color',
    inputType: 'color',
    inputOptions: {
      defaultColor: ''
    }
  },

  {
    type: 'collection',
    label: 'Border',
    children: [
      {type: 'style', key: 'border', default: 'none', label: null, inputType: 'border'},
      {type: 'style', key: 'borderRadius', default: '0px', label: 'Border Radius'},

      {
        type: 'collection',
        label: 'Detailed Border',
        children: [
          {type: 'style', key: 'borderTop', default: 'none', label: 'Top', inputType: 'border'},
          {type: 'style', key: 'borderBottom', default: 'none', label: 'Bottom', inputType: 'border'},
          {type: 'style', key: 'borderLeft', default: 'none', label: 'Left', inputType: 'border'},
          {type: 'style', key: 'borderRight', default: 'none', label: 'Right', inputType: 'border'},
        ]
      }
    ]
  },
  {
    type: 'collection',
    label: 'Padding',
    children: [
      {type: 'style', key: 'paddingTop', default: '0px', label: 'Top', horizontal: false},
      {type: 'style', key: 'paddingBottom', default: '0px', label: 'Bottom', horizontal: false},
      {type: 'style', key: 'paddingLeft', default: '0px', label: 'Left', horizontal: false},
      {type: 'style', key: 'paddingRight', default: '0px', label: 'Right', horizontal: false},
    ]
  },

  {
    type: 'collection',
    label: 'Margin',
    children: [
      {type: 'style', key: 'marginTop', default: '0px', label: 'Top', horizontal: false},
      {type: 'style', key: 'marginBottom', default: '0px', label: 'Bottom', horizontal: false},
      {type: 'style', key: 'marginLeft', default: '0px', label: 'Left', horizontal: false},
      {type: 'style', key: 'marginRight', default: '0px', label: 'Right', horizontal: false},
    ]
  },
  {
    type: 'collection',
    label: 'Effects',
    children: [
      {type: 'style', key: 'opacity', default: '1', label: 'Opacity'},
      {
        type: 'prop',
        key: 'hoverColor',
        default: '',
        label: 'Hover Color',
        inputType: 'color',
        inputOptions: {
          defaultColor: ''
        }
      },
      {type: 'style', key: 'boxShadow', default: 'none', label: 'Box Shadow', inputType: 'boxShadow'},
      {type: 'prop', key: 'link', default: null, label: null, inputType: 'link'},
      {type: 'prop', key: 'cursor', default: null, label: null, inputType: 'cursor'},
      {type: 'style', key: 'transition', default: 'none', label: 'Transition', inputType: 'transition'},
      {type: 'style', key: 'transform', default: 'none', label: 'Rotation', inputType: 'rotation'},
    ]
  },
];

IconButtonDraggable.helpQuestionData = {
  category: 'iconButton',
  helpAssetHeader: {
    assetType: 'Icon Button',
    assetShortDescription: 'Icon buttons offer clear call-to-actions (CTAs), serving as effective instruments for' +
      ' boosting user interaction and clicks on your website. You can fully tailor their look and behavior according to your needs.\n'
  }
}

IconButtonDraggable.serializedName = 'IconButtonDraggable';

IconButtonDraggable.propTypes = {
  classes: PropTypes.object,
  style: PropTypes.object.isRequired,
  draggableProps: PropTypes.object,
  editorId: PropTypes.string.isRequired,
  isChildElem: PropTypes.object,
};

export default withStyles(IconButtonDraggableStyle)(IconButtonDraggable);
