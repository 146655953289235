import {EditorContext} from "editor/Editor";
import React, {useContext, useEffect, useState} from 'react';
import Typography from "@material-ui/core/Typography";
import ElementSettingsInput from "editor/layout/ElementSettings/Inputs/ElementSettingsInput";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';

import {
  Collapse,
  withStyles
} from '@material-ui/core';
import PropTypes from 'prop-types';

import ElementSettingCollectionStyle from './ElementSettingCollectionStyle';

const ElementSettingCollection = props => {
  const {
    classes,
    setting,
    initialValues,
    setInitialValues,
    submitForm,
    options = {}
  } = props;

  const [collapsed, setCollapsed] = useState(false);
  const editorData = useContext(EditorContext);
  const { editorReducerState, dispatch } = editorData;
  const { editorState, editorActions } = editorReducerState;
  const elementSelected = editorState.elementSelected.value;

  const toggleCollapse = () => {
    setCollapsed(!collapsed)
  }

  useEffect(() => {
    setCollapsed(false);
  }, [elementSelected])

  return (
    <div className={classes.root}>
      <div className={classes.label} onClick={toggleCollapse}>
        <Typography
          align={'left'}
        >
          {setting.label}
        </Typography>

        <div className={classes.iconContainer}>
          {collapsed ? <ExpandLessIcon/> : <ExpandMoreIcon/>}
        </div>
      </div>

      <Collapse in={collapsed} className={classes.collapse}>
        <div className={classes.collapseInner}>
          {setting.children.map((settingChild, index) => {
            if(settingChild.type === 'collection') {
              return (
                <div key={settingChild.label} className={classes.innerCollectionContainer}>
                  <ElementSettingCollection
                    setting={settingChild}
                    initialValues={initialValues}
                    setInitialValues={setInitialValues}
                    submitForm={submitForm}
                    classes={classes}
                    options={settingChild.inputOptions}
                  />
                </div>
              )
            }
            else {
              return (
                <div className={classes.inputContainer} key={settingChild.key}>
                  <ElementSettingsInput
                    setting={settingChild}
                    initialValues={initialValues}
                    setInitialValues={setInitialValues}
                    submitForm={submitForm}
                    options={settingChild.inputOptions}
                  />
                </div>
              )
            }
          })}
        </div>
      </Collapse>
    </div>
  );
};

ElementSettingCollection.propTypes = {
  classes: PropTypes.object,
  setting: PropTypes.object.isRequired,
};

//ElementSettingCollection.whyDidYouRender = true;

export default withStyles(ElementSettingCollectionStyle)(ElementSettingCollection);
