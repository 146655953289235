import {withStyles} from '@material-ui/core';
import Paper from "@material-ui/core/Paper";
import CanvasSettings from "editor/layout/CanvasSettings/CanvasSettings";
import ElementSettingCollection from "editor/layout/ElementSettings/ElementSettingCollection/ElementSettingCollection";
import ElementSettingsInputLayout
  from "editor/layout/ElementSettings/InputLayouts/ElementSettingsInputLayout";
import ElementSettingsInput from "editor/layout/ElementSettings/Inputs/ElementSettingsInput";
import ContainerDraggableSerializer from "editor/serializers/ContainerDraggableSerializer";
import {Form, Formik} from "formik";
import {isEqual} from 'lodash';
import PropTypes from 'prop-types';
import React, {useCallback, useContext, useEffect, useMemo, useRef, useState} from 'react';
import {EditorContext} from "../../Editor";
import ElementSettingsStyle from './ElementSettingsStyle';
import { Tabs, Tab } from '@material-ui/core';

const ElementSettings = props => {
  const { classes } = props;
  const editorData = useContext(EditorContext);
  const { dispatch, editorReducerState } = editorData;
  const { editorState, editorActions } = editorReducerState;
  const { elementTree } = editorState;
  const elementSelected = editorState.elementSelected.value;
  const elementSelectedBounds = editorState.elementSelected.currentBounds;
  const elementSelectedData = editorState.elementSelected.data;
  const editorBounds = editorState.editorBounds

  const [selectedElementSettings, setSelectedElementSettings] = useState(null);
  const [selectedElemTree, setSelectedElemTree] = useState(null);
  const [elementSelectedState, setElementSelectedState] = useState(null);
  const [initialValues, setInitialValues] = useState({});
  const [sizeWasManuallyChanged, setSizeWasManuallyChanged] = useState(false);
  const [currentTab, setCurrentTab] = useState('canvas');
  const [isAssetTabDisabled, setIsAssetTabDisabled] = useState(true);

  let currentCanvasBreakpoint = editorReducerState.editorState.canvasSettings.currentCanvasBreakpoint;

  // use this to determine if canvas changed by comparing current
  // editorState.currentBreakpoint with this variable
  const previousCanvasSize = useRef('desktop');
  const wasPreviouslyAbsolutelyPositioned = useRef(false);
  const previousElementSelected = useRef(null);

  // similar to above, compare these and if they are different, just update values
  // but don't submit the form
  // prevents useless submission when selecting an element for the first time
  const lastElementSelected = useRef(null);

  useEffect(() => {
    if (elementSelected) {
      if (elementSelectedState !== elementSelected) {
        let element = editorActions.findElementInTree(elementSelected);

        console.log('element settings element found', element)

        setSelectedElementSettings(element?.tag.editorSettings);

        setSelectedElemTree(element);

        if (
          element
          && element.tag?.editorSettings
        ) {

          let initialValues = formatInitialValues(element.tag.editorSettings, element);
          setInitialValues(initialValues);
        }

        setElementSelectedState(elementSelected);
      }
    }
    else {
      // no element selected, so set to null
      // only set this so we reduce renders?
      setElementSelectedState(null)
    }
  }, [elementSelected, editorReducerState]);

  useEffect(() => {
    // TODO: can we rewrite this?? its's breaking the breakpoint stuff
      if(elementSelectedBounds && (elementSelected || previousElementSelected.current)) {

        let valuesToSet;

        let element = editorActions.findElementInTree(elementSelected);

        let newBounds = editorActions.measureElementBounds(elementSelected, true)

        let isVisible = element?.baseData?.breakpoints?.visibility?.[currentCanvasBreakpoint];

        if(
          previousCanvasSize.current === currentCanvasBreakpoint
          &&
          isVisible
          &&
          // this tells us if the user selected a new element and that's why bounds changed,
          // or if they resized the current element
          // if these are the same then they resized the currently selected element
          // if not, then they just clicked on a new one.
          previousElementSelected.current === elementSelected
        ) {
          // if the user manually resized via dragging
          let baseStyle = {...element?.breakpointData[currentCanvasBreakpoint].style};

          let [didWidthChange, didHeightChange] = didWidthOrHeightChange(baseStyle, newBounds);

          valuesToSet = {
            'styles': {
              ...baseStyle,

              // subtract 2 to compensate for the border of the resizable element
              // TODO: how do we handle percentage based widths/heights?
              'width': didWidthChange ? newBounds.width + 'px' : baseStyle.width,
              'height': didHeightChange ? newBounds.height + 'px' : baseStyle.height,
              'left': newBounds.x + 'px',
              'top': newBounds.y + 'px',
            },
            'newProps': {
              ...element?.baseData,
              ...element?.breakpointData[currentCanvasBreakpoint]
            }
          };
        }
        else {
          let testInitialValues = formatInitialValues(element?.tag?.editorSettings, element)
          // size didn't change here, the user either selected a new element or the canvas changed
          valuesToSet = {
            'styles': {
              ...testInitialValues?.styles,
              ...element?.breakpointData[currentCanvasBreakpoint].style,
            },
            'newProps': {
              ...testInitialValues?.newProps,
              ...element?.baseData,
              ...element?.breakpointData[currentCanvasBreakpoint]
            }
          };
        }

        if(valuesToSet?.newProps?.style) {
          delete valuesToSet.newProps.style;
        }

        // modify initial values based on whether it's absolutely positioned or not
        let modifiedValuesToSet = applyPositionalValues(element, valuesToSet);

        if(!isEqual(modifiedValuesToSet, initialValues)) {
          console.log('values were set', {modifiedValuesToSet})
          setInitialValues(modifiedValuesToSet)
        }
      }

    // set previous element selected either way
    previousElementSelected.current = elementSelected;

    previousCanvasSize.current = editorState.canvasSettings.currentCanvasBreakpoint;

  }, [
    //selectedElemTree,
    elementSelectedBounds,
    currentCanvasBreakpoint,
  ])

  useEffect(() => {
    if(
      // wait for everything to load
      editorState.elementSelected.value
      && editorState.elementSelected.data
      && Object.keys(initialValues).length > 0
    ) {
      let isAbsolutelyPositioned =
        editorState.elementSelected.data?.props?.absolutelyPositioned;

      if(wasPreviouslyAbsolutelyPositioned.current !== isAbsolutelyPositioned) {
        // if these are not equal to each other, then something changed. Otherwise do nothing
        let element = editorActions.findElementInTree(elementSelected);

        let modifiedInitialValues = applyPositionalValues(element, initialValues);
        setInitialValues(modifiedInitialValues)
      }

      wasPreviouslyAbsolutelyPositioned.current = isAbsolutelyPositioned;
    }
  }, [editorState.elementSelected.data, initialValues])

  const didWidthOrHeightChange = (baseStyle, currentBounds) => {
    // this function compares to values and checks if the element size changed
    // it also converts percentage widths into numbers and compares them that way
    let widthChanged = false;
    let heightChanged = false;

    if(baseStyle?.width && typeof baseStyle.width.includes === 'function' && baseStyle.width.includes('%')) {
      // TODO if there's a percentage, then get the editor bounds and multiply it by the percentage
      // then compare those values

      let formattedBaseStyleWidthPercentage = parseInt(baseStyle.width.replace(/\D/g,''))/100;
      let newCalculatedWidth = Math.round((editorBounds.width * formattedBaseStyleWidthPercentage));
      widthChanged = Math.round(currentBounds.width) !== newCalculatedWidth
    }
    else if(baseStyle?.width && typeof baseStyle.width === 'string') {
      let formattedBaseStyleWidth = parseInt(baseStyle.width.replace(/\D/g,''));
      widthChanged = formattedBaseStyleWidth !== currentBounds.width;
    }
    else {
      widthChanged = baseStyle?.width !== currentBounds.width;
    }

    if(baseStyle?.height && typeof baseStyle.height.includes === 'function' && baseStyle.height.includes('%')) {
      //TODO

      let formattedBaseStyleHeightPercentage = parseInt(baseStyle.height.replace(/\D/g,''))/100;
      let newCalculatedHeight = Math.round((editorBounds.height * formattedBaseStyleHeightPercentage));
      heightChanged = Math.round(currentBounds.height) !== newCalculatedHeight;
    }
    else if(baseStyle?.height && typeof baseStyle.height === 'string') {
      let formattedBaseStyleHeight = parseInt(baseStyle.height.replace(/\D/g,''));
      heightChanged = formattedBaseStyleHeight !== currentBounds.height;
    }
    else {
      heightChanged = baseStyle?.height !== currentBounds.height;
    }

    return [widthChanged, heightChanged]
  }

  const applyPositionalValues = (element, newInitialValues) => {
    // this function checks if the element has "position: absolute" and
    // styles it accordingly. Mostly used to remove top/left styles if
    // position absolute is removed

    // isAbsolutelyPositioned && isAbsolutelyPositioned === wasPreviouslyAbsolutelyPositioned.current
    //   ?
    console.log('test', newInitialValues)

    let baseStyle = {...element?.breakpointData[currentCanvasBreakpoint].style};

    let isAbsolutelyPositioned =
      element?.breakpointData[currentCanvasBreakpoint]?.absolutePositioning;

    if(!isAbsolutelyPositioned) {
      newInitialValues.styles.left = baseStyle?.left || '0px';
      newInitialValues.styles.top = baseStyle?.top || '0px';
    }

    return newInitialValues;
  }

  const formatInitialValues = (rawEditorSettings, newElementSelectedTree, newElementSelected) => {
    console.log('format initial values')
    // pull out the default property and return it as a mapping of {key: default}

    let currentCanvasBreakpoint = editorState.canvasSettings.currentCanvasBreakpoint;

    // check the canvas size and merge the canvas props and the base props
    let styles = {...newElementSelectedTree.breakpointData[currentCanvasBreakpoint].style};
    let newProps = {
      ...newElementSelectedTree?.baseData,
      ...newElementSelectedTree?.breakpointData[currentCanvasBreakpoint]
    };

    // add "breakpoints" which is a special prop that is not in the breakpoint data
    newProps.breakpoints = {...newElementSelectedTree?.baseData?.breakpoints}

    // delete this so we can apply the new styles from above
    delete newProps['style'];

    // in some cases we can be trying to get the bounding rect of a dom node that does not exist yet,
    // in these cases all of the values are 0. check for that case here. if this is not true, then use
    // it's measurements as the width and height

    if(elementSelectedBounds && elementSelectedBounds.width !== 0 && elementSelectedBounds.height !== 0) {
      // NOTE: this breaks the percentage width/heights but is it needed?

      // styles.width = elementSelectedBounds.width + 'px';
      // styles.height = elementSelectedBounds.height + 'px';
      // styles.left = elementSelectedBounds.x + 'px';
      // styles.right = elementSelectedBounds.y + 'px';
    }

    for(let x of rawEditorSettings) {
      if(x.type === 'layout') {
        if(!x.elements) continue;

        for(let i of x.elements) {
          // since this is looping through new elements, the code is similar to below,
          // maybe make this a func?
          if(i.type === 'style') {
            styles[i.key] = styles[i.key] || '';
          }
          else if(i.type === 'prop'){
            newProps[i.key] = newProps[i.key] || i.default;
          }
        }
      }
      else if(x.type === 'collection') {
        for(let i of x.children) {
          // since this is looping through new elements, the code is similar to below,
          // maybe make this a func?
          if(i.type === 'style') {
            styles[i.key] = styles[i.key] || '';
          }
          else if(i.type === 'prop'){
            newProps[i.key] = newProps[i.key] || i.default;
          }
        }
      }
      else {
        if(x.type === 'style') {
          styles[x.key] = styles[x.key] || '';
        }
        else if(x.type === 'prop') {
          newProps[x.key] = newProps[x.key] || x.default;
        }
        // TODO: implement this for layouts?
        else if(x.type === 'styleArray') {
          for(let i in x.key) {
            let key = x.key[i];
            styles[key] = styles[key] || '';
          }
        }
      }
    }

    let returnVal = {styles, newProps};
    console.log('format initial values', returnVal)

    return returnVal;
  }

  const removeAllDefaultValues = (currentProps) => {
    let newProps = {...currentProps};

    for(let outerKey of Object.keys(newProps)) {
      if(outerKey === 'styles') {
        for(let innerKey of Object.keys(newProps[outerKey])) {
          if(newProps[outerKey][innerKey] === '') {
            delete newProps[outerKey][innerKey];
            continue;
          }

          // find corresponding style
          let currentSetting = selectedElementSettings.find(elem => elem.key === innerKey);
          if(!currentSetting) {
            for(let i of selectedElementSettings) {
              //stop if found
              if(currentSetting) break;

              if(i.type === 'layout') {
                for(let x of i.elements) {
                  if(x.key === innerKey) {
                    currentSetting = x;
                  }
                }
              }
            }
          }

          if(currentSetting && newProps[outerKey][innerKey] === currentSetting.default) {
            delete newProps[outerKey][innerKey];
          }
        }
      }
    }

    return newProps;
  }

  const formatNewPropsAfterSubmit = (values, originalProps) => {
    let newProps = {...originalProps};

    for(let [key, value] of Object.entries(values)) {
      switch(key) {
        case 'styles':
          // apply styles
          newProps.style = {...value};
          break;
        case 'newProps':
          // apply props
          newProps = {...newProps, ...value};
          break;
        case 'collection':
          break;
        default:
          console.error('invalid element setting type');
          //throw new Error('invalid type');
      }
    }

    let return_data = removeAllDefaultValues(newProps);
    return return_data
  }

  const submitChanges = (valuesToSubmit=initialValues) => {
    // a new element was just selected, don't submit because nothing changed
    if(lastElementSelected.current !== elementSelected) {
      lastElementSelected.current = elementSelected;
      //return;
    }

    // convert the actual props by replacing the tree instance
    console.log('submit settings', {valuesToSubmit})

    let element = editorActions.findElementInTree(elementSelected);

    let newSelfElem = {...element};

    // format both of these with the new props and styles for this breakpoint
    // don't use values, because it's set to the old breakpoint's values
    // and will result in nothing changing even though it should
    let data = {
      ...newSelfElem.baseData,
      ...newSelfElem.breakpointData[currentCanvasBreakpoint]
    };

    let newProps;
    // TODO: do we need this?
    // if(previousCanvasSize.current !== editorState.currentCanvasBreakpoint) {
    //   newProps = {
    //     ...selectedElemTree.baseData,
    //     ...selectedElemTree.breakpointData[currentCanvasBreakpoint]
    //   }
    // }
    // else {
    //   newProps = formatNewPropsAfterSubmit(valuesToSubmit, data);
    // }

    newProps = formatNewPropsAfterSubmit(valuesToSubmit, data);

    previousCanvasSize.current = editorState.canvasSettings.currentCanvasBreakpoint;

    const containerDraggableSerializer = new ContainerDraggableSerializer(element, editorData);
    let draggableProps = containerDraggableSerializer.getDraggablePosition();

    let stylesToApplyToDraggable = ['top', 'left', 'right', 'bottom'];
    let rippedStyles = {};

    for(let i of stylesToApplyToDraggable) {
      if(newProps.style?.[i]) {
        rippedStyles[i] = newProps.style[i];
      }
    }

    // get the current canvas size
    const canvasSize = editorReducerState.editorState.canvasSettings.currentCanvasBreakpoint;

    newSelfElem.baseData.breakpoints = newProps.breakpoints;

    if(newProps?.breakpoints?.visibility?.[canvasSize] === false) {
      newProps.style.display = 'none';
      newProps.style.left = '0px';
      newProps.style.top = '0px';
    }
    else {
      newProps.style.display = 'flex';
    }

    newSelfElem.props = {...newProps};
    newSelfElem.draggableProps = {
      ...draggableProps,
      style: {
        ...draggableProps?.style,
        ...rippedStyles
      }
    };

    console.log('props and draggable props', {newProps, draggableProps, rippedStyles, newSelfElem})

    dispatch({
      type: 'replaceAsset',
      payload: {
        originalElemId: element.id,
        newTreeElem: newSelfElem
      }
    })
  };

  const RenderedElementSettings = useMemo(() => {
    console.log('settings re-rendered')
    return (
      selectedElementSettings && selectedElementSettings.map((setting, index) => {
        const args = {
          setting,
          key: setting.key,
          layoutKey: setting.key,
          initialValues,
          submitForm: submitChanges,
          setInitialValues,
        }

        if (setting.type === 'layout') {
          console.log('layout args', args);
          return <ElementSettingsInputLayout {...args}/>
        }
        else if(setting.type === 'collection') {
          return (
            <ElementSettingCollection
              setting={setting}
              key={setting.key}
              initialValues={initialValues}
              setInitialValues={setInitialValues}
              submitForm={submitChanges}
              options={setting.inputOptions}
            />
          )
        }
        else {
          return (
            <ElementSettingsInput
              setting={setting}
              key={setting.key}
              initialValues={initialValues}
              setInitialValues={setInitialValues}
              submitForm={submitChanges}
              options={setting.inputOptions}
            />
          )
        }
      })
    )
  }, [selectedElementSettings, initialValues])

  useEffect(() => {
    if(elementSelected) {
      setCurrentTab('asset')
      setIsAssetTabDisabled(false)
    } else {
      setCurrentTab('canvas')
      setIsAssetTabDisabled(true)
    }
  }, [elementSelected])

  // Function to handle tab changes
  const handleTabChange = (event, newValue) => {
    setCurrentTab(newValue);
  };

  const form = useMemo(() => {
    console.log('form use memo called')

    return (
      <form className={classes.form} id={'asset-style'}>
        {selectedElementSettings && RenderedElementSettings}
        {/*<Button*/}
        {/*  type={'submit'}*/}
        {/*  variant={'contained'}*/}
        {/*>*/}
        {/*  Change (debug mode)*/}
        {/*</Button>*/}
      </form>
    )
  }, [initialValues, selectedElementSettings])

  // if(!selectedElementSettings || !elementSelectedState) return (
  //   <CanvasSettings className={classes.root} id={'element-settings'} />
  // )

  return (
    <div id={'element-settings'}>
      <Paper className={classes.root} >
        <div className={classes.settingsWrapper}>
          <Tabs value={currentTab} onChange={handleTabChange} className={classes.tabs}>
            <Tab label="Canvas" value="canvas" />
            <Tab label="Asset" value="asset" disabled={isAssetTabDisabled} />
          </Tabs>

          <div className={classes.settingsWrapper}>
            {currentTab === 'canvas' && <CanvasSettings {...props} />}
            {currentTab === 'asset' && form }
          </div>
        </div>



        {/*<div className={classes.editorSwitch}>*/}
        {/*  <Typography*/}
        {/*    variant={'h4'}*/}
        {/*    className={classes.activeEditorText}*/}
        {/*  >*/}
        {/*    Design*/}
        {/*  </Typography>*/}

        {/*  <Typography*/}
        {/*    variant={'h4'}*/}
        {/*    className={classes.inactiveEditorText}*/}
        {/*  >*/}
        {/*    Code*/}
        {/*  </Typography>*/}
        {/*</div>*/}


      </Paper>
    </div>
  );
};

ElementSettings.whyDidYouRender = true;

ElementSettings.propTypes = {
  classes: PropTypes.object
};

export default React.memo(withStyles(ElementSettingsStyle)(ElementSettings));
