import {Dialog, DialogContent, DialogTitle, IconButton, withStyles} from '@material-ui/core';
import CloseIcon from "@material-ui/icons/Close";
import CreateAccountForm from "components/CreateAccountForm/CreateAccountForm";
import LoginForm from "components/LoginForm/LoginForm";
import PropTypes from 'prop-types';
import React, {useState} from 'react';
import {isFunction} from "utils/utils";

import LoginRegisterDialogStyle from './LoginRegisterDialogStyle';

const LoginRegisterDialog = props => {
  const {
    classes,
    open,
    setOpen,
    data,
  } = props;

  const [currentPage, setCurrentPage] = useState('register');

  const handleClose = () => {
    setOpen(false)
  };

  const onSuccess = () => {
    if(data.onSuccess && isFunction(data.onSuccess)) {
      data.onSuccess();
    }

    handleClose();
  }

  const switchDialogPages = (page) => {
    setCurrentPage(page);
  }

  return (
    <Dialog
      open={open}
      onClose={handleClose}
    >
      <DialogTitle className={classes.dialogTitle}>
        <IconButton
          onClick={handleClose}
          className={classes.closeIcon}
          size={'small'}
        >
          <CloseIcon/>
        </IconButton>

      </DialogTitle>
      <DialogContent
        className={classes.dialogContent}
      >
        {currentPage === 'login' ? (
          <LoginForm
            inEditor
            switchDialogPages={switchDialogPages}
            onSuccess={onSuccess}
          />
        ) : (
          <CreateAccountForm
            inEditor
            switchDialogPages={switchDialogPages}
            onSuccess={onSuccess}
          />
        )}

      </DialogContent>
    </Dialog>
  );
};

LoginRegisterDialog.propTypes = {
  classes: PropTypes.object
};

export default withStyles(LoginRegisterDialogStyle)(LoginRegisterDialog);
