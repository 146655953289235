import {Checkbox, Typography, withStyles} from '@material-ui/core';
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import cn from "classnames";
import {useGlobalDialog} from "hooks/useGlobalDialog";
import PropTypes from 'prop-types';
import React from 'react';

import CheckboxTOSAndPPStyle from './CheckboxTOSAndPPStyle';

const CheckboxTOSAndPP = props => {
  const {
    classes,
    checked,
    setChecked,
  } = props;

  const documentDialog = useGlobalDialog('document');

  const openTOS = () => {
    documentDialog.appendDialogData({
      fileName: 'terms_of_service'
    });
    documentDialog.openDialog()
  }

  const openPrivacyPolicy = () => {
    documentDialog.appendDialogData({
      fileName: 'privacy_policy'
    });
    documentDialog.openDialog();
  }

  return (
    <div className={classes.root}>
      <div className={classes.checkboxContainer}>
        <Checkbox
          size={'small'}
          checked={checked}
          checkedIcon={
            <CheckBoxIcon
              className={cn(classes.checkboxIcon, classes.checkedIcon)}
            />
          }
          icon={
            <CheckBoxOutlineBlankIcon
              className={classes.checkboxIcon}
            />
          }
          onChange={() => {
            setChecked(!checked)
          }}
          name={'acceptTerms'}
        />

        <Typography variant={'body1'} className={cn(classes.text, classes.error)}>
          I agree to the{' '}
          <span className={classes.pseudoLink} onClick={() => openTOS()}>
                Terms of Service
              </span>{' '}
          and{' '}
          <span className={classes.pseudoLink} onClick={() => openPrivacyPolicy()}>
                Privacy Policy
              </span>
        </Typography>
      </div>
    </div>
  );
};

CheckboxTOSAndPP.propTypes = {
  classes: PropTypes.object
};

export default withStyles(CheckboxTOSAndPPStyle)(CheckboxTOSAndPP);
