import Typography from "@material-ui/core/Typography";
import React from 'react';

const HowToEditFormFields = () => {
  return (
    <>
      <Typography style={{fontSize: '22px'}}>
        Editing Fields Before Finalizing the Form:
      </Typography>
      <Typography  style={{marginTop: '8px', marginBottom: '8px'}}>
        While still in the form dialog, you have several ways to modify an input:
      </Typography>
      <Typography>
        <strong>Delete:</strong> To remove an input, click the trash can icon.
      </Typography>
      <Typography style={{marginTop: '8px', marginBottom: '8px'}}>
        <strong>Edit:</strong> Click the pencil icon to access the input's details page. Here, you can modify the required fields for that specific input.
      </Typography>
      <Typography>
        <strong>Re-order:</strong> Use the black up and down arrows to change the sequence of the form fields.
      </Typography>
      <Typography style={{fontSize: '22px', marginTop: '16px'}}>
        Editing Fields After Finalizing the Form:
      </Typography>
      <Typography  style={{marginTop: '8px', marginBottom: '8px'}}>
        If the form has already been created and you've exited the form dialog, you still have options to modify an input:
      </Typography>
      <Typography>
        <strong>Delete:</strong> Select the input within the form to reveal an action bar above it. Click the trash can icon to remove the input.
      </Typography>
      <Typography style={{marginTop: '8px', marginBottom: '8px'}}>
        <strong>Edit:</strong> Click the input within the form to display its settings on the right-hand panel.
        This allows you to adjust the required fields like placeholder and name, as well as to change its appearance.
      </Typography>
      <Typography>
        <strong>Re-order:</strong> Select the input within the form to reveal an action bar above it. Use the up or down arrows to change the sequence of the form fields.
      </Typography>
    </>
  );
};

HowToEditFormFields.propTypes = {

};

export default HowToEditFormFields;