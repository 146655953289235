const ContextMenuStyle = theme => ({
  menuList: {
    paddingTop: '0',
    paddingBottom: '0',
    backgroundColor: '#2B2B34',
  },

  menuItemRoot: {
    transition: '0.4s',
    padding: '10px 16px',

    '&:hover': {
      backgroundColor: '#414154'
    }
  },

  contextMenuIconContainer: {
    color: 'white',
    minWidth: '40px',
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'flex-end'
  },

  divider: {
    backgroundColor: '#4A4A54'
  },
});

export default ContextMenuStyle;
