import Typography from "@material-ui/core/Typography";
import React from 'react';

const HowLongDoesExportingTake = () => {
  return (
    <>
      <Typography>
        The time required to export a project varies based on its complexity. However, most projects are usually exported within a few minutes.
        You will receive an email containing a link to your downloadable code once it's ready, or you can periodically check the export dialog.
      </Typography>
    </>
  );
};

HowLongDoesExportingTake.propTypes = {

};

export default HowLongDoesExportingTake;