import {UserContext} from "context/UserContext";
import StripeFormDialog from "dialogs/StripeFormDialog/StripeFormDialog";
import {EditorContext} from "editor/Editor";
import React, {useContext, useEffect, useLayoutEffect, useState} from 'react';

import {
  Button,
  Typography,
  withStyles
} from '@material-ui/core';
import PropTypes from 'prop-types';
import {toast} from "react-toastify";

import SubscriptionBannerStyle from './SubscriptionBannerStyle';

const SubscriptionBanner = props => {
  const { classes } = props;

  const {userData, setUserData} = useContext(UserContext);
  const {editorReducerState, dispatch} = useContext(EditorContext);
  const currentTab = editorReducerState.editorState.currentTab;

  // TODO: we should refactor the stripe dialog and only use it from global or editor dialog provider
  const [stripeDialogOpen, setStripeDialogOpen] = useState(false);
  const [widthOfEditorTools, setWidthOfEditorTools] = useState(405)

  const openAccountUpgradeDialog = () => {
    setStripeDialogOpen(true)
  }

  const onAccountUpgradeSuccess = (data) => {
    console.log('account upgraded!', data);
    toast.success('Account upgraded successfully!');

    setStripeDialogOpen(false);

    let newUserData = {...userData, subscriptionStatus: true};

    if(data?.card?.last4) {
      newUserData.last4 = data.card.last4;
    }

    setUserData(newUserData);
  }

  useEffect(() => {
    let elementSettings = document.querySelector('#element-settings');
    let toolbox = document.querySelector('#toolbox');

    if(currentTab) {
      let elementSettingsWidth = elementSettings.offsetWidth;
      let toolboxWidth = toolbox.offsetWidth;

      let totalWidth = toolboxWidth + elementSettingsWidth + 105;

      console.log('width of editor tools', totalWidth);
      setWidthOfEditorTools(totalWidth);
    }
    else {
      setWidthOfEditorTools(405)
    }
  }, [currentTab])

  return (
    <div
      className={classes.subscriptionBanner}
      style={{
        width: `calc(100% - ${widthOfEditorTools}px)`,
        left: currentTab ? '370px' : '65px'
      }}
    >
      <Typography className={classes.subscriptionText}>
        You have {userData?.freeTrialDaysRemaining} days remaining on your trial.
      </Typography>

      <Button
        className={classes.purpleButton}
        color={'secondary'}
        variant={'contained'}
        size={'small'}
        onClick={openAccountUpgradeDialog}
      >
        Upgrade
      </Button>

      <StripeFormDialog
        open={stripeDialogOpen}
        setOpen={setStripeDialogOpen}
        isEditingCard={false}
        onSuccess={onAccountUpgradeSuccess}
      />
    </div>
  );
};

SubscriptionBanner.propTypes = {
  classes: PropTypes.object
};

export default withStyles(SubscriptionBannerStyle)(SubscriptionBanner);
