import {Dialog, DialogTitle, IconButton, Tooltip, withStyles} from '@material-ui/core';
import CloseIcon from "@material-ui/icons/Close";
import HelpIcon from "@material-ui/icons/Help";
import FormDraggableAddInput
  from "editor/components/dialogs/FormDraggableDialog/FormDraggableDialogAddInput/FormDraggableAddInput";
import FormDraggableMainPage
  from "editor/components/dialogs/FormDraggableDialog/FormDraggableDialogMainPage/FormDraggableMainPage";
import FormDraggableDialogStyle from 'editor/components/dialogs/FormDraggableDialog/FormDraggableDialogStyle';
import FormDraggableInputDetails
  from "editor/components/dialogs/FormDraggableDialog/FormDraggableInputDetails/FormDraggableInputDetails";
import FormDraggableDialogContextProvider, {FormDraggableDialogContext} from "editor/context/FormDraggableDialogContext";
import {EditorContext} from "editor/Editor";
import {useEditorDialog} from "hooks/useEditorDialog";
import PropTypes from 'prop-types';
import React, {useContext, useEffect, useMemo, useState} from 'react';

const FormDraggableDialog = props => {
  const {
    classes,
    open,
    setOpen,
    data,
  } = props;

  const {
    formDraggableReducerState,
    formDraggableDispatch
  } = useContext(FormDraggableDialogContext);

  const editorData = useContext(EditorContext);
  const {editorReducerState, dispatch} = editorData;
  const helpDialog = useEditorDialog('help');

  useEffect(() => {
    formDraggableDispatch({
      type: 'setEditorId',
      payload: {
        editorId: data.editorId
      }
    })
  }, [data])

  const handleClose = (deleteAsset=true) => {
    setOpen(false);
    switchToMainPage()

    console.log('deleting form because it was closed...')

    if(deleteAsset) {
      dispatch({
        type: 'deleteAsset',
        payload: {
          editorId: data.editorId
        }
      })
    }
  }

  const switchToMainPage = () => {
    formDraggableDispatch({
      type: 'changePage',
      payload: {
        page: 'main'
      }
    })
  }

  const getContent = (propsToPass) => {
    switch (formDraggableReducerState.currentPage) {
      case 'main':
        return (
          <FormDraggableMainPage
            {...propsToPass}
          />
        )
      case 'addInput':
        return (
          <FormDraggableAddInput
            {...propsToPass}
          />
        )
      case 'inputDetails':
        return (
          <FormDraggableInputDetails
            {...propsToPass}
          />
        )
      default:
        throw new Error(
          `There is no page ${formDraggableReducerState.currentPage} in the form draggable dialog`
        )
    }
  }

  const content = useMemo(() => {
    return getContent({
      handleClose
    })
  }, [formDraggableReducerState.currentPage])

  const handleHelpClick = () => {
    helpDialog.appendDialogData({
      category: 'form'
    })
    helpDialog.openDialog();
  }

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      maxWidth={'lg'}
      classes={{paper: classes.dialog}}
    >
      <DialogTitle className={classes.dialogTitle}>
        Form

        <div className={classes.dialogTitleButtons}>
          <div onClick={handleHelpClick} className={classes.helpIcon}>
            <Tooltip title={'Need Help?'}>
              <HelpIcon/>
            </Tooltip>
          </div>

          <IconButton
            onClick={handleClose}
            className={classes.closeIcon}
            size={'small'}
          >
            <CloseIcon/>
          </IconButton>
        </div>
      </DialogTitle>

      {content}
    </Dialog>
  );
};

FormDraggableDialog.propTypes = {
  classes: PropTypes.object,
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
  data: PropTypes.object,
};

export default withStyles(FormDraggableDialogStyle)(FormDraggableDialog);
