import BaseDropDownMenu from "components/BaseDropDownMenu/BaseDropDownMenu";
import {EditorContext} from "editor/Editor";
import React, {useContext, useState} from 'react';
import PropTypes from 'prop-types';


const ViewDropDownMenu = props => {
  const {
    classes,
    ...rest
  } = props;

  const editorData = useContext(EditorContext);
  const {editorReducerState, dispatch} = editorData;
  const editorActions = editorReducerState.editorActions;

  const [isRenderingVerticalGuide, setIsRenderingVerticalGuide] = useState(false);
  const [isRenderingHorizontalGuide, setIsRenderingHorizontalGuide] = useState(false);

  const zoomOut = () => {
    dispatch({
      type: 'zoomOut'
    })
  }

  const zoomIn = () => {
    dispatch({
      type: 'zoomIn'
    })
  }

  const showVerticalGuide = () => {
    editorActions.renderGridLine('vertical', !isRenderingVerticalGuide);
    setIsRenderingVerticalGuide(!isRenderingVerticalGuide);
  }

  const showHorizontalGuide = () => {
    editorActions.renderGridLine('horizontal', !isRenderingHorizontalGuide);
    setIsRenderingHorizontalGuide(!isRenderingHorizontalGuide);
  }

  const viewMenuActions = [

    // {
    //   title: 'Mode'
    // },
    // {
    //   title: 'Show Ruler'
    // },
    // {
    //   title: 'Snap To'
    // },
    {
      title: 'Zoom In',
      action: zoomIn
    },
    {
      title: 'Zoom Out',
      action: zoomOut
    },
    {
      title: isRenderingVerticalGuide ? 'Hide Vertical Guide' : 'Show Vertical Guide',
      action: showVerticalGuide
    },
    {
      title: isRenderingHorizontalGuide ? 'Hide Horizontal Guide' : 'Show Horizontal Guide',
      action: showHorizontalGuide
    },
  ]

  return (
    <BaseDropDownMenu
      actions={viewMenuActions}
      {...rest}
    />
  );
};

ViewDropDownMenu.propTypes = {
  classes: PropTypes.object
};

export default ViewDropDownMenu;
