import {withStyles} from '@material-ui/core';
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import BaseDropDownMenuStyle from 'components/BaseDropDownMenu/BaseDropDownMenuStyle';
import PropTypes from 'prop-types';
import React from 'react';

const BaseDropDownMenu = props => {
  const {
    classes,
    actions,
    ...rest
  } = props;

  return (
    <Menu
      {...rest}
    >
      {actions.map((data, index) => (
        <MenuItem
          key={data.title}
          onClick={data.action ? () => {
            data.action();
            rest.onClose()
          } : () => {}}
        >
          {data.title}
        </MenuItem>
      ))}
    </Menu>
  );
};

BaseDropDownMenu.propTypes = {
  classes: PropTypes.object,
  actions: PropTypes.array.isRequired,
};

export default withStyles(BaseDropDownMenuStyle)(BaseDropDownMenu);
