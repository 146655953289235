import {purpleButton} from "assets/theme";

const SubmitFeedbackContentStyle = theme => ({
  root: {
    width: (props) => props.inEditor ? "100%" : "420px",
    height: (props) => props.inEditor ? "100%" : "80%",
    marginBottom: (props) => props.inEditor ? "0" : "20px",
    display: "flex",

    [theme.breakpoints.down('450')]: {
      width: (props) => props.inEditor ? "100%" : "340px",
    },
    [theme.breakpoints.down('375')]: {
      width: (props) => props.inEditor ? "100%" : "290px",
    },
  },

  formInput: {
    backgroundColor: "transparent",
    color: 'white',
    borderColor: "white",

    '&.MuiTextField-root': {
      '& fieldset': {
        borderColor: 'white',
      },
      '&:hover fieldset': {
        borderColor: 'white',
      },
      '&.Mui-focused fieldset': {
        borderColor: 'white',
      },
      '& .MuiInputBase-input': {
        color: "white",
      },
    },
    '& .MuiOutlinedInput-multiline' :{
      padding:0,
    },
  },

  outerFormContainer: {
    width: "100%",
    height: "100%",
  },

  formContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: 'center',
    maxWidth: "420px",
    minWidth: "200px",
  },

  formInputContainer: {
    width: "100%",
    marginTop: "20px",
  },

  input: {
    color: "white",
    fontSize: "18px",
    padding: "11px",

    '&::-webkit-scrollbar': {
      height: '10px',
      width: '5px',
      position: 'relative',
      marginRight: '2px',
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: 'white',
    },
    '&::-webkit-scrollbar-track': {
      backgroundColor: '#262626',
      opacity: '0.48'
    },
  },

  buttonContainer: {
    marginTop: "20px",
    display: "flex",
    justifyContent: "flex-end",
    width: "100%",
  },

  purpleButton: {
    ...purpleButton,
    width: (props) => props.inEditor ? "auto" : "100%",

    '&.MuiButtonBase-root': {
      height: "52px",
      fontSize: "17px",
      transition: "0.3s",
    },
  },

});

export default SubmitFeedbackContentStyle;