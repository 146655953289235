const FormDraggableInputDetailsStyle = theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'stretch',
    height: '100%'
  },

  inputContainer: {
    width: '100%',
    margin: '8px 0'
  },

  content: {
    margin: '8px 0',
    flex: 1
  },

  formError: {
    color: theme.palette.error.main,
    fontWeight: '500',
    margin: '5px 0'
  },

  submitContainer: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "flex-end",
    marginRight: "16px",
    marginBottom: '16px'
  },

  submitButton: {
    width: "175px",
  },

  formContent: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'stretch'
  },

  backButtonContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    color: theme.palette.secondary.main,
  },

  backIcon: {
    marginRight: '5px'
  },

  formDescription: {
    margin: "8px"
  },

  input: {
    margin: "8px",

    '&.MuiTextField-root': {
      '& fieldset': {
        borderColor: 'white',
      },
      '&:hover fieldset': {
        borderColor: 'white',
      },
      '&.Mui-focused fieldset': {
        borderColor: 'white',
      },
      '& .MuiInputBase-input': {
        color: "white",
      },
    },
  },
});

export default FormDraggableInputDetailsStyle;
