const measureElementBounds = (editorId, treeActions, zoomLevel, offsetEditorBounds=false) => {
  let node = treeActions.current.getElementFromTree(editorId);

  if (node) {
    let {top, right, bottom, left, width, height, x, y} = node.getBoundingClientRect();

    let newWidth = width * (1/zoomLevel.current)
    let newHeight = height * (1/zoomLevel.current)

    if(offsetEditorBounds) {
      let zoomContainerNode = document.getElementById('zoom-container');
      let zoomContainerBounds = zoomContainerNode.getBoundingClientRect();

      x = (x - zoomContainerBounds.left) * (1/zoomLevel.current);
      y = (y - zoomContainerBounds.top) * (1/zoomLevel.current);
    }

    let values = {
      top: Math.round(top * 10) / 10,
      right: Math.round(right * 10) / 10,
      bottom: Math.round(bottom * 10) / 10,
      left: Math.round(left * 10) / 10,
      width: Math.round(newWidth * 10) / 10,
      height: Math.round(newHeight * 10) / 10,
      x: Math.round(x * 10) / 10,
      y: Math.round(y * 10) / 10,
    };

    return values;
  }

  return null;
}

export default measureElementBounds;