const DraggablePageLabelStyle = theme => ({
  selected: {
    backgroundColor: theme.palette.editor.selected,
    padding: '1px 4px',
    borderRadius: '5px',
  },

  root: {
    cursor: 'pointer'
  },

  pageIcon: {
    width: "15px",
    height: "15px",
    marginRight: "5px",
  },

  childPageContainer: {
    display: 'flex',
    flexDirection: 'column',
    paddingLeft: '16px',
    justifyContent: 'flex-start',
    alignItems: 'flex-start'
  }
});

export default DraggablePageLabelStyle;
