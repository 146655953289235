import React, {createContext, useEffect, useState} from 'react';
import {User} from "services";
import {handleNetworkError} from "utils/utils";
import APPLICATION_SETTINGS from "../settings";

export const ApplicationSettingsContext = createContext();

export const ApplicationSettingsContextProvider = ({children}) => {
  const [templateList, setTemplateList] = useState([]);

  // preload templates for a better user experience
  useEffect(() => {
    User.listAllTemplates().then(response => {
      console.log('template list success!', response);
      setTemplateList(response.data);
    }).catch(handleNetworkError)
  }, [])

  const values = {
    ...APPLICATION_SETTINGS,
    templates: templateList,
  }

  return (
    <ApplicationSettingsContext.Provider value={values}>
      {children}
    </ApplicationSettingsContext.Provider>
  );
};

export default ApplicationSettingsContextProvider;
