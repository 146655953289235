import {arrayMoveImmutable} from "array-move";
import BaseComponentSerializer from "editor/serializers/BaseComponentSerializer";
import {cloneDeep} from "lodash-es";
import {generateId, isObjectEmpty} from "../../utils/utils";
import {renderTree} from "editor/actions/core";

const initializeElementTreeActions = (treeElements) => {
  const editorRootElem = treeElements['editor-root'];

  const findElementInTree = (editorId, treeElemStartPoint = editorRootElem) => {
    /**
     * @param {String} editorId - The editor ID of the treeElemStartPoint that we're looking for
     * @param {Object} treeElemStartPoint - An element (FROM THE TREE -- NOT A DOM OBJECT)
     *  that we start looking at
     */

    if (treeElemStartPoint.id === editorId) {
      // this only checks if it's the editor
      return treeElemStartPoint;
    } else if (treeElemStartPoint.children) {
      for (let child of treeElemStartPoint.children) {
        if (typeof child === 'object') {
          let found = findElementInTree(editorId, child);
          if (found) {
            // If the object was found in the recursive call, bubble it up.
            return found;
          }
        }
      }
    }
  };

  const findElementTreeSegment = (editorId, treeElemStartPoint = editorRootElem) => {
    let tempResult = null;
    // TODO: this is kind of jank but it works, please optimize this

    if (treeElemStartPoint.id === editorId) {
      // this only checks if it's the editor
      return treeElemStartPoint;
    } else if (treeElemStartPoint.children) {
      for (let child of treeElemStartPoint.children) {
        if (typeof child === 'object') {
          // if it's the root, then save the current child as a temp variable
          // and clear if we don't find anything in it's branch
          if(isEditorRoot(treeElemStartPoint)) {
            tempResult = child;
          }

          let found = findElementTreeSegment(editorId, child);
          if (found) {
            // If the object was found in the recursive call, bubble it up.
            return tempResult;
          }
        }
      }
      // if after this we haven't found, clear the temp object
    }

    return tempResult;
  }

  const appendToTreeElemProps = (elem, propsToAppend, currentTreeElements=treeElements) => {
    let newElemProps = {...elem.props, ...propsToAppend};
    elem.props = newElemProps;

    return replaceElementInTree(elem.id, elem, currentTreeElements);
  }

  const appendPropsOnAllInTreeSegment = (treeSegment, propsToSet) => {
    /**
     * @param {Object} treeSegment - A segment of the tree, can be retreived by calling
     * findElementTreeSegment(). Also accepts single tree elements
     * @param {Object} propsToSet - An object of props to set append to all elements in the tree
     */


    // TODO: doesn't work but also unused right now, left in because we might need this later.

    if(treeSegment.children) {
      for(let child of treeSegment.children) {
        let newChildProps = {...child.props, ...propsToSet};

      }
    }
  }

  const getElementsPropsById = (editorId) => {
    let elem = findElementInTree(editorId);

    return elem.props;
  }

  const getElementFromTree = (editorId) => {
    /**
     * @param {String} editorId - The editor ID of the element that we're looking for
     */

    return document.querySelector(`[data-editorid='${editorId}']`);
  };

  // internal function -- move this?
  // this loops through the attached tag's editor settings and records an array
  // of all props that can change from those
  // then it filters them out
  const getEditableProps = (treeElem) => {
    let treeElemProps = treeElem?.props;

    if(treeElem?.tag?.editorSettings) {
      let listOfKeys = {};
      let keysToExclude = ['breakpoints'];

      for(let setting of treeElem?.tag?.editorSettings) {
        // these contain inner settings we must loop through
        if(['collection', 'layout'].includes(setting.type)) {

          // to access the child settings -- for layouts it's "elements"
          // for collections it's "children"
          // TODO: we should use the same keys in the future
          let innerSettingKey = setting.type === 'collection' ? 'children' : 'elements'

          for(let innerSetting of setting[innerSettingKey]) {
            if(keysToExclude.includes(innerSetting.key)) {
              continue;
            }

            if(innerSetting.type === 'prop') {
              listOfKeys[innerSetting.key] = treeElemProps?.[innerSetting.key];
            }
          }
        }
        else if(setting.type === 'prop') {
          // always ignore "breakpoints" prop
          if(keysToExclude.includes(setting.key)) {
            continue;
          }

          listOfKeys[setting.key] = treeElemProps?.[setting.key];
        }
      }

      // always add the style prop
      listOfKeys['style'] = treeElemProps?.style;

      return listOfKeys;
    }
    else {
      throw new Error('supplied tag has no editor settings')
    }
  }

  const getNewBreakpointData = (elemToAdd, props, editableProps, propData, currentCanvasSize) => {
    // this is a new element
    let newBreakpointData;

    if(elemToAdd.id === undefined || !elemToAdd?.breakpointData) {
      newBreakpointData = {
        mobile: {
          style: {
            ...props.style
          },
          ...editableProps
        },
        tablet: {
          style: {
            ...props.style
          },
          ...editableProps
        },
        desktop: {
          style: {
            ...props.style
          },
          ...editableProps
        }
      }
    }
    else {
      let styleLink = elemToAdd?.baseData?.breakpoints?.styleLink || propData?.breakpoints?.styleLink;
      // add desktop: true for this logic
      styleLink.desktop = true;

      // this is not a new element
      // check if there is a style link
      if(!(styleLink?.[currentCanvasSize])) {
        // styleLink is false, so only alter this size's styles
        newBreakpointData = {
          ...elemToAdd.breakpointData,
          [currentCanvasSize]: {
            style: {
              ...elemToAdd.props.style,
              ...props.style,
            },
            ...editableProps
          }
        }
      }
      else if(styleLink?.['tablet'] && styleLink?.['mobile']) {
        newBreakpointData = {
          mobile: {
            style: {
              ...elemToAdd.props.style,
              ...props.style,
            },
            ...editableProps
          },
          tablet: {
            style: {
              ...elemToAdd.props.style,
              ...props.style,
            },
            ...editableProps
          },
          desktop: {
            style: {
              ...elemToAdd.props.style,
              ...props.style,
            },
            ...editableProps
          }
        }
      }
      else {
        // only one link is active
        if(currentCanvasSize === 'mobile') {
          newBreakpointData = {
            ...elemToAdd.breakpointData,
            'mobile': {
              style: {
                ...elemToAdd.props.style,
                ...props.style,
              },
              ...editableProps
            },
            'tablet': {
              style: {
                ...elemToAdd.props.style,
                ...props.style,
              },
              ...editableProps
            }
          }
        }
        else if(currentCanvasSize === 'tablet') {
          // is this is a special case, we need to check if the link
          // is to desktop or to mobile?
          newBreakpointData = {
            ...elemToAdd.breakpointData,
            'tablet': {
              style: {
                ...elemToAdd.props.style,
                ...props.style,
              },
              ...editableProps
            },
            'desktop': {
              style: {
                ...elemToAdd.props.style,
                ...props.style,
              },
              ...editableProps
            }
          }
        }
        else if(currentCanvasSize === 'desktop') {
          if(styleLink?.['tablet']) {
            newBreakpointData = {
              ...elemToAdd.breakpointData,
              'tablet': {
                style: {
                  ...elemToAdd.props.style,
                  ...props.style,
                },
                ...editableProps
              },
              'desktop': {
                style: {
                  ...elemToAdd.props.style,
                  ...props.style,
                },
                ...editableProps
              }
            }
          }
          else {
            newBreakpointData = {
              ...elemToAdd.breakpointData,
              'desktop': {
                style: {
                  ...elemToAdd.props.style,
                  ...props.style,
                },
                ...editableProps
              }
            }
          }
        }
      }
    }

    // now format visibility
    for(let key of Object.keys(newBreakpointData)) {
      if(elemToAdd?.baseData?.breakpoints?.visibility?.[key] === false) {
        newBreakpointData[key].style = {
          ...newBreakpointData[key].style,
          display: 'none'
        }
      }
      else if(elemToAdd?.baseData?.breakpoints?.visibility?.[key] === true) {
        newBreakpointData[key].style = {
          ...newBreakpointData[key].style,
          display: 'flex'
        }
      }
    }

    return newBreakpointData;
  }

  const addToTree = (
    treeElem,
    elemToAdd,
    originalIndex,
    currentTreeElements=treeElements,
    // "desktop", "mobile", or "tablet"
    currentCanvasSize='desktop',
  ) => {
    let {
      tag,
      props,
      draggableProps,
      children,
      id=undefined,
    } = elemToAdd;

    // TODO: this will need to re-render only sections of a tree
    console.log('tree elem test', treeElem)

    let editorId = id || generateId();

    const defaultProps = {
      key: editorId,
      // this is how we're passing the editor id to be used as a prop in components
      editorId,
      breakpoints: {
        visibility: {
          desktop: true,
          tablet: true,
          mobile: true
        },
        styleLink: {
          desktop: true,
          tablet: true,
          mobile: true
        }
      }
    };

    console.log('tree actions add to tree called', {
      treeElem,
      elemToAdd,
      originalIndex,
      currentTreeElements,
      currentCanvasSize
    })

    console.log('add to tree tests', {
      test1: props && !isObjectEmpty(props),
      test2: {
        ...defaultProps,
        ...props,
        editorId,
        key: editorId
      }
    })

    let propData = props && !isObjectEmpty(props) ?
        {
          ...defaultProps,
          ...props,
          editorId,
          key: editorId
        }
        : defaultProps

    let editableProps = getEditableProps(elemToAdd);

    // make a new variable that contains the difference between propData and editableProps
    // this represents the base props that don't change from element settings
    let basePropData = {...propData}
    for(let key of Object.keys(editableProps)) {
      delete basePropData[key];
    }

    // we want to put every prop that corresponds to what is in editable props
    // in the breakpointData and everything else we need in some other object
    // we can merge the current breakpoint data and this other object to get the
    // current props of the element

    let newBreakpointData = getNewBreakpointData(
      elemToAdd,
      props,
      editableProps,
      propData,
      currentCanvasSize
    )

    let newChild = {
      tag: tag,
      tagName: tag.serializedName || 'not implemented yet',
      id: editorId,
      baseData: {
        ...basePropData,
        ...elemToAdd.baseData,
      },
      breakpointData: newBreakpointData,
      props: {
        ...propData,
        ...editableProps
      },
      draggableProps: draggableProps ? draggableProps : null,
      children
    }

    treeElem.children = [...treeElem.children, newChild];

    // now re-order
    console.log('replace in tree original index', originalIndex)
    if(originalIndex !== undefined) {
      treeElem.children = arrayMoveImmutable(treeElem.children, treeElem.children.length-1, originalIndex);
    }

    console.log('tree result', currentTreeElements);

    return currentTreeElements;
  };

  const findParentInTree = (editorId, treeElemStartPoint = editorRootElem) => {
    if (treeElemStartPoint.children.find(elem => elem.id === editorId)) {
      // this only checks if it's the editor
      return treeElemStartPoint;
    } else if (treeElemStartPoint.children) {
      for (let child of treeElemStartPoint.children) {
        if (typeof child === 'object') {
          let found = findParentInTree(editorId, child);
          if (found) {
            // If the object was found in the recursive call, bubble it up.
            return found;
          }
        }
      }
    }
  }

  const deleteFromTree = (treeElem, currentTreeElements=treeElements) => {
    if(!treeElem?.id) {
      return currentTreeElements;
    }

    let parentElem = null;

    if(treeElem.id !== 'editor-root') {
      parentElem = findParentInTree(treeElem.id, currentTreeElements)
      parentElem.children = parentElem.children.filter(item => item.id !== treeElem.id);
    }
    else {
      currentTreeElements.children = currentTreeElements.children.filter(item => item.id !== treeElem.id);
    }

    //remove the element and all children

    //setTree(renderTree(treeElements));

    return currentTreeElements;
  }

  const deleteFromTreeByEditorId = (editorId, currentTreeElements=treeElements) => {
    const elem = findElementInTree(editorId, currentTreeElements);

    return deleteFromTree(elem, currentTreeElements);
  }

  // TODO: add an optional starting point for the "findParentInTree" function?
  const replaceElementInTree = (
    originalElemId,
    newTreeElem,
    currentTreeElements=treeElements,
    // "desktop", "mobile", or "tablet"
    currentCanvasSize='desktop'
  ) => {
    let parent = findParentInTree(originalElemId, currentTreeElements);
    let originalIndex = undefined;

    if(parent) {
      originalIndex = parent.children.findIndex(elem => elem.id === originalElemId);
    }

    if(newTreeElem?.baseData?.layerLabel) {
      newTreeElem.props.layerLabel = newTreeElem.baseData.layerLabel;
    }

    let postDeleteData = deleteFromTreeByEditorId(originalElemId, currentTreeElements);

    console.log('post delete data', postDeleteData)

    let returnData = addToTree(
      parent,
      {...newTreeElem},
      originalIndex,
      postDeleteData,
      currentCanvasSize
    );

    return returnData;
  }

  const getNodeEditorId = (elem) => {
    console.log('get node editor id', elem)
    if (
      elem.nodeType
      && elem.hasAttribute
      && elem.hasAttribute('data-editorid')
    ) {

      // stop once we find one so we only get the top treeElem
      return elem.getAttribute('data-editorid');
    }

    return undefined;
  };

  const isEditorRoot = elem => {
    return elem.id === editorRootElem.id;
  }

  const getEditorRoot = () => {
    return editorRootElem;
  }

  const isDirectChildTreeElement = (childElem, parentElem) => {
    // very similar to below "isChildTreeElement", but this only checks for a DIRECT child
    console.log('is direct child tree element', {childElem, parentElem})
    if (parentElem?.children) {
      let found = parentElem.children.find(elem => elem.id === childElem.id);
      if(found) {
        // we found the element we're looking for, it is a child
        return true;
      }
    }

    // the parent elem has no children so this can't be a child
    return false;
  }

  const isChildTreeElement = (childElem, parentElem) => {
    console.log('is child tree element', {childElem, parentElem})
    try {
      if (parentElem?.children) {
        let found = parentElem.children.find(elem => elem.id === childElem.id);
        if(found) {
          // we found the element we're looking for, it is a child
          return true;
        }
        else {
          // loop through the children of this element and run it again
          for(let child of parentElem.children) {
            found = isChildTreeElement(childElem, child)
            if(found) {
              // child was found at some level of recursion so return it
              return true;
            }
          }

          // no child was found so return false
          return false
        }
      }

      // the parent elem has no children so this can't be a child
      return false;
    }
    catch (e) {
      console.error('a handled error occurred', e);

      return false;
    }
  }

  const isSiblingElement = (elemToCheck, potentialSiblingElem) => {
    let parent = findParentInTree(elemToCheck.id)

    console.log('is sibling element test', {parent, potentialSiblingElem, elemToCheck})

    return isChildTreeElement(potentialSiblingElem, parent) && isChildTreeElement(elemToCheck, parent)
  }

  return {
    findElementInTree,
    getNodeEditorId,
    addToTree,
    deleteFromTree,
    findParentInTree,
    deleteFromTreeByEditorId,
    replaceElementInTree,
    isEditorRoot,
    isChildTreeElement,
    isDirectChildTreeElement,
    getElementFromTree,
    getEditorRoot,
    findElementTreeSegment,
    appendToTreeElemProps,
    getElementsPropsById,
    isSiblingElement
  }
}
export default initializeElementTreeActions;
