import {outlinedButton, purpleButton} from "assets/theme";

const ConfirmationDialogStyle = theme => ({
  text: {
    width: "355px",
  },

  outlinedButton: {
    ...outlinedButton
  },

  purpleButton: {
    ...purpleButton
  }
});

export default ConfirmationDialogStyle;