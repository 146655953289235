import {withStyles} from '@material-ui/core';
import { Button } from "@material-ui/core";
import DraggableWrapper from "editor/draggables/DraggableWrapper/DraggableWrapper";
import formatStyles from "editor/draggables/formatStyles";
import PropTypes from 'prop-types';
import React, {useEffect, useMemo} from 'react';
import APPLICATION_SETTINGS from "settings";
import ButtonDraggableStyle from './ButtonDraggableStyle';

const ButtonDraggable = props => {
  const {
    classes,
    style,
    editorId,
    draggableProps,
    text,
    variant,
    absolutePositioning,

    isChildElem,
  } = props;

  const {draggableStyles, elementStyles} =
    useMemo(() => formatStyles(style), [style]);

  return (
    <>
      <DraggableWrapper
        resizeable
        editorId={editorId}
        style={{
          ...draggableStyles,
          ...draggableProps?.style,
          position: absolutePositioning ? 'absolute' : 'static'
        }}
        isChildElem={isChildElem}
      >
        <Button
          variant={variant || 'contained'}
          style={{
            ...elementStyles,
            position: 'static',
          }}
          className={classes.buttonDraggableRoot}
          data-editorid={editorId}
          data-asset={true}
        >
          {text || 'Example Text'}
        </Button>
      </DraggableWrapper>
    </>
  );
};

ButtonDraggable.editorSettings = [
  {
    type: 'prop',
    key: 'breakpoints',
    tooltip: 'Make an asset visible at the chosen breakpoints: desktop, tablet, and/or mobile.',
    default: {
      visibility: {
        desktop: true,
        tablet: true,
        mobile: true
      },
      styleLink: {
        tablet: true,
        mobile: true,
      }
    },
    label: null,
    inputType: 'breakpoint'
  },

  {
    type: 'layout',
    key: 'twoCol',
    tooltip: 'Accepted units: px, %, em, rem, vw, vh',
    label: 'Position/Size',
    elements: [
      {type: 'style', key: 'left', default: '0', label: 'X'},
      {type: 'style', key: 'top', default: '0', label: 'Y'},
      {type: 'style', key: 'width', default: '50px', label: 'W'},
      {type: 'style', key: 'height', default: '50px', label: 'H'},
    ]
  },

  {
    type: 'layout',
    key: 'font',
    elements: [
      {type: 'style', key: 'fontFamily', default: 'Roboto',inputType: 'select', options: APPLICATION_SETTINGS.CONSTANTS.SUPPORTED_FONTS},
      {type: 'style', key: 'fontWeight', default: '400', inputType: 'select', options: APPLICATION_SETTINGS.CONSTANTS.SUPPORTED_FONT_WEIGHTS},
      {type: 'style', key: 'fontSize', default: '16px', inputType: 'select', options: APPLICATION_SETTINGS.CONSTANTS.SUPPORTED_FONT_SIZES}
    ]
  },

  {
    type: 'prop',
    key: 'absolutePositioning',
    tooltip: 'If this is checked, your asset\'s CSS will be written with "position: absolute"',
    label: 'Absolute Positioning',
    default: false,
    inputType: 'boolean'
  },

  {type: 'prop', key: 'text', default: 'Example Text', label: 'Button Text'},

  {
    type: 'style',
    key: 'color',
    default: '#000000',
    label: 'Text Color',
    inputType: 'color',
    inputOptions: {
      defaultColor: '#000000'
    }
  },

  {
    type: 'style',
    key: 'backgroundColor',
    default: '#E0E0E0',
    label: null,
    inputType: 'color',
    inputOptions: {
      defaultColor: '#E0E0E0'
    }
  },

  {type: 'prop', key: 'variant', default: 'contained', inputType: 'select', label: 'Variant',
    options: [
      {name: 'Contained', value: 'contained'},
      {name: 'Text', value: 'text'},
      {name: 'Outlined', value: 'outlined'}
    ]
  },

  {
    type: 'collection',
    label: 'Border',
    children: [
      {type: 'style', key: 'border', default: 'none', label: null, inputType: 'border'},
      {type: 'style', key: 'borderRadius', default: '4px', label: 'Border Radius'},
    ]
  },

  {
    type: 'collection',
    label: 'Padding',
    children: [
      {type: 'style', key: 'paddingTop', default: '0px', label: 'Top', horizontal: false},
      {type: 'style', key: 'paddingBottom', default: '0px', label: 'Bottom', horizontal: false},
      {type: 'style', key: 'paddingLeft', default: '0px', label: 'Left', horizontal: false},
      {type: 'style', key: 'paddingRight', default: '0px', label: 'Right', horizontal: false},
    ]
  },

  {
    type: 'collection',
    label: 'Margin',
    children: [
      {type: 'style', key: 'marginTop', default: '0px', label: 'Top', horizontal: false},
      {type: 'style', key: 'marginBottom', default: '0px', label: 'Bottom', horizontal: false},
      {type: 'style', key: 'marginLeft', default: '0px', label: 'Left', horizontal: false},
      {type: 'style', key: 'marginRight', default: '0px', label: 'Right', horizontal: false},
    ]
  },

  {
    type: 'collection',
    label: 'Effects',
    children: [
      {type: 'style', key: 'opacity', default: '1', label: 'Opacity'}, {
        type: 'prop',
        key: 'hoverColor',
        default: '',
        label: 'Hover Color',
        inputType: 'color',
        inputOptions: {
          defaultColor: ''
        }
      },
      {type: 'style', key: 'boxShadow', default: 'none', label: 'Box Shadow', inputType: 'boxShadow'},
      {type: 'prop', key: 'link', default: null, label: null, inputType: 'link'},
      {type: 'prop', key: 'cursor', default: null, label: null, inputType: 'cursor'},
      {type: 'style', key: 'transition', default: 'none', label: 'Transition', inputType: 'transition'},
      {type: 'style', key: 'transform', default: 'none', label: 'Rotation', inputType: 'rotation'},
    ]
  },
];

ButtonDraggable.helpQuestionData = {
  category: 'button',
  helpAssetHeader: {
    assetType: 'Button',
    assetShortDescription: 'Buttons offer clear call-to-actions (CTAs), serving as effective instruments for boosting user interaction and clicks on your ' +
      'website. You can fully tailor their look and behavior according to your needs.'
  }
}

ButtonDraggable.serializedName = 'ButtonDraggable';

ButtonDraggable.propTypes = {
  classes: PropTypes.object,
  style: PropTypes.object.isRequired,
  draggableProps: PropTypes.object,
  editorId: PropTypes.string.isRequired,
  isChildElem: PropTypes.object,
};

export default withStyles(ButtonDraggableStyle)(ButtonDraggable);
