const RotationInputStyle = theme => ({
  root: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    justifyContent: 'flex-start',
    alignItems: 'center',
    marginTop: "10px",
  },
});

export default RotationInputStyle;