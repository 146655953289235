import React from 'react';
import {withStyles} from "@material-ui/core";
import MediaGridStyle from "editor/components/dialogs/MediaDraggableDialog/MediaGrid/MediaGridStyle";
import PropTypes from "prop-types";

const MediaGrid = props => {
  const {
    classes,
    children
  } = props;

  return (
    <div className={classes.gridContainer}>
      <div className={classes.grid}>
        {children}
      </div>
    </div>
  );
};

MediaGrid.propTypes = {

  classes: PropTypes.object,
  children: PropTypes.array,
};

export default withStyles(MediaGridStyle)(MediaGrid);
