const ClassInputStyle = theme => ({
  root: {
    width: '100%',
  },

  comboboxContainer: {
    width: '100%',
    marginTop: "8px",
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center'
  },

  input: {
    background: 'rgba(43, 43, 55, 0.6)',
  },

  autocompleteRoot: {
    flex: 1,

    '& .MuiSvgIcon-root': {
      color: "white"
    }
  },

  addButton: {
    color: '#8A56FF',

  },

  addButtonIcon: {
    width: '28px',
    height: '28px'
  }
});

export default ClassInputStyle;
