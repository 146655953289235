import {withStyles} from '@material-ui/core';
import Autocomplete from "@material-ui/lab/Autocomplete";
import ElementSettingsGroupLabel from "components/ElementSettingGroupLabel/ElementSettingsGroupLabel";
import StyledTextField from "components/StyledTextField/StyledTextField";
import PropTypes from 'prop-types';
import React, {useEffect, useState} from 'react';
import BackgroundPositionInputStyle from './BackgroundPositionInputStyle';

const BackgroundPositionInput = props => {
  const {
    classes,
    setting,
    onBlur,
    onFocus,
    onChange,
    value,
  } = props;

  const [backgroundPosition, setBackgroundPosition] = useState('top left');

  const backgroundPositions = [
    'top',
    'bottom',
    'left',
    'right',
    'center'
  ]

  const handleAutoCompleteChange = (e, value, reason) => {
    if(value === null) {
      setBackgroundPosition('top left')
      onChange('top left')
    } else {
      setBackgroundPosition(value)
      onChange(value)
    }
  }

  useEffect(() => {
    setBackgroundPosition(value)
  }, [value])

  return (
    <div className={classes.root}>
      <ElementSettingsGroupLabel setting={setting}>
        Background Position
      </ElementSettingsGroupLabel>

      <div className={classes.autocompleteContainer}>
        <Autocomplete
          freeSolo
          size={'small'}
          value={backgroundPosition}
          ListboxProps={{className: classes.listBox}}
          classes={{root: classes.autocompleteRoot}}
          options={
            backgroundPositions.map((option) => option)
          }
          onChange={handleAutoCompleteChange}
          renderInput={(params) => (
            <StyledTextField
              {...params}
              className={classes.input}
              variant="outlined"
              onFocus={onFocus}
              onBlur={onBlur}
            />
          )}
        />
      </div>
    </div>
  );
};

BackgroundPositionInput.propTypes = {
  classes: PropTypes.object,
  onChange: PropTypes.func.isRequired,
  setting: PropTypes.object.isRequired,
  value: PropTypes.string,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
};

export default withStyles(BackgroundPositionInputStyle)(BackgroundPositionInput);