const EditorPagesStyle = theme => ({
  buttonPopoverMenu: {
    background: '#2B2B37',
  },

  buttonPopoverItem: {
    transition: '0.3s',

    '&:hover': {
      background: '#3a3a4a'
    }
  },

  pageHeaderTitle: {
    marginTop: '6px',
    marginBottom: '10px',
    marginLeft: '6px',
  },

  pageHeaderContainer: {
    width: "100%",
    height: '32px',
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
    alignItems: "center",
  },

  addButtonContainer: {
    marginLeft: "auto",
    marginRight: "4px"
  },

  pagesContainer: {
    margin: '6px',
    height: '100%'
  },

  pageDataContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "flex-start",
    minHeight: '350px',
    marginTop: '10px',
    height: 'calc(100% - 40px)',
    width: "100%",
    overflowY: "auto",
    padding: "16px",
    borderRadius: "5px",
    backgroundColor: "#181A1E",

    '&::-webkit-scrollbar': {
      height: '100px',
      width: '2px',
      position: 'relative',
      marginLeft: '9.33px',
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: 'white',
    },
    '&::-webkit-scrollbar-track': {
      backgroundColor: '#262626',
      opacity: '0.48'
    },

    [theme.breakpoints.down('lg')]: {
      minHeight: '250px',
    },
  },
});

export default EditorPagesStyle;
