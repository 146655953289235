const UserMenuStyle = theme => ({
  userMenuContainer: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
  },

  icon: {
    color: "white"
  },

  iconButton: {
    transition: "0.3s",
  }
});

export default UserMenuStyle;
