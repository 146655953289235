const FormDraggableInputCardStyle = theme => ({
  inputCardRoot: {
    padding: '5px 10px',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    background: 'rgba(255,255,255, 0.65)',
    borderRadius: '8px',
    margin: '8px 0',
    boxShadow: '2px 2px 5px 0px rgba(0,0,0,0.75)'
  },

  deleteButton: {
    color: 'white',
    padding: '8px',
    backgroundColor: theme.palette.error.main,
    transition: '0.3s',

    '&:hover': {
      backgroundColor: theme.palette.error.dark
    },
  },

  iconContainer: {
    margin: '0 16px',
    position: "relative",
    top: "2px"
  },

  upDownContainer: {
    height: '100%',
    width: '30px',
    padding: '0 10px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },

  nameContainer: {
    flex: 1,
    fontFamily: 'Roboto',
    fontWeight: '500'
  },

  upDownArrow: {
    color: 'black',
  },

  editButton: {
    color: 'white',
    marginLeft: "8px",
    padding: "8px",
    transition: '0.3s',
  }
});

export default FormDraggableInputCardStyle;
