const EditorLayersStyle = theme => ({
  layersRoot: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "flex-start",
    height: "100%",
    minHeight: '200px',
    overflow: "auto",
    padding: "16px",
    borderRadius: "5px",
    backgroundColor: "#181A1E",
    position: 'relative',
    bottom: '2px',

    '&::-webkit-scrollbar': {
      height: "2px",
      width: '2px',
      position: 'relative',
      marginLeft: '9.33px',
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: 'white',
    },
    '&::-webkit-scrollbar-track': {
      backgroundColor: '#262626',
      opacity: '0.48'
    },
    // '&::-webkit-scrollbar-horizontal': {
    //   height: "2px"
    // },
  },
});

export default EditorLayersStyle;
