import getFormDraggableAssetConfig from "editor/components/dialogs/FormDraggableDialog/formDraggableAssetConfig";
import InputDraggable from "editor/draggables/InputDraggable/InputDraggable";
import {EditorContext} from "editor/Editor";
import {useAssetConfig} from "hooks/useAssetConfig";
import {cloneDeep} from "lodash-es";
import React, {createContext, useCallback, useContext, useReducer} from 'react';
import PropTypes from 'prop-types';
import {arrayMoveImmutable} from "array-move";
import {generateId} from "utils/utils";

export const FormDraggableDialogContext = createContext();

const FormDraggableDialogContextProvider = props => {
  const { children } = props;
  const editorData = useContext(EditorContext);
  const {editorReducerState, dispatch} = editorData;

  const assetConfig = useAssetConfig();

  const initState = {
    currentFields: [],

    elementEditorId: null,
    currentPage: 'main',

    inputTypeBeingAdded: null,
    inputBeingEdited: null,

    inputTypes: [
      {
        component: 'TextField',
        label: 'Text Field',
        tagName: 'InputDraggable',
        fields: ['name', 'label', 'placeholder'],
        defaultProps: {}
      },
      {
        component: 'RadioButton',
        label: 'Radio Button',
        tagName: 'RadioDraggable',
        fields: ['name', 'label'],
        defaultProps: {}
      },
      {
        component: 'Checkbox',
        label: 'Checkbox',
        tagName: 'CheckboxDraggable',
        fields: ['name', 'label'],
        defaultProps: {}
      },
      {
        component: 'Button',
        label: 'Submit Button',
        tagName: 'ButtonDraggable',
        fields: ['text'],
        defaultProps: {
          type: 'submit'
        }
      }
    ],
  }

  const formDraggableReducer = useCallback((state, action) => {
    switch (action.type) {
      case 'changePage': {
        const {page} = action.payload;

        let returnVal = {
          ...state,
          currentPage: page
        }

        console.log('form draggable reducer was called', {returnVal, action})

        return returnVal;
      }
      case 'setEditorId': {
        const {editorId} = action.payload;

        let returnVal = {
          ...state,
          elementEditorId: editorId
        }

        console.log('form draggable reducer was called', {returnVal, action});

        return returnVal;
      }
      case 'addInput': {
        const {inputData} = action.payload;

        let returnVal = {
          ...state,
          currentFields: [
            ...state.currentFields,
            inputData
          ]
        }

        console.log('form draggable reducer was called', {returnVal, action});

        return returnVal;
      }
      case 'editInput': {
        const {inputName, inputData} = action.payload;

        let idx = state.currentFields.findIndex((elem => elem.name === inputName))

        let newCurrentFields = [...state.currentFields];

        newCurrentFields.splice(idx, 1, {...inputData})

        let returnVal = {
          ...state,
          currentFields: newCurrentFields,
          inputTypeBeingAdded: null,
          inputBeingEdited: null,
        }

        console.log('form draggable reducer was called', {returnVal, action});

        return returnVal;
      }
      case 'resetInputBeingEdited': {
        let returnVal = {
          ...state,
          inputTypeBeingAdded: null,
          inputBeingEdited: null,
        }

        console.log('form draggable reducer was called', {returnVal, action});

        return returnVal;
      }
      case 'setInputBeingAdded': {
        const {component} = action.payload;

        let foundInputData = state.inputTypes.find((elem => elem.component === component))

        let returnVal = {
          ...state,
          inputTypeBeingAdded: {...foundInputData}
        }

        console.log('form draggable reducer was called', {returnVal, action});

        return returnVal;
      }
      case 'setInputBeingEdited': {
        const {inputName} = action.payload;

        let foundInputData = state.currentFields.find((elem => elem.name === inputName))
        let foundInputTypeData = state.inputTypes.find((elem => elem.component === foundInputData.component))

        let returnVal = {
          ...state,
          inputTypeBeingAdded: {...foundInputTypeData},
          inputBeingEdited: {...foundInputData}
        }

        console.log('form draggable reducer was called', {returnVal, action});

        return returnVal;
      }
      case 'moveCardUpInOrder': {
        const {inputName} = action.payload;

        let idx = state.currentFields.findIndex(elem => elem.name === inputName);

        if(idx === 0) {
          return state;
        }

        let newCurrentFields = arrayMoveImmutable(state.currentFields, idx, idx - 1)

        let returnVal = {
          ...state,
          currentFields: newCurrentFields
        }

        console.log('form draggable reducer was called', {returnVal, action});

        return returnVal;
      }
      case 'moveCardDownInOrder': {
        const {inputName} = action.payload;

        let idx = state.currentFields.findIndex(elem => elem.name === inputName);
        let newCurrentFields = arrayMoveImmutable(state.currentFields, idx, idx + 1)

        let returnVal = {
          ...state,
          currentFields: newCurrentFields
        }

        console.log('form draggable reducer was called', {returnVal, action});

        return returnVal;
      }
      case 'deleteInputCard': {
        const {inputName} = action.payload;

        let idx = state.currentFields.findIndex(elem => elem.name === inputName);

        let newCurrentFields = [...state.currentFields];

        newCurrentFields.splice(idx, 1)

        let returnVal = {
          ...state,
          currentFields: newCurrentFields
        }

        console.log('form draggable reducer was called', {returnVal, action});

        return returnVal
      }
      case 'generateForm': {
        const {currentEditorState} = action.payload;

        const {
          findElementInTree,
          generateNewAssetLayerLabel
        } = editorReducerState.editorActions;

        // get the current tree elem
        let currentFormElem = findElementInTree(state.elementEditorId);
        let newFormElem = cloneDeep(currentFormElem);

        // keep count of each asset added so we can increment them correctly
        let assetCounts = {
          'TextField': 0,
          'TextArea': 0,
          'Checkbox': 0,
          'RadioButton': 0,
          'Button': 0
        };

        let labelsInUse = [...currentEditorState.editorState.layerLabelsInUse];

        for(let i of state.currentFields) {

          // copy i then delete some keys that are not props
          let assetProps = {...i};
          delete assetProps.component;
          delete assetProps.tagName;
          delete assetProps.fields;
          delete assetProps.defaultProps;

          let generatedId = generateId();
          let generatedLabel = generateNewAssetLayerLabel(
              assetConfig[i.tagName].sharedProps.layerLabel,
              i.tagName,
              assetCounts[i.component],
              labelsInUse
            );

          let newAsset = getFormDraggableAssetConfig(
            i.component,
            generatedId,
            generatedLabel,
            assetConfig[i.tagName].sharedProps.layerIconNode,
            {
              ...i.defaultProps,
              ...assetProps
            }
          )

          // can we extrapolate this out to some re-usable variable?
          newFormElem.children.push(newAsset)

          dispatch({
            type: 'addLabelInUse',
            payload: {
              labelToAdd: generatedLabel
            }
          })

          // register the used label locally since we won't have the updated state
          labelsInUse.push(generatedLabel);

          // add to count
          assetCounts[i.component]++;
        }

        dispatch({
          type: 'replaceAsset',
          payload: {
            originalElemId: state.elementEditorId,
            newTreeElem: newFormElem
          }
        })

        return {
          ...state,
          currentFields: []
        };
      }
      default: {
        throw new Error(`There is no function ${action.type} in the form draggable reducer.`)
      }
    }
  }, [])

  const [formDraggableReducerState, formDraggableDispatch] = useReducer(
    formDraggableReducer,
    initState
  );

  return (
    <FormDraggableDialogContext.Provider value={{
      formDraggableReducerState,
      formDraggableDispatch
    }}>
      {children}
    </FormDraggableDialogContext.Provider>
  );
};

FormDraggableDialogContextProvider.propTypes = {
  children: PropTypes.object.isRequired
};

export default FormDraggableDialogContextProvider;
