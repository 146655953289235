const LinkInputStyle = theme => ({
  root: {
    width: '100%',
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: 'flex-start',
  },

  header: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: 'center',
    width: "100%",
  },

  checkbox: {
    marginRight: "8px",
    width: "32px",
    height: "32px",
    position: "relative",
    top: "4px",
  },

  checkboxIcon: {
    width: "32px",
    height: "32px",
    color: '#363744'
  },

  checkedIcon: {
    color: theme.palette.secondary.main
  },

  input: {
    marginTop: "10px",
  },
});

export default LinkInputStyle;
