const HelpDialogStyle = theme => ({
  dialog: {
    height: '550px',
    minWidth: "450px",
    maxHeight: '80vh',
  },

  dialogTitle: {
    width: "100%",

    '& .MuiTypography-root': {
      display: "flex",
      flexDirection: "row",
      width: "100%",
      justifyContent: "space-between",
    },
  },

  closeIcon: {
    color: "white",

    '&:hover':{
      background: "#3a3a4a",
      transition: "0.3s",
    },
  },

  dialogContent: {
    padding: 0,
    marginBottom: "16px",
    overflowY: 'auto',
    boxSizing: 'border-box',

    '&::-webkit-scrollbar': {
      height: '0px',
      width: '2px',
      position: 'relative',
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: 'white',
    },
    '&::-webkit-scrollbar-track': {
      backgroundColor: '#262626',
      opacity: '0.48'
    },
  },

  outerDialogContentContainer: {
    height: "100%",
    display: 'flex',
    flexDirection: 'column',
    padding: "0 24px",
  },

  innerDialogContentContainer: {
    background: "#F5F0FF",
    flexGrow: 1,
    width: "600px",
    color: "black",
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "center",
    borderRadius: "3px",
    padding: '16px',
  },

  searchBarContainer: {
    width: "100%",
    height: "48px",
  },

  notchedOutline: {
    borderColor: "black !important",
    borderRadius: "3px",
  },

  searchBar: {
    '& .MuiInputBase-input': {
      color: "black",
    },
    '& .MuiOutlinedInput-root': {
      height: '48px'
    }
  },

  searchIcon: {
    marginRight: "3px",
    color: 'black'
  },

  accordionContainer: {
    width: "100%",
    marginTop: '16px',

    '& .MuiAccordion-root': {
      margin: '-1px 0px',
      backgroundColor: '#F5F0FF',
      color: 'black',
      border: '1px solid black',
    },

    '& .MuiAccordion-root:last-child': {
      marginBottom: '-1px',
    },
  },

  assetHeaderContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    marginTop: '16px',
  },

  expandIcon: {
    color: 'black'
  },

  plusIcon: {
    width: '20px',
    height: '20px',
    color: 'black'
  },

  linkOuterContainer: {
    width: "100%",
    marginTop: '16px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'flex-start'
  },

  linkInnerContainer: {
    width: '100%',
    height: '48px',
    padding: '16px',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    color: 'black',
    border: '1px solid black',
    borderRadius: '3px',
    marginBottom: '16px'
  },

  assetTitle: {
    '& .MuiTypography-root': {
      color: 'black'
    },
  },

  assetShortDescription: {
    '& .MuiTypography-root': {
      fontSize: '18px',
      color: 'black'
    },
  }
});

export default HelpDialogStyle;