import Typography from "@material-ui/core/Typography";
import React from 'react';

const StylingIcon = () => {
  return (
    <>
      <Typography>
        When having an icon selected, the icon settings panel will appear on the right-hand side of the editor.
        In this panel, you can customize the icon to match your design. You have many options to choose from, such as: size, color, and effects.
      </Typography>
    </>
  );
};

StylingIcon.propTypes = {

};

export default StylingIcon;