const ButtonDraggableStyle = theme => ({
  buttonDraggableRoot: {
    position: 'static',

    '&:hover': props => ({
      background: props.hoverColor
    })
  }
});

export default ButtonDraggableStyle;
