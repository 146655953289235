const MediaGridStyle = theme => ({
  gridContainer: {
    marginTop: "10px",
    minHeight: "355px",
    flex: "1",
    overflowY: "auto",
    paddingRight: "5px",
    width: "100%",

    '&::-webkit-scrollbar': {
      height: '50px',
      width: '2px',
      position: 'relative',
      marginLeft: '10px',
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: '#262626',
      opacity: '0.48'
    },
    '&::-webkit-scrollbar-track': {
      backgroundColor: 'white',
    },

  },

  grid: {
    display: "flex",
    flexWrap: "wrap",
    alignItems: 'flex-start',
    justifyContent: "flex-start",
    flexDirection: "row",

    '& > div': {
      width: "calc(33.33% - 4px)",
      height: "150px",
      margin: "2px",
      display: "inline-flex",
      '& > img': {
        width: "100%",
        height: "100%",
        objectFit: "cover",
      }
    },
  },
});

export default MediaGridStyle;