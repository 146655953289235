import Typography from "@material-ui/core/Typography";
import CloseIcon from "@material-ui/icons/Close";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import SearchIcon from "@material-ui/icons/Search";
import QUESTIONS from "editor/components/dialogs/HelpDialog/HelpDialogQuestions";
import React, {useEffect, useRef, useState} from 'react';
import {
  Accordion, AccordionDetails, AccordionSummary,
  Dialog, DialogContent, DialogTitle, IconButton, TextField,
  withStyles
} from '@material-ui/core';
import PropTypes from 'prop-types';

import HelpDialogStyle from './HelpDialogStyle';

const HelpDialog = props => {
  const {
    classes,
    open,
    setOpen,
    data,
    setData,
  } = props;

  const [searchQuery, setSearchQuery] = useState('')
  const [userSearchQuery, setUserSearchQuery] = useState('');
  const [expanded, setExpanded] = useState(false);
  const defaultCategory = data?.category || 'general';
  const searchTimeout = useRef(null);

  const getFilteredQuestions = () => {
    // If there's a search query, combine all questions from both categories.
    // Otherwise, use the questions from the current category.
    const questionsToFilter = searchQuery ?
      [...QUESTIONS.general, ...QUESTIONS.container] :
      QUESTIONS[defaultCategory];

    // Filter the questions based on the search query
    return questionsToFilter.filter(question =>
      (typeof question.title === 'string' && question.title.toLowerCase().includes(searchQuery.toLowerCase())) ||
      (typeof question.answerText === 'string' && question.answerText.toLowerCase().includes(searchQuery.toLowerCase()))
    );
  };

// Use the function to get the filtered questions
  const displayedQuestions = getFilteredQuestions();

  const handleClose = () => {
    setOpen(false);
    setData({})
  };

  const handleSearchQuery = (e) => {
    console.log('e', e, e.target)

    let query = e.target.value

    if(searchTimeout.current) {
      clearTimeout(searchTimeout.current)
    }
    const timeout = setTimeout(() => {
      setSearchQuery(query)
    }, 1000)
    searchTimeout.current = timeout
    setUserSearchQuery(query)
  };

  const handleAccordionChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  useEffect(() => {
    if (data?.resetAccordion) {
      setExpanded(false);
    }
  }, [data]);

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      maxWidth={'md'}
      classes={{paper: classes.dialog}}
    >
      <DialogTitle className={classes.dialogTitle}>
        {data?.helpAssetHeader ? (
          data.helpAssetHeader.assetType
        ) : (
          'How can we help you?'
        )}

        <IconButton
          onClick={handleClose}
          className={classes.closeIcon}
          size={'small'}
        >
          <CloseIcon/>
        </IconButton>
      </DialogTitle>

      <DialogContent className={classes.dialogContent}>
        <div className={classes.outerDialogContentContainer}>
          <div className={classes.innerDialogContentContainer}>
            <div className={classes.searchBarContainer}>
              <TextField
                variant={"outlined"}
                fullWidth
                placeholder={'What questions do you have?'}
                className={classes.searchBar}
                InputProps={{
                  classes: {
                    notchedOutline: classes.notchedOutline,
                  },
                  startAdornment: <SearchIcon fontSize={'small'} className={classes.searchIcon}/>
                }}
                value={userSearchQuery}
                onChange={handleSearchQuery}
              />
            </div>

            {data?.helpAssetHeader && (
              <div className={classes.assetHeaderContainer}>
                <Typography className={classes.assetShortDescription}>
                  {data.helpAssetHeader.assetShortDescription}
                </Typography>
              </div>
            )}

            <div className={classes.accordionContainer}>
              {displayedQuestions
                ?.filter(question =>
                  (typeof question.title === 'string' && question.title.toLowerCase().includes(searchQuery.toLowerCase())) ||
                  (typeof question.answerText === 'string' && question.answerText.toLowerCase().includes(searchQuery.toLowerCase()))
                )
                .map((question, index) => (
                  <Accordion
                    expanded={expanded === `panel${index}`} // This will check if this accordion is the currently expanded one
                    onChange={handleAccordionChange(`panel${index}`)} // This will change the expanded accordion to the current one when clicked
                    key={index}
                  >
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon className={classes.expandIcon} />}
                      aria-controls={`panel${index}-content`}
                      id={`panel${index}-header`}
                    >
                      <Typography>{question.title}</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <div>
                        {question.answer}
                      </div>
                    </AccordionDetails>
                  </Accordion>
                ))}
            </div>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
};

HelpDialog.propTypes = {
  classes: PropTypes.object
};

export default withStyles(HelpDialogStyle)(HelpDialog);