import Typography from "@material-ui/core/Typography";
import Zoom from "assets/education/videos/zoom.mp4";
import React from 'react';
import PropTypes from 'prop-types';

const HowToMoveAroundCanvas = props => {
  const { classes } = props;

  return (
    <>
      <Typography>
        1. To pan up and down, Scroll. <br/>
        2. To pan left and right, Scroll + Shift. <br/>
        3. To zoom in, Scroll Up + Shift + Ctrl (Windows) or ⌘ Command (Mac).<br/>
        4. To zoom out, Scroll Down + Shift + Ctrl (Windows) or ⌘ Command (Mac).
      </Typography>
      <Typography style={{marginTop: '8px'}}>
        You can also use the zoom buttons found in the top-right of the editor or underneath the 'View' tab.
      </Typography>

      <video
        width="534"
        height="315"
        loop
        autoPlay
        muted
        controls={false}
      >
        <source src={Zoom} type="video/mp4" />
      </video>
    </>
  );
};

HowToMoveAroundCanvas.propTypes = {
  classes: PropTypes.object
};

export default HowToMoveAroundCanvas;