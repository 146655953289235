import RadioDraggable from "editor/draggables/RadioDraggable/RadioDraggable";
import BaseComponentSerializer from "editor/serializers/BaseComponentSerializer";
import {useAssetConfig} from "hooks/useAssetConfig";
import React from "react";

class RadioDraggableSerializer extends BaseComponentSerializer {
  constructor(elem, editorData) {
    super(elem, editorData);
    this.propsToRemove = ['layerIconNode', 'key'];
    this.assetConfig = useAssetConfig();
  }

  deserialize() {
    return {
      tag: RadioDraggable,
      tagName: 'RadioDraggable',
      props: {
        //layerLabel: this.assetConfig.RadioDraggable.props.layerLabel,
        layerIconNode: this.assetConfig.RadioDraggable.sharedProps.layerIconNode
      },
      baseData: {
        ...this.elem?.baseData,
        layerIconNode: this.assetConfig.RadioDraggable.sharedProps.layerIconNode
      }
    }
  }

  serialize() {
    return {
      tag: 'RadioDraggable',
      id: this.elem.id,
      draggableProps: {
        style: {
          ...this.getDraggablePosition()
        }
      },
      props: {
        ...this.formatProps({...this.elem.props}),
      },
      baseData: {
        ...this.formatProps(this.elem.baseData)
      },
      breakpointData: {
        ...this.formatProps(this.elem.breakpointData)
      }
    };
  }
}

export default RadioDraggableSerializer;
