import {faTrashAlt} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

import {IconButton, Tooltip, withStyles} from '@material-ui/core';
import AddBoxIcon from "@material-ui/icons/AddBox";
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import InputIcon from '@material-ui/icons/Input';
import RadioButtonCheckedIcon from '@material-ui/icons/RadioButtonChecked';
import SubjectIcon from '@material-ui/icons/Subject';
import EditIcon from '@material-ui/icons/Edit';

import FormDraggableInputCardStyle
  from 'editor/components/dialogs/FormDraggableDialog/FormDraggableDialogInputCard/FormDraggableInputCardStyle';
import {FormDraggableDialogContext} from "editor/context/FormDraggableDialogContext";
import PropTypes from 'prop-types';
import React, {useContext} from 'react';

const FormDraggableInputCard = props => {
  const {
    classes,
    component,
    type,
    name,
    label,
    ...rest
  } = props;

  const {
    formDraggableReducerState,
    formDraggableDispatch
  } = useContext(FormDraggableDialogContext);

  // move this to the dispatch?
  const getIcon = () => {
    switch (component) {
      case 'TextField':
        return (
          <Tooltip title={'Text Input'}>
            <div>
              <InputIcon/>
            </div>
          </Tooltip>
        )
      case 'TextArea':
        return (
          <Tooltip title={'Text Area'}>
            <div>
              <SubjectIcon/>
            </div>
          </Tooltip>
        )
      case 'RadioButton':
        return (
          <Tooltip title={'Radio Button'}>
            <div>
              <RadioButtonCheckedIcon/>
            </div>
          </Tooltip>
        )
      case 'Checkbox':
        return (
          <Tooltip title={'Checkbox'}>
            <div>
              <CheckBoxIcon/>
            </div>
          </Tooltip>
        )
      case 'Button':
        return (
          <Tooltip title={'Submit Button'}>
            <div>
              <AddBoxIcon/>
            </div>
          </Tooltip>
        )
      default:
        throw new Error(`No Icon exists for ${component}`)
    }
  }

  const handleDelete = () => {
    formDraggableDispatch({
      type: 'deleteInputCard',
      payload: {
        inputName: name
      }
    })
  }

  const handleEdit = () => {
    formDraggableDispatch({
      type: 'setInputBeingEdited',
      payload: {
        inputName: name
      }
    })

    formDraggableDispatch({
      type: 'changePage',
      payload: {
        page: 'inputDetails'
      }
    })
  }

  const moveUpInOrder = () => {
    formDraggableDispatch({
      type: 'moveCardUpInOrder',
      payload: {
        inputName: name
      }
    })
  }

  const moveDownInOrder = () => {
    formDraggableDispatch({
      type: 'moveCardDownInOrder',
      payload: {
        inputName: name
      }
    })
  }

  return (
    // TODO: do we make this clickable and expandable kinda like layers?
    <div className={classes.inputCardRoot}>
      <IconButton onClick={handleDelete} className={classes.deleteButton} size={'small'}>
        <FontAwesomeIcon icon={faTrashAlt}/>
      </IconButton>

      <IconButton onClick={handleEdit} className={classes.editButton} size={"small"}>
        <EditIcon/>
      </IconButton>

      <div className={classes.iconContainer}>
        {getIcon()}
      </div>

      <div className={classes.nameContainer}>
        {label}
      </div>

      <div className={classes.upDownContainer}>
        <IconButton
          size={'small'}
          className={classes.upDownArrow}
          onClick={moveUpInOrder}
        >
          <ArrowDropUpIcon/>
        </IconButton>

        <IconButton
          size={'small'}
          className={classes.upDownArrow}
          onClick={moveDownInOrder}
        >
          <ArrowDropDownIcon/>
        </IconButton>
      </div>
    </div>
  );
};

FormDraggableInputCard.propTypes = {
  classes: PropTypes.object,
  component: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
};

export default withStyles(FormDraggableInputCardStyle)(FormDraggableInputCard);
