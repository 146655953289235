const BackgroundSizeInputStyle = theme => ({
  root: {
    width: '100%',
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: 'flex-start',
  },

  autocompleteContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: 'center',
    width: "100%",
  },

  autocompleteRoot: {
    marginTop: "8px",
    width: "100%",

    '& .MuiSvgIcon-root': {
      color: "white"
    }
  },

  input: {
    background: 'rgba(43, 43, 55, 0.6)',
  },

  listBox: {
    height: "120px",
    overflowY: "auto",
    padding: 0,
    margin: 0,

    '&::-webkit-scrollbar': {
      height: '100px',
      width: '2px',
      position: 'relative',
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: 'white',
    },
    '&::-webkit-scrollbar-track': {
      backgroundColor: '#262626',
      opacity: '0.48'
    },
  },
});

export default BackgroundSizeInputStyle;
