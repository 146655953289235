import {Dialog, DialogContent, DialogTitle, IconButton, withStyles} from '@material-ui/core';
import CloseIcon from "@material-ui/icons/Close";
import SubmitFeedbackContent from "components/SubmitFeedbackContent/SubmitFeedbackContent";
import PropTypes from 'prop-types';
import React from 'react';
import SubmitFeedbackDialogStyle from './SubmitFeedbackDialogStyle';

const SubmitFeedbackDialog = props => {
  const {
    classes,
    open,
    setOpen,
  } = props;

  const handleClose = () => {
    setOpen(false)
  }

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      maxWidth={'md'}
      classes={{paper: classes.dialog}}
    >
      <DialogTitle className={classes.dialogTitle}>
        Give us your feedback!

        <IconButton
          onClick={handleClose}
          className={classes.closeIcon}
          size={'small'}
        >
          <CloseIcon/>
        </IconButton>
      </DialogTitle>

      <DialogContent>
        <SubmitFeedbackContent
          inEditor
          setOpen={setOpen}
        />
      </DialogContent>

    </Dialog>
  );
};

SubmitFeedbackDialog.propTypes = {
  classes: PropTypes.object,
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
};

export default withStyles(SubmitFeedbackDialogStyle)(SubmitFeedbackDialog);
