const AccountSettingsDialogStyle = theme => ({
  dialog: {
    height: "85%",
    width: "75%",
    maxHeight: "720px",
    minHeight: "600px",
  },

  dialogContent: {
    overflowY: "hidden",
    padding: "16px",
  },

  dialogTitle: {
    width: "100%",

    '& .MuiTypography-root': {
      display: "flex",
      flexDirection: "row",
      width: "100%",
      justifyContent: "space-between",
    },
  },

  closeIcon: {
    color: "white",

    '&:hover':{
      background: "#3a3a4a",
      transition: "0.3s",
    },
  },

});

export default AccountSettingsDialogStyle;