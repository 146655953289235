const FormDraggableAddInputStyle = theme => ({
  backButtonContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    color: theme.palette.secondary.main,
  },

  backIcon: {
    marginRight: '5px'
  },

  menu: {
    width: '100%',
    padding: '0'
  },

  formDescription: {
    margin: "8px"
  },

});

export default FormDraggableAddInputStyle;
