import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import Loading from "components/Common/Loading/Loading";
import {ApplicationSettingsContext} from "context/ApplicationSettingsContext";
import React, {useContext, useEffect, useState} from 'react';
import {
  withStyles
} from '@material-ui/core';
import PropTypes from 'prop-types';
import {useHistory} from "react-router-dom";
import {User} from "services";
import {Pencil, Trash} from "tabler-icons-react";
import {handleNetworkError} from "utils/utils";
import TemplatePageStyle from './TemplatePageStyle';

const TemplatePage = props => {
  const { classes } = props;

  const history = useHistory();

  const {templates} = useContext(ApplicationSettingsContext);

  useEffect(() => {
    console.log('template list', templates);
  }, [templates])

  const handleSelectTemplate = (selectedTemplate) => {
    if(selectedTemplate) {
      window.localStorage.removeItem('projectId');
      window.localStorage.removeItem('versionId');

      history.push('/editor', {
        templateId: selectedTemplate.id,
        versionId: null,
        projectId: null
      })
    }
    else {
      history.push('/editor');
    }
  }

  if(!templates) return (
    <div className={classes.root}>
      <Loading/>
    </div>
  )

  return (
    <div className={classes.root}>
      <div className={classes.projectNameContainer}>
        <Typography
          variant="h3"
        >
          Choose a Template
        </Typography>
      </div>

      <div className={classes.gridContainer}>

        <div className={classes.blankCanvasContainer} onClick={() => handleSelectTemplate()}>
          <Pencil className={classes.pencil}/>
          <div>
            <Typography
              variant="h6"
            >
              Blank canvas
            </Typography>
          </div>
        </div>

        {templates?.map((data, index) => (
          <div
            key={data.id}
            className={classes.projectContainer}
            onClick={() => handleSelectTemplate(data)}
          >
            <img
              src={data.screenshot}
              className={classes.projectImage}
            />

            <div className={classes.projectCardFooter}>

              <div className={classes.projectCardFooterText}>
                <Typography
                  variant="h6"
                  className={classes.title}
                >
                  {data.name}
                </Typography>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

TemplatePage.propTypes = {
  classes: PropTypes.object
};

export default withStyles(TemplatePageStyle)(TemplatePage);
