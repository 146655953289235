const DEFAULT_TREE = {
  // the base element of the editor
  'editor-root': {
    id: 'editor-root',
    tag: 'div',
    props: {
      id: 'editor-root',
      'data-editorid': 'editor-root'
    },
    children: []
  }
}

export {
  DEFAULT_TREE
}
