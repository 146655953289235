import ActionBar from "editor/components/ActionBar/ActionBar";
import React, {useContext, useEffect, useRef, useState} from 'react';

import ResizeableElementStyle from './ResizeableElementStyle';

import {makeStyles, withStyles} from '@material-ui/core';
import cn from 'classnames';
import PropTypes from 'prop-types';

import {EditorContext} from "editor/Editor";

const useStyles = makeStyles(ResizeableElementStyle);

const ResizeableElement = props => {
  const {
    children,
    onResizeStart,
    onResizeStop,
    isSelected,
    draggableRef,
    minWidth,
    minHeight,
    showHandles,
    showActionBar,
    editorId,
    isChildElem,
    style,
    currentSize,
    setCurrentSize,
    ...rest
  } = props;

  const classes = useStyles();

  const handles = {
    n: {elem: null, class: classes.handleTop},
    ne: {elem: null, class: classes.handleTopRight},
    e: {elem: null, class: classes.handleRight},
    se: {elem: null, class: classes.handleBottomRight},
    s: {elem: null, class: classes.handleBottom},
    sw: {elem: null, class: classes.handleBottomLeft},
    w: {elem: null, class: classes.handleLeft},
    nw: {elem: null, class: classes.handleTopLeft}
  };

  const elemRef = useRef();
  const childrenRef = useRef([]);

  const widthHasChanged = useRef(false);
  const heightHasChanged = useRef(false);

  // this holds the initial screen coordinates of the element when it's first clicked, used to calculate
  // the amount to increase/decrease resizing
  const initialCoords = useRef();
  const currentResizeHandle = useRef();

  const editorData = useContext(EditorContext);
  const {editorState, editorActions, editorSettings} = editorData.editorReducerState;

  const selectedElemData = editorState.elementSelected.data;

  const {zoomLevel} = editorSettings;
  const editorBounds = editorState.editorBounds;

  const changeCursorStyleGlobally = cursorStyle => {
    // should be a valid CSS handle or 'false' which will remove all styles
    if(cursorStyle === false) {
      document.body.style.cursor = 'auto';
    }
    else {
      document.body.style.cursor = cursorStyle;
    }
  };

  const handleOutOfBounds = (direction, e, rect) => {
    const currentElemStyle = elemRef.current.style;

    let zoomContainerNode = document.getElementById('zoom-container');
    const zoomContainerBounds = zoomContainerNode.getBoundingClientRect();

    switch(direction) {
      case 'right':
        currentElemStyle.width = `${(Math.abs(rect.left - zoomContainerBounds.right) * (1/zoomLevel))}px`;
        break;
      case 'left':
        currentElemStyle.width = `${(Math.abs(rect.right - zoomContainerBounds.left) * (1/zoomLevel))}px`;
        draggableRef.style.left = `${editorBounds.left}px`;
        break;
      case 'bottom':
        currentElemStyle.height = `${(Math.abs(rect.top - zoomContainerBounds.bottom) * (1/zoomLevel))}px`;
        break;
      case 'top':
        currentElemStyle.height = `${(Math.abs(rect.bottom - zoomContainerBounds.top) * (1/zoomLevel))}px`;
        draggableRef.style.top = `${editorBounds.top}px`;
        break;
      default:
        return;
    }
  }

  const handleMouseMove = (e) => {
    // This resizing was inspired by https://www.youtube.com/watch?v=4qyuNBlc8ho

    // this is a little hack to make the mouse cursor stay changed when resizing
    // since all resize cursor options are in a format like "sw-resize" we can use the handle names
    changeCursorStyleGlobally(`${currentResizeHandle.current}-resize`);
    const currentElemStyle = elemRef.current.style;

    const rect = elemRef.current.getBoundingClientRect();
    let zoomContainerNode = document.getElementById('zoom-container');
    const zoomContainerBounds = zoomContainerNode.getBoundingClientRect();

    console.log('handle mosue move test', {
      elemRef: elemRef.current,
      width: elemRef.current?.style?.width,
      height: elemRef.current?.style?.height,
      rectWidth: rect.width,
      rectHeight: rect.height
    });

    // this doesn't work
    // let rect = {
    //   left: (elemRef.current.offsetLeft * zoomLevel),
    //   right: (elemRef.current.offsetLeft * zoomLevel) + (elemRef.current.clientWidth * zoomLevel),
    //   top: (elemRef.current.offsetTop * zoomLevel),
    //   bottom: (elemRef.current.offsetTop * zoomLevel) + (elemRef.current.clientHeight * zoomLevel),
    //   width: elemRef.current.clientWidth * zoomLevel,
    //   height: elemRef.current.clientHeight * zoomLevel
    // }

    let calculatedDraggableRect = {
      left: (draggableRef.offsetLeft * zoomLevel),
      right: (draggableRef.offsetLeft * zoomLevel) + (draggableRef.clientWidth * zoomLevel),
      top: (draggableRef.offsetTop * zoomLevel),
      bottom: (draggableRef.offsetTop * zoomLevel) + (draggableRef.clientHeight * zoomLevel),
      width: (draggableRef.clientWidth * zoomLevel),
      height: (draggableRef.clientHeight * zoomLevel)
    }

    let {clientX, clientY} = e;

    const editorBounds = editorState.editorBounds;

    const scale_factor = 1 / zoomLevel; // Common scaling factor for all cases

    switch (currentResizeHandle.current) {
      case 'se':
        const deltaX_se = (initialCoords.current.x - clientX) * scale_factor;
        const deltaY_se = (initialCoords.current.y - clientY) * scale_factor;
        currentElemStyle.width = parseFloat(currentElemStyle.width) - deltaX_se + 'px';
        currentElemStyle.height = parseFloat(currentElemStyle.height) - deltaY_se + 'px';
        widthHasChanged.current = true;
        heightHasChanged.current = true;
        break;

      case 'sw':
        const deltaX_sw = (initialCoords.current.x - clientX) * scale_factor;
        const deltaY_sw = (initialCoords.current.y - clientY) * scale_factor;
        currentElemStyle.width = parseFloat(currentElemStyle.width) + deltaX_sw + 'px';
        currentElemStyle.height = parseFloat(currentElemStyle.height) - deltaY_sw + 'px';
        draggableRef.style.left = calculatedDraggableRect.left + deltaX_sw + 'px';
        widthHasChanged.current = true;
        heightHasChanged.current = true;
        break;

      case 'ne':
        const deltaX_ne = (initialCoords.current.x - clientX) * scale_factor;
        const deltaY_ne = (initialCoords.current.y - clientY) * scale_factor;
        currentElemStyle.width = parseFloat(currentElemStyle.width) - deltaX_ne + 'px';
        currentElemStyle.height = parseFloat(currentElemStyle.height) + deltaY_ne + 'px';
        draggableRef.style.top = calculatedDraggableRect.top + deltaY_ne + 'px';
        widthHasChanged.current = true;
        heightHasChanged.current = true;
        break;

      case 'nw':
        const deltaX_nw = (initialCoords.current.x - clientX) * scale_factor;
        const deltaY_nw = (initialCoords.current.y - clientY) * scale_factor;
        currentElemStyle.width = parseFloat(currentElemStyle.width) + deltaX_nw + 'px';
        currentElemStyle.height = parseFloat(currentElemStyle.height) + deltaY_nw + 'px';
        draggableRef.style.left = calculatedDraggableRect.left + deltaX_nw + 'px';
        draggableRef.style.top = calculatedDraggableRect.top + deltaY_nw + 'px';
        widthHasChanged.current = true;
        heightHasChanged.current = true;
        break;

      case 'e':
        const deltaX_e = (initialCoords.current.x - clientX) * scale_factor;
        currentElemStyle.width = parseFloat(currentElemStyle.width) - deltaX_e + 'px';
        widthHasChanged.current = true;
        break;

      case 'w':
        const deltaX_w = (initialCoords.current.x - clientX) * scale_factor;
        currentElemStyle.width = parseFloat(currentElemStyle.width) + deltaX_w + 'px';
        draggableRef.style.left = calculatedDraggableRect.left + deltaX_w + 'px';
        widthHasChanged.current = true;
        break;

      case 'n':
        const deltaY_n = (initialCoords.current.y - clientY) * scale_factor;
        currentElemStyle.height = parseFloat(currentElemStyle.height) + deltaY_n + 'px';
        draggableRef.style.top = calculatedDraggableRect.top + deltaY_n + 'px';
        heightHasChanged.current = true;
        break;

      case 's':
        const deltaY_s = (initialCoords.current.y - clientY) * scale_factor;
        currentElemStyle.height = parseFloat(currentElemStyle.height) - deltaY_s + 'px';
        heightHasChanged.current = true;
        break;

      default:
        break;
    }

    // if out of bounds, snap back
    if(rect.right > zoomContainerBounds.right) {
      handleOutOfBounds('right', e, rect);
    }

    if(rect.left < zoomContainerBounds.left) {
      handleOutOfBounds('left', e, rect);
    }

    if(rect.bottom > zoomContainerBounds.bottom) {
      handleOutOfBounds('bottom', e, rect);
    }

    if(rect.top < zoomContainerBounds.top) {
      handleOutOfBounds('top', e, rect)
    }

    // set the initial coordinates to the new mouse position
    initialCoords.current.x = clientX;
    initialCoords.current.y = clientY;

    let newCurrentSize = {
      width: currentElemStyle.width ? currentElemStyle.width :
      selectedElemData?.props?.style?.width ? selectedElemData.props.style.width : rect.width + 'px',

      height: currentElemStyle.height ? currentElemStyle.height :
      selectedElemData?.props?.style?.height ? selectedElemData.props.style.height : rect.height + 'px'
    };

    // update the internal measurements to match what the user just dragged
    setCurrentSize(newCurrentSize);
  }

  const handleMouseUp = (e, handle) => {

    //remove listeners
    window.removeEventListener('mousemove', handleMouseMove);
    window.removeEventListener('mouseup', handleMouseUp);

    // set cursor style back
    changeCursorStyleGlobally(false);

    onResizeStop(widthHasChanged.current, heightHasChanged.current);

    widthHasChanged.current = false;
    heightHasChanged.current = false;
  }

  const handleClick = (e, handle) => {
    e.stopPropagation();
    e.preventDefault();

    initialCoords.current = {x: e.clientX, y: e.clientY};
    console.log('initial coords', initialCoords.current)
    currentResizeHandle.current = handle;

    // callback to the draggable core to disable dragging
    onResizeStart();

    console.log('initial click screen coordinates', initialCoords.current);

    // user might be attempting to resize, add the listeners here
    window.addEventListener('mousemove', handleMouseMove);
    window.addEventListener('mouseup', handleMouseUp);
  }

  return (
    <div
      className={showHandles ? cn(classes.root, classes.selected) : classes.root}
      ref={elemRef}
      style={style}
      {...rest}
    >
      {isSelected && showActionBar && (
        <ActionBar
          editorId={editorId}
        />
      )}

      {showHandles &&
        (Object.keys(handles).map((handle, i) => (
          <div
            key={handle}
            className={`${classes.handle} ${handles[handle].class}`}
            style={{transform: `scale(${(1/zoomLevel)})`}}
            ref={ref => handles[handle].elem = ref}
            onMouseDown={e => handleClick(e, handle)}
          >
          </div>
        )))
      }

      {/* not 100% sure why this works, maybe look into React.Children? */}
      {/* https://stackoverflow.com/questions/63654496/is-it-possible-to-add-ref-to-the-props-children-elements */}
      {React.Children.map(React.Children.only(children), (child, index) =>
        React.cloneElement(child, {
          ref: (ref) => (elemRef.current = ref),
        })
      )}
    </div>
  );
};

ResizeableElement.defaultProps = {
  minWidth: 35,
  minHeight: 35,
  showHandles: false,
  showActionBar: true,
};

ResizeableElement.propTypes = {
  classes: PropTypes.object,
  children: PropTypes.element.isRequired,
  onResizeStart: PropTypes.func,
  onResizeStop: PropTypes.func,
  showHandles: PropTypes.bool,
  showActionBar: PropTypes.bool,

  minHeight: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]).isRequired, // in px
  minWidth: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]).isRequired, // in px
  isSelected: PropTypes.bool.isRequired,
  draggableRef: PropTypes.object,
  editorId: PropTypes.string.isRequired,
  style: PropTypes.object.isRequired,
  setCurrentSize: PropTypes.func.isRequired,
};

export default ResizeableElement;
