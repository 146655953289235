const InputDraggableStyle = {
  root: {
    width: '100%',
    minWidth: '35px',
    height: '100%',
    minHeight: '35px',

    '& input': {
      color: props => props.textColor || '#FFFFFFF',
    },

    '& fieldset': {
      borderColor: props => props.outlineColor + ' !important'
    },

    '&:hover fieldset': {
      borderColor: props => props.outlineColor + ' !important'
    },

    '& label': {
      color: props => props.labelColor || '#FFFFFFF',
      "&.Mui-focused": {
        color: props => props.labelColor || '#FFFFFFF',
      }
    },
  },
};

export default InputDraggableStyle;
