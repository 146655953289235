import {withStyles} from '@material-ui/core';
import Typography from "@material-ui/core/Typography";
import DescriptionOutlinedIcon from "@material-ui/icons/DescriptionOutlined";
import FolderIcon from '@material-ui/icons/Folder';
import DraggableWrapper from "editor/draggables/DraggableWrapper/DraggableWrapper";
import {EditorContext} from "editor/Editor";
import PropTypes from 'prop-types';
import React, {useContext, useEffect, useRef, useState} from 'react';
import {getElementsAtClientCoordinates} from "utils/utils";
import cn from 'classnames';

import DraggablePageLabelStyle from 'editor/draggables/DraggablePageLabel/DraggablePageLabelStyle';

const DraggablePageLabel = React.forwardRef((props, ref) => {
  const {
    classes,
    labelId,
    addFolder,
    childPages,
    isInFolder,
    selectPage,
    name
  } = props;

  // a node created to keep the place of the icon when dragged since the icon gets removed from page flow
  const placeholderNode = useRef(null);
  const elemRef = useRef(null);
  const editorData = useContext(EditorContext);
  const {editorReducerState, dispatch} = editorData;
  const pages = editorReducerState.editorState.editorPages.value;
  const selectedPage = editorReducerState.editorState.editorPages.selectedPage
  const [isSelected, setIsSelected] = useState(selectedPage === labelId)

  const DEFAULT_STARTING_POSITION = {
    clientX: null,
    clientY: null
  };

  const startingPosition = useRef(DEFAULT_STARTING_POSITION);

  useEffect(() => {
    console.log('child pages', childPages)
  }, [childPages])

  useEffect(() => {
    if(elemRef.current) {
      let newPages = [...pages];
      let currentPage = newPages.find(elem => elem.labelId === labelId)

      if(currentPage && !currentPage.ref) {
        currentPage.ref = elemRef.current;

        dispatch({
          type: 'setPages',
          payload: {
            newPages: newPages
          }
        })

        console.log('newpages', newPages)
      }
    }
  }, [elemRef])

  useEffect(() => {
    setIsSelected(selectedPage === labelId)
  }, [selectedPage])

  const dropOnFolder = (foundElem, foundElemPage) => {
    /**
     * @param {Node} foundElem - a DOM Node representing the element dropped on
     * @param {Object} foundElemPage - the entry in editor pages representing the element dropped on
     */

    console.log('dropped on folder!')
    let newPages = [...pages];

    let pageBeingDroppedId = elemRef.current.getAttribute('data-labelid')
    let pageBeingDropped = newPages.find(elem => elem.labelId === pageBeingDroppedId)

    // the folder we are dropping onto
    let newFoundElemPage = newPages.find(elem => elem.labelId === foundElemPage.labelId);

    newPages.splice(newPages.indexOf(pageBeingDropped), 1)
    newFoundElemPage.children.push(pageBeingDropped);

    dispatch({
      type: 'setPages',
      payload: {
        newPages: newPages
      }
    })
  }

  const dropOnOtherPage = (foundElem, foundElemPage) => {
    /**
     * @param {Node} foundElem - a DOM Node representing the element dropped on
     * @param {Object} foundElemPage - the entry in editor pages representing the element dropped on
     */

    console.log('dropped on another page')
    addFolder([foundElem, elemRef.current]);
  }

  const removePlaceholder = () => {
    if(placeholderNode.current) {
      placeholderNode.current.remove()
      placeholderNode.current = null;
    }
  }

  const handleStart = (eventHandler, data) => {
    let node = data.node;
    let bounds = node.getBoundingClientRect();

    startingPosition.current.clientX = bounds.x;
    startingPosition.current.clientY = bounds.y;
  }

  const handleDrag = (eventHandler, data) => {

    // if(!placeholderNode?.current) {
    //   // create a duplicate, empty div so that elements don't shift, then delete this on drag end
    //   let node = data.node;
    //
    //   let bounds = node.getBoundingClientRect();
    //   let height = bounds.height;
    //   let width = bounds.width;
    //
    //   console.log('starting position', startingPosition.current)
    //
    //   let newPlaceholderNode = document.createElement('DIV');
    //
    //   newPlaceholderNode.style.width = `${width}px`;
    //   newPlaceholderNode.style.height = `${height}px`;
    //   node.after(newPlaceholderNode);
    //   placeholderNode.current = newPlaceholderNode;
    // }
  }

  const handleDrop = (eventHandler, data) => {
    let bounds = data.node.getBoundingClientRect()

    // data.node is the original node of the icon
    // snapBackToOriginalPosition(data.node);
    // removePlaceholder()

    let deltaX = Math.abs(bounds.x - startingPosition.current.clientX)
    let deltaY = Math.abs(bounds.y - startingPosition.current.clientY)

    console.log('handle drop was called', {deltaX, deltaY})

    let selfLabelId = elemRef.current.getAttribute('data-labelid');

    // if the label hasn't moved more than 10 pixels vertically or horizontally, then it's a click
    if(deltaX < 10 && deltaY < 10) {
      // this was a click
      selectPage(selfLabelId)
      return;
    }

    // if(!isInFolder) {
    //   const {clientX, clientY} = eventHandler;
    //
    //   let elemsOnDrop = getElementsAtClientCoordinates(clientX, clientY);
    //
    //   // the actual DOM element that we dropped on
    //   let foundElem = null;
    //
    //   // the entry in editor pages
    //   let foundElemPage = null;
    //
    //   console.log('elems on drop', elemsOnDrop)
    //   for(let i of elemsOnDrop) {
    //     if(i.hasAttribute('data-labelid')) {
    //       console.log('elem found', i)
    //       foundElem = i;
    //
    //       let elemLabelId = i.getAttribute('data-labelid');
    //       foundElemPage = pages.find(elem => elem.labelId === elemLabelId);
    //
    //       break;
    //     }
    //   }
    //
    //   if(foundElem && foundElemPage && foundElemPage?.labelId !== selfLabelId) {
    //     if(foundElemPage.tree) {
    //       // this has a tree, so it's a page
    //       // TODO: since we disabled folders, this function crashes
    //       //dropOnOtherPage(foundElem, foundElemPage)
    //     }
    //     else {
    //       // this is a folder
    //       dropOnFolder(foundElem, foundElemPage)
    //     }
    //     // create folder
    //     console.log('found elem', {foundElem, foundElemPage})
    //
    //   }
    // }
    // else {
    //   // TODO: add code for removing from folder
    // }
  };

  const snapBackToOriginalPosition = (labelNode) => {
    labelNode.style.position = 'static';
  };

  return (
    <>
      <DraggableWrapper
        onStart={handleStart}
        onDrag={handleDrag}
        onStop={handleDrop}
        canBeDraggedOutOfEditor
        doesNotRenderGridLines
        resizeable={false}
        editorDraggable={false}
      >
        <div
          data-labelid={labelId}
          ref={elemRef}
          id={'page-label'}
        >
          <Typography
            variant={'body1'}
            className={cn(classes.root, isSelected ? classes.selected : null)}
          >
            {childPages ? (
              <FolderIcon className={classes.pageIcon}/>
            ) : (
              <DescriptionOutlinedIcon className={classes.pageIcon}/>
            )}
            {name}
          </Typography>
        </div>
      </DraggableWrapper>

      {childPages && (
        <div className={classes.childPageContainer}>
          {childPages.map(({pageName, labelId}, index) => (
            <DraggablePageLabel
              key={labelId}
              labelId={labelId}
              name={pageName}
              addFolder={addFolder}
              classes={classes}
              selectPage={selectPage}
              isInFolder
            />
          ))}
        </div>
      )}
    </>
  );
});

DraggablePageLabel.defaultProps = {
  isInFolder: false
}

DraggablePageLabel.propTypes = {
  classes: PropTypes.object,
  labelId: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  addFolder: PropTypes.func.isRequired,
  isInFolder: PropTypes.bool.isRequired,
  selectPage: PropTypes.func.isRequired,
  childPages: PropTypes.bool
};

export default withStyles(DraggablePageLabelStyle)(DraggablePageLabel);
