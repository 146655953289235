import {Button, MenuItem, TextField, Typography, withStyles} from "@material-ui/core";
import {Autocomplete} from "@material-ui/lab";
import {CardCvcElement, CardExpiryElement, CardNumberElement, useElements, useStripe} from "@stripe/react-stripe-js";
import cn from "classnames";
import StripeCardInput from "components/StripeInputs/StripeCardInput/StripeCardInput";
import StripeFormStyle from "components/StripeForm/StripeFormStyle";
import StripeCVCInput from "components/StripeInputs/StripeCVCInput/StripeCVCInput";
import StripeExpirationInput from "components/StripeInputs/StripeExpirationInput/StripeExpirationInput";
import StripeTextField from "components/StripeTextField/StripeTextField";
import {UserContext} from "context/UserContext";
//import {State} from 'country-state-city';
import {Form, Formik} from "formik";
import PropTypes from 'prop-types';
import React, {useContext, useMemo, useState} from 'react';
import {useHistory} from "react-router-dom";
import countryList from 'react-select-country-list'
import {toast} from "react-toastify";
import {CountryData, User} from "services";
import {handleNetworkError} from "utils/utils";
import * as yup from "yup";

const StripeForm = props => {
  const {
    classes,
    isEditingCard,
    isInDialog,
    onSuccess
  } = props;

  const stripe = useStripe();
  const elements = useElements();
  const history = useHistory();
  const {userData, setUserData} = useContext(UserContext);

  const [busy, setBusy] = useState(false)
  const [formError, setFormError] = useState("");
  const [provincesInSelectedCountry, setProvincesInSelectedCountry] = useState([]);

  // because we don't require a state/province if the length is 0 (due to some countries
  // not having states/provinces) we need to check if this has been changed so we do require
  // the user to at least pick a country
  const [countryTouched, setCountryTouched] = useState(false);

  const schema = yup.object().shape({
    address1: yup.string('Address is invalid').required('Street Address is a required field'),
    address2: yup.string('Address 2 is invalid'),
    city: yup.string('City is invalid').required('City is a required field'),
    zipCode: yup.number('Zip Code is invalid').required('Zip Code is a required field'),
    country: yup.string('Country is invalid').required('Country is a required field'),
    state: provincesInSelectedCountry.length === 0 && countryTouched ?
      yup.string() : yup.string().required('State/Province is a required field')
  });

  const countryData = useMemo(() => countryList().getData(), []);

  const onSubmit = async (values, {setErrors, setFieldError}) => {
    let tokenData = {
      'address_line1': values.address1,
      'address_line2': values.address2,
      'address_city': values.city,
      'address_state': values.state,
      'address_zip': values.zipCode,
      'address_country': values.country
    }

    // stripe will check all elements, just needs one at random
    const cardNumberElement = elements.getElement(CardNumberElement);

    const result = await stripe.createToken(cardNumberElement, tokenData);

    if (result.token) {
      // move to next page
      let data = {
        clientIp: result.token['client_ip'],
        livemode: result.token['livemode'],
        paymentType: result.token['type'],
        card: {
          addressCity: result.token.card['address_city'],
          addressCountry: result.token.card['address_country'],
          addressLine1: result.token.card['address_line1'],
          addressLine2: result.token.card['address_line2'],
          addressState: result.token.card['address_state'],
          addressZipCode: result.token.card['address_zip'],
          brand: result.token.card['brand'],
          country: result.token.card['country'],
          funding: result.token.card['funding'],
          last4: result.token.card['last4'],
          stripeId: result.token.card['id'],
          stripeToken: result.token['id'],
        }
      }
      // send token to django
      setFormError('')
      if(isEditingCard) {

        setBusy(true)

        User.changePaymentMethod(data)
          .then(response => {
            console.log('payment method changed', response);

            toast.success('Card successfully changed!')

            if(onSuccess) {
              onSuccess(data);
            }
          })
          .catch(handleNetworkError)
          .finally(() => {
            setBusy(false)
          })
      }
      else {

        setBusy(true)

        User.createSubscription(data)
          .then(response => {
            let newUserData = {...userData, subscriptionStatus: true, last4: response?.data?.last4};
            setUserData(newUserData);

            if(onSuccess) {
              onSuccess(data);
            }
            else {
              history.push('/editor')
            }

          }).catch(handleNetworkError).finally(() => {
          setBusy(false)
        })
      }
    }
    else {
      setFormError(result?.error.message);
    }
  };

  const handleCountryChange = (e, value, reason, setFieldValue) => {
    console.log('handle country change', {e, value, reason})
    //value is an object with keys label and value - we want value inside of this object
    const countryValue = value?.value

    CountryData.getStatesInCountry(countryValue)
      .then(response => {
        setProvincesInSelectedCountry(response.data)
      })
      .catch(handleNetworkError);

    //setProvincesInSelectedCountry(State.getStatesOfCountry(countryValue))
    setFieldValue('country', countryValue)
    setCountryTouched(true);
  }

  return (
    <>
      {formError && (
        <Typography className={classes.formErrorMessage}>
          {formError}
        </Typography>
      )}
      <Formik
        initialValues={{
          address1: "",
          address2: "",
          country: "",
          city: "",
          state: "",
          zipCode: "",
        }}
        onSubmit={onSubmit}
        validationSchema={schema}
        validateOnChange={false}
      >
        {({
            values,
            errors,
            handleChange,
            setFieldError,
            setFieldValue,
          }) => (
          <Form>
            <div className={classes.formContainer}>
              <div className={classes.formInputContainer}>
                <StripeCardInput/>
                <StripeExpirationInput/>
                {/*<StripeTextField*/}
                {/*  label={'Expiration'}*/}
                {/*  stripeElement={CardExpiryElement}*/}
                {/*  name={'expDate'}*/}
                {/*  className={cn(classes.formInput, classes.expDate)}*/}
                {/*  placeholder={'MM/YY'}*/}
                {/*  InputProps={{*/}
                {/*    classes: {*/}
                {/*      root: classes.inputRoot,*/}
                {/*      input: classes.input*/}
                {/*    },*/}
                {/*  }}*/}
                {/*/>*/}
                <StripeCVCInput/>
                {/*<StripeTextField*/}
                {/*  label={'CVC'}*/}
                {/*  stripeElement={CardCvcElement}*/}
                {/*  name={'cvc'}*/}
                {/*  placeholder={'CVC'}*/}
                {/*  className={cn(classes.formInput, classes.cvc)}*/}
                {/*  InputProps={{*/}
                {/*    classes: {*/}
                {/*      root: classes.inputRoot,*/}
                {/*      input: classes.input*/}
                {/*    },*/}
                {/*  }}*/}
                {/*/>*/}
              </div>

              <div className={classes.formInputContainer}>
                <TextField
                  className={cn(classes.formInput, classes.address)}
                  placeholder="1234 Street St"
                  label="Street Address"
                  name="address1"
                  error={!!errors.address1}
                  helperText={errors.address1}
                  InputLabelProps={{shrink: true}}
                  value={values.address1}
                  onChange={handleChange}
                  variant="outlined"
                  fullWidth
                  InputProps={{
                    classes: {input: classes.input},
                  }}
                />
              </div>
              <div className={classes.formInputContainer}>
                <TextField
                  className={cn(classes.formInput, classes.address)}
                  label="Apt, unit, etc. (optional)"
                  placeholder="Apt 1234"
                  name="address2"
                  error={!!errors.address2}
                  helperText={errors.address2}
                  InputLabelProps={{shrink: true}}
                  value={values.address2}
                  onChange={handleChange}
                  variant="outlined"
                  fullWidth
                  InputProps={{
                    classes: {input: classes.input},
                  }}
                />
              </div>

              <div className={classes.formInputContainer}>
                <Autocomplete
                  options={countryData}
                  autoHighlight
                  fullWidth
                  onChange={
                    (
                      e,
                      value,
                      reason
                    ) => handleCountryChange(e, value, reason, setFieldValue)}
                  getOptionLabel={(option) => option.label}
                  renderOption={(option) => (
                    <>
                      {option.label}
                    </>
                  )}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      className={classes.formInput}
                      label={'Country'}
                      variant={'outlined'}
                      fullWidth
                      inputProps={{
                        ...params.inputProps,
                      }}
                    />
                  )}
                />
              </div>

              <div className={classes.formInputContainer}>
                <TextField
                  className={cn(classes.formInput, classes.city)}
                  label="City"
                  placeholder="Chicago"
                  name="city"
                  error={!!errors.city}
                  helperText={errors.city}
                  InputLabelProps={{shrink: true}}
                  onChange={handleChange}
                  value={values.city}
                  variant="outlined"
                  InputProps={{
                    classes: {
                      root: classes.inputRoot,
                      input: classes.input
                    },
                  }}
                />
                <TextField
                  select
                  className={cn(classes.formInput, classes.state)}
                  label="State/Province"
                  name="state"
                  error={!!errors.state}
                  helperText={errors.state}
                  InputLabelProps={{shrink: true}}
                  value={values.state}
                  onChange={handleChange}
                  variant="outlined"
                  disabled={provincesInSelectedCountry.length === 0}
                  InputProps={{
                    classes: {
                      root: classes.inputRoot,
                      input: classes.input,
                      icon: classes.selectIcon
                    },
                  }}
                >
                  {provincesInSelectedCountry.map((data, index) => (
                    <MenuItem
                      value={data}
                      key={data}
                    >
                      {data}
                    </MenuItem>
                  ))}
                </TextField>
                <TextField
                  className={cn(classes.formInput, classes.zipCode)}
                  label="Zip Code"
                  name="zipCode"
                  error={!!errors.zipCode}
                  placeholder="12345"
                  helperText={errors.zipCode}
                  InputLabelProps={{shrink: true}}
                  value={values.zipCode}
                  onChange={handleChange}
                  variant="outlined"
                  InputProps={{
                    classes: {input: classes.input},
                  }}
                />
              </div>
              <Button
                variant='contained'
                type="submit"
                disabled={busy}
                className={classes.purpleButton}
              >
                Finish Subscription
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    </>
  );
}

StripeForm.propTypes = {
  classes: PropTypes.object,
  isEditingCard: PropTypes.bool.isRequired
};

export default withStyles(StripeFormStyle)(StripeForm);
