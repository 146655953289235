import {Dialog, DialogActions, DialogContent, DialogTitle, IconButton, TextField, withStyles} from '@material-ui/core';
import Button from "@material-ui/core/Button";
import CloseIcon from "@material-ui/icons/Close";
import SubmitBugContent from "components/SubmitBugContent/SubmitBugContent";
import SubmitFeedbackContent from "components/SubmitFeedbackContent/SubmitFeedbackContent";
import {Form, Formik} from "formik";
import PropTypes from 'prop-types';
import React from 'react';
import {toast} from "react-toastify";
import {ContactUs} from "services";
import SubmitBugDialogStyle from './SubmitBugDialogStyle';

const SubmitBugDialog = props => {
  const {
    classes,
    open,
    setOpen,
  } = props;

  const handleClose = () => {
    setOpen(false)
  }

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      maxWidth={'md'}
      classes={{paper: classes.dialog}}
    >
      <DialogTitle className={classes.dialogTitle}>
        Having issues? Let us know!

        <IconButton
          onClick={handleClose}
          className={classes.closeIcon}
          size={'small'}
        >
          <CloseIcon/>
        </IconButton>
      </DialogTitle>

      <DialogContent>
        <SubmitBugContent
          inEditor
          setOpen={setOpen}
        />
      </DialogContent>
    </Dialog>
  );
};

SubmitBugDialog.propTypes = {
  classes: PropTypes.object,
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
};

export default withStyles(SubmitBugDialogStyle)(SubmitBugDialog);
