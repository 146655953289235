import InformationIcon from "components/InformationIcon/InformationIcon";
import React from 'react';

import {
  withStyles
} from '@material-ui/core';
import PropTypes from 'prop-types';

import InputGroupHeaderStyle from './InputGroupHeaderStyle';
import Typography from "@material-ui/core/Typography";

const InputGroupHeader = props => {
  const {
    classes,
    children,
    setting
  } = props;

  return (
    <div className={classes.root}>
      <Typography variant={'subtitle1'} className={classes.title}>
        {children}

        {setting.tooltip &&
          (
            <InformationIcon
              tooltipTitle={setting.tooltip}
            />
          )
        }
      </Typography>
    </div>
  );
};

InputGroupHeader.propTypes = {
  classes: PropTypes.object,
  children: PropTypes.string.isRequired,
};

export default withStyles(InputGroupHeaderStyle)(InputGroupHeader);
