const InputGroupHeaderStyle = theme => ({
  root: {
    width: '100%',
    height: '32px',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    marginBottom: '3px'
  },

  title: {
    fontSize: '15px'
  }
});

export default InputGroupHeaderStyle;
