const CanvasBreakpointInputStyle = theme => ({
  root: {
    width: '100%',
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: 'flex-start',
  },

  inputContainer: {
    width: "100%",
    marginBottom: "7px"
  },

  breakpointContainer: {
    width: "80%",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    marginTop: "7px"
  },

  icon: {
    color: theme.palette.common.white,
  },

  active: {
    color: theme.palette.secondary.main
  },

  inactive: {
    color: theme.palette.common.white,
  },

  visibilityIcon: {
    transition: "0.3s",
    position: "absolute",
    bottom: "-3px",
    right: "0px",
    width: "18px"
  },

  iconButton: {
    '&:hover': {
      background: "rgba(255,255,255,0.1)",
      transition: "0.3s"
    }
  }
});

export default CanvasBreakpointInputStyle;