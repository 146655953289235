import Typography from "@material-ui/core/Typography";
import React from 'react';

const HowToUploadImage = () => {
  return (
    <>
      <Typography style={{marginBottom: '8px'}}>
        To upload an image, follow these steps:
      </Typography>
      <Typography>
        1. Select the media asset and drag and drop it to the desired location on the canvas.
      </Typography>
      <Typography>
        2. A media dialog will open with the 'Upload Media' tab selected.
      </Typography>
      <Typography>
        3. Under 'Upload Media', click or drop your image file onto the dropzone.
      </Typography>
      <Typography>
        4. The image will automatically upload and be stored in the 'Your Images' tab, serving as your personal image library.
      </Typography>
    </>
  );
};

HowToUploadImage.propTypes = {

};

export default HowToUploadImage;