import {withStyles} from '@material-ui/core';
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import cn from "classnames";
import ToolboxIcon from "editor/components/ToolboxIcon/ToolboxIcon";
import {EditorContext} from "editor/Editor";
import AssetPanel from "editor/layout/AssetPanel/AssetPanel";
import EditorLayers from "editor/layout/EditorLayers/EditorLayers";
import EditorPages from "editor/layout/EditorPages/EditorPages";
import EditorSavedComponents from "editor/layout/EditorSavedComponents/EditorSavedComponents";
import LayoutPanel from "editor/layout/LayoutPanel/LayoutPanel";
import ThemePanel from "editor/layout/ThemePanel/ThemePanel";
import {useAssetConfig} from "hooks/useAssetConfig";
import PropTypes from 'prop-types';
import React, {useContext, useEffect, useRef, useState} from 'react';
import ToolboxStyle from './ToolboxStyle';

const Toolbox = props => {
  const {classes} = props;
  const assetConfig = useAssetConfig();

  const pageLayerContainerRef = useRef(null);
  const {editorReducerState, dispatch} = useContext(EditorContext);
  const showAssetPanel = editorReducerState.editorState.showAssetPanel;
  const currentTab = editorReducerState.editorState.currentTab;

  if(!currentTab) return null;

  return (
    <div className={classes.root} id={'toolbox'}>
      <Paper className={classes.toolboxRoot}>
        <EditorPages
          ref={pageLayerContainerRef}
          show={currentTab === 'pages'}
        />

        <div
          className={classes.layersContainer}
          ref={pageLayerContainerRef}
          style={{display: currentTab === 'layers' ? 'flex' : 'none'}}
        >
          <Typography variant={'h5'} className={classes.title}>
            Layers
          </Typography>

          <div className={classes.layersInnerContainer}>
            <EditorLayers/>
          </div>
        </div>

        <div
          className={classes.draggableComponentsOuterContainer}
          style={{display: currentTab === 'assets' ? 'flex' : 'none'}}
        >
          <Typography variant={'h5'} className={classes.title}>
            Assets
          </Typography>

          <div
            className={classes.draggableComponentsInnerContainer}
            id={'assets'}
          >
            {Object.keys(assetConfig).map((data, index) => {
              let asset = assetConfig[data];

              return (
                <div id={asset?.id} key={asset.toolboxSettings.textNode}>
                  <ToolboxIcon
                    asset={asset}
                  />
                </div>
              )
            })}
          </div>
        </div>

        <LayoutPanel
          show={currentTab === 'layouts'}
        />

        <EditorSavedComponents
          show={currentTab === 'your components'}
        />

        <EditorSavedComponents
          publicComponents={true}
          show={currentTab === 'public components'}
        />

        <ThemePanel
          show={currentTab === 'theme'}
        />
      </Paper>
    </div>
  );
};

Toolbox.propTypes = {
  classes: PropTypes.object
};

export default withStyles(ToolboxStyle)(Toolbox);
