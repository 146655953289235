const ElementSettingsGroupLabelStyle = theme => ({
  label: {
    width: '100%',
    marginTop: '0px',
    marginBottom: '-4px',
    fontSize: '15px'
  },
});

export default ElementSettingsGroupLabelStyle;
