import {IconButton, withStyles} from '@material-ui/core';
import FormatAlignCenterIcon from '@material-ui/icons/FormatAlignCenter';
import FormatAlignJustifyIcon from '@material-ui/icons/FormatAlignJustify';
import FormatAlignLeftIcon from '@material-ui/icons/FormatAlignLeft';
import FormatAlignRightIcon from '@material-ui/icons/FormatAlignRight';
import cn from "classnames";
import ElementSettingsGroupLabel from "components/ElementSettingGroupLabel/ElementSettingsGroupLabel";
import PropTypes from 'prop-types';
import React, {useEffect, useRef, useState} from "react";
import TextAlignInputStyle from './TextAlignInputStyle';

const TextAlignInput = props => {
  const {
    classes,
    onChange,
    value,
  } = props;

  const [textAlignment, setTextAlignment] = useState('center')
  const mounted = useRef(false);

  const generateTextAlignCSS = (newTextAlignment=textAlignment) => {
    let generatedCSS = `${newTextAlignment}`;
    onChange(generatedCSS);
  }

  useEffect(() => {
    return () => {
      mounted.current = false;
    }
  }, [])

  useEffect(() => {
    console.log('text align value', value)
    setTextAlignment(value);
  }, [value])

  const buttonTypeList = [
    {
      type: 'left',
      icon: (
        <FormatAlignLeftIcon
          className={classes.icon}
        />
      ),
    },
    {
      type: 'center',
      icon: (
        <FormatAlignCenterIcon
          className={classes.icon}
        />
      ),
    },
    {
      type: 'right',
      icon: (
        <FormatAlignRightIcon
          className={classes.icon}
        />
      ),
    },
    {
      type: 'justify',
      icon: (
        <FormatAlignJustifyIcon
          className={classes.icon}
        />
      ),
    }
  ]

  const handleClick = (alignment) => {
    setTextAlignment(alignment)
    generateTextAlignCSS(alignment);
  }

  return (
    <div className={classes.outerButtonContainer}>
      <ElementSettingsGroupLabel>
        Text Alignment
      </ElementSettingsGroupLabel>

      <div className={classes.buttonContainer}>
        {buttonTypeList.map((data, index) => (
          <IconButton
            onClick={() => handleClick(data.type)}
            className={cn(classes.button,
              data.type === textAlignment
                ?
                classes.activeButton
                :
                null
            )}
            key={data.type}
          >
            {data.icon}
          </IconButton>
        ))}
      </div>
    </div>
  );
};

TextAlignInput.propTypes = {
  classes: PropTypes.object,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired
};

export default withStyles(TextAlignInputStyle)(TextAlignInput);
