const FormDraggableDialogStyle = theme => ({
  dialog: {
    height: "515px",
    width: "450px",
    display: "flex",
  },

  dialogTitle: {
    width: "100%",

    '& .MuiTypography-root': {
      display: "flex",
      flexDirection: "row",
      width: "100%",
      justifyContent: "space-between",
      alignItems: 'center'
    },
  },

  closeIcon: {
    color: "white",

    '&:hover':{
      background: "#3a3a4a",
      transition: "0.3s",
    },
  },

  button: {
    width: "150px",
  },

  helpIcon: {
    marginRight: '8px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',

    '& > *': {
      fontSize: '30px',
      width: '30px',
      height: '30px',
      transition: '0.3s',
      cursor: 'pointer',
      color: '#D2D2D2',

      '&:hover': {
        color: theme.palette.secondary.main
      }
    }
  },

  dialogTitleButtons: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
  },

});

export default FormDraggableDialogStyle;
