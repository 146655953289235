import {withStyles} from '@material-ui/core';
import {UserContext} from "context/UserContext";
import PropTypes from 'prop-types';
import React, {useContext} from 'react';
import {useHistory} from "react-router-dom";
import Cookies from "universal-cookie";
import MobileUserMenuStyle from 'components/MobileUserMenu/MobileUserMenuStyle';

const MobileUserMenu = props => {
  const { classes } = props;

  const {userData, setUserData} = useContext(UserContext);
  const history = useHistory()

  const handleSignOut = () => {
    setUserData(null)
    history.replace('/')
    const cookies = new Cookies()
    cookies.remove('token', {path: "/"})
  }

  const handleMyAccount = () => {
    history.push("/account")
  }

  const userMenuActions = [
    {
      title: "My Account",
      action: handleMyAccount,
    },
    {
      title: "Sign Out",
      action: handleSignOut,
    },
  ]

  const links = userMenuActions.map((data, index) => (
    <div
      key={index}
      className={classes.internalLinks}
      onClick={data.action}
      {...data}
    >
      {data.title}
    </div>
  ))

  return (
    <div className={classes.internalLinksContainer}>
      {links}
    </div>
  );
};

MobileUserMenu.propTypes = {
  classes: PropTypes.object
};

export default withStyles(MobileUserMenuStyle)(MobileUserMenu);