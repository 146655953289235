const ThemeDialogStyle = theme => ({
  dialogTitle: {
    width: "100%",
    paddingBottom: '0',

    '& .MuiTypography-root': {
      display: "flex",
      flexDirection: "row",
      width: "100%",
      justifyContent: "space-between",
    },
  },

  closeIcon: {
    color: "white",

    '&:hover':{
      background: "#3a3a4a",
      transition: "0.3s",
    },
  },

  colorPickerSection: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    width: '100%'
  },

  arrowContainer: {
    position: 'relative',
    top: '5px',
    left: '2px',
    marginRight: 'auto',
    cursor: 'pointer'
  },

  colorPickerSectionHeader: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center'
  },

  colorPickerSectionHeaderText: {
    fontSize: '20px',
    fontWeight: 'bold',
    fontFamily: 'Roboto',
    margin: '6px 0',
    marginTop: '12px',
    cursor: 'pointer'
  },

  colorPickerSectionInnerHeader: {
    fontSize: '15px',
    fontWeight: '400',
    fontFamily: 'Roboto'
  },

  form: {
    width: '100%'
  },

  formInner: {
    width: '100%'
  },

  colorPickerInput: {
    maxWidth: '350px'
  },

  dialogContent: {
    minWidth: '400px',
    maxHeight: '600px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',

    '&::-webkit-scrollbar': {
      height: "2px",
      width: '2px',
      position: 'relative',
      marginLeft: '9.33px',
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: 'white',
    },
    '&::-webkit-scrollbar-track': {
      backgroundColor: '#262626',
      opacity: '0.48'
    },
  }
});

export default ThemeDialogStyle;
