import {purpleText} from "assets/theme";

const LoginFormStyle = theme => ({
  content: {
    width: "100%",
    color: "white",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: 'center',
    textAlign: "center",
    paddingLeft: "20px",
    paddingRight: "20px",
  },

  title: {
    width: "100%",
    marginBottom: "20px",

    '& .MuiTypography-h3': {
      [theme.breakpoints.down('530')]: {
        fontSize: "40px",
      },
      [theme.breakpoints.down('450')]: {
        fontSize: "32px",
      },
      [theme.breakpoints.down('375')]: {
        fontSize: "25px",
      },
    },
  },

  errorText: {
    color: theme.palette.error.main,
    marginBottom: '8px',
    fontSize: '19px',
    fontWeight: '500'
  },

  subtitle: {
    width: "100%",
    marginBottom: "20px",

    '& .MuiTypography-h6': {
      [theme.breakpoints.down('375')]: {
        fontSize: "18px",
      },
    },
  },

  purpleText: {
    ...purpleText,
    textDecoration: "none",
  },

  formContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: 'center',
    maxWidth: "450px",
    minWidth: "200px",
  },

  formInput: {
    backgroundColor: "transparent",
    color: 'white',
    borderColor: "white",

    '&.MuiTextField-root': {
      '& fieldset': {
        borderColor: 'white',
      },
      '&:hover fieldset': {
        borderColor: 'white',
      },
      '&.Mui-focused fieldset': {
        borderColor: 'white',
      },
      '& .MuiInputBase-input': {
        color: "white",
      },
    },
  },

  googleAccountButton: {
    '&.MuiButtonBase-root': {
      textTransform: "none",
      color:"white",
      backgroundColor: "transparent",
      borderColor: "white",
      width:"440px",
      height: "52px",
      fontSize: "17px",
      transition: "0.3s",
      marginBottom: "20px",

      [theme.breakpoints.down('530')]: {
        width:"375px",
      },
      [theme.breakpoints.down('450')]: {
        width:"275px",
      },
      [theme.breakpoints.down('375')]: {
        width:"250px",
      },
      '&:hover': {
        backgroundColor: "#1D1D1D",
      },
    },
  },

  googleIcon: {
    width: "20px",
    height: "24px",
    marginRight: "10px"
  },

  formInputContainer: {
    width: "100%",
    marginTop: "20px",
  },

  input: {
    color: "white",
    fontSize: "18px",
    padding: "11px",
  },

  loginAccountButton: {
    '&.MuiButtonBase-root': {
      textTransform: "none",
      color:"black",
      backgroundColor: "white",
      borderColor: "white",
      width:"440px",
      height: "52px",
      fontSize: "17px",
      transition: "0.3s",
      marginBottom: "20px",
      marginTop: "20px",

      [theme.breakpoints.down('530')]: {
        width:"375px",
      },
      [theme.breakpoints.down('450')]: {
        width:"275px",
      },
      [theme.breakpoints.down('375')]: {
        width:"250px",
      },

      '&:hover': {
        backgroundColor: "#E1E1E1",
      },
    },
  },

  registerContainer: {
    textDecoration: "none",
    marginBottom: "20px",
    cursor: "pointer",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    width: "100%",
    maxWidth: "450px",
    minWidth: "200px",
    minHeight: "50px",

    [theme.breakpoints.down('450')]: {
      flexDirection: "column",
    },

  },

  forgotPassword: {
    [theme.breakpoints.down('450')]: {
      marginBottom: "40px",
    },
  }
});

export default LoginFormStyle;
