const TabBarStyle = theme => ({
  root: {
    width: '55px',
    background: 'linear-gradient(to bottom, rgba(35, 42, 49, 0.65), rgba(39, 36, 51, 0.65))',
    display: 'flex',
    padding: '8px',
    height: 'calc(100vh - 55px)',
    justifyContent: 'flex-start',
    flexDirection: 'column',
    alignItems: 'center',
  },

  icon: {
    fontSize: '30px',
    width: '30px',
    height: '30px',
    margin: '8px 0',

    '& > *': {
      fontSize: '30px',
      width: '30px',
      height: '30px',
      transition: '0.3s',
      cursor: 'pointer',

      color: '#D2D2D2',

      '&:hover': {
        color: theme.palette.secondary.main
      }
    }
  },

  selected: {
    '& > *': {
      color: theme.palette.secondary.main + ' !important'
    }
  },
});

export default TabBarStyle;
