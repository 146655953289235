import Typography from "@material-ui/core/Typography";
import React from 'react';

const DifferentIconButtonOptions = () => {
  return (
    <>
      <Typography style={{marginBottom: '8px'}}>
        Nimbus utilizes Material-UI Icons to provide you with an expansive library of icons. To find the icon you want for your icon button, follow these steps:
      </Typography>
      <Typography>
        1. Select the icon button asset and drag and drop it in the desired location on the canvas.
      </Typography>
      <Typography>
        2. An icon dialog will appear, featuring a comprehensive library of icons.
      </Typography>
      <Typography>
        3. Use the search bar to find a specific icon or scroll through the available options.
      </Typography>
      <Typography>
        4. Icons can be filtered by style for different appearances, including filled, outlined, rounded, two-tone, and sharp.
      </Typography>
    </>
  );
};

DifferentIconButtonOptions.propTypes = {

};

export default DifferentIconButtonOptions;