import {TableCell, TableContainer, withStyles} from '@material-ui/core';
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import DraggableWrapper from "editor/draggables/DraggableWrapper/DraggableWrapper";
import formatStyles from "editor/draggables/formatStyles";
import PropTypes from 'prop-types';
import React, {useEffect, useMemo, useState} from 'react';
import ContentEditable from "react-contenteditable";
import TableDraggableStyle from './TableDraggableStyle';

const TableDraggable = props => {
  const {
    classes,
    style,
    draggableProps,
    isChildElem,
    rows = 4,
    columns = 3,
    editorId,
    absolutePositioning
  } = props;

  const [tableStyle, setTableStyle] = useState({});
  const [tableCellStyle, setTableCellStyle] = useState({});

  // TODO: get self by editor id and then change the children on load?

  const {draggableStyles, elementStyles} =
    useMemo(() => formatStyles(style), [style]);

  useEffect(() => {
    console.log('rows', typeof rows)
    console.log('new Array(rows)', new Array(rows))
  }, [rows])

  useEffect(() => {
    console.log('props', props)
  }, [props])

  useEffect(() => {
    console.log('style', style)
    const tableKeys = [
      'width',
      'height',
      'top',
      'left',
      'right',
      'background',
      'border',
      'borderRadius',
      'padding',
      'margin',
    ]

    const tableCellKeys = [
      'color',
    ]

    let newTableStyle = {}
    let newTableCellStyle = {}

    for (let key of Object.keys(elementStyles)) {
      console.log('key', key)
      console.log('tableKeys.includes(key)', tableKeys.includes(key))
      if (tableKeys.includes(key)) {
        console.log('style[key]', elementStyles[key])
        newTableStyle[key] = elementStyles[key]
      } else {
        newTableCellStyle[key] = elementStyles[key]
      }
    }
    console.log('newTableStyle', newTableStyle)
    console.log('newTableCellStyle', newTableCellStyle)
    setTableStyle(newTableStyle)
    setTableCellStyle(newTableCellStyle)
  }, [style])

  return (
    <DraggableWrapper
      resizeable
      editorId={props.editorId}
      style={{
        ...draggableStyles,
        ...draggableProps?.style,
        position: absolutePositioning ? 'absolute' : 'static'
      }}
      isChildElem={isChildElem}
    >
      <TableContainer
        component={Paper}
        style={{...tableStyle}}
        data-editorid={editorId}
        data-asset={true}
      >
        <Table className={classes.tableStyle}>
          <TableHead>
            <TableRow>
              {new Array(parseInt(columns)).fill("").map((columnData, columnIndex) => (
                <TableCell
                  key={columnIndex}
                  className={classes.headerStyle}
                >
                  <ContentEditable
                    html={'header'}
                    tagName={'div'}
                  />
                </TableCell>
              ))}
            </TableRow>
          </TableHead>

          <TableBody>
            {new Array(parseInt(rows)).fill("").map((rowData, rowIndex) => (
              <TableRow key={rowIndex}>
                {new Array(parseInt(columns)).fill("").map((columnData, columnIndex) => (
                  <TableCell
                    key={columnIndex}
                    style={{...tableCellStyle}}
                    className={classes.cellStyle}
                  >
                    <ContentEditable
                      html={'cell'}
                      tagName={'div'}
                    />
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </DraggableWrapper>
  );
};

TableDraggable.serializedName = 'TableDraggable';

TableDraggable.editorSettings = [
  {
    type: 'prop',
    key: 'breakpoints',
    tooltip: 'Make an asset visible at the chosen breakpoints: desktop, tablet, and/or mobile.',
    default: {
      visibility: {
        desktop: true,
        tablet: true,
        mobile: true
      },
      styleLink: {
        tablet: true,
        mobile: true,
      }
    },
    label: null,
    inputType: 'breakpoint'
  },

  {
    type: 'layout',
    key: 'twoCol',
    tooltip: 'Accepted units: px, %, em, rem, vw, vh',
    label: 'Position/Size',
    elements: [
      {type: 'style', key: 'left', default: '0', label: 'X'},
      {type: 'style', key: 'top', default: '0', label: 'Y'},
      {type: 'style', key: 'width', default: '50px', label: 'W'},
      {type: 'style', key: 'height', default: '50px', label: 'H'},
    ]
  },

  {
    type: 'prop',
    key: 'absolutePositioning',
    tooltip: 'If this is checked, your asset\'s CSS will be written with "position: absolute"',
    label: 'Absolute Positioning',
    default: false,
    inputType: 'boolean'
  },

  {
    type: 'layout',
    key: 'twoCol',
    elements: [
      {type: 'prop', key: 'rows', horizontal: false, default: 3, label: 'Rows'},
      {type: 'prop', key: 'columns', horizontal: false, default: 3, label: 'Columns'},
    ]
  },

  //{type: 'prop', key: 'classList', default: null, label: null, inputType: 'class'},
  //general table settings
  {
    type: 'collection',
    label: 'General Table Settings',
    children: [
      {
        type: 'prop',
        key: 'tableBackground',
        default: '#FFFFFF',
        label: 'Background Color',
        inputType: 'color',
        tooltip: 'Accepted values are hexcodes, rgba values (e.g. "rgba(255,255,255,1)") and the value "transparent"',
        inputOptions: {
          defaultColor: '#FFFFFF'
        }
      },
      {
        type: 'collection',
        label: 'Padding',
        children: [
          {type: 'style', key: 'paddingTop', default: '0px', label: 'Top', horizontal: false},
          {type: 'style', key: 'paddingBottom', default: '0px', label: 'Bottom', horizontal: false},
          {type: 'style', key: 'paddingLeft', default: '0px', label: 'Left', horizontal: false},
          {type: 'style', key: 'paddingRight', default: '0px', label: 'Right', horizontal: false},
        ]
      },
      {
        type: 'collection',
        label: 'Margin',
        children: [
          {type: 'style', key: 'marginTop', default: '0px', label: 'Top', horizontal: false},
          {type: 'style', key: 'marginBottom', default: '0px', label: 'Bottom', horizontal: false},
          {type: 'style', key: 'marginLeft', default: '0px', label: 'Left', horizontal: false},
          {type: 'style', key: 'marginRight', default: '0px', label: 'Right', horizontal: false},
        ]
      },
      {
        type: 'collection',
        label: 'Border',
        children: [
          {type: 'style', key: 'border', default: 'none', label: null, inputType: 'border'},
          {type: 'style', key: 'borderRadius', default: '0px', label: 'Border Radius'},
        ]
      },
    ]
  },
  //header settings
  {
    type: 'collection',
    label: 'Table Header',
    children: [
      {type: 'prop', key: 'headerColor', default: '#FFFFFF', label: 'Text Color', inputType: 'color'},
      {
        type: 'prop',
        key: 'headerBackground',
        default: '#222229',
        label: 'Background Color',
        inputType: 'color',
        inputOptions: {
          defaultColor: '#222229'
        }
      },

      //TODO: LAYOUTS ARE CURRENTLY NOT SUPPORTED IN COLLECTIONS - ADD THIS TO COLLECTIONS
      // {
      //   type: 'layout',
      //   key: 'font',
      //   elements: [
      //     {type: 'prop', key: 'headerFontFamily', default: 'Roboto',inputType: 'select', options: APPLICATION_SETTINGS.CONSTANTS.SUPPORTED_FONTS},
      //     {type: 'prop', key: 'headerFontWeight', default: '400', inputType: 'select', options: APPLICATION_SETTINGS.CONSTANTS.SUPPORTED_FONT_WEIGHTS},
      //     {type: 'prop', key: 'headerFontSize', default: '16px', inputType: 'select', options: APPLICATION_SETTINGS.CONSTANTS.SUPPORTED_FONT_SIZES}
      //   ]
      // },
      {
        type: 'collection',
        label: 'Border',
        children: [
          {type: 'prop', key: 'headerBorder', default: 'none', label: 'Cell Border', inputType: 'border'},
          {
            type: 'collection',
            label: 'Detailed Border',
            children: [
              {type: 'prop', key: 'headerBorderLeft', default: null, label: 'Left', inputType: 'border'},
              {type: 'prop', key: 'headerBorderRight', default: null, label: 'Right', inputType: 'border'},
              {type: 'prop', key: 'headerBorderTop', default: null, label: 'Top', inputType: 'border'},
              {type: 'prop', key: 'headerBorderBottom', default: null, label: 'Bottom', inputType: 'border'}
            ]
          }
        ]
      },
    ]
  },
  //body settings
  {
    type: 'collection',
    label: 'Table Body',
    children: [
      {type: 'style', key: 'color', default: '#FFFFFF', label: 'Text Color', inputType: 'color'},
      {
        type: 'collection',
        label: 'Border',
        children: [
          {type: 'prop', key: 'cellBorder', default: 'none', label: 'Cell Border', inputType: 'border'},
        ]
      },
      //TODO: LAYOUTS ARE CURRENTLY NOT SUPPORTED IN COLLECTIONS - ADD THIS TO COLLECTIONS
      // {
      //   type: 'layout',
      //   key: 'font',
      //   elements: [
      //     {type: 'style', key: 'fontFamily', default: 'Roboto',inputType: 'select', options: APPLICATION_SETTINGS.CONSTANTS.SUPPORTED_FONTS},
      //     {type: 'style', key: 'fontWeight', default: '400', inputType: 'select', options: APPLICATION_SETTINGS.CONSTANTS.SUPPORTED_FONT_WEIGHTS},
      //     {type: 'style', key: 'fontSize', default: '16px', inputType: 'select', options: APPLICATION_SETTINGS.CONSTANTS.SUPPORTED_FONT_SIZES}
      //   ]
      // },
    ]
  },
];


TableDraggable.propTypes = {
  classes: PropTypes.object,
  style: PropTypes.object.isRequired,
  draggableProps: PropTypes.object,
  editorId: PropTypes.string.isRequired,
  isChildElem: PropTypes.object,
  rows: PropTypes.number,
  columns: PropTypes.number
};

export default withStyles(TableDraggableStyle)(TableDraggable);
