import ElementSettingsInput from "editor/layout/ElementSettings/Inputs/ElementSettingsInput";
import React, {useEffect, useMemo} from 'react';

import {withStyles} from '@material-ui/core';
import PropTypes from 'prop-types';

import FontLayoutStyle from 'editor/layout/ElementSettings/InputLayouts/FontLayout/FontLayoutStyle';
import ElementSettingsGroupLabel from "components/ElementSettingGroupLabel/ElementSettingsGroupLabel";

const FontLayout = props => {
  const {
    classes,
    setting,
    key,
    name,
    initialValues,
    submitForm,
    setInitialValues,
  } = props;

  const fontInput = useMemo(() => (
    <ElementSettingsInput
      setting={setting.elements.find(elem => elem.key === 'fontFamily')}
      key={'fontFamily'}
      initialValues={initialValues}
      setInitialValues={setInitialValues}
      submitForm={submitForm}
    />
  ), [setting.elements, initialValues]);

  const fontWeightInput = useMemo(() => (
    <ElementSettingsInput
      setting={setting.elements.find(elem => elem.key === 'fontWeight')}
      key={'fontWeight'}
      initialValues={initialValues}
      setInitialValues={setInitialValues}
      submitForm={submitForm}
    />
  ), [setting.elements, initialValues]);

  const fontSizeInput = useMemo(() => (
    <ElementSettingsInput
      setting={setting.elements.find(elem => elem.key === 'fontSize')}
      key={'fontSize'}
      initialValues={initialValues}
      setInitialValues={setInitialValues}
      submitForm={submitForm}
    />
  ), [setting.elements, initialValues]);

  useEffect(() => {
    console.log('setting elements', setting.elements)

    if(!(
      setting.elements.find(elem => elem.key === 'fontFamily') &&
      setting.elements.find(elem => elem.key === 'fontWeight') &&
      setting.elements.find(elem => elem.key === 'fontSize')
    )) {
      console.error('The font layout requires all 3 fields: "fontSize", "fontWeight", "fontFamily"');
    }
  }, [setting.elements])

  return (
    <div className={classes.fontLayoutRoot}>
      <ElementSettingsGroupLabel setting={setting}>
        Styling
      </ElementSettingsGroupLabel>

      <div className={classes.fontLayoutInner}>
        <div className={classes.fontSizeContainer}>
          {fontInput}
        </div>

        <div className={classes.row}>
          {fontWeightInput}

          {fontSizeInput}
        </div>
      </div>
    </div>
  );
};

FontLayout.propTypes = {
  classes: PropTypes.object,
  setting: PropTypes.object.isRequired,
  formikBag: PropTypes.object.isRequired,
};

export default withStyles(FontLayoutStyle)(FontLayout);
