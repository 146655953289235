import DesktopWindowsIcon from "@material-ui/icons/DesktopWindows";
import SmartphoneIcon from "@material-ui/icons/Smartphone";
import TabletIcon from "@material-ui/icons/Tablet";
import cn from "classnames";
import ElementSettingsGroupLabel from "components/ElementSettingGroupLabel/ElementSettingsGroupLabel";
import {EditorContext} from "editor/Editor";
import React, {useContext, useState} from 'react';

import {
  IconButton,
  withStyles
} from '@material-ui/core';
import PropTypes from 'prop-types';

import CanvasSizeBannerStyle from './CanvasSizeBannerStyle';

const CanvasSizeBanner = props => {
  const { classes } = props;
  const editorData = useContext(EditorContext);
  const {editorReducerState, dispatch} = editorData;

  const [selectedSize, setSelectedSize] = useState(
    editorReducerState.editorState.canvasSettings.currentCanvasBreakpoint
  );

  const submitForm = (newCanvasSize) => {
    console.log('new canvas size', newCanvasSize)
    setSelectedSize(newCanvasSize)

    dispatch({
      type: 'changeCanvasData',
      payload: {
        breakpointToUse: newCanvasSize,
      }
    })
  };

  const handleCanvasSizeChange = (newCanvasSize) => {
    setSelectedSize(newCanvasSize);
    submitForm(newCanvasSize);
  };

  return (
    <div className={classes.root}>
      <div className={classes.inputContainer} id={'canvas-size'}>
        {/*<ElementSettingsGroupLabel>*/}
        {/*  Canvas Size*/}
        {/*</ElementSettingsGroupLabel>*/}

        <div className={classes.canvasSizeContainer}>
          <IconButton
            onClick={() => handleCanvasSizeChange('desktop')}
            className={classes.iconButton}

          >
            <DesktopWindowsIcon className={cn(classes.icon, selectedSize === 'desktop' && classes.active)}/>
          </IconButton>
          <IconButton
            onClick={() => handleCanvasSizeChange('tablet')}
            className={classes.iconButton}

          >
            <TabletIcon className={cn(classes.icon, selectedSize === 'tablet' && classes.active)}/>
          </IconButton>
          <IconButton
            onClick={() => handleCanvasSizeChange('mobile')}
            className={classes.iconButton}
          >
            <SmartphoneIcon className={cn(classes.icon, selectedSize === 'mobile' && classes.active)}/>
          </IconButton>
        </div>
      </div>
    </div>
  );
};

CanvasSizeBanner.propTypes = {
  classes: PropTypes.object
};

export default withStyles(CanvasSizeBannerStyle)(CanvasSizeBanner);
