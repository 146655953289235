const ActionBarStyle = theme => ({
  root: {
    position: "absolute",
    //top: "-45px",
    width: "100%",
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'flex-start',
    minWidth: '70px',
    pointerEvents: 'none',
    zIndex: 4000
  },

  iconButton: {
    backgroundColor: '#353544',
    color: 'white',
    opacity: 1,
    marginLeft: '4px',
    pointerEvents: 'auto',

    '&:hover': {
      backgroundColor: '#59596C'
    }
  },

  deleteIconButton: {
    color: 'white',
    backgroundColor: theme.palette.error.main,
    opacity: 1,
    marginLeft: '4px',
    pointerEvents: 'auto',

    '&:hover': {
      backgroundColor: theme.palette.error.dark
    }
  }
});

export default ActionBarStyle;
