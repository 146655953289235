const formatStyles = (style) => {
  if(!style) return {draggableStyles: {}, elementStyles: {}};

  console.log('format styles was called', style)

  // if width or height is a percentage, we want to add it to draggable styles,
  // else element styles

  const {
    top,
    left,
    right,
    bottom,

    margin,
    marginLeft,
    marginRight,
    marginTop,
    marginBottom,

    display,

    position,

    ...elementStyles
  } = style;

  const draggableStyles = {
    top,
    left,
    right,
    bottom,

    margin,
    marginLeft,
    marginRight,
    marginTop,
    marginBottom,

    display,

    position
  }

  // this can fail when inserting elements
  // since it's not vital, just wrap it in a try/catch
  // if the catch is triggered, this wouldn't be
  // true anyways
  try {
    if(style?.width?.includes('%')) {
      draggableStyles.width = elementStyles.width;
      delete elementStyles.width;
    }
  }
  catch (e) {}

  try {
    if(style?.height?.includes('%')) {
      draggableStyles.height = elementStyles.height;
      delete elementStyles.height;
    }
  }
  catch (e) {}

  return {draggableStyles, elementStyles}
}

export default formatStyles;
