import {Button, DialogActions, DialogContent, TextField, Typography, withStyles} from '@material-ui/core';
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import {FormDraggableDialogContext} from "editor/context/FormDraggableDialogContext";
import {Form, Formik} from "formik";
import PropTypes from 'prop-types';
import React, {useContext, useEffect, useState} from 'react';

import FormDraggableInputDetailsStyle from './FormDraggableInputDetailsStyle';

const FormDraggableInputDetails = props => {
  const {
    classes,
  } = props;

  const {
    formDraggableReducerState,
    formDraggableDispatch
  } = useContext(FormDraggableDialogContext);

  const {
    inputTypeBeingAdded,
    inputBeingEdited,
  } = formDraggableReducerState;

  const [initialValues, setInitialValues] = useState({});
  const [formError, setFormError] = useState('');

  const goBack = () => {
    let editMode = !!inputBeingEdited;

    if(!editMode) {
      formDraggableDispatch({
        type: 'changePage',
        payload: {
          page: 'addInput'
        }
      })
    }
    else {
      formDraggableDispatch({
        type: 'changePage',
        payload: {
          page: 'main'
        }
      })

      formDraggableDispatch({
        type: 'resetInputBeingEdited',
      })
    }
  }

  const formatInitialValues = () => {
    if(inputTypeBeingAdded === null && inputBeingEdited === null) return;

    console.log('format initial detail values', inputTypeBeingAdded)

    console.log('edit mode?', !!inputBeingEdited, inputBeingEdited)

    let editMode = !!inputBeingEdited;

    let result = {};
    if(editMode) {
      for (let i of inputTypeBeingAdded?.fields) {
        result[i] = inputBeingEdited[i] || ''
      }
    }
    else {
      for (let i of inputTypeBeingAdded?.fields) {
        result[i] = ''
      }
    }

    return result;
  }

  useEffect(() => {
    console.log('form draggable details initial values', formatInitialValues());
    setInitialValues(formatInitialValues())
  }, [inputTypeBeingAdded]);

  const onSubmit = async (values, {setErrors}) => {
    // create the input card!
    console.log('values', values)

    let editMode = !!inputBeingEdited;

    for(let key of Object.keys(values)) {
      if(values[key].trim().length === 0) {
        setFormError('All fields must be filled out.')
        return;
      }
    }

    if(!editMode && formDraggableReducerState.currentFields.find(elem => elem.name === values.name)) {
      // we found an element with the same name -- they must be unique
      setFormError('A form input already exists with this name, they must be unique.');
      return;
    }

    let newInputData = {
      ...inputTypeBeingAdded,
      ...values
    }

    if(!editMode) {
      // create a new field


      formDraggableDispatch({
        type: 'addInput',
        payload: {
          inputData: {
            ...newInputData
          }
        }
      })
    }
    else {
      // edit the field instead of making a new one
      formDraggableDispatch({
        type: 'editInput',
        payload: {
          inputName: inputBeingEdited.name,
          inputData: {
            ...newInputData
          }
        }
      })
    }

    formDraggableDispatch({
      type: 'changePage',
      payload: {
        page: 'main'
      }
    })
  };

  return (
    <div className={classes.root}>
      <Formik
        initialValues={initialValues}
        enableReinitialize
        onSubmit={onSubmit}
      >
        {({
            values,
            errors,
            handleChange
          }) => (
          <Form className={classes.formContent}>
            <DialogContent>
              <div className={classes.backButtonContainer}>
                <Button onClick={goBack}>
                  <ChevronLeftIcon className={classes.backIcon}/>
                  Back
                </Button>
              </div>

              <Typography variant={'subtitle1'} className={classes.formDescription}>
                Enter some details about the input.
              </Typography>

              {formError && (
                <Typography variant={'subtitle1'} className={classes.formError}>
                  {formError}
                </Typography>
              )}

                <div className={classes.content}>
                  {Object.keys(initialValues).map((fieldName, index) => (
                    <div key={fieldName} className={classes.inputContainer}>
                      <TextField
                        name={fieldName}
                        value={values[fieldName] || ''}
                        error={errors[fieldName]}
                        onChange={handleChange}
                        label={fieldName.charAt(0).toUpperCase() + fieldName.slice(1)}
                        variant={'outlined'}
                        fullWidth
                        className={classes.input}
                      />
                    </div>
                    ))}
                </div>

            </DialogContent>

            <DialogActions>
              <Button
                type={'submit'}
                variant={'contained'}
                color={'secondary'}
                className={classes.submitButton}
              >
                Submit
              </Button>
            </DialogActions>
          </Form>
        )}
      </Formik>
    </div>
  );
};

FormDraggableInputDetails.propTypes = {
  classes: PropTypes.object
};

export default withStyles(FormDraggableInputDetailsStyle)(FormDraggableInputDetails);
