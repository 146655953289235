const OurImagesStyle = theme => ({
  root: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: 'flex-start',
    width: "100%",
    height: "100%",
  },

  providedBy: {
    fontWeight: '500',
    color: 'black',
    textDecoration: 'underline'
  },

  notSubscribed: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: '50px'
  },

  notSubscribedText: {
    fontSize: '19px',
    fontWeight: '500',
    fontFamily: 'Roboto'
  },

  notSubscribedIcon: {
    fontSize: '70px',
    color: theme.palette.common.gray
  },

  searchBar: {
    marginBottom: "10px",
    marginTop: "10px",


    '& .MuiInputBase-input': {
      height: "5px",
      color: "black",
    },
  },

  searchIcon: {
    marginRight: "3px",
    color: "black",
  },

  img: {
    '&:hover': {
      transition: "0.5s",
      filter: "brightness(60%)",
      cursor: "pointer",
    },
  },

  imgContainer: {
    position: 'relative',
    width: '100%',
    height: '100%',

    '&:hover': {
      '& $img': {
        transition: "0.5s",
        filter: "brightness(70%)",
        cursor: "pointer",
      },

      '& $attribution': {
        opacity: 1
      }
    }
  },

  attribution: {
    opacity: 0,
    position: 'absolute',
    bottom: 0,
    left: 0,
    transition: '0.3s',
    color: 'white',
    margin: '4px',
  },

  attributionLink: {
    color: 'white',
    fontWeight: '500',
    cursor: 'pointer'
  },

  attributionText: {
    fontSize: '10px'
  },

  notchedOutline: {
    borderColor: "black !important",
  },

  paginationContainer: {
    marginTop: "12px",
    marginBottom: "6px",
    display: "flex",
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    flexWrap: "wrap",
    width: '100%',

    '& .MuiPaginationItem-root': {
      color: 'rgba(0, 0, 0, 0.7)'
    }
  },

  loadingScreen: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
    flex: 1,
  },
});

export default OurImagesStyle;
