import React, {useContext, useEffect, useRef, useState} from 'react';
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import {UserContext} from "context/UserContext";
import {EditorContext} from "editor/Editor";
import {useProjectSerializer} from "hooks/serializerHooks/useProjectSerializer";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import {
  Dialog, DialogActions, DialogContent, DialogTitle,
  withStyles
} from '@material-ui/core';
import PropTypes from 'prop-types';
import {toast} from "react-toastify";
import {User} from "services";
import {handleNetworkError} from "utils/utils";
import PreviewDialogStyle from './PreviewDialogStyle';

const PreviewDialog = props => {
  const {
    classes,
    open,
    setOpen,
    data,
  } = props;

  const [busy, setBusy] = useState(false)
  const {userData} = useContext(UserContext);
  const editorData = useContext(EditorContext);
  const {editorReducerState, dispatch} = editorData;
  const {editorActions} = editorReducerState;
  const projectSerializer = useProjectSerializer(editorReducerState, dispatch);
  const [projectsInProgress, setProjectsInProgress] = useState([]);
  const [firstRequestFinished, setFirstRequestFinished] = useState(false);


  const timeout = useRef(null);
  const initiallyRan = useRef(false);

  // We use this to show a loading screen before the first network request finishes

  useEffect(() => {
    if(open === true && initiallyRan.current === false) {
      pollApi();
      initiallyRan.current = true;
    }

    return () => {
      clearTimeout(timeout.current);
      initiallyRan.current = false;
      setProjectsInProgress([]);
    }
  }, [open])

  const pollApi = () => {
    User.checkCurrentExports()
      .then(response => {
        console.log('successfully checked user Previews!', response)
        setProjectsInProgress(response.data.data)

        setFirstRequestFinished(true);
        timeout.current = setTimeout(pollApi, 10000)
      })
      .catch(error => {
        handleNetworkError(error);
        clearTimeout(timeout.current);
      })
  }

  const handleClose = () => {
    setOpen(false)
  }

  const previewProject = async () => {
    let currentCanvasSettings = editorReducerState.editorState.canvasSettings;

    dispatch({
      type: 'changeCanvasData',
      payload: {
        breakpointToUse: 'desktop',
        backgroundColor: currentCanvasSettings.currentCanvasBackgroundColor,
        canvasHeight: currentCanvasSettings.canvasHeight,
        currentAlignment: currentCanvasSettings.currentAlignment,
        style: currentCanvasSettings.style,
      }
    })

    let projectData = {
      ...await projectSerializer.serializeProject()
    }

    setBusy(true)

    let projectId =
      editorReducerState.editorSettings.projectId
      ||
      window.localStorage.getItem('projectId');

    let versionId =
      editorReducerState.editorSettings.versionId
      ||
      window.localStorage.getItem('versionId');

    User.exportProject(projectData, projectId, versionId, true)
      .then(response => {
        console.log('export project response', response);
        toast.success('Successfully exported project!');
      })
      .catch(handleNetworkError)
      .finally(() => {
        setBusy(false)
      })
  }

  const handlePreview = async () => {
    handleClose();

    previewProject();
  }

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      maxWidth={'md'}
    >
      <DialogTitle>
        Preview Confirmation
      </DialogTitle>

      <DialogContent className={classes.dialogContent}>
        <Typography
          variant={'body1'}
          className={classes.text}
        >
          Preview your website live on the internet!
        </Typography>
        <Typography
          variant={'body1'}
          className={classes.text}
        >
          This will take a few minutes, but you can check the progress of your preview below. We'll provide a link here once it's ready. You can leave this dialog open or return in a few minutes.
        </Typography>

        <TableContainer className={classes.table}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Preview</TableCell>
                <TableCell>Status</TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {projectsInProgress.map((data, index) => (
                <TableRow key={data.projectUuid}>
                  <TableCell>{data.projectName}</TableCell>
                  <TableCell>
                    {data.completed ?
                      <span className={classes.complete}>
                        {data.url ?
                          (
                            <a
                              href={data.previewUrl}
                              target={'_blank'}
                              className={classes.complete}
                            >
                              Completed
                            </a>
                          )
                          :
                          (
                            'Completed'
                          )
                        }
                      </span>
                      :
                      <span className={classes.inProgress}>
                        In Progress
                      </span>
                    }
                  </TableCell>
                </TableRow>
              ))}

              {projectsInProgress.length === 0 && firstRequestFinished && (
                <TableRow>
                  <TableCell colSpan={2} className={classes.boldTableCell}>
                    No recent previews
                  </TableCell>
                </TableRow>
              )}

              {!firstRequestFinished && (
                <TableRow>
                  <TableCell colSpan={2} className={classes.boldTableCell}>
                    Loading...
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>

        <Typography
          variant={'body1'}
          className={classes.text}
        >
          Would you like to start a preview?
        </Typography>
      </DialogContent>

      <DialogActions className={classes.dialogActions}>
        <Button
          onClick={handlePreview}
          variant={'contained'}
          disabled={busy}
          className={classes.purpleButton}
        >
          Yes
        </Button>
        <Button
          onClick={handleClose}
          variant={'contained'}
          disabled={busy}
          className={classes.outlinedButton}
        >
          No
        </Button>
      </DialogActions>
    </Dialog>
  );
};

PreviewDialog.propTypes = {
  classes: PropTypes.object,
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
  data: PropTypes.object,
};

export default withStyles(PreviewDialogStyle)(PreviewDialog);
