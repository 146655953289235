import {UserContext} from "context/UserContext";
import StripeFormDialog from "dialogs/StripeFormDialog/StripeFormDialog";
import CanvasSizeBanner from "editor/components/CanvasSizeBanner/CanvasSizeBanner";
import SubscriptionBanner from "editor/components/SubscriptionBanner/SubscriptionBanner";
import {EditorContext} from "editor/Editor";
import {useEditorDialog} from "hooks/useEditorDialog";
import React, {useContext, useState} from 'react';

import {
  Button,
  MuiThemeProvider, Typography,
  withStyles
} from '@material-ui/core';
import PropTypes from 'prop-types';
import {toast} from "react-toastify";

import EditorCanvasStyle from './EditorCanvasStyle';

const EditorCanvas = props => {
  const { classes, tree, userDefinedTheme } = props;

  const editorData = useContext(EditorContext);
  const {userData, setUserData} = useContext(UserContext);
  const {editorReducerState, dispatch} = editorData;

  return (
    <div className={classes.canvasOuter} id={'canvas-outer'}>
      {userData && !userData?.subscriptionStatus && (
        <SubscriptionBanner/>
      )}

      <CanvasSizeBanner/>

      <MuiThemeProvider theme={userDefinedTheme}>
        <div className={classes.canvasInner} id={'canvas-inner'}>
          <div
            className={classes.zoomContainer}
            id={'zoom-container'}
            style={{transform: `scale(${editorReducerState.editorSettings.zoomLevel})`}}
          >
            {tree}
          </div>
        </div>
      </MuiThemeProvider>
    </div>
  );
};

EditorCanvas.propTypes = {
  classes: PropTypes.object
};

export default withStyles(EditorCanvasStyle)(EditorCanvas);
