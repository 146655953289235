import {Radio, withStyles} from '@material-ui/core';
import RadioButtonCheckedIcon from "@material-ui/icons/RadioButtonChecked";
import DraggableWrapper from "editor/draggables/DraggableWrapper/DraggableWrapper";
import formatStyles from "editor/draggables/formatStyles";
import PropTypes from 'prop-types';
import React, {useMemo} from 'react';
import RadioDraggableStyle from './RadioDraggableStyle';

const RadioDraggable = props => {
  const {
    classes,
    style,
    editorId,
    draggableProps,
    isChildElem,
    absolutePositioning
  } = props;

  const {draggableStyles, elementStyles} =
    useMemo(() => formatStyles(style), [style]);

  return (
    <>
      <DraggableWrapper
        resizeable
        editorId={editorId}
        isChildElem={isChildElem}
        style={{
          ...draggableStyles,
          ...draggableProps?.style,
          ...elementStyles,
          position: absolutePositioning ? 'absolute' : 'static'
        }}
      >
        <Radio
          style={{
            color: '#000000',
            ...elementStyles,
            position: 'static',
          }}
          checkedIcon={
            <RadioButtonCheckedIcon
              className={classes.radioIcon}
              style={{
                width: elementStyles?.width,
                height: elementStyles?.height
              }}
            />
          }
          className={classes.radio}
          disabled
          checked
          data-editorid={editorId}
          data-asset={true}
        />
      </DraggableWrapper>
    </>
  );
};

RadioDraggable.serializedName = 'RadioDraggable'

RadioDraggable.editorSettings = [
  {
    type: 'prop',
    key: 'breakpoints',
    tooltip: 'Make an asset visible at the chosen breakpoints: desktop, tablet, and/or mobile.',
    default: {
      visibility: {
        desktop: true,
        tablet: true,
        mobile: true
      },
      styleLink: {
        tablet: true,
        mobile: true,
      }
    },
    label: null,
    inputType: 'breakpoint'
  },

  {
    type: 'layout',
    key: 'twoCol',
    tooltip: 'Accepted units: px, %, em, rem, vw, vh',
    label: 'Position/Size',
    elements: [
      {type: 'style', key: 'left', default: '0', label: 'X'},
      {type: 'style', key: 'top', default: '0', label: 'Y'},
      {type: 'style', key: 'width', default: '300px', label: 'W'},
      {type: 'style', key: 'height', default: '300px', label: 'H'},
    ]
  },

  {
    type: 'prop',
    key: 'absolutePositioning',
    tooltip: 'If this is checked, your asset\'s CSS will be written with "position: absolute"',
    label: 'Absolute Positioning',
    default: false,
    inputType: 'boolean'
  },

  {
    type: 'style',
    key: 'color',
    default: '#000000',
    label: 'Fill',
    inputType: 'color',
    inputOptions: {
      defaultColor: '#000000'
    }
  },

  {
    type: 'collection',
    label: 'Margin',
    children: [
      {type: 'style', key: 'marginTop', default: '0px', label: 'Top', horizontal: false},
      {type: 'style', key: 'marginBottom', default: '0px', label: 'Bottom', horizontal: false},
      {type: 'style', key: 'marginLeft', default: '0px', label: 'Left', horizontal: false},
      {type: 'style', key: 'marginRight', default: '0px', label: 'Right', horizontal: false},
    ]
  },

  {
    type: 'collection',
    label: 'Effects',
    children: [
      {type: 'style', key: 'opacity', default: '1', label: 'Opacity'},
      {type: 'style', key: 'transform', default: 'none', label: 'Rotation', inputType: 'rotation'},
    ]
  },
];

RadioDraggable.propTypes = {
  classes: PropTypes.object,
  style: PropTypes.object.isRequired,
  draggableProps: PropTypes.object,
  editorId: PropTypes.string.isRequired,
  isChildElem: PropTypes.object,
};

export default withStyles(RadioDraggableStyle)(RadioDraggable);
