import Typography from "@material-ui/core/Typography";
import BaseDropDownMenu from "components/BaseDropDownMenu/BaseDropDownMenu";
import {EditorContext} from "editor/Editor";
import {useEditorDialog} from "hooks/useEditorDialog";
import {useGlobalDialog} from "hooks/useGlobalDialog";
import React, {useContext, useEffect} from 'react';
import Zoom from "assets/education/videos/zoom.mp4";
import Drag_Asset from "assets/education/videos/drag_asset.mp4";
import Canvas_Breakpoint_Styles from "assets/education/videos/canvas_breakpoint_styles.mp4";
import Canvas_Visibility from "assets/education/videos/canvas_visibility.mp4";
import PropTypes from 'prop-types';

const HelpDropDownMenu = props => {
  const {
    classes,
    ...rest
  } = props;

  const editorData = useContext(EditorContext);
  const {editorReducerState, dispatch} = editorData;

  const submitFeedbackDialog = useEditorDialog('submitFeedback');
  const submitBugDialog = useEditorDialog('submitBug');
  const helpDialog = useEditorDialog('help')
  const documentDialog = useGlobalDialog('document');

  useEffect(() => {
    console.log('help drop down props', props)
  }, [props])

  const restartJoyride = () => {
    dispatch({
      type: 'startJoyrideOverride'
    });
  };

  useEffect(() => {
    const script = document.createElement('script');

    script.src = "https://player.vimeo.com/api/player.js";
    script.async = true;

    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    }
  }, []);

  const openTOS = () => {
    documentDialog.appendDialogData({
      fileName: 'terms_of_service'
    });
    documentDialog.openDialog();
  }

  const openPrivacyPolicy = () => {
    documentDialog.appendDialogData({
      fileName: 'privacy_policy'
    });
    documentDialog.openDialog();
  }

  const helpMenuActions = [
    {
      title: 'Privacy Policy',
      action: () => {
        openPrivacyPolicy();

        if(props?.onClose)
          props.onClose()
      }
    },
    {
      title: 'Terms of Service',
      action: () => {
        openTOS();

        if(props?.onClose)
          props.onClose();
      }
    },
    {
      title: 'Get Help',
      action: () => {
        helpDialog.appendDialogData({
          category: 'general'
        })
        helpDialog.openDialog();

        if(props?.onClose)
          props.onClose()
      }
    },
    {
      title: 'Getting Started',
      action: () => {
        restartJoyride();

        if(props?.onClose)
          props.onClose()
      }
    },
    {
      title: 'Submit Feedback',
      action: () => {
        submitFeedbackDialog.openDialog();

        if(props?.onClose)
          props.onClose()
      }
    },
    {
      title: 'Submit Bug',
      action: () => {
        submitBugDialog.openDialog();

        if(props?.onClose)
          props.onClose()
      }
    },
  ]

  return (
    <BaseDropDownMenu
      actions={helpMenuActions}
      {...rest}
    />
  );
};

HelpDropDownMenu.propTypes = {
  classes: PropTypes.object
};

export default HelpDropDownMenu;
