const SubscriptionBannerStyle = theme => ({
  subscriptionBanner: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    // this is a placeholder width, we style
    // it using a js computed width
    width: "calc(100% - 970px)",
    padding: '6px',
    height: "45px",
    position: "fixed",
    backgroundColor: "rgba(59, 59, 71, 0.45)",
    boxShadow: "0px 1px 5px 0px rgba(0,0,0,0.45)",
    zIndex: 10,
    top: '75px',
    left: '360px',
    right: '0',
    marginRight: "8px",
    marginTop: "-10px"
  },

  subscriptionText: {
    color: "#FFFFFF"
  },

  purpleButton: {
    marginLeft: "16px",
    backgroundColor: theme.palette.secondary.main,
    textTransform: "none",
    fontFamily: "Roboto",
    fontSize: '1rem',
    transition: "0.3s",
    width: "84px",

    '&:hover': {
      backgroundColor: "#5C3CA4"
    }

  }
});

export default SubscriptionBannerStyle;
