import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {fas, faIcons} from "@fortawesome/free-solid-svg-icons";
import {makeStyles} from "@material-ui/core";
import AddBoxIcon from "@material-ui/icons/AddBox";
import CancelOutlinedIcon from "@material-ui/icons/CancelOutlined";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckBoxOutlinedIcon from "@material-ui/icons/CheckBoxOutlined";
import CloudCircleIcon from "@material-ui/icons/CloudCircle";
import CloudQueueIcon from "@material-ui/icons/CloudQueue";
import EditAttributesIcon from "@material-ui/icons/EditAttributes";
import InputIcon from "@material-ui/icons/Input";
import ListAltIcon from "@material-ui/icons/ListAlt";
import PhotoCameraOutlinedIcon from "@material-ui/icons/PhotoCameraOutlined";
import RadioButtonCheckedIcon from "@material-ui/icons/RadioButtonChecked";
import TableChartOutlinedIcon from "@material-ui/icons/TableChartOutlined";
import TextFieldsIcon from "@material-ui/icons/TextFields";
import outlinedButtonImage from "assets/variantImages/outlinedButton.jpg";
import containedButtonImage from "assets/variantImages/containedButton.jpg";
import textButtonImage from "assets/variantImages/textButton.jpg";
import containerImage from "assets/variantImages/container.jpg";
import textImage from "assets/variantImages/text.jpg";
import checkboxImage from "assets/variantImages/checkbox.jpg";
import radioImage from "assets/variantImages/radio.jpg";
import mediaImage from "assets/variantImages/media.jpg";
import iconImage from "assets/variantImages/icon.jpg";
import iconButtonImage from "assets/variantImages/iconButton.jpg";
import formImage from "assets/variantImages/form.jpg";
import outlinedInputImage from "assets/variantImages/outlinedInput.jpg";
import cn from "classnames";
import ButtonDraggable from "editor/draggables/ButtonDraggable/ButtonDraggable";
import CheckboxDraggable from "editor/draggables/CheckboxDraggable/CheckboxDraggable";
import ContainerDraggable from "editor/draggables/ContainerDraggable/ContainerDraggable";
import FormDraggable from "editor/draggables/FormDraggable/FormDraggable";
import IconButtonDraggable from "editor/draggables/IconButtonDraggable/IconButtonDraggable";
import IconDraggable from "editor/draggables/IconDraggable/IconDraggable";
import InputDraggable from "editor/draggables/InputDraggable/InputDraggable";
import MediaDraggable from "editor/draggables/MediaDraggable/MediaDraggable";
import RadioDraggable from "editor/draggables/RadioDraggable/RadioDraggable";
import SwitchDraggable from "editor/draggables/SwitchDraggable/SwitchDraggable";
import TableDraggable from "editor/draggables/TableDraggable/TableDraggable";
import TextDraggable from "editor/draggables/TextDraggable/TextDraggable";
import ButtonDraggableSerializer from "editor/serializers/ButtonDraggableSerializer";
import CheckboxDraggableSerializer from "editor/serializers/CheckboxDraggableSerializer";
import ContainerDraggableSerializer from "editor/serializers/ContainerDraggableSerializer";
import FormDraggableSerializer from "editor/serializers/FormDraggableSerializer";
import IconButtonDraggableSerializer from "editor/serializers/IconButtonDraggableSerializer";
import IconDraggableSerializer from "editor/serializers/IconDraggableSerializer";
import InputDraggableSerializer from "editor/serializers/InputDraggableSerializer";
import MediaDraggableSerializer from "editor/serializers/MediaDraggableSerializer";
import RadioDraggableSerializer from "editor/serializers/RadioDraggableSerializer";
import TableDraggableSerializer from "editor/serializers/TableDraggableSerializer";
import TextDraggableSerializer from "editor/serializers/TextDraggableSerializer";
import React from "react";

export const useAssetConfig = () => {

  // same thing as withStyles but we use this for hooks
  const classes = makeStyles({
    faIcons: {
      //  these are font awesome icons - mui icons have default font size applied to their style
      //  mui font size is 2.1875
      fontSize: "2.1875rem"
    },

    iconAsset: {
      fontSize: "1.75rem"
    },
  });

  return {
    'ContainerDraggable': {
      tag: ContainerDraggable,
      tagName: 'ContainerDraggable',
      serializer: ContainerDraggableSerializer,
      toolboxSettings: {
        iconNode: <CheckBoxOutlineBlankIcon fontSize={'large'}/>,
        textNode: 'Container',
        id: 'container',
      },
      variants: {
        'default': {
          // no extra changes here because there are no other variants
          image: containerImage
        },
      },
      sharedProps: {
        style: {
          // background: '#fff',
          width: '300px',
          height: '300px',
        },
        layerLabel: 'Container',
        baseLayerLabel: 'Container',
        layerIconNode: (
          <CheckBoxOutlineBlankIcon
            fontSize={'small'}
          />
        )
      }
    },

    'TextDraggable': {
      tag: TextDraggable,
      tagName: 'TextDraggable',
      serializer: TextDraggableSerializer,
      toolboxSettings: {
        iconNode: <TextFieldsIcon fontSize={'large'}/>,
        textNode: 'Text'
      },
      variants: {
        'default': {
          // no extra changes here because there are no other variants
          image: textImage,
        }
      },
      sharedProps: {
        style: {
          color: '#000000'
        },
        editable: true,
        text: 'Hello World!',
        contentTag: 'div',
        layerLabel: 'Text',
        baseLayerLabel: 'Text',
        layerIconNode: (
          <TextFieldsIcon
            fontSize={'small'}
          />
        )
      }
    },

    'ButtonDraggable': {
      tag: ButtonDraggable,
      tagName: 'ButtonDraggable',
      serializer: ButtonDraggableSerializer,
      toolboxSettings: {
        iconNode: <AddBoxIcon fontSize={'large'}/>,
        textNode: 'Button'
      },
      variants: {
        'contained': {
          props: {
            variant: 'contained',
          },
          image: containedButtonImage
        },
        'outlined': {
          props: {
            variant: 'outlined',
          },
          image: outlinedButtonImage
        },
        'text': {
          props: {
            variant: 'text',
          },
          image: textButtonImage
        }
      },
      sharedProps: {
        style: {
          width: "150px",
          height: "auto",
        },
        baseLayerLabel: 'Button',
        layerLabel: 'Button',
        layerIconNode: (
          <AddBoxIcon
            fontSize={'small'}
          />
        )
      }
    },


    'MediaDraggable': {
      tag: MediaDraggable,
      tagName: 'MediaDraggable',
      serializer: MediaDraggableSerializer,
      toolboxSettings: {
        iconNode: <PhotoCameraOutlinedIcon fontSize={'large'}/>,
        textNode: 'Media'
      },
      variants: {
        'Choose your own': {
          // no extra changes here because there are no other variants
          image: mediaImage
        }
      },
      sharedProps: {
        style: {},
        baseLayerLabel: 'Media',
        layerLabel: 'Media',
        layerIconNode: (
          <CloudQueueIcon
            fontSize={'small'}
          />
        )
      }
    },

    'IconDraggable': {
      tag: IconDraggable,
      tagName: 'IconDraggable',
      serializer: IconDraggableSerializer,
      toolboxSettings: {
        iconNode: (
          <FontAwesomeIcon
            icon={faIcons}
            className={cn(classes.faIcons, classes.iconAsset)}
          />
        ),
        textNode: 'Icon'
      },
      variants: {
        'default': {
          // no extra changes here because there are no other variants
          image: iconImage
        }
      },
      sharedProps: {
        style: {
          color: '#000000'
        },
        baseLayerLabel: 'Icon',
        layerLabel: 'Icon',
        layerIconNode: (
          <FontAwesomeIcon
            icon={faIcons}
          />
        )
      }
    },

    'InputDraggable': {
      tag: InputDraggable,
      tagName: 'InputDraggable',
      serializer: InputDraggableSerializer,
      toolboxSettings: {
        iconNode: <InputIcon fontSize={'large'}/>,
        textNode: 'Input'
      },
      variants: {
        'Outlined Input': {
          // no extra changes here because there are no other variants
          image: outlinedInputImage,
        }
      },
      sharedProps: {
        style: {},
        baseLayerLabel: 'Input',
        layerLabel: 'Input',
        layerIconNode: (
          <InputIcon
            fontSize={'small'}
          />
        )
      }
    },

    'CheckboxDraggable': {
      tag: CheckboxDraggable,
      tagName: 'CheckboxDraggable',
      serializer: CheckboxDraggableSerializer,
      toolboxSettings: {
        iconNode: <CheckBoxOutlinedIcon fontSize={'large'}/>,
        textNode: 'Checkbox'
      },
      variants: {
        'default': {
          // no extra changes here because there are no other variants
          image: checkboxImage,
        }
      },
      sharedProps: {
        style: {},
        baseLayerLabel: 'Checkbox',
        layerLabel: 'Checkbox',
        layerIconNode: (
          <CheckBoxOutlinedIcon
            fontSize={'small'}
          />
        )
      }
    },

    'RadioDraggable': {
      tag: RadioDraggable,
      tagName: 'RadioDraggable',
      serializer: RadioDraggableSerializer,
      toolboxSettings: {
        iconNode: <RadioButtonCheckedIcon fontSize={'large'}/>,
        textNode: 'Radio Button'
      },
      variants: {
        'default': {
          // no extra changes here because there are no other variants
          image: radioImage,
        }
      },
      sharedProps: {
        style: {
          color: "#000000"
        },
        baseLayerLabel: 'Radio',
        layerLabel: 'Radio',
        layerIconNode: (
          <RadioButtonCheckedIcon
            fontSize={'small'}
          />
        )
      }
    },

    // 'SwitchDraggable': {
    //   tag: SwitchDraggable,
    //   tagName: 'SwitchDraggable',
    //   toolboxSettings: {
    //     iconNode: <EditAttributesIcon fontSize={'large'}/>,
    //     textNode: 'Switch'
    //   },
    //   variants: {
    //     'default': {
    //       // no extra changes here because there are no other variants
    //     }
    //   },
    //   sharedProps: {
    //     style: {},
    //     layerLabel: 'Switch',
    //     baseLayerLabel: 'Switch',
    //     layerIconNode: (
    //       <EditAttributesIcon
    //         fontSize={'small'}
    //       />
    //     )
    //   }
    // },

    // 'TableDraggable': {
    //   tag: TableDraggable,
    //   tagName: 'TableDraggable',
    //   serializer: TableDraggableSerializer,
    //   toolboxSettings: {
    //     iconNode: <TableChartOutlinedIcon fontSize={'large'}/>,
    //     textNode: 'Table'
    //   },
    //   variants: {
    //     'default': {
    //       // no extra changes here because there are no other variants
    //     }
    //   },
    //   sharedProps: {
    //     style: {},
    //     baseLayerLabel: 'Radio',
    //     layerLabel: 'Table',
    //     layerIconNode: (
    //       <TableChartOutlinedIcon
    //         fontSize={'small'}
    //       />
    //     )
    //   }
    // },

    'IconButtonDraggable': {
      tag: IconButtonDraggable,
      tagName: 'IconButtonDraggable',
      serializer: IconButtonDraggableSerializer,
      toolboxSettings: {
        iconNode: <CancelOutlinedIcon fontSize={'large'}/>,
        textNode: 'Icon Button'
      },
      variants: {
        'default': {
          // no extra changes here because there are no other variants
          image: iconButtonImage
        }
      },
      sharedProps: {
        style: {
          color: "#000000"
        },
        baseLayerLabel: 'Icon Button',
        layerLabel: 'Icon Button',
        layerIconNode: (
          <CancelOutlinedIcon
            fontSize={'small'}
          />
        )
      }
    },

    'FormDraggable': {
      tag: FormDraggable,
      tagName: 'FormDraggable',
      serializer: FormDraggableSerializer,
      toolboxSettings: {
        iconNode: <ListAltIcon fontSize={'large'}/>,
        textNode: 'Form'
      },
      variants: {
        'create your own': {
          // no extra changes here because there are no other variants
          image: formImage
        }
      },
      sharedProps: {
        style: {
          color: "#000000"
        },
        baseLayerLabel: 'Form',
        layerLabel: 'Form',
        layerIconNode: (
          <ListAltIcon
            fontSize={'small'}
          />
        )
      }
    }
  }
};
