const FormDraggableMainPageStyle = theme => ({
  formRoot: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'stretch',
    flexDirection: 'column',
    flex: 1
  },

  dialogContent: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "flex-start",
    borderRadius: "5px",
    overflowY: "auto",
    flex: 1,

    '&::-webkit-scrollbar': {
      width: '2px',
      position: 'relative',
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: 'white',
    },
    '&::-webkit-scrollbar-track': {
      backgroundColor: '#262626',
      opacity: '0.48'
    },
  },

  fieldsContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'stretch',
    width: '100%',
    flex: 1
  },

  addInputButton: {
    width: '130px'
  },

  submitContainer: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "flex-end",
    marginRight: "16px",
    marginBottom: '16px',
    marginTop: '16px',
  },

  submitButton: {
    width: "175px",
  },
});

export default FormDraggableMainPageStyle;
