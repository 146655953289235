import Button from "@material-ui/core/Button";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TextField from "@material-ui/core/TextField";
import CloseIcon from "@material-ui/icons/Close";
import {EditorContext} from "editor/Editor";
import {Form, Formik} from "formik";
import React, {useContext, useEffect, useMemo} from 'react';
import {
  Dialog, DialogActions, IconButton, TableContainer,
  withStyles
} from '@material-ui/core';
import PropTypes from 'prop-types';
import RoutingDialogStyle from './RoutingDialogStyle';

const RoutingDialog = props => {
  const {
    classes,
    open,
    setOpen,
    data,
  } = props;

  const editorData = useContext(EditorContext);
  const {editorReducerState, dispatch} = editorData;
  const editorPages = editorReducerState.editorState.editorPages.value;

  useEffect(() => {
    console.log('editor pages', editorPages);
  }, [editorPages])

  const handleClose = () => {
    setOpen(false);
  }

  const onSubmit = async (values, {setErrors}) => {
    console.log('submit!', values);

    let newEditorPages = [...editorPages];
    for (let key of Object.keys(values)) {
      let newRoute = values[key];
      let foundEditorPage = newEditorPages.find(elem => elem.labelId === key);

      foundEditorPage.route = newRoute;
    }

    dispatch({
      type: 'setPages',
      payload: {
        newPages: newEditorPages
      }
    })

    setOpen(false);
  };

  const formatInitialValues = () => {
    let formattedValues = {};
    for (let page of editorPages) {
      formattedValues[page.labelId] = page.route;
    }

    return formattedValues;
  }

  let initialValues = useMemo(() => formatInitialValues(), [editorPages])

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      maxWidth={'md'}
    >
      <DialogTitle className={classes.dialogTitle}>
        Routing

        <IconButton
          onClick={handleClose}
          className={classes.closeIcon}
          size={'small'}
        >
          <CloseIcon/>
        </IconButton>
      </DialogTitle>

      <Formik
        initialValues={initialValues}
        onSubmit={onSubmit}
      >
        {({
            values,
            errors,
            handleChange,
            handleSubmit
          }) => (
          <>
            <DialogContent>
              <Form>
                <TableContainer>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell>
                          Page
                        </TableCell>
                        <TableCell>
                          Route
                        </TableCell>
                      </TableRow>
                    </TableHead>
                  </Table>
                </TableContainer>

                <TableContainer
                  style={{ overflow: 'auto', maxHeight: '250px' }}
                  className={classes.tableBody}
                >
                  <Table>
                    <TableBody>
                      {editorPages.map((page, index) => (
                        <TableRow key={page.labelId}>
                          <TableCell  className={classes.tableCell}>
                            {page.pageName}
                          </TableCell>
                          <TableCell  className={classes.tableCell}>
                            <TextField
                              name={page.labelId}
                              variant={'outlined'}
                              color={"primary"}
                              InputProps={{
                                classes: {
                                  notchedOutline: classes.notchedOutline,
                                },
                              }}
                              value={values[page.labelId]}
                              onChange={handleChange}
                              helperText={errors[page.labelId]}
                              error={!!errors[page.labelId]}
                            >

                            </TextField>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Form>
            </DialogContent>
            <DialogActions>
              <Button
                variant={'contained'}
                color={'secondary'}
                onClick={handleSubmit}
              >
                Submit Changes
              </Button>
            </DialogActions>
          </>
        )}
      </Formik>
    </Dialog>
  );
};

RoutingDialog.propTypes = {
  classes: PropTypes.object,
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
  data: PropTypes.object,
};

export default React.memo(withStyles(RoutingDialogStyle)(RoutingDialog));
