import {Dialog, DialogContent, DialogTitle, IconButton, withStyles} from '@material-ui/core';
import CloseIcon from "@material-ui/icons/Close";
import {Elements} from "@stripe/react-stripe-js";
import StripeForm from "components/StripeForm/StripeForm";
import {StripeContext} from "context/StripeContext";
import PropTypes from 'prop-types';
import React, {useContext} from 'react';
import StripeFormDialogStyle from './StripeFormDialogStyle';

const StripeFormDialog = props => {
  const {
    classes,
    open,
    setOpen,
    data,
    isEditingCard,
    onSuccess=null
  } = props;

  const {stripePromise} = useContext(StripeContext);

  const handleClose = () => {
    setOpen(false)
  }

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      maxWidth={'md'}
      classes={{paper: classes.dialog}}
    >
      <DialogTitle className={classes.dialogTitle}>
        Please enter your payment details

        <IconButton
          onClick={handleClose}
          className={classes.closeIcon}
          size={'small'}
        >
          <CloseIcon/>
        </IconButton>
      </DialogTitle>

      <DialogContent>
        <Elements
          stripe={stripePromise}
          options={{
            appearance: {
              theme: 'night'
            }
          }}
        >
          <StripeForm
            isEditingCard={isEditingCard}
            /* TODO: this should be refactored into only an editor dialog and should only use data?.onSuccess */
            onSuccess={onSuccess || data?.onSuccess || null}
            isInDialog
          />
        </Elements>
      </DialogContent>
    </Dialog>
  );
};

StripeFormDialog.propTypes = {
  classes: PropTypes.object,
  isEditingCard: PropTypes.bool,
  open: PropTypes.func,
  setOpen: PropTypes.func,
};

export default withStyles(StripeFormDialogStyle)(StripeFormDialog);
