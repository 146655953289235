import {Card, CardContent, withStyles} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import cn from "classnames";
import SubscriptionStyle from "components/AccountSettings/ManageSubscription/Subscription/SubscriptionStyle";
import {UserContext} from "context/UserContext";
import StripeFormDialog from "dialogs/StripeFormDialog/StripeFormDialog";
import ConfirmationDialog from "editor/components/dialogs/ConfirmationDialog/ConfirmationDialog";
import PropTypes from 'prop-types';
import React, {useContext, useState} from 'react';
import {toast} from "react-toastify";
import {User} from "services";
import {handleNetworkError} from "utils/utils";

const Subscription = props => {
  const {
    pageClasses,
    classes,
  } = props;

  const [open, setOpen] = useState(false);
  const [confirmationDialogOpen, setConfirmationDialogOpen] = useState(false);
  const [busy, setBusy] = useState(false);

  const {userData, setUserData} = useContext(UserContext);

  const handleCancelSubscriptionClick = (e) => {
    setConfirmationDialogOpen(true)
  }

  const handleCancelSubscription = (e) => {
    if(busy) return;

    setBusy(true);

    User.cancelSubscription()
      .then(response => {
        // let newUserData = {...userData, subscriptionStatus: false, last4: null};
        // setUserData(newUserData);

        toast.success('Subscription canceled. Your account will remain premium until your billing cycle ends.')
      })
      .catch(handleNetworkError)
      .finally(() => {
        setConfirmationDialogOpen(false);
        setBusy(false);
      })
  }

  const handleUpgradeSubscriptionClick = (e) => {
    setOpen(true);
  }

  const handleUpgradeSubscription = (data) => {
    let newUserData = {...userData, subscriptionStatus: true};

    if(data?.card?.last4) {
      newUserData.last4 = data.card.last4;
    }

    setUserData(newUserData);
    setOpen(false);
  }

  return (
    <Card className={pageClasses.pageCard}>
      <CardContent>
        <Typography
          variant={'h6'}
          className={pageClasses.pageContentTitle}
        >
         Subscription
        </Typography>

        <Typography
          variant={'subtitle1'}
          className={cn(pageClasses.pageContentTitle, pageClasses.colTitle)}
        >
          Status:{' '}
          {userData.subscriptionStatus ?
            (
              <span className={classes.activeSubscription}>Premium</span>
            )
            :
            (
              <span className={classes.nonActiveSubscription}>Trial</span>
            )
          }
        </Typography>

        <div className={classes.buttonContainer}>
          {userData.subscriptionStatus ?
            (
              <Button
                variant={'outlined'}
                disabled={busy}
                className={classes.outlinedButton}
                onClick={handleCancelSubscriptionClick}
              >
                Cancel Subscription
              </Button>
            )
              :
            (
              <Button
                variant={'contained'}
                disabled={busy}
                className={classes.purpleButton}
                onClick={handleUpgradeSubscriptionClick}
              >
                Upgrade Subscription
              </Button>
            )
          }
        </div>

        <div className={classes.mobileButtonContainer}>
          {userData.subscriptionStatus ?
            (
              <Button
                variant={'outlined'}
                disabled={busy}
                className={classes.outlinedButton}
                onClick={handleCancelSubscriptionClick}
              >
                Cancel
              </Button>
            )
              :
            (
              <Button
                variant={'contained'}
                disabled={busy}
                className={classes.purpleButton}
                onClick={handleUpgradeSubscriptionClick}
              >
                Upgrade
              </Button>
            )
          }
        </div>
      </CardContent>

      <ConfirmationDialog
        open={confirmationDialogOpen}
        setOpen={setConfirmationDialogOpen}
        data={{
          onSuccess: handleCancelSubscription,
          text: "Are you sure you want to cancel your subscription? We're sad to see you go :("
        }}
      />

      <StripeFormDialog
        open={open}
        setOpen={setOpen}
        isEditingCard={false}
        onSuccess={handleUpgradeSubscription}
      />
    </Card>
  );
};

Subscription.propTypes = {
  pageClasses: PropTypes.object,
  classes: PropTypes.object
};

export default withStyles(SubscriptionStyle)(Subscription);
