import {ListItemIcon, Menu, MenuItem, withStyles} from '@material-ui/core';
import Divider from "@material-ui/core/Divider";
import Typography from "@material-ui/core/Typography";
import cn from "classnames";
import PropTypes from 'prop-types';
import React from 'react';

import ContextMenuStyle from './ContextMenuStyle';

const ContextMenu = props => {
  const {
    onClose,
    contextMenuCoords,
    actions,
    customClasses,
    classes
  } = props;

  if(!contextMenuCoords || !contextMenuCoords.x || !contextMenuCoords.y) return null;

  return (
    <Menu
      keepMounted
      open={!!contextMenuCoords.x && !!contextMenuCoords.y}
      onClose={onClose}
      anchorReference="anchorPosition"
      anchorPosition={
        contextMenuCoords.y && contextMenuCoords.x
          ? {top: contextMenuCoords.y, left: contextMenuCoords.x}
          : undefined
      }
      classes={{
        list: classes.menuList,
      }}
    >
      {actions.map((menuSection, outerIndex) => (
        <div key={outerIndex}>
          {menuSection.map(({title, icon, className, action}, index) => (
            <MenuItem
              className={cn(classes.menuItemRoot, className)}
              key={title}
              onClick={action}
            >
              <Typography variant="inherit">
                {title}
              </Typography>
              <ListItemIcon className={classes.contextMenuIconContainer}>
                {icon}
              </ListItemIcon>
            </MenuItem>
          ))}

          {/* don't render the divider if it's the last menu section */}
          {outerIndex !== actions.length -1 && (
            <Divider light className={classes.divider}/>
          )}
        </div>
      ))}
    </Menu>
  );
};

ContextMenu.defaultProps = {
  classes: {},
  customClasses: {}
}

ContextMenu.propTypes = {
  classes: PropTypes.object,
  customClasses: PropTypes.object,
  contextMenuCoords: PropTypes.object.isRequired,
  actions: PropTypes.array.isRequired,
  onClose: PropTypes.func.isRequired
};

export default React.memo(withStyles(ContextMenuStyle)(ContextMenu));
