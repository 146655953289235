import MenuItem from "@material-ui/core/MenuItem";
import StyledTextField from "components/StyledTextField/StyledTextField";
import {EditorContext} from "editor/Editor";
import AlignmentInput from "editor/layout/ElementSettings/Inputs/AlignmentInput/AlignmentInput";
import BackgroundPositionInput
  from "editor/layout/ElementSettings/Inputs/BackgroundPositionInput/BackgroundPositionInput";
import BackgroundSizeInput from "editor/layout/ElementSettings/Inputs/BackgroundSizeInput/BackgroundSizeInput";
import BooleanButtonInput from "editor/layout/ElementSettings/Inputs/FitToTextInput/FitToTextInput";
import BooleanInput from "editor/layout/ElementSettings/Inputs/BooleanInput/BooleanInput";
import BorderPickerInput from "editor/layout/ElementSettings/Inputs/BorderPickerInput/BorderPickerInput";
import BoxShadowInput from "editor/layout/ElementSettings/Inputs/BoxShadowInput/BoxShadowInput";
import CanvasBreakpointInput from "editor/layout/ElementSettings/Inputs/CanvasBreakpointInput/CanvasBreakpointInput";
import ClassInput from "editor/layout/ElementSettings/Inputs/ClassInput/ClassInput";
import CursorInput from "editor/layout/ElementSettings/Inputs/CursorInput/CursorInput";
import ImageFillInput from "editor/layout/ElementSettings/Inputs/ImageFillInput/ImageFillInput";
import LinkInput from "editor/layout/ElementSettings/Inputs/LinkInput/LinkInput";
import RotationInput from "editor/layout/ElementSettings/Inputs/RotationInput/RotationInput";
import TextAlignInput from "editor/layout/ElementSettings/Inputs/TextAlignInput/TextAlignInput";
import TextStyleInput from "editor/layout/ElementSettings/Inputs/TextStyleInput/TextStyleInput";
import TransitionInput from "editor/layout/ElementSettings/Inputs/TransitionInput/TransitionInput";
import {FastField} from "formik";
import PropTypes from "prop-types";
import React, {useCallback, useContext, useEffect, useMemo, useRef, useState} from "react";

import {useDebouncedCallback} from 'use-debounce';

import ColorPickerInput from "./ColorPickerInput/ColorPickerInput";

const ElementSettingsInput = props => {
  const {
    setting,
    initialValues,
    setInitialValues,
    submitForm,
    options = {},

    // these are used to bypass default settings behavior so these inputs can
    // be used elsewhere
    loadValuesFromAsset=true,
    valueOverride=null,
  } = props

  // const {
  //   //values,
  //   //setFieldValue,
  //   //setValues,
  //   //handleChange,
  // } = formikBag;

  const editorData = useContext(EditorContext);
  const { dispatch, editorReducerState } = editorData;
  const { editorState, editorActions } = editorReducerState;
  const { elementTree } = editorState;
  const elementSelected = editorState.elementSelected.value;

  const key = setting.key;

  // TODO: set the inner value to the default and set the formik to ''
  const [innerValue, setInnerValue] = useState(setting.default);
  const mounted = useRef();

  // some inputs like the alignment input need to override the default
  // behavior of setting their values to the default

  useEffect(() => {
    if(setting.name === 'style.boxShadow') {
      console.log('test')
    }

    if(loadValuesFromAsset) {
      let newValue = setting.default;

      if(setting.type === 'style') {
        if(initialValues?.styles && initialValues?.styles[key]) {
          newValue = initialValues.styles[key];
        }
      }
      else if(setting.type === 'prop') {
        if(initialValues?.newProps && initialValues?.newProps[key]) {
          newValue = initialValues.newProps[key];
        }
      }
      else if(setting.type === 'none') {
        if(initialValues && initialValues[key]) {
          newValue = initialValues[key];
        }
      }

      if(newValue !== innerValue) {
        setInnerValue(newValue);
      }
    }
    else {
      setInnerValue(valueOverride)
    }
  }, [initialValues, elementSelected])

  // TODO: debug only delete this
  useEffect(() => {
    if(!mounted.current) {
      mounted.current = true;
    }
  }, [])

  const setFieldValue = (name, value) => {
    let newInitialValues = {...initialValues};

    if(name.includes('.')) {
      const argArr = name.split('.');

      newInitialValues[argArr[0]][argArr[1]] = value;
      setInitialValues(newInitialValues);
    }
    else {
      newInitialValues[name] = value;
      setInitialValues(newInitialValues);
    }

    return newInitialValues;
  }

  const debouncedSetFieldValue = useDebouncedCallback(
    (name, value) => {
      let newValues = setFieldValue(name, value)
      submitForm(newValues);
    },
    400
  );

  const wrappedSetFieldValue = useCallback((name, newValue) => {
    setInnerValue(newValue);
    debouncedSetFieldValue(name, newValue);
  }, [debouncedSetFieldValue])

  // some inputs like the breakpoint input don't need to be debounced,
  // use this in those cases
  const unDebouncedSetFieldValue = (newValue) => {
    setInnerValue(newValue);
    let newValues = setFieldValue(defaultProps.name, newValue);
    submitForm(newValues);
  }

  const handleCompoundChange = (name, placeholder, inputValues) => {
    // this function is used for special cases where we need to change
    // more than just the associated value. TRY NOT TO USE THIS IF POSSIBLE.

    // this does not need to be debounced
    let newStyles = {};
    for (let key of Object.keys(inputValues)) {
      newStyles[key] = inputValues[key];
    }

    let result = {
      ...initialValues,
      'styles': {
        ...initialValues.styles,
        ...newStyles
      },
      'newProps': {
        ...initialValues.newProps,
        [name]: placeholder
      }
    }

    setInitialValues(result);

    // override the default setting behavior, because we have
    // nothing to compare to. Settings that use this
    // function are special cases
    //overrideDefault.current = true;

    // we use this to preserve in the inputs
    setInnerValue(placeholder);

    submitForm(result);
  }

  const getName = () => {
    switch (setting.type) {
      case 'style':
        return 'styles.' + key;
      case 'prop':
        return 'newProps.' + key;
      case 'none':
        return key;
      case 'styleArray':
        // this doesn't need a name, we will use setFieldValue()
        return undefined
    }

    return setting.type === 'style' ? 'styles.' + key : 'newProps.' + key;
  }

  const defaultProps = {
    key,
    value: innerValue,
    name: getName(),
    label: setting.label,
    setting: setting,
    variant: 'outlined',
    ...options
  }

  // a default on change function since most use the same thing and we can cache it with a useCallback
  const defaultHandleChangeValue = value => {
    if(mounted.current) {
      wrappedSetFieldValue(defaultProps.name, value)
    }
  }

  const defaultHandleChangeEvent = e => {
    if(mounted.current) {
      wrappedSetFieldValue(defaultProps.name, e.target.value)
    }
  }

  const handleFocus = (e) => {
    if(!editorState.userIsTyping) {
      dispatch({
        type: 'setEditorState',
        payload: {
          userIsTyping: true
        }
      })
    }
  }

  const handleBlur = (e) => {
    if(editorState.userIsTyping) {
      dispatch({
        type: 'setEditorState',
        payload: {
          userIsTyping: false
        }
      })
    }
  }

  const renderedElementSetting = useMemo(() => {
    if (!setting.inputType) {
      //default
      return (
        <StyledTextField
          {...defaultProps}
          //type={settings.type}
          type={'text'}
          value={innerValue}
          onChange={defaultHandleChangeEvent}
          onFocus={handleFocus}
          onBlur={handleBlur}
          fullWidth
        />
      )
    } else if (setting.inputType === 'select') {
      return (
        <StyledTextField
          {...defaultProps}
          //type={settings.type}
          type={'text'}
          onChange={defaultHandleChangeEvent}
          onFocus={handleFocus}
          onBlur={handleBlur}
          fullWidth
          select
        >
          {setting.options.map(({name, value}, index) => (
            <MenuItem value={value} key={value}>
              {name}
            </MenuItem>
          ))}
        </StyledTextField>
      )
    } else if(setting.inputType === 'boolean') {
      return (
        <BooleanInput
          {...defaultProps}
          onChange={defaultHandleChangeValue}
        />
      )
    } else if(setting.inputType === 'fitToText') {
      return (
        <BooleanButtonInput
          {...defaultProps}
          onChange={(placeholder, inputValues) =>
            handleCompoundChange('fitToText', placeholder, inputValues)}
        />
      )
    } else if (setting.inputType === 'color') {
      return (
        <ColorPickerInput
          {...defaultProps}
          onFocus={handleFocus}
          onBlur={handleBlur}
          onChange={defaultHandleChangeValue}
        />
      );
    } else if (setting.inputType === 'border') {
      return (
        <BorderPickerInput
          {...defaultProps}
          value={innerValue}
          onFocus={handleFocus}
          onBlur={handleBlur}
          onChange={defaultHandleChangeValue}
        />
      )
    } else if (setting.inputType === 'boxShadow') {
      return (
        <BoxShadowInput
          {...defaultProps}
          onFocus={handleFocus}
          onBlur={handleBlur}
          onChange={defaultHandleChangeValue}
        />
      )
    } else if (setting.inputType === 'rotation') {
      return (
        <RotationInput
          {...defaultProps}
          onFocus={handleFocus}
          onBlur={handleBlur}
          onChange={defaultHandleChangeValue}
        />
      )
    } else if (setting.inputType === 'alignment') {
      return (
        <AlignmentInput
          {...defaultProps}
          onChange={(placeholder, inputValues) =>
            handleCompoundChange('alignment', placeholder, inputValues)}
        />
      )
    } else if(setting.inputType === 'link') {
      return (
        <LinkInput
          {...defaultProps}
          onFocus={handleFocus}
          onBlur={handleBlur}
          onChange={defaultHandleChangeValue}
        />
      )
    } else if(setting.inputType === 'class') {
      return (
        <ClassInput
          {...defaultProps}
          onFocus={handleFocus}
          onBlur={handleBlur}
          onChange={defaultHandleChangeValue}
        />
      )
    } else if(setting.inputType === 'imageFill') {
      return (
        <ImageFillInput
          {...defaultProps}
          onChange={(placeholder, inputValues) =>
            handleCompoundChange('selectedMedia', placeholder, inputValues)}
        />
      )
    } else if(setting.inputType === 'textAlign') {
      return (
        <TextAlignInput
          {...defaultProps}
          onChange={defaultHandleChangeValue}
        />
      )
    } else if(setting.inputType === 'cursor') {
      return (
        <CursorInput
          {...defaultProps}
          onFocus={handleFocus}
          onBlur={handleBlur}
          onChange={defaultHandleChangeValue}
        />
      )
    } else if(setting.inputType === 'transition') {
      return (
        <TransitionInput
          {...defaultProps}
          onFocus={handleFocus}
          onBlur={handleBlur}
          onChange={defaultHandleChangeValue}
        />
      )
    } else if(setting.inputType === 'breakpoint') {
      return (
        <CanvasBreakpointInput
          {...defaultProps}
          onChange={unDebouncedSetFieldValue}
        />
      )
    } else if(setting.inputType === 'textStyle') {
      return (
        <TextStyleInput
          {...defaultProps}
          onChange={(placeholder, inputValues) =>
            handleCompoundChange('textStyle', placeholder, inputValues)}
        />
      )
    }  else if(setting.inputType === 'backgroundSize') {
      return (
        <BackgroundSizeInput
          {...defaultProps}
          onFocus={handleFocus}
          onBlur={handleBlur}
          onChange={defaultHandleChangeValue}
        />
      )
    } else if(setting.inputType === 'backgroundPosition') {
      return (
        <BackgroundPositionInput
          {...defaultProps}
          onFocus={handleFocus}
          onBlur={handleBlur}
          onChange={defaultHandleChangeValue}
        />
      )
    }

  }, [setting.inputType, innerValue, mounted.current, initialValues])

  // TODO: ADD NEW ELEMENT SETTINGS INPUTS HERE

  return renderedElementSetting;
}
ElementSettingsInput.propTypes = {
  setting: PropTypes.object.isRequired,
  formikBag: PropTypes.object.isRequired,
};

export default React.memo(ElementSettingsInput);
