import CheckBoxIcon from "@material-ui/icons/CheckBox";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import cn from "classnames";
import React from 'react';
import {
  Checkbox, Typography,
  withStyles
} from '@material-ui/core';
import PropTypes from 'prop-types';
import CheckboxEmailStyle from './CheckboxEmailStyle';

const CheckboxEmail = props => {
  const {
    classes,
    checked,
    setChecked,
  } = props;

  return (
    <div className={classes.root}>
      <div className={classes.checkboxContainer}>
        <Checkbox
          size={'small'}
          checked={checked}
          checkedIcon={
            <CheckBoxIcon
              className={cn(classes.checkboxIcon, classes.checkedIcon)}
            />
          }
          icon={
            <CheckBoxOutlineBlankIcon
              className={classes.checkboxIcon}
            />
          }
          onChange={() => {
            setChecked(!checked)
          }}
          name={'signupEmails'}
        />

        <Typography variant={'body1'} className={cn(classes.text, classes.error)}>
         Sign up to receive emails and updates from Nimbus
        </Typography>
      </div>
    </div>
  );
};

CheckboxEmail.propTypes = {
  classes: PropTypes.object
};

export default withStyles(CheckboxEmailStyle)(CheckboxEmail);