import {useAssetConfig} from "hooks/useAssetConfig";
import React from "react";
import {capitalizeFirstLetter} from "utils/utils";

export const useAssetVariants = (assetName) => {
  const assetConfig = useAssetConfig();

  console.log('use asset variants', assetName)

  if(!assetName) return null;

  let asset = assetConfig[assetName];
  let formattedVariants = [];

  for (let variantName in asset.variants) {
    let variant = asset.variants[variantName];

    let formattedVariantName = capitalizeFirstLetter(variantName);

    formattedVariants.push({
      tag: asset.tag,
      tagName: asset.tagName,
      variantName,
      formattedVariantName: formattedVariantName,
      serializer: asset.serializer,
      image: variant.image,
      props: {
        ...asset.sharedProps,
        ...variant.props,
      }
    })
  }

  return formattedVariants;
}
