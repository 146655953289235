const generateNewAssetLayerLabel = (
  baseLayerLabel,
  tagName, // this isn't used?
  numberOffset=0,
  layerLabelsInUse,

  // this argument will change "Container1" to "Container" but it will add
  // numbers if there is a name clash
  onlyUserNumbersIfNeeded=false
) => {
  let generatedName;
  let count = numberOffset;
  let layerLabel = baseLayerLabel

  let num = parseInt(layerLabel.replace( /^\D+/g, ''));

  do {
    if(!isNaN(num)) {
      let placeholder  = layerLabel.replace(num.toString(), '')
      generatedName = placeholder + (1 + count + num)
    }
    else {
      generatedName = layerLabel + (1 + count)
    }
    count++;
  }
  while(layerLabelsInUse.includes(generatedName))


  if(onlyUserNumbersIfNeeded && count === 1 && !layerLabelsInUse.includes(layerLabel)) {
    return layerLabel;
  }

  return generatedName;
}

export default generateNewAssetLayerLabel;