import Button from "@material-ui/core/Button";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import ArrowDropUpIcon from "@material-ui/icons/ArrowDropUp";
import CloseIcon from "@material-ui/icons/Close";
import ColorPickerBox from "editor/components/ColorPickerBox/ColorPickerBox";
import {EditorContext} from "editor/Editor";
import ColorPickerInput from "editor/layout/ElementSettings/Inputs/ColorPickerInput/ColorPickerInput";
import {Form, Formik} from "formik";
import React, {useContext, useEffect, useMemo, useState} from 'react';

import {
  Collapse,
  Dialog, DialogActions, IconButton, TableContainer,
  withStyles
} from '@material-ui/core';
import PropTypes from 'prop-types';
import {splitCamelCaseToString} from "utils/utils";

import ThemeDialogStyle from './ThemeDialogStyle';

const ThemeDialog = props => {
  const {
    classes,
    open,
    setOpen,
    data
  } = props;

  const editorData = useContext(EditorContext);
  const {editorReducerState, dispatch} = editorData;

  const initialValues = {
    ...editorReducerState.editorState.themeSettings.palette
  };

  // these are the keys we iterate through below
  // NOTE: new colors will not show up unless also added to this
  // I would love to have this just read from the settings so we don't need to
  // also change these, but django stores out project data in variable order
  // so if we don't use these the colors and inner color types get rearranged
  const colorTypes = ['primary', 'secondary', 'error', 'warning', 'success'];
  const innerColorTypes = ['main', 'light', 'dark', 'contrastText'];

  const initialCollapseState = useMemo(() => {
    let baseState = {};
    let valuesToUncollapseByDefault = ['primary', 'secondary'];

    for(let key of Object.keys(editorReducerState.editorState.themeSettings.palette)) {
      baseState[key] = valuesToUncollapseByDefault.includes(key);
    }

    return baseState;
  }, [editorReducerState.editorState.themeSettings])

  const [collapseState, setCollapseState] = useState(initialCollapseState);

  const formatColorName = (colorName) => {
    return splitCamelCaseToString(colorName);
  }

  const handleClose = () => {
    setOpen(false);
  }

  const handleColorBoxChange = (value, name, setFieldValue) => {
    setFieldValue(name, value)
  }

  const collapseLabel = (name) => {
    setCollapseState({
      ...collapseState,
      [name]: !collapseState[name]
    })
  }

  const onSubmit = async (values, {setErrors}) => {
    dispatch({
      type: 'changeTheme',
      payload: {
        newTheme: {
          ...editorReducerState.editorState.themeSettings,
          palette: {
            ...values
          }
        }
      }
    });

    setOpen(false);
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      maxWidth={'md'}
    >
      <DialogTitle className={classes.dialogTitle}>
        Customize Theme

        <IconButton
          onClick={handleClose}
          className={classes.closeIcon}
          size={'small'}
        >
          <CloseIcon/>
        </IconButton>
      </DialogTitle>

      <Formik
        initialValues={initialValues}
        onSubmit={onSubmit}
      >
        {({
            values,
            errors,
            handleChange,
            handleSubmit,
            setFieldValue
          }) => (
          <>
            <DialogContent className={classes.dialogContent}>
              <Form className={classes.form}>
                {colorTypes.map((key, index) => (
                  <div className={classes.colorPickerSection}>
                    <div className={classes.colorPickerSectionHeader}>
                      <Typography
                        className={classes.colorPickerSectionHeaderText}
                        onClick={e => collapseLabel(key)}>
                        {formatColorName(key)}
                      </Typography>

                      <div
                        className={classes.arrowContainer}
                        onClick={e => collapseLabel(key)}
                      >
                        {collapseState[key] ? (
                          <ArrowDropDownIcon fontSize={'small'}/>
                        ) : (
                          <ArrowDropUpIcon fontSize={'small'}/>
                        )}
                      </div>
                    </div>

                    <div className={classes.formInner}>
                      {innerColorTypes.map((innerKey, index) => (
                        <Collapse
                          in={collapseState[key]}
                          classes={{
                            wrapper: classes.colorPickerSection,
                            wrapperInner: classes.colorPickerSection
                          }}
                        >
                          <Typography className={classes.colorPickerSectionInnerHeader}>
                            {formatColorName(innerKey)}
                          </Typography>

                          <ColorPickerInput
                            onChange={
                              value => handleColorBoxChange(
                                value,
                                `${key}.${innerKey}`,
                                setFieldValue
                              )
                            }
                            className={classes.colorPickerInput}
                            defaultColor={initialValues[key][innerKey]}
                            showTitle={false}
                            showTextField
                            value={values[key][innerKey]}
                            name={`${key}.${innerKey}`}
                            removable={false}
                            forceHexcode={true}
                            setting={{
                              type: 'none',
                              key: `${key}.${innerKey}`,
                              default: initialValues[key][innerKey],
                              label: innerKey,
                              inputType: 'color'
                            }}
                          />
                        </Collapse>
                      ))}
                    </div>
                  </div>
                ))}
              </Form>
            </DialogContent>
            <DialogActions>
              <Button
                variant={'contained'}
                color={'secondary'}
                onClick={handleSubmit}
              >
                Submit Changes
              </Button>
            </DialogActions>
          </>
        )}
      </Formik>
    </Dialog>
  );
};

ThemeDialog.propTypes = {
  classes: PropTypes.object
};

export default withStyles(ThemeDialogStyle)(ThemeDialog);
