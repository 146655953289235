import {faFileExport, faEye} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Button, IconButton, Tooltip, Typography, useMediaQuery, withStyles} from "@material-ui/core";
import {useTheme} from "@material-ui/core/styles";
import EditIcon from '@material-ui/icons/Edit'
import ZoomInIcon from '@material-ui/icons/ZoomIn';
import ZoomOutIcon from '@material-ui/icons/ZoomOut';
import NimbusLogo from "components/NimbusLogo/NimbusLogo";
import UserMenu from "components/UserMenu/UserMenu";
import {UserContext} from "context/UserContext";
import EditDropDownMenu from "editor/components/dropDownMenus/EditDropDownMenu/EditDropDownMenu";
import FileDropDownMenu from "editor/components/dropDownMenus/FileDropDownMenu/FileDropDownMenu";
import HelpDropDownMenu from "editor/components/dropDownMenus/HelpDropDownMenu/HelpDropDownMenu";
import ViewDropDownMenu from "editor/components/dropDownMenus/ViewDropDownMenu/ViewDropDownMenu";
import {EditorContext} from "editor/Editor";
import EditorSettingsStyle from "editor/layout/EditorSettings/EditorSettingsStyle";
import {useEditorDialog} from "hooks/useEditorDialog";
import PropTypes from "prop-types";
import React, {useContext, useEffect, useState} from 'react';
import {NavLink, useHistory} from "react-router-dom";

const EditorSettings = props => {
  const {classes} = props;

  const [fileAnchorEl, setFileAnchorEl] = useState(null);
  const [editAnchorEl, setEditAnchorEl] = useState(null);
  const [viewAnchorEl, setViewAnchorEl] = useState(null);
  const [helpAnchorEl, setHelpAnchorEl] = useState(null);
  const theme = useTheme();
  const isBreakpoint1400 = useMediaQuery(theme.breakpoints.down(1400));

  const editorData = useContext(EditorContext);
  const {userData} = useContext(UserContext);
  const {editorReducerState, dispatch} = editorData;
  const {editorSettings} = editorReducerState;
  const projectName = editorReducerState.editorState.currentProjectData.value?.name;
  const history = useHistory();

  const renameProjectDialog = useEditorDialog('renameProject');
  const exportDialog = useEditorDialog('export');
  const loginRegisterDialog = useEditorDialog('loginRegister');
  const confirmationDialog = useEditorDialog('confirmation');
  const previewDialog = useEditorDialog('preview');

  const handleClose = (type) => {
    if(type === 'file') {
      setFileAnchorEl(null)
    } else if(type === 'edit') {
      setEditAnchorEl(null)
    } else if(type === 'view') {
      setViewAnchorEl(null)
    } else if(type === 'help') {
      setHelpAnchorEl(null)
    }
  }

  const handleOpen = (e, type) => {
    if(type === 'file') {
      setFileAnchorEl(e.currentTarget)
    } else if(type === 'edit') {
      setEditAnchorEl(e.currentTarget)
    } else if(type === 'view') {
      setViewAnchorEl(e.currentTarget)
    } else if(type === 'help') {
      setHelpAnchorEl(e.currentTarget)
    }
  }

  const openExportDialog = () => {
    if(userData) {
      exportDialog.openDialog();
    }
    else {
      loginRegisterDialog.appendDialogData({
        onSuccess: () => {
          exportDialog.openDialog();
        }
      })

      loginRegisterDialog.openDialog();
    }
  }

  const openPreviewDialog = () => {
    if(userData) {
      previewDialog.openDialog();
    }
    else {
      loginRegisterDialog.appendDialogData({
        onSuccess: () => {
          previewDialog.openDialog();
        }
      })

      loginRegisterDialog.openDialog();
    }
  }

  const handleZoomIn = () => {
    dispatch({type: 'zoomIn'})
  }

  const handleZoomOut = () => {
    dispatch({type: 'zoomOut'})
  }

  const openRenameProjectDialog = () => {
    renameProjectDialog.openDialog();
  }

  const openLoginDialog = () => {
    loginRegisterDialog.openDialog();
  }

  const goToHome = () => {
    let dataToAppend = {onSuccess: () => history.push('/')};

    dataToAppend.text = 'Are you sure you want to go to the home page? ' +
      'Any unsaved progress will be lost.';

    confirmationDialog.appendDialogData(dataToAppend);
    confirmationDialog.openDialog();
  }

  return (
    <div className={classes.root}>
      <div onClick={goToHome} className={classes.logo}>
        <NimbusLogo size={"small"}/>
      </div>

      <div className={classes.settingOuterContainer}>
        <IconButton
          className={classes.settingContainer}
          size={'small'}
          onClick={(e) => {handleOpen(e, 'file')}}
        >
          <Typography variant={"subtitle1"}>
            File
          </Typography>
        </IconButton>

        <FileDropDownMenu
          anchorEl={fileAnchorEl}
          open={Boolean(fileAnchorEl)}
          getContentAnchorEl={null}
          onClose={(e) => {handleClose('file')}}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
        />

        <IconButton
          className={classes.settingContainer}
          size={'small'}
          onClick={(e) => {handleOpen(e,'edit')}}
        >
          <Typography variant={"subtitle1"}>
            Edit
          </Typography>
        </IconButton>

        <EditDropDownMenu
          anchorEl={editAnchorEl}
          open={Boolean(editAnchorEl)}
          getContentAnchorEl={null}
          onClose={(e) => {handleClose('edit')}}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
        />

        <IconButton
          className={classes.settingContainer}
          size={'small'}
          onClick={(e) => {handleOpen(e, 'view')}}
        >
          <Typography variant={"subtitle1"}>
            View
          </Typography>
        </IconButton>

        <ViewDropDownMenu
          anchorEl={viewAnchorEl}
          open={Boolean(viewAnchorEl)}
          getContentAnchorEl={null}
          onClose={(e) => {handleClose('view')}}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
        />

        <IconButton
          className={classes.settingContainer}
          size={'small'}
          onClick={(e) => {handleOpen(e, 'help')}}
        >
          <Typography variant={"subtitle1"}>
            Help
          </Typography>
        </IconButton>

        <HelpDropDownMenu
          anchorEl={helpAnchorEl}
          open={Boolean(helpAnchorEl)}
          getContentAnchorEl={null}
          onClose={(e) => {handleClose( 'help')}}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
        />
      </div>

      <div
        className={classes.projectTitleContainer}
        id={'project-name'}
      >
        <Typography
          className={classes.projectTitle}
          variant={"h6"}
        >
          {projectName || 'New Project'}
          <IconButton onClick={openRenameProjectDialog} className={classes.editIconButton}>
            <EditIcon className={classes.editIcon}/>
          </IconButton>
        </Typography>
      </div>

      <div className={classes.endRow}>

        {isBreakpoint1400 ? (
            <div className={classes.exportContainer}>
              <Tooltip title={'Preview'}>
                <Button
                  onClick={openPreviewDialog}
                  variant="contained"
                  className={classes.exportButton}
                  id={'export'}
                >
                  <FontAwesomeIcon icon={faEye} className={classes.exportIcon}/>
                </Button>
              </Tooltip>
            </div>
          )
          :
          (
            <div className={classes.exportContainer}>
              <Button
                onClick={openPreviewDialog}
                variant="contained"
                className={classes.exportButton}
                id={'export'}
              >
                <FontAwesomeIcon icon={faEye} className={classes.exportIcon}/>
                <Typography className={classes.exportText}>
                  Preview
                </Typography>
              </Button>
            </div>
          )
        }

        {isBreakpoint1400 ? (
            <div className={classes.exportContainer}>
              <Tooltip title={'Export'}>
                <Button
                  onClick={openExportDialog}
                  variant="contained"
                  className={classes.exportButton}
                  id={'export'}
                >
                  <FontAwesomeIcon icon={faFileExport} className={classes.exportIcon}/>
                </Button>
              </Tooltip>
            </div>
          )
          :
          (
            <div className={classes.exportContainer}>
              <Button
                onClick={openExportDialog}
                variant="contained"
                className={classes.exportButton}
                id={'export'}
              >
                <FontAwesomeIcon icon={faFileExport} className={classes.exportIcon}/>
                <Typography className={classes.exportText}>
                  Export
                </Typography>
              </Button>
            </div>
          )
        }

        <div className={classes.zoomLevelContainer}>
          <IconButton onClick={handleZoomIn} className={classes.zoomButton}>
            <ZoomInIcon/>
          </IconButton>

          <IconButton onClick={handleZoomOut} className={classes.zoomButton}>
            <ZoomOutIcon/>
          </IconButton>

          <Typography className={classes.zoomLevelText}>
            {editorSettings.zoomLevel * 100}%
          </Typography>
        </div>

        {userData ? (
          <UserMenu
            inEditor
          />
        ) : (
          <Button
            variant="contained"
            className={classes.purpleButton}
            onClick={openLoginDialog}
          >
            Login
          </Button>
        )}
      </div>
    </div>
  );
};

EditorSettings.propTypes = {
  classes: PropTypes.object
};

export default React.memo(withStyles(EditorSettingsStyle)(EditorSettings));
