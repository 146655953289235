import ButtonDraggable from "editor/draggables/ButtonDraggable/ButtonDraggable";
import CheckboxDraggable from "editor/draggables/CheckboxDraggable/CheckboxDraggable";
import InputDraggable from "editor/draggables/InputDraggable/InputDraggable";
import RadioDraggable from "editor/draggables/RadioDraggable/RadioDraggable";

const getFormDraggableAssetConfig = (
  component,
  generatedId,
  generatedLabel,
  layerIconNode,
  restProps
) => {

  // data that is shared by all assets,
  // we use the spread operator to put this in
  // one place and re-use it
  const defaultAssetData = {
    id: generatedId,
    baseData: {
      key: generatedId,
      editorId: generatedId,
      layerIconNode: layerIconNode,
      layerLabel: generatedLabel,
      isChildElem: true,
      breakpoints: {
        styleLink: {
          tablet: true,
          mobile: true,
        },
        visibility: {
          desktop: true,
          tablet: true,
          mobile: true
        }
      }
    },
    breakpointData: {
      mobile: {
        style: {},
        ...restProps
      },
      tablet: {
        style: {},
        ...restProps
      },
      desktop: {
        style: {},
        ...restProps
      }
    },
    props: {
      key: generatedId,
      editorId: generatedId,
      layerIconNode: layerIconNode,
      layerLabel: generatedLabel,
      isChildElem: true,
      ...restProps
    },
    draggableProps: {},
    children: []
  }

  switch (component) {
    case 'TextField':
      return {
        tag: InputDraggable,
        tagName: 'InputDraggable',
        ...defaultAssetData
      };

    case 'RadioButton':
      return {
        tag: RadioDraggable,
        tagName: 'RadioDraggable',
        ...defaultAssetData
      }

    case 'Checkbox':
      return {
        tag: CheckboxDraggable,
        tagName: 'CheckboxDraggable',
        ...defaultAssetData
      }

    case 'Button':
      return {
        tag: ButtonDraggable,
        tagName: 'ButtonDraggable',
        ...defaultAssetData
      }
  }
}

export default getFormDraggableAssetConfig;
