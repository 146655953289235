import {purpleText} from "assets/theme";

const CreateAccountFormStyle = theme => ({
  root: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: 'center',
    width: "100%",
    textAlign: 'center'
  },

  title: {
    width: "100%",
    marginBottom: props => props.inEditor ? '10px' : "35px",
    marginTop: props => props.inEditor? '10px' : "50px",

    '& .MuiTypography-h2': {
      fontSize: props => props.inEditor? '38px' : "60px",

      [theme.breakpoints.down('605')]: {
        fontSize: props => props.inEditor? '38px' : "40px",
      },
      [theme.breakpoints.down('425')]: {
        fontSize: props => props.inEditor? '38px' : "32px",
      },
      [theme.breakpoints.down('350')]: {
        fontSize: props => props.inEditor? '38px' : "28px",
      },
    },

    [theme.breakpoints.down('605')]: {
      marginBottom: props => props.inEditor ? '10px' : "0px",
    },
  },

  purpleText: {
    ...purpleText,
    textDecoration: "none",
  },

  formContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: 'center',
    maxWidth: "450px",
    minWidth: "200px",
  },

  formInput: {
    backgroundColor: "transparent",
    color: 'white',
    borderColor: "white",

    '&.MuiTextField-root': {
      '& fieldset': {
        borderColor: 'white',
      },
      '&:hover fieldset': {
        borderColor: 'white',
      },
      '&.Mui-focused fieldset': {
        borderColor: 'white',
      },
      '& .MuiInputBase-input': {
        color: "white",
      },
    },
  },

  googleAccountButton: {
    '&.MuiButtonBase-root': {
      textTransform: "none",
      color:"white",
      backgroundColor: "transparent",
      borderColor: "white",
      width:"450px",
      height: "52px",
      fontSize: "17px",
      transition: "0.3s",
      marginBottom: "20px",

      [theme.breakpoints.down('615')]: {
        width:"400px",
      },
      [theme.breakpoints.down('515')]: {
        width:"300px",
      },
      [theme.breakpoints.down('425')]: {
        width:"275px",
      },
      [theme.breakpoints.down('350')]: {
        width:"250px",
      },
      '&:hover': {
        backgroundColor: "#1D1D1D",
      },
    },
  },

  googleIcon: {
    width: "20px",
    height: "24px",
    marginRight: "10px"
  },

  formNameContainer: {
    width: "100%",
    marginBottom: "20px",
    marginTop: "20px",
  },

  formInputContainer: {
    width: "100%",
    marginBottom: "20px",
  },

  input: {
    color: "white",
    fontSize: "18px",
    padding: "11px",
  },

  createAccountButton: {
    '&.MuiButtonBase-root': {
      textTransform: "none",
      color:"black",
      backgroundColor: "white",
      borderColor: "white",
      width:"450px",
      height: "52px",
      fontSize: "17px",
      transition: "0.3s",
      marginBottom: "20px",


      [theme.breakpoints.down('615')]: {
        width:"400px",
      },
      [theme.breakpoints.down('515')]: {
        width:"300px",
      },
      [theme.breakpoints.down('425')]: {
        width:"275px",
      },
      [theme.breakpoints.down('350')]: {
        width:"250px",
      },

      '&:hover': {
        backgroundColor: "#E1E1E1",
      },
    },
  },

  errorText: {
    color: theme.palette.error.main,
    marginBottom: '8px',
    fontSize: '19px',
    fontWeight: '500'
  },

  login: {
    textDecoration: "none",
    marginBottom: "20px",
    cursor: "pointer",
  },
});

export default CreateAccountFormStyle;
