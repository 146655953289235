import {List, ListItem, ListItemText, withStyles} from "@material-ui/core";
import AccountSettingsStyle from "components/AccountSettings/AccountSettingsStyle";
import ManageSubscription from "components/AccountSettings/ManageSubscription/ManageSubscription";
import Profile from "components/AccountSettings/Profile/Profile";
import {UserContext} from "context/UserContext";
import PropTypes from "prop-types";
import React, {useContext, useState} from 'react';
import {Redirect} from "react-router-dom";

const AccountSettings = props => {
  const {
    classes,
    pageToOpen=0
  } = props;

  const [page, setPage] = useState(pageToOpen)
  const {userData, setUserData} = useContext(UserContext)

  const handlePageChange = (page) => {
    setPage(page)
  };

  if(!userData) return <Redirect to={'/'}/>

  return (
    <div className={classes.root}>
      <div className={classes.listOuterContainer}>
        <List className={classes.listContainer}>
          <ListItem
            button={page !== 0}
            onClick={() => {handlePageChange(0)}}
            className={page === 0 ? classes.activeListItem : null}
          >
            <ListItemText primary={"Profile"}/>
          </ListItem>

          <ListItem
            button={page !== 1}
            onClick={() => {handlePageChange(1)}}
            className={page === 1 ? classes.activeListItem : null}
          >
            <ListItemText primary={"Subscription"}/>
          </ListItem>
        </List>
      </div>

      {/*mobile only*/}
      <div className={classes.mobileListOuterContainer}>
        <List className={classes.mobileListContainer}>
          <ListItem
            button={page !== 0}
            onClick={() => {handlePageChange(0)}}
            className={page === 0 ? classes.activeListItem : null}
          >
            <ListItemText primary={"Profile"}/>
          </ListItem>

          <ListItem
            button={page !== 1}
            onClick={() => {handlePageChange(1)}}
            className={page === 1 ? classes.activeListItem : null}
          >
            <ListItemText primary={"Subscription"}/>
          </ListItem>
        </List>
      </div>


      <div className={classes.pageContainer}>
        <Profile
          pageClasses={classes}
          style={{display: page === 0 ? "flex": "none"}}
        />
        <ManageSubscription
          pageClasses={classes}
          style={{display: page === 1 ? "flex": "none"}}
        />
      </div>
    </div>
  );
};

AccountSettings.propTypes = {
  classes: PropTypes.object
};

export default withStyles(AccountSettingsStyle)(AccountSettings);
