import {purpleButton} from "assets/theme";

const CookieBarStyle = theme => ({
  root: {
    width: '100%',
    height: '84px',
    display: (props) => props.open ? 'flex' : 'none',
    flexDirection: 'row',
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center",
    backgroundColor: "rgba(0,0,0,0.8)",
    position: 'sticky',
    bottom: '0',
    right: '0',
    padding: '20px 20px',
    zIndex: 1000,

    [theme.breakpoints.down('md')]: {
      flexDirection: "column",
      height: '150px',
      padding: "20px 20px"
    },
  },

  text: {
    color: "white",
    fontFamily: "Roboto",
    fontSize: "16px",
    fontWeight: "500",
  },

  buttonContainer: {
    marginLeft: "50px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",

    [theme.breakpoints.down('md')]: {
      marginTop: "20px",
      marginLeft: "0px"
    },
  },

  acceptCookiesButton: {
    ...purpleButton,
    width: "200px",
  },

  link: {
    color: 'white',
  }
});

export default CookieBarStyle;