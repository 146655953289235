const EditorStyle = theme => ({
  gridLineStyle: {
    position: 'absolute',
    backgroundColor: theme.palette.gridLines.main,
  },

  mobileBlocker: {
    display: "none",
    position: "absolute",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    textAlign: "center",
    width: "100%",
    height: "100vh",
    padding: "0 30px",
    backgroundColor: "#1B1C20",
    zIndex: 999999,

    [theme.breakpoints.down('sm')]: {
      display: 'flex',
    },
  },

  mobileBlockerText: {
    color: "white",
    fontWeight: "500"
  },

  editorOuterContainer: {
    display: "flex",
    flexDirection: "column",
    width: "100%",

    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },

  editorInnerContainer: {
    display: "flex",
    flexDirection: "row",
    width: "100%",
    justifyContent: "flex-start",
    alignItems: "center",
    position: 'relative'
  },
});

export default EditorStyle;
