import {Dialog, DialogContent, DialogTitle, IconButton, TextField, withStyles} from '@material-ui/core';
import Button from "@material-ui/core/Button";
import CloseIcon from "@material-ui/icons/Close";
import {EditorContext} from "editor/Editor";
import {Form, Formik} from "formik";
import PropTypes from 'prop-types';
import React, {useContext, useState} from 'react';
import RenamePageDialogStyle from './RenamePageDialogStyle';

const RenamePageDialog = props => {
  const {
    classes,
    open,
    setOpen,
    data,
  } = props;

  const editorData = useContext(EditorContext);
  const {editorReducerState, dispatch} = editorData;
  const [busy, setBusy] = useState(false)

  const handleClose = () => {
    setOpen(false)
  }

  const submitRenamePage = async (values, {setErrors}) => {
    setBusy(true)

    dispatch({
      type: 'renamePage',
      payload: {
        name: values.pageName,
        lastClickedPage: data.lastClickedPage.labelId
      }
    })
    handleClose()
    setBusy(false)
  }

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      maxWidth={'md'}
      classes={{paper: classes.dialog}}
    >
      <DialogTitle className={classes.dialogTitle}>
        Rename Page

        <IconButton
          onClick={handleClose}
          className={classes.closeIcon}
          size={'small'}
        >
          <CloseIcon/>
        </IconButton>
      </DialogTitle>

      <DialogContent>
        <Formik
          initialValues={{
            'pageName': data?.lastClickedPage?.pageName
          }}
          enableReinitialize
          onSubmit={submitRenamePage}
        >
          {({
              values,
              errors,
              handleChange
            }) => (
            <Form className={classes.form}>
              <TextField
                fullWidth
                color={"primary"}
                variant={"outlined"}
                name={'pageName'}
                autoFocus
                value={values.pageName}
                onChange={handleChange}
                error={!!errors.pageName}
                helperText={errors.pageName}
                InputProps={{
                  classes: {
                    notchedOutline: classes.notchedOutline,
                  },
                }}
              />

              <Button
                className={classes.purpleButton}
                variant={'contained'}
                color={'secondary'}
                disabled={busy}
                type={'submit'}
              >
                Submit
              </Button>
            </Form>
          )}
        </Formik>
      </DialogContent>
    </Dialog>
  );
};

RenamePageDialog.propTypes = {
  classes: PropTypes.object
};

export default withStyles(RenamePageDialogStyle)(RenamePageDialog);