const StripeCVCInputStyle = theme => ({
  stripeInput: {
    padding: '6px',
    paddingLeft: 0,
    marginBottom: '6px',
    borderRadius: '4px',
    border: `1px solid ${theme.palette.divider}`,
    borderColor: theme.palette.text.primary,
    '&:hover': {
      borderColor: theme.palette.text.primary,
    },
    '&:focus': {
      borderColor: theme.palette.primary.main,
      boxShadow: `0px 0px 0px 2px ${theme.palette.primary.light}`,
    },

    '& .StripeElement--invalid': {
      border: 'none !important',
    }
  },

  inputLabel: {
    position:'relative',
    top: '10px',
    background: '#0F0E10',
    zIndex: 1000,
    paddingLeft: '8px',
    width: '44px',
    left: '8px'
  },

  inputRoot: {
    display: 'flex',
    flexDirection: 'column',
    width: '125px',
    marginLeft: '12px'
  },

  formControl: {
    position: 'relative',
    zIndex: 1
  }
});

export default StripeCVCInputStyle;