import {TextField, withStyles} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import {Form, Formik} from "formik";
import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {toast} from "react-toastify";
import {ContactUs} from "services";
import {handleNetworkError} from "utils/utils";
import SubmitBugContentStyle from './SubmitBugContentStyle';

const SubmitBugContent = props => {
  const {
    classes,
    setOpen,
    inEditor,
  } = props;

  const [busy, setBusy] = useState(false)

  const onSubmit = async (values, {setErrors}) => {
    console.log('values', values);
    const {
      name,
      email,
      message
    } = values;

    setBusy(true)
    ContactUs.submitBugReport(name, email, message)
      .then(response => {
        console.log('response!', response);
        toast.success('Feedback submitted!');
      })
      .catch(handleNetworkError)
      .finally(() => {
        if(inEditor){
          setOpen(false);
        }
        setBusy(false)
      })
  };

  return (
    <div className={classes.root}>
      <Formik
        initialValues={{
          name: "",
          email: "",
          message: "",
        }}

        onSubmit={onSubmit}
      >
        {({
            values,
            errors,
            handleChange
          }) => (
          <Form className={classes.outerFormContainer}>
          <div className={classes.formContainer}>
                <div className={classes.formInputContainer}>
                  <TextField
                    className={classes.formInput}
                    placeholder="Name"
                    name="name"
                    error={!!errors.name}
                    helperText={errors.name}
                    onChange={handleChange}
                    value={values.name}
                    variant="outlined"
                    fullWidth
                    InputProps={{
                      classes: {input: classes.input},
                    }}
                  />
                </div>
                <div className={classes.formInputContainer}>
                  <TextField
                    className={classes.formInput}
                    placeholder="Email"
                    name="email"
                    error={!!errors.email}
                    helperText={errors.email}
                    onChange={handleChange}
                    value={values.email}
                    variant="outlined"
                    fullWidth
                    InputProps={{
                      classes: {input: classes.input},
                    }}
                  />
                </div>
                <div className={classes.formInputContainer}>
                  <TextField
                    className={classes.formInput}
                    name="message"
                    multiline
                    rows={5}
                    placeholder={'Type Your Message Here'}
                    error={!!errors.message}
                    helperText={errors.message}
                    value={values.message}
                    onChange={handleChange}
                    variant="outlined"
                    fullWidth
                    InputProps={{
                      classes: {input: classes.input},
                    }}
                  />
                </div>
              </div>

            <div className={classes.buttonContainer}>
              <Button
                variant={'contained'}
                type='submit'
                disabled={busy}
                className={classes.purpleButton}
              >
                Submit Bug
              </Button>
            </div>

          </Form>
        )}
      </Formik>
    </div>
  );
};

SubmitBugContent.propTypes = {
  classes: PropTypes.object,
  setOpen: PropTypes.func,
  inEditor: PropTypes.bool,
};

export default withStyles(SubmitBugContentStyle)(SubmitBugContent);