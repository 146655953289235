import Typography from "@material-ui/core/Typography";
import React from 'react';

const WhereDoUploadedImagesGoTo = () => {
  return (
    <>
      <Typography style={{marginBottom: '8px'}}>
        Once you have uploaded your image file under the 'Upload Media' tab, all images you have uploaded will appear under the 'Your Images' tab in the media dialog.
        To access this location, follow these steps:
      </Typography>
      <Typography>
        1. Select the media asset and drag and drop it to the desired location on the canvas.
      </Typography>
      <Typography>
        2. A media dialog will open with the 'Upload Media' tab selected.
      </Typography>
      <Typography>
        3. To locate your image library, go to the 'Your Images' tab.
      </Typography>
    </>
  );
};

WhereDoUploadedImagesGoTo.propTypes = {

};

export default WhereDoUploadedImagesGoTo;