import {outlinedButton, purpleButton} from "assets/theme";

const ExportDialogStyle = theme => ({
  text: {
    width: "400px",
    marginBottom: "8px",
  },

  outlinedButton: {
    ...outlinedButton
  },

  purpleButton: {
    ...purpleButton
  },

  dialogActions: {
    padding: "16px"
  },

  dialogTitle: {
    width: "100%",

    '& .MuiTypography-root': {
      display: "flex",
      flexDirection: "row",
      width: "100%",
      justifyContent: "space-between",
    },
  },

  icon: {
    '& > *': {
      fontSize: '30px',
      width: '30px',
      height: '30px',
      transition: '0.3s',
      cursor: 'pointer',

      color: '#D2D2D2',

      '&:hover': {
        color: theme.palette.secondary.main
      }
    }
  },

  inProgress: {
    color: theme.palette.secondary.main,
    fontWeight: '600'
  },

  complete: {
    color: '#67E72F',
    fontWeight: '600',
    cursor: 'pointer',

    '&:hover': {
      color: '#45A41C'
    }
  },

  table: {
    margin: '20px 0',
    marginTop: '0px',
    maxHeight: "275px",

    '&::-webkit-scrollbar': {
      height: '100px',
      width: '2px',
      position: 'relative',
      marginRight: '2px',
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: 'white',
    },
    '&::-webkit-scrollbar-track': {
      backgroundColor: '#262626',
      opacity: '0.48'
    },
  },

  boldTableCell: {
    fontWeight: '500'
  },

  dialogContent: {
    overflow: "hidden",
    width: "100%",
  },
});

export default ExportDialogStyle;
