import CloseIcon from "@material-ui/icons/Close";
import PaginationChevronCountPanel from "components/PaginationChevronCountPanel/PaginationChevronCountPanel";
import useWindowDimensions from "hooks/useWindowDimensions";
import React, {useEffect, useRef, useState} from 'react';
import privacy_policy_pdf from 'assets/documents/privacy_policy.pdf';
import terms_of_service_pdf from 'assets/documents/terms_of_service.pdf';

import { pdfjs } from 'react-pdf';

import {
  Dialog, DialogContent, DialogTitle, IconButton,
  withStyles
} from '@material-ui/core';
import PropTypes from 'prop-types';

import DocumentDialogStyle from './DocumentDialogStyle';

// required workaround for this library
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

// eslint-disable-next-line import/first
import { Document, Page } from 'react-pdf/dist/entry.webpack';

const DocumentDialog = props => {
  const {
    classes,
    open,
    setOpen,
    data
  } = props;

  const [pageNumber, setPageNumber] = useState(1);
  const [numPages, setNumPages] = useState(1);
  const [file, setFile] = useState(null);
  const [scale, setScale] = useState(1.2);
  const [fullScreen, setFullScreen] = useState(false)
  const [title, setTitle] = useState('');

  const windowDimensions = useWindowDimensions();

  const handleClose = () => {
    setOpen(false)
    setNumPages(1);
    setPageNumber(1);
  }

  const onDocLoadSuccess = ({ numPages }) => {
    setNumPages(numPages)
  };

  const updatePage = (isNextPage) => {
    if(isNextPage && pageNumber <= numPages-1) {
      setPageNumber(pageNumber+1);
    }
    else if(!isNextPage && pageNumber > 1) {
      setPageNumber(pageNumber-1);
    }
  };

  useEffect(() => {
    console.log('window dimensions', windowDimensions)
    if(windowDimensions.width > 960) {
      // large screens
      console.log('large')
      setScale(1.2)
      setFullScreen(false)
    }
    else if(windowDimensions.width > 760) {
      // medium screens
      setScale(1.0)
      setFullScreen(false)
      console.log('medium')
    }
    else if(windowDimensions.width > 635) {
      // small screens
      setScale(0.8)
      setFullScreen(false)
      console.log('medium')
    }
    else if(windowDimensions.width > 500) {
      // small screens
      setScale(0.7)
      setFullScreen(false)
      console.log('medium')
    }
    else {
      // extra small screens
      setScale(0.7)
      setFullScreen(true)
      console.log('small')
    }
  }, [windowDimensions])

  useEffect(() => {
    switch (data?.fileName) {
      case 'terms_of_service':
        setFile(terms_of_service_pdf);
        setTitle('Terms of Service');
        break;
      case 'privacy_policy':
        setFile(privacy_policy_pdf);
        setTitle('Privacy Policy');
        break;
      default:
        setFile(null);
        setTitle('');
    }
  }, [data]);

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      fullScreen={fullScreen}
      maxWidth={'md'}
      classes={{paper: classes.dialog}}
    >
      <DialogTitle className={classes.dialogTitle}>
        {title}

        <IconButton
          onClick={handleClose}
          className={classes.closeIcon}
          size={'small'}
        >
          <CloseIcon/>
        </IconButton>
      </DialogTitle>

      <DialogContent className={classes.dialogRoot}>
        <div className={classes.document}>
          <Document
            file={file}
            error={"error"}
            onLoadSuccess={onDocLoadSuccess}
          >
            <Page
              pageNumber={pageNumber}
              className={classes.pdfViewer}
              scale={scale}
              renderAnnotationLayer={false}
            />
          </Document>

        </div>

        <div className={classes.paginationPanel}>
          <PaginationChevronCountPanel
            pageIndex={pageNumber}
            pageCount={numPages}
            updatePageIndex={updatePage}
          />
        </div>
      </DialogContent>
    </Dialog>
  );
};

DocumentDialog.propTypes = {
  classes: PropTypes.object
};

export default withStyles(DocumentDialogStyle)(DocumentDialog);
