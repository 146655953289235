import {withStyles} from '@material-ui/core';
import Autocomplete from "@material-ui/lab/Autocomplete";
import ElementSettingsGroupLabel from "components/ElementSettingGroupLabel/ElementSettingsGroupLabel";
import StyledTextField from "components/StyledTextField/StyledTextField";
import PropTypes from 'prop-types';
import React, {useEffect, useState} from 'react';
import BackgroundSizeInputStyle from './BackgroundSizeInputStyle';

const BackgroundSizeInput = props => {
  const {
    classes,
    setting,
    onBlur,
    onFocus,
    onChange,
    value,
  } = props;

  const [backgroundSize, setBackgroundSize] = useState('cover');

  const backgroundSizes = [
    'auto',
    'cover',
    'contain',
  ]

  const handleAutoCompleteChange = (e, value, reason) => {
    if(value === null) {
      setBackgroundSize('cover')
      onChange('cover')
    } else {
      setBackgroundSize(value)
      onChange(value)
    }
  }

  useEffect(() => {
    setBackgroundSize(value)
  }, [value])

  return (
    <div className={classes.root}>
      <ElementSettingsGroupLabel setting={setting}>
        Background Size
      </ElementSettingsGroupLabel>

      <div className={classes.autocompleteContainer}>
        <Autocomplete
          freeSolo
          size={'small'}
          value={backgroundSize}
          ListboxProps={{className: classes.listBox}}
          classes={{root: classes.autocompleteRoot}}
          options={
            backgroundSizes.map((option) => option)
          }
          onChange={handleAutoCompleteChange}
          renderInput={(params) => (
            <StyledTextField
              {...params}
              className={classes.input}
              variant="outlined"
              onFocus={onFocus}
              onBlur={onBlur}
            />
          )}
        />
      </div>
    </div>
  );
};

BackgroundSizeInput.propTypes = {
  classes: PropTypes.object,
  onChange: PropTypes.func.isRequired,
  setting: PropTypes.object.isRequired,
  value: PropTypes.string,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
};

export default withStyles(BackgroundSizeInputStyle)(BackgroundSizeInput);
