import React, {createContext, useEffect, useMemo, useState} from 'react';
import icons from './icons';

export const IconContext = createContext();

const IconProvider = ({children}) => {
  const [iconList, setIconList] = useState([])

  const [sortedIconList, setSortedIconList] = useState({
    'rounded': [],
    'sharp': [],
    'twoTone': [],
    'outlined': [],
    'filled': []
  });

  useEffect(() => {
    let newIconList= []
    for (let i of icons) {

      let formattedIcon = i[0];

      formattedIcon.displayName = i[1];

      newIconList.push(formattedIcon)
    }
    setIconList(newIconList)
  }, [])

  useEffect(() => {
    try {
      if(iconList.length > 0) {
        let newSortedIconList = {...sortedIconList};
        let newRoundedIconList = newSortedIconList.rounded;
        let newSharpIconList = newSortedIconList.sharp;
        let newTwoToneIconList = newSortedIconList.twoTone;
        let newOutlinedIconList = newSortedIconList.outlined;
        let newFilledIconList = newSortedIconList.filled;

        for(let icon of iconList){
          if(icon.displayName.includes('Rounded')) {
            newRoundedIconList.push(icon)
          } else if(icon.displayName.includes('Sharp')) {
            newSharpIconList.push(icon)
          } else if(icon.displayName.includes('TwoTone')) {
            newTwoToneIconList.push(icon)
          } else if(icon.displayName.includes('Outlined')) {
            newOutlinedIconList.push(icon)
          } else {
            newFilledIconList.push(icon)
          }
        }

        console.log('new sorted icon list', newSortedIconList)
        setSortedIconList(newSortedIconList);
      }
    }
    catch (e) {
      console.error(e);
    }
  }, [iconList])

  const findIconByDisplayName = (name) => {
    return iconList.find(elem => elem.displayName === name)
  }

  const values = useMemo(() => {
    return {
      iconList,
      roundedIconList: sortedIconList.rounded,
      filledIconList: sortedIconList.filled,
      sharpIconList: sortedIconList.sharp,
      outlinedIconList: sortedIconList.outlined,
      twoToneIconList: sortedIconList.twoTone,
      findIconByDisplayName
    }
  }, [sortedIconList, iconList])

  return (
    <IconContext.Provider value={values}>
      {children}
    </IconContext.Provider>
  );
};

export default IconProvider;
