import {withStyles} from '@material-ui/core';
import Typography from "@material-ui/core/Typography";
import Payment from "components/AccountSettings/ManageSubscription/Payment/Payment";
import Subscription from "components/AccountSettings/ManageSubscription/Subscription/Subscription";
import PropTypes from 'prop-types';
import React from 'react';
import ManageSubscriptionStyle from './ManageSubscriptionStyle';

const ManageSubscription = props => {
  const {
    classes,
    pageClasses,
    style,
  } = props;

  return (
    <div className={classes.root} style={{...style}}>
      <Typography
        variant={'h6'}
        className={pageClasses.pageContentTitle}
      >
        Manage Your Subscription
      </Typography>

      <div className={pageClasses.divider}/>

      <div className={pageClasses.pageContent}>
        <Subscription pageClasses={pageClasses}/>
        <Payment pageClasses={pageClasses}/>
      </div>
    </div>
  );
};

ManageSubscription.propTypes = {
  classes: PropTypes.object,
  pageClasses: PropTypes.object,
  style: PropTypes.object,
};

export default withStyles(ManageSubscriptionStyle)(ManageSubscription);