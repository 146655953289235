import {outlinedPurpleHoverButton} from "assets/theme";
const menuIconWidth = 48;
const NavBarStyle = theme => ({
  root: {
    width: '100%',
    height: '84px',
    display: 'flex',
    flexDirection: 'row',
    backgroundColor: 'transparent',
    position: 'sticky',
    top: '0',
    right: '0',
    padding: '20px 180px',
    zIndex: 1000,

    [theme.breakpoints.down('md')]: {
      padding: "20px 90px"
    },
    [theme.breakpoints.down('sm')]: {
      padding: "20px 45px"
    },
    [theme.breakpoints.down('xs')]: {
      padding: "20px 20px"
    },
  },

  desktop: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',

    [theme.breakpoints.down('sm')]: {
      display: "none",
    },
  },

  logoContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    position: "relative",
    right: `${(menuIconWidth/2)}px`,
    textDecoration: 'none',

    [theme.breakpoints.down('sm')]: {
      width: "90%",
    },
  },

  internalLinksContainer: {
    display: "flex",
    width: "100%",
    justifyContent: "center",
  },

  internalLinks: {
    textDecoration: "none",
    marginRight: "50px",
    color: "white",
    fontFamily: "Roboto",
    fontSize: "16px",
    fontWeight: "500",
    transition: "0.3s",

    '&:hover': {
      color: "#8A56FF",
    },

    [theme.breakpoints.down('sm')]: {
      marginBottom: "20px",
    },
  },

  loginLink: {
    textDecoration: "none",
  },

  login: {
   ...outlinedPurpleHoverButton,
  },

  loginMobile: {
    ...outlinedPurpleHoverButton,
    width: "100%",
  },

  mobile: {
    display: "none",
    width: "100%",

    [theme.breakpoints.down('sm')]: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "center",
    },
  },

  drawerButton: {
    color: "white",
    position: "relative",
    top: "2px",
    width: `${menuIconWidth}px`,
    zIndex: 10000,
    marginRight: 'auto'
  },

  mobileList: {
    display: "flex",
    flexDirection: "column",
    width: "200px",
    marginLeft: "20px",
    marginTop: "20px",
    marginRight: "20px",
  },

  divider: {
    backgroundColor: "white",
    marginBottom: "20px",
    width: "calc(100% + 40px)",
    position: "relative",
    right: "20px",
  },
});

export default NavBarStyle;
