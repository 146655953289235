import {EditorContext} from "editor/Editor";
import {useSerializerUtils} from "editor/serializers/useSerializerUtils";
import {useAssetConfig} from "hooks/useAssetConfig";
import React, {useContext, useEffect, useState} from "react";
import {User} from "services";
import {capitalizeFirstLetter, handleNetworkError} from "utils/utils";

export const usePublicVariants = (selectedAsset) => {

  const {editorReducerState, dispatch} = useContext(EditorContext);

  const {
    recursivelyDeserializeChildren
  } = useSerializerUtils(editorReducerState);

  const currentBreakpoint = editorReducerState.editorState.canvasSettings.currentCanvasBreakpoint;

  const assetConfig = useAssetConfig();

  let [formattedVariants, setFormattedVariants] = useState([]);

  const getComponentSerializer = (tag, elem=null) => {
    console.log('get one of these serializers', Object.keys(assetConfig))
    for(let assetKey of Object.keys(assetConfig)) {
      if(assetKey === tag) {
        return new assetConfig[assetKey].serializer(elem, editorReducerState);
      }
    }
  }

  useEffect(() => {
    if(selectedAsset === 'public') {
      User.listPublicComponents()
        .then(response => {
          for (let variant of response.data.results) {
            let serializer = getComponentSerializer(variant.data.tag, variant.data);

            let elem = serializer.deserialize()

            elem.children = recursivelyDeserializeChildren(variant.data, editorReducerState);

            let formattedVariantName = variant.name;

            // TODO: get icon node based on tag name

            let newFormattedVariants = [];

            newFormattedVariants.push({
              elementToCreate: {
                ...elem,
                props: {
                  ...elem?.props,
                  ...variant.data?.breakpointData?.[currentBreakpoint]
                },
                breakpointData: {...variant.data?.breakpointData},
              },
              id: variant.id,
              image: variant.screenshot,
              variantName: variant.name,
              formattedVariantName: formattedVariantName,
            })

            setFormattedVariants(newFormattedVariants);
          }
        })
        .catch(handleNetworkError)
    }
  }, [selectedAsset])

  if(selectedAsset !== 'public') return null;

  return formattedVariants;
}
