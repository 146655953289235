import {EditorContext} from "editor/Editor";
import React, {useContext, useEffect, useRef, useState} from 'react';

import {
  Tooltip,
  withStyles
} from '@material-ui/core';
import PropTypes from 'prop-types';

import BorderPickerInputStyle from './BorderPickerInputStyle';
import ColorPickerInput from "editor/layout/ElementSettings/Inputs/ColorPickerInput/ColorPickerInput";
import StyledTextField from "components/StyledTextField/StyledTextField";
import MenuItem from "@material-ui/core/MenuItem";
import cn from 'classnames';
import ElementSettingsGroupLabel from "components/ElementSettingGroupLabel/ElementSettingsGroupLabel";

const BorderPickerInput = props => {
  const {
    classes,
    onChange,
    onBlur,
    onFocus,
    value,
    label,
    setting,
    ...rest
  } = props;

  const BORDER_TYPE_DEFAULT = 'solid';
  const BORDER_WIDTH_DEFAULT = '0px';
  const BORDER_COLOR_DEFAULT = '#FFFFFF';

  const [borderType, setBorderType] = useState(BORDER_TYPE_DEFAULT);
  const [borderWidth, setBorderWidth] = useState(BORDER_WIDTH_DEFAULT);
  const [borderColor, setBorderColor] = useState(BORDER_COLOR_DEFAULT);
  const [touched, setTouched] = useState(false);
  const [innerGeneratedCSS, setInnerGeneratedCSS] = useState(null);
  const mounted = useRef(false);

  const editorData = useContext(EditorContext)
  const {editorReducerState, dispatch} = editorData
  const { editorState, editorActions } = editorReducerState;
  const { elementTree } = editorState;
  const elementSelectedId = editorState.elementSelected.value?.id;

  const generateBorderCSS = () => {
    let generatedCSS = `${borderType} ${borderWidth} ${borderColor}`;

    if(generatedCSS !== innerGeneratedCSS) {
      onChange(generatedCSS);
    }

    setInnerGeneratedCSS(generatedCSS);
  }

  useEffect(() => {
    setTouched(false)
  }, [elementSelectedId])

  useEffect(() => {
    if(mounted.current) {
      if(touched) {
        // we have this if statement so we don't submit when values are given
        // i.e. clicked on an asset that already has a border
        generateBorderCSS();
      }
    }
    else {
      mounted.current = true;
    }
  }, [borderWidth, borderColor, borderType, touched]);

  // we need a separate useEffect because this only needs to run on unmount
  useEffect(() => {
    return () => {
      mounted.current = false;
    }
  }, [])

  useEffect(() => {
    if(value) {
      if(value === 'none') {
        setBorderWidth(BORDER_WIDTH_DEFAULT);
        setBorderType(BORDER_TYPE_DEFAULT);
        setBorderColor(BORDER_COLOR_DEFAULT);
      }
      else {
        console.log('set border picker values', value)
        let splitValue = value.split(' ');
        if(splitValue.length === 3) {
          setBorderType(splitValue[0]);
          setBorderWidth(splitValue[1]);
          setBorderColor(splitValue[2]);
        }
      }
    }
  }, [value])

  const areValuesEqualToDefaults = () => {
    return (
      borderType === BORDER_TYPE_DEFAULT
      && borderWidth === BORDER_WIDTH_DEFAULT
      && borderColor === BORDER_COLOR_DEFAULT
    )
  }

  const handleBorderColorChange = (color) => {
    setBorderColor(color);
    setTouched(true);
  }

  const handleBorderTypeChange = e => {
    setBorderType(e.target.value);
    setTouched(true);
  }

  const handleBorderWidthChange = e => {
    setBorderWidth(e.target.value);
    setTouched(true);
  }

  return (
    <div className={classes.root}>
      {label && (
        <ElementSettingsGroupLabel setting={setting}>
          {label}
        </ElementSettingsGroupLabel>
      )}

      <div className={classes.row}>
        <Tooltip
          title={'Border Color'}
          arrow
        >
          <ColorPickerInput
            showTitle={false}
            className={classes.colorPicker}
            value={borderColor}
            onChange={handleBorderColorChange}
            onBlur={onBlur}
            onFocus={onFocus}
            removable
          />
        </Tooltip>

        <StyledTextField
          select
          formControlClassName={classes.input}
          className={classes.borderTypeSelect}
          value={borderType}
          onChange={handleBorderTypeChange}
          onBlur={onBlur}
          onFocus={onFocus}
        >
          <MenuItem value={'solid'}>
            solid
          </MenuItem>
          <MenuItem value={'dashed'}>
            dashed
          </MenuItem>
          <MenuItem value={'double'}>
            double
          </MenuItem>
          <MenuItem value={'dotted'}>
            dotted
          </MenuItem>
          <MenuItem value={'groove'}>
            groove
          </MenuItem>
          <MenuItem value={'ridge'}>
            ridge
          </MenuItem>
          <MenuItem value={'inset'}>
            inset
          </MenuItem>
          <MenuItem value={'outset'}>
            outset
          </MenuItem>
        </StyledTextField>

        <Tooltip
          title={'Border Width'}
          arrow
        >
          <StyledTextField
            value={borderWidth}
            onChange={handleBorderWidthChange}
            onBlur={onBlur}
            onFocus={onFocus}
            formControlClassName={classes.input}
            className={classes.borderWidth}
          />
        </Tooltip>

      </div>
    </div>
  );
};

BorderPickerInput.propTypes = {
  classes: PropTypes.object,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string,
  setting: PropTypes.object.isRequired,
  label: PropTypes.string,
};

export default withStyles(BorderPickerInputStyle)(BorderPickerInput);
