import {loadStripe} from "@stripe/stripe-js";
import React, {createContext, useEffect, useState} from 'react';
import APPLICATION_SETTINGS from "settings";

export const StripeContext = createContext();

const StripeProvider = ({children}) => {
  const [stripePromise, setStripePromise] = useState(null);

  // initialize stripe
  useEffect(() => {
    const stripePromise = loadStripe(
      APPLICATION_SETTINGS.STRIPE_API_KEY,
    );

    setStripePromise(stripePromise);
  }, []);

  const values = {
    stripePromise
  }

  return (
    <StripeContext.Provider value={values}>
      {children}
    </StripeContext.Provider>
  );
};


export default StripeProvider;
