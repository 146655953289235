const ToolboxStyle = theme => ({
  root: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "flex-start",
    // position: "absolute",
    // left: '71px',
    // zIndex: 200
  },

  toolboxRoot: {
    width: '300px',
    display: 'flex',
    padding: '8px',
    height: 'calc(100vh - 55px)',
    justifyContent: 'flex-start',
    flexDirection: 'column',
    alignItems: 'stretch',
    background: 'linear-gradient(to bottom, rgba(35, 42, 49, 0.65), rgba(39, 36, 51, 0.65))',

    '&::-webkit-scrollbar': {
      height: '100px',
      width: '2px',
      position: 'relative',
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: 'white',
    },
    '&::-webkit-scrollbar-track': {
      backgroundColor: '#262626',
      opacity: '0.48'
    },
  },

  draggableComponentsContainer: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    flexDirection: 'column',
    height: 'auto',
    width: '100%',
    position: "relative",
    overflow: "hidden",
    flexGrow: 1,
  },

  active: {
    color: 'white',

    '&:hover': {
      cursor: 'pointer'
    }
  },

  inactive: {
    color: '#C8C8C8',

    '&:hover': {
      cursor: 'pointer'
    }
  },

  draggableComponentsOuterContainer: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'stretch',
    flexDirection: 'column',
    height: '100%',
    margin: '6px',
  },

  draggableComponentsInnerContainer: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    overflowY: "auto",
    borderRadius: "5px",
    backgroundColor: "#181A1E",
    padding: "6px",

    '&::-webkit-scrollbar': {
      height: '2px',
      width: '2px',
      position: 'relative',
      marginLeft: '9.33px',
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: 'white',
    },
    '&::-webkit-scrollbar-track': {
      backgroundColor: '#262626',
      opacity: '0.48'
    },

    '& > *': {
      margin: '6px 6px'
    },
  },

  layersContainer: {
    margin: '6px',
    height: '100%',
    flex: 1,
    display: 'flex',
    position: 'relative',
    flexDirection: 'column',
    justifyContent: 'stretch',
    alignItems: 'stretch',
  },

  layersInnerContainer: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'stretch',
    height: '100%',
    width: '100%',
    position: 'relative',
    overflowY: 'auto'
  },

  toolboxIcon: {
    color: 'white',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },

  title: {
    marginBottom: '16px',
    marginLeft: '6px'
  },

  subtitle: {
    marginBottom: '10px'
  },

  toolboxHeaderContainer: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "flex-start",
  },

  faIcons: {
    //  these are font awesome icons - mui icons have default font size applied to their style
    //  mui font size is 2.1875
    fontSize: "2.1875rem"
  },

  iconAsset: {
    fontSize: "1.75rem"
  },

  innerCollapse: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "center",
  },

  collapse: {

  }
});

export default ToolboxStyle;
