const TextDraggableStyle = {
  text: {
    display: 'inline',
    margin: '0',
  },

  editable: {
    border: 'none',
    fontFamily: 'Roboto',
    margin: '0',
    color: 'white',
    minWidth: '15px',
    minHeight: '15px',
  }
};

export default TextDraggableStyle;
