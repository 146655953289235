class BaseComponentSerializer {
  // just a collection of functions shared by all/most serializers

  constructor(elem, editorData) {
    this.elem = elem;
    this.editorData = editorData;
    this.propsToRemove = [];
  }

  formatProps(props) {
    let result = {};
    for(let key of Object.keys(props)) {
      if(!this.propsToRemove.includes(key)) {
        result[key] = props[key]
      }
    }

    return result
  }

  // getElementStyles() {
  //   const {getElementFromTree} = this.editorData.editorState.elementTree.actions;
  //   const domElement = getElementFromTree(this.elem.id);
  //
  //   console.log('dom elem', domElement)
  //
  //   return domElement.style;
  // }

  getDraggablePosition() {
    // pull out necessary functions
    const {getElementFromTree} = this.editorData.editorState.elementTree.actions;
    const domElement = getElementFromTree(this.elem.id);

    if(domElement?.style?.position && domElement?.style?.left && domElement?.style?.top) {
      return {
        position: domElement.style.position,
        left: domElement.style.left,
        top: domElement.style.top
      }
    }

    return {};
  }
}

export default BaseComponentSerializer;
