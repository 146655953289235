import {IconButton, withStyles} from '@material-ui/core';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import PopperDropDownMenu from "components/PopperDropDownMenu/PopperDropDownMenu";
import UserMenuStyle from 'components/UserMenu/UserMenuStyle';
import {UserContext} from "context/UserContext";
import {useEditorDialog} from "hooks/useEditorDialog";
import PropTypes from 'prop-types';
import React, {useContext, useEffect, useRef, useState} from 'react';
import {useHistory} from "react-router-dom";
import Cookies from "universal-cookie";

const UserMenu = props => {
  const {
    classes,
    inEditor,
    ...rest
  } = props;

  const [userMenuAnchorEl, setUserMenuAnchorEl] = useState(null);
  const {userData, setUserData} = useContext(UserContext);
  const wrapperRef = useRef(null)
  const history = useHistory()
  const accountSettingDialog = useEditorDialog('accountSettings')

  const handleOpen = (e) => {
    setUserMenuAnchorEl(e.currentTarget)
  }

  const handleClose = () => {
    setUserMenuAnchorEl(null)
  }

  const handleSignOut = () => {
    setUserData(null)
    history.replace('/')
    const cookies = new Cookies()
    cookies.remove('token', {path: "/"})
  }

  const handleMyAccount = () => {
    if(inEditor) {
      accountSettingDialog.openDialog()
    } else {
      history.push("/account")
    }
  }

  const userMenuActions = [
    {
      title: userData?.name,
      disableHighlight: true,
    },
    {
      title: "My Account",
      action: handleMyAccount,
    },
    {
      title: "Sign Out",
      action: handleSignOut,
    },
  ]

  const handleClick = (e) => {
    if (wrapperRef.current && !wrapperRef.current.contains(e.target)) {
      handleClose()
    }
  }

  useEffect(() => {
    window.addEventListener("click", handleClick);
    return () => window.removeEventListener("click", handleClick);
  }, []);

  return (
    <>
      <div className={classes.userMenuContainer} ref={wrapperRef}>
        <IconButton
          onClick={(e) => {handleOpen(e)}}
          className={classes.iconButton}
        >
          <AccountCircleIcon className={classes.icon}/>
        </IconButton>

        <PopperDropDownMenu
          anchorEl={userMenuAnchorEl}
          open={Boolean(userMenuAnchorEl)}
          disablePortal
          getContentAnchorEl={null}
          placement={"bottom"}
          actions={userMenuActions}
        />
      </div>
    </>
  );
};

UserMenu.propTypes = {
  classes: PropTypes.object,
  inEditor: PropTypes.bool,
};

export default withStyles(UserMenuStyle)(UserMenu);
