const MediaDraggableStyle = theme => ({
  selectedImage: (props) => ({
    height: "100%",
    width: "100%",
    cursor: props.cursor,
  }),

  root: props => ({
    width: '300px',
    height: '300px',

    '& img': {
      width: '100%',
      height: '100%',
    },

    '&:hover': {
      'background': props.hoverColor,
    },
  })
});

export default MediaDraggableStyle;
