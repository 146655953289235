import CheckboxEmail from "components/CheckboxEmail/CheckboxEmail";
import CheckboxTOSAndPP from "components/CheckboxTOSAndPP/CheckboxTOSAndPP";
import {useGlobalDialog} from "hooks/useGlobalDialog";
import React, {useContext, useState} from 'react';
import {Button, TextField, Typography, withStyles} from "@material-ui/core";
import CreateAccountFormStyle from "components/CreateAccountForm/CreateAccountFormStyle";
import googleIcon from "assets/googleIcon.svg";
import {Link, useHistory} from "react-router-dom";
import {Form, Formik} from "formik";
import Cookies from "universal-cookie";
import {handleNetworkError} from "utils/utils";
import * as yup from "yup";
import {User} from "services";
import {UserContext} from "context/UserContext";
import GoogleLogin from "react-google-login";
import PropTypes from 'prop-types';


const CreateAccountForm = props => {
  const {
    classes,
    inEditor,
    switchDialogPages,
    onSuccess=null
  } = props;

  const {userData, setUserData} = useContext(UserContext)
  const history = useHistory()
  const [error, setError] = useState("")
  const [busy, setBusy] = useState(false)

  const [termsChecked, setTermsChecked] = useState(false)
  const [emailChecked, setEmailChecked] = useState(true)

  // whether or not to highlight the terms checkbox text red to indicate the user
  // needs to accept it to advance
  const [termsError, setTermsError] = useState(false);

  const schema = yup.object().shape({
    name: yup.string()
      .required('Name is a required field'),
    email: yup.string()
      .email('Must be a valid email')
      .required('Email is a required field'),
    password: yup.string()
      .min(8, 'Password must be at least ${min} characters')
      .required('Password is a required field'),
    confirmPassword: yup.string()
      .min(8, 'Password must be at least ${min} characters')
      .oneOf([yup.ref('password'), null], 'Passwords must match')
      .required('Confirm password is a required field'),
  });

  const handleLoginSuccess = (userData) => {
    setUserData(userData.user)
    const cookies = new Cookies();
    cookies.set('token', userData.user.token, {path: '/'})
    console.log("response", userData.user)

    if(onSuccess) {
      onSuccess();
    }
    else {
      history.push('/dashboard')
    }
  }

  const onSubmit = async (values, {setErrors}) => {
    console.log("values", values)

    if(!termsChecked) {
      setTermsError(true);
      return;
    }

    setErrors({});
    setError('');

    let data = {...values};
    delete data.confirmPassword;

    setBusy(true)
    User.register({...values, shouldNotify: emailChecked})
      .then(response => {
        handleLoginSuccess(response.data)
        console.log('response', response.data)
      })
      .catch(error => {
        if(error?.response?.data && !error?.response?.data?.non_field_errors) {
          setErrors(error.response.data)
        }
        else {
          let errorMsg = handleNetworkError(error, true, false);
          setError(errorMsg);
        }
      })
      .finally(() => {
        setBusy(false)
      })
  };

  const googleResponseSuccess = (response) => {
    console.log("response", response)

    if(!termsChecked) {
      setTermsError(true);
      return;
    }

    let dataToPost = {
      name: response.profileObj.name,
      email: response.profileObj.email,
      googleId: response.profileObj.googleId,
      googleIdToken: response.tokenId
    }

    console.log('post to django', dataToPost)

    setBusy(true)
    User.register(dataToPost)
      .then(response => {
        console.log('google user success!', response);
        handleLoginSuccess(response.data)
      })
      .catch(error => {
        let errorMsg = handleNetworkError(error, true, false);
        setError(errorMsg);
      })
      .finally(() => {
        setBusy(false)
      })
  }

  const googleResponseFailure = (error) => {
    console.error('error', error);
  }

  return (
    <div className={classes.root}>
      <div className={classes.title}>
        <Typography variant="h2">
          Join <span className={classes.purpleText}>Nimbus</span> for free
        </Typography>
      </div>

      {error && (
        <Typography className={classes.errorText}>
          {error}
        </Typography>
      )}

      <Formik
        initialValues={{
          name: "",
          email: "",
          password: "",
          confirmPassword: "",
        }}
        onSubmit={onSubmit}
        validationSchema={schema}
        validateOnChange={false}
      >
        {({
            values,
            errors,
            handleChange
          }) => (
          <Form>
            <div className={classes.formContainer}>

              {/*<GoogleLogin*/}
              {/*  clientId="528004528681-acj316o3ql8vbvn32vamt9t54cqdhgs7.apps.googleusercontent.com"*/}
              {/*  buttonText="Sign up with Google"*/}
              {/*  onSuccess={googleResponseSuccess}*/}
              {/*  onFailure={googleResponseFailure}*/}
              {/*  cookiePolicy={'single_host_origin'}*/}
              {/*  render={renderProps => (*/}
              {/*    <Button*/}
              {/*      onClick={() => {*/}
              {/*        if(!termsChecked) {*/}
              {/*          setTermsError(true);*/}
              {/*        }*/}
              {/*        else {*/}
              {/*          renderProps.onClick();*/}
              {/*          setTermsError(false);*/}
              {/*        }*/}
              {/*      }}*/}
              {/*      disabled={renderProps.disabled || busy}*/}
              {/*      className={classes.googleAccountButton}*/}
              {/*      variant="outlined"*/}
              {/*    >*/}
              {/*      <img src={googleIcon} className={classes.googleIcon}/>*/}
              {/*      Sign up with Google*/}
              {/*    </Button>*/}
              {/*  )}*/}
              {/*/>*/}

              {/*<div>*/}
              {/*  <Typography variant="body1">*/}
              {/*    or*/}
              {/*  </Typography>*/}
              {/*</div>*/}
              <div className={classes.formNameContainer}>
                <TextField
                  className={classes.formInput}
                  placeholder="Name"
                  name="name"
                  error={!!errors.name}
                  helperText={errors.name}
                  onChange={handleChange}
                  value={values.name}
                  variant="outlined"
                  fullWidth
                  InputProps={{
                    classes: {input: classes.input},
                  }}
                />
              </div>
              <div className={classes.formInputContainer}>
                <TextField
                  className={classes.formInput}
                  placeholder="Email"
                  name="email"
                  error={!!errors.email}
                  helperText={errors.email}
                  onChange={handleChange}
                  value={values.email}
                  variant="outlined"
                  fullWidth
                  InputProps={{
                    classes: {input: classes.input},
                  }}
                />
              </div>
              <div className={classes.formInputContainer}>
                <TextField
                  className={classes.formInput}
                  placeholder="Password"
                  name="password"
                  type="password"
                  error={!!errors.password}
                  helperText={errors.password}
                  value={values.password}
                  onChange={handleChange}
                  variant="outlined"
                  fullWidth
                  InputProps={{
                    classes: {input: classes.input},
                  }}
                />
              </div>
              <div className={classes.formInputContainer}>
                <TextField
                  className={classes.formInput}
                  placeholder="Retype Password"
                  name="confirmPassword"
                  type="password"
                  error={!!errors.confirmPassword}
                  helperText={errors.confirmPassword}
                  value={values.confirmPassword}
                  onChange={handleChange}
                  variant="outlined"
                  fullWidth
                  InputProps={{
                    classes: {input: classes.input},
                  }}
                />
              </div>

              <CheckboxTOSAndPP
                checked={termsChecked}
                setChecked={setTermsChecked}
                error={termsError}
              />

              <CheckboxEmail
                checked={emailChecked}
                setChecked={setEmailChecked}
              />

              <Button
                variant="outlined"
                size="medium"
                disabled={busy}
                className={classes.createAccountButton}
                type="submit"
              >
                Create account
              </Button>
            </div>

          </Form>
        )}
      </Formik>

      <div className={classes.login}>
        <Typography variant="body1">
          Already have an account?
          {' '}

          {inEditor ? (
            <span onClick={() => switchDialogPages('login')} className={classes.purpleText}>
              Login
            </span>
          ) : (
            <Link to="/login" className={classes.purpleText}>
              Login
            </Link>
          )}
        </Typography>
      </div>

    </div>
  );
};

CreateAccountForm.propTypes = {
  classes: PropTypes.object,
};

export default withStyles(CreateAccountFormStyle)(CreateAccountForm);
