const TableDraggableStyle = theme => ({
  tableStyle: (props) => ({
    'background': props.tableBackground,
  }),
  headerStyle: (props) => ({
    'color': props.headerColor,
    'background':props.headerBackground,
    'border': props.headerBorder,
    'borderLeft': props.headerBorderLeft,
    'borderRight': props.headerBorderRight,
    'borderTop': props.headerBorderTop,
    'borderBottom': props.headerBorderBottom,
    // 'fontFamily': props.headerFontFamily,
    // 'fontWeight': props.headerFontWeight,
    // 'fontSize': props.headerFontSize,
  }),
  cellStyle: (props) => ({
    'border': props.cellBorder,
  }),
});

export default TableDraggableStyle;