const BoxShadowInputStyle = theme => ({
  root: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    justifyContent: 'flex-start',
    alignItems: 'center',
    marginTop: "10px"
  },

  colorPicker: {
    position: 'relative',
    bottom: '2px',
    width: 'auto',
    marginRight: '4px'
  },

});

export default BoxShadowInputStyle;