import {EditorContext} from "editor/Editor";
import {useAssetConfig} from "hooks/useAssetConfig";
import html2canvas from "html2canvas";
import {useContext} from "react";
import APPLICATION_SETTINGS from "settings";

export const useSerializerUtils = (editorReducerState) => {
  const assetConfig = useAssetConfig();

  const getComponentSerializer = (tag, elem=null) => {
    console.log('editorReducerState serializer utils', editorReducerState)

    console.log('get one of these serializers', Object.keys(assetConfig))
    for(let assetKey of Object.keys(assetConfig)) {
      if(assetKey === tag) {
        return new assetConfig[assetKey].serializer(elem, editorReducerState);
      }
    }
  }

  const takeScreenshotOfComponentAsync = async (elementId) => {
    console.log('editorReducerState serializer utils', {editorReducerState, elementId})

    //let editorNode = editorReducerState.editorState.editorNode;
    let elementNode = editorReducerState.editorState.elementTree.actions.getElementFromTree(elementId);

    let innerElemNode = elementNode.querySelector(`[data-editorid='${elementId}']`)

    if(innerElemNode) {
      elementNode = innerElemNode;
    }

    let elementNodeBounds = elementNode.getBoundingClientRect();

    console.log('element node bounds', elementNodeBounds)

    let zoomLevel = elementNodeBounds.width / elementNode.offsetWidth;

    let calculatedWidth = elementNodeBounds.width * (1/zoomLevel);
    let calculatedHeight = elementNodeBounds.height * (1/zoomLevel);

    console.log('component screenshot data', {calculatedHeight, calculatedWidth, elementNode, zoomLevel, test: 1/zoomLevel})

    //editorNode.style.fontFeatureSettings = '"liga" 0';

    let reactRoot = document.getElementById('root');

    let clonedElem =
      elementNode.cloneNode(true);

    let clonedElemContainer = document.createElement('div');
    clonedElemContainer.appendChild(clonedElem);

    clonedElemContainer.style.backgroundColor = editorReducerState.editorState.canvasSettings.currentCanvasBackgroundColor;
    clonedElemContainer.style.position = 'absolute';
    clonedElemContainer.style.top = '100000px'
    clonedElemContainer.style.width = calculatedWidth + 'px';
    clonedElemContainer.style.height = calculatedHeight + 'px';
    clonedElemContainer.id = 'cloned-elem-screenshot';
    //clonedElemContainer.style.transform = `scale(${1/zoomLevel})`;

    // this stops the container from freaking out when we create the copied zoom container
    let previousRootOverflow = reactRoot.style.overflow;
    let previousHtmlOverflow = document.documentElement.style.overflow;

    document.documentElement.style.overflow = 'hidden';
    reactRoot.style.overflow = 'hidden';

    reactRoot.appendChild(clonedElemContainer);

    let canvas = (await html2canvas(clonedElemContainer, {
      width: calculatedWidth,
      height: calculatedHeight,
      scale: (1/zoomLevel),
      useCORS: false,
      allowTaint: false,
      proxy: `${APPLICATION_SETTINGS.DJANGO_API_URL}/image-proxy/`
    }))

    let dataUrl = canvas.toDataURL('image/jpeg', 0.75);

    clonedElem.remove();
    clonedElemContainer.remove();

    reactRoot.style.overflow = previousRootOverflow;
    document.documentElement.style.overflow = previousHtmlOverflow;

    return dataUrl;
  }

  const recursivelyDeserializeChildren = (elem) => {
    let returnData = [];

    if(!elem.children || elem.children.length === 0) {
      return [];
    }

    for(let i of elem.children) {
      let serializer = getComponentSerializer(i.tag, i);
      let componentData = serializer.deserialize();

      // if the child element (i) has children, do this again
      let deserializedChildren = [];
      if(i.children && i.children.length > 0) {
        deserializedChildren = recursivelyDeserializeChildren(i);
      }

      returnData.push({
        ...i,
        ...componentData,
        props: {
          ...i.props,
          ...componentData.props
        },
        children: deserializedChildren
      });
    }

    return returnData;
  }

  const recursivelySerializeChildren = (elem) => {
    console.log('editorReducerState serializer utils', editorReducerState)
    let serializedChildren = [];

    if(!elem.children || elem.children.length === 0) {
      return [];
    }

    for(let i of elem.children) {
      let childSerializer = getComponentSerializer(i.tag.serializedName, i);
      let serializedChild = childSerializer.serialize();

      // if the child element (i) has children, do this again
      if(i.children && i.children.length > 0) {
        let serializedChildren = recursivelySerializeChildren(i);
        serializedChild.children = serializedChildren;
      }
      else {
        serializedChild.children = [];
      }

      serializedChildren.push(serializedChild);
      console.log('serialized child', serializedChild);
    }

    return serializedChildren;
  }

  return {
    takeScreenshotOfComponentAsync,
    recursivelySerializeChildren,
    recursivelyDeserializeChildren,
    getComponentSerializer
  }
}
