const ElementSettingsStyle = theme => ({
  root: {
    height: 'calc(100vh - 55px)',
    width: '310px',
    padding: '16px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'center',
    // background: 'linear-gradient(to top, rgba(35, 42, 49, 0.65), rgba(39, 36, 51, 0.65))',
    background: 'linear-gradient(to bottom, rgba(35, 42, 49, 0.65), rgba(39, 36, 51, 0.65))',
    overflowY: 'auto',
    position: 'relative',
    zIndex: 100,

    '&::-webkit-scrollbar': {
      height: '100px',
      width: '2px',
      position: 'relative',
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: 'white',
    },
    '&::-webkit-scrollbar-track': {
      backgroundColor: '#262626',
      opacity: '0.48'
    },
  },

  settingsWrapper: {
    flex: 1,
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
  },

  tabs: {
    width: '100%',

    '& .MuiTab-root': {
      minWidth: '139px'
    }
  },

  form: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'center',
    width: '100%',
    // marginTop: '10px',
    //have this commented out because editor switch div
    //is commented out

    '& > div': {
      margin: '7px 0'
    }
  },

  editorSwitch: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    width: '100%',
    padding: '6px 10px',

    '& > h4': {
      fontSize: '15px',
      fontWeight: '500',
      fontFamily: 'Roboto',

      '&:first-of-type': {
        marginRight: '16px'
      }
    },
  },

  activeEditorText: {
    color: 'white'
  },

  inactiveEditorText: {
    color: theme.palette.text.disabled
  }
});

export default ElementSettingsStyle;
