import {Grid, Typography, withStyles} from '@material-ui/core';
import {ChevronLeft, ChevronRight} from '@material-ui/icons';
import React from 'react';

import PaginationChevronCountPanelStyle from './PaginationChevronCountPanelStyle';

const PaginationChevronCountPanel = ({pageIndex, pageCount, updatePageIndex, classes}) => {

  return (
    <Grid container item xs={12} md={6} lg={8}>
      <Grid
        container
        item
        xs
        className={classes.arrowContainer}
        direction="row"
        alignItems="center"
        justify="center"
        onClick={() => updatePageIndex(false)}
      >
        <ChevronLeft/>
      </Grid>
      <Grid
        container
        item
        xs={6}
        className={classes.container}
        direction="row"
        alignItems="center"
        justify="center"
      >
        <Typography className={classes.text}>
          {`Page ${pageIndex} of ${pageCount}`}
        </Typography>
      </Grid>
      <Grid
        container
        item
        xs
        className={classes.arrowContainer}
        direction="row"
        alignItems="center"
        justify="center"
        onClick={() => updatePageIndex(true)}
      >
        <ChevronRight/>
      </Grid>
    </Grid>
  )
};

export default withStyles(PaginationChevronCountPanelStyle)(PaginationChevronCountPanel);
