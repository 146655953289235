import {withStyles} from '@material-ui/core';
import {MuiTheme as theme} from "assets/theme";

import ResizeableElement from 'editor/components/ResizeableElement/ResizeableElement';
import {EditorHotkeysContext} from "editor/context/EditorHotkeysProvider";
import DraggableWrapperStyle from 'editor/draggables/DraggableWrapper/DraggableWrapperStyle';
import {EditorContext} from 'editor/Editor';
import PropTypes from 'prop-types';
import React, {useContext, useEffect, useRef, useState} from 'react';
import {DraggableCore} from 'react-draggable';
import {getElementsAtClientCoordinates, isFunction} from 'utils/utils';
import cn from 'classnames';

import {getTopmostEditorElementFromCoordinates} from "../../../lib/draggables/draggables";

const DraggableWrapper = props => {
  const {
    children,
    classes,
    editorId,
    testId,
    onClick,
    onStart,
    onStop,
    onDrag,
    canBeDraggedOutOfEditor,
    absolutePositioning,
    doesNotRenderGridLines,
    resizeable,
    resizeableElementProps,
    style,
    disabled,
    isChildElem,
    editorDraggable // whether or not to treat this element like an editor element
  } = props;

  const elemRef = useRef(null);
  const editorData = useContext(EditorContext);
  const {editorReducerState, dispatch} = editorData;
  const {editorState, editorActions, editorSettings} = editorReducerState;
  const {zoomLevel} = editorSettings;
  const {elementTree} = editorState;

  const elementSelected = editorState.elementSelected.value;

  const {
    ctrlKeyPressed
  } = useContext(EditorHotkeysContext);

  const {measureElementBounds} = editorActions;
  const treeActions = editorState.elementTree.actions;
  const [isBeingDragged, setIsBeingDragged] = useState(false);
  const [isBeingResized, setIsBeingResized] = useState(false);
  const [snapVertical, setSnapVertical] = useState(false);
  const [snapHorizontal, setSnapHorizontal] = useState(false);
  const [isSelected, setIsSelected] = useState(false);
  const [isDragDisabled, setIsDragDisabled] = useState(false);

  const [currentSize, setCurrentSize] = useState({
    width: null,
    height: null
  })

  const dragStartBounds = useRef(null);
  const dragEndBounds = useRef(null);

  const rootTreeElem = useRef(elementTree.value['editor-root']);
  const phantomDragRef = useRef(null);
  const indicatorElems = useRef([]);

  const checkIfCenteredVerticallyOrHorizontally = (node) => {
    let scaledWidth = (node.clientWidth * zoomLevel);
    let scaledHeight = (node.clientHeight * zoomLevel);

    // get center of the draggable
    let draggableCenter = {
      x: node.offsetLeft + ((scaledWidth / 2) * (1/zoomLevel)),
      y: node.offsetTop + ((scaledHeight / 2) * (1/zoomLevel)),
    };
    const editorVerticalWithOffset = editorState.editorVerticalCenterWithOffset;
    const editorHorizontalWithOffset = editorState.editorHorizontalCenterWithOffset;

    let shouldRenderVertical =
      (editorVerticalWithOffset.left < draggableCenter.x) && (draggableCenter.x < editorVerticalWithOffset.right);
    let shouldRenderHorizontal =
      (editorHorizontalWithOffset.top < draggableCenter.y) && (draggableCenter.y < editorHorizontalWithOffset.bottom);

    editorActions.renderGridLine(
      'vertical',
      shouldRenderVertical
    );

    editorActions.renderGridLine(
      'horizontal',
      shouldRenderHorizontal
    );

    // handle "snapping" to grid
    if (ctrlKeyPressed && shouldRenderVertical)
      snapToGridLine('vertical', node);
    if (ctrlKeyPressed && shouldRenderHorizontal)
      snapToGridLine('horizontal', node)
  };

  const snapToGridLine = (lineType, node) => {
    /**
     * @param {string} lineType - "horizontal" or "vertical"
     */
    let scaledWidth = (node.clientWidth * zoomLevel);
    let scaledHeight = (node.clientHeight * zoomLevel);

    if (lineType === 'vertical') {
      setSnapVertical(true)
      node.style.left = `${editorState.editorVerticalCenter - ((scaledWidth / 2) * (1/zoomLevel))}px`
    } else if (lineType === 'horizontal') {
      setSnapHorizontal(true)
      node.style.top = `${editorState.editorHorizontalCenter - ((scaledHeight / 2) * (1/zoomLevel))}px`
    }
  }

  const unrenderGridLines = () => {
    editorActions.renderGridLine(
      'vertical',
      false
    );

    editorActions.renderGridLine(
      'horizontal',
      false
    );
  };

  const insertSelfIntoElement = (parentElem, isEditorRoot=false) => {
    // then get it's editor id
    let topMostElemEditorId;

    if(!isEditorRoot) {
      topMostElemEditorId = treeActions.getNodeEditorId(parentElem);
    }
    else {
      topMostElemEditorId = 'editor-root'
    }

    console.log('insert self into element', {props, parentElem, elementTree})

    dispatch({
      type: 'insertAsset',
      payload: {
        toBeInsertedEditorId: editorId,
        parentEditorId: topMostElemEditorId
      }
    });
  }

  const updateSelfInTree = (widthHasChanged=true, heightHasChanged=true) => {
    if(elementSelected === editorId) {
      let measuredBounds = measureElementBounds(editorId, true);

      dispatch({
        type: 'updateElementSelectedBounds',
        payload: {
          bounds: measuredBounds
        }
      });

      dispatch({
        type: 'updateAssetBounds',
        payload: {
          editorId,
          heightHasChanged,
          widthHasChanged,
          bounds: {
            ...measuredBounds,
          },
        }
      })
      //setElementSelectedBounds(measuredBounds);
    }
  }

  const determineIfSelfMoved = () => {
    // compares dragStartBounds.current and dragEndBounds.current
    let deltaX = Math.abs(dragStartBounds.current.left - dragEndBounds.current.left);
    let deltaY = Math.abs(dragStartBounds.current.top - dragEndBounds.current.top);

    // I know this can be slightly simplified but this is intentional for debugging
    if(deltaX >= 16 || deltaY >= 16) {
      return true;
    }
    else {
      return false;
    }
  }

  const createIndicator = (elemsAtPoint) => {
    for(let elem of elemsAtPoint) {
      let elemEditorId = elem.getAttribute('data-editorid');

      if(elemEditorId && elemEditorId !== editorId) {

        let foundElem = treeActions.getElementFromTree(elemEditorId);

        if(elemRef.current.contains(foundElem)) {
          continue;
        }

        let innerElem = foundElem.querySelector(`[data-editorid='${elemEditorId}'][data-container='true']`)

        console.log('inner elem', innerElem)

        if(innerElem) foundElem = innerElem;

        console.log('found elem', foundElem);

        let rect = foundElem.getBoundingClientRect();

        let indicatorDiv = document.createElement('div');

        indicatorDiv.style.border = `solid 2px ${theme.palette.gridLines.main}`;
        indicatorDiv.style.margin = '10px';
        indicatorDiv.style.position = 'absolute';
        indicatorDiv.style.zIndex = '500';
        indicatorDiv.style.top = `${foundElem.offsetTop}px`;
        indicatorDiv.style.left = `${foundElem.offsetLeft}px`;
        indicatorDiv.style.width = `${(rect.width * (1/zoomLevel)) - 20}px`;
        indicatorDiv.style.height = `${(rect.height * (1/zoomLevel)) - 20}px`;

        foundElem.after(indicatorDiv);

        for(let elem of indicatorElems.current) {
          elem.remove();
          indicatorElems.current.splice(indicatorElems.current.indexOf(elem), 1);
        }

        indicatorElems.current.push(indicatorDiv);

        break;
      }
    }
  }

  const handleDragStart = (eventHandler, data) => {
    /**
     * @param {EventHandler} eventHandler - An EventHandler from https://www.npmjs.com/package/react-draggable
     * @param {Object} data - data from https://www.npmjs.com/package/react-draggable
     */

    // determine if it was a click or a drag, by default everything is considered a "drag" here
    // we need to write this like this because getBoundingClientRect() doesn't return a normal
    // js object
    const {top, right, bottom, left, width, height, x, y} = data.node.getBoundingClientRect();
    const bounds = {top, right, bottom, left, width, height, x, y};


    dragStartBounds.current = {...bounds};

    setIsBeingDragged(true);

    if (isFunction(onStart)) {
      onStart(eventHandler, data);
    }

  };

  const handleDragStop = (eventHandler, data) => {
    /**
     * @param {EventHandler} eventHandler - An EventHandler from https://www.npmjs.com/package/react-draggable
     * @param {Object} data - data from https://www.npmjs.com/package/react-draggable
     */

    // we need to write it this way instead of using the spread operator
    // because this isn't a normal object
    const {top, right, bottom, left, width, height, x, y} = data.node.getBoundingClientRect();

    if(phantomDragRef.current) {
      phantomDragRef.current.remove();
      phantomDragRef.current = null;

      data.node.style.opacity = 1;
      data.node.style.position = 'static';
      data.node.style.top = dragStartBounds.current.top + 'px';
      data.node.style.left = dragStartBounds.current.left + 'px';
    }

    for(let elem of indicatorElems.current) {
      elem.remove();
    }

    dragEndBounds.current = {top, right, bottom, left, width, height, x, y};

    // we need to figure out if the element is actually being dragged.
    // isBeingDragged doesn't really work here because it always gets set
    // to true even if you just click
    // we need to compare the starting position with the current position
    let hasElementMoved = determineIfSelfMoved()

    if (editorDraggable && hasElementMoved && !absolutePositioning) {
      let parentTreeElem = treeActions.findParentInTree(editorId);

      // TODO: remove these or refactor them better

      // if the element's parent is the editor

      let topMostElem =
        getTopmostEditorElementFromCoordinates(
          eventHandler.clientX,
          eventHandler.clientY,
          elemRef.current,
          true
        );

      // if dropped on another editor element
      if (topMostElem) {
        let topMostElemEditorId = treeActions.getNodeEditorId(topMostElem);
        let searchResult = treeActions.findElementInTree(topMostElemEditorId, rootTreeElem.current);

        // TODO: maybe make this either a function or a variable that initializes on load?
        let selfTreeElem = treeActions.findElementInTree(editorId, rootTreeElem.current);

        // check that topMostElem is not a child
        // we need this to be recursive so it checks every level
        let isChild = treeActions.isChildTreeElement(searchResult, selfTreeElem);

        if (!isChild) {
          insertSelfIntoElement(topMostElem);
        }
      }
      else {
        if(isChildElem) {
          let editorRootElem = treeActions.findElementInTree('editor-root', rootTreeElem.current);
          insertSelfIntoElement(editorRootElem, true);
        }
      }


      if(dragStartBounds.current && dragEndBounds.current) {
        if (Math.abs(dragStartBounds.current.x - dragEndBounds.current.x) > 25
          || Math.abs(dragStartBounds.current.y - dragEndBounds.current.y) > 25) {
          updateSelfInTree();
        }
      }
    }

    setIsBeingDragged(false);

    unrenderGridLines();

    if (isFunction(onStop)) {
      onStop(eventHandler, data);
    }
  };

  // the "phantom" is a ghost version of the asset that lets users still drag and drop stuff
  // around without modifying the position of the actual asset itself
  // you can drag the phantom around and insert it in a container, and the asset will insert too
  // but if you don't insert it in a container, it will just be a ghost and disappear when you let go
  const handlePhantomDrag = (eventHandler, data) => {
    const node = data.node;

    if (!phantomDragRef.current) {
      let clonedNode = node.cloneNode(true);

      let rect = node.getBoundingClientRect();

      clonedNode.style.position = 'static';
      clonedNode.style.top = `${node.offsetTop}px`;
      clonedNode.style.left = `${node.offsetLeft}px`;

      node.after(clonedNode);
      phantomDragRef.current = clonedNode;
    }

    let modifiedData = {...data};

    node.style.opacity = 0.55;

    handleDragging(eventHandler, modifiedData, true);
  }

  const handleDragging = (eventHandler, data, phantomDragOverride=false) => {
    console.log('handleDragging', data, phantomDragOverride);
    const node = data.node;

    if ((isDragDisabled || !absolutePositioning) && !phantomDragOverride) {
      let nodeEditorId = node.getAttribute('data-editorid');
      console.log('phantom drag', nodeEditorId)

      if(nodeEditorId === elementSelected) {
        handlePhantomDrag(eventHandler, data);
      }
      else {
        dispatch({
          type: 'selectAssetById',
          payload: {
            editorId:
            nodeEditorId
          }
        });

        handlePhantomDrag(eventHandler, data);
      }
      return;
    }

    if(absolutePositioning || phantomDragOverride) {
      node.style.position = 'absolute';
      node.style.zIndex = 400;
    }


    // this is the function that animates the actual dragging of the object and applies the correct styles
    //node.style.transform = `translate(${data.lastX}, ${data.lastY})`

    let scaledWidth = (node.clientWidth * zoomLevel);
    let scaledHeight = (node.clientHeight * zoomLevel);

    let calculatedBounds = editorDraggable ?
      {
        // 1/zoomLevel compensates for zooming making things move slower/faster
        left: (data.x - (scaledWidth / 2)) * (1/zoomLevel),
        right: (data.x + (scaledWidth / 2)) * (1/zoomLevel),
        top: (data.y - (scaledHeight / 2)) * (1/zoomLevel),
        bottom: (data.y + (scaledHeight / 2)) * (1/zoomLevel)
      }
      :
      {
        left: data.x - (node.clientWidth / 2),
        right: data.x + (node.clientWidth / 2),
        top: data.y - (node.clientHeight / 2),
        bottom: data.y + (node.clientHeight / 2)
      }

    // if(editorDraggable) {
    //   let parentTreeElem = treeActions.findParentInTree(editorId);
    //
    //   if (!treeActions.isEditorRoot(parentTreeElem)) return;
    // }
      // get width and height of the node so we can offset by 50%

    let zoomContainer = document.getElementById('zoom-container');
    let zoomContainerRect = zoomContainer.getBoundingClientRect();

    let editorBounds = {
      left: editorState.editorBounds.left,
      right: editorState.editorBounds.right,
      top: editorState.editorBounds.top,
      bottom: editorState.editorBounds.bottom
    };

    let isNodeInBounds = {
      left: (calculatedBounds.left > editorBounds.left) || !!canBeDraggedOutOfEditor,
      right: (calculatedBounds.right < editorBounds.right) || !!canBeDraggedOutOfEditor,
      top: (calculatedBounds.top > editorBounds.top) || !!canBeDraggedOutOfEditor,
      bottom: (calculatedBounds.bottom < editorBounds.bottom) || !!canBeDraggedOutOfEditor
    }

    // render grid lines when hovering over the centers
    if (!doesNotRenderGridLines) {
      checkIfCenteredVerticallyOrHorizontally(node);
    }

    // find any containers below where the node is being dragged
    // and apply an indicator style to them
    if(!absolutePositioning) {
      let elemsAtPoint = getElementsAtClientCoordinates(eventHandler.clientX, eventHandler.clientY);

      console.log('elems at point', elemsAtPoint);
      createIndicator(elemsAtPoint)
    }

    console.log('calculated bounds', {calculatedBounds, isNodeInBounds})

    // moving
    // these should all be if statements
    if (!snapVertical) {
      node.style.left = `${calculatedBounds.left}px`;
    }
    if (!snapHorizontal) {
      node.style.top = `${calculatedBounds.top}px`;
      console.log('calculated bounds top', `${calculatedBounds.top}px`)
    }

    // if the element goes out of bounds, reset the position to the edge of the editor
    // these should all be if statements
    if (!isNodeInBounds.left && !phantomDragOverride) {
      node.style.left = `${editorBounds.left}px`;
    }
    if (!isNodeInBounds.right && !phantomDragOverride) {
      node.style.left = `${(editorBounds.right - (scaledWidth * (1/zoomLevel)))}px`;
    }
    if (!isNodeInBounds.bottom && !phantomDragOverride) {
      node.style.top = `${(editorBounds.bottom - (scaledHeight * (1/zoomLevel)))}px`;
    }
    if (!isNodeInBounds.top && !phantomDragOverride) {
      node.style.top = `${editorBounds.top}px`;
    }

    node.style.transform = `translate(-50% -50%)`;

    if (isFunction(onDrag)) {
      onDrag(eventHandler, data);
    }
  };

  const handleResizeStart = () => {
    // on resize we don't want the element to be moveable
    setIsDragDisabled(true);
    setIsBeingResized(true);
  }

  const handleResizeStop = (widthHasChanged=true, heightHasChanged=true) => {
    updateSelfInTree(widthHasChanged, heightHasChanged);

    // TODO: everything above is experimental

    setIsDragDisabled(false);
    setIsBeingResized(false);
  }

  useEffect(() => {
    if (!ctrlKeyPressed) {
      setSnapHorizontal(false);
      setSnapVertical(false);
    }
  }, [ctrlKeyPressed]);

  // media draggable breaks without this
  useEffect(() => {
    console.log('pizza')

    setCurrentSize({
      width: style?.width || null,
      height: style?.height || null
    })
  }, [style]);

  useEffect(() => {
    // on mount, check if it's selected in the editor or not and subscribe
    setIsSelected(elementSelected === editorId);
  }, [editorState.elementSelected, editorId]);

  return (
    <DraggableCore
      onStart={handleDragStart}
      onStop={handleDragStop}
      onDrag={handleDragging}
      disabled={disabled || isDragDisabled || (editorDraggable && !isSelected)}
    >
      <div
        data-editorid={editorId}
        // if it's an editor draggable, force the position to absolute
        className={cn(classes.draggableContainer)}
        onDrag={() => {}}
        style={editorDraggable && !isChildElem ?
          {
            ...style,
            //position: 'absolute',
            width: currentSize.width,
            height: currentSize.height,
          }
          :
          {
            ...style,
            width: currentSize.width,
            height: currentSize.height,
          }
        }
        ref={elemRef}
      >
        {resizeable ? (
          <ResizeableElement
            onResizeStart={handleResizeStart}
            onResizeStop={handleResizeStop}
            isSelected={isSelected}
            style={{
              // ...resizeableElementStyles,
              // width: currentSize.width,
              // height: currentSize.height
            }}
            draggableRef={elemRef.current}
            showHandles={(isBeingDragged || isBeingResized || isSelected)}
            editorId={editorId}
            isChildElem={isChildElem}
            currentSize={currentSize}
            setCurrentSize={setCurrentSize}
            {...resizeableElementProps}
          >
            {children}
          </ResizeableElement>
        ) : (
          children
        )}
      </div>
    </DraggableCore>
  );
};

DraggableWrapper.defaultProps = {
  resizeable: true,
  container: false,
  editorDraggable: true,
  isChildElem: false,
  disabled: false
};

DraggableWrapper.propTypes = {
  // required
  classes: PropTypes.object.isRequired,
  children: PropTypes.element.isRequired,
  editorId: PropTypes.string,

  // optional
  defaultClassName: PropTypes.string,
  defaultClassNameDragging: PropTypes.string,
  defaultClassNameDragged: PropTypes.string,
  editorDraggable: PropTypes.bool,
  testId: PropTypes.string,
  onClick: PropTypes.func,
  onStart: PropTypes.func,
  onStop: PropTypes.func,
  canBeDraggedOutOfEditor: PropTypes.bool,
  doesNotRenderGridLines: PropTypes.bool,
  resizeable: PropTypes.bool,
  // activates container mode, allowing elements to be placed inside
  container: PropTypes.bool.isRequired,
  resizeableElementProps: PropTypes.object
};

export default withStyles(DraggableWrapperStyle)(DraggableWrapper);
