import Typography from "@material-ui/core/Typography";
import React from 'react';

const ContainersForDevelopers = () => {
  return (
    <>
      <Typography>
        Containers operate similarly to {'<div>'} elements in coding. They employ flexbox for positioning child elements within them.
        To set up the flexbox properties, you can take advantage of our alignment widget. This method of positioning ensures that Nimbus
        generates high-quality code upon project exportation. Unlike the use of position: relative, Nimbus opts for flexbox to more accurately
        mirror how a web developer would code a website.
      </Typography>
    </>
  );
};

export default ContainersForDevelopers;