import logo from "assets/logo.png";
import React from 'react';
import {
  Typography,
  withStyles
} from '@material-ui/core';
import PropTypes from 'prop-types';

import NimbusLogoStyle from './NimbusLogoStyle';

const NimbusLogo = props => {
  const {classes} = props;

  return (
    <div className={classes.logoContainer}>
      <img src={logo} alt={'Nimbus Editor Logo of a nimbus cloud'} className={classes.logo}/>
      <Typography variant="h5" className={classes.nimbus}>
        NIMBUS
      </Typography>
    </div>
  );
};

NimbusLogo.propTypes = {
  classes: PropTypes.object
};

export default withStyles(NimbusLogoStyle)(NimbusLogo);