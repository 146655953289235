import React, {createContext, useEffect, useState} from 'react';
import {User} from "services";
import {handleNetworkError} from "utils/utils";

export const UserContext = createContext();

const UserProvider = ({children}) => {
  const [userData, setUserData] = useState(null);

  useEffect(() => {
    console.log('attempting to read cookies...')
    User.getMe()
      .then(response => {
        console.log('user data response', response);
        setUserData(response.data);
      })
      .catch(error => {
        setUserData(false);
        handleNetworkError(error, false, false)
      })
  }, []);

  const values = {
    userData,
    setUserData,
  }

  return (
    <UserContext.Provider value={values}>
      {children}
    </UserContext.Provider>
  );
};


export default UserProvider;
