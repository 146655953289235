import {withStyles} from '@material-ui/core';
import {arrayMoveMutable} from "array-move";
import ContextMenu from "components/ContextMenu/ContextMenu";
import {UserContext} from "context/UserContext";

import LayerContextMenuStyle from 'editor/components/contextMenus/LayerContextMenu/LayerContextMenuStyle';
import {EditorContext} from "editor/Editor";
import {useEditorDialog} from "hooks/useEditorDialog";
import PropTypes from 'prop-types';
import React, {useContext} from 'react';

const LayerContextMenu = props => {
  const {
    classes,
    contextMenuCoords,
    onClose,
    hasSiblings,
    DOMSiblings,
    startingPosition,
    elementEditorId,
    setNewStartingPosition
  } = props;

  const editorData = useContext(EditorContext);
  const { dispatch, editorReducerState } = editorData;
  const {editorState, editorActions} = editorReducerState;
  const treeActions = editorState.elementTree.actions;
  const {
    copyFromTree,
    moveUpInOrder,
    moveDownInOrder,
    moveToStartInOrder,
    moveToEndInOrder,
  } = editorReducerState.editorActions;

  const {userData} = useContext(UserContext);

  const renameAssetDialog = useEditorDialog('renameAsset');
  const registerLoginDialog = useEditorDialog('loginRegister');
  const saveComponentDialog = useEditorDialog('saveComponent');



  const lockAsset = () => {
    //editorActions.toggleLock(elementEditorId);
    dispatch({
      type: 'toggleAssetLock',
      payload: {
        editorId: elementEditorId
      }
    })
  }

  const copyAsset = () => {
    let elemToCopy = copyFromTree(elementEditorId)

    dispatch({
      type: 'setUserClipboard',
      payload: {
        clipboard: elemToCopy
      }
    })
  }

  const pasteAsset = () => {
    dispatch({
      type: 'pasteClipboard'
    })
  }

  const cutAsset = () => {
    let elemToCopy = copyFromTree(elementEditorId)

    dispatch({
      type: 'setUserClipboard',
      payload: {
        clipboard: elemToCopy
      }
    })

    dispatch({
      type: 'deleteAsset',
      payload: {
        editorId: elemToCopy.id
      }
    })
  }

  const deleteAsset = () => {
    dispatch({
      type: 'deleteAsset',
      payload: {
        editorId: elementEditorId
      }
    })
  }

  const renameAsset = () => {
    let foundElement = treeActions.findElementInTree(elementEditorId)

    renameAssetDialog.appendDialogData({foundElement}, true)
    renameAssetDialog.openDialog();
    onClose();
  }

  const moveToParent = () => {
    console.log('move to parent', elementEditorId)

  }

  const saveComponent = () => {
    console.log('save component', elementEditorId);

    if(!userData) {
      registerLoginDialog.openDialog();
      onClose();
    }
    else {
      saveComponentDialog.appendDialogData({
        elementEditorId,
        onSuccess: () => {
          console.log('on save component success');
          onClose();
        }
      })
      saveComponentDialog.openDialog();
    }
  }

  const contextMenuActions = [
    [
      {
        title: 'Rename',
        icon: null,
        action: renameAsset
      },
      {
        title: 'Copy',
        icon: null,
        action: copyAsset
      },
      {
        title: 'Cut',
        icon: null,
        action: cutAsset
      },
      {
        title: 'Paste',
        icon: null,
        action: pasteAsset
      },
    ],
      // if it has siblings, add these options
      // this conditionally adds it to the array
      hasSiblings ? [
        {
          title: 'Move up in order',
          icon: null,
          action: () => {
            moveUpInOrder(elementEditorId)
            setNewStartingPosition()
          }
        },
        {
          title: 'Move down in order',
          icon: null,
          action: () => {
            moveDownInOrder(elementEditorId)
            setNewStartingPosition()
          }
        },
        {
          title: 'Move to the start',
          icon: null,
          action: () => {
            moveToStartInOrder(elementEditorId)
            setNewStartingPosition()
          }
        },
        {
          title: 'Move to the end',
          icon: null,
          action: () => {
            moveToEndInOrder(elementEditorId)
            setNewStartingPosition()
          }
        },
        // {
        //   title: 'Move to parent',
        //   action: moveToParent
        // }
      ] : [],
    [
      // {
      //   title: 'Move to front',
      //   icon: null,
      // },
      // {
      //   title: 'Move to back',
      //   icon: null,
      // },
      {
        title: 'Toggle Lock',
        icon: null,
        action: lockAsset
      },
      {
        title: 'Save to component library',
        icon: null,
        action: saveComponent
      }
    ],
    [
      {
        title: 'Delete',
        icon: null,
        className: classes.deleteButton,
        action: deleteAsset
      }
    ]
  ]

  return (
    <ContextMenu
      contextMenuCoords={contextMenuCoords}
      actions={contextMenuActions}
      customClasses={classes}
      onClose={onClose}
    />
  );
};

LayerContextMenu.propTypes = {
  classes: PropTypes.object,
  contextMenuCoords: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired,
  hasSiblings: PropTypes.bool.isRequired,
  setNewStartingPosition: PropTypes.func.isRequired,
  elementEditorId: PropTypes.string.isRequired,
};

export default withStyles(LayerContextMenuStyle)(LayerContextMenu);
