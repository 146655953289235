import Typography from "@material-ui/core/Typography";
import {useEditorDialog} from "hooks/useEditorDialog";
import React from 'react';
import PropTypes from 'prop-types';

const SavePreviewExport = props => {
  const { classes } = props;

  const helpDialog = useEditorDialog('help')

  const handleExportLink = () => {
    helpDialog.appendDialogData({
      category: 'exporting',
      resetAccordion: true
    });
    helpDialog.openDialog();
  }

  return (
    <>
      <Typography style={{fontSize: '20px', fontWeight: 'bold'}}>
        Saving
      </Typography>
      <Typography style={{marginTop: '8px'}}>
        Navigate to File + Save to store your project. Nimbus keeps a record of all saved versions for each project on your dashboard.
        Access your dashboard and click on your project to find all saved versions, allowing you to work on any previous iterations.
      </Typography>
      <Typography style={{fontSize: '20px', fontWeight: 'bold', marginTop: '16px'}}>
        Previewing
      </Typography>
      <Typography style={{marginTop: '8px'}}>
        Click the preview button located at the top-right corner of the editor. A dialog will prompt you to confirm the preview.
        Once your site is ready, either an email will be sent to you with a preview link or you can find the link in the same dialog.
      </Typography>
      <Typography style={{fontSize: '20px', fontWeight: 'bold', marginTop: '16px'}}>
        Exporting
      </Typography>
      <Typography style={{marginTop: '8px'}}>
        Click the export button at the top-right corner of the editor. A dialog will appear asking for export confirmation. After a brief wait,
        your exportable code will be ready. Click the completed link to download it, or check your email for the link. For more details on exporting
        a project, click <span onClick={handleExportLink} style={{color: '#8A56FF', cursor: 'pointer'}}>here</span>.
      </Typography>
    </>
  );
};

SavePreviewExport.propTypes = {
  classes: PropTypes.object
};

export default SavePreviewExport;