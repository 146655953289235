const CanvasSettingsStyle = theme => ({
  canvasSizeContainer: {
    width: "60% !important",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-around",
    alignItems: "flex-start",
  },

  inputContainer: {
    width: "100%",
    marginBottom: "7px",
    display: "flex",
    flexDirection: "column"
  },

  form: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    width: '100%',
  },

  icon: {
    color: theme.palette.common.white,
    transition: "0.3s"
  },

  active: {
    color: theme.palette.secondary.main
  },

  iconButton: {
    '&:hover': {
      background: "rgba(255,255,255,0.1)",
      transition: "0.3s"
    }
  }
});

export default CanvasSettingsStyle;