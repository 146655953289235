import {
  AccessAlarm,
  AccessAlarmOutlined,
  AccessAlarmRounded,
  AccessAlarms,
  AccessAlarmSharp,
  AccessAlarmsOutlined,
  AccessAlarmsRounded,
  AccessAlarmsSharp,
  AccessAlarmsTwoTone,
  AccessAlarmTwoTone,
  Accessibility,
  AccessibilityNew,
  AccessibilityNewOutlined,
  AccessibilityNewRounded,
  AccessibilityNewSharp,
  AccessibilityNewTwoTone,
  AccessibilityOutlined,
  AccessibilityRounded,
  AccessibilitySharp,
  AccessibilityTwoTone,
  Accessible,
  AccessibleForward,
  AccessibleForwardOutlined,
  AccessibleForwardRounded,
  AccessibleForwardSharp,
  AccessibleForwardTwoTone,
  AccessibleOutlined,
  AccessibleRounded,
  AccessibleSharp,
  AccessibleTwoTone,
  AccessTime,
  AccessTimeOutlined,
  AccessTimeRounded,
  AccessTimeSharp,
  AccessTimeTwoTone,
  AccountBalance,
  AccountBalanceOutlined,
  AccountBalanceRounded,
  AccountBalanceSharp,
  AccountBalanceTwoTone,
  AccountBalanceWallet,
  AccountBalanceWalletOutlined,
  AccountBalanceWalletRounded,
  AccountBalanceWalletSharp,
  AccountBalanceWalletTwoTone,
  AccountBox,
  AccountBoxOutlined,
  AccountBoxRounded,
  AccountBoxSharp,
  AccountBoxTwoTone,
  AccountCircle,
  AccountCircleOutlined,
  AccountCircleRounded,
  AccountCircleSharp,
  AccountCircleTwoTone,
  AccountTree,
  AccountTreeOutlined,
  AccountTreeRounded,
  AccountTreeSharp,
  AccountTreeTwoTone,
  AcUnit,
  AcUnitOutlined,
  AcUnitRounded,
  AcUnitSharp,
  AcUnitTwoTone,
  Adb,
  AdbOutlined,
  AdbRounded,
  AdbSharp,
  AdbTwoTone,
  Add,
  AddAlarm,
  AddAlarmOutlined,
  AddAlarmRounded,
  AddAlarmSharp,
  AddAlarmTwoTone,
  AddAlert,
  AddAlertOutlined,
  AddAlertRounded,
  AddAlertSharp,
  AddAlertTwoTone,
  AddAPhoto,
  AddAPhotoOutlined,
  AddAPhotoRounded,
  AddAPhotoSharp,
  AddAPhotoTwoTone,
  AddBox,
  AddBoxOutlined,
  AddBoxRounded,
  AddBoxSharp,
  AddBoxTwoTone,
  AddCircle,
  AddCircleOutline,
  AddCircleOutlined,
  AddCircleOutlineOutlined,
  AddCircleOutlineRounded,
  AddCircleOutlineSharp,
  AddCircleOutlineTwoTone,
  AddCircleRounded,
  AddCircleSharp,
  AddCircleTwoTone,
  AddComment,
  AddCommentOutlined,
  AddCommentRounded,
  AddCommentSharp,
  AddCommentTwoTone,
  AddIcCall,
  AddIcCallOutlined,
  AddIcCallRounded,
  AddIcCallSharp,
  AddIcCallTwoTone,
  AddLocation,
  AddLocationOutlined,
  AddLocationRounded,
  AddLocationSharp,
  AddLocationTwoTone,
  AddOutlined,
  AddPhotoAlternate,
  AddPhotoAlternateOutlined,
  AddPhotoAlternateRounded,
  AddPhotoAlternateSharp,
  AddPhotoAlternateTwoTone,
  AddRounded,
  AddSharp,
  AddShoppingCart,
  AddShoppingCartOutlined,
  AddShoppingCartRounded,
  AddShoppingCartSharp,
  AddShoppingCartTwoTone,
  AddToHomeScreen,
  AddToHomeScreenOutlined,
  AddToHomeScreenRounded,
  AddToHomeScreenSharp,
  AddToHomeScreenTwoTone,
  AddToPhotos,
  AddToPhotosOutlined,
  AddToPhotosRounded,
  AddToPhotosSharp,
  AddToPhotosTwoTone,
  AddToQueue,
  AddToQueueOutlined,
  AddToQueueRounded,
  AddToQueueSharp,
  AddToQueueTwoTone,
  AddTwoTone,
  Adjust,
  AdjustOutlined,
  AdjustRounded,
  AdjustSharp,
  AdjustTwoTone,
  AirlineSeatFlat,
  AirlineSeatFlatAngled,
  AirlineSeatFlatAngledOutlined,
  AirlineSeatFlatAngledRounded,
  AirlineSeatFlatAngledSharp,
  AirlineSeatFlatAngledTwoTone,
  AirlineSeatFlatOutlined,
  AirlineSeatFlatRounded,
  AirlineSeatFlatSharp,
  AirlineSeatFlatTwoTone,
  AirlineSeatIndividualSuite,
  AirlineSeatIndividualSuiteOutlined,
  AirlineSeatIndividualSuiteRounded,
  AirlineSeatIndividualSuiteSharp,
  AirlineSeatIndividualSuiteTwoTone,
  AirlineSeatLegroomExtra,
  AirlineSeatLegroomExtraOutlined,
  AirlineSeatLegroomExtraRounded,
  AirlineSeatLegroomExtraSharp,
  AirlineSeatLegroomExtraTwoTone,
  AirlineSeatLegroomNormal,
  AirlineSeatLegroomNormalOutlined,
  AirlineSeatLegroomNormalRounded,
  AirlineSeatLegroomNormalSharp,
  AirlineSeatLegroomNormalTwoTone,
  AirlineSeatLegroomReduced,
  AirlineSeatLegroomReducedOutlined,
  AirlineSeatLegroomReducedRounded,
  AirlineSeatLegroomReducedSharp,
  AirlineSeatLegroomReducedTwoTone,
  AirlineSeatReclineExtra,
  AirlineSeatReclineExtraOutlined,
  AirlineSeatReclineExtraRounded,
  AirlineSeatReclineExtraSharp,
  AirlineSeatReclineExtraTwoTone,
  AirlineSeatReclineNormal,
  AirlineSeatReclineNormalOutlined,
  AirlineSeatReclineNormalRounded,
  AirlineSeatReclineNormalSharp,
  AirlineSeatReclineNormalTwoTone,
  AirplanemodeActive,
  AirplanemodeActiveOutlined,
  AirplanemodeActiveRounded,
  AirplanemodeActiveSharp,
  AirplanemodeActiveTwoTone,
  AirplanemodeInactive,
  AirplanemodeInactiveOutlined,
  AirplanemodeInactiveRounded,
  AirplanemodeInactiveSharp,
  AirplanemodeInactiveTwoTone,
  Airplay,
  AirplayOutlined,
  AirplayRounded,
  AirplaySharp,
  AirplayTwoTone,
  AirportShuttle,
  AirportShuttleOutlined,
  AirportShuttleRounded,
  AirportShuttleSharp,
  AirportShuttleTwoTone,
  Alarm,
  AlarmAdd,
  AlarmAddOutlined,
  AlarmAddRounded,
  AlarmAddSharp,
  AlarmAddTwoTone,
  AlarmOff,
  AlarmOffOutlined,
  AlarmOffRounded,
  AlarmOffSharp,
  AlarmOffTwoTone,
  AlarmOn,
  AlarmOnOutlined,
  AlarmOnRounded,
  AlarmOnSharp,
  AlarmOnTwoTone,
  AlarmOutlined,
  AlarmRounded,
  AlarmSharp,
  AlarmTwoTone,
  Album,
  AlbumOutlined,
  AlbumRounded,
  AlbumSharp,
  AlbumTwoTone,
  AllInbox,
  AllInboxOutlined,
  AllInboxRounded,
  AllInboxSharp,
  AllInboxTwoTone,
  AllInclusive,
  AllInclusiveOutlined,
  AllInclusiveRounded,
  AllInclusiveSharp,
  AllInclusiveTwoTone,
  AllOut,
  AllOutOutlined,
  AllOutRounded,
  AllOutSharp,
  AllOutTwoTone,
  AlternateEmail,
  AlternateEmailOutlined,
  AlternateEmailRounded,
  AlternateEmailSharp,
  AlternateEmailTwoTone,
  AmpStories,
  AmpStoriesOutlined,
  AmpStoriesRounded,
  AmpStoriesSharp,
  AmpStoriesTwoTone,
  Android,
  AndroidOutlined,
  AndroidRounded,
  AndroidSharp,
  AndroidTwoTone,
  Announcement,
  AnnouncementOutlined,
  AnnouncementRounded,
  AnnouncementSharp,
  AnnouncementTwoTone,
  Apartment,
  ApartmentOutlined,
  ApartmentRounded,
  ApartmentSharp,
  ApartmentTwoTone,
  Apple,
  Apps,
  AppsOutlined,
  AppsRounded,
  AppsSharp,
  AppsTwoTone,
  Archive,
  ArchiveOutlined,
  ArchiveRounded,
  ArchiveSharp,
  ArchiveTwoTone,
  ArrowBack,
  ArrowBackIos,
  ArrowBackIosOutlined,
  ArrowBackIosRounded,
  ArrowBackIosSharp,
  ArrowBackIosTwoTone,
  ArrowBackOutlined,
  ArrowBackRounded,
  ArrowBackSharp,
  ArrowBackTwoTone,
  ArrowDownward,
  ArrowDownwardOutlined,
  ArrowDownwardRounded,
  ArrowDownwardSharp,
  ArrowDownwardTwoTone,
  ArrowDropDown,
  ArrowDropDownCircle,
  ArrowDropDownCircleOutlined,
  ArrowDropDownCircleRounded,
  ArrowDropDownCircleSharp,
  ArrowDropDownCircleTwoTone,
  ArrowDropDownOutlined,
  ArrowDropDownRounded,
  ArrowDropDownSharp,
  ArrowDropDownTwoTone,
  ArrowDropUp,
  ArrowDropUpOutlined,
  ArrowDropUpRounded,
  ArrowDropUpSharp,
  ArrowDropUpTwoTone,
  ArrowForward,
  ArrowForwardIos,
  ArrowForwardIosOutlined,
  ArrowForwardIosRounded,
  ArrowForwardIosSharp,
  ArrowForwardIosTwoTone,
  ArrowForwardOutlined,
  ArrowForwardRounded,
  ArrowForwardSharp,
  ArrowForwardTwoTone,
  ArrowLeft,
  ArrowLeftOutlined,
  ArrowLeftRounded,
  ArrowLeftSharp,
  ArrowLeftTwoTone,
  ArrowRight,
  ArrowRightAlt,
  ArrowRightAltOutlined,
  ArrowRightAltRounded,
  ArrowRightAltSharp,
  ArrowRightAltTwoTone,
  ArrowRightOutlined,
  ArrowRightRounded,
  ArrowRightSharp,
  ArrowRightTwoTone,
  ArrowUpward,
  ArrowUpwardOutlined,
  ArrowUpwardRounded,
  ArrowUpwardSharp,
  ArrowUpwardTwoTone,
  ArtTrack,
  ArtTrackOutlined,
  ArtTrackRounded,
  ArtTrackSharp,
  ArtTrackTwoTone,
  AspectRatio,
  AspectRatioOutlined,
  AspectRatioRounded,
  AspectRatioSharp,
  AspectRatioTwoTone,
  Assessment,
  AssessmentOutlined,
  AssessmentRounded,
  AssessmentSharp,
  AssessmentTwoTone,
  Assignment,
  AssignmentInd,
  AssignmentIndOutlined,
  AssignmentIndRounded,
  AssignmentIndSharp,
  AssignmentIndTwoTone,
  AssignmentLate,
  AssignmentLateOutlined,
  AssignmentLateRounded,
  AssignmentLateSharp,
  AssignmentLateTwoTone,
  AssignmentOutlined,
  AssignmentReturn,
  AssignmentReturned,
  AssignmentReturnedOutlined,
  AssignmentReturnedRounded,
  AssignmentReturnedSharp,
  AssignmentReturnedTwoTone,
  AssignmentReturnOutlined,
  AssignmentReturnRounded,
  AssignmentReturnSharp,
  AssignmentReturnTwoTone,
  AssignmentRounded,
  AssignmentSharp,
  AssignmentTurnedIn,
  AssignmentTurnedInOutlined,
  AssignmentTurnedInRounded,
  AssignmentTurnedInSharp,
  AssignmentTurnedInTwoTone,
  AssignmentTwoTone,
  Assistant,
  AssistantOutlined,
  AssistantPhoto,
  AssistantPhotoOutlined,
  AssistantPhotoRounded,
  AssistantPhotoSharp,
  AssistantPhotoTwoTone,
  AssistantRounded,
  AssistantSharp,
  AssistantTwoTone,
  Atm,
  AtmOutlined,
  AtmRounded,
  AtmSharp,
  AtmTwoTone,
  AttachFile,
  AttachFileOutlined,
  AttachFileRounded,
  AttachFileSharp,
  AttachFileTwoTone,
  Attachment,
  AttachmentOutlined,
  AttachmentRounded,
  AttachmentSharp,
  AttachmentTwoTone,
  AttachMoney,
  AttachMoneyOutlined,
  AttachMoneyRounded,
  AttachMoneySharp,
  AttachMoneyTwoTone,
  Audiotrack,
  AudiotrackOutlined,
  AudiotrackRounded,
  AudiotrackSharp,
  AudiotrackTwoTone,
  Autorenew,
  AutorenewOutlined,
  AutorenewRounded,
  AutorenewSharp,
  AutorenewTwoTone,
  AvTimer,
  AvTimerOutlined,
  AvTimerRounded,
  AvTimerSharp,
  AvTimerTwoTone,
  Backspace,
  BackspaceOutlined,
  BackspaceRounded,
  BackspaceSharp,
  BackspaceTwoTone,
  Backup,
  BackupOutlined,
  BackupRounded,
  BackupSharp,
  BackupTwoTone,
  Ballot,
  BallotOutlined,
  BallotRounded,
  BallotSharp,
  BallotTwoTone,
  BarChart,
  BarChartOutlined,
  BarChartRounded,
  BarChartSharp,
  BarChartTwoTone,
  Bathtub,
  BathtubOutlined,
  BathtubRounded,
  BathtubSharp,
  BathtubTwoTone,
  Battery20,
  Battery20Outlined,
  Battery20Rounded,
  Battery20Sharp,
  Battery20TwoTone,
  Battery30,
  Battery30Outlined,
  Battery30Rounded,
  Battery30Sharp,
  Battery30TwoTone,
  Battery50,
  Battery50Outlined,
  Battery50Rounded,
  Battery50Sharp,
  Battery50TwoTone,
  Battery60,
  Battery60Outlined,
  Battery60Rounded,
  Battery60Sharp,
  Battery60TwoTone,
  Battery80,
  Battery80Outlined,
  Battery80Rounded,
  Battery80Sharp,
  Battery80TwoTone,
  Battery90,
  Battery90Outlined,
  Battery90Rounded,
  Battery90Sharp,
  Battery90TwoTone,
  BatteryAlert,
  BatteryAlertOutlined,
  BatteryAlertRounded,
  BatteryAlertSharp,
  BatteryAlertTwoTone,
  BatteryCharging20,
  BatteryCharging20Outlined,
  BatteryCharging20Rounded,
  BatteryCharging20Sharp,
  BatteryCharging20TwoTone,
  BatteryCharging30,
  BatteryCharging30Outlined,
  BatteryCharging30Rounded,
  BatteryCharging30Sharp,
  BatteryCharging30TwoTone,
  BatteryCharging50,
  BatteryCharging50Outlined,
  BatteryCharging50Rounded,
  BatteryCharging50Sharp,
  BatteryCharging50TwoTone,
  BatteryCharging60,
  BatteryCharging60Outlined,
  BatteryCharging60Rounded,
  BatteryCharging60Sharp,
  BatteryCharging60TwoTone,
  BatteryCharging80,
  BatteryCharging80Outlined,
  BatteryCharging80Rounded,
  BatteryCharging80Sharp,
  BatteryCharging80TwoTone,
  BatteryCharging90,
  BatteryCharging90Outlined,
  BatteryCharging90Rounded,
  BatteryCharging90Sharp,
  BatteryCharging90TwoTone,
  BatteryChargingFull,
  BatteryChargingFullOutlined,
  BatteryChargingFullRounded,
  BatteryChargingFullSharp,
  BatteryChargingFullTwoTone,
  BatteryFull,
  BatteryFullOutlined,
  BatteryFullRounded,
  BatteryFullSharp,
  BatteryFullTwoTone,
  BatteryStd,
  BatteryStdOutlined,
  BatteryStdRounded,
  BatteryStdSharp,
  BatteryStdTwoTone,
  BatteryUnknown,
  BatteryUnknownOutlined,
  BatteryUnknownRounded,
  BatteryUnknownSharp,
  BatteryUnknownTwoTone,
  BeachAccess,
  BeachAccessOutlined,
  BeachAccessRounded,
  BeachAccessSharp,
  BeachAccessTwoTone,
  Beenhere,
  BeenhereOutlined,
  BeenhereRounded,
  BeenhereSharp,
  BeenhereTwoTone,
  Block,
  BlockOutlined,
  BlockRounded,
  BlockSharp,
  BlockTwoTone,
  Bluetooth,
  BluetoothAudio,
  BluetoothAudioOutlined,
  BluetoothAudioRounded,
  BluetoothAudioSharp,
  BluetoothAudioTwoTone,
  BluetoothConnected,
  BluetoothConnectedOutlined,
  BluetoothConnectedRounded,
  BluetoothConnectedSharp,
  BluetoothConnectedTwoTone,
  BluetoothDisabled,
  BluetoothDisabledOutlined,
  BluetoothDisabledRounded,
  BluetoothDisabledSharp,
  BluetoothDisabledTwoTone,
  BluetoothOutlined,
  BluetoothRounded,
  BluetoothSearching,
  BluetoothSearchingOutlined,
  BluetoothSearchingRounded,
  BluetoothSearchingSharp,
  BluetoothSearchingTwoTone,
  BluetoothSharp,
  BluetoothTwoTone,
  BlurCircular,
  BlurCircularOutlined,
  BlurCircularRounded,
  BlurCircularSharp,
  BlurCircularTwoTone,
  BlurLinear,
  BlurLinearOutlined,
  BlurLinearRounded,
  BlurLinearSharp,
  BlurLinearTwoTone,
  BlurOff,
  BlurOffOutlined,
  BlurOffRounded,
  BlurOffSharp,
  BlurOffTwoTone,
  BlurOn,
  BlurOnOutlined,
  BlurOnRounded,
  BlurOnSharp,
  BlurOnTwoTone,
  Book,
  Bookmark,
  BookmarkBorder,
  BookmarkBorderOutlined,
  BookmarkBorderRounded,
  BookmarkBorderSharp,
  BookmarkBorderTwoTone,
  BookmarkOutlined,
  BookmarkRounded,
  Bookmarks,
  BookmarkSharp,
  BookmarksOutlined,
  BookmarksRounded,
  BookmarksSharp,
  BookmarksTwoTone,
  BookmarkTwoTone,
  BookOutlined,
  BookRounded,
  BookSharp,
  BookTwoTone,
  BorderAll,
  BorderAllOutlined,
  BorderAllRounded,
  BorderAllSharp,
  BorderAllTwoTone,
  BorderBottom,
  BorderBottomOutlined,
  BorderBottomRounded,
  BorderBottomSharp,
  BorderBottomTwoTone,
  BorderClear,
  BorderClearOutlined,
  BorderClearRounded,
  BorderClearSharp,
  BorderClearTwoTone,
  BorderColor,
  BorderColorOutlined,
  BorderColorRounded,
  BorderColorSharp,
  BorderColorTwoTone,
  BorderHorizontal,
  BorderHorizontalOutlined,
  BorderHorizontalRounded,
  BorderHorizontalSharp,
  BorderHorizontalTwoTone,
  BorderInner,
  BorderInnerOutlined,
  BorderInnerRounded,
  BorderInnerSharp,
  BorderInnerTwoTone,
  BorderLeft,
  BorderLeftOutlined,
  BorderLeftRounded,
  BorderLeftSharp,
  BorderLeftTwoTone,
  BorderOuter,
  BorderOuterOutlined,
  BorderOuterRounded,
  BorderOuterSharp,
  BorderOuterTwoTone,
  BorderRight,
  BorderRightOutlined,
  BorderRightRounded,
  BorderRightSharp,
  BorderRightTwoTone,
  BorderStyle,
  BorderStyleOutlined,
  BorderStyleRounded,
  BorderStyleSharp,
  BorderStyleTwoTone,
  BorderTop,
  BorderTopOutlined,
  BorderTopRounded,
  BorderTopSharp,
  BorderTopTwoTone,
  BorderVertical,
  BorderVerticalOutlined,
  BorderVerticalRounded,
  BorderVerticalSharp,
  BorderVerticalTwoTone,
  BrandingWatermark,
  BrandingWatermarkOutlined,
  BrandingWatermarkRounded,
  BrandingWatermarkSharp,
  BrandingWatermarkTwoTone,
  Brightness1,
  Brightness1Outlined,
  Brightness1Rounded,
  Brightness1Sharp,
  Brightness1TwoTone,
  Brightness2,
  Brightness2Outlined,
  Brightness2Rounded,
  Brightness2Sharp,
  Brightness2TwoTone,
  Brightness3,
  Brightness3Outlined,
  Brightness3Rounded,
  Brightness3Sharp,
  Brightness3TwoTone,
  Brightness4,
  Brightness4Outlined,
  Brightness4Rounded,
  Brightness4Sharp,
  Brightness4TwoTone,
  Brightness5,
  Brightness5Outlined,
  Brightness5Rounded,
  Brightness5Sharp,
  Brightness5TwoTone,
  Brightness6,
  Brightness6Outlined,
  Brightness6Rounded,
  Brightness6Sharp,
  Brightness6TwoTone,
  Brightness7,
  Brightness7Outlined,
  Brightness7Rounded,
  Brightness7Sharp,
  Brightness7TwoTone,
  BrightnessAuto,
  BrightnessAutoOutlined,
  BrightnessAutoRounded,
  BrightnessAutoSharp,
  BrightnessAutoTwoTone,
  BrightnessHigh,
  BrightnessHighOutlined,
  BrightnessHighRounded,
  BrightnessHighSharp,
  BrightnessHighTwoTone,
  BrightnessLow,
  BrightnessLowOutlined,
  BrightnessLowRounded,
  BrightnessLowSharp,
  BrightnessLowTwoTone,
  BrightnessMedium,
  BrightnessMediumOutlined,
  BrightnessMediumRounded,
  BrightnessMediumSharp,
  BrightnessMediumTwoTone,
  BrokenImage,
  BrokenImageOutlined,
  BrokenImageRounded,
  BrokenImageSharp,
  BrokenImageTwoTone,
  Brush,
  BrushOutlined,
  BrushRounded,
  BrushSharp,
  BrushTwoTone,
  BubbleChart,
  BubbleChartOutlined,
  BubbleChartRounded,
  BubbleChartSharp,
  BubbleChartTwoTone,
  BugReport,
  BugReportOutlined,
  BugReportRounded,
  BugReportSharp,
  BugReportTwoTone,
  Build,
  BuildOutlined,
  BuildRounded,
  BuildSharp,
  BuildTwoTone,
  BurstMode,
  BurstModeOutlined,
  BurstModeRounded,
  BurstModeSharp,
  BurstModeTwoTone,
  Business,
  BusinessCenter,
  BusinessCenterOutlined,
  BusinessCenterRounded,
  BusinessCenterSharp,
  BusinessCenterTwoTone,
  BusinessOutlined,
  BusinessRounded,
  BusinessSharp,
  BusinessTwoTone,
  Cached,
  CachedOutlined,
  CachedRounded,
  CachedSharp,
  CachedTwoTone,
  Cake,
  CakeOutlined,
  CakeRounded,
  CakeSharp,
  CakeTwoTone,
  CalendarToday,
  CalendarTodayOutlined,
  CalendarTodayRounded,
  CalendarTodaySharp,
  CalendarTodayTwoTone,
  CalendarViewDay,
  CalendarViewDayOutlined,
  CalendarViewDayRounded,
  CalendarViewDaySharp,
  CalendarViewDayTwoTone,
  Call,
  CallEnd,
  CallEndOutlined,
  CallEndRounded,
  CallEndSharp,
  CallEndTwoTone,
  CallMade,
  CallMadeOutlined,
  CallMadeRounded,
  CallMadeSharp,
  CallMadeTwoTone,
  CallMerge,
  CallMergeOutlined,
  CallMergeRounded,
  CallMergeSharp,
  CallMergeTwoTone,
  CallMissed,
  CallMissedOutgoing,
  CallMissedOutgoingOutlined,
  CallMissedOutgoingRounded,
  CallMissedOutgoingSharp,
  CallMissedOutgoingTwoTone,
  CallMissedOutlined,
  CallMissedRounded,
  CallMissedSharp,
  CallMissedTwoTone,
  CallOutlined,
  CallReceived,
  CallReceivedOutlined,
  CallReceivedRounded,
  CallReceivedSharp,
  CallReceivedTwoTone,
  CallRounded,
  CallSharp,
  CallSplit,
  CallSplitOutlined,
  CallSplitRounded,
  CallSplitSharp,
  CallSplitTwoTone,
  CallToAction,
  CallToActionOutlined,
  CallToActionRounded,
  CallToActionSharp,
  CallToActionTwoTone,
  CallTwoTone,
  Camera,
  CameraAlt,
  CameraAltOutlined,
  CameraAltRounded,
  CameraAltSharp,
  CameraAltTwoTone,
  CameraEnhance,
  CameraEnhanceOutlined,
  CameraEnhanceRounded,
  CameraEnhanceSharp,
  CameraEnhanceTwoTone,
  CameraFront,
  CameraFrontOutlined,
  CameraFrontRounded,
  CameraFrontSharp,
  CameraFrontTwoTone,
  CameraOutlined,
  CameraRear,
  CameraRearOutlined,
  CameraRearRounded,
  CameraRearSharp,
  CameraRearTwoTone,
  CameraRoll,
  CameraRollOutlined,
  CameraRollRounded,
  CameraRollSharp,
  CameraRollTwoTone,
  CameraRounded,
  CameraSharp,
  CameraTwoTone,
  Cancel,
  CancelOutlined,
  CancelPresentation,
  CancelPresentationOutlined,
  CancelPresentationRounded,
  CancelPresentationSharp,
  CancelPresentationTwoTone,
  CancelRounded,
  CancelScheduleSend,
  CancelScheduleSendOutlined,
  CancelScheduleSendRounded,
  CancelScheduleSendSharp,
  CancelScheduleSendTwoTone,
  CancelSharp,
  CancelTwoTone,
  CardGiftcard,
  CardGiftcardOutlined,
  CardGiftcardRounded,
  CardGiftcardSharp,
  CardGiftcardTwoTone,
  CardMembership,
  CardMembershipOutlined,
  CardMembershipRounded,
  CardMembershipSharp,
  CardMembershipTwoTone,
  CardTravel,
  CardTravelOutlined,
  CardTravelRounded,
  CardTravelSharp,
  CardTravelTwoTone,
  Casino,
  CasinoOutlined,
  CasinoRounded,
  CasinoSharp,
  CasinoTwoTone,
  Cast,
  CastConnected,
  CastConnectedOutlined,
  CastConnectedRounded,
  CastConnectedSharp,
  CastConnectedTwoTone,
  CastForEducation,
  CastForEducationOutlined,
  CastForEducationRounded,
  CastForEducationSharp,
  CastForEducationTwoTone,
  CastOutlined,
  CastRounded,
  CastSharp,
  CastTwoTone,
  Category,
  CategoryOutlined,
  CategoryRounded,
  CategorySharp,
  CategoryTwoTone,
  CellWifi,
  CellWifiOutlined,
  CellWifiRounded,
  CellWifiSharp,
  CellWifiTwoTone,
  CenterFocusStrong,
  CenterFocusStrongOutlined,
  CenterFocusStrongRounded,
  CenterFocusStrongSharp,
  CenterFocusStrongTwoTone,
  CenterFocusWeak,
  CenterFocusWeakOutlined,
  CenterFocusWeakRounded,
  CenterFocusWeakSharp,
  CenterFocusWeakTwoTone,
  ChangeHistory,
  ChangeHistoryOutlined,
  ChangeHistoryRounded,
  ChangeHistorySharp,
  ChangeHistoryTwoTone,
  Chat,
  ChatBubble,
  ChatBubbleOutline,
  ChatBubbleOutlined,
  ChatBubbleOutlineOutlined,
  ChatBubbleOutlineRounded,
  ChatBubbleOutlineSharp,
  ChatBubbleOutlineTwoTone,
  ChatBubbleRounded,
  ChatBubbleSharp,
  ChatBubbleTwoTone,
  ChatOutlined,
  ChatRounded,
  ChatSharp,
  ChatTwoTone,
  Check,
  CheckBox,
  CheckBoxOutlineBlank,
  CheckBoxOutlineBlankOutlined,
  CheckBoxOutlineBlankRounded,
  CheckBoxOutlineBlankSharp,
  CheckBoxOutlineBlankTwoTone,
  CheckBoxOutlined,
  CheckBoxRounded,
  CheckBoxSharp,
  CheckBoxTwoTone,
  CheckCircle,
  CheckCircleOutline,
  CheckCircleOutlined,
  CheckCircleOutlineOutlined,
  CheckCircleOutlineRounded,
  CheckCircleOutlineSharp,
  CheckCircleOutlineTwoTone,
  CheckCircleRounded,
  CheckCircleSharp,
  CheckCircleTwoTone,
  CheckOutlined,
  CheckRounded,
  CheckSharp,
  CheckTwoTone,
  ChevronLeft,
  ChevronLeftOutlined,
  ChevronLeftRounded,
  ChevronLeftSharp,
  ChevronLeftTwoTone,
  ChevronRight,
  ChevronRightOutlined,
  ChevronRightRounded,
  ChevronRightSharp,
  ChevronRightTwoTone,
  ChildCare,
  ChildCareOutlined,
  ChildCareRounded,
  ChildCareSharp,
  ChildCareTwoTone,
  ChildFriendly,
  ChildFriendlyOutlined,
  ChildFriendlyRounded,
  ChildFriendlySharp,
  ChildFriendlyTwoTone,
  ChromeReaderMode,
  ChromeReaderModeOutlined,
  ChromeReaderModeRounded,
  ChromeReaderModeSharp,
  ChromeReaderModeTwoTone,
  Class,
  ClassOutlined,
  ClassRounded,
  ClassSharp,
  ClassTwoTone,
  Clear,
  ClearAll,
  ClearAllOutlined,
  ClearAllRounded,
  ClearAllSharp,
  ClearAllTwoTone,
  ClearOutlined,
  ClearRounded,
  ClearSharp,
  ClearTwoTone,
  Close,
  ClosedCaption,
  ClosedCaptionOutlined,
  ClosedCaptionRounded,
  ClosedCaptionSharp,
  ClosedCaptionTwoTone,
  CloseOutlined,
  CloseRounded,
  CloseSharp,
  CloseTwoTone,
  Cloud,
  CloudCircle,
  CloudCircleOutlined,
  CloudCircleRounded,
  CloudCircleSharp,
  CloudCircleTwoTone,
  CloudDone,
  CloudDoneOutlined,
  CloudDoneRounded,
  CloudDoneSharp,
  CloudDoneTwoTone,
  CloudDownload,
  CloudDownloadOutlined,
  CloudDownloadRounded,
  CloudDownloadSharp,
  CloudDownloadTwoTone,
  CloudOff,
  CloudOffOutlined,
  CloudOffRounded,
  CloudOffSharp,
  CloudOffTwoTone,
  CloudOutlined,
  CloudQueue,
  CloudQueueOutlined,
  CloudQueueRounded,
  CloudQueueSharp,
  CloudQueueTwoTone,
  CloudRounded,
  CloudSharp,
  CloudTwoTone,
  CloudUpload,
  CloudUploadOutlined,
  CloudUploadRounded,
  CloudUploadSharp,
  CloudUploadTwoTone,
  Code,
  CodeOutlined,
  CodeRounded,
  CodeSharp,
  CodeTwoTone,
  Collections,
  CollectionsBookmark,
  CollectionsBookmarkOutlined,
  CollectionsBookmarkRounded,
  CollectionsBookmarkSharp,
  CollectionsBookmarkTwoTone,
  CollectionsOutlined,
  CollectionsRounded,
  CollectionsSharp,
  CollectionsTwoTone,
  Colorize,
  ColorizeOutlined,
  ColorizeRounded,
  ColorizeSharp,
  ColorizeTwoTone,
  ColorLens,
  ColorLensOutlined,
  ColorLensRounded,
  ColorLensSharp,
  ColorLensTwoTone,
  Comment,
  CommentOutlined,
  CommentRounded,
  CommentSharp,
  CommentTwoTone,
  Commute,
  CommuteOutlined,
  CommuteRounded,
  CommuteSharp,
  CommuteTwoTone,
  Compare,
  CompareArrows,
  CompareArrowsOutlined,
  CompareArrowsRounded,
  CompareArrowsSharp,
  CompareArrowsTwoTone,
  CompareOutlined,
  CompareRounded,
  CompareSharp,
  CompareTwoTone,
  CompassCalibration,
  CompassCalibrationOutlined,
  CompassCalibrationRounded,
  CompassCalibrationSharp,
  CompassCalibrationTwoTone,
  Computer,
  ComputerOutlined,
  ComputerRounded,
  ComputerSharp,
  ComputerTwoTone,
  ConfirmationNumber,
  ConfirmationNumberOutlined,
  ConfirmationNumberRounded,
  ConfirmationNumberSharp,
  ConfirmationNumberTwoTone,
  Contactless,
  ContactlessOutlined,
  ContactlessRounded,
  ContactlessSharp,
  ContactlessTwoTone,
  ContactMail,
  ContactMailOutlined,
  ContactMailRounded,
  ContactMailSharp,
  ContactMailTwoTone,
  ContactPhone,
  ContactPhoneOutlined,
  ContactPhoneRounded,
  ContactPhoneSharp,
  ContactPhoneTwoTone,
  Contacts,
  ContactsOutlined,
  ContactsRounded,
  ContactsSharp,
  ContactsTwoTone,
  ContactSupport,
  ContactSupportOutlined,
  ContactSupportRounded,
  ContactSupportSharp,
  ContactSupportTwoTone,
  ControlCamera,
  ControlCameraOutlined,
  ControlCameraRounded,
  ControlCameraSharp,
  ControlCameraTwoTone,
  ControlPoint,
  ControlPointDuplicate,
  ControlPointDuplicateOutlined,
  ControlPointDuplicateRounded,
  ControlPointDuplicateSharp,
  ControlPointDuplicateTwoTone,
  ControlPointOutlined,
  ControlPointRounded,
  ControlPointSharp,
  ControlPointTwoTone,
  Copyright,
  CopyrightOutlined,
  CopyrightRounded,
  CopyrightSharp,
  CopyrightTwoTone,
  Create,
  CreateNewFolder,
  CreateNewFolderOutlined,
  CreateNewFolderRounded,
  CreateNewFolderSharp,
  CreateNewFolderTwoTone,
  CreateOutlined,
  CreateRounded,
  CreateSharp,
  CreateTwoTone,
  CreditCard,
  CreditCardOutlined,
  CreditCardRounded,
  CreditCardSharp,
  CreditCardTwoTone,
  Crop,
  Crop169,
  Crop169Outlined,
  Crop169Rounded,
  Crop169Sharp,
  Crop169TwoTone,
  Crop32,
  Crop32Outlined,
  Crop32Rounded,
  Crop32Sharp,
  Crop32TwoTone,
  Crop54,
  Crop54Outlined,
  Crop54Rounded,
  Crop54Sharp,
  Crop54TwoTone,
  Crop75,
  Crop75Outlined,
  Crop75Rounded,
  Crop75Sharp,
  Crop75TwoTone,
  CropDin,
  CropDinOutlined,
  CropDinRounded,
  CropDinSharp,
  CropDinTwoTone,
  CropFree,
  CropFreeOutlined,
  CropFreeRounded,
  CropFreeSharp,
  CropFreeTwoTone,
  CropLandscape,
  CropLandscapeOutlined,
  CropLandscapeRounded,
  CropLandscapeSharp,
  CropLandscapeTwoTone,
  CropOriginal,
  CropOriginalOutlined,
  CropOriginalRounded,
  CropOriginalSharp,
  CropOriginalTwoTone,
  CropOutlined,
  CropPortrait,
  CropPortraitOutlined,
  CropPortraitRounded,
  CropPortraitSharp,
  CropPortraitTwoTone,
  CropRotate,
  CropRotateOutlined,
  CropRotateRounded,
  CropRotateSharp,
  CropRotateTwoTone,
  CropRounded,
  CropSharp,
  CropSquare,
  CropSquareOutlined,
  CropSquareRounded,
  CropSquareSharp,
  CropSquareTwoTone,
  CropTwoTone,
  Dashboard,
  DashboardOutlined,
  DashboardRounded,
  DashboardSharp,
  DashboardTwoTone,
  DataUsage,
  DataUsageOutlined,
  DataUsageRounded,
  DataUsageSharp,
  DataUsageTwoTone,
  DateRange,
  DateRangeOutlined,
  DateRangeRounded,
  DateRangeSharp,
  DateRangeTwoTone,
  Deck,
  DeckOutlined,
  DeckRounded,
  DeckSharp,
  DeckTwoTone,
  Dehaze,
  DehazeOutlined,
  DehazeRounded,
  DehazeSharp,
  DehazeTwoTone,
  Delete,
  DeleteForever,
  DeleteForeverOutlined,
  DeleteForeverRounded,
  DeleteForeverSharp,
  DeleteForeverTwoTone,
  DeleteOutline,
  DeleteOutlined,
  DeleteOutlineOutlined,
  DeleteOutlineRounded,
  DeleteOutlineSharp,
  DeleteOutlineTwoTone,
  DeleteRounded,
  DeleteSharp,
  DeleteSweep,
  DeleteSweepOutlined,
  DeleteSweepRounded,
  DeleteSweepSharp,
  DeleteSweepTwoTone,
  DeleteTwoTone,
  DepartureBoard,
  DepartureBoardOutlined,
  DepartureBoardRounded,
  DepartureBoardSharp,
  DepartureBoardTwoTone,
  Description,
  DescriptionOutlined,
  DescriptionRounded,
  DescriptionSharp,
  DescriptionTwoTone,
  DesktopAccessDisabled,
  DesktopAccessDisabledOutlined,
  DesktopAccessDisabledRounded,
  DesktopAccessDisabledSharp,
  DesktopAccessDisabledTwoTone,
  DesktopMac,
  DesktopMacOutlined,
  DesktopMacRounded,
  DesktopMacSharp,
  DesktopMacTwoTone,
  DesktopWindows,
  DesktopWindowsOutlined,
  DesktopWindowsRounded,
  DesktopWindowsSharp,
  DesktopWindowsTwoTone,
  Details,
  DetailsOutlined,
  DetailsRounded,
  DetailsSharp,
  DetailsTwoTone,
  DeveloperBoard,
  DeveloperBoardOutlined,
  DeveloperBoardRounded,
  DeveloperBoardSharp,
  DeveloperBoardTwoTone,
  DeveloperMode,
  DeveloperModeOutlined,
  DeveloperModeRounded,
  DeveloperModeSharp,
  DeveloperModeTwoTone,
  DeviceHub,
  DeviceHubOutlined,
  DeviceHubRounded,
  DeviceHubSharp,
  DeviceHubTwoTone,
  Devices,
  DevicesOther,
  DevicesOtherOutlined,
  DevicesOtherRounded,
  DevicesOtherSharp,
  DevicesOtherTwoTone,
  DevicesOutlined,
  DevicesRounded,
  DevicesSharp,
  DevicesTwoTone,
  DeviceUnknown,
  DeviceUnknownOutlined,
  DeviceUnknownRounded,
  DeviceUnknownSharp,
  DeviceUnknownTwoTone,
  DialerSip,
  DialerSipOutlined,
  DialerSipRounded,
  DialerSipSharp,
  DialerSipTwoTone,
  Dialpad,
  DialpadOutlined,
  DialpadRounded,
  DialpadSharp,
  DialpadTwoTone,
  Directions,
  DirectionsBike,
  DirectionsBikeOutlined,
  DirectionsBikeRounded,
  DirectionsBikeSharp,
  DirectionsBikeTwoTone,
  DirectionsBoat,
  DirectionsBoatOutlined,
  DirectionsBoatRounded,
  DirectionsBoatSharp,
  DirectionsBoatTwoTone,
  DirectionsBus,
  DirectionsBusOutlined,
  DirectionsBusRounded,
  DirectionsBusSharp,
  DirectionsBusTwoTone,
  DirectionsCar,
  DirectionsCarOutlined,
  DirectionsCarRounded,
  DirectionsCarSharp,
  DirectionsCarTwoTone,
  DirectionsOutlined,
  DirectionsRailway,
  DirectionsRailwayOutlined,
  DirectionsRailwayRounded,
  DirectionsRailwaySharp,
  DirectionsRailwayTwoTone,
  DirectionsRounded,
  DirectionsRun,
  DirectionsRunOutlined,
  DirectionsRunRounded,
  DirectionsRunSharp,
  DirectionsRunTwoTone,
  DirectionsSharp,
  DirectionsSubway,
  DirectionsSubwayOutlined,
  DirectionsSubwayRounded,
  DirectionsSubwaySharp,
  DirectionsSubwayTwoTone,
  DirectionsTransit,
  DirectionsTransitOutlined,
  DirectionsTransitRounded,
  DirectionsTransitSharp,
  DirectionsTransitTwoTone,
  DirectionsTwoTone,
  DirectionsWalk,
  DirectionsWalkOutlined,
  DirectionsWalkRounded,
  DirectionsWalkSharp,
  DirectionsWalkTwoTone,
  DiscFull,
  DiscFullOutlined,
  DiscFullRounded,
  DiscFullSharp,
  DiscFullTwoTone,
  Dns,
  DnsOutlined,
  DnsRounded,
  DnsSharp,
  DnsTwoTone,
  Dock,
  DockOutlined,
  DockRounded,
  DockSharp,
  DockTwoTone,
  Domain,
  DomainDisabled,
  DomainDisabledOutlined,
  DomainDisabledRounded,
  DomainDisabledSharp,
  DomainDisabledTwoTone,
  DomainOutlined,
  DomainRounded,
  DomainSharp,
  DomainTwoTone,
  Done,
  DoneAll,
  DoneAllOutlined,
  DoneAllRounded,
  DoneAllSharp,
  DoneAllTwoTone,
  DoneOutline,
  DoneOutlined,
  DoneOutlineOutlined,
  DoneOutlineRounded,
  DoneOutlineSharp,
  DoneOutlineTwoTone,
  DoneRounded,
  DoneSharp,
  DoneTwoTone,
  DonutLarge,
  DonutLargeOutlined,
  DonutLargeRounded,
  DonutLargeSharp,
  DonutLargeTwoTone,
  DonutSmall,
  DonutSmallOutlined,
  DonutSmallRounded,
  DonutSmallSharp,
  DonutSmallTwoTone,
  DoubleArrow,
  DoubleArrowOutlined,
  DoubleArrowRounded,
  DoubleArrowSharp,
  DoubleArrowTwoTone,
  Drafts,
  DraftsOutlined,
  DraftsRounded,
  DraftsSharp,
  DraftsTwoTone,
  DragHandle,
  DragHandleOutlined,
  DragHandleRounded,
  DragHandleSharp,
  DragHandleTwoTone,
  DragIndicator,
  DragIndicatorOutlined,
  DragIndicatorRounded,
  DragIndicatorSharp,
  DragIndicatorTwoTone,
  DriveEta,
  DriveEtaOutlined,
  DriveEtaRounded,
  DriveEtaSharp,
  DriveEtaTwoTone,
  Duo,
  DuoOutlined,
  DuoRounded,
  DuoSharp,
  DuoTwoTone,
  Dvr,
  DvrOutlined,
  DvrRounded,
  DvrSharp,
  DvrTwoTone,
  DynamicFeed,
  DynamicFeedOutlined,
  DynamicFeedRounded,
  DynamicFeedSharp,
  DynamicFeedTwoTone,
  Eco,
  EcoOutlined,
  EcoRounded,
  EcoSharp,
  EcoTwoTone,
  Edit,
  EditAttributes,
  EditAttributesOutlined,
  EditAttributesRounded,
  EditAttributesSharp,
  EditAttributesTwoTone,
  EditLocation,
  EditLocationOutlined,
  EditLocationRounded,
  EditLocationSharp,
  EditLocationTwoTone,
  EditOutlined,
  EditRounded,
  EditSharp,
  EditTwoTone,
  Eject,
  EjectOutlined,
  EjectRounded,
  EjectSharp,
  EjectTwoTone,
  Email,
  EmailOutlined,
  EmailRounded,
  EmailSharp,
  EmailTwoTone,
  EmojiEmotions,
  EmojiEmotionsOutlined,
  EmojiEmotionsRounded,
  EmojiEmotionsSharp,
  EmojiEmotionsTwoTone,
  EmojiEvents,
  EmojiEventsOutlined,
  EmojiEventsRounded,
  EmojiEventsSharp,
  EmojiEventsTwoTone,
  EmojiFlags,
  EmojiFlagsOutlined,
  EmojiFlagsRounded,
  EmojiFlagsSharp,
  EmojiFlagsTwoTone,
  EmojiFoodBeverage,
  EmojiFoodBeverageOutlined,
  EmojiFoodBeverageRounded,
  EmojiFoodBeverageSharp,
  EmojiFoodBeverageTwoTone,
  EmojiNature,
  EmojiNatureOutlined,
  EmojiNatureRounded,
  EmojiNatureSharp,
  EmojiNatureTwoTone,
  EmojiObjects,
  EmojiObjectsOutlined,
  EmojiObjectsRounded,
  EmojiObjectsSharp,
  EmojiObjectsTwoTone,
  EmojiPeople,
  EmojiPeopleOutlined,
  EmojiPeopleRounded,
  EmojiPeopleSharp,
  EmojiPeopleTwoTone,
  EmojiSymbols,
  EmojiSymbolsOutlined,
  EmojiSymbolsRounded,
  EmojiSymbolsSharp,
  EmojiSymbolsTwoTone,
  EmojiTransportation,
  EmojiTransportationOutlined,
  EmojiTransportationRounded,
  EmojiTransportationSharp,
  EmojiTransportationTwoTone,
  EnhancedEncryption,
  EnhancedEncryptionOutlined,
  EnhancedEncryptionRounded,
  EnhancedEncryptionSharp,
  EnhancedEncryptionTwoTone,
  Equalizer,
  EqualizerOutlined,
  EqualizerRounded,
  EqualizerSharp,
  EqualizerTwoTone,
  Error,
  ErrorOutline,
  ErrorOutlined,
  ErrorOutlineOutlined,
  ErrorOutlineRounded,
  ErrorOutlineSharp,
  ErrorOutlineTwoTone,
  ErrorRounded,
  ErrorSharp,
  ErrorTwoTone,
  Euro,
  EuroOutlined,
  EuroRounded,
  EuroSharp,
  EuroSymbol,
  EuroSymbolOutlined,
  EuroSymbolRounded,
  EuroSymbolSharp,
  EuroSymbolTwoTone,
  EuroTwoTone,
  Event,
  EventAvailable,
  EventAvailableOutlined,
  EventAvailableRounded,
  EventAvailableSharp,
  EventAvailableTwoTone,
  EventBusy,
  EventBusyOutlined,
  EventBusyRounded,
  EventBusySharp,
  EventBusyTwoTone,
  EventNote,
  EventNoteOutlined,
  EventNoteRounded,
  EventNoteSharp,
  EventNoteTwoTone,
  EventOutlined,
  EventRounded,
  EventSeat,
  EventSeatOutlined,
  EventSeatRounded,
  EventSeatSharp,
  EventSeatTwoTone,
  EventSharp,
  EventTwoTone,
  EvStation,
  EvStationOutlined,
  EvStationRounded,
  EvStationSharp,
  EvStationTwoTone,
  ExitToApp,
  ExitToAppOutlined,
  ExitToAppRounded,
  ExitToAppSharp,
  ExitToAppTwoTone,
  ExpandLess,
  ExpandLessOutlined,
  ExpandLessRounded,
  ExpandLessSharp,
  ExpandLessTwoTone,
  ExpandMore,
  ExpandMoreOutlined,
  ExpandMoreRounded,
  ExpandMoreSharp,
  ExpandMoreTwoTone,
  Explicit,
  ExplicitOutlined,
  ExplicitRounded,
  ExplicitSharp,
  ExplicitTwoTone,
  Explore,
  ExploreOff,
  ExploreOffOutlined,
  ExploreOffRounded,
  ExploreOffSharp,
  ExploreOffTwoTone,
  ExploreOutlined,
  ExploreRounded,
  ExploreSharp,
  ExploreTwoTone,
  Exposure,
  ExposureNeg1,
  ExposureNeg1Outlined,
  ExposureNeg1Rounded,
  ExposureNeg1Sharp,
  ExposureNeg1TwoTone,
  ExposureNeg2,
  ExposureNeg2Outlined,
  ExposureNeg2Rounded,
  ExposureNeg2Sharp,
  ExposureNeg2TwoTone,
  ExposureOutlined,
  ExposurePlus1,
  ExposurePlus1Outlined,
  ExposurePlus1Rounded,
  ExposurePlus1Sharp,
  ExposurePlus1TwoTone,
  ExposurePlus2,
  ExposurePlus2Outlined,
  ExposurePlus2Rounded,
  ExposurePlus2Sharp,
  ExposurePlus2TwoTone,
  ExposureRounded,
  ExposureSharp,
  ExposureTwoTone,
  ExposureZero,
  ExposureZeroOutlined,
  ExposureZeroRounded,
  ExposureZeroSharp,
  ExposureZeroTwoTone,
  Extension,
  ExtensionOutlined,
  ExtensionRounded,
  ExtensionSharp,
  ExtensionTwoTone,
  Face,
  Facebook,
  FaceOutlined,
  FaceRounded,
  FaceSharp,
  FaceTwoTone,
  Fastfood,
  FastfoodOutlined,
  FastfoodRounded,
  FastfoodSharp,
  FastfoodTwoTone,
  FastForward,
  FastForwardOutlined,
  FastForwardRounded,
  FastForwardSharp,
  FastForwardTwoTone,
  FastRewind,
  FastRewindOutlined,
  FastRewindRounded,
  FastRewindSharp,
  FastRewindTwoTone,
  Favorite,
  FavoriteBorder,
  FavoriteBorderOutlined,
  FavoriteBorderRounded,
  FavoriteBorderSharp,
  FavoriteBorderTwoTone,
  FavoriteOutlined,
  FavoriteRounded,
  FavoriteSharp,
  FavoriteTwoTone,
  FeaturedPlayList,
  FeaturedPlayListOutlined,
  FeaturedPlayListRounded,
  FeaturedPlayListSharp,
  FeaturedPlayListTwoTone,
  FeaturedVideo,
  FeaturedVideoOutlined,
  FeaturedVideoRounded,
  FeaturedVideoSharp,
  FeaturedVideoTwoTone,
  Feedback,
  FeedbackOutlined,
  FeedbackRounded,
  FeedbackSharp,
  FeedbackTwoTone,
  FiberDvr,
  FiberDvrOutlined,
  FiberDvrRounded,
  FiberDvrSharp,
  FiberDvrTwoTone,
  FiberManualRecord,
  FiberManualRecordOutlined,
  FiberManualRecordRounded,
  FiberManualRecordSharp,
  FiberManualRecordTwoTone,
  FiberNew,
  FiberNewOutlined,
  FiberNewRounded,
  FiberNewSharp,
  FiberNewTwoTone,
  FiberPin,
  FiberPinOutlined,
  FiberPinRounded,
  FiberPinSharp,
  FiberPinTwoTone,
  FiberSmartRecord,
  FiberSmartRecordOutlined,
  FiberSmartRecordRounded,
  FiberSmartRecordSharp,
  FiberSmartRecordTwoTone,
  FileCopy,
  FileCopyOutlined,
  FileCopyRounded,
  FileCopySharp,
  FileCopyTwoTone,
  Filter,
  Filter1,
  Filter1Outlined,
  Filter1Rounded,
  Filter1Sharp,
  Filter1TwoTone,
  Filter2,
  Filter2Outlined,
  Filter2Rounded,
  Filter2Sharp,
  Filter2TwoTone,
  Filter3,
  Filter3Outlined,
  Filter3Rounded,
  Filter3Sharp,
  Filter3TwoTone,
  Filter4,
  Filter4Outlined,
  Filter4Rounded,
  Filter4Sharp,
  Filter4TwoTone,
  Filter5,
  Filter5Outlined,
  Filter5Rounded,
  Filter5Sharp,
  Filter5TwoTone,
  Filter6,
  Filter6Outlined,
  Filter6Rounded,
  Filter6Sharp,
  Filter6TwoTone,
  Filter7,
  Filter7Outlined,
  Filter7Rounded,
  Filter7Sharp,
  Filter7TwoTone,
  Filter8,
  Filter8Outlined,
  Filter8Rounded,
  Filter8Sharp,
  Filter8TwoTone,
  Filter9,
  Filter9Outlined,
  Filter9Plus,
  Filter9PlusOutlined,
  Filter9PlusRounded,
  Filter9PlusSharp,
  Filter9PlusTwoTone,
  Filter9Rounded,
  Filter9Sharp,
  Filter9TwoTone,
  FilterBAndW,
  FilterBAndWOutlined,
  FilterBAndWRounded,
  FilterBAndWSharp,
  FilterBAndWTwoTone,
  FilterCenterFocus,
  FilterCenterFocusOutlined,
  FilterCenterFocusRounded,
  FilterCenterFocusSharp,
  FilterCenterFocusTwoTone,
  FilterDrama,
  FilterDramaOutlined,
  FilterDramaRounded,
  FilterDramaSharp,
  FilterDramaTwoTone,
  FilterFrames,
  FilterFramesOutlined,
  FilterFramesRounded,
  FilterFramesSharp,
  FilterFramesTwoTone,
  FilterHdr,
  FilterHdrOutlined,
  FilterHdrRounded,
  FilterHdrSharp,
  FilterHdrTwoTone,
  FilterList,
  FilterListOutlined,
  FilterListRounded,
  FilterListSharp,
  FilterListTwoTone,
  FilterNone,
  FilterNoneOutlined,
  FilterNoneRounded,
  FilterNoneSharp,
  FilterNoneTwoTone,
  FilterOutlined,
  FilterRounded,
  FilterSharp,
  FilterTiltShift,
  FilterTiltShiftOutlined,
  FilterTiltShiftRounded,
  FilterTiltShiftSharp,
  FilterTiltShiftTwoTone,
  FilterTwoTone,
  FilterVintage,
  FilterVintageOutlined,
  FilterVintageRounded,
  FilterVintageSharp,
  FilterVintageTwoTone,
  FindInPage,
  FindInPageOutlined,
  FindInPageRounded,
  FindInPageSharp,
  FindInPageTwoTone,
  FindReplace,
  FindReplaceOutlined,
  FindReplaceRounded,
  FindReplaceSharp,
  FindReplaceTwoTone,
  Fingerprint,
  FingerprintOutlined,
  FingerprintRounded,
  FingerprintSharp,
  FingerprintTwoTone,
  Fireplace,
  FireplaceOutlined,
  FireplaceRounded,
  FireplaceSharp,
  FireplaceTwoTone,
  FirstPage,
  FirstPageOutlined,
  FirstPageRounded,
  FirstPageSharp,
  FirstPageTwoTone,
  FitnessCenter,
  FitnessCenterOutlined,
  FitnessCenterRounded,
  FitnessCenterSharp,
  FitnessCenterTwoTone,
  Flag,
  FlagOutlined,
  FlagRounded,
  FlagSharp,
  FlagTwoTone,
  Flare,
  FlareOutlined,
  FlareRounded,
  FlareSharp,
  FlareTwoTone,
  FlashAuto,
  FlashAutoOutlined,
  FlashAutoRounded,
  FlashAutoSharp,
  FlashAutoTwoTone,
  FlashOff,
  FlashOffOutlined,
  FlashOffRounded,
  FlashOffSharp,
  FlashOffTwoTone,
  FlashOn,
  FlashOnOutlined,
  FlashOnRounded,
  FlashOnSharp,
  FlashOnTwoTone,
  Flight,
  FlightLand,
  FlightLandOutlined,
  FlightLandRounded,
  FlightLandSharp,
  FlightLandTwoTone,
  FlightOutlined,
  FlightRounded,
  FlightSharp,
  FlightTakeoff,
  FlightTakeoffOutlined,
  FlightTakeoffRounded,
  FlightTakeoffSharp,
  FlightTakeoffTwoTone,
  FlightTwoTone,
  Flip,
  FlipCameraAndroid,
  FlipCameraAndroidOutlined,
  FlipCameraAndroidRounded,
  FlipCameraAndroidSharp,
  FlipCameraAndroidTwoTone,
  FlipCameraIos,
  FlipCameraIosOutlined,
  FlipCameraIosRounded,
  FlipCameraIosSharp,
  FlipCameraIosTwoTone,
  FlipOutlined,
  FlipRounded,
  FlipSharp,
  FlipToBack,
  FlipToBackOutlined,
  FlipToBackRounded,
  FlipToBackSharp,
  FlipToBackTwoTone,
  FlipToFront,
  FlipToFrontOutlined,
  FlipToFrontRounded,
  FlipToFrontSharp,
  FlipToFrontTwoTone,
  FlipTwoTone,
  Folder,
  FolderOpen,
  FolderOpenOutlined,
  FolderOpenRounded,
  FolderOpenSharp,
  FolderOpenTwoTone,
  FolderOutlined,
  FolderRounded,
  FolderShared,
  FolderSharedOutlined,
  FolderSharedRounded,
  FolderSharedSharp,
  FolderSharedTwoTone,
  FolderSharp,
  FolderSpecial,
  FolderSpecialOutlined,
  FolderSpecialRounded,
  FolderSpecialSharp,
  FolderSpecialTwoTone,
  FolderTwoTone,
  FontDownload,
  FontDownloadOutlined,
  FontDownloadRounded,
  FontDownloadSharp,
  FontDownloadTwoTone,
  FormatAlignCenter,
  FormatAlignCenterOutlined,
  FormatAlignCenterRounded,
  FormatAlignCenterSharp,
  FormatAlignCenterTwoTone,
  FormatAlignJustify,
  FormatAlignJustifyOutlined,
  FormatAlignJustifyRounded,
  FormatAlignJustifySharp,
  FormatAlignJustifyTwoTone,
  FormatAlignLeft,
  FormatAlignLeftOutlined,
  FormatAlignLeftRounded,
  FormatAlignLeftSharp,
  FormatAlignLeftTwoTone,
  FormatAlignRight,
  FormatAlignRightOutlined,
  FormatAlignRightRounded,
  FormatAlignRightSharp,
  FormatAlignRightTwoTone,
  FormatBold,
  FormatBoldOutlined,
  FormatBoldRounded,
  FormatBoldSharp,
  FormatBoldTwoTone,
  FormatClear,
  FormatClearOutlined,
  FormatClearRounded,
  FormatClearSharp,
  FormatClearTwoTone,
  FormatColorFill,
  FormatColorFillOutlined,
  FormatColorFillRounded,
  FormatColorFillSharp,
  FormatColorFillTwoTone,
  FormatColorReset,
  FormatColorResetOutlined,
  FormatColorResetRounded,
  FormatColorResetSharp,
  FormatColorResetTwoTone,
  FormatColorText,
  FormatColorTextOutlined,
  FormatColorTextRounded,
  FormatColorTextSharp,
  FormatColorTextTwoTone,
  FormatIndentDecrease,
  FormatIndentDecreaseOutlined,
  FormatIndentDecreaseRounded,
  FormatIndentDecreaseSharp,
  FormatIndentDecreaseTwoTone,
  FormatIndentIncrease,
  FormatIndentIncreaseOutlined,
  FormatIndentIncreaseRounded,
  FormatIndentIncreaseSharp,
  FormatIndentIncreaseTwoTone,
  FormatItalic,
  FormatItalicOutlined,
  FormatItalicRounded,
  FormatItalicSharp,
  FormatItalicTwoTone,
  FormatLineSpacing,
  FormatLineSpacingOutlined,
  FormatLineSpacingRounded,
  FormatLineSpacingSharp,
  FormatLineSpacingTwoTone,
  FormatListBulleted,
  FormatListBulletedOutlined,
  FormatListBulletedRounded,
  FormatListBulletedSharp,
  FormatListBulletedTwoTone,
  FormatListNumbered,
  FormatListNumberedOutlined,
  FormatListNumberedRounded,
  FormatListNumberedRtl,
  FormatListNumberedRtlOutlined,
  FormatListNumberedRtlRounded,
  FormatListNumberedRtlSharp,
  FormatListNumberedRtlTwoTone,
  FormatListNumberedSharp,
  FormatListNumberedTwoTone,
  FormatPaint,
  FormatPaintOutlined,
  FormatPaintRounded,
  FormatPaintSharp,
  FormatPaintTwoTone,
  FormatQuote,
  FormatQuoteOutlined,
  FormatQuoteRounded,
  FormatQuoteSharp,
  FormatQuoteTwoTone,
  FormatShapes,
  FormatShapesOutlined,
  FormatShapesRounded,
  FormatShapesSharp,
  FormatShapesTwoTone,
  FormatSize,
  FormatSizeOutlined,
  FormatSizeRounded,
  FormatSizeSharp,
  FormatSizeTwoTone,
  FormatStrikethrough,
  FormatStrikethroughOutlined,
  FormatStrikethroughRounded,
  FormatStrikethroughSharp,
  FormatStrikethroughTwoTone,
  FormatTextdirectionLToR,
  FormatTextdirectionLToROutlined,
  FormatTextdirectionLToRRounded,
  FormatTextdirectionLToRSharp,
  FormatTextdirectionLToRTwoTone,
  FormatTextdirectionRToL,
  FormatTextdirectionRToLOutlined,
  FormatTextdirectionRToLRounded,
  FormatTextdirectionRToLSharp,
  FormatTextdirectionRToLTwoTone,
  FormatUnderlined,
  FormatUnderlinedOutlined,
  FormatUnderlinedRounded,
  FormatUnderlinedSharp,
  FormatUnderlinedTwoTone,
  Forum,
  ForumOutlined,
  ForumRounded,
  ForumSharp,
  ForumTwoTone,
  Forward,
  Forward10,
  Forward10Outlined,
  Forward10Rounded,
  Forward10Sharp,
  Forward10TwoTone,
  Forward30,
  Forward30Outlined,
  Forward30Rounded,
  Forward30Sharp,
  Forward30TwoTone,
  Forward5,
  Forward5Outlined,
  Forward5Rounded,
  Forward5Sharp,
  Forward5TwoTone,
  ForwardOutlined,
  ForwardRounded,
  ForwardSharp,
  ForwardTwoTone,
  FourK,
  FourKOutlined,
  FourKRounded,
  FourKSharp,
  FourKTwoTone,
  FreeBreakfast,
  FreeBreakfastOutlined,
  FreeBreakfastRounded,
  FreeBreakfastSharp,
  FreeBreakfastTwoTone,
  Fullscreen,
  FullscreenExit,
  FullscreenExitOutlined,
  FullscreenExitRounded,
  FullscreenExitSharp,
  FullscreenExitTwoTone,
  FullscreenOutlined,
  FullscreenRounded,
  FullscreenSharp,
  FullscreenTwoTone,
  Functions,
  FunctionsOutlined,
  FunctionsRounded,
  FunctionsSharp,
  FunctionsTwoTone,
  Gamepad,
  GamepadOutlined,
  GamepadRounded,
  GamepadSharp,
  GamepadTwoTone,
  Games,
  GamesOutlined,
  GamesRounded,
  GamesSharp,
  GamesTwoTone,
  Gavel,
  GavelOutlined,
  GavelRounded,
  GavelSharp,
  GavelTwoTone,
  Gesture,
  GestureOutlined,
  GestureRounded,
  GestureSharp,
  GestureTwoTone,
  GetApp,
  GetAppOutlined,
  GetAppRounded,
  GetAppSharp,
  GetAppTwoTone,
  Gif,
  GifOutlined,
  GifRounded,
  GifSharp,
  GifTwoTone,
  GitHub,
  GolfCourse,
  GolfCourseOutlined,
  GolfCourseRounded,
  GolfCourseSharp,
  GolfCourseTwoTone,
  GpsFixed,
  GpsFixedOutlined,
  GpsFixedRounded,
  GpsFixedSharp,
  GpsFixedTwoTone,
  GpsNotFixed,
  GpsNotFixedOutlined,
  GpsNotFixedRounded,
  GpsNotFixedSharp,
  GpsNotFixedTwoTone,
  GpsOff,
  GpsOffOutlined,
  GpsOffRounded,
  GpsOffSharp,
  GpsOffTwoTone,
  Grade,
  GradeOutlined,
  GradeRounded,
  GradeSharp,
  GradeTwoTone,
  Gradient,
  GradientOutlined,
  GradientRounded,
  GradientSharp,
  GradientTwoTone,
  Grain,
  GrainOutlined,
  GrainRounded,
  GrainSharp,
  GrainTwoTone,
  GraphicEq,
  GraphicEqOutlined,
  GraphicEqRounded,
  GraphicEqSharp,
  GraphicEqTwoTone,
  GridOff,
  GridOffOutlined,
  GridOffRounded,
  GridOffSharp,
  GridOffTwoTone,
  GridOn,
  GridOnOutlined,
  GridOnRounded,
  GridOnSharp,
  GridOnTwoTone,
  Group,
  GroupAdd,
  GroupAddOutlined,
  GroupAddRounded,
  GroupAddSharp,
  GroupAddTwoTone,
  GroupOutlined,
  GroupRounded,
  GroupSharp,
  GroupTwoTone,
  GroupWork,
  GroupWorkOutlined,
  GroupWorkRounded,
  GroupWorkSharp,
  GroupWorkTwoTone,
  GTranslate,
  GTranslateOutlined,
  GTranslateRounded,
  GTranslateSharp,
  GTranslateTwoTone,
  Hd,
  HdOutlined,
  HdrOff,
  HdrOffOutlined,
  HdrOffRounded,
  HdrOffSharp,
  HdrOffTwoTone,
  HdrOn,
  HdrOnOutlined,
  HdrOnRounded,
  HdrOnSharp,
  HdrOnTwoTone,
  HdRounded,
  HdrStrong,
  HdrStrongOutlined,
  HdrStrongRounded,
  HdrStrongSharp,
  HdrStrongTwoTone,
  HdrWeak,
  HdrWeakOutlined,
  HdrWeakRounded,
  HdrWeakSharp,
  HdrWeakTwoTone,
  HdSharp,
  HdTwoTone,
  Headset,
  HeadsetMic,
  HeadsetMicOutlined,
  HeadsetMicRounded,
  HeadsetMicSharp,
  HeadsetMicTwoTone,
  HeadsetOutlined,
  HeadsetRounded,
  HeadsetSharp,
  HeadsetTwoTone,
  Healing,
  HealingOutlined,
  HealingRounded,
  HealingSharp,
  HealingTwoTone,
  Hearing,
  HearingOutlined,
  HearingRounded,
  HearingSharp,
  HearingTwoTone,
  Height,
  HeightOutlined,
  HeightRounded,
  HeightSharp,
  HeightTwoTone,
  Help,
  HelpOutline,
  HelpOutlined,
  HelpOutlineOutlined,
  HelpOutlineRounded,
  HelpOutlineSharp,
  HelpOutlineTwoTone,
  HelpRounded,
  HelpSharp,
  HelpTwoTone,
  Highlight,
  HighlightOff,
  HighlightOffOutlined,
  HighlightOffRounded,
  HighlightOffSharp,
  HighlightOffTwoTone,
  HighlightOutlined,
  HighlightRounded,
  HighlightSharp,
  HighlightTwoTone,
  HighQuality,
  HighQualityOutlined,
  HighQualityRounded,
  HighQualitySharp,
  HighQualityTwoTone,
  History,
  HistoryOutlined,
  HistoryRounded,
  HistorySharp,
  HistoryTwoTone,
  Home,
  HomeOutlined,
  HomeRounded,
  HomeSharp,
  HomeTwoTone,
  HomeWork,
  HomeWorkOutlined,
  HomeWorkRounded,
  HomeWorkSharp,
  HomeWorkTwoTone,
  HorizontalSplit,
  HorizontalSplitOutlined,
  HorizontalSplitRounded,
  HorizontalSplitSharp,
  HorizontalSplitTwoTone,
  Hotel,
  HotelOutlined,
  HotelRounded,
  HotelSharp,
  HotelTwoTone,
  HotTub,
  HotTubOutlined,
  HotTubRounded,
  HotTubSharp,
  HotTubTwoTone,
  HourglassEmpty,
  HourglassEmptyOutlined,
  HourglassEmptyRounded,
  HourglassEmptySharp,
  HourglassEmptyTwoTone,
  HourglassFull,
  HourglassFullOutlined,
  HourglassFullRounded,
  HourglassFullSharp,
  HourglassFullTwoTone,
  House,
  HouseOutlined,
  HouseRounded,
  HouseSharp,
  HouseTwoTone,
  HowToReg,
  HowToRegOutlined,
  HowToRegRounded,
  HowToRegSharp,
  HowToRegTwoTone,
  HowToVote,
  HowToVoteOutlined,
  HowToVoteRounded,
  HowToVoteSharp,
  HowToVoteTwoTone,
  Http,
  HttpOutlined,
  HttpRounded,
  Https,
  HttpSharp,
  HttpsOutlined,
  HttpsRounded,
  HttpsSharp,
  HttpsTwoTone,
  HttpTwoTone,
  Image,
  ImageAspectRatio,
  ImageAspectRatioOutlined,
  ImageAspectRatioRounded,
  ImageAspectRatioSharp,
  ImageAspectRatioTwoTone,
  ImageOutlined,
  ImageRounded,
  ImageSearch,
  ImageSearchOutlined,
  ImageSearchRounded,
  ImageSearchSharp,
  ImageSearchTwoTone,
  ImageSharp,
  ImageTwoTone,
  ImportantDevices,
  ImportantDevicesOutlined,
  ImportantDevicesRounded,
  ImportantDevicesSharp,
  ImportantDevicesTwoTone,
  ImportContacts,
  ImportContactsOutlined,
  ImportContactsRounded,
  ImportContactsSharp,
  ImportContactsTwoTone,
  ImportExport,
  ImportExportOutlined,
  ImportExportRounded,
  ImportExportSharp,
  ImportExportTwoTone,
  Inbox,
  InboxOutlined,
  InboxRounded,
  InboxSharp,
  InboxTwoTone,
  IndeterminateCheckBox,
  IndeterminateCheckBoxOutlined,
  IndeterminateCheckBoxRounded,
  IndeterminateCheckBoxSharp,
  IndeterminateCheckBoxTwoTone,
  Info,
  InfoOutlined,
  InfoRounded,
  InfoSharp,
  InfoTwoTone,
  Input,
  InputOutlined,
  InputRounded,
  InputSharp,
  InputTwoTone,
  InsertChart,
  InsertChartOutlined,
  InsertChartOutlinedOutlined,
  InsertChartOutlinedRounded,
  InsertChartOutlinedSharp,
  InsertChartOutlinedTwoTone,
  InsertChartRounded,
  InsertChartSharp,
  InsertChartTwoTone,
  InsertComment,
  InsertCommentOutlined,
  InsertCommentRounded,
  InsertCommentSharp,
  InsertCommentTwoTone,
  InsertDriveFile,
  InsertDriveFileOutlined,
  InsertDriveFileRounded,
  InsertDriveFileSharp,
  InsertDriveFileTwoTone,
  InsertEmoticon,
  InsertEmoticonOutlined,
  InsertEmoticonRounded,
  InsertEmoticonSharp,
  InsertEmoticonTwoTone,
  InsertInvitation,
  InsertInvitationOutlined,
  InsertInvitationRounded,
  InsertInvitationSharp,
  InsertInvitationTwoTone,
  InsertLink,
  InsertLinkOutlined,
  InsertLinkRounded,
  InsertLinkSharp,
  InsertLinkTwoTone,
  InsertPhoto,
  InsertPhotoOutlined,
  InsertPhotoRounded,
  InsertPhotoSharp,
  InsertPhotoTwoTone,
  Instagram,
  InvertColors,
  InvertColorsOff,
  InvertColorsOffOutlined,
  InvertColorsOffRounded,
  InvertColorsOffSharp,
  InvertColorsOffTwoTone,
  InvertColorsOutlined,
  InvertColorsRounded,
  InvertColorsSharp,
  InvertColorsTwoTone,
  Iso,
  IsoOutlined,
  IsoRounded,
  IsoSharp,
  IsoTwoTone,
  Keyboard,
  KeyboardArrowDown,
  KeyboardArrowDownOutlined,
  KeyboardArrowDownRounded,
  KeyboardArrowDownSharp,
  KeyboardArrowDownTwoTone,
  KeyboardArrowLeft,
  KeyboardArrowLeftOutlined,
  KeyboardArrowLeftRounded,
  KeyboardArrowLeftSharp,
  KeyboardArrowLeftTwoTone,
  KeyboardArrowRight,
  KeyboardArrowRightOutlined,
  KeyboardArrowRightRounded,
  KeyboardArrowRightSharp,
  KeyboardArrowRightTwoTone,
  KeyboardArrowUp,
  KeyboardArrowUpOutlined,
  KeyboardArrowUpRounded,
  KeyboardArrowUpSharp,
  KeyboardArrowUpTwoTone,
  KeyboardBackspace,
  KeyboardBackspaceOutlined,
  KeyboardBackspaceRounded,
  KeyboardBackspaceSharp,
  KeyboardBackspaceTwoTone,
  KeyboardCapslock,
  KeyboardCapslockOutlined,
  KeyboardCapslockRounded,
  KeyboardCapslockSharp,
  KeyboardCapslockTwoTone,
  KeyboardHide,
  KeyboardHideOutlined,
  KeyboardHideRounded,
  KeyboardHideSharp,
  KeyboardHideTwoTone,
  KeyboardOutlined,
  KeyboardReturn,
  KeyboardReturnOutlined,
  KeyboardReturnRounded,
  KeyboardReturnSharp,
  KeyboardReturnTwoTone,
  KeyboardRounded,
  KeyboardSharp,
  KeyboardTab,
  KeyboardTabOutlined,
  KeyboardTabRounded,
  KeyboardTabSharp,
  KeyboardTabTwoTone,
  KeyboardTwoTone,
  KeyboardVoice,
  KeyboardVoiceOutlined,
  KeyboardVoiceRounded,
  KeyboardVoiceSharp,
  KeyboardVoiceTwoTone,
  KingBed,
  KingBedOutlined,
  KingBedRounded,
  KingBedSharp,
  KingBedTwoTone,
  Kitchen,
  KitchenOutlined,
  KitchenRounded,
  KitchenSharp,
  KitchenTwoTone,
  Label,
  LabelImportant,
  LabelImportantOutlined,
  LabelImportantRounded,
  LabelImportantSharp,
  LabelImportantTwoTone,
  LabelOff,
  LabelOffOutlined,
  LabelOffRounded,
  LabelOffSharp,
  LabelOffTwoTone,
  LabelOutlined,
  LabelRounded,
  LabelSharp,
  LabelTwoTone,
  Landscape,
  LandscapeOutlined,
  LandscapeRounded,
  LandscapeSharp,
  LandscapeTwoTone,
  Language,
  LanguageOutlined,
  LanguageRounded,
  LanguageSharp,
  LanguageTwoTone,
  Laptop,
  LaptopChromebook,
  LaptopChromebookOutlined,
  LaptopChromebookRounded,
  LaptopChromebookSharp,
  LaptopChromebookTwoTone,
  LaptopMac,
  LaptopMacOutlined,
  LaptopMacRounded,
  LaptopMacSharp,
  LaptopMacTwoTone,
  LaptopOutlined,
  LaptopRounded,
  LaptopSharp,
  LaptopTwoTone,
  LaptopWindows,
  LaptopWindowsOutlined,
  LaptopWindowsRounded,
  LaptopWindowsSharp,
  LaptopWindowsTwoTone,
  LastPage,
  LastPageOutlined,
  LastPageRounded,
  LastPageSharp,
  LastPageTwoTone,
  Launch,
  LaunchOutlined,
  LaunchRounded,
  LaunchSharp,
  LaunchTwoTone,
  Layers,
  LayersClear,
  LayersClearOutlined,
  LayersClearRounded,
  LayersClearSharp,
  LayersClearTwoTone,
  LayersOutlined,
  LayersRounded,
  LayersSharp,
  LayersTwoTone,
  LeakAdd,
  LeakAddOutlined,
  LeakAddRounded,
  LeakAddSharp,
  LeakAddTwoTone,
  LeakRemove,
  LeakRemoveOutlined,
  LeakRemoveRounded,
  LeakRemoveSharp,
  LeakRemoveTwoTone,
  Lens,
  LensOutlined,
  LensRounded,
  LensSharp,
  LensTwoTone,
  LibraryAdd,
  LibraryAddCheck,
  LibraryAddCheckOutlined,
  LibraryAddCheckRounded,
  LibraryAddCheckSharp,
  LibraryAddCheckTwoTone,
  LibraryAddOutlined,
  LibraryAddRounded,
  LibraryAddSharp,
  LibraryAddTwoTone,
  LibraryBooks,
  LibraryBooksOutlined,
  LibraryBooksRounded,
  LibraryBooksSharp,
  LibraryBooksTwoTone,
  LibraryMusic,
  LibraryMusicOutlined,
  LibraryMusicRounded,
  LibraryMusicSharp,
  LibraryMusicTwoTone,
  LinearScale,
  LinearScaleOutlined,
  LinearScaleRounded,
  LinearScaleSharp,
  LinearScaleTwoTone,
  LineStyle,
  LineStyleOutlined,
  LineStyleRounded,
  LineStyleSharp,
  LineStyleTwoTone,
  LineWeight,
  LineWeightOutlined,
  LineWeightRounded,
  LineWeightSharp,
  LineWeightTwoTone,
  Link,
  LinkedCamera,
  LinkedCameraOutlined,
  LinkedCameraRounded,
  LinkedCameraSharp,
  LinkedCameraTwoTone,
  LinkedIn,
  LinkOff,
  LinkOffOutlined,
  LinkOffRounded,
  LinkOffSharp,
  LinkOffTwoTone,
  LinkOutlined,
  LinkRounded,
  LinkSharp,
  LinkTwoTone,
  List,
  ListAlt,
  ListAltOutlined,
  ListAltRounded,
  ListAltSharp,
  ListAltTwoTone,
  ListOutlined,
  ListRounded,
  ListSharp,
  ListTwoTone,
  LiveHelp,
  LiveHelpOutlined,
  LiveHelpRounded,
  LiveHelpSharp,
  LiveHelpTwoTone,
  LiveTv,
  LiveTvOutlined,
  LiveTvRounded,
  LiveTvSharp,
  LiveTvTwoTone,
  LocalActivity,
  LocalActivityOutlined,
  LocalActivityRounded,
  LocalActivitySharp,
  LocalActivityTwoTone,
  LocalAirport,
  LocalAirportOutlined,
  LocalAirportRounded,
  LocalAirportSharp,
  LocalAirportTwoTone,
  LocalAtm,
  LocalAtmOutlined,
  LocalAtmRounded,
  LocalAtmSharp,
  LocalAtmTwoTone,
  LocalBar,
  LocalBarOutlined,
  LocalBarRounded,
  LocalBarSharp,
  LocalBarTwoTone,
  LocalCafe,
  LocalCafeOutlined,
  LocalCafeRounded,
  LocalCafeSharp,
  LocalCafeTwoTone,
  LocalCarWash,
  LocalCarWashOutlined,
  LocalCarWashRounded,
  LocalCarWashSharp,
  LocalCarWashTwoTone,
  LocalConvenienceStore,
  LocalConvenienceStoreOutlined,
  LocalConvenienceStoreRounded,
  LocalConvenienceStoreSharp,
  LocalConvenienceStoreTwoTone,
  LocalDining,
  LocalDiningOutlined,
  LocalDiningRounded,
  LocalDiningSharp,
  LocalDiningTwoTone,
  LocalDrink,
  LocalDrinkOutlined,
  LocalDrinkRounded,
  LocalDrinkSharp,
  LocalDrinkTwoTone,
  LocalFlorist,
  LocalFloristOutlined,
  LocalFloristRounded,
  LocalFloristSharp,
  LocalFloristTwoTone,
  LocalGasStation,
  LocalGasStationOutlined,
  LocalGasStationRounded,
  LocalGasStationSharp,
  LocalGasStationTwoTone,
  LocalGroceryStore,
  LocalGroceryStoreOutlined,
  LocalGroceryStoreRounded,
  LocalGroceryStoreSharp,
  LocalGroceryStoreTwoTone,
  LocalHospital,
  LocalHospitalOutlined,
  LocalHospitalRounded,
  LocalHospitalSharp,
  LocalHospitalTwoTone,
  LocalHotel,
  LocalHotelOutlined,
  LocalHotelRounded,
  LocalHotelSharp,
  LocalHotelTwoTone,
  LocalLaundryService,
  LocalLaundryServiceOutlined,
  LocalLaundryServiceRounded,
  LocalLaundryServiceSharp,
  LocalLaundryServiceTwoTone,
  LocalLibrary,
  LocalLibraryOutlined,
  LocalLibraryRounded,
  LocalLibrarySharp,
  LocalLibraryTwoTone,
  LocalMall,
  LocalMallOutlined,
  LocalMallRounded,
  LocalMallSharp,
  LocalMallTwoTone,
  LocalMovies,
  LocalMoviesOutlined,
  LocalMoviesRounded,
  LocalMoviesSharp,
  LocalMoviesTwoTone,
  LocalOffer,
  LocalOfferOutlined,
  LocalOfferRounded,
  LocalOfferSharp,
  LocalOfferTwoTone,
  LocalParking,
  LocalParkingOutlined,
  LocalParkingRounded,
  LocalParkingSharp,
  LocalParkingTwoTone,
  LocalPharmacy,
  LocalPharmacyOutlined,
  LocalPharmacyRounded,
  LocalPharmacySharp,
  LocalPharmacyTwoTone,
  LocalPhone,
  LocalPhoneOutlined,
  LocalPhoneRounded,
  LocalPhoneSharp,
  LocalPhoneTwoTone,
  LocalPizza,
  LocalPizzaOutlined,
  LocalPizzaRounded,
  LocalPizzaSharp,
  LocalPizzaTwoTone,
  LocalPlay,
  LocalPlayOutlined,
  LocalPlayRounded,
  LocalPlaySharp,
  LocalPlayTwoTone,
  LocalPostOffice,
  LocalPostOfficeOutlined,
  LocalPostOfficeRounded,
  LocalPostOfficeSharp,
  LocalPostOfficeTwoTone,
  LocalPrintshop,
  LocalPrintshopOutlined,
  LocalPrintshopRounded,
  LocalPrintshopSharp,
  LocalPrintshopTwoTone,
  LocalSee,
  LocalSeeOutlined,
  LocalSeeRounded,
  LocalSeeSharp,
  LocalSeeTwoTone,
  LocalShipping,
  LocalShippingOutlined,
  LocalShippingRounded,
  LocalShippingSharp,
  LocalShippingTwoTone,
  LocalTaxi,
  LocalTaxiOutlined,
  LocalTaxiRounded,
  LocalTaxiSharp,
  LocalTaxiTwoTone,
  LocationCity,
  LocationCityOutlined,
  LocationCityRounded,
  LocationCitySharp,
  LocationCityTwoTone,
  LocationDisabled,
  LocationDisabledOutlined,
  LocationDisabledRounded,
  LocationDisabledSharp,
  LocationDisabledTwoTone,
  LocationOff,
  LocationOffOutlined,
  LocationOffRounded,
  LocationOffSharp,
  LocationOffTwoTone,
  LocationOn,
  LocationOnOutlined,
  LocationOnRounded,
  LocationOnSharp,
  LocationOnTwoTone,
  LocationSearching,
  LocationSearchingOutlined,
  LocationSearchingRounded,
  LocationSearchingSharp,
  LocationSearchingTwoTone,
  Lock,
  LockOpen,
  LockOpenOutlined,
  LockOpenRounded,
  LockOpenSharp,
  LockOpenTwoTone,
  LockOutlined,
  LockRounded,
  LockSharp,
  LockTwoTone,
  Looks,
  Looks3,
  Looks3Outlined,
  Looks3Rounded,
  Looks3Sharp,
  Looks3TwoTone,
  Looks4,
  Looks4Outlined,
  Looks4Rounded,
  Looks4Sharp,
  Looks4TwoTone,
  Looks5,
  Looks5Outlined,
  Looks5Rounded,
  Looks5Sharp,
  Looks5TwoTone,
  Looks6,
  Looks6Outlined,
  Looks6Rounded,
  Looks6Sharp,
  Looks6TwoTone,
  LooksOne,
  LooksOneOutlined,
  LooksOneRounded,
  LooksOneSharp,
  LooksOneTwoTone,
  LooksOutlined,
  LooksRounded,
  LooksSharp,
  LooksTwo,
  LooksTwoOutlined,
  LooksTwoRounded,
  LooksTwoSharp,
  LooksTwoTone,
  LooksTwoTwoTone,
  Loop,
  LoopOutlined,
  LoopRounded,
  LoopSharp,
  LoopTwoTone,
  Loupe,
  LoupeOutlined,
  LoupeRounded,
  LoupeSharp,
  LoupeTwoTone,
  LowPriority,
  LowPriorityOutlined,
  LowPriorityRounded,
  LowPrioritySharp,
  LowPriorityTwoTone,
  Loyalty,
  LoyaltyOutlined,
  LoyaltyRounded,
  LoyaltySharp,
  LoyaltyTwoTone,
  Mail,
  MailOutline,
  MailOutlined,
  MailOutlineOutlined,
  MailOutlineRounded,
  MailOutlineSharp,
  MailOutlineTwoTone,
  MailRounded,
  MailSharp,
  MailTwoTone,
  Map,
  MapOutlined,
  MapRounded,
  MapSharp,
  MapTwoTone,
  Markunread,
  MarkunreadMailbox,
  MarkunreadMailboxOutlined,
  MarkunreadMailboxRounded,
  MarkunreadMailboxSharp,
  MarkunreadMailboxTwoTone,
  MarkunreadOutlined,
  MarkunreadRounded,
  MarkunreadSharp,
  MarkunreadTwoTone,
  Maximize,
  MaximizeOutlined,
  MaximizeRounded,
  MaximizeSharp,
  MaximizeTwoTone,
  MeetingRoom,
  MeetingRoomOutlined,
  MeetingRoomRounded,
  MeetingRoomSharp,
  MeetingRoomTwoTone,
  Memory,
  MemoryOutlined,
  MemoryRounded,
  MemorySharp,
  MemoryTwoTone,
  Menu,
  MenuBook,
  MenuBookOutlined,
  MenuBookRounded,
  MenuBookSharp,
  MenuBookTwoTone,
  MenuOpen,
  MenuOpenOutlined,
  MenuOpenRounded,
  MenuOpenSharp,
  MenuOpenTwoTone,
  MenuOutlined,
  MenuRounded,
  MenuSharp,
  MenuTwoTone,
  MergeType,
  MergeTypeOutlined,
  MergeTypeRounded,
  MergeTypeSharp,
  MergeTypeTwoTone,
  Message,
  MessageOutlined,
  MessageRounded,
  MessageSharp,
  MessageTwoTone,
  Mic,
  MicNone,
  MicNoneOutlined,
  MicNoneRounded,
  MicNoneSharp,
  MicNoneTwoTone,
  MicOff,
  MicOffOutlined,
  MicOffRounded,
  MicOffSharp,
  MicOffTwoTone,
  MicOutlined,
  MicRounded,
  MicSharp,
  MicTwoTone,
  Minimize,
  MinimizeOutlined,
  MinimizeRounded,
  MinimizeSharp,
  MinimizeTwoTone,
  MissedVideoCall,
  MissedVideoCallOutlined,
  MissedVideoCallRounded,
  MissedVideoCallSharp,
  MissedVideoCallTwoTone,
  Mms,
  MmsOutlined,
  MmsRounded,
  MmsSharp,
  MmsTwoTone,
  MobileFriendly,
  MobileFriendlyOutlined,
  MobileFriendlyRounded,
  MobileFriendlySharp,
  MobileFriendlyTwoTone,
  MobileOff,
  MobileOffOutlined,
  MobileOffRounded,
  MobileOffSharp,
  MobileOffTwoTone,
  MobileScreenShare,
  MobileScreenShareOutlined,
  MobileScreenShareRounded,
  MobileScreenShareSharp,
  MobileScreenShareTwoTone,
  ModeComment,
  ModeCommentOutlined,
  ModeCommentRounded,
  ModeCommentSharp,
  ModeCommentTwoTone,
  MonetizationOn,
  MonetizationOnOutlined,
  MonetizationOnRounded,
  MonetizationOnSharp,
  MonetizationOnTwoTone,
  Money,
  MoneyOff,
  MoneyOffOutlined,
  MoneyOffRounded,
  MoneyOffSharp,
  MoneyOffTwoTone,
  MoneyOutlined,
  MoneyRounded,
  MoneySharp,
  MoneyTwoTone,
  MonochromePhotos,
  MonochromePhotosOutlined,
  MonochromePhotosRounded,
  MonochromePhotosSharp,
  MonochromePhotosTwoTone,
  Mood,
  MoodBad,
  MoodBadOutlined,
  MoodBadRounded,
  MoodBadSharp,
  MoodBadTwoTone,
  MoodOutlined,
  MoodRounded,
  MoodSharp,
  MoodTwoTone,
  More,
  MoreHoriz,
  MoreHorizOutlined,
  MoreHorizRounded,
  MoreHorizSharp,
  MoreHorizTwoTone,
  MoreOutlined,
  MoreRounded,
  MoreSharp,
  MoreTwoTone,
  MoreVert,
  MoreVertOutlined,
  MoreVertRounded,
  MoreVertSharp,
  MoreVertTwoTone,
  Motorcycle,
  MotorcycleOutlined,
  MotorcycleRounded,
  MotorcycleSharp,
  MotorcycleTwoTone,
  Mouse,
  MouseOutlined,
  MouseRounded,
  MouseSharp,
  MouseTwoTone,
  MoveToInbox,
  MoveToInboxOutlined,
  MoveToInboxRounded,
  MoveToInboxSharp,
  MoveToInboxTwoTone,
  Movie,
  MovieCreation,
  MovieCreationOutlined,
  MovieCreationRounded,
  MovieCreationSharp,
  MovieCreationTwoTone,
  MovieFilter,
  MovieFilterOutlined,
  MovieFilterRounded,
  MovieFilterSharp,
  MovieFilterTwoTone,
  MovieOutlined,
  MovieRounded,
  MovieSharp,
  MovieTwoTone,
  MultilineChart,
  MultilineChartOutlined,
  MultilineChartRounded,
  MultilineChartSharp,
  MultilineChartTwoTone,
  Museum,
  MuseumOutlined,
  MuseumRounded,
  MuseumSharp,
  MuseumTwoTone,
  MusicNote,
  MusicNoteOutlined,
  MusicNoteRounded,
  MusicNoteSharp,
  MusicNoteTwoTone,
  MusicOff,
  MusicOffOutlined,
  MusicOffRounded,
  MusicOffSharp,
  MusicOffTwoTone,
  MusicVideo,
  MusicVideoOutlined,
  MusicVideoRounded,
  MusicVideoSharp,
  MusicVideoTwoTone,
  MyLocation,
  MyLocationOutlined,
  MyLocationRounded,
  MyLocationSharp,
  MyLocationTwoTone,
  Nature,
  NatureOutlined,
  NaturePeople,
  NaturePeopleOutlined,
  NaturePeopleRounded,
  NaturePeopleSharp,
  NaturePeopleTwoTone,
  NatureRounded,
  NatureSharp,
  NatureTwoTone,
  NavigateBefore,
  NavigateBeforeOutlined,
  NavigateBeforeRounded,
  NavigateBeforeSharp,
  NavigateBeforeTwoTone,
  NavigateNext,
  NavigateNextOutlined,
  NavigateNextRounded,
  NavigateNextSharp,
  NavigateNextTwoTone,
  Navigation,
  NavigationOutlined,
  NavigationRounded,
  NavigationSharp,
  NavigationTwoTone,
  NearMe,
  NearMeOutlined,
  NearMeRounded,
  NearMeSharp,
  NearMeTwoTone,
  NetworkCell,
  NetworkCellOutlined,
  NetworkCellRounded,
  NetworkCellSharp,
  NetworkCellTwoTone,
  NetworkCheck,
  NetworkCheckOutlined,
  NetworkCheckRounded,
  NetworkCheckSharp,
  NetworkCheckTwoTone,
  NetworkLocked,
  NetworkLockedOutlined,
  NetworkLockedRounded,
  NetworkLockedSharp,
  NetworkLockedTwoTone,
  NetworkWifi,
  NetworkWifiOutlined,
  NetworkWifiRounded,
  NetworkWifiSharp,
  NetworkWifiTwoTone,
  NewReleases,
  NewReleasesOutlined,
  NewReleasesRounded,
  NewReleasesSharp,
  NewReleasesTwoTone,
  NextWeek,
  NextWeekOutlined,
  NextWeekRounded,
  NextWeekSharp,
  NextWeekTwoTone,
  Nfc,
  NfcOutlined,
  NfcRounded,
  NfcSharp,
  NfcTwoTone,
  NightsStay,
  NightsStayOutlined,
  NightsStayRounded,
  NightsStaySharp,
  NightsStayTwoTone,
  NoEncryption,
  NoEncryptionOutlined,
  NoEncryptionRounded,
  NoEncryptionSharp,
  NoEncryptionTwoTone,
  NoMeetingRoom,
  NoMeetingRoomOutlined,
  NoMeetingRoomRounded,
  NoMeetingRoomSharp,
  NoMeetingRoomTwoTone,
  NoSim,
  NoSimOutlined,
  NoSimRounded,
  NoSimSharp,
  NoSimTwoTone,
  Note,
  NoteAdd,
  NoteAddOutlined,
  NoteAddRounded,
  NoteAddSharp,
  NoteAddTwoTone,
  NoteOutlined,
  NoteRounded,
  Notes,
  NoteSharp,
  NotesOutlined,
  NotesRounded,
  NotesSharp,
  NotesTwoTone,
  NoteTwoTone,
  NotificationImportant,
  NotificationImportantOutlined,
  NotificationImportantRounded,
  NotificationImportantSharp,
  NotificationImportantTwoTone,
  Notifications,
  NotificationsActive,
  NotificationsActiveOutlined,
  NotificationsActiveRounded,
  NotificationsActiveSharp,
  NotificationsActiveTwoTone,
  NotificationsNone,
  NotificationsNoneOutlined,
  NotificationsNoneRounded,
  NotificationsNoneSharp,
  NotificationsNoneTwoTone,
  NotificationsOff,
  NotificationsOffOutlined,
  NotificationsOffRounded,
  NotificationsOffSharp,
  NotificationsOffTwoTone,
  NotificationsOutlined,
  NotificationsPaused,
  NotificationsPausedOutlined,
  NotificationsPausedRounded,
  NotificationsPausedSharp,
  NotificationsPausedTwoTone,
  NotificationsRounded,
  NotificationsSharp,
  NotificationsTwoTone,
  NotInterested,
  NotInterestedOutlined,
  NotInterestedRounded,
  NotInterestedSharp,
  NotInterestedTwoTone,
  NotListedLocation,
  NotListedLocationOutlined,
  NotListedLocationRounded,
  NotListedLocationSharp,
  NotListedLocationTwoTone,
  OfflineBolt,
  OfflineBoltOutlined,
  OfflineBoltRounded,
  OfflineBoltSharp,
  OfflineBoltTwoTone,
  OfflinePin,
  OfflinePinOutlined,
  OfflinePinRounded,
  OfflinePinSharp,
  OfflinePinTwoTone,
  OndemandVideo,
  OndemandVideoOutlined,
  OndemandVideoRounded,
  OndemandVideoSharp,
  OndemandVideoTwoTone,
  Opacity,
  OpacityOutlined,
  OpacityRounded,
  OpacitySharp,
  OpacityTwoTone,
  OpenInBrowser,
  OpenInBrowserOutlined,
  OpenInBrowserRounded,
  OpenInBrowserSharp,
  OpenInBrowserTwoTone,
  OpenInNew,
  OpenInNewOutlined,
  OpenInNewRounded,
  OpenInNewSharp,
  OpenInNewTwoTone,
  OpenWith,
  OpenWithOutlined,
  OpenWithRounded,
  OpenWithSharp,
  OpenWithTwoTone,
  OutdoorGrill,
  OutdoorGrillOutlined,
  OutdoorGrillRounded,
  OutdoorGrillSharp,
  OutdoorGrillTwoTone,
  OutlinedFlag,
  OutlinedFlagOutlined,
  OutlinedFlagRounded,
  OutlinedFlagSharp,
  OutlinedFlagTwoTone,
  Pages,
  PagesOutlined,
  PagesRounded,
  PagesSharp,
  PagesTwoTone,
  Pageview,
  PageviewOutlined,
  PageviewRounded,
  PageviewSharp,
  PageviewTwoTone,
  Palette,
  PaletteOutlined,
  PaletteRounded,
  PaletteSharp,
  PaletteTwoTone,
  Panorama,
  PanoramaFishEye,
  PanoramaFishEyeOutlined,
  PanoramaFishEyeRounded,
  PanoramaFishEyeSharp,
  PanoramaFishEyeTwoTone,
  PanoramaHorizontal,
  PanoramaHorizontalOutlined,
  PanoramaHorizontalRounded,
  PanoramaHorizontalSharp,
  PanoramaHorizontalTwoTone,
  PanoramaOutlined,
  PanoramaRounded,
  PanoramaSharp,
  PanoramaTwoTone,
  PanoramaVertical,
  PanoramaVerticalOutlined,
  PanoramaVerticalRounded,
  PanoramaVerticalSharp,
  PanoramaVerticalTwoTone,
  PanoramaWideAngle,
  PanoramaWideAngleOutlined,
  PanoramaWideAngleRounded,
  PanoramaWideAngleSharp,
  PanoramaWideAngleTwoTone,
  PanTool,
  PanToolOutlined,
  PanToolRounded,
  PanToolSharp,
  PanToolTwoTone,
  PartyMode,
  PartyModeOutlined,
  PartyModeRounded,
  PartyModeSharp,
  PartyModeTwoTone,
  Pause,
  PauseCircleFilled,
  PauseCircleFilledOutlined,
  PauseCircleFilledRounded,
  PauseCircleFilledSharp,
  PauseCircleFilledTwoTone,
  PauseCircleOutline,
  PauseCircleOutlineOutlined,
  PauseCircleOutlineRounded,
  PauseCircleOutlineSharp,
  PauseCircleOutlineTwoTone,
  PauseOutlined,
  PausePresentation,
  PausePresentationOutlined,
  PausePresentationRounded,
  PausePresentationSharp,
  PausePresentationTwoTone,
  PauseRounded,
  PauseSharp,
  PauseTwoTone,
  Payment,
  PaymentOutlined,
  PaymentRounded,
  PaymentSharp,
  PaymentTwoTone,
  People,
  PeopleAlt,
  PeopleAltOutlined,
  PeopleAltRounded,
  PeopleAltSharp,
  PeopleAltTwoTone,
  PeopleOutline,
  PeopleOutlined,
  PeopleOutlineOutlined,
  PeopleOutlineRounded,
  PeopleOutlineSharp,
  PeopleOutlineTwoTone,
  PeopleRounded,
  PeopleSharp,
  PeopleTwoTone,
  PermCameraMic,
  PermCameraMicOutlined,
  PermCameraMicRounded,
  PermCameraMicSharp,
  PermCameraMicTwoTone,
  PermContactCalendar,
  PermContactCalendarOutlined,
  PermContactCalendarRounded,
  PermContactCalendarSharp,
  PermContactCalendarTwoTone,
  PermDataSetting,
  PermDataSettingOutlined,
  PermDataSettingRounded,
  PermDataSettingSharp,
  PermDataSettingTwoTone,
  PermDeviceInformation,
  PermDeviceInformationOutlined,
  PermDeviceInformationRounded,
  PermDeviceInformationSharp,
  PermDeviceInformationTwoTone,
  PermIdentity,
  PermIdentityOutlined,
  PermIdentityRounded,
  PermIdentitySharp,
  PermIdentityTwoTone,
  PermMedia,
  PermMediaOutlined,
  PermMediaRounded,
  PermMediaSharp,
  PermMediaTwoTone,
  PermPhoneMsg,
  PermPhoneMsgOutlined,
  PermPhoneMsgRounded,
  PermPhoneMsgSharp,
  PermPhoneMsgTwoTone,
  PermScanWifi,
  PermScanWifiOutlined,
  PermScanWifiRounded,
  PermScanWifiSharp,
  PermScanWifiTwoTone,
  Person,
  PersonAdd,
  PersonAddDisabled,
  PersonAddDisabledOutlined,
  PersonAddDisabledRounded,
  PersonAddDisabledSharp,
  PersonAddDisabledTwoTone,
  PersonAddOutlined,
  PersonAddRounded,
  PersonAddSharp,
  PersonAddTwoTone,
  PersonalVideo,
  PersonalVideoOutlined,
  PersonalVideoRounded,
  PersonalVideoSharp,
  PersonalVideoTwoTone,
  PersonOutline,
  PersonOutlined,
  PersonOutlineOutlined,
  PersonOutlineRounded,
  PersonOutlineSharp,
  PersonOutlineTwoTone,
  PersonPin,
  PersonPinCircle,
  PersonPinCircleOutlined,
  PersonPinCircleRounded,
  PersonPinCircleSharp,
  PersonPinCircleTwoTone,
  PersonPinOutlined,
  PersonPinRounded,
  PersonPinSharp,
  PersonPinTwoTone,
  PersonRounded,
  PersonSharp,
  PersonTwoTone,
  Pets,
  PetsOutlined,
  PetsRounded,
  PetsSharp,
  PetsTwoTone,
  Phone,
  PhoneAndroid,
  PhoneAndroidOutlined,
  PhoneAndroidRounded,
  PhoneAndroidSharp,
  PhoneAndroidTwoTone,
  PhoneBluetoothSpeaker,
  PhoneBluetoothSpeakerOutlined,
  PhoneBluetoothSpeakerRounded,
  PhoneBluetoothSpeakerSharp,
  PhoneBluetoothSpeakerTwoTone,
  PhoneCallback,
  PhoneCallbackOutlined,
  PhoneCallbackRounded,
  PhoneCallbackSharp,
  PhoneCallbackTwoTone,
  PhoneDisabled,
  PhoneDisabledOutlined,
  PhoneDisabledRounded,
  PhoneDisabledSharp,
  PhoneDisabledTwoTone,
  PhoneEnabled,
  PhoneEnabledOutlined,
  PhoneEnabledRounded,
  PhoneEnabledSharp,
  PhoneEnabledTwoTone,
  PhoneForwarded,
  PhoneForwardedOutlined,
  PhoneForwardedRounded,
  PhoneForwardedSharp,
  PhoneForwardedTwoTone,
  PhoneInTalk,
  PhoneInTalkOutlined,
  PhoneInTalkRounded,
  PhoneInTalkSharp,
  PhoneInTalkTwoTone,
  PhoneIphone,
  PhoneIphoneOutlined,
  PhoneIphoneRounded,
  PhoneIphoneSharp,
  PhoneIphoneTwoTone,
  Phonelink,
  PhonelinkErase,
  PhonelinkEraseOutlined,
  PhonelinkEraseRounded,
  PhonelinkEraseSharp,
  PhonelinkEraseTwoTone,
  PhonelinkLock,
  PhonelinkLockOutlined,
  PhonelinkLockRounded,
  PhonelinkLockSharp,
  PhonelinkLockTwoTone,
  PhonelinkOff,
  PhonelinkOffOutlined,
  PhonelinkOffRounded,
  PhonelinkOffSharp,
  PhonelinkOffTwoTone,
  PhonelinkOutlined,
  PhonelinkRing,
  PhonelinkRingOutlined,
  PhonelinkRingRounded,
  PhonelinkRingSharp,
  PhonelinkRingTwoTone,
  PhonelinkRounded,
  PhonelinkSetup,
  PhonelinkSetupOutlined,
  PhonelinkSetupRounded,
  PhonelinkSetupSharp,
  PhonelinkSetupTwoTone,
  PhonelinkSharp,
  PhonelinkTwoTone,
  PhoneLocked,
  PhoneLockedOutlined,
  PhoneLockedRounded,
  PhoneLockedSharp,
  PhoneLockedTwoTone,
  PhoneMissed,
  PhoneMissedOutlined,
  PhoneMissedRounded,
  PhoneMissedSharp,
  PhoneMissedTwoTone,
  PhoneOutlined,
  PhonePaused,
  PhonePausedOutlined,
  PhonePausedRounded,
  PhonePausedSharp,
  PhonePausedTwoTone,
  PhoneRounded,
  PhoneSharp,
  PhoneTwoTone,
  Photo,
  PhotoAlbum,
  PhotoAlbumOutlined,
  PhotoAlbumRounded,
  PhotoAlbumSharp,
  PhotoAlbumTwoTone,
  PhotoCamera,
  PhotoCameraOutlined,
  PhotoCameraRounded,
  PhotoCameraSharp,
  PhotoCameraTwoTone,
  PhotoFilter,
  PhotoFilterOutlined,
  PhotoFilterRounded,
  PhotoFilterSharp,
  PhotoFilterTwoTone,
  PhotoLibrary,
  PhotoLibraryOutlined,
  PhotoLibraryRounded,
  PhotoLibrarySharp,
  PhotoLibraryTwoTone,
  PhotoOutlined,
  PhotoRounded,
  PhotoSharp,
  PhotoSizeSelectActual,
  PhotoSizeSelectActualOutlined,
  PhotoSizeSelectActualRounded,
  PhotoSizeSelectActualSharp,
  PhotoSizeSelectActualTwoTone,
  PhotoSizeSelectLarge,
  PhotoSizeSelectLargeOutlined,
  PhotoSizeSelectLargeRounded,
  PhotoSizeSelectLargeSharp,
  PhotoSizeSelectLargeTwoTone,
  PhotoSizeSelectSmall,
  PhotoSizeSelectSmallOutlined,
  PhotoSizeSelectSmallRounded,
  PhotoSizeSelectSmallSharp,
  PhotoSizeSelectSmallTwoTone,
  PhotoTwoTone,
  PictureAsPdf,
  PictureAsPdfOutlined,
  PictureAsPdfRounded,
  PictureAsPdfSharp,
  PictureAsPdfTwoTone,
  PictureInPicture,
  PictureInPictureAlt,
  PictureInPictureAltOutlined,
  PictureInPictureAltRounded,
  PictureInPictureAltSharp,
  PictureInPictureAltTwoTone,
  PictureInPictureOutlined,
  PictureInPictureRounded,
  PictureInPictureSharp,
  PictureInPictureTwoTone,
  PieChart,
  PieChartOutlined,
  PieChartRounded,
  PieChartSharp,
  PieChartTwoTone,
  PinDrop,
  PinDropOutlined,
  PinDropRounded,
  PinDropSharp,
  PinDropTwoTone,
  Pinterest,
  Place,
  PlaceOutlined,
  PlaceRounded,
  PlaceSharp,
  PlaceTwoTone,
  PlayArrow,
  PlayArrowOutlined,
  PlayArrowRounded,
  PlayArrowSharp,
  PlayArrowTwoTone,
  PlayCircleFilled,
  PlayCircleFilledOutlined,
  PlayCircleFilledRounded,
  PlayCircleFilledSharp,
  PlayCircleFilledTwoTone,
  PlayCircleFilledWhite,
  PlayCircleFilledWhiteOutlined,
  PlayCircleFilledWhiteRounded,
  PlayCircleFilledWhiteSharp,
  PlayCircleFilledWhiteTwoTone,
  PlayCircleOutline,
  PlayCircleOutlineOutlined,
  PlayCircleOutlineRounded,
  PlayCircleOutlineSharp,
  PlayCircleOutlineTwoTone,
  PlayForWork,
  PlayForWorkOutlined,
  PlayForWorkRounded,
  PlayForWorkSharp,
  PlayForWorkTwoTone,
  PlaylistAdd,
  PlaylistAddCheck,
  PlaylistAddCheckOutlined,
  PlaylistAddCheckRounded,
  PlaylistAddCheckSharp,
  PlaylistAddCheckTwoTone,
  PlaylistAddOutlined,
  PlaylistAddRounded,
  PlaylistAddSharp,
  PlaylistAddTwoTone,
  PlaylistPlay,
  PlaylistPlayOutlined,
  PlaylistPlayRounded,
  PlaylistPlaySharp,
  PlaylistPlayTwoTone,
  PlusOne,
  PlusOneOutlined,
  PlusOneRounded,
  PlusOneSharp,
  PlusOneTwoTone,
  Policy,
  PolicyOutlined,
  PolicyRounded,
  PolicySharp,
  PolicyTwoTone,
  Poll,
  PollOutlined,
  PollRounded,
  PollSharp,
  PollTwoTone,
  Polymer,
  PolymerOutlined,
  PolymerRounded,
  PolymerSharp,
  PolymerTwoTone,
  Pool,
  PoolOutlined,
  PoolRounded,
  PoolSharp,
  PoolTwoTone,
  PortableWifiOff,
  PortableWifiOffOutlined,
  PortableWifiOffRounded,
  PortableWifiOffSharp,
  PortableWifiOffTwoTone,
  Portrait,
  PortraitOutlined,
  PortraitRounded,
  PortraitSharp,
  PortraitTwoTone,
  PostAdd,
  PostAddOutlined,
  PostAddRounded,
  PostAddSharp,
  PostAddTwoTone,
  Power,
  PowerInput,
  PowerInputOutlined,
  PowerInputRounded,
  PowerInputSharp,
  PowerInputTwoTone,
  PowerOff,
  PowerOffOutlined,
  PowerOffRounded,
  PowerOffSharp,
  PowerOffTwoTone,
  PowerOutlined,
  PowerRounded,
  PowerSettingsNew,
  PowerSettingsNewOutlined,
  PowerSettingsNewRounded,
  PowerSettingsNewSharp,
  PowerSettingsNewTwoTone,
  PowerSharp,
  PowerTwoTone,
  PregnantWoman,
  PregnantWomanOutlined,
  PregnantWomanRounded,
  PregnantWomanSharp,
  PregnantWomanTwoTone,
  PresentToAll,
  PresentToAllOutlined,
  PresentToAllRounded,
  PresentToAllSharp,
  PresentToAllTwoTone,
  Print,
  PrintDisabled,
  PrintDisabledOutlined,
  PrintDisabledRounded,
  PrintDisabledSharp,
  PrintDisabledTwoTone,
  PrintOutlined,
  PrintRounded,
  PrintSharp,
  PrintTwoTone,
  PriorityHigh,
  PriorityHighOutlined,
  PriorityHighRounded,
  PriorityHighSharp,
  PriorityHighTwoTone,
  Public,
  PublicOutlined,
  PublicRounded,
  PublicSharp,
  PublicTwoTone,
  Publish,
  PublishOutlined,
  PublishRounded,
  PublishSharp,
  PublishTwoTone,
  QueryBuilder,
  QueryBuilderOutlined,
  QueryBuilderRounded,
  QueryBuilderSharp,
  QueryBuilderTwoTone,
  QuestionAnswer,
  QuestionAnswerOutlined,
  QuestionAnswerRounded,
  QuestionAnswerSharp,
  QuestionAnswerTwoTone,
  Queue,
  QueueMusic,
  QueueMusicOutlined,
  QueueMusicRounded,
  QueueMusicSharp,
  QueueMusicTwoTone,
  QueueOutlined,
  QueuePlayNext,
  QueuePlayNextOutlined,
  QueuePlayNextRounded,
  QueuePlayNextSharp,
  QueuePlayNextTwoTone,
  QueueRounded,
  QueueSharp,
  QueueTwoTone,
  Radio,
  RadioButtonChecked,
  RadioButtonCheckedOutlined,
  RadioButtonCheckedRounded,
  RadioButtonCheckedSharp,
  RadioButtonCheckedTwoTone,
  RadioButtonUnchecked,
  RadioButtonUncheckedOutlined,
  RadioButtonUncheckedRounded,
  RadioButtonUncheckedSharp,
  RadioButtonUncheckedTwoTone,
  RadioOutlined,
  RadioRounded,
  RadioSharp,
  RadioTwoTone,
  RateReview,
  RateReviewOutlined,
  RateReviewRounded,
  RateReviewSharp,
  RateReviewTwoTone,
  Receipt,
  ReceiptOutlined,
  ReceiptRounded,
  ReceiptSharp,
  ReceiptTwoTone,
  RecentActors,
  RecentActorsOutlined,
  RecentActorsRounded,
  RecentActorsSharp,
  RecentActorsTwoTone,
  RecordVoiceOver,
  RecordVoiceOverOutlined,
  RecordVoiceOverRounded,
  RecordVoiceOverSharp,
  RecordVoiceOverTwoTone,
  Reddit,
  Redeem,
  RedeemOutlined,
  RedeemRounded,
  RedeemSharp,
  RedeemTwoTone,
  Redo,
  RedoOutlined,
  RedoRounded,
  RedoSharp,
  RedoTwoTone,
  Refresh,
  RefreshOutlined,
  RefreshRounded,
  RefreshSharp,
  RefreshTwoTone,
  Remove,
  RemoveCircle,
  RemoveCircleOutline,
  RemoveCircleOutlined,
  RemoveCircleOutlineOutlined,
  RemoveCircleOutlineRounded,
  RemoveCircleOutlineSharp,
  RemoveCircleOutlineTwoTone,
  RemoveCircleRounded,
  RemoveCircleSharp,
  RemoveCircleTwoTone,
  RemoveFromQueue,
  RemoveFromQueueOutlined,
  RemoveFromQueueRounded,
  RemoveFromQueueSharp,
  RemoveFromQueueTwoTone,
  RemoveOutlined,
  RemoveRedEye,
  RemoveRedEyeOutlined,
  RemoveRedEyeRounded,
  RemoveRedEyeSharp,
  RemoveRedEyeTwoTone,
  RemoveRounded,
  RemoveSharp,
  RemoveShoppingCart,
  RemoveShoppingCartOutlined,
  RemoveShoppingCartRounded,
  RemoveShoppingCartSharp,
  RemoveShoppingCartTwoTone,
  RemoveTwoTone,
  Reorder,
  ReorderOutlined,
  ReorderRounded,
  ReorderSharp,
  ReorderTwoTone,
  Repeat,
  RepeatOne,
  RepeatOneOutlined,
  RepeatOneRounded,
  RepeatOneSharp,
  RepeatOneTwoTone,
  RepeatOutlined,
  RepeatRounded,
  RepeatSharp,
  RepeatTwoTone,
  Replay,
  Replay10,
  Replay10Outlined,
  Replay10Rounded,
  Replay10Sharp,
  Replay10TwoTone,
  Replay30,
  Replay30Outlined,
  Replay30Rounded,
  Replay30Sharp,
  Replay30TwoTone,
  Replay5,
  Replay5Outlined,
  Replay5Rounded,
  Replay5Sharp,
  Replay5TwoTone,
  ReplayOutlined,
  ReplayRounded,
  ReplaySharp,
  ReplayTwoTone,
  Reply,
  ReplyAll,
  ReplyAllOutlined,
  ReplyAllRounded,
  ReplyAllSharp,
  ReplyAllTwoTone,
  ReplyOutlined,
  ReplyRounded,
  ReplySharp,
  ReplyTwoTone,
  Report,
  ReportOff,
  ReportOffOutlined,
  ReportOffRounded,
  ReportOffSharp,
  ReportOffTwoTone,
  ReportOutlined,
  ReportProblem,
  ReportProblemOutlined,
  ReportProblemRounded,
  ReportProblemSharp,
  ReportProblemTwoTone,
  ReportRounded,
  ReportSharp,
  ReportTwoTone,
  Restaurant,
  RestaurantMenu,
  RestaurantMenuOutlined,
  RestaurantMenuRounded,
  RestaurantMenuSharp,
  RestaurantMenuTwoTone,
  RestaurantOutlined,
  RestaurantRounded,
  RestaurantSharp,
  RestaurantTwoTone,
  Restore,
  RestoreFromTrash,
  RestoreFromTrashOutlined,
  RestoreFromTrashRounded,
  RestoreFromTrashSharp,
  RestoreFromTrashTwoTone,
  RestoreOutlined,
  RestorePage,
  RestorePageOutlined,
  RestorePageRounded,
  RestorePageSharp,
  RestorePageTwoTone,
  RestoreRounded,
  RestoreSharp,
  RestoreTwoTone,
  RingVolume,
  RingVolumeOutlined,
  RingVolumeRounded,
  RingVolumeSharp,
  RingVolumeTwoTone,
  Room,
  RoomOutlined,
  RoomRounded,
  RoomService,
  RoomServiceOutlined,
  RoomServiceRounded,
  RoomServiceSharp,
  RoomServiceTwoTone,
  RoomSharp,
  RoomTwoTone,
  Rotate90DegreesCcw,
  Rotate90DegreesCcwOutlined,
  Rotate90DegreesCcwRounded,
  Rotate90DegreesCcwSharp,
  Rotate90DegreesCcwTwoTone,
  RotateLeft,
  RotateLeftOutlined,
  RotateLeftRounded,
  RotateLeftSharp,
  RotateLeftTwoTone,
  RotateRight,
  RotateRightOutlined,
  RotateRightRounded,
  RotateRightSharp,
  RotateRightTwoTone,
  RoundedCorner,
  RoundedCornerOutlined,
  RoundedCornerRounded,
  RoundedCornerSharp,
  RoundedCornerTwoTone,
  Router,
  RouterOutlined,
  RouterRounded,
  RouterSharp,
  RouterTwoTone,
  Rowing,
  RowingOutlined,
  RowingRounded,
  RowingSharp,
  RowingTwoTone,
  RssFeed,
  RssFeedOutlined,
  RssFeedRounded,
  RssFeedSharp,
  RssFeedTwoTone,
  RvHookup,
  RvHookupOutlined,
  RvHookupRounded,
  RvHookupSharp,
  RvHookupTwoTone,
  Satellite,
  SatelliteOutlined,
  SatelliteRounded,
  SatelliteSharp,
  SatelliteTwoTone,
  Save,
  SaveAlt,
  SaveAltOutlined,
  SaveAltRounded,
  SaveAltSharp,
  SaveAltTwoTone,
  SaveOutlined,
  SaveRounded,
  SaveSharp,
  SaveTwoTone,
  Scanner,
  ScannerOutlined,
  ScannerRounded,
  ScannerSharp,
  ScannerTwoTone,
  ScatterPlot,
  ScatterPlotOutlined,
  ScatterPlotRounded,
  ScatterPlotSharp,
  ScatterPlotTwoTone,
  Schedule,
  ScheduleOutlined,
  ScheduleRounded,
  ScheduleSharp,
  ScheduleTwoTone,
  School,
  SchoolOutlined,
  SchoolRounded,
  SchoolSharp,
  SchoolTwoTone,
  Score,
  ScoreOutlined,
  ScoreRounded,
  ScoreSharp,
  ScoreTwoTone,
  ScreenLockLandscape,
  ScreenLockLandscapeOutlined,
  ScreenLockLandscapeRounded,
  ScreenLockLandscapeSharp,
  ScreenLockLandscapeTwoTone,
  ScreenLockPortrait,
  ScreenLockPortraitOutlined,
  ScreenLockPortraitRounded,
  ScreenLockPortraitSharp,
  ScreenLockPortraitTwoTone,
  ScreenLockRotation,
  ScreenLockRotationOutlined,
  ScreenLockRotationRounded,
  ScreenLockRotationSharp,
  ScreenLockRotationTwoTone,
  ScreenRotation,
  ScreenRotationOutlined,
  ScreenRotationRounded,
  ScreenRotationSharp,
  ScreenRotationTwoTone,
  ScreenShare,
  ScreenShareOutlined,
  ScreenShareRounded,
  ScreenShareSharp,
  ScreenShareTwoTone,
  SdCard,
  SdCardOutlined,
  SdCardRounded,
  SdCardSharp,
  SdCardTwoTone,
  SdStorage,
  SdStorageOutlined,
  SdStorageRounded,
  SdStorageSharp,
  SdStorageTwoTone,
  Search,
  SearchOutlined,
  SearchRounded,
  SearchSharp,
  SearchTwoTone,
  Security,
  SecurityOutlined,
  SecurityRounded,
  SecuritySharp,
  SecurityTwoTone,
  SelectAll,
  SelectAllOutlined,
  SelectAllRounded,
  SelectAllSharp,
  SelectAllTwoTone,
  Send,
  SendOutlined,
  SendRounded,
  SendSharp,
  SendTwoTone,
  SentimentDissatisfied,
  SentimentDissatisfiedOutlined,
  SentimentDissatisfiedRounded,
  SentimentDissatisfiedSharp,
  SentimentDissatisfiedTwoTone,
  SentimentSatisfied,
  SentimentSatisfiedAlt,
  SentimentSatisfiedAltOutlined,
  SentimentSatisfiedAltRounded,
  SentimentSatisfiedAltSharp,
  SentimentSatisfiedAltTwoTone,
  SentimentSatisfiedOutlined,
  SentimentSatisfiedRounded,
  SentimentSatisfiedSharp,
  SentimentSatisfiedTwoTone,
  SentimentVeryDissatisfied,
  SentimentVeryDissatisfiedOutlined,
  SentimentVeryDissatisfiedRounded,
  SentimentVeryDissatisfiedSharp,
  SentimentVeryDissatisfiedTwoTone,
  SentimentVerySatisfied,
  SentimentVerySatisfiedOutlined,
  SentimentVerySatisfiedRounded,
  SentimentVerySatisfiedSharp,
  SentimentVerySatisfiedTwoTone,
  Settings,
  SettingsApplications,
  SettingsApplicationsOutlined,
  SettingsApplicationsRounded,
  SettingsApplicationsSharp,
  SettingsApplicationsTwoTone,
  SettingsBackupRestore,
  SettingsBackupRestoreOutlined,
  SettingsBackupRestoreRounded,
  SettingsBackupRestoreSharp,
  SettingsBackupRestoreTwoTone,
  SettingsBluetooth,
  SettingsBluetoothOutlined,
  SettingsBluetoothRounded,
  SettingsBluetoothSharp,
  SettingsBluetoothTwoTone,
  SettingsBrightness,
  SettingsBrightnessOutlined,
  SettingsBrightnessRounded,
  SettingsBrightnessSharp,
  SettingsBrightnessTwoTone,
  SettingsCell,
  SettingsCellOutlined,
  SettingsCellRounded,
  SettingsCellSharp,
  SettingsCellTwoTone,
  SettingsEthernet,
  SettingsEthernetOutlined,
  SettingsEthernetRounded,
  SettingsEthernetSharp,
  SettingsEthernetTwoTone,
  SettingsInputAntenna,
  SettingsInputAntennaOutlined,
  SettingsInputAntennaRounded,
  SettingsInputAntennaSharp,
  SettingsInputAntennaTwoTone,
  SettingsInputComponent,
  SettingsInputComponentOutlined,
  SettingsInputComponentRounded,
  SettingsInputComponentSharp,
  SettingsInputComponentTwoTone,
  SettingsInputComposite,
  SettingsInputCompositeOutlined,
  SettingsInputCompositeRounded,
  SettingsInputCompositeSharp,
  SettingsInputCompositeTwoTone,
  SettingsInputHdmi,
  SettingsInputHdmiOutlined,
  SettingsInputHdmiRounded,
  SettingsInputHdmiSharp,
  SettingsInputHdmiTwoTone,
  SettingsInputSvideo,
  SettingsInputSvideoOutlined,
  SettingsInputSvideoRounded,
  SettingsInputSvideoSharp,
  SettingsInputSvideoTwoTone,
  SettingsOutlined,
  SettingsOverscan,
  SettingsOverscanOutlined,
  SettingsOverscanRounded,
  SettingsOverscanSharp,
  SettingsOverscanTwoTone,
  SettingsPhone,
  SettingsPhoneOutlined,
  SettingsPhoneRounded,
  SettingsPhoneSharp,
  SettingsPhoneTwoTone,
  SettingsPower,
  SettingsPowerOutlined,
  SettingsPowerRounded,
  SettingsPowerSharp,
  SettingsPowerTwoTone,
  SettingsRemote,
  SettingsRemoteOutlined,
  SettingsRemoteRounded,
  SettingsRemoteSharp,
  SettingsRemoteTwoTone,
  SettingsRounded,
  SettingsSharp,
  SettingsSystemDaydream,
  SettingsSystemDaydreamOutlined,
  SettingsSystemDaydreamRounded,
  SettingsSystemDaydreamSharp,
  SettingsSystemDaydreamTwoTone,
  SettingsTwoTone,
  SettingsVoice,
  SettingsVoiceOutlined,
  SettingsVoiceRounded,
  SettingsVoiceSharp,
  SettingsVoiceTwoTone,
  Share,
  ShareOutlined,
  ShareRounded,
  ShareSharp,
  ShareTwoTone,
  Shop,
  ShopOutlined,
  ShoppingBasket,
  ShoppingBasketOutlined,
  ShoppingBasketRounded,
  ShoppingBasketSharp,
  ShoppingBasketTwoTone,
  ShoppingCart,
  ShoppingCartOutlined,
  ShoppingCartRounded,
  ShoppingCartSharp,
  ShoppingCartTwoTone,
  ShopRounded,
  ShopSharp,
  ShopTwo,
  ShopTwoOutlined,
  ShopTwoRounded,
  ShopTwoSharp,
  ShopTwoTone,
  ShopTwoTwoTone,
  ShortText,
  ShortTextOutlined,
  ShortTextRounded,
  ShortTextSharp,
  ShortTextTwoTone,
  ShowChart,
  ShowChartOutlined,
  ShowChartRounded,
  ShowChartSharp,
  ShowChartTwoTone,
  Shuffle,
  ShuffleOutlined,
  ShuffleRounded,
  ShuffleSharp,
  ShuffleTwoTone,
  ShutterSpeed,
  ShutterSpeedOutlined,
  ShutterSpeedRounded,
  ShutterSpeedSharp,
  ShutterSpeedTwoTone,
  SignalCellular0Bar,
  SignalCellular0BarOutlined,
  SignalCellular0BarRounded,
  SignalCellular0BarSharp,
  SignalCellular0BarTwoTone,
  SignalCellular1Bar,
  SignalCellular1BarOutlined,
  SignalCellular1BarRounded,
  SignalCellular1BarSharp,
  SignalCellular1BarTwoTone,
  SignalCellular2Bar,
  SignalCellular2BarOutlined,
  SignalCellular2BarRounded,
  SignalCellular2BarSharp,
  SignalCellular2BarTwoTone,
  SignalCellular3Bar,
  SignalCellular3BarOutlined,
  SignalCellular3BarRounded,
  SignalCellular3BarSharp,
  SignalCellular3BarTwoTone,
  SignalCellular4Bar,
  SignalCellular4BarOutlined,
  SignalCellular4BarRounded,
  SignalCellular4BarSharp,
  SignalCellular4BarTwoTone,
  SignalCellularAlt,
  SignalCellularAltOutlined,
  SignalCellularAltRounded,
  SignalCellularAltSharp,
  SignalCellularAltTwoTone,
  SignalCellularConnectedNoInternet0Bar,
  SignalCellularConnectedNoInternet0BarOutlined,
  SignalCellularConnectedNoInternet0BarRounded,
  SignalCellularConnectedNoInternet0BarSharp,
  SignalCellularConnectedNoInternet0BarTwoTone,
  SignalCellularConnectedNoInternet1Bar,
  SignalCellularConnectedNoInternet1BarOutlined,
  SignalCellularConnectedNoInternet1BarRounded,
  SignalCellularConnectedNoInternet1BarSharp,
  SignalCellularConnectedNoInternet1BarTwoTone,
  SignalCellularConnectedNoInternet2Bar,
  SignalCellularConnectedNoInternet2BarOutlined,
  SignalCellularConnectedNoInternet2BarRounded,
  SignalCellularConnectedNoInternet2BarSharp,
  SignalCellularConnectedNoInternet2BarTwoTone,
  SignalCellularConnectedNoInternet3Bar,
  SignalCellularConnectedNoInternet3BarOutlined,
  SignalCellularConnectedNoInternet3BarRounded,
  SignalCellularConnectedNoInternet3BarSharp,
  SignalCellularConnectedNoInternet3BarTwoTone,
  SignalCellularConnectedNoInternet4Bar,
  SignalCellularConnectedNoInternet4BarOutlined,
  SignalCellularConnectedNoInternet4BarRounded,
  SignalCellularConnectedNoInternet4BarSharp,
  SignalCellularConnectedNoInternet4BarTwoTone,
  SignalCellularNoSim,
  SignalCellularNoSimOutlined,
  SignalCellularNoSimRounded,
  SignalCellularNoSimSharp,
  SignalCellularNoSimTwoTone,
  SignalCellularNull,
  SignalCellularNullOutlined,
  SignalCellularNullRounded,
  SignalCellularNullSharp,
  SignalCellularNullTwoTone,
  SignalCellularOff,
  SignalCellularOffOutlined,
  SignalCellularOffRounded,
  SignalCellularOffSharp,
  SignalCellularOffTwoTone,
  SignalWifi0Bar,
  SignalWifi0BarOutlined,
  SignalWifi0BarRounded,
  SignalWifi0BarSharp,
  SignalWifi0BarTwoTone,
  SignalWifi1Bar,
  SignalWifi1BarLock,
  SignalWifi1BarLockOutlined,
  SignalWifi1BarLockRounded,
  SignalWifi1BarLockSharp,
  SignalWifi1BarLockTwoTone,
  SignalWifi1BarOutlined,
  SignalWifi1BarRounded,
  SignalWifi1BarSharp,
  SignalWifi1BarTwoTone,
  SignalWifi2Bar,
  SignalWifi2BarLock,
  SignalWifi2BarLockOutlined,
  SignalWifi2BarLockRounded,
  SignalWifi2BarLockSharp,
  SignalWifi2BarLockTwoTone,
  SignalWifi2BarOutlined,
  SignalWifi2BarRounded,
  SignalWifi2BarSharp,
  SignalWifi2BarTwoTone,
  SignalWifi3Bar,
  SignalWifi3BarLock,
  SignalWifi3BarLockOutlined,
  SignalWifi3BarLockRounded,
  SignalWifi3BarLockSharp,
  SignalWifi3BarLockTwoTone,
  SignalWifi3BarOutlined,
  SignalWifi3BarRounded,
  SignalWifi3BarSharp,
  SignalWifi3BarTwoTone,
  SignalWifi4Bar,
  SignalWifi4BarLock,
  SignalWifi4BarLockOutlined,
  SignalWifi4BarLockRounded,
  SignalWifi4BarLockSharp,
  SignalWifi4BarLockTwoTone,
  SignalWifi4BarOutlined,
  SignalWifi4BarRounded,
  SignalWifi4BarSharp,
  SignalWifi4BarTwoTone,
  SignalWifiOff,
  SignalWifiOffOutlined,
  SignalWifiOffRounded,
  SignalWifiOffSharp,
  SignalWifiOffTwoTone,
  SimCard,
  SimCardOutlined,
  SimCardRounded,
  SimCardSharp,
  SimCardTwoTone,
  SingleBed,
  SingleBedOutlined,
  SingleBedRounded,
  SingleBedSharp,
  SingleBedTwoTone,
  SkipNext,
  SkipNextOutlined,
  SkipNextRounded,
  SkipNextSharp,
  SkipNextTwoTone,
  SkipPrevious,
  SkipPreviousOutlined,
  SkipPreviousRounded,
  SkipPreviousSharp,
  SkipPreviousTwoTone,
  Slideshow,
  SlideshowOutlined,
  SlideshowRounded,
  SlideshowSharp,
  SlideshowTwoTone,
  SlowMotionVideo,
  SlowMotionVideoOutlined,
  SlowMotionVideoRounded,
  SlowMotionVideoSharp,
  SlowMotionVideoTwoTone,
  Smartphone,
  SmartphoneOutlined,
  SmartphoneRounded,
  SmartphoneSharp,
  SmartphoneTwoTone,
  SmokeFree,
  SmokeFreeOutlined,
  SmokeFreeRounded,
  SmokeFreeSharp,
  SmokeFreeTwoTone,
  SmokingRooms,
  SmokingRoomsOutlined,
  SmokingRoomsRounded,
  SmokingRoomsSharp,
  SmokingRoomsTwoTone,
  Sms,
  SmsFailed,
  SmsFailedOutlined,
  SmsFailedRounded,
  SmsFailedSharp,
  SmsFailedTwoTone,
  SmsOutlined,
  SmsRounded,
  SmsSharp,
  SmsTwoTone,
  Snooze,
  SnoozeOutlined,
  SnoozeRounded,
  SnoozeSharp,
  SnoozeTwoTone,
  Sort,
  SortByAlpha,
  SortByAlphaOutlined,
  SortByAlphaRounded,
  SortByAlphaSharp,
  SortByAlphaTwoTone,
  SortOutlined,
  SortRounded,
  SortSharp,
  SortTwoTone,
  Spa,
  SpaceBar,
  SpaceBarOutlined,
  SpaceBarRounded,
  SpaceBarSharp,
  SpaceBarTwoTone,
  SpaOutlined,
  SpaRounded,
  SpaSharp,
  SpaTwoTone,
  Speaker,
  SpeakerGroup,
  SpeakerGroupOutlined,
  SpeakerGroupRounded,
  SpeakerGroupSharp,
  SpeakerGroupTwoTone,
  SpeakerNotes,
  SpeakerNotesOff,
  SpeakerNotesOffOutlined,
  SpeakerNotesOffRounded,
  SpeakerNotesOffSharp,
  SpeakerNotesOffTwoTone,
  SpeakerNotesOutlined,
  SpeakerNotesRounded,
  SpeakerNotesSharp,
  SpeakerNotesTwoTone,
  SpeakerOutlined,
  SpeakerPhone,
  SpeakerPhoneOutlined,
  SpeakerPhoneRounded,
  SpeakerPhoneSharp,
  SpeakerPhoneTwoTone,
  SpeakerRounded,
  SpeakerSharp,
  SpeakerTwoTone,
  Speed,
  SpeedOutlined,
  SpeedRounded,
  SpeedSharp,
  SpeedTwoTone,
  Spellcheck,
  SpellcheckOutlined,
  SpellcheckRounded,
  SpellcheckSharp,
  SpellcheckTwoTone,
  Sports,
  SportsBaseball,
  SportsBaseballOutlined,
  SportsBaseballRounded,
  SportsBaseballSharp,
  SportsBaseballTwoTone,
  SportsBasketball,
  SportsBasketballOutlined,
  SportsBasketballRounded,
  SportsBasketballSharp,
  SportsBasketballTwoTone,
  SportsCricket,
  SportsCricketOutlined,
  SportsCricketRounded,
  SportsCricketSharp,
  SportsCricketTwoTone,
  SportsEsports,
  SportsEsportsOutlined,
  SportsEsportsRounded,
  SportsEsportsSharp,
  SportsEsportsTwoTone,
  SportsFootball,
  SportsFootballOutlined,
  SportsFootballRounded,
  SportsFootballSharp,
  SportsFootballTwoTone,
  SportsGolf,
  SportsGolfOutlined,
  SportsGolfRounded,
  SportsGolfSharp,
  SportsGolfTwoTone,
  SportsHandball,
  SportsHandballOutlined,
  SportsHandballRounded,
  SportsHandballSharp,
  SportsHandballTwoTone,
  SportsHockey,
  SportsHockeyOutlined,
  SportsHockeyRounded,
  SportsHockeySharp,
  SportsHockeyTwoTone,
  SportsKabaddi,
  SportsKabaddiOutlined,
  SportsKabaddiRounded,
  SportsKabaddiSharp,
  SportsKabaddiTwoTone,
  SportsMma,
  SportsMmaOutlined,
  SportsMmaRounded,
  SportsMmaSharp,
  SportsMmaTwoTone,
  SportsMotorsports,
  SportsMotorsportsOutlined,
  SportsMotorsportsRounded,
  SportsMotorsportsSharp,
  SportsMotorsportsTwoTone,
  SportsOutlined,
  SportsRounded,
  SportsRugby,
  SportsRugbyOutlined,
  SportsRugbyRounded,
  SportsRugbySharp,
  SportsRugbyTwoTone,
  SportsSharp,
  SportsSoccer,
  SportsSoccerOutlined,
  SportsSoccerRounded,
  SportsSoccerSharp,
  SportsSoccerTwoTone,
  SportsTennis,
  SportsTennisOutlined,
  SportsTennisRounded,
  SportsTennisSharp,
  SportsTennisTwoTone,
  SportsTwoTone,
  SportsVolleyball,
  SportsVolleyballOutlined,
  SportsVolleyballRounded,
  SportsVolleyballSharp,
  SportsVolleyballTwoTone,
  SquareFoot,
  SquareFootOutlined,
  SquareFootRounded,
  SquareFootSharp,
  SquareFootTwoTone,
  Star,
  StarBorder,
  StarBorderOutlined,
  StarBorderRounded,
  StarBorderSharp,
  StarBorderTwoTone,
  StarHalf,
  StarHalfOutlined,
  StarHalfRounded,
  StarHalfSharp,
  StarHalfTwoTone,
  StarOutlined,
  StarRate,
  StarRateOutlined,
  StarRateRounded,
  StarRateSharp,
  StarRateTwoTone,
  StarRounded,
  Stars,
  StarSharp,
  StarsOutlined,
  StarsRounded,
  StarsSharp,
  StarsTwoTone,
  StarTwoTone,
  StayCurrentLandscape,
  StayCurrentLandscapeOutlined,
  StayCurrentLandscapeRounded,
  StayCurrentLandscapeSharp,
  StayCurrentLandscapeTwoTone,
  StayCurrentPortrait,
  StayCurrentPortraitOutlined,
  StayCurrentPortraitRounded,
  StayCurrentPortraitSharp,
  StayCurrentPortraitTwoTone,
  StayPrimaryLandscape,
  StayPrimaryLandscapeOutlined,
  StayPrimaryLandscapeRounded,
  StayPrimaryLandscapeSharp,
  StayPrimaryLandscapeTwoTone,
  StayPrimaryPortrait,
  StayPrimaryPortraitOutlined,
  StayPrimaryPortraitRounded,
  StayPrimaryPortraitSharp,
  StayPrimaryPortraitTwoTone,
  Stop,
  StopOutlined,
  StopRounded,
  StopScreenShare,
  StopScreenShareOutlined,
  StopScreenShareRounded,
  StopScreenShareSharp,
  StopScreenShareTwoTone,
  StopSharp,
  StopTwoTone,
  Storage,
  StorageOutlined,
  StorageRounded,
  StorageSharp,
  StorageTwoTone,
  Store,
  Storefront,
  StorefrontOutlined,
  StorefrontRounded,
  StorefrontSharp,
  StorefrontTwoTone,
  StoreMallDirectory,
  StoreMallDirectoryOutlined,
  StoreMallDirectoryRounded,
  StoreMallDirectorySharp,
  StoreMallDirectoryTwoTone,
  StoreOutlined,
  StoreRounded,
  StoreSharp,
  StoreTwoTone,
  Straighten,
  StraightenOutlined,
  StraightenRounded,
  StraightenSharp,
  StraightenTwoTone,
  Streetview,
  StreetviewOutlined,
  StreetviewRounded,
  StreetviewSharp,
  StreetviewTwoTone,
  StrikethroughS,
  StrikethroughSOutlined,
  StrikethroughSRounded,
  StrikethroughSSharp,
  StrikethroughSTwoTone,
  Style,
  StyleOutlined,
  StyleRounded,
  StyleSharp,
  StyleTwoTone,
  SubdirectoryArrowLeft,
  SubdirectoryArrowLeftOutlined,
  SubdirectoryArrowLeftRounded,
  SubdirectoryArrowLeftSharp,
  SubdirectoryArrowLeftTwoTone,
  SubdirectoryArrowRight,
  SubdirectoryArrowRightOutlined,
  SubdirectoryArrowRightRounded,
  SubdirectoryArrowRightSharp,
  SubdirectoryArrowRightTwoTone,
  Subject,
  SubjectOutlined,
  SubjectRounded,
  SubjectSharp,
  SubjectTwoTone,
  Subscriptions,
  SubscriptionsOutlined,
  SubscriptionsRounded,
  SubscriptionsSharp,
  SubscriptionsTwoTone,
  Subtitles,
  SubtitlesOutlined,
  SubtitlesRounded,
  SubtitlesSharp,
  SubtitlesTwoTone,
  Subway,
  SubwayOutlined,
  SubwayRounded,
  SubwaySharp,
  SubwayTwoTone,
  SupervisedUserCircle,
  SupervisedUserCircleOutlined,
  SupervisedUserCircleRounded,
  SupervisedUserCircleSharp,
  SupervisedUserCircleTwoTone,
  SupervisorAccount,
  SupervisorAccountOutlined,
  SupervisorAccountRounded,
  SupervisorAccountSharp,
  SupervisorAccountTwoTone,
  SurroundSound,
  SurroundSoundOutlined,
  SurroundSoundRounded,
  SurroundSoundSharp,
  SurroundSoundTwoTone,
  SwapCalls,
  SwapCallsOutlined,
  SwapCallsRounded,
  SwapCallsSharp,
  SwapCallsTwoTone,
  SwapHoriz,
  SwapHorizontalCircle,
  SwapHorizontalCircleOutlined,
  SwapHorizontalCircleRounded,
  SwapHorizontalCircleSharp,
  SwapHorizontalCircleTwoTone,
  SwapHorizOutlined,
  SwapHorizRounded,
  SwapHorizSharp,
  SwapHorizTwoTone,
  SwapVert,
  SwapVerticalCircle,
  SwapVerticalCircleOutlined,
  SwapVerticalCircleRounded,
  SwapVerticalCircleSharp,
  SwapVerticalCircleTwoTone,
  SwapVertOutlined,
  SwapVertRounded,
  SwapVertSharp,
  SwapVertTwoTone,
  SwitchCamera,
  SwitchCameraOutlined,
  SwitchCameraRounded,
  SwitchCameraSharp,
  SwitchCameraTwoTone,
  SwitchVideo,
  SwitchVideoOutlined,
  SwitchVideoRounded,
  SwitchVideoSharp,
  SwitchVideoTwoTone,
  Sync,
  SyncAlt,
  SyncAltOutlined,
  SyncAltRounded,
  SyncAltSharp,
  SyncAltTwoTone,
  SyncDisabled,
  SyncDisabledOutlined,
  SyncDisabledRounded,
  SyncDisabledSharp,
  SyncDisabledTwoTone,
  SyncOutlined,
  SyncProblem,
  SyncProblemOutlined,
  SyncProblemRounded,
  SyncProblemSharp,
  SyncProblemTwoTone,
  SyncRounded,
  SyncSharp,
  SyncTwoTone,
  SystemUpdate,
  SystemUpdateAlt,
  SystemUpdateAltOutlined,
  SystemUpdateAltRounded,
  SystemUpdateAltSharp,
  SystemUpdateAltTwoTone,
  SystemUpdateOutlined,
  SystemUpdateRounded,
  SystemUpdateSharp,
  SystemUpdateTwoTone,
  Tab,
  TableChart,
  TableChartOutlined,
  TableChartRounded,
  TableChartSharp,
  TableChartTwoTone,
  Tablet,
  TabletAndroid,
  TabletAndroidOutlined,
  TabletAndroidRounded,
  TabletAndroidSharp,
  TabletAndroidTwoTone,
  TabletMac,
  TabletMacOutlined,
  TabletMacRounded,
  TabletMacSharp,
  TabletMacTwoTone,
  TabletOutlined,
  TabletRounded,
  TabletSharp,
  TabletTwoTone,
  TabOutlined,
  TabRounded,
  TabSharp,
  TabTwoTone,
  TabUnselected,
  TabUnselectedOutlined,
  TabUnselectedRounded,
  TabUnselectedSharp,
  TabUnselectedTwoTone,
  TagFaces,
  TagFacesOutlined,
  TagFacesRounded,
  TagFacesSharp,
  TagFacesTwoTone,
  TapAndPlay,
  TapAndPlayOutlined,
  TapAndPlayRounded,
  TapAndPlaySharp,
  TapAndPlayTwoTone,
  Telegram,
  Terrain,
  TerrainOutlined,
  TerrainRounded,
  TerrainSharp,
  TerrainTwoTone,
  TextFields,
  TextFieldsOutlined,
  TextFieldsRounded,
  TextFieldsSharp,
  TextFieldsTwoTone,
  TextFormat,
  TextFormatOutlined,
  TextFormatRounded,
  TextFormatSharp,
  TextFormatTwoTone,
  TextRotateUp,
  TextRotateUpOutlined,
  TextRotateUpRounded,
  TextRotateUpSharp,
  TextRotateUpTwoTone,
  TextRotateVertical,
  TextRotateVerticalOutlined,
  TextRotateVerticalRounded,
  TextRotateVerticalSharp,
  TextRotateVerticalTwoTone,
  TextRotationAngledown,
  TextRotationAngledownOutlined,
  TextRotationAngledownRounded,
  TextRotationAngledownSharp,
  TextRotationAngledownTwoTone,
  TextRotationAngleup,
  TextRotationAngleupOutlined,
  TextRotationAngleupRounded,
  TextRotationAngleupSharp,
  TextRotationAngleupTwoTone,
  TextRotationDown,
  TextRotationDownOutlined,
  TextRotationDownRounded,
  TextRotationDownSharp,
  TextRotationDownTwoTone,
  TextRotationNone,
  TextRotationNoneOutlined,
  TextRotationNoneRounded,
  TextRotationNoneSharp,
  TextRotationNoneTwoTone,
  Textsms,
  TextsmsOutlined,
  TextsmsRounded,
  TextsmsSharp,
  TextsmsTwoTone,
  Texture,
  TextureOutlined,
  TextureRounded,
  TextureSharp,
  TextureTwoTone,
  Theaters,
  TheatersOutlined,
  TheatersRounded,
  TheatersSharp,
  TheatersTwoTone,
  ThreeDRotation,
  ThreeDRotationOutlined,
  ThreeDRotationRounded,
  ThreeDRotationSharp,
  ThreeDRotationTwoTone,
  ThreeSixty,
  ThreeSixtyOutlined,
  ThreeSixtyRounded,
  ThreeSixtySharp,
  ThreeSixtyTwoTone,
  ThumbDown,
  ThumbDownAlt,
  ThumbDownAltOutlined,
  ThumbDownAltRounded,
  ThumbDownAltSharp,
  ThumbDownAltTwoTone,
  ThumbDownOutlined,
  ThumbDownRounded,
  ThumbDownSharp,
  ThumbDownTwoTone,
  ThumbsUpDown,
  ThumbsUpDownOutlined,
  ThumbsUpDownRounded,
  ThumbsUpDownSharp,
  ThumbsUpDownTwoTone,
  ThumbUp,
  ThumbUpAlt,
  ThumbUpAltOutlined,
  ThumbUpAltRounded,
  ThumbUpAltSharp,
  ThumbUpAltTwoTone,
  ThumbUpOutlined,
  ThumbUpRounded,
  ThumbUpSharp,
  ThumbUpTwoTone,
  Timelapse,
  TimelapseOutlined,
  TimelapseRounded,
  TimelapseSharp,
  TimelapseTwoTone,
  Timeline,
  TimelineOutlined,
  TimelineRounded,
  TimelineSharp,
  TimelineTwoTone,
  Timer,
  Timer10,
  Timer10Outlined,
  Timer10Rounded,
  Timer10Sharp,
  Timer10TwoTone,
  Timer3,
  Timer3Outlined,
  Timer3Rounded,
  Timer3Sharp,
  Timer3TwoTone,
  TimerOff,
  TimerOffOutlined,
  TimerOffRounded,
  TimerOffSharp,
  TimerOffTwoTone,
  TimerOutlined,
  TimerRounded,
  TimerSharp,
  TimerTwoTone,
  TimeToLeave,
  TimeToLeaveOutlined,
  TimeToLeaveRounded,
  TimeToLeaveSharp,
  TimeToLeaveTwoTone,
  Title,
  TitleOutlined,
  TitleRounded,
  TitleSharp,
  TitleTwoTone,
  Toc,
  TocOutlined,
  TocRounded,
  TocSharp,
  TocTwoTone,
  Today,
  TodayOutlined,
  TodayRounded,
  TodaySharp,
  TodayTwoTone,
  ToggleOff,
  ToggleOffOutlined,
  ToggleOffRounded,
  ToggleOffSharp,
  ToggleOffTwoTone,
  ToggleOn,
  ToggleOnOutlined,
  ToggleOnRounded,
  ToggleOnSharp,
  ToggleOnTwoTone,
  Toll,
  TollOutlined,
  TollRounded,
  TollSharp,
  TollTwoTone,
  Tonality,
  TonalityOutlined,
  TonalityRounded,
  TonalitySharp,
  TonalityTwoTone,
  TouchApp,
  TouchAppOutlined,
  TouchAppRounded,
  TouchAppSharp,
  TouchAppTwoTone,
  Toys,
  ToysOutlined,
  ToysRounded,
  ToysSharp,
  ToysTwoTone,
  TrackChanges,
  TrackChangesOutlined,
  TrackChangesRounded,
  TrackChangesSharp,
  TrackChangesTwoTone,
  Traffic,
  TrafficOutlined,
  TrafficRounded,
  TrafficSharp,
  TrafficTwoTone,
  Train,
  TrainOutlined,
  TrainRounded,
  TrainSharp,
  TrainTwoTone,
  Tram,
  TramOutlined,
  TramRounded,
  TramSharp,
  TramTwoTone,
  TransferWithinAStation,
  TransferWithinAStationOutlined,
  TransferWithinAStationRounded,
  TransferWithinAStationSharp,
  TransferWithinAStationTwoTone,
  Transform,
  TransformOutlined,
  TransformRounded,
  TransformSharp,
  TransformTwoTone,
  TransitEnterexit,
  TransitEnterexitOutlined,
  TransitEnterexitRounded,
  TransitEnterexitSharp,
  TransitEnterexitTwoTone,
  Translate,
  TranslateOutlined,
  TranslateRounded,
  TranslateSharp,
  TranslateTwoTone,
  TrendingDown,
  TrendingDownOutlined,
  TrendingDownRounded,
  TrendingDownSharp,
  TrendingDownTwoTone,
  TrendingFlat,
  TrendingFlatOutlined,
  TrendingFlatRounded,
  TrendingFlatSharp,
  TrendingFlatTwoTone,
  TrendingUp,
  TrendingUpOutlined,
  TrendingUpRounded,
  TrendingUpSharp,
  TrendingUpTwoTone,
  TripOrigin,
  TripOriginOutlined,
  TripOriginRounded,
  TripOriginSharp,
  TripOriginTwoTone,
  Tune,
  TuneOutlined,
  TuneRounded,
  TuneSharp,
  TuneTwoTone,
  TurnedIn,
  TurnedInNot,
  TurnedInNotOutlined,
  TurnedInNotRounded,
  TurnedInNotSharp,
  TurnedInNotTwoTone,
  TurnedInOutlined,
  TurnedInRounded,
  TurnedInSharp,
  TurnedInTwoTone,
  Tv,
  TvOff,
  TvOffOutlined,
  TvOffRounded,
  TvOffSharp,
  TvOffTwoTone,
  TvOutlined,
  TvRounded,
  TvSharp,
  TvTwoTone,
  Twitter,
  Unarchive,
  UnarchiveOutlined,
  UnarchiveRounded,
  UnarchiveSharp,
  UnarchiveTwoTone,
  Undo,
  UndoOutlined,
  UndoRounded,
  UndoSharp,
  UndoTwoTone,
  UnfoldLess,
  UnfoldLessOutlined,
  UnfoldLessRounded,
  UnfoldLessSharp,
  UnfoldLessTwoTone,
  UnfoldMore,
  UnfoldMoreOutlined,
  UnfoldMoreRounded,
  UnfoldMoreSharp,
  UnfoldMoreTwoTone,
  Unsubscribe,
  UnsubscribeOutlined,
  UnsubscribeRounded,
  UnsubscribeSharp,
  UnsubscribeTwoTone,
  Update,
  UpdateOutlined,
  UpdateRounded,
  UpdateSharp,
  UpdateTwoTone,
  Usb,
  UsbOutlined,
  UsbRounded,
  UsbSharp,
  UsbTwoTone,
  VerifiedUser,
  VerifiedUserOutlined,
  VerifiedUserRounded,
  VerifiedUserSharp,
  VerifiedUserTwoTone,
  VerticalAlignBottom,
  VerticalAlignBottomOutlined,
  VerticalAlignBottomRounded,
  VerticalAlignBottomSharp,
  VerticalAlignBottomTwoTone,
  VerticalAlignCenter,
  VerticalAlignCenterOutlined,
  VerticalAlignCenterRounded,
  VerticalAlignCenterSharp,
  VerticalAlignCenterTwoTone,
  VerticalAlignTop,
  VerticalAlignTopOutlined,
  VerticalAlignTopRounded,
  VerticalAlignTopSharp,
  VerticalAlignTopTwoTone,
  VerticalSplit,
  VerticalSplitOutlined,
  VerticalSplitRounded,
  VerticalSplitSharp,
  VerticalSplitTwoTone,
  Vibration,
  VibrationOutlined,
  VibrationRounded,
  VibrationSharp,
  VibrationTwoTone,
  VideoCall,
  VideoCallOutlined,
  VideoCallRounded,
  VideoCallSharp,
  VideoCallTwoTone,
  Videocam,
  VideocamOff,
  VideocamOffOutlined,
  VideocamOffRounded,
  VideocamOffSharp,
  VideocamOffTwoTone,
  VideocamOutlined,
  VideocamRounded,
  VideocamSharp,
  VideocamTwoTone,
  VideogameAsset,
  VideogameAssetOutlined,
  VideogameAssetRounded,
  VideogameAssetSharp,
  VideogameAssetTwoTone,
  VideoLabel,
  VideoLabelOutlined,
  VideoLabelRounded,
  VideoLabelSharp,
  VideoLabelTwoTone,
  VideoLibrary,
  VideoLibraryOutlined,
  VideoLibraryRounded,
  VideoLibrarySharp,
  VideoLibraryTwoTone,
  ViewAgenda,
  ViewAgendaOutlined,
  ViewAgendaRounded,
  ViewAgendaSharp,
  ViewAgendaTwoTone,
  ViewArray,
  ViewArrayOutlined,
  ViewArrayRounded,
  ViewArraySharp,
  ViewArrayTwoTone,
  ViewCarousel,
  ViewCarouselOutlined,
  ViewCarouselRounded,
  ViewCarouselSharp,
  ViewCarouselTwoTone,
  ViewColumn,
  ViewColumnOutlined,
  ViewColumnRounded,
  ViewColumnSharp,
  ViewColumnTwoTone,
  ViewComfy,
  ViewComfyOutlined,
  ViewComfyRounded,
  ViewComfySharp,
  ViewComfyTwoTone,
  ViewCompact,
  ViewCompactOutlined,
  ViewCompactRounded,
  ViewCompactSharp,
  ViewCompactTwoTone,
  ViewDay,
  ViewDayOutlined,
  ViewDayRounded,
  ViewDaySharp,
  ViewDayTwoTone,
  ViewHeadline,
  ViewHeadlineOutlined,
  ViewHeadlineRounded,
  ViewHeadlineSharp,
  ViewHeadlineTwoTone,
  ViewList,
  ViewListOutlined,
  ViewListRounded,
  ViewListSharp,
  ViewListTwoTone,
  ViewModule,
  ViewModuleOutlined,
  ViewModuleRounded,
  ViewModuleSharp,
  ViewModuleTwoTone,
  ViewQuilt,
  ViewQuiltOutlined,
  ViewQuiltRounded,
  ViewQuiltSharp,
  ViewQuiltTwoTone,
  ViewStream,
  ViewStreamOutlined,
  ViewStreamRounded,
  ViewStreamSharp,
  ViewStreamTwoTone,
  ViewWeek,
  ViewWeekOutlined,
  ViewWeekRounded,
  ViewWeekSharp,
  ViewWeekTwoTone,
  Vignette,
  VignetteOutlined,
  VignetteRounded,
  VignetteSharp,
  VignetteTwoTone,
  Visibility,
  VisibilityOff,
  VisibilityOffOutlined,
  VisibilityOffRounded,
  VisibilityOffSharp,
  VisibilityOffTwoTone,
  VisibilityOutlined,
  VisibilityRounded,
  VisibilitySharp,
  VisibilityTwoTone,
  VoiceChat,
  VoiceChatOutlined,
  VoiceChatRounded,
  VoiceChatSharp,
  VoiceChatTwoTone,
  Voicemail,
  VoicemailOutlined,
  VoicemailRounded,
  VoicemailSharp,
  VoicemailTwoTone,
  VoiceOverOff,
  VoiceOverOffOutlined,
  VoiceOverOffRounded,
  VoiceOverOffSharp,
  VoiceOverOffTwoTone,
  VolumeDown,
  VolumeDownOutlined,
  VolumeDownRounded,
  VolumeDownSharp,
  VolumeDownTwoTone,
  VolumeMute,
  VolumeMuteOutlined,
  VolumeMuteRounded,
  VolumeMuteSharp,
  VolumeMuteTwoTone,
  VolumeOff,
  VolumeOffOutlined,
  VolumeOffRounded,
  VolumeOffSharp,
  VolumeOffTwoTone,
  VolumeUp,
  VolumeUpOutlined,
  VolumeUpRounded,
  VolumeUpSharp,
  VolumeUpTwoTone,
  VpnKey,
  VpnKeyOutlined,
  VpnKeyRounded,
  VpnKeySharp,
  VpnKeyTwoTone,
  VpnLock,
  VpnLockOutlined,
  VpnLockRounded,
  VpnLockSharp,
  VpnLockTwoTone,
  Wallpaper,
  WallpaperOutlined,
  WallpaperRounded,
  WallpaperSharp,
  WallpaperTwoTone,
  Warning,
  WarningOutlined,
  WarningRounded,
  WarningSharp,
  WarningTwoTone,
  Watch,
  WatchLater,
  WatchLaterOutlined,
  WatchLaterRounded,
  WatchLaterSharp,
  WatchLaterTwoTone,
  WatchOutlined,
  WatchRounded,
  WatchSharp,
  WatchTwoTone,
  Waves,
  WavesOutlined,
  WavesRounded,
  WavesSharp,
  WavesTwoTone,
  WbAuto,
  WbAutoOutlined,
  WbAutoRounded,
  WbAutoSharp,
  WbAutoTwoTone,
  WbCloudy,
  WbCloudyOutlined,
  WbCloudyRounded,
  WbCloudySharp,
  WbCloudyTwoTone,
  WbIncandescent,
  WbIncandescentOutlined,
  WbIncandescentRounded,
  WbIncandescentSharp,
  WbIncandescentTwoTone,
  WbIridescent,
  WbIridescentOutlined,
  WbIridescentRounded,
  WbIridescentSharp,
  WbIridescentTwoTone,
  WbSunny,
  WbSunnyOutlined,
  WbSunnyRounded,
  WbSunnySharp,
  WbSunnyTwoTone,
  Wc,
  WcOutlined,
  WcRounded,
  WcSharp,
  WcTwoTone,
  Web,
  WebAsset,
  WebAssetOutlined,
  WebAssetRounded,
  WebAssetSharp,
  WebAssetTwoTone,
  WebOutlined,
  WebRounded,
  WebSharp,
  WebTwoTone,
  Weekend,
  WeekendOutlined,
  WeekendRounded,
  WeekendSharp,
  WeekendTwoTone,
  WhatsApp,
  Whatshot,
  WhatshotOutlined,
  WhatshotRounded,
  WhatshotSharp,
  WhatshotTwoTone,
  WhereToVote,
  WhereToVoteOutlined,
  WhereToVoteRounded,
  WhereToVoteSharp,
  WhereToVoteTwoTone,
  Widgets,
  WidgetsOutlined,
  WidgetsRounded,
  WidgetsSharp,
  WidgetsTwoTone,
  Wifi,
  WifiLock,
  WifiLockOutlined,
  WifiLockRounded,
  WifiLockSharp,
  WifiLockTwoTone,
  WifiOff,
  WifiOffOutlined,
  WifiOffRounded,
  WifiOffSharp,
  WifiOffTwoTone,
  WifiOutlined,
  WifiRounded,
  WifiSharp,
  WifiTethering,
  WifiTetheringOutlined,
  WifiTetheringRounded,
  WifiTetheringSharp,
  WifiTetheringTwoTone,
  WifiTwoTone,
  Work,
  WorkOff,
  WorkOffOutlined,
  WorkOffRounded,
  WorkOffSharp,
  WorkOffTwoTone,
  WorkOutline,
  WorkOutlined,
  WorkOutlineOutlined,
  WorkOutlineRounded,
  WorkOutlineSharp,
  WorkOutlineTwoTone,
  WorkRounded,
  WorkSharp,
  WorkTwoTone,
  WrapText,
  WrapTextOutlined,
  WrapTextRounded,
  WrapTextSharp,
  WrapTextTwoTone,
  YouTube,
  YoutubeSearchedFor,
  YoutubeSearchedForOutlined,
  YoutubeSearchedForRounded,
  YoutubeSearchedForSharp,
  YoutubeSearchedForTwoTone,
  ZoomIn,
  ZoomInOutlined,
  ZoomInRounded,
  ZoomInSharp,
  ZoomInTwoTone,
  ZoomOut,
  ZoomOutMap,
  ZoomOutMapOutlined,
  ZoomOutMapRounded,
  ZoomOutMapSharp,
  ZoomOutMapTwoTone,
  ZoomOutOutlined,
  ZoomOutRounded,
  ZoomOutSharp,
  ZoomOutTwoTone,
} from '@material-ui/icons';

const icons = [
  [AccessAlarm,'AccessAlarm',],
  [AccessAlarmOutlined,'AccessAlarmOutlined',],
  [AccessAlarmRounded,'AccessAlarmRounded',],
  [AccessAlarms,'AccessAlarms',],
  [AccessAlarmSharp,'AccessAlarmSharp',],
  [AccessAlarmsOutlined,'AccessAlarmsOutlined',],
  [AccessAlarmsRounded,'AccessAlarmsRounded',],
  [AccessAlarmsSharp,'AccessAlarmsSharp',],
  [AccessAlarmsTwoTone,'AccessAlarmsTwoTone',],
  [AccessAlarmTwoTone,'AccessAlarmTwoTone',],
  [Accessibility,'Accessibility',],
  [AccessibilityNew,'AccessibilityNew',],
  [AccessibilityNewOutlined,'AccessibilityNewOutlined',],
  [AccessibilityNewRounded,'AccessibilityNewRounded',],
  [AccessibilityNewSharp,'AccessibilityNewSharp',],
  [AccessibilityNewTwoTone,'AccessibilityNewTwoTone',],
  [AccessibilityOutlined,'AccessibilityOutlined',],
  [AccessibilityRounded,'AccessibilityRounded',],
  [AccessibilitySharp,'AccessibilitySharp',],
  [AccessibilityTwoTone,'AccessibilityTwoTone',],
  [Accessible,'Accessible',],
  [AccessibleForward,'AccessibleForward',],
  [AccessibleForwardOutlined,'AccessibleForwardOutlined',],
  [AccessibleForwardRounded,'AccessibleForwardRounded',],
  [AccessibleForwardSharp,'AccessibleForwardSharp',],
  [AccessibleForwardTwoTone,'AccessibleForwardTwoTone',],
  [AccessibleOutlined,'AccessibleOutlined',],
  [AccessibleRounded,'AccessibleRounded',],
  [AccessibleSharp,'AccessibleSharp',],
  [AccessibleTwoTone,'AccessibleTwoTone',],
  [AccessTime,'AccessTime',],
  [AccessTimeOutlined,'AccessTimeOutlined',],
  [AccessTimeRounded,'AccessTimeRounded',],
  [AccessTimeSharp,'AccessTimeSharp',],
  [AccessTimeTwoTone,'AccessTimeTwoTone',],
  [AccountBalance,'AccountBalance',],
  [AccountBalanceOutlined,'AccountBalanceOutlined',],
  [AccountBalanceRounded,'AccountBalanceRounded',],
  [AccountBalanceSharp,'AccountBalanceSharp',],
  [AccountBalanceTwoTone,'AccountBalanceTwoTone',],
  [AccountBalanceWallet,'AccountBalanceWallet',],
  [AccountBalanceWalletOutlined,'AccountBalanceWalletOutlined',],
  [AccountBalanceWalletRounded,'AccountBalanceWalletRounded',],
  [AccountBalanceWalletSharp,'AccountBalanceWalletSharp',],
  [AccountBalanceWalletTwoTone,'AccountBalanceWalletTwoTone',],
  [AccountBox,'AccountBox',],
  [AccountBoxOutlined,'AccountBoxOutlined',],
  [AccountBoxRounded,'AccountBoxRounded',],
  [AccountBoxSharp,'AccountBoxSharp',],
  [AccountBoxTwoTone,'AccountBoxTwoTone',],
  [AccountCircle,'AccountCircle',],
  [AccountCircleOutlined,'AccountCircleOutlined',],
  [AccountCircleRounded,'AccountCircleRounded',],
  [AccountCircleSharp,'AccountCircleSharp',],
  [AccountCircleTwoTone,'AccountCircleTwoTone',],
  [AccountTree,'AccountTree',],
  [AccountTreeOutlined,'AccountTreeOutlined',],
  [AccountTreeRounded,'AccountTreeRounded',],
  [AccountTreeSharp,'AccountTreeSharp',],
  [AccountTreeTwoTone,'AccountTreeTwoTone',],
  [AcUnit,'AcUnit',],
  [AcUnitOutlined,'AcUnitOutlined',],
  [AcUnitRounded,'AcUnitRounded',],
  [AcUnitSharp,'AcUnitSharp',],
  [AcUnitTwoTone,'AcUnitTwoTone',],
  [Adb,'Adb',],
  [AdbOutlined,'AdbOutlined',],
  [AdbRounded,'AdbRounded',],
  [AdbSharp,'AdbSharp',],
  [AdbTwoTone,'AdbTwoTone',],
  [Add,'Add',],
  [AddAlarm,'AddAlarm',],
  [AddAlarmOutlined,'AddAlarmOutlined',],
  [AddAlarmRounded,'AddAlarmRounded',],
  [AddAlarmSharp,'AddAlarmSharp',],
  [AddAlarmTwoTone,'AddAlarmTwoTone',],
  [AddAlert,'AddAlert',],
  [AddAlertOutlined,'AddAlertOutlined',],
  [AddAlertRounded,'AddAlertRounded',],
  [AddAlertSharp,'AddAlertSharp',],
  [AddAlertTwoTone,'AddAlertTwoTone',],
  [AddAPhoto,'AddAPhoto',],
  [AddAPhotoOutlined,'AddAPhotoOutlined',],
  [AddAPhotoRounded,'AddAPhotoRounded',],
  [AddAPhotoSharp,'AddAPhotoSharp',],
  [AddAPhotoTwoTone,'AddAPhotoTwoTone',],
  [AddBox,'AddBox',],
  [AddBoxOutlined,'AddBoxOutlined',],
  [AddBoxRounded,'AddBoxRounded',],
  [AddBoxSharp,'AddBoxSharp',],
  [AddBoxTwoTone,'AddBoxTwoTone',],
  [AddCircle,'AddCircle',],
  [AddCircleOutline,'AddCircleOutline',],
  [AddCircleOutlined,'AddCircleOutlined',],
  [AddCircleOutlineOutlined,'AddCircleOutlineOutlined',],
  [AddCircleOutlineRounded,'AddCircleOutlineRounded',],
  [AddCircleOutlineSharp,'AddCircleOutlineSharp',],
  [AddCircleOutlineTwoTone,'AddCircleOutlineTwoTone',],
  [AddCircleRounded,'AddCircleRounded',],
  [AddCircleSharp,'AddCircleSharp',],
  [AddCircleTwoTone,'AddCircleTwoTone',],
  [AddComment,'AddComment',],
  [AddCommentOutlined,'AddCommentOutlined',],
  [AddCommentRounded,'AddCommentRounded',],
  [AddCommentSharp,'AddCommentSharp',],
  [AddCommentTwoTone,'AddCommentTwoTone',],
  [AddIcCall,'AddIcCall',],
  [AddIcCallOutlined,'AddIcCallOutlined',],
  [AddIcCallRounded,'AddIcCallRounded',],
  [AddIcCallSharp,'AddIcCallSharp',],
  [AddIcCallTwoTone,'AddIcCallTwoTone',],
  [AddLocation,'AddLocation',],
  [AddLocationOutlined,'AddLocationOutlined',],
  [AddLocationRounded,'AddLocationRounded',],
  [AddLocationSharp,'AddLocationSharp',],
  [AddLocationTwoTone,'AddLocationTwoTone',],
  [AddOutlined,'AddOutlined',],
  [AddPhotoAlternate,'AddPhotoAlternate',],
  [AddPhotoAlternateOutlined,'AddPhotoAlternateOutlined',],
  [AddPhotoAlternateRounded,'AddPhotoAlternateRounded',],
  [AddPhotoAlternateSharp,'AddPhotoAlternateSharp',],
  [AddPhotoAlternateTwoTone,'AddPhotoAlternateTwoTone',],
  [AddRounded,'AddRounded',],
  [AddSharp,'AddSharp',],
  [AddShoppingCart,'AddShoppingCart',],
  [AddShoppingCartOutlined,'AddShoppingCartOutlined',],
  [AddShoppingCartRounded,'AddShoppingCartRounded',],
  [AddShoppingCartSharp,'AddShoppingCartSharp',],
  [AddShoppingCartTwoTone,'AddShoppingCartTwoTone',],
  [AddToHomeScreen,'AddToHomeScreen',],
  [AddToHomeScreenOutlined,'AddToHomeScreenOutlined',],
  [AddToHomeScreenRounded,'AddToHomeScreenRounded',],
  [AddToHomeScreenSharp,'AddToHomeScreenSharp',],
  [AddToHomeScreenTwoTone,'AddToHomeScreenTwoTone',],
  [AddToPhotos,'AddToPhotos',],
  [AddToPhotosOutlined,'AddToPhotosOutlined',],
  [AddToPhotosRounded,'AddToPhotosRounded',],
  [AddToPhotosSharp,'AddToPhotosSharp',],
  [AddToPhotosTwoTone,'AddToPhotosTwoTone',],
  [AddToQueue,'AddToQueue',],
  [AddToQueueOutlined,'AddToQueueOutlined',],
  [AddToQueueRounded,'AddToQueueRounded',],
  [AddToQueueSharp,'AddToQueueSharp',],
  [AddToQueueTwoTone,'AddToQueueTwoTone',],
  [AddTwoTone,'AddTwoTone',],
  [Adjust,'Adjust',],
  [AdjustOutlined,'AdjustOutlined',],
  [AdjustRounded,'AdjustRounded',],
  [AdjustSharp,'AdjustSharp',],
  [AdjustTwoTone,'AdjustTwoTone',],
  [AirlineSeatFlat,'AirlineSeatFlat',],
  [AirlineSeatFlatAngled,'AirlineSeatFlatAngled',],
  [AirlineSeatFlatAngledOutlined,'AirlineSeatFlatAngledOutlined',],
  [AirlineSeatFlatAngledRounded,'AirlineSeatFlatAngledRounded',],
  [AirlineSeatFlatAngledSharp,'AirlineSeatFlatAngledSharp',],
  [AirlineSeatFlatAngledTwoTone,'AirlineSeatFlatAngledTwoTone',],
  [AirlineSeatFlatOutlined,'AirlineSeatFlatOutlined',],
  [AirlineSeatFlatRounded,'AirlineSeatFlatRounded',],
  [AirlineSeatFlatSharp,'AirlineSeatFlatSharp',],
  [AirlineSeatFlatTwoTone,'AirlineSeatFlatTwoTone',],
  [AirlineSeatIndividualSuite,'AirlineSeatIndividualSuite',],
  [AirlineSeatIndividualSuiteOutlined,'AirlineSeatIndividualSuiteOutlined',],
  [AirlineSeatIndividualSuiteRounded,'AirlineSeatIndividualSuiteRounded',],
  [AirlineSeatIndividualSuiteSharp,'AirlineSeatIndividualSuiteSharp',],
  [AirlineSeatIndividualSuiteTwoTone,'AirlineSeatIndividualSuiteTwoTone',],
  [AirlineSeatLegroomExtra,'AirlineSeatLegroomExtra',],
  [AirlineSeatLegroomExtraOutlined,'AirlineSeatLegroomExtraOutlined',],
  [AirlineSeatLegroomExtraRounded,'AirlineSeatLegroomExtraRounded',],
  [AirlineSeatLegroomExtraSharp,'AirlineSeatLegroomExtraSharp',],
  [AirlineSeatLegroomExtraTwoTone,'AirlineSeatLegroomExtraTwoTone',],
  [AirlineSeatLegroomNormal,'AirlineSeatLegroomNormal',],
  [AirlineSeatLegroomNormalOutlined,'AirlineSeatLegroomNormalOutlined',],
  [AirlineSeatLegroomNormalRounded,'AirlineSeatLegroomNormalRounded',],
  [AirlineSeatLegroomNormalSharp,'AirlineSeatLegroomNormalSharp',],
  [AirlineSeatLegroomNormalTwoTone,'AirlineSeatLegroomNormalTwoTone',],
  [AirlineSeatLegroomReduced,'AirlineSeatLegroomReduced',],
  [AirlineSeatLegroomReducedOutlined,'AirlineSeatLegroomReducedOutlined',],
  [AirlineSeatLegroomReducedRounded,'AirlineSeatLegroomReducedRounded',],
  [AirlineSeatLegroomReducedSharp,'AirlineSeatLegroomReducedSharp',],
  [AirlineSeatLegroomReducedTwoTone,'AirlineSeatLegroomReducedTwoTone',],
  [AirlineSeatReclineExtra,'AirlineSeatReclineExtra',],
  [AirlineSeatReclineExtraOutlined,'AirlineSeatReclineExtraOutlined',],
  [AirlineSeatReclineExtraRounded,'AirlineSeatReclineExtraRounded',],
  [AirlineSeatReclineExtraSharp,'AirlineSeatReclineExtraSharp',],
  [AirlineSeatReclineExtraTwoTone,'AirlineSeatReclineExtraTwoTone',],
  [AirlineSeatReclineNormal,'AirlineSeatReclineNormal',],
  [AirlineSeatReclineNormalOutlined,'AirlineSeatReclineNormalOutlined',],
  [AirlineSeatReclineNormalRounded,'AirlineSeatReclineNormalRounded',],
  [AirlineSeatReclineNormalSharp,'AirlineSeatReclineNormalSharp',],
  [AirlineSeatReclineNormalTwoTone,'AirlineSeatReclineNormalTwoTone',],
  [AirplanemodeActive,'AirplanemodeActive',],
  [AirplanemodeActiveOutlined,'AirplanemodeActiveOutlined',],
  [AirplanemodeActiveRounded,'AirplanemodeActiveRounded',],
  [AirplanemodeActiveSharp,'AirplanemodeActiveSharp',],
  [AirplanemodeActiveTwoTone,'AirplanemodeActiveTwoTone',],
  [AirplanemodeInactive,'AirplanemodeInactive',],
  [AirplanemodeInactiveOutlined,'AirplanemodeInactiveOutlined',],
  [AirplanemodeInactiveRounded,'AirplanemodeInactiveRounded',],
  [AirplanemodeInactiveSharp,'AirplanemodeInactiveSharp',],
  [AirplanemodeInactiveTwoTone,'AirplanemodeInactiveTwoTone',],
  [Airplay,'Airplay',],
  [AirplayOutlined,'AirplayOutlined',],
  [AirplayRounded,'AirplayRounded',],
  [AirplaySharp,'AirplaySharp',],
  [AirplayTwoTone,'AirplayTwoTone',],
  [AirportShuttle,'AirportShuttle',],
  [AirportShuttleOutlined,'AirportShuttleOutlined',],
  [AirportShuttleRounded,'AirportShuttleRounded',],
  [AirportShuttleSharp,'AirportShuttleSharp',],
  [AirportShuttleTwoTone,'AirportShuttleTwoTone',],
  [Alarm,'Alarm',],
  [AlarmAdd,'AlarmAdd',],
  [AlarmAddOutlined,'AlarmAddOutlined',],
  [AlarmAddRounded,'AlarmAddRounded',],
  [AlarmAddSharp,'AlarmAddSharp',],
  [AlarmAddTwoTone,'AlarmAddTwoTone',],
  [AlarmOff,'AlarmOff',],
  [AlarmOffOutlined,'AlarmOffOutlined',],
  [AlarmOffRounded,'AlarmOffRounded',],
  [AlarmOffSharp,'AlarmOffSharp',],
  [AlarmOffTwoTone,'AlarmOffTwoTone',],
  [AlarmOn,'AlarmOn',],
  [AlarmOnOutlined,'AlarmOnOutlined',],
  [AlarmOnRounded,'AlarmOnRounded',],
  [AlarmOnSharp,'AlarmOnSharp',],
  [AlarmOnTwoTone,'AlarmOnTwoTone',],
  [AlarmOutlined,'AlarmOutlined',],
  [AlarmRounded,'AlarmRounded',],
  [AlarmSharp,'AlarmSharp',],
  [AlarmTwoTone,'AlarmTwoTone',],
  [Album,'Album',],
  [AlbumOutlined,'AlbumOutlined',],
  [AlbumRounded,'AlbumRounded',],
  [AlbumSharp,'AlbumSharp',],
  [AlbumTwoTone,'AlbumTwoTone',],
  [AllInbox,'AllInbox',],
  [AllInboxOutlined,'AllInboxOutlined',],
  [AllInboxRounded,'AllInboxRounded',],
  [AllInboxSharp,'AllInboxSharp',],
  [AllInboxTwoTone,'AllInboxTwoTone',],
  [AllInclusive,'AllInclusive',],
  [AllInclusiveOutlined,'AllInclusiveOutlined',],
  [AllInclusiveRounded,'AllInclusiveRounded',],
  [AllInclusiveSharp,'AllInclusiveSharp',],
  [AllInclusiveTwoTone,'AllInclusiveTwoTone',],
  [AllOut,'AllOut',],
  [AllOutOutlined,'AllOutOutlined',],
  [AllOutRounded,'AllOutRounded',],
  [AllOutSharp,'AllOutSharp',],
  [AllOutTwoTone,'AllOutTwoTone',],
  [AlternateEmail,'AlternateEmail',],
  [AlternateEmailOutlined,'AlternateEmailOutlined',],
  [AlternateEmailRounded,'AlternateEmailRounded',],
  [AlternateEmailSharp,'AlternateEmailSharp',],
  [AlternateEmailTwoTone,'AlternateEmailTwoTone',],
  [AmpStories,'AmpStories',],
  [AmpStoriesOutlined,'AmpStoriesOutlined',],
  [AmpStoriesRounded,'AmpStoriesRounded',],
  [AmpStoriesSharp,'AmpStoriesSharp',],
  [AmpStoriesTwoTone,'AmpStoriesTwoTone',],
  [Android,'Android',],
  [AndroidOutlined,'AndroidOutlined',],
  [AndroidRounded,'AndroidRounded',],
  [AndroidSharp,'AndroidSharp',],
  [AndroidTwoTone,'AndroidTwoTone',],
  [Announcement,'Announcement',],
  [AnnouncementOutlined,'AnnouncementOutlined',],
  [AnnouncementRounded,'AnnouncementRounded',],
  [AnnouncementSharp,'AnnouncementSharp',],
  [AnnouncementTwoTone,'AnnouncementTwoTone',],
  [Apartment,'Apartment',],
  [ApartmentOutlined,'ApartmentOutlined',],
  [ApartmentRounded,'ApartmentRounded',],
  [ApartmentSharp,'ApartmentSharp',],
  [ApartmentTwoTone,'ApartmentTwoTone',],
  [Apple,'Apple',],
  [Apps,'Apps',],
  [AppsOutlined,'AppsOutlined',],
  [AppsRounded,'AppsRounded',],
  [AppsSharp,'AppsSharp',],
  [AppsTwoTone,'AppsTwoTone',],
  [Archive,'Archive',],
  [ArchiveOutlined,'ArchiveOutlined',],
  [ArchiveRounded,'ArchiveRounded',],
  [ArchiveSharp,'ArchiveSharp',],
  [ArchiveTwoTone,'ArchiveTwoTone',],
  [ArrowBack,'ArrowBack',],
  [ArrowBackIos,'ArrowBackIos',],
  [ArrowBackIosOutlined,'ArrowBackIosOutlined',],
  [ArrowBackIosRounded,'ArrowBackIosRounded',],
  [ArrowBackIosSharp,'ArrowBackIosSharp',],
  [ArrowBackIosTwoTone,'ArrowBackIosTwoTone',],
  [ArrowBackOutlined,'ArrowBackOutlined',],
  [ArrowBackRounded,'ArrowBackRounded',],
  [ArrowBackSharp,'ArrowBackSharp',],
  [ArrowBackTwoTone,'ArrowBackTwoTone',],
  [ArrowDownward,'ArrowDownward',],
  [ArrowDownwardOutlined,'ArrowDownwardOutlined',],
  [ArrowDownwardRounded,'ArrowDownwardRounded',],
  [ArrowDownwardSharp,'ArrowDownwardSharp',],
  [ArrowDownwardTwoTone,'ArrowDownwardTwoTone',],
  [ArrowDropDown,'ArrowDropDown',],
  [ArrowDropDownCircle,'ArrowDropDownCircle',],
  [ArrowDropDownCircleOutlined,'ArrowDropDownCircleOutlined',],
  [ArrowDropDownCircleRounded,'ArrowDropDownCircleRounded',],
  [ArrowDropDownCircleSharp,'ArrowDropDownCircleSharp',],
  [ArrowDropDownCircleTwoTone,'ArrowDropDownCircleTwoTone',],
  [ArrowDropDownOutlined,'ArrowDropDownOutlined',],
  [ArrowDropDownRounded,'ArrowDropDownRounded',],
  [ArrowDropDownSharp,'ArrowDropDownSharp',],
  [ArrowDropDownTwoTone,'ArrowDropDownTwoTone',],
  [ArrowDropUp,'ArrowDropUp',],
  [ArrowDropUpOutlined,'ArrowDropUpOutlined',],
  [ArrowDropUpRounded,'ArrowDropUpRounded',],
  [ArrowDropUpSharp,'ArrowDropUpSharp',],
  [ArrowDropUpTwoTone,'ArrowDropUpTwoTone',],
  [ArrowForward,'ArrowForward',],
  [ArrowForwardIos,'ArrowForwardIos',],
  [ArrowForwardIosOutlined,'ArrowForwardIosOutlined',],
  [ArrowForwardIosRounded,'ArrowForwardIosRounded',],
  [ArrowForwardIosSharp,'ArrowForwardIosSharp',],
  [ArrowForwardIosTwoTone,'ArrowForwardIosTwoTone',],
  [ArrowForwardOutlined,'ArrowForwardOutlined',],
  [ArrowForwardRounded,'ArrowForwardRounded',],
  [ArrowForwardSharp,'ArrowForwardSharp',],
  [ArrowForwardTwoTone,'ArrowForwardTwoTone',],
  [ArrowLeft,'ArrowLeft',],
  [ArrowLeftOutlined,'ArrowLeftOutlined',],
  [ArrowLeftRounded,'ArrowLeftRounded',],
  [ArrowLeftSharp,'ArrowLeftSharp',],
  [ArrowLeftTwoTone,'ArrowLeftTwoTone',],
  [ArrowRight,'ArrowRight',],
  [ArrowRightAlt,'ArrowRightAlt',],
  [ArrowRightAltOutlined,'ArrowRightAltOutlined',],
  [ArrowRightAltRounded,'ArrowRightAltRounded',],
  [ArrowRightAltSharp,'ArrowRightAltSharp',],
  [ArrowRightAltTwoTone,'ArrowRightAltTwoTone',],
  [ArrowRightOutlined,'ArrowRightOutlined',],
  [ArrowRightRounded,'ArrowRightRounded',],
  [ArrowRightSharp,'ArrowRightSharp',],
  [ArrowRightTwoTone,'ArrowRightTwoTone',],
  [ArrowUpward,'ArrowUpward',],
  [ArrowUpwardOutlined,'ArrowUpwardOutlined',],
  [ArrowUpwardRounded,'ArrowUpwardRounded',],
  [ArrowUpwardSharp,'ArrowUpwardSharp',],
  [ArrowUpwardTwoTone,'ArrowUpwardTwoTone',],
  [ArtTrack,'ArtTrack',],
  [ArtTrackOutlined,'ArtTrackOutlined',],
  [ArtTrackRounded,'ArtTrackRounded',],
  [ArtTrackSharp,'ArtTrackSharp',],
  [ArtTrackTwoTone,'ArtTrackTwoTone',],
  [AspectRatio,'AspectRatio',],
  [AspectRatioOutlined,'AspectRatioOutlined',],
  [AspectRatioRounded,'AspectRatioRounded',],
  [AspectRatioSharp,'AspectRatioSharp',],
  [AspectRatioTwoTone,'AspectRatioTwoTone',],
  [Assessment,'Assessment',],
  [AssessmentOutlined,'AssessmentOutlined',],
  [AssessmentRounded,'AssessmentRounded',],
  [AssessmentSharp,'AssessmentSharp',],
  [AssessmentTwoTone,'AssessmentTwoTone',],
  [Assignment,'Assignment',],
  [AssignmentInd,'AssignmentInd',],
  [AssignmentIndOutlined,'AssignmentIndOutlined',],
  [AssignmentIndRounded,'AssignmentIndRounded',],
  [AssignmentIndSharp,'AssignmentIndSharp',],
  [AssignmentIndTwoTone,'AssignmentIndTwoTone',],
  [AssignmentLate,'AssignmentLate',],
  [AssignmentLateOutlined,'AssignmentLateOutlined',],
  [AssignmentLateRounded,'AssignmentLateRounded',],
  [AssignmentLateSharp,'AssignmentLateSharp',],
  [AssignmentLateTwoTone,'AssignmentLateTwoTone',],
  [AssignmentOutlined,'AssignmentOutlined',],
  [AssignmentReturn,'AssignmentReturn',],
  [AssignmentReturned,'AssignmentReturned',],
  [AssignmentReturnedOutlined,'AssignmentReturnedOutlined',],
  [AssignmentReturnedRounded,'AssignmentReturnedRounded',],
  [AssignmentReturnedSharp,'AssignmentReturnedSharp',],
  [AssignmentReturnedTwoTone,'AssignmentReturnedTwoTone',],
  [AssignmentReturnOutlined,'AssignmentReturnOutlined',],
  [AssignmentReturnRounded,'AssignmentReturnRounded',],
  [AssignmentReturnSharp,'AssignmentReturnSharp',],
  [AssignmentReturnTwoTone,'AssignmentReturnTwoTone',],
  [AssignmentRounded,'AssignmentRounded',],
  [AssignmentSharp,'AssignmentSharp',],
  [AssignmentTurnedIn,'AssignmentTurnedIn',],
  [AssignmentTurnedInOutlined,'AssignmentTurnedInOutlined',],
  [AssignmentTurnedInRounded,'AssignmentTurnedInRounded',],
  [AssignmentTurnedInSharp,'AssignmentTurnedInSharp',],
  [AssignmentTurnedInTwoTone,'AssignmentTurnedInTwoTone',],
  [AssignmentTwoTone,'AssignmentTwoTone',],
  [Assistant,'Assistant',],
  [AssistantOutlined,'AssistantOutlined',],
  [AssistantPhoto,'AssistantPhoto',],
  [AssistantPhotoOutlined,'AssistantPhotoOutlined',],
  [AssistantPhotoRounded,'AssistantPhotoRounded',],
  [AssistantPhotoSharp,'AssistantPhotoSharp',],
  [AssistantPhotoTwoTone,'AssistantPhotoTwoTone',],
  [AssistantRounded,'AssistantRounded',],
  [AssistantSharp,'AssistantSharp',],
  [AssistantTwoTone,'AssistantTwoTone',],
  [Atm,'Atm',],
  [AtmOutlined,'AtmOutlined',],
  [AtmRounded,'AtmRounded',],
  [AtmSharp,'AtmSharp',],
  [AtmTwoTone,'AtmTwoTone',],
  [AttachFile,'AttachFile',],
  [AttachFileOutlined,'AttachFileOutlined',],
  [AttachFileRounded,'AttachFileRounded',],
  [AttachFileSharp,'AttachFileSharp',],
  [AttachFileTwoTone,'AttachFileTwoTone',],
  [Attachment,'Attachment',],
  [AttachmentOutlined,'AttachmentOutlined',],
  [AttachmentRounded,'AttachmentRounded',],
  [AttachmentSharp,'AttachmentSharp',],
  [AttachmentTwoTone,'AttachmentTwoTone',],
  [AttachMoney,'AttachMoney',],
  [AttachMoneyOutlined,'AttachMoneyOutlined',],
  [AttachMoneyRounded,'AttachMoneyRounded',],
  [AttachMoneySharp,'AttachMoneySharp',],
  [AttachMoneyTwoTone,'AttachMoneyTwoTone',],
  [Audiotrack,'Audiotrack',],
  [AudiotrackOutlined,'AudiotrackOutlined',],
  [AudiotrackRounded,'AudiotrackRounded',],
  [AudiotrackSharp,'AudiotrackSharp',],
  [AudiotrackTwoTone,'AudiotrackTwoTone',],
  [Autorenew,'Autorenew',],
  [AutorenewOutlined,'AutorenewOutlined',],
  [AutorenewRounded,'AutorenewRounded',],
  [AutorenewSharp,'AutorenewSharp',],
  [AutorenewTwoTone,'AutorenewTwoTone',],
  [AvTimer,'AvTimer',],
  [AvTimerOutlined,'AvTimerOutlined',],
  [AvTimerRounded,'AvTimerRounded',],
  [AvTimerSharp,'AvTimerSharp',],
  [AvTimerTwoTone,'AvTimerTwoTone',],
  [Backspace,'Backspace',],
  [BackspaceOutlined,'BackspaceOutlined',],
  [BackspaceRounded,'BackspaceRounded',],
  [BackspaceSharp,'BackspaceSharp',],
  [BackspaceTwoTone,'BackspaceTwoTone',],
  [Backup,'Backup',],
  [BackupOutlined,'BackupOutlined',],
  [BackupRounded,'BackupRounded',],
  [BackupSharp,'BackupSharp',],
  [BackupTwoTone,'BackupTwoTone',],
  [Ballot,'Ballot',],
  [BallotOutlined,'BallotOutlined',],
  [BallotRounded,'BallotRounded',],
  [BallotSharp,'BallotSharp',],
  [BallotTwoTone,'BallotTwoTone',],
  [BarChart,'BarChart',],
  [BarChartOutlined,'BarChartOutlined',],
  [BarChartRounded,'BarChartRounded',],
  [BarChartSharp,'BarChartSharp',],
  [BarChartTwoTone,'BarChartTwoTone',],
  [Bathtub,'Bathtub',],
  [BathtubOutlined,'BathtubOutlined',],
  [BathtubRounded,'BathtubRounded',],
  [BathtubSharp,'BathtubSharp',],
  [BathtubTwoTone,'BathtubTwoTone',],
  [Battery20,'Battery20',],
  [Battery20Outlined,'Battery20Outlined',],
  [Battery20Rounded,'Battery20Rounded',],
  [Battery20Sharp,'Battery20Sharp',],
  [Battery20TwoTone,'Battery20TwoTone',],
  [Battery30,'Battery30',],
  [Battery30Outlined,'Battery30Outlined',],
  [Battery30Rounded,'Battery30Rounded',],
  [Battery30Sharp,'Battery30Sharp',],
  [Battery30TwoTone,'Battery30TwoTone',],
  [Battery50,'Battery50',],
  [Battery50Outlined,'Battery50Outlined',],
  [Battery50Rounded,'Battery50Rounded',],
  [Battery50Sharp,'Battery50Sharp',],
  [Battery50TwoTone,'Battery50TwoTone',],
  [Battery60,'Battery60',],
  [Battery60Outlined,'Battery60Outlined',],
  [Battery60Rounded,'Battery60Rounded',],
  [Battery60Sharp,'Battery60Sharp',],
  [Battery60TwoTone,'Battery60TwoTone',],
  [Battery80,'Battery80',],
  [Battery80Outlined,'Battery80Outlined',],
  [Battery80Rounded,'Battery80Rounded',],
  [Battery80Sharp,'Battery80Sharp',],
  [Battery80TwoTone,'Battery80TwoTone',],
  [Battery90,'Battery90',],
  [Battery90Outlined,'Battery90Outlined',],
  [Battery90Rounded,'Battery90Rounded',],
  [Battery90Sharp,'Battery90Sharp',],
  [Battery90TwoTone,'Battery90TwoTone',],
  [BatteryAlert,'BatteryAlert',],
  [BatteryAlertOutlined,'BatteryAlertOutlined',],
  [BatteryAlertRounded,'BatteryAlertRounded',],
  [BatteryAlertSharp,'BatteryAlertSharp',],
  [BatteryAlertTwoTone,'BatteryAlertTwoTone',],
  [BatteryCharging20,'BatteryCharging20',],
  [BatteryCharging20Outlined,'BatteryCharging20Outlined',],
  [BatteryCharging20Rounded,'BatteryCharging20Rounded',],
  [BatteryCharging20Sharp,'BatteryCharging20Sharp',],
  [BatteryCharging20TwoTone,'BatteryCharging20TwoTone',],
  [BatteryCharging30,'BatteryCharging30',],
  [BatteryCharging30Outlined,'BatteryCharging30Outlined',],
  [BatteryCharging30Rounded,'BatteryCharging30Rounded',],
  [BatteryCharging30Sharp,'BatteryCharging30Sharp',],
  [BatteryCharging30TwoTone,'BatteryCharging30TwoTone',],
  [BatteryCharging50,'BatteryCharging50',],
  [BatteryCharging50Outlined,'BatteryCharging50Outlined',],
  [BatteryCharging50Rounded,'BatteryCharging50Rounded',],
  [BatteryCharging50Sharp,'BatteryCharging50Sharp',],
  [BatteryCharging50TwoTone,'BatteryCharging50TwoTone',],
  [BatteryCharging60,'BatteryCharging60',],
  [BatteryCharging60Outlined,'BatteryCharging60Outlined',],
  [BatteryCharging60Rounded,'BatteryCharging60Rounded',],
  [BatteryCharging60Sharp,'BatteryCharging60Sharp',],
  [BatteryCharging60TwoTone,'BatteryCharging60TwoTone',],
  [BatteryCharging80,'BatteryCharging80',],
  [BatteryCharging80Outlined,'BatteryCharging80Outlined',],
  [BatteryCharging80Rounded,'BatteryCharging80Rounded',],
  [BatteryCharging80Sharp,'BatteryCharging80Sharp',],
  [BatteryCharging80TwoTone,'BatteryCharging80TwoTone',],
  [BatteryCharging90,'BatteryCharging90',],
  [BatteryCharging90Outlined,'BatteryCharging90Outlined',],
  [BatteryCharging90Rounded,'BatteryCharging90Rounded',],
  [BatteryCharging90Sharp,'BatteryCharging90Sharp',],
  [BatteryCharging90TwoTone,'BatteryCharging90TwoTone',],
  [BatteryChargingFull,'BatteryChargingFull',],
  [BatteryChargingFullOutlined,'BatteryChargingFullOutlined',],
  [BatteryChargingFullRounded,'BatteryChargingFullRounded',],
  [BatteryChargingFullSharp,'BatteryChargingFullSharp',],
  [BatteryChargingFullTwoTone,'BatteryChargingFullTwoTone',],
  [BatteryFull,'BatteryFull',],
  [BatteryFullOutlined,'BatteryFullOutlined',],
  [BatteryFullRounded,'BatteryFullRounded',],
  [BatteryFullSharp,'BatteryFullSharp',],
  [BatteryFullTwoTone,'BatteryFullTwoTone',],
  [BatteryStd,'BatteryStd',],
  [BatteryStdOutlined,'BatteryStdOutlined',],
  [BatteryStdRounded,'BatteryStdRounded',],
  [BatteryStdSharp,'BatteryStdSharp',],
  [BatteryStdTwoTone,'BatteryStdTwoTone',],
  [BatteryUnknown,'BatteryUnknown',],
  [BatteryUnknownOutlined,'BatteryUnknownOutlined',],
  [BatteryUnknownRounded,'BatteryUnknownRounded',],
  [BatteryUnknownSharp,'BatteryUnknownSharp',],
  [BatteryUnknownTwoTone,'BatteryUnknownTwoTone',],
  [BeachAccess,'BeachAccess',],
  [BeachAccessOutlined,'BeachAccessOutlined',],
  [BeachAccessRounded,'BeachAccessRounded',],
  [BeachAccessSharp,'BeachAccessSharp',],
  [BeachAccessTwoTone,'BeachAccessTwoTone',],
  [Beenhere,'Beenhere',],
  [BeenhereOutlined,'BeenhereOutlined',],
  [BeenhereRounded,'BeenhereRounded',],
  [BeenhereSharp,'BeenhereSharp',],
  [BeenhereTwoTone,'BeenhereTwoTone',],
  [Block,'Block',],
  [BlockOutlined,'BlockOutlined',],
  [BlockRounded,'BlockRounded',],
  [BlockSharp,'BlockSharp',],
  [BlockTwoTone,'BlockTwoTone',],
  [Bluetooth,'Bluetooth',],
  [BluetoothAudio,'BluetoothAudio',],
  [BluetoothAudioOutlined,'BluetoothAudioOutlined',],
  [BluetoothAudioRounded,'BluetoothAudioRounded',],
  [BluetoothAudioSharp,'BluetoothAudioSharp',],
  [BluetoothAudioTwoTone,'BluetoothAudioTwoTone',],
  [BluetoothConnected,'BluetoothConnected',],
  [BluetoothConnectedOutlined,'BluetoothConnectedOutlined',],
  [BluetoothConnectedRounded,'BluetoothConnectedRounded',],
  [BluetoothConnectedSharp,'BluetoothConnectedSharp',],
  [BluetoothConnectedTwoTone,'BluetoothConnectedTwoTone',],
  [BluetoothDisabled,'BluetoothDisabled',],
  [BluetoothDisabledOutlined,'BluetoothDisabledOutlined',],
  [BluetoothDisabledRounded,'BluetoothDisabledRounded',],
  [BluetoothDisabledSharp,'BluetoothDisabledSharp',],
  [BluetoothDisabledTwoTone,'BluetoothDisabledTwoTone',],
  [BluetoothOutlined,'BluetoothOutlined',],
  [BluetoothRounded,'BluetoothRounded',],
  [BluetoothSearching,'BluetoothSearching',],
  [BluetoothSearchingOutlined,'BluetoothSearchingOutlined',],
  [BluetoothSearchingRounded,'BluetoothSearchingRounded',],
  [BluetoothSearchingSharp,'BluetoothSearchingSharp',],
  [BluetoothSearchingTwoTone,'BluetoothSearchingTwoTone',],
  [BluetoothSharp,'BluetoothSharp',],
  [BluetoothTwoTone,'BluetoothTwoTone',],
  [BlurCircular,'BlurCircular',],
  [BlurCircularOutlined,'BlurCircularOutlined',],
  [BlurCircularRounded,'BlurCircularRounded',],
  [BlurCircularSharp,'BlurCircularSharp',],
  [BlurCircularTwoTone,'BlurCircularTwoTone',],
  [BlurLinear,'BlurLinear',],
  [BlurLinearOutlined,'BlurLinearOutlined',],
  [BlurLinearRounded,'BlurLinearRounded',],
  [BlurLinearSharp,'BlurLinearSharp',],
  [BlurLinearTwoTone,'BlurLinearTwoTone',],
  [BlurOff,'BlurOff',],
  [BlurOffOutlined,'BlurOffOutlined',],
  [BlurOffRounded,'BlurOffRounded',],
  [BlurOffSharp,'BlurOffSharp',],
  [BlurOffTwoTone,'BlurOffTwoTone',],
  [BlurOn,'BlurOn',],
  [BlurOnOutlined,'BlurOnOutlined',],
  [BlurOnRounded,'BlurOnRounded',],
  [BlurOnSharp,'BlurOnSharp',],
  [BlurOnTwoTone,'BlurOnTwoTone',],
  [Book,'Book',],
  [Bookmark,'Bookmark',],
  [BookmarkBorder,'BookmarkBorder',],
  [BookmarkBorderOutlined,'BookmarkBorderOutlined',],
  [BookmarkBorderRounded,'BookmarkBorderRounded',],
  [BookmarkBorderSharp,'BookmarkBorderSharp',],
  [BookmarkBorderTwoTone,'BookmarkBorderTwoTone',],
  [BookmarkOutlined,'BookmarkOutlined',],
  [BookmarkRounded,'BookmarkRounded',],
  [Bookmarks,'Bookmarks',],
  [BookmarkSharp,'BookmarkSharp',],
  [BookmarksOutlined,'BookmarksOutlined',],
  [BookmarksRounded,'BookmarksRounded',],
  [BookmarksSharp,'BookmarksSharp',],
  [BookmarksTwoTone,'BookmarksTwoTone',],
  [BookmarkTwoTone,'BookmarkTwoTone',],
  [BookOutlined,'BookOutlined',],
  [BookRounded,'BookRounded',],
  [BookSharp,'BookSharp',],
  [BookTwoTone,'BookTwoTone',],
  [BorderAll,'BorderAll',],
  [BorderAllOutlined,'BorderAllOutlined',],
  [BorderAllRounded,'BorderAllRounded',],
  [BorderAllSharp,'BorderAllSharp',],
  [BorderAllTwoTone,'BorderAllTwoTone',],
  [BorderBottom,'BorderBottom',],
  [BorderBottomOutlined,'BorderBottomOutlined',],
  [BorderBottomRounded,'BorderBottomRounded',],
  [BorderBottomSharp,'BorderBottomSharp',],
  [BorderBottomTwoTone,'BorderBottomTwoTone',],
  [BorderClear,'BorderClear',],
  [BorderClearOutlined,'BorderClearOutlined',],
  [BorderClearRounded,'BorderClearRounded',],
  [BorderClearSharp,'BorderClearSharp',],
  [BorderClearTwoTone,'BorderClearTwoTone',],
  [BorderColor,'BorderColor',],
  [BorderColorOutlined,'BorderColorOutlined',],
  [BorderColorRounded,'BorderColorRounded',],
  [BorderColorSharp,'BorderColorSharp',],
  [BorderColorTwoTone,'BorderColorTwoTone',],
  [BorderHorizontal,'BorderHorizontal',],
  [BorderHorizontalOutlined,'BorderHorizontalOutlined',],
  [BorderHorizontalRounded,'BorderHorizontalRounded',],
  [BorderHorizontalSharp,'BorderHorizontalSharp',],
  [BorderHorizontalTwoTone,'BorderHorizontalTwoTone',],
  [BorderInner,'BorderInner',],
  [BorderInnerOutlined,'BorderInnerOutlined',],
  [BorderInnerRounded,'BorderInnerRounded',],
  [BorderInnerSharp,'BorderInnerSharp',],
  [BorderInnerTwoTone,'BorderInnerTwoTone',],
  [BorderLeft,'BorderLeft',],
  [BorderLeftOutlined,'BorderLeftOutlined',],
  [BorderLeftRounded,'BorderLeftRounded',],
  [BorderLeftSharp,'BorderLeftSharp',],
  [BorderLeftTwoTone,'BorderLeftTwoTone',],
  [BorderOuter,'BorderOuter',],
  [BorderOuterOutlined,'BorderOuterOutlined',],
  [BorderOuterRounded,'BorderOuterRounded',],
  [BorderOuterSharp,'BorderOuterSharp',],
  [BorderOuterTwoTone,'BorderOuterTwoTone',],
  [BorderRight,'BorderRight',],
  [BorderRightOutlined,'BorderRightOutlined',],
  [BorderRightRounded,'BorderRightRounded',],
  [BorderRightSharp,'BorderRightSharp',],
  [BorderRightTwoTone,'BorderRightTwoTone',],
  [BorderStyle,'BorderStyle',],
  [BorderStyleOutlined,'BorderStyleOutlined',],
  [BorderStyleRounded,'BorderStyleRounded',],
  [BorderStyleSharp,'BorderStyleSharp',],
  [BorderStyleTwoTone,'BorderStyleTwoTone',],
  [BorderTop,'BorderTop',],
  [BorderTopOutlined,'BorderTopOutlined',],
  [BorderTopRounded,'BorderTopRounded',],
  [BorderTopSharp,'BorderTopSharp',],
  [BorderTopTwoTone,'BorderTopTwoTone',],
  [BorderVertical,'BorderVertical',],
  [BorderVerticalOutlined,'BorderVerticalOutlined',],
  [BorderVerticalRounded,'BorderVerticalRounded',],
  [BorderVerticalSharp,'BorderVerticalSharp',],
  [BorderVerticalTwoTone,'BorderVerticalTwoTone',],
  [BrandingWatermark,'BrandingWatermark',],
  [BrandingWatermarkOutlined,'BrandingWatermarkOutlined',],
  [BrandingWatermarkRounded,'BrandingWatermarkRounded',],
  [BrandingWatermarkSharp,'BrandingWatermarkSharp',],
  [BrandingWatermarkTwoTone,'BrandingWatermarkTwoTone',],
  [Brightness1,'Brightness1',],
  [Brightness1Outlined,'Brightness1Outlined',],
  [Brightness1Rounded,'Brightness1Rounded',],
  [Brightness1Sharp,'Brightness1Sharp',],
  [Brightness1TwoTone,'Brightness1TwoTone',],
  [Brightness2,'Brightness2',],
  [Brightness2Outlined,'Brightness2Outlined',],
  [Brightness2Rounded,'Brightness2Rounded',],
  [Brightness2Sharp,'Brightness2Sharp',],
  [Brightness2TwoTone,'Brightness2TwoTone',],
  [Brightness3,'Brightness3',],
  [Brightness3Outlined,'Brightness3Outlined',],
  [Brightness3Rounded,'Brightness3Rounded',],
  [Brightness3Sharp,'Brightness3Sharp',],
  [Brightness3TwoTone,'Brightness3TwoTone',],
  [Brightness4,'Brightness4',],
  [Brightness4Outlined,'Brightness4Outlined',],
  [Brightness4Rounded,'Brightness4Rounded',],
  [Brightness4Sharp,'Brightness4Sharp',],
  [Brightness4TwoTone,'Brightness4TwoTone',],
  [Brightness5,'Brightness5',],
  [Brightness5Outlined,'Brightness5Outlined',],
  [Brightness5Rounded,'Brightness5Rounded',],
  [Brightness5Sharp,'Brightness5Sharp',],
  [Brightness5TwoTone,'Brightness5TwoTone',],
  [Brightness6,'Brightness6',],
  [Brightness6Outlined,'Brightness6Outlined',],
  [Brightness6Rounded,'Brightness6Rounded',],
  [Brightness6Sharp,'Brightness6Sharp',],
  [Brightness6TwoTone,'Brightness6TwoTone',],
  [Brightness7,'Brightness7',],
  [Brightness7Outlined,'Brightness7Outlined',],
  [Brightness7Rounded,'Brightness7Rounded',],
  [Brightness7Sharp,'Brightness7Sharp',],
  [Brightness7TwoTone,'Brightness7TwoTone',],
  [BrightnessAuto,'BrightnessAuto',],
  [BrightnessAutoOutlined,'BrightnessAutoOutlined',],
  [BrightnessAutoRounded,'BrightnessAutoRounded',],
  [BrightnessAutoSharp,'BrightnessAutoSharp',],
  [BrightnessAutoTwoTone,'BrightnessAutoTwoTone',],
  [BrightnessHigh,'BrightnessHigh',],
  [BrightnessHighOutlined,'BrightnessHighOutlined',],
  [BrightnessHighRounded,'BrightnessHighRounded',],
  [BrightnessHighSharp,'BrightnessHighSharp',],
  [BrightnessHighTwoTone,'BrightnessHighTwoTone',],
  [BrightnessLow,'BrightnessLow',],
  [BrightnessLowOutlined,'BrightnessLowOutlined',],
  [BrightnessLowRounded,'BrightnessLowRounded',],
  [BrightnessLowSharp,'BrightnessLowSharp',],
  [BrightnessLowTwoTone,'BrightnessLowTwoTone',],
  [BrightnessMedium,'BrightnessMedium',],
  [BrightnessMediumOutlined,'BrightnessMediumOutlined',],
  [BrightnessMediumRounded,'BrightnessMediumRounded',],
  [BrightnessMediumSharp,'BrightnessMediumSharp',],
  [BrightnessMediumTwoTone,'BrightnessMediumTwoTone',],
  [BrokenImage,'BrokenImage',],
  [BrokenImageOutlined,'BrokenImageOutlined',],
  [BrokenImageRounded,'BrokenImageRounded',],
  [BrokenImageSharp,'BrokenImageSharp',],
  [BrokenImageTwoTone,'BrokenImageTwoTone',],
  [Brush,'Brush',],
  [BrushOutlined,'BrushOutlined',],
  [BrushRounded,'BrushRounded',],
  [BrushSharp,'BrushSharp',],
  [BrushTwoTone,'BrushTwoTone',],
  [BubbleChart,'BubbleChart',],
  [BubbleChartOutlined,'BubbleChartOutlined',],
  [BubbleChartRounded,'BubbleChartRounded',],
  [BubbleChartSharp,'BubbleChartSharp',],
  [BubbleChartTwoTone,'BubbleChartTwoTone',],
  [BugReport,'BugReport',],
  [BugReportOutlined,'BugReportOutlined',],
  [BugReportRounded,'BugReportRounded',],
  [BugReportSharp,'BugReportSharp',],
  [BugReportTwoTone,'BugReportTwoTone',],
  [Build,'Build',],
  [BuildOutlined,'BuildOutlined',],
  [BuildRounded,'BuildRounded',],
  [BuildSharp,'BuildSharp',],
  [BuildTwoTone,'BuildTwoTone',],
  [BurstMode,'BurstMode',],
  [BurstModeOutlined,'BurstModeOutlined',],
  [BurstModeRounded,'BurstModeRounded',],
  [BurstModeSharp,'BurstModeSharp',],
  [BurstModeTwoTone,'BurstModeTwoTone',],
  [Business,'Business',],
  [BusinessCenter,'BusinessCenter',],
  [BusinessCenterOutlined,'BusinessCenterOutlined',],
  [BusinessCenterRounded,'BusinessCenterRounded',],
  [BusinessCenterSharp,'BusinessCenterSharp',],
  [BusinessCenterTwoTone,'BusinessCenterTwoTone',],
  [BusinessOutlined,'BusinessOutlined',],
  [BusinessRounded,'BusinessRounded',],
  [BusinessSharp,'BusinessSharp',],
  [BusinessTwoTone,'BusinessTwoTone',],
  [Cached,'Cached',],
  [CachedOutlined,'CachedOutlined',],
  [CachedRounded,'CachedRounded',],
  [CachedSharp,'CachedSharp',],
  [CachedTwoTone,'CachedTwoTone',],
  [Cake,'Cake',],
  [CakeOutlined,'CakeOutlined',],
  [CakeRounded,'CakeRounded',],
  [CakeSharp,'CakeSharp',],
  [CakeTwoTone,'CakeTwoTone',],
  [CalendarToday,'CalendarToday',],
  [CalendarTodayOutlined,'CalendarTodayOutlined',],
  [CalendarTodayRounded,'CalendarTodayRounded',],
  [CalendarTodaySharp,'CalendarTodaySharp',],
  [CalendarTodayTwoTone,'CalendarTodayTwoTone',],
  [CalendarViewDay,'CalendarViewDay',],
  [CalendarViewDayOutlined,'CalendarViewDayOutlined',],
  [CalendarViewDayRounded,'CalendarViewDayRounded',],
  [CalendarViewDaySharp,'CalendarViewDaySharp',],
  [CalendarViewDayTwoTone,'CalendarViewDayTwoTone',],
  [Call,'Call',],
  [CallEnd,'CallEnd',],
  [CallEndOutlined,'CallEndOutlined',],
  [CallEndRounded,'CallEndRounded',],
  [CallEndSharp,'CallEndSharp',],
  [CallEndTwoTone,'CallEndTwoTone',],
  [CallMade,'CallMade',],
  [CallMadeOutlined,'CallMadeOutlined',],
  [CallMadeRounded,'CallMadeRounded',],
  [CallMadeSharp,'CallMadeSharp',],
  [CallMadeTwoTone,'CallMadeTwoTone',],
  [CallMerge,'CallMerge',],
  [CallMergeOutlined,'CallMergeOutlined',],
  [CallMergeRounded,'CallMergeRounded',],
  [CallMergeSharp,'CallMergeSharp',],
  [CallMergeTwoTone,'CallMergeTwoTone',],
  [CallMissed,'CallMissed',],
  [CallMissedOutgoing,'CallMissedOutgoing',],
  [CallMissedOutgoingOutlined,'CallMissedOutgoingOutlined',],
  [CallMissedOutgoingRounded,'CallMissedOutgoingRounded',],
  [CallMissedOutgoingSharp,'CallMissedOutgoingSharp',],
  [CallMissedOutgoingTwoTone,'CallMissedOutgoingTwoTone',],
  [CallMissedOutlined,'CallMissedOutlined',],
  [CallMissedRounded,'CallMissedRounded',],
  [CallMissedSharp,'CallMissedSharp',],
  [CallMissedTwoTone,'CallMissedTwoTone',],
  [CallOutlined,'CallOutlined',],
  [CallReceived,'CallReceived',],
  [CallReceivedOutlined,'CallReceivedOutlined',],
  [CallReceivedRounded,'CallReceivedRounded',],
  [CallReceivedSharp,'CallReceivedSharp',],
  [CallReceivedTwoTone,'CallReceivedTwoTone',],
  [CallRounded,'CallRounded',],
  [CallSharp,'CallSharp',],
  [CallSplit,'CallSplit',],
  [CallSplitOutlined,'CallSplitOutlined',],
  [CallSplitRounded,'CallSplitRounded',],
  [CallSplitSharp,'CallSplitSharp',],
  [CallSplitTwoTone,'CallSplitTwoTone',],
  [CallToAction,'CallToAction',],
  [CallToActionOutlined,'CallToActionOutlined',],
  [CallToActionRounded,'CallToActionRounded',],
  [CallToActionSharp,'CallToActionSharp',],
  [CallToActionTwoTone,'CallToActionTwoTone',],
  [CallTwoTone,'CallTwoTone',],
  [Camera,'Camera',],
  [CameraAlt,'CameraAlt',],
  [CameraAltOutlined,'CameraAltOutlined',],
  [CameraAltRounded,'CameraAltRounded',],
  [CameraAltSharp,'CameraAltSharp',],
  [CameraAltTwoTone,'CameraAltTwoTone',],
  [CameraEnhance,'CameraEnhance',],
  [CameraEnhanceOutlined,'CameraEnhanceOutlined',],
  [CameraEnhanceRounded,'CameraEnhanceRounded',],
  [CameraEnhanceSharp,'CameraEnhanceSharp',],
  [CameraEnhanceTwoTone,'CameraEnhanceTwoTone',],
  [CameraFront,'CameraFront',],
  [CameraFrontOutlined,'CameraFrontOutlined',],
  [CameraFrontRounded,'CameraFrontRounded',],
  [CameraFrontSharp,'CameraFrontSharp',],
  [CameraFrontTwoTone,'CameraFrontTwoTone',],
  [CameraOutlined,'CameraOutlined',],
  [CameraRear,'CameraRear',],
  [CameraRearOutlined,'CameraRearOutlined',],
  [CameraRearRounded,'CameraRearRounded',],
  [CameraRearSharp,'CameraRearSharp',],
  [CameraRearTwoTone,'CameraRearTwoTone',],
  [CameraRoll,'CameraRoll',],
  [CameraRollOutlined,'CameraRollOutlined',],
  [CameraRollRounded,'CameraRollRounded',],
  [CameraRollSharp,'CameraRollSharp',],
  [CameraRollTwoTone,'CameraRollTwoTone',],
  [CameraRounded,'CameraRounded',],
  [CameraSharp,'CameraSharp',],
  [CameraTwoTone,'CameraTwoTone',],
  [Cancel,'Cancel',],
  [CancelOutlined,'CancelOutlined',],
  [CancelPresentation,'CancelPresentation',],
  [CancelPresentationOutlined,'CancelPresentationOutlined',],
  [CancelPresentationRounded,'CancelPresentationRounded',],
  [CancelPresentationSharp,'CancelPresentationSharp',],
  [CancelPresentationTwoTone,'CancelPresentationTwoTone',],
  [CancelRounded,'CancelRounded',],
  [CancelScheduleSend,'CancelScheduleSend',],
  [CancelScheduleSendOutlined,'CancelScheduleSendOutlined',],
  [CancelScheduleSendRounded,'CancelScheduleSendRounded',],
  [CancelScheduleSendSharp,'CancelScheduleSendSharp',],
  [CancelScheduleSendTwoTone,'CancelScheduleSendTwoTone',],
  [CancelSharp,'CancelSharp',],
  [CancelTwoTone,'CancelTwoTone',],
  [CardGiftcard,'CardGiftcard',],
  [CardGiftcardOutlined,'CardGiftcardOutlined',],
  [CardGiftcardRounded,'CardGiftcardRounded',],
  [CardGiftcardSharp,'CardGiftcardSharp',],
  [CardGiftcardTwoTone,'CardGiftcardTwoTone',],
  [CardMembership,'CardMembership',],
  [CardMembershipOutlined,'CardMembershipOutlined',],
  [CardMembershipRounded,'CardMembershipRounded',],
  [CardMembershipSharp,'CardMembershipSharp',],
  [CardMembershipTwoTone,'CardMembershipTwoTone',],
  [CardTravel,'CardTravel',],
  [CardTravelOutlined,'CardTravelOutlined',],
  [CardTravelRounded,'CardTravelRounded',],
  [CardTravelSharp,'CardTravelSharp',],
  [CardTravelTwoTone,'CardTravelTwoTone',],
  [Casino,'Casino',],
  [CasinoOutlined,'CasinoOutlined',],
  [CasinoRounded,'CasinoRounded',],
  [CasinoSharp,'CasinoSharp',],
  [CasinoTwoTone,'CasinoTwoTone',],
  [Cast,'Cast',],
  [CastConnected,'CastConnected',],
  [CastConnectedOutlined,'CastConnectedOutlined',],
  [CastConnectedRounded,'CastConnectedRounded',],
  [CastConnectedSharp,'CastConnectedSharp',],
  [CastConnectedTwoTone,'CastConnectedTwoTone',],
  [CastForEducation,'CastForEducation',],
  [CastForEducationOutlined,'CastForEducationOutlined',],
  [CastForEducationRounded,'CastForEducationRounded',],
  [CastForEducationSharp,'CastForEducationSharp',],
  [CastForEducationTwoTone,'CastForEducationTwoTone',],
  [CastOutlined,'CastOutlined',],
  [CastRounded,'CastRounded',],
  [CastSharp,'CastSharp',],
  [CastTwoTone,'CastTwoTone',],
  [Category,'Category',],
  [CategoryOutlined,'CategoryOutlined',],
  [CategoryRounded,'CategoryRounded',],
  [CategorySharp,'CategorySharp',],
  [CategoryTwoTone,'CategoryTwoTone',],
  [CellWifi,'CellWifi',],
  [CellWifiOutlined,'CellWifiOutlined',],
  [CellWifiRounded,'CellWifiRounded',],
  [CellWifiSharp,'CellWifiSharp',],
  [CellWifiTwoTone,'CellWifiTwoTone',],
  [CenterFocusStrong,'CenterFocusStrong',],
  [CenterFocusStrongOutlined,'CenterFocusStrongOutlined',],
  [CenterFocusStrongRounded,'CenterFocusStrongRounded',],
  [CenterFocusStrongSharp,'CenterFocusStrongSharp',],
  [CenterFocusStrongTwoTone,'CenterFocusStrongTwoTone',],
  [CenterFocusWeak,'CenterFocusWeak',],
  [CenterFocusWeakOutlined,'CenterFocusWeakOutlined',],
  [CenterFocusWeakRounded,'CenterFocusWeakRounded',],
  [CenterFocusWeakSharp,'CenterFocusWeakSharp',],
  [CenterFocusWeakTwoTone,'CenterFocusWeakTwoTone',],
  [ChangeHistory,'ChangeHistory',],
  [ChangeHistoryOutlined,'ChangeHistoryOutlined',],
  [ChangeHistoryRounded,'ChangeHistoryRounded',],
  [ChangeHistorySharp,'ChangeHistorySharp',],
  [ChangeHistoryTwoTone,'ChangeHistoryTwoTone',],
  [Chat,'Chat',],
  [ChatBubble,'ChatBubble',],
  [ChatBubbleOutline,'ChatBubbleOutline',],
  [ChatBubbleOutlined,'ChatBubbleOutlined',],
  [ChatBubbleOutlineOutlined,'ChatBubbleOutlineOutlined',],
  [ChatBubbleOutlineRounded,'ChatBubbleOutlineRounded',],
  [ChatBubbleOutlineSharp,'ChatBubbleOutlineSharp',],
  [ChatBubbleOutlineTwoTone,'ChatBubbleOutlineTwoTone',],
  [ChatBubbleRounded,'ChatBubbleRounded',],
  [ChatBubbleSharp,'ChatBubbleSharp',],
  [ChatBubbleTwoTone,'ChatBubbleTwoTone',],
  [ChatOutlined,'ChatOutlined',],
  [ChatRounded,'ChatRounded',],
  [ChatSharp,'ChatSharp',],
  [ChatTwoTone,'ChatTwoTone',],
  [Check,'Check',],
  [CheckBox,'CheckBox',],
  [CheckBoxOutlineBlank,'CheckBoxOutlineBlank',],
  [CheckBoxOutlineBlankOutlined,'CheckBoxOutlineBlankOutlined',],
  [CheckBoxOutlineBlankRounded,'CheckBoxOutlineBlankRounded',],
  [CheckBoxOutlineBlankSharp,'CheckBoxOutlineBlankSharp',],
  [CheckBoxOutlineBlankTwoTone,'CheckBoxOutlineBlankTwoTone',],
  [CheckBoxOutlined,'CheckBoxOutlined',],
  [CheckBoxRounded,'CheckBoxRounded',],
  [CheckBoxSharp,'CheckBoxSharp',],
  [CheckBoxTwoTone,'CheckBoxTwoTone',],
  [CheckCircle,'CheckCircle',],
  [CheckCircleOutline,'CheckCircleOutline',],
  [CheckCircleOutlined,'CheckCircleOutlined',],
  [CheckCircleOutlineOutlined,'CheckCircleOutlineOutlined',],
  [CheckCircleOutlineRounded,'CheckCircleOutlineRounded',],
  [CheckCircleOutlineSharp,'CheckCircleOutlineSharp',],
  [CheckCircleOutlineTwoTone,'CheckCircleOutlineTwoTone',],
  [CheckCircleRounded,'CheckCircleRounded',],
  [CheckCircleSharp,'CheckCircleSharp',],
  [CheckCircleTwoTone,'CheckCircleTwoTone',],
  [CheckOutlined,'CheckOutlined',],
  [CheckRounded,'CheckRounded',],
  [CheckSharp,'CheckSharp',],
  [CheckTwoTone,'CheckTwoTone',],
  [ChevronLeft,'ChevronLeft',],
  [ChevronLeftOutlined,'ChevronLeftOutlined',],
  [ChevronLeftRounded,'ChevronLeftRounded',],
  [ChevronLeftSharp,'ChevronLeftSharp',],
  [ChevronLeftTwoTone,'ChevronLeftTwoTone',],
  [ChevronRight,'ChevronRight',],
  [ChevronRightOutlined,'ChevronRightOutlined',],
  [ChevronRightRounded,'ChevronRightRounded',],
  [ChevronRightSharp,'ChevronRightSharp',],
  [ChevronRightTwoTone,'ChevronRightTwoTone',],
  [ChildCare,'ChildCare',],
  [ChildCareOutlined,'ChildCareOutlined',],
  [ChildCareRounded,'ChildCareRounded',],
  [ChildCareSharp,'ChildCareSharp',],
  [ChildCareTwoTone,'ChildCareTwoTone',],
  [ChildFriendly,'ChildFriendly',],
  [ChildFriendlyOutlined,'ChildFriendlyOutlined',],
  [ChildFriendlyRounded,'ChildFriendlyRounded',],
  [ChildFriendlySharp,'ChildFriendlySharp',],
  [ChildFriendlyTwoTone,'ChildFriendlyTwoTone',],
  [ChromeReaderMode,'ChromeReaderMode',],
  [ChromeReaderModeOutlined,'ChromeReaderModeOutlined',],
  [ChromeReaderModeRounded,'ChromeReaderModeRounded',],
  [ChromeReaderModeSharp,'ChromeReaderModeSharp',],
  [ChromeReaderModeTwoTone,'ChromeReaderModeTwoTone',],
  [Class,'Class',],
  [ClassOutlined,'ClassOutlined',],
  [ClassRounded,'ClassRounded',],
  [ClassSharp,'ClassSharp',],
  [ClassTwoTone,'ClassTwoTone',],
  [Clear,'Clear',],
  [ClearAll,'ClearAll',],
  [ClearAllOutlined,'ClearAllOutlined',],
  [ClearAllRounded,'ClearAllRounded',],
  [ClearAllSharp,'ClearAllSharp',],
  [ClearAllTwoTone,'ClearAllTwoTone',],
  [ClearOutlined,'ClearOutlined',],
  [ClearRounded,'ClearRounded',],
  [ClearSharp,'ClearSharp',],
  [ClearTwoTone,'ClearTwoTone',],
  [Close,'Close',],
  [ClosedCaption,'ClosedCaption',],
  [ClosedCaptionOutlined,'ClosedCaptionOutlined',],
  [ClosedCaptionRounded,'ClosedCaptionRounded',],
  [ClosedCaptionSharp,'ClosedCaptionSharp',],
  [ClosedCaptionTwoTone,'ClosedCaptionTwoTone',],
  [CloseOutlined,'CloseOutlined',],
  [CloseRounded,'CloseRounded',],
  [CloseSharp,'CloseSharp',],
  [CloseTwoTone,'CloseTwoTone',],
  [Cloud,'Cloud',],
  [CloudCircle,'CloudCircle',],
  [CloudCircleOutlined,'CloudCircleOutlined',],
  [CloudCircleRounded,'CloudCircleRounded',],
  [CloudCircleSharp,'CloudCircleSharp',],
  [CloudCircleTwoTone,'CloudCircleTwoTone',],
  [CloudDone,'CloudDone',],
  [CloudDoneOutlined,'CloudDoneOutlined',],
  [CloudDoneRounded,'CloudDoneRounded',],
  [CloudDoneSharp,'CloudDoneSharp',],
  [CloudDoneTwoTone,'CloudDoneTwoTone',],
  [CloudDownload,'CloudDownload',],
  [CloudDownloadOutlined,'CloudDownloadOutlined',],
  [CloudDownloadRounded,'CloudDownloadRounded',],
  [CloudDownloadSharp,'CloudDownloadSharp',],
  [CloudDownloadTwoTone,'CloudDownloadTwoTone',],
  [CloudOff,'CloudOff',],
  [CloudOffOutlined,'CloudOffOutlined',],
  [CloudOffRounded,'CloudOffRounded',],
  [CloudOffSharp,'CloudOffSharp',],
  [CloudOffTwoTone,'CloudOffTwoTone',],
  [CloudOutlined,'CloudOutlined',],
  [CloudQueue,'CloudQueue',],
  [CloudQueueOutlined,'CloudQueueOutlined',],
  [CloudQueueRounded,'CloudQueueRounded',],
  [CloudQueueSharp,'CloudQueueSharp',],
  [CloudQueueTwoTone,'CloudQueueTwoTone',],
  [CloudRounded,'CloudRounded',],
  [CloudSharp,'CloudSharp',],
  [CloudTwoTone,'CloudTwoTone',],
  [CloudUpload,'CloudUpload',],
  [CloudUploadOutlined,'CloudUploadOutlined',],
  [CloudUploadRounded,'CloudUploadRounded',],
  [CloudUploadSharp,'CloudUploadSharp',],
  [CloudUploadTwoTone,'CloudUploadTwoTone',],
  [Code,'Code',],
  [CodeOutlined,'CodeOutlined',],
  [CodeRounded,'CodeRounded',],
  [CodeSharp,'CodeSharp',],
  [CodeTwoTone,'CodeTwoTone',],
  [Collections,'Collections',],
  [CollectionsBookmark,'CollectionsBookmark',],
  [CollectionsBookmarkOutlined,'CollectionsBookmarkOutlined',],
  [CollectionsBookmarkRounded,'CollectionsBookmarkRounded',],
  [CollectionsBookmarkSharp,'CollectionsBookmarkSharp',],
  [CollectionsBookmarkTwoTone,'CollectionsBookmarkTwoTone',],
  [CollectionsOutlined,'CollectionsOutlined',],
  [CollectionsRounded,'CollectionsRounded',],
  [CollectionsSharp,'CollectionsSharp',],
  [CollectionsTwoTone,'CollectionsTwoTone',],
  [Colorize,'Colorize',],
  [ColorizeOutlined,'ColorizeOutlined',],
  [ColorizeRounded,'ColorizeRounded',],
  [ColorizeSharp,'ColorizeSharp',],
  [ColorizeTwoTone,'ColorizeTwoTone',],
  [ColorLens,'ColorLens',],
  [ColorLensOutlined,'ColorLensOutlined',],
  [ColorLensRounded,'ColorLensRounded',],
  [ColorLensSharp,'ColorLensSharp',],
  [ColorLensTwoTone,'ColorLensTwoTone',],
  [Comment,'Comment',],
  [CommentOutlined,'CommentOutlined',],
  [CommentRounded,'CommentRounded',],
  [CommentSharp,'CommentSharp',],
  [CommentTwoTone,'CommentTwoTone',],
  [Commute,'Commute',],
  [CommuteOutlined,'CommuteOutlined',],
  [CommuteRounded,'CommuteRounded',],
  [CommuteSharp,'CommuteSharp',],
  [CommuteTwoTone,'CommuteTwoTone',],
  [Compare,'Compare',],
  [CompareArrows,'CompareArrows',],
  [CompareArrowsOutlined,'CompareArrowsOutlined',],
  [CompareArrowsRounded,'CompareArrowsRounded',],
  [CompareArrowsSharp,'CompareArrowsSharp',],
  [CompareArrowsTwoTone,'CompareArrowsTwoTone',],
  [CompareOutlined,'CompareOutlined',],
  [CompareRounded,'CompareRounded',],
  [CompareSharp,'CompareSharp',],
  [CompareTwoTone,'CompareTwoTone',],
  [CompassCalibration,'CompassCalibration',],
  [CompassCalibrationOutlined,'CompassCalibrationOutlined',],
  [CompassCalibrationRounded,'CompassCalibrationRounded',],
  [CompassCalibrationSharp,'CompassCalibrationSharp',],
  [CompassCalibrationTwoTone,'CompassCalibrationTwoTone',],
  [Computer,'Computer',],
  [ComputerOutlined,'ComputerOutlined',],
  [ComputerRounded,'ComputerRounded',],
  [ComputerSharp,'ComputerSharp',],
  [ComputerTwoTone,'ComputerTwoTone',],
  [ConfirmationNumber,'ConfirmationNumber',],
  [ConfirmationNumberOutlined,'ConfirmationNumberOutlined',],
  [ConfirmationNumberRounded,'ConfirmationNumberRounded',],
  [ConfirmationNumberSharp,'ConfirmationNumberSharp',],
  [ConfirmationNumberTwoTone,'ConfirmationNumberTwoTone',],
  [Contactless,'Contactless',],
  [ContactlessOutlined,'ContactlessOutlined',],
  [ContactlessRounded,'ContactlessRounded',],
  [ContactlessSharp,'ContactlessSharp',],
  [ContactlessTwoTone,'ContactlessTwoTone',],
  [ContactMail,'ContactMail',],
  [ContactMailOutlined,'ContactMailOutlined',],
  [ContactMailRounded,'ContactMailRounded',],
  [ContactMailSharp,'ContactMailSharp',],
  [ContactMailTwoTone,'ContactMailTwoTone',],
  [ContactPhone,'ContactPhone',],
  [ContactPhoneOutlined,'ContactPhoneOutlined',],
  [ContactPhoneRounded,'ContactPhoneRounded',],
  [ContactPhoneSharp,'ContactPhoneSharp',],
  [ContactPhoneTwoTone,'ContactPhoneTwoTone',],
  [Contacts,'Contacts',],
  [ContactsOutlined,'ContactsOutlined',],
  [ContactsRounded,'ContactsRounded',],
  [ContactsSharp,'ContactsSharp',],
  [ContactsTwoTone,'ContactsTwoTone',],
  [ContactSupport,'ContactSupport',],
  [ContactSupportOutlined,'ContactSupportOutlined',],
  [ContactSupportRounded,'ContactSupportRounded',],
  [ContactSupportSharp,'ContactSupportSharp',],
  [ContactSupportTwoTone,'ContactSupportTwoTone',],
  [ControlCamera,'ControlCamera',],
  [ControlCameraOutlined,'ControlCameraOutlined',],
  [ControlCameraRounded,'ControlCameraRounded',],
  [ControlCameraSharp,'ControlCameraSharp',],
  [ControlCameraTwoTone,'ControlCameraTwoTone',],
  [ControlPoint,'ControlPoint',],
  [ControlPointDuplicate,'ControlPointDuplicate',],
  [ControlPointDuplicateOutlined,'ControlPointDuplicateOutlined',],
  [ControlPointDuplicateRounded,'ControlPointDuplicateRounded',],
  [ControlPointDuplicateSharp,'ControlPointDuplicateSharp',],
  [ControlPointDuplicateTwoTone,'ControlPointDuplicateTwoTone',],
  [ControlPointOutlined,'ControlPointOutlined',],
  [ControlPointRounded,'ControlPointRounded',],
  [ControlPointSharp,'ControlPointSharp',],
  [ControlPointTwoTone,'ControlPointTwoTone',],
  [Copyright,'Copyright',],
  [CopyrightOutlined,'CopyrightOutlined',],
  [CopyrightRounded,'CopyrightRounded',],
  [CopyrightSharp,'CopyrightSharp',],
  [CopyrightTwoTone,'CopyrightTwoTone',],
  [Create,'Create',],
  [CreateNewFolder,'CreateNewFolder',],
  [CreateNewFolderOutlined,'CreateNewFolderOutlined',],
  [CreateNewFolderRounded,'CreateNewFolderRounded',],
  [CreateNewFolderSharp,'CreateNewFolderSharp',],
  [CreateNewFolderTwoTone,'CreateNewFolderTwoTone',],
  [CreateOutlined,'CreateOutlined',],
  [CreateRounded,'CreateRounded',],
  [CreateSharp,'CreateSharp',],
  [CreateTwoTone,'CreateTwoTone',],
  [CreditCard,'CreditCard',],
  [CreditCardOutlined,'CreditCardOutlined',],
  [CreditCardRounded,'CreditCardRounded',],
  [CreditCardSharp,'CreditCardSharp',],
  [CreditCardTwoTone,'CreditCardTwoTone',],
  [Crop,'Crop',],
  [Crop169,'Crop169',],
  [Crop169Outlined,'Crop169Outlined',],
  [Crop169Rounded,'Crop169Rounded',],
  [Crop169Sharp,'Crop169Sharp',],
  [Crop169TwoTone,'Crop169TwoTone',],
  [Crop32,'Crop32',],
  [Crop32Outlined,'Crop32Outlined',],
  [Crop32Rounded,'Crop32Rounded',],
  [Crop32Sharp,'Crop32Sharp',],
  [Crop32TwoTone,'Crop32TwoTone',],
  [Crop54,'Crop54',],
  [Crop54Outlined,'Crop54Outlined',],
  [Crop54Rounded,'Crop54Rounded',],
  [Crop54Sharp,'Crop54Sharp',],
  [Crop54TwoTone,'Crop54TwoTone',],
  [Crop75,'Crop75',],
  [Crop75Outlined,'Crop75Outlined',],
  [Crop75Rounded,'Crop75Rounded',],
  [Crop75Sharp,'Crop75Sharp',],
  [Crop75TwoTone,'Crop75TwoTone',],
  [CropDin,'CropDin',],
  [CropDinOutlined,'CropDinOutlined',],
  [CropDinRounded,'CropDinRounded',],
  [CropDinSharp,'CropDinSharp',],
  [CropDinTwoTone,'CropDinTwoTone',],
  [CropFree,'CropFree',],
  [CropFreeOutlined,'CropFreeOutlined',],
  [CropFreeRounded,'CropFreeRounded',],
  [CropFreeSharp,'CropFreeSharp',],
  [CropFreeTwoTone,'CropFreeTwoTone',],
  [CropLandscape,'CropLandscape',],
  [CropLandscapeOutlined,'CropLandscapeOutlined',],
  [CropLandscapeRounded,'CropLandscapeRounded',],
  [CropLandscapeSharp,'CropLandscapeSharp',],
  [CropLandscapeTwoTone,'CropLandscapeTwoTone',],
  [CropOriginal,'CropOriginal',],
  [CropOriginalOutlined,'CropOriginalOutlined',],
  [CropOriginalRounded,'CropOriginalRounded',],
  [CropOriginalSharp,'CropOriginalSharp',],
  [CropOriginalTwoTone,'CropOriginalTwoTone',],
  [CropOutlined,'CropOutlined',],
  [CropPortrait,'CropPortrait',],
  [CropPortraitOutlined,'CropPortraitOutlined',],
  [CropPortraitRounded,'CropPortraitRounded',],
  [CropPortraitSharp,'CropPortraitSharp',],
  [CropPortraitTwoTone,'CropPortraitTwoTone',],
  [CropRotate,'CropRotate',],
  [CropRotateOutlined,'CropRotateOutlined',],
  [CropRotateRounded,'CropRotateRounded',],
  [CropRotateSharp,'CropRotateSharp',],
  [CropRotateTwoTone,'CropRotateTwoTone',],
  [CropRounded,'CropRounded',],
  [CropSharp,'CropSharp',],
  [CropSquare,'CropSquare',],
  [CropSquareOutlined,'CropSquareOutlined',],
  [CropSquareRounded,'CropSquareRounded',],
  [CropSquareSharp,'CropSquareSharp',],
  [CropSquareTwoTone,'CropSquareTwoTone',],
  [CropTwoTone,'CropTwoTone',],
  [Dashboard,'Dashboard',],
  [DashboardOutlined,'DashboardOutlined',],
  [DashboardRounded,'DashboardRounded',],
  [DashboardSharp,'DashboardSharp',],
  [DashboardTwoTone,'DashboardTwoTone',],
  [DataUsage,'DataUsage',],
  [DataUsageOutlined,'DataUsageOutlined',],
  [DataUsageRounded,'DataUsageRounded',],
  [DataUsageSharp,'DataUsageSharp',],
  [DataUsageTwoTone,'DataUsageTwoTone',],
  [DateRange,'DateRange',],
  [DateRangeOutlined,'DateRangeOutlined',],
  [DateRangeRounded,'DateRangeRounded',],
  [DateRangeSharp,'DateRangeSharp',],
  [DateRangeTwoTone,'DateRangeTwoTone',],
  [Deck,'Deck',],
  [DeckOutlined,'DeckOutlined',],
  [DeckRounded,'DeckRounded',],
  [DeckSharp,'DeckSharp',],
  [DeckTwoTone,'DeckTwoTone',],
  [Dehaze,'Dehaze',],
  [DehazeOutlined,'DehazeOutlined',],
  [DehazeRounded,'DehazeRounded',],
  [DehazeSharp,'DehazeSharp',],
  [DehazeTwoTone,'DehazeTwoTone',],
  [Delete,'Delete',],
  [DeleteForever,'DeleteForever',],
  [DeleteForeverOutlined,'DeleteForeverOutlined',],
  [DeleteForeverRounded,'DeleteForeverRounded',],
  [DeleteForeverSharp,'DeleteForeverSharp',],
  [DeleteForeverTwoTone,'DeleteForeverTwoTone',],
  [DeleteOutline,'DeleteOutline',],
  [DeleteOutlined,'DeleteOutlined',],
  [DeleteOutlineOutlined,'DeleteOutlineOutlined',],
  [DeleteOutlineRounded,'DeleteOutlineRounded',],
  [DeleteOutlineSharp,'DeleteOutlineSharp',],
  [DeleteOutlineTwoTone,'DeleteOutlineTwoTone',],
  [DeleteRounded,'DeleteRounded',],
  [DeleteSharp,'DeleteSharp',],
  [DeleteSweep,'DeleteSweep',],
  [DeleteSweepOutlined,'DeleteSweepOutlined',],
  [DeleteSweepRounded,'DeleteSweepRounded',],
  [DeleteSweepSharp,'DeleteSweepSharp',],
  [DeleteSweepTwoTone,'DeleteSweepTwoTone',],
  [DeleteTwoTone,'DeleteTwoTone',],
  [DepartureBoard,'DepartureBoard',],
  [DepartureBoardOutlined,'DepartureBoardOutlined',],
  [DepartureBoardRounded,'DepartureBoardRounded',],
  [DepartureBoardSharp,'DepartureBoardSharp',],
  [DepartureBoardTwoTone,'DepartureBoardTwoTone',],
  [Description,'Description',],
  [DescriptionOutlined,'DescriptionOutlined',],
  [DescriptionRounded,'DescriptionRounded',],
  [DescriptionSharp,'DescriptionSharp',],
  [DescriptionTwoTone,'DescriptionTwoTone',],
  [DesktopAccessDisabled,'DesktopAccessDisabled',],
  [DesktopAccessDisabledOutlined,'DesktopAccessDisabledOutlined',],
  [DesktopAccessDisabledRounded,'DesktopAccessDisabledRounded',],
  [DesktopAccessDisabledSharp,'DesktopAccessDisabledSharp',],
  [DesktopAccessDisabledTwoTone,'DesktopAccessDisabledTwoTone',],
  [DesktopMac,'DesktopMac',],
  [DesktopMacOutlined,'DesktopMacOutlined',],
  [DesktopMacRounded,'DesktopMacRounded',],
  [DesktopMacSharp,'DesktopMacSharp',],
  [DesktopMacTwoTone,'DesktopMacTwoTone',],
  [DesktopWindows,'DesktopWindows',],
  [DesktopWindowsOutlined,'DesktopWindowsOutlined',],
  [DesktopWindowsRounded,'DesktopWindowsRounded',],
  [DesktopWindowsSharp,'DesktopWindowsSharp',],
  [DesktopWindowsTwoTone,'DesktopWindowsTwoTone',],
  [Details,'Details',],
  [DetailsOutlined,'DetailsOutlined',],
  [DetailsRounded,'DetailsRounded',],
  [DetailsSharp,'DetailsSharp',],
  [DetailsTwoTone,'DetailsTwoTone',],
  [DeveloperBoard,'DeveloperBoard',],
  [DeveloperBoardOutlined,'DeveloperBoardOutlined',],
  [DeveloperBoardRounded,'DeveloperBoardRounded',],
  [DeveloperBoardSharp,'DeveloperBoardSharp',],
  [DeveloperBoardTwoTone,'DeveloperBoardTwoTone',],
  [DeveloperMode,'DeveloperMode',],
  [DeveloperModeOutlined,'DeveloperModeOutlined',],
  [DeveloperModeRounded,'DeveloperModeRounded',],
  [DeveloperModeSharp,'DeveloperModeSharp',],
  [DeveloperModeTwoTone,'DeveloperModeTwoTone',],
  [DeviceHub,'DeviceHub',],
  [DeviceHubOutlined,'DeviceHubOutlined',],
  [DeviceHubRounded,'DeviceHubRounded',],
  [DeviceHubSharp,'DeviceHubSharp',],
  [DeviceHubTwoTone,'DeviceHubTwoTone',],
  [Devices,'Devices',],
  [DevicesOther,'DevicesOther',],
  [DevicesOtherOutlined,'DevicesOtherOutlined',],
  [DevicesOtherRounded,'DevicesOtherRounded',],
  [DevicesOtherSharp,'DevicesOtherSharp',],
  [DevicesOtherTwoTone,'DevicesOtherTwoTone',],
  [DevicesOutlined,'DevicesOutlined',],
  [DevicesRounded,'DevicesRounded',],
  [DevicesSharp,'DevicesSharp',],
  [DevicesTwoTone,'DevicesTwoTone',],
  [DeviceUnknown,'DeviceUnknown',],
  [DeviceUnknownOutlined,'DeviceUnknownOutlined',],
  [DeviceUnknownRounded,'DeviceUnknownRounded',],
  [DeviceUnknownSharp,'DeviceUnknownSharp',],
  [DeviceUnknownTwoTone,'DeviceUnknownTwoTone',],
  [DialerSip,'DialerSip',],
  [DialerSipOutlined,'DialerSipOutlined',],
  [DialerSipRounded,'DialerSipRounded',],
  [DialerSipSharp,'DialerSipSharp',],
  [DialerSipTwoTone,'DialerSipTwoTone',],
  [Dialpad,'Dialpad',],
  [DialpadOutlined,'DialpadOutlined',],
  [DialpadRounded,'DialpadRounded',],
  [DialpadSharp,'DialpadSharp',],
  [DialpadTwoTone,'DialpadTwoTone',],
  [Directions,'Directions',],
  [DirectionsBike,'DirectionsBike',],
  [DirectionsBikeOutlined,'DirectionsBikeOutlined',],
  [DirectionsBikeRounded,'DirectionsBikeRounded',],
  [DirectionsBikeSharp,'DirectionsBikeSharp',],
  [DirectionsBikeTwoTone,'DirectionsBikeTwoTone',],
  [DirectionsBoat,'DirectionsBoat',],
  [DirectionsBoatOutlined,'DirectionsBoatOutlined',],
  [DirectionsBoatRounded,'DirectionsBoatRounded',],
  [DirectionsBoatSharp,'DirectionsBoatSharp',],
  [DirectionsBoatTwoTone,'DirectionsBoatTwoTone',],
  [DirectionsBus,'DirectionsBus',],
  [DirectionsBusOutlined,'DirectionsBusOutlined',],
  [DirectionsBusRounded,'DirectionsBusRounded',],
  [DirectionsBusSharp,'DirectionsBusSharp',],
  [DirectionsBusTwoTone,'DirectionsBusTwoTone',],
  [DirectionsCar,'DirectionsCar',],
  [DirectionsCarOutlined,'DirectionsCarOutlined',],
  [DirectionsCarRounded,'DirectionsCarRounded',],
  [DirectionsCarSharp,'DirectionsCarSharp',],
  [DirectionsCarTwoTone,'DirectionsCarTwoTone',],
  [DirectionsOutlined,'DirectionsOutlined',],
  [DirectionsRailway,'DirectionsRailway',],
  [DirectionsRailwayOutlined,'DirectionsRailwayOutlined',],
  [DirectionsRailwayRounded,'DirectionsRailwayRounded',],
  [DirectionsRailwaySharp,'DirectionsRailwaySharp',],
  [DirectionsRailwayTwoTone,'DirectionsRailwayTwoTone',],
  [DirectionsRounded,'DirectionsRounded',],
  [DirectionsRun,'DirectionsRun',],
  [DirectionsRunOutlined,'DirectionsRunOutlined',],
  [DirectionsRunRounded,'DirectionsRunRounded',],
  [DirectionsRunSharp,'DirectionsRunSharp',],
  [DirectionsRunTwoTone,'DirectionsRunTwoTone',],
  [DirectionsSharp,'DirectionsSharp',],
  [DirectionsSubway,'DirectionsSubway',],
  [DirectionsSubwayOutlined,'DirectionsSubwayOutlined',],
  [DirectionsSubwayRounded,'DirectionsSubwayRounded',],
  [DirectionsSubwaySharp,'DirectionsSubwaySharp',],
  [DirectionsSubwayTwoTone,'DirectionsSubwayTwoTone',],
  [DirectionsTransit,'DirectionsTransit',],
  [DirectionsTransitOutlined,'DirectionsTransitOutlined',],
  [DirectionsTransitRounded,'DirectionsTransitRounded',],
  [DirectionsTransitSharp,'DirectionsTransitSharp',],
  [DirectionsTransitTwoTone,'DirectionsTransitTwoTone',],
  [DirectionsTwoTone,'DirectionsTwoTone',],
  [DirectionsWalk,'DirectionsWalk',],
  [DirectionsWalkOutlined,'DirectionsWalkOutlined',],
  [DirectionsWalkRounded,'DirectionsWalkRounded',],
  [DirectionsWalkSharp,'DirectionsWalkSharp',],
  [DirectionsWalkTwoTone,'DirectionsWalkTwoTone',],
  [DiscFull,'DiscFull',],
  [DiscFullOutlined,'DiscFullOutlined',],
  [DiscFullRounded,'DiscFullRounded',],
  [DiscFullSharp,'DiscFullSharp',],
  [DiscFullTwoTone,'DiscFullTwoTone',],
  [Dns,'Dns',],
  [DnsOutlined,'DnsOutlined',],
  [DnsRounded,'DnsRounded',],
  [DnsSharp,'DnsSharp',],
  [DnsTwoTone,'DnsTwoTone',],
  [Dock,'Dock',],
  [DockOutlined,'DockOutlined',],
  [DockRounded,'DockRounded',],
  [DockSharp,'DockSharp',],
  [DockTwoTone,'DockTwoTone',],
  [Domain,'Domain',],
  [DomainDisabled,'DomainDisabled',],
  [DomainDisabledOutlined,'DomainDisabledOutlined',],
  [DomainDisabledRounded,'DomainDisabledRounded',],
  [DomainDisabledSharp,'DomainDisabledSharp',],
  [DomainDisabledTwoTone,'DomainDisabledTwoTone',],
  [DomainOutlined,'DomainOutlined',],
  [DomainRounded,'DomainRounded',],
  [DomainSharp,'DomainSharp',],
  [DomainTwoTone,'DomainTwoTone',],
  [Done,'Done',],
  [DoneAll,'DoneAll',],
  [DoneAllOutlined,'DoneAllOutlined',],
  [DoneAllRounded,'DoneAllRounded',],
  [DoneAllSharp,'DoneAllSharp',],
  [DoneAllTwoTone,'DoneAllTwoTone',],
  [DoneOutline,'DoneOutline',],
  [DoneOutlined,'DoneOutlined',],
  [DoneOutlineOutlined,'DoneOutlineOutlined',],
  [DoneOutlineRounded,'DoneOutlineRounded',],
  [DoneOutlineSharp,'DoneOutlineSharp',],
  [DoneOutlineTwoTone,'DoneOutlineTwoTone',],
  [DoneRounded,'DoneRounded',],
  [DoneSharp,'DoneSharp',],
  [DoneTwoTone,'DoneTwoTone',],
  [DonutLarge,'DonutLarge',],
  [DonutLargeOutlined,'DonutLargeOutlined',],
  [DonutLargeRounded,'DonutLargeRounded',],
  [DonutLargeSharp,'DonutLargeSharp',],
  [DonutLargeTwoTone,'DonutLargeTwoTone',],
  [DonutSmall,'DonutSmall',],
  [DonutSmallOutlined,'DonutSmallOutlined',],
  [DonutSmallRounded,'DonutSmallRounded',],
  [DonutSmallSharp,'DonutSmallSharp',],
  [DonutSmallTwoTone,'DonutSmallTwoTone',],
  [DoubleArrow,'DoubleArrow',],
  [DoubleArrowOutlined,'DoubleArrowOutlined',],
  [DoubleArrowRounded,'DoubleArrowRounded',],
  [DoubleArrowSharp,'DoubleArrowSharp',],
  [DoubleArrowTwoTone,'DoubleArrowTwoTone',],
  [Drafts,'Drafts',],
  [DraftsOutlined,'DraftsOutlined',],
  [DraftsRounded,'DraftsRounded',],
  [DraftsSharp,'DraftsSharp',],
  [DraftsTwoTone,'DraftsTwoTone',],
  [DragHandle,'DragHandle',],
  [DragHandleOutlined,'DragHandleOutlined',],
  [DragHandleRounded,'DragHandleRounded',],
  [DragHandleSharp,'DragHandleSharp',],
  [DragHandleTwoTone,'DragHandleTwoTone',],
  [DragIndicator,'DragIndicator',],
  [DragIndicatorOutlined,'DragIndicatorOutlined',],
  [DragIndicatorRounded,'DragIndicatorRounded',],
  [DragIndicatorSharp,'DragIndicatorSharp',],
  [DragIndicatorTwoTone,'DragIndicatorTwoTone',],
  [DriveEta,'DriveEta',],
  [DriveEtaOutlined,'DriveEtaOutlined',],
  [DriveEtaRounded,'DriveEtaRounded',],
  [DriveEtaSharp,'DriveEtaSharp',],
  [DriveEtaTwoTone,'DriveEtaTwoTone',],
  [Duo,'Duo',],
  [DuoOutlined,'DuoOutlined',],
  [DuoRounded,'DuoRounded',],
  [DuoSharp,'DuoSharp',],
  [DuoTwoTone,'DuoTwoTone',],
  [Dvr,'Dvr',],
  [DvrOutlined,'DvrOutlined',],
  [DvrRounded,'DvrRounded',],
  [DvrSharp,'DvrSharp',],
  [DvrTwoTone,'DvrTwoTone',],
  [DynamicFeed,'DynamicFeed',],
  [DynamicFeedOutlined,'DynamicFeedOutlined',],
  [DynamicFeedRounded,'DynamicFeedRounded',],
  [DynamicFeedSharp,'DynamicFeedSharp',],
  [DynamicFeedTwoTone,'DynamicFeedTwoTone',],
  [Eco,'Eco',],
  [EcoOutlined,'EcoOutlined',],
  [EcoRounded,'EcoRounded',],
  [EcoSharp,'EcoSharp',],
  [EcoTwoTone,'EcoTwoTone',],
  [Edit,'Edit',],
  [EditAttributes,'EditAttributes',],
  [EditAttributesOutlined,'EditAttributesOutlined',],
  [EditAttributesRounded,'EditAttributesRounded',],
  [EditAttributesSharp,'EditAttributesSharp',],
  [EditAttributesTwoTone,'EditAttributesTwoTone',],
  [EditLocation,'EditLocation',],
  [EditLocationOutlined,'EditLocationOutlined',],
  [EditLocationRounded,'EditLocationRounded',],
  [EditLocationSharp,'EditLocationSharp',],
  [EditLocationTwoTone,'EditLocationTwoTone',],
  [EditOutlined,'EditOutlined',],
  [EditRounded,'EditRounded',],
  [EditSharp,'EditSharp',],
  [EditTwoTone,'EditTwoTone',],
  [Eject,'Eject',],
  [EjectOutlined,'EjectOutlined',],
  [EjectRounded,'EjectRounded',],
  [EjectSharp,'EjectSharp',],
  [EjectTwoTone,'EjectTwoTone',],
  [Email,'Email',],
  [EmailOutlined,'EmailOutlined',],
  [EmailRounded,'EmailRounded',],
  [EmailSharp,'EmailSharp',],
  [EmailTwoTone,'EmailTwoTone',],
  [EmojiEmotions,'EmojiEmotions',],
  [EmojiEmotionsOutlined,'EmojiEmotionsOutlined',],
  [EmojiEmotionsRounded,'EmojiEmotionsRounded',],
  [EmojiEmotionsSharp,'EmojiEmotionsSharp',],
  [EmojiEmotionsTwoTone,'EmojiEmotionsTwoTone',],
  [EmojiEvents,'EmojiEvents',],
  [EmojiEventsOutlined,'EmojiEventsOutlined',],
  [EmojiEventsRounded,'EmojiEventsRounded',],
  [EmojiEventsSharp,'EmojiEventsSharp',],
  [EmojiEventsTwoTone,'EmojiEventsTwoTone',],
  [EmojiFlags,'EmojiFlags',],
  [EmojiFlagsOutlined,'EmojiFlagsOutlined',],
  [EmojiFlagsRounded,'EmojiFlagsRounded',],
  [EmojiFlagsSharp,'EmojiFlagsSharp',],
  [EmojiFlagsTwoTone,'EmojiFlagsTwoTone',],
  [EmojiFoodBeverage,'EmojiFoodBeverage',],
  [EmojiFoodBeverageOutlined,'EmojiFoodBeverageOutlined',],
  [EmojiFoodBeverageRounded,'EmojiFoodBeverageRounded',],
  [EmojiFoodBeverageSharp,'EmojiFoodBeverageSharp',],
  [EmojiFoodBeverageTwoTone,'EmojiFoodBeverageTwoTone',],
  [EmojiNature,'EmojiNature',],
  [EmojiNatureOutlined,'EmojiNatureOutlined',],
  [EmojiNatureRounded,'EmojiNatureRounded',],
  [EmojiNatureSharp,'EmojiNatureSharp',],
  [EmojiNatureTwoTone,'EmojiNatureTwoTone',],
  [EmojiObjects,'EmojiObjects',],
  [EmojiObjectsOutlined,'EmojiObjectsOutlined',],
  [EmojiObjectsRounded,'EmojiObjectsRounded',],
  [EmojiObjectsSharp,'EmojiObjectsSharp',],
  [EmojiObjectsTwoTone,'EmojiObjectsTwoTone',],
  [EmojiPeople,'EmojiPeople',],
  [EmojiPeopleOutlined,'EmojiPeopleOutlined',],
  [EmojiPeopleRounded,'EmojiPeopleRounded',],
  [EmojiPeopleSharp,'EmojiPeopleSharp',],
  [EmojiPeopleTwoTone,'EmojiPeopleTwoTone',],
  [EmojiSymbols,'EmojiSymbols',],
  [EmojiSymbolsOutlined,'EmojiSymbolsOutlined',],
  [EmojiSymbolsRounded,'EmojiSymbolsRounded',],
  [EmojiSymbolsSharp,'EmojiSymbolsSharp',],
  [EmojiSymbolsTwoTone,'EmojiSymbolsTwoTone',],
  [EmojiTransportation,'EmojiTransportation',],
  [EmojiTransportationOutlined,'EmojiTransportationOutlined',],
  [EmojiTransportationRounded,'EmojiTransportationRounded',],
  [EmojiTransportationSharp,'EmojiTransportationSharp',],
  [EmojiTransportationTwoTone,'EmojiTransportationTwoTone',],
  [EnhancedEncryption,'EnhancedEncryption',],
  [EnhancedEncryptionOutlined,'EnhancedEncryptionOutlined',],
  [EnhancedEncryptionRounded,'EnhancedEncryptionRounded',],
  [EnhancedEncryptionSharp,'EnhancedEncryptionSharp',],
  [EnhancedEncryptionTwoTone,'EnhancedEncryptionTwoTone',],
  [Equalizer,'Equalizer',],
  [EqualizerOutlined,'EqualizerOutlined',],
  [EqualizerRounded,'EqualizerRounded',],
  [EqualizerSharp,'EqualizerSharp',],
  [EqualizerTwoTone,'EqualizerTwoTone',],
  [Error,'Error',],
  [ErrorOutline,'ErrorOutline',],
  [ErrorOutlined,'ErrorOutlined',],
  [ErrorOutlineOutlined,'ErrorOutlineOutlined',],
  [ErrorOutlineRounded,'ErrorOutlineRounded',],
  [ErrorOutlineSharp,'ErrorOutlineSharp',],
  [ErrorOutlineTwoTone,'ErrorOutlineTwoTone',],
  [ErrorRounded,'ErrorRounded',],
  [ErrorSharp,'ErrorSharp',],
  [ErrorTwoTone,'ErrorTwoTone',],
  [Euro,'Euro',],
  [EuroOutlined,'EuroOutlined',],
  [EuroRounded,'EuroRounded',],
  [EuroSharp,'EuroSharp',],
  [EuroSymbol,'EuroSymbol',],
  [EuroSymbolOutlined,'EuroSymbolOutlined',],
  [EuroSymbolRounded,'EuroSymbolRounded',],
  [EuroSymbolSharp,'EuroSymbolSharp',],
  [EuroSymbolTwoTone,'EuroSymbolTwoTone',],
  [EuroTwoTone,'EuroTwoTone',],
  [Event,'Event',],
  [EventAvailable,'EventAvailable',],
  [EventAvailableOutlined,'EventAvailableOutlined',],
  [EventAvailableRounded,'EventAvailableRounded',],
  [EventAvailableSharp,'EventAvailableSharp',],
  [EventAvailableTwoTone,'EventAvailableTwoTone',],
  [EventBusy,'EventBusy',],
  [EventBusyOutlined,'EventBusyOutlined',],
  [EventBusyRounded,'EventBusyRounded',],
  [EventBusySharp,'EventBusySharp',],
  [EventBusyTwoTone,'EventBusyTwoTone',],
  [EventNote,'EventNote',],
  [EventNoteOutlined,'EventNoteOutlined',],
  [EventNoteRounded,'EventNoteRounded',],
  [EventNoteSharp,'EventNoteSharp',],
  [EventNoteTwoTone,'EventNoteTwoTone',],
  [EventOutlined,'EventOutlined',],
  [EventRounded,'EventRounded',],
  [EventSeat,'EventSeat',],
  [EventSeatOutlined,'EventSeatOutlined',],
  [EventSeatRounded,'EventSeatRounded',],
  [EventSeatSharp,'EventSeatSharp',],
  [EventSeatTwoTone,'EventSeatTwoTone',],
  [EventSharp,'EventSharp',],
  [EventTwoTone,'EventTwoTone',],
  [EvStation,'EvStation',],
  [EvStationOutlined,'EvStationOutlined',],
  [EvStationRounded,'EvStationRounded',],
  [EvStationSharp,'EvStationSharp',],
  [EvStationTwoTone,'EvStationTwoTone',],
  [ExitToApp,'ExitToApp',],
  [ExitToAppOutlined,'ExitToAppOutlined',],
  [ExitToAppRounded,'ExitToAppRounded',],
  [ExitToAppSharp,'ExitToAppSharp',],
  [ExitToAppTwoTone,'ExitToAppTwoTone',],
  [ExpandLess,'ExpandLess',],
  [ExpandLessOutlined,'ExpandLessOutlined',],
  [ExpandLessRounded,'ExpandLessRounded',],
  [ExpandLessSharp,'ExpandLessSharp',],
  [ExpandLessTwoTone,'ExpandLessTwoTone',],
  [ExpandMore,'ExpandMore',],
  [ExpandMoreOutlined,'ExpandMoreOutlined',],
  [ExpandMoreRounded,'ExpandMoreRounded',],
  [ExpandMoreSharp,'ExpandMoreSharp',],
  [ExpandMoreTwoTone,'ExpandMoreTwoTone',],
  [Explicit,'Explicit',],
  [ExplicitOutlined,'ExplicitOutlined',],
  [ExplicitRounded,'ExplicitRounded',],
  [ExplicitSharp,'ExplicitSharp',],
  [ExplicitTwoTone,'ExplicitTwoTone',],
  [Explore,'Explore',],
  [ExploreOff,'ExploreOff',],
  [ExploreOffOutlined,'ExploreOffOutlined',],
  [ExploreOffRounded,'ExploreOffRounded',],
  [ExploreOffSharp,'ExploreOffSharp',],
  [ExploreOffTwoTone,'ExploreOffTwoTone',],
  [ExploreOutlined,'ExploreOutlined',],
  [ExploreRounded,'ExploreRounded',],
  [ExploreSharp,'ExploreSharp',],
  [ExploreTwoTone,'ExploreTwoTone',],
  [Exposure,'Exposure',],
  [ExposureNeg1,'ExposureNeg1',],
  [ExposureNeg1Outlined,'ExposureNeg1Outlined',],
  [ExposureNeg1Rounded,'ExposureNeg1Rounded',],
  [ExposureNeg1Sharp,'ExposureNeg1Sharp',],
  [ExposureNeg1TwoTone,'ExposureNeg1TwoTone',],
  [ExposureNeg2,'ExposureNeg2',],
  [ExposureNeg2Outlined,'ExposureNeg2Outlined',],
  [ExposureNeg2Rounded,'ExposureNeg2Rounded',],
  [ExposureNeg2Sharp,'ExposureNeg2Sharp',],
  [ExposureNeg2TwoTone,'ExposureNeg2TwoTone',],
  [ExposureOutlined,'ExposureOutlined',],
  [ExposurePlus1,'ExposurePlus1',],
  [ExposurePlus1Outlined,'ExposurePlus1Outlined',],
  [ExposurePlus1Rounded,'ExposurePlus1Rounded',],
  [ExposurePlus1Sharp,'ExposurePlus1Sharp',],
  [ExposurePlus1TwoTone,'ExposurePlus1TwoTone',],
  [ExposurePlus2,'ExposurePlus2',],
  [ExposurePlus2Outlined,'ExposurePlus2Outlined',],
  [ExposurePlus2Rounded,'ExposurePlus2Rounded',],
  [ExposurePlus2Sharp,'ExposurePlus2Sharp',],
  [ExposurePlus2TwoTone,'ExposurePlus2TwoTone',],
  [ExposureRounded,'ExposureRounded',],
  [ExposureSharp,'ExposureSharp',],
  [ExposureTwoTone,'ExposureTwoTone',],
  [ExposureZero,'ExposureZero',],
  [ExposureZeroOutlined,'ExposureZeroOutlined',],
  [ExposureZeroRounded,'ExposureZeroRounded',],
  [ExposureZeroSharp,'ExposureZeroSharp',],
  [ExposureZeroTwoTone,'ExposureZeroTwoTone',],
  [Extension,'Extension',],
  [ExtensionOutlined,'ExtensionOutlined',],
  [ExtensionRounded,'ExtensionRounded',],
  [ExtensionSharp,'ExtensionSharp',],
  [ExtensionTwoTone,'ExtensionTwoTone',],
  [Face,'Face',],
  [Facebook,'Facebook',],
  [FaceOutlined,'FaceOutlined',],
  [FaceRounded,'FaceRounded',],
  [FaceSharp,'FaceSharp',],
  [FaceTwoTone,'FaceTwoTone',],
  [Fastfood,'Fastfood',],
  [FastfoodOutlined,'FastfoodOutlined',],
  [FastfoodRounded,'FastfoodRounded',],
  [FastfoodSharp,'FastfoodSharp',],
  [FastfoodTwoTone,'FastfoodTwoTone',],
  [FastForward,'FastForward',],
  [FastForwardOutlined,'FastForwardOutlined',],
  [FastForwardRounded,'FastForwardRounded',],
  [FastForwardSharp,'FastForwardSharp',],
  [FastForwardTwoTone,'FastForwardTwoTone',],
  [FastRewind,'FastRewind',],
  [FastRewindOutlined,'FastRewindOutlined',],
  [FastRewindRounded,'FastRewindRounded',],
  [FastRewindSharp,'FastRewindSharp',],
  [FastRewindTwoTone,'FastRewindTwoTone',],
  [Favorite,'Favorite',],
  [FavoriteBorder,'FavoriteBorder',],
  [FavoriteBorderOutlined,'FavoriteBorderOutlined',],
  [FavoriteBorderRounded,'FavoriteBorderRounded',],
  [FavoriteBorderSharp,'FavoriteBorderSharp',],
  [FavoriteBorderTwoTone,'FavoriteBorderTwoTone',],
  [FavoriteOutlined,'FavoriteOutlined',],
  [FavoriteRounded,'FavoriteRounded',],
  [FavoriteSharp,'FavoriteSharp',],
  [FavoriteTwoTone,'FavoriteTwoTone',],
  [FeaturedPlayList,'FeaturedPlayList',],
  [FeaturedPlayListOutlined,'FeaturedPlayListOutlined',],
  [FeaturedPlayListRounded,'FeaturedPlayListRounded',],
  [FeaturedPlayListSharp,'FeaturedPlayListSharp',],
  [FeaturedPlayListTwoTone,'FeaturedPlayListTwoTone',],
  [FeaturedVideo,'FeaturedVideo',],
  [FeaturedVideoOutlined,'FeaturedVideoOutlined',],
  [FeaturedVideoRounded,'FeaturedVideoRounded',],
  [FeaturedVideoSharp,'FeaturedVideoSharp',],
  [FeaturedVideoTwoTone,'FeaturedVideoTwoTone',],
  [Feedback,'Feedback',],
  [FeedbackOutlined,'FeedbackOutlined',],
  [FeedbackRounded,'FeedbackRounded',],
  [FeedbackSharp,'FeedbackSharp',],
  [FeedbackTwoTone,'FeedbackTwoTone',],
  [FiberDvr,'FiberDvr',],
  [FiberDvrOutlined,'FiberDvrOutlined',],
  [FiberDvrRounded,'FiberDvrRounded',],
  [FiberDvrSharp,'FiberDvrSharp',],
  [FiberDvrTwoTone,'FiberDvrTwoTone',],
  [FiberManualRecord,'FiberManualRecord',],
  [FiberManualRecordOutlined,'FiberManualRecordOutlined',],
  [FiberManualRecordRounded,'FiberManualRecordRounded',],
  [FiberManualRecordSharp,'FiberManualRecordSharp',],
  [FiberManualRecordTwoTone,'FiberManualRecordTwoTone',],
  [FiberNew,'FiberNew',],
  [FiberNewOutlined,'FiberNewOutlined',],
  [FiberNewRounded,'FiberNewRounded',],
  [FiberNewSharp,'FiberNewSharp',],
  [FiberNewTwoTone,'FiberNewTwoTone',],
  [FiberPin,'FiberPin',],
  [FiberPinOutlined,'FiberPinOutlined',],
  [FiberPinRounded,'FiberPinRounded',],
  [FiberPinSharp,'FiberPinSharp',],
  [FiberPinTwoTone,'FiberPinTwoTone',],
  [FiberSmartRecord,'FiberSmartRecord',],
  [FiberSmartRecordOutlined,'FiberSmartRecordOutlined',],
  [FiberSmartRecordRounded,'FiberSmartRecordRounded',],
  [FiberSmartRecordSharp,'FiberSmartRecordSharp',],
  [FiberSmartRecordTwoTone,'FiberSmartRecordTwoTone',],
  [FileCopy,'FileCopy',],
  [FileCopyOutlined,'FileCopyOutlined',],
  [FileCopyRounded,'FileCopyRounded',],
  [FileCopySharp,'FileCopySharp',],
  [FileCopyTwoTone,'FileCopyTwoTone',],
  [Filter,'Filter',],
  [Filter1,'Filter1',],
  [Filter1Outlined,'Filter1Outlined',],
  [Filter1Rounded,'Filter1Rounded',],
  [Filter1Sharp,'Filter1Sharp',],
  [Filter1TwoTone,'Filter1TwoTone',],
  [Filter2,'Filter2',],
  [Filter2Outlined,'Filter2Outlined',],
  [Filter2Rounded,'Filter2Rounded',],
  [Filter2Sharp,'Filter2Sharp',],
  [Filter2TwoTone,'Filter2TwoTone',],
  [Filter3,'Filter3',],
  [Filter3Outlined,'Filter3Outlined',],
  [Filter3Rounded,'Filter3Rounded',],
  [Filter3Sharp,'Filter3Sharp',],
  [Filter3TwoTone,'Filter3TwoTone',],
  [Filter4,'Filter4',],
  [Filter4Outlined,'Filter4Outlined',],
  [Filter4Rounded,'Filter4Rounded',],
  [Filter4Sharp,'Filter4Sharp',],
  [Filter4TwoTone,'Filter4TwoTone',],
  [Filter5,'Filter5',],
  [Filter5Outlined,'Filter5Outlined',],
  [Filter5Rounded,'Filter5Rounded',],
  [Filter5Sharp,'Filter5Sharp',],
  [Filter5TwoTone,'Filter5TwoTone',],
  [Filter6,'Filter6',],
  [Filter6Outlined,'Filter6Outlined',],
  [Filter6Rounded,'Filter6Rounded',],
  [Filter6Sharp,'Filter6Sharp',],
  [Filter6TwoTone,'Filter6TwoTone',],
  [Filter7,'Filter7',],
  [Filter7Outlined,'Filter7Outlined',],
  [Filter7Rounded,'Filter7Rounded',],
  [Filter7Sharp,'Filter7Sharp',],
  [Filter7TwoTone,'Filter7TwoTone',],
  [Filter8,'Filter8',],
  [Filter8Outlined,'Filter8Outlined',],
  [Filter8Rounded,'Filter8Rounded',],
  [Filter8Sharp,'Filter8Sharp',],
  [Filter8TwoTone,'Filter8TwoTone',],
  [Filter9,'Filter9',],
  [Filter9Outlined,'Filter9Outlined',],
  [Filter9Plus,'Filter9Plus',],
  [Filter9PlusOutlined,'Filter9PlusOutlined',],
  [Filter9PlusRounded,'Filter9PlusRounded',],
  [Filter9PlusSharp,'Filter9PlusSharp',],
  [Filter9PlusTwoTone,'Filter9PlusTwoTone',],
  [Filter9Rounded,'Filter9Rounded',],
  [Filter9Sharp,'Filter9Sharp',],
  [Filter9TwoTone,'Filter9TwoTone',],
  [FilterBAndW,'FilterBAndW',],
  [FilterBAndWOutlined,'FilterBAndWOutlined',],
  [FilterBAndWRounded,'FilterBAndWRounded',],
  [FilterBAndWSharp,'FilterBAndWSharp',],
  [FilterBAndWTwoTone,'FilterBAndWTwoTone',],
  [FilterCenterFocus,'FilterCenterFocus',],
  [FilterCenterFocusOutlined,'FilterCenterFocusOutlined',],
  [FilterCenterFocusRounded,'FilterCenterFocusRounded',],
  [FilterCenterFocusSharp,'FilterCenterFocusSharp',],
  [FilterCenterFocusTwoTone,'FilterCenterFocusTwoTone',],
  [FilterDrama,'FilterDrama',],
  [FilterDramaOutlined,'FilterDramaOutlined',],
  [FilterDramaRounded,'FilterDramaRounded',],
  [FilterDramaSharp,'FilterDramaSharp',],
  [FilterDramaTwoTone,'FilterDramaTwoTone',],
  [FilterFrames,'FilterFrames',],
  [FilterFramesOutlined,'FilterFramesOutlined',],
  [FilterFramesRounded,'FilterFramesRounded',],
  [FilterFramesSharp,'FilterFramesSharp',],
  [FilterFramesTwoTone,'FilterFramesTwoTone',],
  [FilterHdr,'FilterHdr',],
  [FilterHdrOutlined,'FilterHdrOutlined',],
  [FilterHdrRounded,'FilterHdrRounded',],
  [FilterHdrSharp,'FilterHdrSharp',],
  [FilterHdrTwoTone,'FilterHdrTwoTone',],
  [FilterList,'FilterList',],
  [FilterListOutlined,'FilterListOutlined',],
  [FilterListRounded,'FilterListRounded',],
  [FilterListSharp,'FilterListSharp',],
  [FilterListTwoTone,'FilterListTwoTone',],
  [FilterNone,'FilterNone',],
  [FilterNoneOutlined,'FilterNoneOutlined',],
  [FilterNoneRounded,'FilterNoneRounded',],
  [FilterNoneSharp,'FilterNoneSharp',],
  [FilterNoneTwoTone,'FilterNoneTwoTone',],
  [FilterOutlined,'FilterOutlined',],
  [FilterRounded,'FilterRounded',],
  [FilterSharp,'FilterSharp',],
  [FilterTiltShift,'FilterTiltShift',],
  [FilterTiltShiftOutlined,'FilterTiltShiftOutlined',],
  [FilterTiltShiftRounded,'FilterTiltShiftRounded',],
  [FilterTiltShiftSharp,'FilterTiltShiftSharp',],
  [FilterTiltShiftTwoTone,'FilterTiltShiftTwoTone',],
  [FilterTwoTone,'FilterTwoTone',],
  [FilterVintage,'FilterVintage',],
  [FilterVintageOutlined,'FilterVintageOutlined',],
  [FilterVintageRounded,'FilterVintageRounded',],
  [FilterVintageSharp,'FilterVintageSharp',],
  [FilterVintageTwoTone,'FilterVintageTwoTone',],
  [FindInPage,'FindInPage',],
  [FindInPageOutlined,'FindInPageOutlined',],
  [FindInPageRounded,'FindInPageRounded',],
  [FindInPageSharp,'FindInPageSharp',],
  [FindInPageTwoTone,'FindInPageTwoTone',],
  [FindReplace,'FindReplace',],
  [FindReplaceOutlined,'FindReplaceOutlined',],
  [FindReplaceRounded,'FindReplaceRounded',],
  [FindReplaceSharp,'FindReplaceSharp',],
  [FindReplaceTwoTone,'FindReplaceTwoTone',],
  [Fingerprint,'Fingerprint',],
  [FingerprintOutlined,'FingerprintOutlined',],
  [FingerprintRounded,'FingerprintRounded',],
  [FingerprintSharp,'FingerprintSharp',],
  [FingerprintTwoTone,'FingerprintTwoTone',],
  [Fireplace,'Fireplace',],
  [FireplaceOutlined,'FireplaceOutlined',],
  [FireplaceRounded,'FireplaceRounded',],
  [FireplaceSharp,'FireplaceSharp',],
  [FireplaceTwoTone,'FireplaceTwoTone',],
  [FirstPage,'FirstPage',],
  [FirstPageOutlined,'FirstPageOutlined',],
  [FirstPageRounded,'FirstPageRounded',],
  [FirstPageSharp,'FirstPageSharp',],
  [FirstPageTwoTone,'FirstPageTwoTone',],
  [FitnessCenter,'FitnessCenter',],
  [FitnessCenterOutlined,'FitnessCenterOutlined',],
  [FitnessCenterRounded,'FitnessCenterRounded',],
  [FitnessCenterSharp,'FitnessCenterSharp',],
  [FitnessCenterTwoTone,'FitnessCenterTwoTone',],
  [Flag,'Flag',],
  [FlagOutlined,'FlagOutlined',],
  [FlagRounded,'FlagRounded',],
  [FlagSharp,'FlagSharp',],
  [FlagTwoTone,'FlagTwoTone',],
  [Flare,'Flare',],
  [FlareOutlined,'FlareOutlined',],
  [FlareRounded,'FlareRounded',],
  [FlareSharp,'FlareSharp',],
  [FlareTwoTone,'FlareTwoTone',],
  [FlashAuto,'FlashAuto',],
  [FlashAutoOutlined,'FlashAutoOutlined',],
  [FlashAutoRounded,'FlashAutoRounded',],
  [FlashAutoSharp,'FlashAutoSharp',],
  [FlashAutoTwoTone,'FlashAutoTwoTone',],
  [FlashOff,'FlashOff',],
  [FlashOffOutlined,'FlashOffOutlined',],
  [FlashOffRounded,'FlashOffRounded',],
  [FlashOffSharp,'FlashOffSharp',],
  [FlashOffTwoTone,'FlashOffTwoTone',],
  [FlashOn,'FlashOn',],
  [FlashOnOutlined,'FlashOnOutlined',],
  [FlashOnRounded,'FlashOnRounded',],
  [FlashOnSharp,'FlashOnSharp',],
  [FlashOnTwoTone,'FlashOnTwoTone',],
  [Flight,'Flight',],
  [FlightLand,'FlightLand',],
  [FlightLandOutlined,'FlightLandOutlined',],
  [FlightLandRounded,'FlightLandRounded',],
  [FlightLandSharp,'FlightLandSharp',],
  [FlightLandTwoTone,'FlightLandTwoTone',],
  [FlightOutlined,'FlightOutlined',],
  [FlightRounded,'FlightRounded',],
  [FlightSharp,'FlightSharp',],
  [FlightTakeoff,'FlightTakeoff',],
  [FlightTakeoffOutlined,'FlightTakeoffOutlined',],
  [FlightTakeoffRounded,'FlightTakeoffRounded',],
  [FlightTakeoffSharp,'FlightTakeoffSharp',],
  [FlightTakeoffTwoTone,'FlightTakeoffTwoTone',],
  [FlightTwoTone,'FlightTwoTone',],
  [Flip,'Flip',],
  [FlipCameraAndroid,'FlipCameraAndroid',],
  [FlipCameraAndroidOutlined,'FlipCameraAndroidOutlined',],
  [FlipCameraAndroidRounded,'FlipCameraAndroidRounded',],
  [FlipCameraAndroidSharp,'FlipCameraAndroidSharp',],
  [FlipCameraAndroidTwoTone,'FlipCameraAndroidTwoTone',],
  [FlipCameraIos,'FlipCameraIos',],
  [FlipCameraIosOutlined,'FlipCameraIosOutlined',],
  [FlipCameraIosRounded,'FlipCameraIosRounded',],
  [FlipCameraIosSharp,'FlipCameraIosSharp',],
  [FlipCameraIosTwoTone,'FlipCameraIosTwoTone',],
  [FlipOutlined,'FlipOutlined',],
  [FlipRounded,'FlipRounded',],
  [FlipSharp,'FlipSharp',],
  [FlipToBack,'FlipToBack',],
  [FlipToBackOutlined,'FlipToBackOutlined',],
  [FlipToBackRounded,'FlipToBackRounded',],
  [FlipToBackSharp,'FlipToBackSharp',],
  [FlipToBackTwoTone,'FlipToBackTwoTone',],
  [FlipToFront,'FlipToFront',],
  [FlipToFrontOutlined,'FlipToFrontOutlined',],
  [FlipToFrontRounded,'FlipToFrontRounded',],
  [FlipToFrontSharp,'FlipToFrontSharp',],
  [FlipToFrontTwoTone,'FlipToFrontTwoTone',],
  [FlipTwoTone,'FlipTwoTone',],
  [Folder,'Folder',],
  [FolderOpen,'FolderOpen',],
  [FolderOpenOutlined,'FolderOpenOutlined',],
  [FolderOpenRounded,'FolderOpenRounded',],
  [FolderOpenSharp,'FolderOpenSharp',],
  [FolderOpenTwoTone,'FolderOpenTwoTone',],
  [FolderOutlined,'FolderOutlined',],
  [FolderRounded,'FolderRounded',],
  [FolderShared,'FolderShared',],
  [FolderSharedOutlined,'FolderSharedOutlined',],
  [FolderSharedRounded,'FolderSharedRounded',],
  [FolderSharedSharp,'FolderSharedSharp',],
  [FolderSharedTwoTone,'FolderSharedTwoTone',],
  [FolderSharp,'FolderSharp',],
  [FolderSpecial,'FolderSpecial',],
  [FolderSpecialOutlined,'FolderSpecialOutlined',],
  [FolderSpecialRounded,'FolderSpecialRounded',],
  [FolderSpecialSharp,'FolderSpecialSharp',],
  [FolderSpecialTwoTone,'FolderSpecialTwoTone',],
  [FolderTwoTone,'FolderTwoTone',],
  [FontDownload,'FontDownload',],
  [FontDownloadOutlined,'FontDownloadOutlined',],
  [FontDownloadRounded,'FontDownloadRounded',],
  [FontDownloadSharp,'FontDownloadSharp',],
  [FontDownloadTwoTone,'FontDownloadTwoTone',],
  [FormatAlignCenter,'FormatAlignCenter',],
  [FormatAlignCenterOutlined,'FormatAlignCenterOutlined',],
  [FormatAlignCenterRounded,'FormatAlignCenterRounded',],
  [FormatAlignCenterSharp,'FormatAlignCenterSharp',],
  [FormatAlignCenterTwoTone,'FormatAlignCenterTwoTone',],
  [FormatAlignJustify,'FormatAlignJustify',],
  [FormatAlignJustifyOutlined,'FormatAlignJustifyOutlined',],
  [FormatAlignJustifyRounded,'FormatAlignJustifyRounded',],
  [FormatAlignJustifySharp,'FormatAlignJustifySharp',],
  [FormatAlignJustifyTwoTone,'FormatAlignJustifyTwoTone',],
  [FormatAlignLeft,'FormatAlignLeft',],
  [FormatAlignLeftOutlined,'FormatAlignLeftOutlined',],
  [FormatAlignLeftRounded,'FormatAlignLeftRounded',],
  [FormatAlignLeftSharp,'FormatAlignLeftSharp',],
  [FormatAlignLeftTwoTone,'FormatAlignLeftTwoTone',],
  [FormatAlignRight,'FormatAlignRight',],
  [FormatAlignRightOutlined,'FormatAlignRightOutlined',],
  [FormatAlignRightRounded,'FormatAlignRightRounded',],
  [FormatAlignRightSharp,'FormatAlignRightSharp',],
  [FormatAlignRightTwoTone,'FormatAlignRightTwoTone',],
  [FormatBold,'FormatBold',],
  [FormatBoldOutlined,'FormatBoldOutlined',],
  [FormatBoldRounded,'FormatBoldRounded',],
  [FormatBoldSharp,'FormatBoldSharp',],
  [FormatBoldTwoTone,'FormatBoldTwoTone',],
  [FormatClear,'FormatClear',],
  [FormatClearOutlined,'FormatClearOutlined',],
  [FormatClearRounded,'FormatClearRounded',],
  [FormatClearSharp,'FormatClearSharp',],
  [FormatClearTwoTone,'FormatClearTwoTone',],
  [FormatColorFill,'FormatColorFill',],
  [FormatColorFillOutlined,'FormatColorFillOutlined',],
  [FormatColorFillRounded,'FormatColorFillRounded',],
  [FormatColorFillSharp,'FormatColorFillSharp',],
  [FormatColorFillTwoTone,'FormatColorFillTwoTone',],
  [FormatColorReset,'FormatColorReset',],
  [FormatColorResetOutlined,'FormatColorResetOutlined',],
  [FormatColorResetRounded,'FormatColorResetRounded',],
  [FormatColorResetSharp,'FormatColorResetSharp',],
  [FormatColorResetTwoTone,'FormatColorResetTwoTone',],
  [FormatColorText,'FormatColorText',],
  [FormatColorTextOutlined,'FormatColorTextOutlined',],
  [FormatColorTextRounded,'FormatColorTextRounded',],
  [FormatColorTextSharp,'FormatColorTextSharp',],
  [FormatColorTextTwoTone,'FormatColorTextTwoTone',],
  [FormatIndentDecrease,'FormatIndentDecrease',],
  [FormatIndentDecreaseOutlined,'FormatIndentDecreaseOutlined',],
  [FormatIndentDecreaseRounded,'FormatIndentDecreaseRounded',],
  [FormatIndentDecreaseSharp,'FormatIndentDecreaseSharp',],
  [FormatIndentDecreaseTwoTone,'FormatIndentDecreaseTwoTone',],
  [FormatIndentIncrease,'FormatIndentIncrease',],
  [FormatIndentIncreaseOutlined,'FormatIndentIncreaseOutlined',],
  [FormatIndentIncreaseRounded,'FormatIndentIncreaseRounded',],
  [FormatIndentIncreaseSharp,'FormatIndentIncreaseSharp',],
  [FormatIndentIncreaseTwoTone,'FormatIndentIncreaseTwoTone',],
  [FormatItalic,'FormatItalic',],
  [FormatItalicOutlined,'FormatItalicOutlined',],
  [FormatItalicRounded,'FormatItalicRounded',],
  [FormatItalicSharp,'FormatItalicSharp',],
  [FormatItalicTwoTone,'FormatItalicTwoTone',],
  [FormatLineSpacing,'FormatLineSpacing',],
  [FormatLineSpacingOutlined,'FormatLineSpacingOutlined',],
  [FormatLineSpacingRounded,'FormatLineSpacingRounded',],
  [FormatLineSpacingSharp,'FormatLineSpacingSharp',],
  [FormatLineSpacingTwoTone,'FormatLineSpacingTwoTone',],
  [FormatListBulleted,'FormatListBulleted',],
  [FormatListBulletedOutlined,'FormatListBulletedOutlined',],
  [FormatListBulletedRounded,'FormatListBulletedRounded',],
  [FormatListBulletedSharp,'FormatListBulletedSharp',],
  [FormatListBulletedTwoTone,'FormatListBulletedTwoTone',],
  [FormatListNumbered,'FormatListNumbered',],
  [FormatListNumberedOutlined,'FormatListNumberedOutlined',],
  [FormatListNumberedRounded,'FormatListNumberedRounded',],
  [FormatListNumberedRtl,'FormatListNumberedRtl',],
  [FormatListNumberedRtlOutlined,'FormatListNumberedRtlOutlined',],
  [FormatListNumberedRtlRounded,'FormatListNumberedRtlRounded',],
  [FormatListNumberedRtlSharp,'FormatListNumberedRtlSharp',],
  [FormatListNumberedRtlTwoTone,'FormatListNumberedRtlTwoTone',],
  [FormatListNumberedSharp,'FormatListNumberedSharp',],
  [FormatListNumberedTwoTone,'FormatListNumberedTwoTone',],
  [FormatPaint,'FormatPaint',],
  [FormatPaintOutlined,'FormatPaintOutlined',],
  [FormatPaintRounded,'FormatPaintRounded',],
  [FormatPaintSharp,'FormatPaintSharp',],
  [FormatPaintTwoTone,'FormatPaintTwoTone',],
  [FormatQuote,'FormatQuote',],
  [FormatQuoteOutlined,'FormatQuoteOutlined',],
  [FormatQuoteRounded,'FormatQuoteRounded',],
  [FormatQuoteSharp,'FormatQuoteSharp',],
  [FormatQuoteTwoTone,'FormatQuoteTwoTone',],
  [FormatShapes,'FormatShapes',],
  [FormatShapesOutlined,'FormatShapesOutlined',],
  [FormatShapesRounded,'FormatShapesRounded',],
  [FormatShapesSharp,'FormatShapesSharp',],
  [FormatShapesTwoTone,'FormatShapesTwoTone',],
  [FormatSize,'FormatSize',],
  [FormatSizeOutlined,'FormatSizeOutlined',],
  [FormatSizeRounded,'FormatSizeRounded',],
  [FormatSizeSharp,'FormatSizeSharp',],
  [FormatSizeTwoTone,'FormatSizeTwoTone',],
  [FormatStrikethrough,'FormatStrikethrough',],
  [FormatStrikethroughOutlined,'FormatStrikethroughOutlined',],
  [FormatStrikethroughRounded,'FormatStrikethroughRounded',],
  [FormatStrikethroughSharp,'FormatStrikethroughSharp',],
  [FormatStrikethroughTwoTone,'FormatStrikethroughTwoTone',],
  [FormatTextdirectionLToR,'FormatTextdirectionLToR',],
  [FormatTextdirectionLToROutlined,'FormatTextdirectionLToROutlined',],
  [FormatTextdirectionLToRRounded,'FormatTextdirectionLToRRounded',],
  [FormatTextdirectionLToRSharp,'FormatTextdirectionLToRSharp',],
  [FormatTextdirectionLToRTwoTone,'FormatTextdirectionLToRTwoTone',],
  [FormatTextdirectionRToL,'FormatTextdirectionRToL',],
  [FormatTextdirectionRToLOutlined,'FormatTextdirectionRToLOutlined',],
  [FormatTextdirectionRToLRounded,'FormatTextdirectionRToLRounded',],
  [FormatTextdirectionRToLSharp,'FormatTextdirectionRToLSharp',],
  [FormatTextdirectionRToLTwoTone,'FormatTextdirectionRToLTwoTone',],
  [FormatUnderlined,'FormatUnderlined',],
  [FormatUnderlinedOutlined,'FormatUnderlinedOutlined',],
  [FormatUnderlinedRounded,'FormatUnderlinedRounded',],
  [FormatUnderlinedSharp,'FormatUnderlinedSharp',],
  [FormatUnderlinedTwoTone,'FormatUnderlinedTwoTone',],
  [Forum,'Forum',],
  [ForumOutlined,'ForumOutlined',],
  [ForumRounded,'ForumRounded',],
  [ForumSharp,'ForumSharp',],
  [ForumTwoTone,'ForumTwoTone',],
  [Forward,'Forward',],
  [Forward10,'Forward10',],
  [Forward10Outlined,'Forward10Outlined',],
  [Forward10Rounded,'Forward10Rounded',],
  [Forward10Sharp,'Forward10Sharp',],
  [Forward10TwoTone,'Forward10TwoTone',],
  [Forward30,'Forward30',],
  [Forward30Outlined,'Forward30Outlined',],
  [Forward30Rounded,'Forward30Rounded',],
  [Forward30Sharp,'Forward30Sharp',],
  [Forward30TwoTone,'Forward30TwoTone',],
  [Forward5,'Forward5',],
  [Forward5Outlined,'Forward5Outlined',],
  [Forward5Rounded,'Forward5Rounded',],
  [Forward5Sharp,'Forward5Sharp',],
  [Forward5TwoTone,'Forward5TwoTone',],
  [ForwardOutlined,'ForwardOutlined',],
  [ForwardRounded,'ForwardRounded',],
  [ForwardSharp,'ForwardSharp',],
  [ForwardTwoTone,'ForwardTwoTone',],
  [FourK,'FourK',],
  [FourKOutlined,'FourKOutlined',],
  [FourKRounded,'FourKRounded',],
  [FourKSharp,'FourKSharp',],
  [FourKTwoTone,'FourKTwoTone',],
  [FreeBreakfast,'FreeBreakfast',],
  [FreeBreakfastOutlined,'FreeBreakfastOutlined',],
  [FreeBreakfastRounded,'FreeBreakfastRounded',],
  [FreeBreakfastSharp,'FreeBreakfastSharp',],
  [FreeBreakfastTwoTone,'FreeBreakfastTwoTone',],
  [Fullscreen,'Fullscreen',],
  [FullscreenExit,'FullscreenExit',],
  [FullscreenExitOutlined,'FullscreenExitOutlined',],
  [FullscreenExitRounded,'FullscreenExitRounded',],
  [FullscreenExitSharp,'FullscreenExitSharp',],
  [FullscreenExitTwoTone,'FullscreenExitTwoTone',],
  [FullscreenOutlined,'FullscreenOutlined',],
  [FullscreenRounded,'FullscreenRounded',],
  [FullscreenSharp,'FullscreenSharp',],
  [FullscreenTwoTone,'FullscreenTwoTone',],
  [Functions,'Functions',],
  [FunctionsOutlined,'FunctionsOutlined',],
  [FunctionsRounded,'FunctionsRounded',],
  [FunctionsSharp,'FunctionsSharp',],
  [FunctionsTwoTone,'FunctionsTwoTone',],
  [Gamepad,'Gamepad',],
  [GamepadOutlined,'GamepadOutlined',],
  [GamepadRounded,'GamepadRounded',],
  [GamepadSharp,'GamepadSharp',],
  [GamepadTwoTone,'GamepadTwoTone',],
  [Games,'Games',],
  [GamesOutlined,'GamesOutlined',],
  [GamesRounded,'GamesRounded',],
  [GamesSharp,'GamesSharp',],
  [GamesTwoTone,'GamesTwoTone',],
  [Gavel,'Gavel',],
  [GavelOutlined,'GavelOutlined',],
  [GavelRounded,'GavelRounded',],
  [GavelSharp,'GavelSharp',],
  [GavelTwoTone,'GavelTwoTone',],
  [Gesture,'Gesture',],
  [GestureOutlined,'GestureOutlined',],
  [GestureRounded,'GestureRounded',],
  [GestureSharp,'GestureSharp',],
  [GestureTwoTone,'GestureTwoTone',],
  [GetApp,'GetApp',],
  [GetAppOutlined,'GetAppOutlined',],
  [GetAppRounded,'GetAppRounded',],
  [GetAppSharp,'GetAppSharp',],
  [GetAppTwoTone,'GetAppTwoTone',],
  [Gif,'Gif',],
  [GifOutlined,'GifOutlined',],
  [GifRounded,'GifRounded',],
  [GifSharp,'GifSharp',],
  [GifTwoTone,'GifTwoTone',],
  [GitHub,'GitHub',],
  [GolfCourse,'GolfCourse',],
  [GolfCourseOutlined,'GolfCourseOutlined',],
  [GolfCourseRounded,'GolfCourseRounded',],
  [GolfCourseSharp,'GolfCourseSharp',],
  [GolfCourseTwoTone,'GolfCourseTwoTone',],
  [GpsFixed,'GpsFixed',],
  [GpsFixedOutlined,'GpsFixedOutlined',],
  [GpsFixedRounded,'GpsFixedRounded',],
  [GpsFixedSharp,'GpsFixedSharp',],
  [GpsFixedTwoTone,'GpsFixedTwoTone',],
  [GpsNotFixed,'GpsNotFixed',],
  [GpsNotFixedOutlined,'GpsNotFixedOutlined',],
  [GpsNotFixedRounded,'GpsNotFixedRounded',],
  [GpsNotFixedSharp,'GpsNotFixedSharp',],
  [GpsNotFixedTwoTone,'GpsNotFixedTwoTone',],
  [GpsOff,'GpsOff',],
  [GpsOffOutlined,'GpsOffOutlined',],
  [GpsOffRounded,'GpsOffRounded',],
  [GpsOffSharp,'GpsOffSharp',],
  [GpsOffTwoTone,'GpsOffTwoTone',],
  [Grade,'Grade',],
  [GradeOutlined,'GradeOutlined',],
  [GradeRounded,'GradeRounded',],
  [GradeSharp,'GradeSharp',],
  [GradeTwoTone,'GradeTwoTone',],
  [Gradient,'Gradient',],
  [GradientOutlined,'GradientOutlined',],
  [GradientRounded,'GradientRounded',],
  [GradientSharp,'GradientSharp',],
  [GradientTwoTone,'GradientTwoTone',],
  [Grain,'Grain',],
  [GrainOutlined,'GrainOutlined',],
  [GrainRounded,'GrainRounded',],
  [GrainSharp,'GrainSharp',],
  [GrainTwoTone,'GrainTwoTone',],
  [GraphicEq,'GraphicEq',],
  [GraphicEqOutlined,'GraphicEqOutlined',],
  [GraphicEqRounded,'GraphicEqRounded',],
  [GraphicEqSharp,'GraphicEqSharp',],
  [GraphicEqTwoTone,'GraphicEqTwoTone',],
  [GridOff,'GridOff',],
  [GridOffOutlined,'GridOffOutlined',],
  [GridOffRounded,'GridOffRounded',],
  [GridOffSharp,'GridOffSharp',],
  [GridOffTwoTone,'GridOffTwoTone',],
  [GridOn,'GridOn',],
  [GridOnOutlined,'GridOnOutlined',],
  [GridOnRounded,'GridOnRounded',],
  [GridOnSharp,'GridOnSharp',],
  [GridOnTwoTone,'GridOnTwoTone',],
  [Group,'Group',],
  [GroupAdd,'GroupAdd',],
  [GroupAddOutlined,'GroupAddOutlined',],
  [GroupAddRounded,'GroupAddRounded',],
  [GroupAddSharp,'GroupAddSharp',],
  [GroupAddTwoTone,'GroupAddTwoTone',],
  [GroupOutlined,'GroupOutlined',],
  [GroupRounded,'GroupRounded',],
  [GroupSharp,'GroupSharp',],
  [GroupTwoTone,'GroupTwoTone',],
  [GroupWork,'GroupWork',],
  [GroupWorkOutlined,'GroupWorkOutlined',],
  [GroupWorkRounded,'GroupWorkRounded',],
  [GroupWorkSharp,'GroupWorkSharp',],
  [GroupWorkTwoTone,'GroupWorkTwoTone',],
  [GTranslate,'GTranslate',],
  [GTranslateOutlined,'GTranslateOutlined',],
  [GTranslateRounded,'GTranslateRounded',],
  [GTranslateSharp,'GTranslateSharp',],
  [GTranslateTwoTone,'GTranslateTwoTone',],
  [Hd,'Hd',],
  [HdOutlined,'HdOutlined',],
  [HdrOff,'HdrOff',],
  [HdrOffOutlined,'HdrOffOutlined',],
  [HdrOffRounded,'HdrOffRounded',],
  [HdrOffSharp,'HdrOffSharp',],
  [HdrOffTwoTone,'HdrOffTwoTone',],
  [HdrOn,'HdrOn',],
  [HdrOnOutlined,'HdrOnOutlined',],
  [HdrOnRounded,'HdrOnRounded',],
  [HdrOnSharp,'HdrOnSharp',],
  [HdrOnTwoTone,'HdrOnTwoTone',],
  [HdRounded,'HdRounded',],
  [HdrStrong,'HdrStrong',],
  [HdrStrongOutlined,'HdrStrongOutlined',],
  [HdrStrongRounded,'HdrStrongRounded',],
  [HdrStrongSharp,'HdrStrongSharp',],
  [HdrStrongTwoTone,'HdrStrongTwoTone',],
  [HdrWeak,'HdrWeak',],
  [HdrWeakOutlined,'HdrWeakOutlined',],
  [HdrWeakRounded,'HdrWeakRounded',],
  [HdrWeakSharp,'HdrWeakSharp',],
  [HdrWeakTwoTone,'HdrWeakTwoTone',],
  [HdSharp,'HdSharp',],
  [HdTwoTone,'HdTwoTone',],
  [Headset,'Headset',],
  [HeadsetMic,'HeadsetMic',],
  [HeadsetMicOutlined,'HeadsetMicOutlined',],
  [HeadsetMicRounded,'HeadsetMicRounded',],
  [HeadsetMicSharp,'HeadsetMicSharp',],
  [HeadsetMicTwoTone,'HeadsetMicTwoTone',],
  [HeadsetOutlined,'HeadsetOutlined',],
  [HeadsetRounded,'HeadsetRounded',],
  [HeadsetSharp,'HeadsetSharp',],
  [HeadsetTwoTone,'HeadsetTwoTone',],
  [Healing,'Healing',],
  [HealingOutlined,'HealingOutlined',],
  [HealingRounded,'HealingRounded',],
  [HealingSharp,'HealingSharp',],
  [HealingTwoTone,'HealingTwoTone',],
  [Hearing,'Hearing',],
  [HearingOutlined,'HearingOutlined',],
  [HearingRounded,'HearingRounded',],
  [HearingSharp,'HearingSharp',],
  [HearingTwoTone,'HearingTwoTone',],
  [Height,'Height',],
  [HeightOutlined,'HeightOutlined',],
  [HeightRounded,'HeightRounded',],
  [HeightSharp,'HeightSharp',],
  [HeightTwoTone,'HeightTwoTone',],
  [Help,'Help',],
  [HelpOutline,'HelpOutline',],
  [HelpOutlined,'HelpOutlined',],
  [HelpOutlineOutlined,'HelpOutlineOutlined',],
  [HelpOutlineRounded,'HelpOutlineRounded',],
  [HelpOutlineSharp,'HelpOutlineSharp',],
  [HelpOutlineTwoTone,'HelpOutlineTwoTone',],
  [HelpRounded,'HelpRounded',],
  [HelpSharp,'HelpSharp',],
  [HelpTwoTone,'HelpTwoTone',],
  [Highlight,'Highlight',],
  [HighlightOff,'HighlightOff',],
  [HighlightOffOutlined,'HighlightOffOutlined',],
  [HighlightOffRounded,'HighlightOffRounded',],
  [HighlightOffSharp,'HighlightOffSharp',],
  [HighlightOffTwoTone,'HighlightOffTwoTone',],
  [HighlightOutlined,'HighlightOutlined',],
  [HighlightRounded,'HighlightRounded',],
  [HighlightSharp,'HighlightSharp',],
  [HighlightTwoTone,'HighlightTwoTone',],
  [HighQuality,'HighQuality',],
  [HighQualityOutlined,'HighQualityOutlined',],
  [HighQualityRounded,'HighQualityRounded',],
  [HighQualitySharp,'HighQualitySharp',],
  [HighQualityTwoTone,'HighQualityTwoTone',],
  [History,'History',],
  [HistoryOutlined,'HistoryOutlined',],
  [HistoryRounded,'HistoryRounded',],
  [HistorySharp,'HistorySharp',],
  [HistoryTwoTone,'HistoryTwoTone',],
  [Home,'Home',],
  [HomeOutlined,'HomeOutlined',],
  [HomeRounded,'HomeRounded',],
  [HomeSharp,'HomeSharp',],
  [HomeTwoTone,'HomeTwoTone',],
  [HomeWork,'HomeWork',],
  [HomeWorkOutlined,'HomeWorkOutlined',],
  [HomeWorkRounded,'HomeWorkRounded',],
  [HomeWorkSharp,'HomeWorkSharp',],
  [HomeWorkTwoTone,'HomeWorkTwoTone',],
  [HorizontalSplit,'HorizontalSplit',],
  [HorizontalSplitOutlined,'HorizontalSplitOutlined',],
  [HorizontalSplitRounded,'HorizontalSplitRounded',],
  [HorizontalSplitSharp,'HorizontalSplitSharp',],
  [HorizontalSplitTwoTone,'HorizontalSplitTwoTone',],
  [Hotel,'Hotel',],
  [HotelOutlined,'HotelOutlined',],
  [HotelRounded,'HotelRounded',],
  [HotelSharp,'HotelSharp',],
  [HotelTwoTone,'HotelTwoTone',],
  [HotTub,'HotTub',],
  [HotTubOutlined,'HotTubOutlined',],
  [HotTubRounded,'HotTubRounded',],
  [HotTubSharp,'HotTubSharp',],
  [HotTubTwoTone,'HotTubTwoTone',],
  [HourglassEmpty,'HourglassEmpty',],
  [HourglassEmptyOutlined,'HourglassEmptyOutlined',],
  [HourglassEmptyRounded,'HourglassEmptyRounded',],
  [HourglassEmptySharp,'HourglassEmptySharp',],
  [HourglassEmptyTwoTone,'HourglassEmptyTwoTone',],
  [HourglassFull,'HourglassFull',],
  [HourglassFullOutlined,'HourglassFullOutlined',],
  [HourglassFullRounded,'HourglassFullRounded',],
  [HourglassFullSharp,'HourglassFullSharp',],
  [HourglassFullTwoTone,'HourglassFullTwoTone',],
  [House,'House',],
  [HouseOutlined,'HouseOutlined',],
  [HouseRounded,'HouseRounded',],
  [HouseSharp,'HouseSharp',],
  [HouseTwoTone,'HouseTwoTone',],
  [HowToReg,'HowToReg',],
  [HowToRegOutlined,'HowToRegOutlined',],
  [HowToRegRounded,'HowToRegRounded',],
  [HowToRegSharp,'HowToRegSharp',],
  [HowToRegTwoTone,'HowToRegTwoTone',],
  [HowToVote,'HowToVote',],
  [HowToVoteOutlined,'HowToVoteOutlined',],
  [HowToVoteRounded,'HowToVoteRounded',],
  [HowToVoteSharp,'HowToVoteSharp',],
  [HowToVoteTwoTone,'HowToVoteTwoTone',],
  [Http,'Http',],
  [HttpOutlined,'HttpOutlined',],
  [HttpRounded,'HttpRounded',],
  [Https,'Https',],
  [HttpSharp,'HttpSharp',],
  [HttpsOutlined,'HttpsOutlined',],
  [HttpsRounded,'HttpsRounded',],
  [HttpsSharp,'HttpsSharp',],
  [HttpsTwoTone,'HttpsTwoTone',],
  [HttpTwoTone,'HttpTwoTone',],
  [Image,'Image',],
  [ImageAspectRatio,'ImageAspectRatio',],
  [ImageAspectRatioOutlined,'ImageAspectRatioOutlined',],
  [ImageAspectRatioRounded,'ImageAspectRatioRounded',],
  [ImageAspectRatioSharp,'ImageAspectRatioSharp',],
  [ImageAspectRatioTwoTone,'ImageAspectRatioTwoTone',],
  [ImageOutlined,'ImageOutlined',],
  [ImageRounded,'ImageRounded',],
  [ImageSearch,'ImageSearch',],
  [ImageSearchOutlined,'ImageSearchOutlined',],
  [ImageSearchRounded,'ImageSearchRounded',],
  [ImageSearchSharp,'ImageSearchSharp',],
  [ImageSearchTwoTone,'ImageSearchTwoTone',],
  [ImageSharp,'ImageSharp',],
  [ImageTwoTone,'ImageTwoTone',],
  [ImportantDevices,'ImportantDevices',],
  [ImportantDevicesOutlined,'ImportantDevicesOutlined',],
  [ImportantDevicesRounded,'ImportantDevicesRounded',],
  [ImportantDevicesSharp,'ImportantDevicesSharp',],
  [ImportantDevicesTwoTone,'ImportantDevicesTwoTone',],
  [ImportContacts,'ImportContacts',],
  [ImportContactsOutlined,'ImportContactsOutlined',],
  [ImportContactsRounded,'ImportContactsRounded',],
  [ImportContactsSharp,'ImportContactsSharp',],
  [ImportContactsTwoTone,'ImportContactsTwoTone',],
  [ImportExport,'ImportExport',],
  [ImportExportOutlined,'ImportExportOutlined',],
  [ImportExportRounded,'ImportExportRounded',],
  [ImportExportSharp,'ImportExportSharp',],
  [ImportExportTwoTone,'ImportExportTwoTone',],
  [Inbox,'Inbox',],
  [InboxOutlined,'InboxOutlined',],
  [InboxRounded,'InboxRounded',],
  [InboxSharp,'InboxSharp',],
  [InboxTwoTone,'InboxTwoTone',],
  [IndeterminateCheckBox,'IndeterminateCheckBox',],
  [IndeterminateCheckBoxOutlined,'IndeterminateCheckBoxOutlined',],
  [IndeterminateCheckBoxRounded,'IndeterminateCheckBoxRounded',],
  [IndeterminateCheckBoxSharp,'IndeterminateCheckBoxSharp',],
  [IndeterminateCheckBoxTwoTone,'IndeterminateCheckBoxTwoTone',],
  [Info,'Info',],
  [InfoOutlined,'InfoOutlined',],
  [InfoRounded,'InfoRounded',],
  [InfoSharp,'InfoSharp',],
  [InfoTwoTone,'InfoTwoTone',],
  [Input,'Input',],
  [InputOutlined,'InputOutlined',],
  [InputRounded,'InputRounded',],
  [InputSharp,'InputSharp',],
  [InputTwoTone,'InputTwoTone',],
  [InsertChart,'InsertChart',],
  [InsertChartOutlined,'InsertChartOutlined',],
  [InsertChartOutlinedOutlined,'InsertChartOutlinedOutlined',],
  [InsertChartOutlinedRounded,'InsertChartOutlinedRounded',],
  [InsertChartOutlinedSharp,'InsertChartOutlinedSharp',],
  [InsertChartOutlinedTwoTone,'InsertChartOutlinedTwoTone',],
  [InsertChartRounded,'InsertChartRounded',],
  [InsertChartSharp,'InsertChartSharp',],
  [InsertChartTwoTone,'InsertChartTwoTone',],
  [InsertComment,'InsertComment',],
  [InsertCommentOutlined,'InsertCommentOutlined',],
  [InsertCommentRounded,'InsertCommentRounded',],
  [InsertCommentSharp,'InsertCommentSharp',],
  [InsertCommentTwoTone,'InsertCommentTwoTone',],
  [InsertDriveFile,'InsertDriveFile',],
  [InsertDriveFileOutlined,'InsertDriveFileOutlined',],
  [InsertDriveFileRounded,'InsertDriveFileRounded',],
  [InsertDriveFileSharp,'InsertDriveFileSharp',],
  [InsertDriveFileTwoTone,'InsertDriveFileTwoTone',],
  [InsertEmoticon,'InsertEmoticon',],
  [InsertEmoticonOutlined,'InsertEmoticonOutlined',],
  [InsertEmoticonRounded,'InsertEmoticonRounded',],
  [InsertEmoticonSharp,'InsertEmoticonSharp',],
  [InsertEmoticonTwoTone,'InsertEmoticonTwoTone',],
  [InsertInvitation,'InsertInvitation',],
  [InsertInvitationOutlined,'InsertInvitationOutlined',],
  [InsertInvitationRounded,'InsertInvitationRounded',],
  [InsertInvitationSharp,'InsertInvitationSharp',],
  [InsertInvitationTwoTone,'InsertInvitationTwoTone',],
  [InsertLink,'InsertLink',],
  [InsertLinkOutlined,'InsertLinkOutlined',],
  [InsertLinkRounded,'InsertLinkRounded',],
  [InsertLinkSharp,'InsertLinkSharp',],
  [InsertLinkTwoTone,'InsertLinkTwoTone',],
  [InsertPhoto,'InsertPhoto',],
  [InsertPhotoOutlined,'InsertPhotoOutlined',],
  [InsertPhotoRounded,'InsertPhotoRounded',],
  [InsertPhotoSharp,'InsertPhotoSharp',],
  [InsertPhotoTwoTone,'InsertPhotoTwoTone',],
  [Instagram,'Instagram',],
  [InvertColors,'InvertColors',],
  [InvertColorsOff,'InvertColorsOff',],
  [InvertColorsOffOutlined,'InvertColorsOffOutlined',],
  [InvertColorsOffRounded,'InvertColorsOffRounded',],
  [InvertColorsOffSharp,'InvertColorsOffSharp',],
  [InvertColorsOffTwoTone,'InvertColorsOffTwoTone',],
  [InvertColorsOutlined,'InvertColorsOutlined',],
  [InvertColorsRounded,'InvertColorsRounded',],
  [InvertColorsSharp,'InvertColorsSharp',],
  [InvertColorsTwoTone,'InvertColorsTwoTone',],
  [Iso,'Iso',],
  [IsoOutlined,'IsoOutlined',],
  [IsoRounded,'IsoRounded',],
  [IsoSharp,'IsoSharp',],
  [IsoTwoTone,'IsoTwoTone',],
  [Keyboard,'Keyboard',],
  [KeyboardArrowDown,'KeyboardArrowDown',],
  [KeyboardArrowDownOutlined,'KeyboardArrowDownOutlined',],
  [KeyboardArrowDownRounded,'KeyboardArrowDownRounded',],
  [KeyboardArrowDownSharp,'KeyboardArrowDownSharp',],
  [KeyboardArrowDownTwoTone,'KeyboardArrowDownTwoTone',],
  [KeyboardArrowLeft,'KeyboardArrowLeft',],
  [KeyboardArrowLeftOutlined,'KeyboardArrowLeftOutlined',],
  [KeyboardArrowLeftRounded,'KeyboardArrowLeftRounded',],
  [KeyboardArrowLeftSharp,'KeyboardArrowLeftSharp',],
  [KeyboardArrowLeftTwoTone,'KeyboardArrowLeftTwoTone',],
  [KeyboardArrowRight,'KeyboardArrowRight',],
  [KeyboardArrowRightOutlined,'KeyboardArrowRightOutlined',],
  [KeyboardArrowRightRounded,'KeyboardArrowRightRounded',],
  [KeyboardArrowRightSharp,'KeyboardArrowRightSharp',],
  [KeyboardArrowRightTwoTone,'KeyboardArrowRightTwoTone',],
  [KeyboardArrowUp,'KeyboardArrowUp',],
  [KeyboardArrowUpOutlined,'KeyboardArrowUpOutlined',],
  [KeyboardArrowUpRounded,'KeyboardArrowUpRounded',],
  [KeyboardArrowUpSharp,'KeyboardArrowUpSharp',],
  [KeyboardArrowUpTwoTone,'KeyboardArrowUpTwoTone',],
  [KeyboardBackspace,'KeyboardBackspace',],
  [KeyboardBackspaceOutlined,'KeyboardBackspaceOutlined',],
  [KeyboardBackspaceRounded,'KeyboardBackspaceRounded',],
  [KeyboardBackspaceSharp,'KeyboardBackspaceSharp',],
  [KeyboardBackspaceTwoTone,'KeyboardBackspaceTwoTone',],
  [KeyboardCapslock,'KeyboardCapslock',],
  [KeyboardCapslockOutlined,'KeyboardCapslockOutlined',],
  [KeyboardCapslockRounded,'KeyboardCapslockRounded',],
  [KeyboardCapslockSharp,'KeyboardCapslockSharp',],
  [KeyboardCapslockTwoTone,'KeyboardCapslockTwoTone',],
  [KeyboardHide,'KeyboardHide',],
  [KeyboardHideOutlined,'KeyboardHideOutlined',],
  [KeyboardHideRounded,'KeyboardHideRounded',],
  [KeyboardHideSharp,'KeyboardHideSharp',],
  [KeyboardHideTwoTone,'KeyboardHideTwoTone',],
  [KeyboardOutlined,'KeyboardOutlined',],
  [KeyboardReturn,'KeyboardReturn',],
  [KeyboardReturnOutlined,'KeyboardReturnOutlined',],
  [KeyboardReturnRounded,'KeyboardReturnRounded',],
  [KeyboardReturnSharp,'KeyboardReturnSharp',],
  [KeyboardReturnTwoTone,'KeyboardReturnTwoTone',],
  [KeyboardRounded,'KeyboardRounded',],
  [KeyboardSharp,'KeyboardSharp',],
  [KeyboardTab,'KeyboardTab',],
  [KeyboardTabOutlined,'KeyboardTabOutlined',],
  [KeyboardTabRounded,'KeyboardTabRounded',],
  [KeyboardTabSharp,'KeyboardTabSharp',],
  [KeyboardTabTwoTone,'KeyboardTabTwoTone',],
  [KeyboardTwoTone,'KeyboardTwoTone',],
  [KeyboardVoice,'KeyboardVoice',],
  [KeyboardVoiceOutlined,'KeyboardVoiceOutlined',],
  [KeyboardVoiceRounded,'KeyboardVoiceRounded',],
  [KeyboardVoiceSharp,'KeyboardVoiceSharp',],
  [KeyboardVoiceTwoTone,'KeyboardVoiceTwoTone',],
  [KingBed,'KingBed',],
  [KingBedOutlined,'KingBedOutlined',],
  [KingBedRounded,'KingBedRounded',],
  [KingBedSharp,'KingBedSharp',],
  [KingBedTwoTone,'KingBedTwoTone',],
  [Kitchen,'Kitchen',],
  [KitchenOutlined,'KitchenOutlined',],
  [KitchenRounded,'KitchenRounded',],
  [KitchenSharp,'KitchenSharp',],
  [KitchenTwoTone,'KitchenTwoTone',],
  [Label,'Label',],
  [LabelImportant,'LabelImportant',],
  [LabelImportantOutlined,'LabelImportantOutlined',],
  [LabelImportantRounded,'LabelImportantRounded',],
  [LabelImportantSharp,'LabelImportantSharp',],
  [LabelImportantTwoTone,'LabelImportantTwoTone',],
  [LabelOff,'LabelOff',],
  [LabelOffOutlined,'LabelOffOutlined',],
  [LabelOffRounded,'LabelOffRounded',],
  [LabelOffSharp,'LabelOffSharp',],
  [LabelOffTwoTone,'LabelOffTwoTone',],
  [LabelOutlined,'LabelOutlined',],
  [LabelRounded,'LabelRounded',],
  [LabelSharp,'LabelSharp',],
  [LabelTwoTone,'LabelTwoTone',],
  [Landscape,'Landscape',],
  [LandscapeOutlined,'LandscapeOutlined',],
  [LandscapeRounded,'LandscapeRounded',],
  [LandscapeSharp,'LandscapeSharp',],
  [LandscapeTwoTone,'LandscapeTwoTone',],
  [Language,'Language',],
  [LanguageOutlined,'LanguageOutlined',],
  [LanguageRounded,'LanguageRounded',],
  [LanguageSharp,'LanguageSharp',],
  [LanguageTwoTone,'LanguageTwoTone',],
  [Laptop,'Laptop',],
  [LaptopChromebook,'LaptopChromebook',],
  [LaptopChromebookOutlined,'LaptopChromebookOutlined',],
  [LaptopChromebookRounded,'LaptopChromebookRounded',],
  [LaptopChromebookSharp,'LaptopChromebookSharp',],
  [LaptopChromebookTwoTone,'LaptopChromebookTwoTone',],
  [LaptopMac,'LaptopMac',],
  [LaptopMacOutlined,'LaptopMacOutlined',],
  [LaptopMacRounded,'LaptopMacRounded',],
  [LaptopMacSharp,'LaptopMacSharp',],
  [LaptopMacTwoTone,'LaptopMacTwoTone',],
  [LaptopOutlined,'LaptopOutlined',],
  [LaptopRounded,'LaptopRounded',],
  [LaptopSharp,'LaptopSharp',],
  [LaptopTwoTone,'LaptopTwoTone',],
  [LaptopWindows,'LaptopWindows',],
  [LaptopWindowsOutlined,'LaptopWindowsOutlined',],
  [LaptopWindowsRounded,'LaptopWindowsRounded',],
  [LaptopWindowsSharp,'LaptopWindowsSharp',],
  [LaptopWindowsTwoTone,'LaptopWindowsTwoTone',],
  [LastPage,'LastPage',],
  [LastPageOutlined,'LastPageOutlined',],
  [LastPageRounded,'LastPageRounded',],
  [LastPageSharp,'LastPageSharp',],
  [LastPageTwoTone,'LastPageTwoTone',],
  [Launch,'Launch',],
  [LaunchOutlined,'LaunchOutlined',],
  [LaunchRounded,'LaunchRounded',],
  [LaunchSharp,'LaunchSharp',],
  [LaunchTwoTone,'LaunchTwoTone',],
  [Layers,'Layers',],
  [LayersClear,'LayersClear',],
  [LayersClearOutlined,'LayersClearOutlined',],
  [LayersClearRounded,'LayersClearRounded',],
  [LayersClearSharp,'LayersClearSharp',],
  [LayersClearTwoTone,'LayersClearTwoTone',],
  [LayersOutlined,'LayersOutlined',],
  [LayersRounded,'LayersRounded',],
  [LayersSharp,'LayersSharp',],
  [LayersTwoTone,'LayersTwoTone',],
  [LeakAdd,'LeakAdd',],
  [LeakAddOutlined,'LeakAddOutlined',],
  [LeakAddRounded,'LeakAddRounded',],
  [LeakAddSharp,'LeakAddSharp',],
  [LeakAddTwoTone,'LeakAddTwoTone',],
  [LeakRemove,'LeakRemove',],
  [LeakRemoveOutlined,'LeakRemoveOutlined',],
  [LeakRemoveRounded,'LeakRemoveRounded',],
  [LeakRemoveSharp,'LeakRemoveSharp',],
  [LeakRemoveTwoTone,'LeakRemoveTwoTone',],
  [Lens,'Lens',],
  [LensOutlined,'LensOutlined',],
  [LensRounded,'LensRounded',],
  [LensSharp,'LensSharp',],
  [LensTwoTone,'LensTwoTone',],
  [LibraryAdd,'LibraryAdd',],
  [LibraryAddCheck,'LibraryAddCheck',],
  [LibraryAddCheckOutlined,'LibraryAddCheckOutlined',],
  [LibraryAddCheckRounded,'LibraryAddCheckRounded',],
  [LibraryAddCheckSharp,'LibraryAddCheckSharp',],
  [LibraryAddCheckTwoTone,'LibraryAddCheckTwoTone',],
  [LibraryAddOutlined,'LibraryAddOutlined',],
  [LibraryAddRounded,'LibraryAddRounded',],
  [LibraryAddSharp,'LibraryAddSharp',],
  [LibraryAddTwoTone,'LibraryAddTwoTone',],
  [LibraryBooks,'LibraryBooks',],
  [LibraryBooksOutlined,'LibraryBooksOutlined',],
  [LibraryBooksRounded,'LibraryBooksRounded',],
  [LibraryBooksSharp,'LibraryBooksSharp',],
  [LibraryBooksTwoTone,'LibraryBooksTwoTone',],
  [LibraryMusic,'LibraryMusic',],
  [LibraryMusicOutlined,'LibraryMusicOutlined',],
  [LibraryMusicRounded,'LibraryMusicRounded',],
  [LibraryMusicSharp,'LibraryMusicSharp',],
  [LibraryMusicTwoTone,'LibraryMusicTwoTone',],
  [LinearScale,'LinearScale',],
  [LinearScaleOutlined,'LinearScaleOutlined',],
  [LinearScaleRounded,'LinearScaleRounded',],
  [LinearScaleSharp,'LinearScaleSharp',],
  [LinearScaleTwoTone,'LinearScaleTwoTone',],
  [LineStyle,'LineStyle',],
  [LineStyleOutlined,'LineStyleOutlined',],
  [LineStyleRounded,'LineStyleRounded',],
  [LineStyleSharp,'LineStyleSharp',],
  [LineStyleTwoTone,'LineStyleTwoTone',],
  [LineWeight,'LineWeight',],
  [LineWeightOutlined,'LineWeightOutlined',],
  [LineWeightRounded,'LineWeightRounded',],
  [LineWeightSharp,'LineWeightSharp',],
  [LineWeightTwoTone,'LineWeightTwoTone',],
  [Link,'Link',],
  [LinkedCamera,'LinkedCamera',],
  [LinkedCameraOutlined,'LinkedCameraOutlined',],
  [LinkedCameraRounded,'LinkedCameraRounded',],
  [LinkedCameraSharp,'LinkedCameraSharp',],
  [LinkedCameraTwoTone,'LinkedCameraTwoTone',],
  [LinkedIn,'LinkedIn',],
  [LinkOff,'LinkOff',],
  [LinkOffOutlined,'LinkOffOutlined',],
  [LinkOffRounded,'LinkOffRounded',],
  [LinkOffSharp,'LinkOffSharp',],
  [LinkOffTwoTone,'LinkOffTwoTone',],
  [LinkOutlined,'LinkOutlined',],
  [LinkRounded,'LinkRounded',],
  [LinkSharp,'LinkSharp',],
  [LinkTwoTone,'LinkTwoTone',],
  [List,'List',],
  [ListAlt,'ListAlt',],
  [ListAltOutlined,'ListAltOutlined',],
  [ListAltRounded,'ListAltRounded',],
  [ListAltSharp,'ListAltSharp',],
  [ListAltTwoTone,'ListAltTwoTone',],
  [ListOutlined,'ListOutlined',],
  [ListRounded,'ListRounded',],
  [ListSharp,'ListSharp',],
  [ListTwoTone,'ListTwoTone',],
  [LiveHelp,'LiveHelp',],
  [LiveHelpOutlined,'LiveHelpOutlined',],
  [LiveHelpRounded,'LiveHelpRounded',],
  [LiveHelpSharp,'LiveHelpSharp',],
  [LiveHelpTwoTone,'LiveHelpTwoTone',],
  [LiveTv,'LiveTv',],
  [LiveTvOutlined,'LiveTvOutlined',],
  [LiveTvRounded,'LiveTvRounded',],
  [LiveTvSharp,'LiveTvSharp',],
  [LiveTvTwoTone,'LiveTvTwoTone',],
  [LocalActivity,'LocalActivity',],
  [LocalActivityOutlined,'LocalActivityOutlined',],
  [LocalActivityRounded,'LocalActivityRounded',],
  [LocalActivitySharp,'LocalActivitySharp',],
  [LocalActivityTwoTone,'LocalActivityTwoTone',],
  [LocalAirport,'LocalAirport',],
  [LocalAirportOutlined,'LocalAirportOutlined',],
  [LocalAirportRounded,'LocalAirportRounded',],
  [LocalAirportSharp,'LocalAirportSharp',],
  [LocalAirportTwoTone,'LocalAirportTwoTone',],
  [LocalAtm,'LocalAtm',],
  [LocalAtmOutlined,'LocalAtmOutlined',],
  [LocalAtmRounded,'LocalAtmRounded',],
  [LocalAtmSharp,'LocalAtmSharp',],
  [LocalAtmTwoTone,'LocalAtmTwoTone',],
  [LocalBar,'LocalBar',],
  [LocalBarOutlined,'LocalBarOutlined',],
  [LocalBarRounded,'LocalBarRounded',],
  [LocalBarSharp,'LocalBarSharp',],
  [LocalBarTwoTone,'LocalBarTwoTone',],
  [LocalCafe,'LocalCafe',],
  [LocalCafeOutlined,'LocalCafeOutlined',],
  [LocalCafeRounded,'LocalCafeRounded',],
  [LocalCafeSharp,'LocalCafeSharp',],
  [LocalCafeTwoTone,'LocalCafeTwoTone',],
  [LocalCarWash,'LocalCarWash',],
  [LocalCarWashOutlined,'LocalCarWashOutlined',],
  [LocalCarWashRounded,'LocalCarWashRounded',],
  [LocalCarWashSharp,'LocalCarWashSharp',],
  [LocalCarWashTwoTone,'LocalCarWashTwoTone',],
  [LocalConvenienceStore,'LocalConvenienceStore',],
  [LocalConvenienceStoreOutlined,'LocalConvenienceStoreOutlined',],
  [LocalConvenienceStoreRounded,'LocalConvenienceStoreRounded',],
  [LocalConvenienceStoreSharp,'LocalConvenienceStoreSharp',],
  [LocalConvenienceStoreTwoTone,'LocalConvenienceStoreTwoTone',],
  [LocalDining,'LocalDining',],
  [LocalDiningOutlined,'LocalDiningOutlined',],
  [LocalDiningRounded,'LocalDiningRounded',],
  [LocalDiningSharp,'LocalDiningSharp',],
  [LocalDiningTwoTone,'LocalDiningTwoTone',],
  [LocalDrink,'LocalDrink',],
  [LocalDrinkOutlined,'LocalDrinkOutlined',],
  [LocalDrinkRounded,'LocalDrinkRounded',],
  [LocalDrinkSharp,'LocalDrinkSharp',],
  [LocalDrinkTwoTone,'LocalDrinkTwoTone',],
  [LocalFlorist,'LocalFlorist',],
  [LocalFloristOutlined,'LocalFloristOutlined',],
  [LocalFloristRounded,'LocalFloristRounded',],
  [LocalFloristSharp,'LocalFloristSharp',],
  [LocalFloristTwoTone,'LocalFloristTwoTone',],
  [LocalGasStation,'LocalGasStation',],
  [LocalGasStationOutlined,'LocalGasStationOutlined',],
  [LocalGasStationRounded,'LocalGasStationRounded',],
  [LocalGasStationSharp,'LocalGasStationSharp',],
  [LocalGasStationTwoTone,'LocalGasStationTwoTone',],
  [LocalGroceryStore,'LocalGroceryStore',],
  [LocalGroceryStoreOutlined,'LocalGroceryStoreOutlined',],
  [LocalGroceryStoreRounded,'LocalGroceryStoreRounded',],
  [LocalGroceryStoreSharp,'LocalGroceryStoreSharp',],
  [LocalGroceryStoreTwoTone,'LocalGroceryStoreTwoTone',],
  [LocalHospital,'LocalHospital',],
  [LocalHospitalOutlined,'LocalHospitalOutlined',],
  [LocalHospitalRounded,'LocalHospitalRounded',],
  [LocalHospitalSharp,'LocalHospitalSharp',],
  [LocalHospitalTwoTone,'LocalHospitalTwoTone',],
  [LocalHotel,'LocalHotel',],
  [LocalHotelOutlined,'LocalHotelOutlined',],
  [LocalHotelRounded,'LocalHotelRounded',],
  [LocalHotelSharp,'LocalHotelSharp',],
  [LocalHotelTwoTone,'LocalHotelTwoTone',],
  [LocalLaundryService,'LocalLaundryService',],
  [LocalLaundryServiceOutlined,'LocalLaundryServiceOutlined',],
  [LocalLaundryServiceRounded,'LocalLaundryServiceRounded',],
  [LocalLaundryServiceSharp,'LocalLaundryServiceSharp',],
  [LocalLaundryServiceTwoTone,'LocalLaundryServiceTwoTone',],
  [LocalLibrary,'LocalLibrary',],
  [LocalLibraryOutlined,'LocalLibraryOutlined',],
  [LocalLibraryRounded,'LocalLibraryRounded',],
  [LocalLibrarySharp,'LocalLibrarySharp',],
  [LocalLibraryTwoTone,'LocalLibraryTwoTone',],
  [LocalMall,'LocalMall',],
  [LocalMallOutlined,'LocalMallOutlined',],
  [LocalMallRounded,'LocalMallRounded',],
  [LocalMallSharp,'LocalMallSharp',],
  [LocalMallTwoTone,'LocalMallTwoTone',],
  [LocalMovies,'LocalMovies',],
  [LocalMoviesOutlined,'LocalMoviesOutlined',],
  [LocalMoviesRounded,'LocalMoviesRounded',],
  [LocalMoviesSharp,'LocalMoviesSharp',],
  [LocalMoviesTwoTone,'LocalMoviesTwoTone',],
  [LocalOffer,'LocalOffer',],
  [LocalOfferOutlined,'LocalOfferOutlined',],
  [LocalOfferRounded,'LocalOfferRounded',],
  [LocalOfferSharp,'LocalOfferSharp',],
  [LocalOfferTwoTone,'LocalOfferTwoTone',],
  [LocalParking,'LocalParking',],
  [LocalParkingOutlined,'LocalParkingOutlined',],
  [LocalParkingRounded,'LocalParkingRounded',],
  [LocalParkingSharp,'LocalParkingSharp',],
  [LocalParkingTwoTone,'LocalParkingTwoTone',],
  [LocalPharmacy,'LocalPharmacy',],
  [LocalPharmacyOutlined,'LocalPharmacyOutlined',],
  [LocalPharmacyRounded,'LocalPharmacyRounded',],
  [LocalPharmacySharp,'LocalPharmacySharp',],
  [LocalPharmacyTwoTone,'LocalPharmacyTwoTone',],
  [LocalPhone,'LocalPhone',],
  [LocalPhoneOutlined,'LocalPhoneOutlined',],
  [LocalPhoneRounded,'LocalPhoneRounded',],
  [LocalPhoneSharp,'LocalPhoneSharp',],
  [LocalPhoneTwoTone,'LocalPhoneTwoTone',],
  [LocalPizza,'LocalPizza',],
  [LocalPizzaOutlined,'LocalPizzaOutlined',],
  [LocalPizzaRounded,'LocalPizzaRounded',],
  [LocalPizzaSharp,'LocalPizzaSharp',],
  [LocalPizzaTwoTone,'LocalPizzaTwoTone',],
  [LocalPlay,'LocalPlay',],
  [LocalPlayOutlined,'LocalPlayOutlined',],
  [LocalPlayRounded,'LocalPlayRounded',],
  [LocalPlaySharp,'LocalPlaySharp',],
  [LocalPlayTwoTone,'LocalPlayTwoTone',],
  [LocalPostOffice,'LocalPostOffice',],
  [LocalPostOfficeOutlined,'LocalPostOfficeOutlined',],
  [LocalPostOfficeRounded,'LocalPostOfficeRounded',],
  [LocalPostOfficeSharp,'LocalPostOfficeSharp',],
  [LocalPostOfficeTwoTone,'LocalPostOfficeTwoTone',],
  [LocalPrintshop,'LocalPrintshop',],
  [LocalPrintshopOutlined,'LocalPrintshopOutlined',],
  [LocalPrintshopRounded,'LocalPrintshopRounded',],
  [LocalPrintshopSharp,'LocalPrintshopSharp',],
  [LocalPrintshopTwoTone,'LocalPrintshopTwoTone',],
  [LocalSee,'LocalSee',],
  [LocalSeeOutlined,'LocalSeeOutlined',],
  [LocalSeeRounded,'LocalSeeRounded',],
  [LocalSeeSharp,'LocalSeeSharp',],
  [LocalSeeTwoTone,'LocalSeeTwoTone',],
  [LocalShipping,'LocalShipping',],
  [LocalShippingOutlined,'LocalShippingOutlined',],
  [LocalShippingRounded,'LocalShippingRounded',],
  [LocalShippingSharp,'LocalShippingSharp',],
  [LocalShippingTwoTone,'LocalShippingTwoTone',],
  [LocalTaxi,'LocalTaxi',],
  [LocalTaxiOutlined,'LocalTaxiOutlined',],
  [LocalTaxiRounded,'LocalTaxiRounded',],
  [LocalTaxiSharp,'LocalTaxiSharp',],
  [LocalTaxiTwoTone,'LocalTaxiTwoTone',],
  [LocationCity,'LocationCity',],
  [LocationCityOutlined,'LocationCityOutlined',],
  [LocationCityRounded,'LocationCityRounded',],
  [LocationCitySharp,'LocationCitySharp',],
  [LocationCityTwoTone,'LocationCityTwoTone',],
  [LocationDisabled,'LocationDisabled',],
  [LocationDisabledOutlined,'LocationDisabledOutlined',],
  [LocationDisabledRounded,'LocationDisabledRounded',],
  [LocationDisabledSharp,'LocationDisabledSharp',],
  [LocationDisabledTwoTone,'LocationDisabledTwoTone',],
  [LocationOff,'LocationOff',],
  [LocationOffOutlined,'LocationOffOutlined',],
  [LocationOffRounded,'LocationOffRounded',],
  [LocationOffSharp,'LocationOffSharp',],
  [LocationOffTwoTone,'LocationOffTwoTone',],
  [LocationOn,'LocationOn',],
  [LocationOnOutlined,'LocationOnOutlined',],
  [LocationOnRounded,'LocationOnRounded',],
  [LocationOnSharp,'LocationOnSharp',],
  [LocationOnTwoTone,'LocationOnTwoTone',],
  [LocationSearching,'LocationSearching',],
  [LocationSearchingOutlined,'LocationSearchingOutlined',],
  [LocationSearchingRounded,'LocationSearchingRounded',],
  [LocationSearchingSharp,'LocationSearchingSharp',],
  [LocationSearchingTwoTone,'LocationSearchingTwoTone',],
  [Lock,'Lock',],
  [LockOpen,'LockOpen',],
  [LockOpenOutlined,'LockOpenOutlined',],
  [LockOpenRounded,'LockOpenRounded',],
  [LockOpenSharp,'LockOpenSharp',],
  [LockOpenTwoTone,'LockOpenTwoTone',],
  [LockOutlined,'LockOutlined',],
  [LockRounded,'LockRounded',],
  [LockSharp,'LockSharp',],
  [LockTwoTone,'LockTwoTone',],
  [Looks,'Looks',],
  [Looks3,'Looks3',],
  [Looks3Outlined,'Looks3Outlined',],
  [Looks3Rounded,'Looks3Rounded',],
  [Looks3Sharp,'Looks3Sharp',],
  [Looks3TwoTone,'Looks3TwoTone',],
  [Looks4,'Looks4',],
  [Looks4Outlined,'Looks4Outlined',],
  [Looks4Rounded,'Looks4Rounded',],
  [Looks4Sharp,'Looks4Sharp',],
  [Looks4TwoTone,'Looks4TwoTone',],
  [Looks5,'Looks5',],
  [Looks5Outlined,'Looks5Outlined',],
  [Looks5Rounded,'Looks5Rounded',],
  [Looks5Sharp,'Looks5Sharp',],
  [Looks5TwoTone,'Looks5TwoTone',],
  [Looks6,'Looks6',],
  [Looks6Outlined,'Looks6Outlined',],
  [Looks6Rounded,'Looks6Rounded',],
  [Looks6Sharp,'Looks6Sharp',],
  [Looks6TwoTone,'Looks6TwoTone',],
  [LooksOne,'LooksOne',],
  [LooksOneOutlined,'LooksOneOutlined',],
  [LooksOneRounded,'LooksOneRounded',],
  [LooksOneSharp,'LooksOneSharp',],
  [LooksOneTwoTone,'LooksOneTwoTone',],
  [LooksOutlined,'LooksOutlined',],
  [LooksRounded,'LooksRounded',],
  [LooksSharp,'LooksSharp',],
  [LooksTwo,'LooksTwo',],
  [LooksTwoOutlined,'LooksTwoOutlined',],
  [LooksTwoRounded,'LooksTwoRounded',],
  [LooksTwoSharp,'LooksTwoSharp',],
  [LooksTwoTone,'LooksTwoTone',],
  [LooksTwoTwoTone,'LooksTwoTwoTone',],
  [Loop,'Loop',],
  [LoopOutlined,'LoopOutlined',],
  [LoopRounded,'LoopRounded',],
  [LoopSharp,'LoopSharp',],
  [LoopTwoTone,'LoopTwoTone',],
  [Loupe,'Loupe',],
  [LoupeOutlined,'LoupeOutlined',],
  [LoupeRounded,'LoupeRounded',],
  [LoupeSharp,'LoupeSharp',],
  [LoupeTwoTone,'LoupeTwoTone',],
  [LowPriority,'LowPriority',],
  [LowPriorityOutlined,'LowPriorityOutlined',],
  [LowPriorityRounded,'LowPriorityRounded',],
  [LowPrioritySharp,'LowPrioritySharp',],
  [LowPriorityTwoTone,'LowPriorityTwoTone',],
  [Loyalty,'Loyalty',],
  [LoyaltyOutlined,'LoyaltyOutlined',],
  [LoyaltyRounded,'LoyaltyRounded',],
  [LoyaltySharp,'LoyaltySharp',],
  [LoyaltyTwoTone,'LoyaltyTwoTone',],
  [Mail,'Mail',],
  [MailOutline,'MailOutline',],
  [MailOutlined,'MailOutlined',],
  [MailOutlineOutlined,'MailOutlineOutlined',],
  [MailOutlineRounded,'MailOutlineRounded',],
  [MailOutlineSharp,'MailOutlineSharp',],
  [MailOutlineTwoTone,'MailOutlineTwoTone',],
  [MailRounded,'MailRounded',],
  [MailSharp,'MailSharp',],
  [MailTwoTone,'MailTwoTone',],
  [Map,'Map',],
  [MapOutlined,'MapOutlined',],
  [MapRounded,'MapRounded',],
  [MapSharp,'MapSharp',],
  [MapTwoTone,'MapTwoTone',],
  [Markunread,'Markunread',],
  [MarkunreadMailbox,'MarkunreadMailbox',],
  [MarkunreadMailboxOutlined,'MarkunreadMailboxOutlined',],
  [MarkunreadMailboxRounded,'MarkunreadMailboxRounded',],
  [MarkunreadMailboxSharp,'MarkunreadMailboxSharp',],
  [MarkunreadMailboxTwoTone,'MarkunreadMailboxTwoTone',],
  [MarkunreadOutlined,'MarkunreadOutlined',],
  [MarkunreadRounded,'MarkunreadRounded',],
  [MarkunreadSharp,'MarkunreadSharp',],
  [MarkunreadTwoTone,'MarkunreadTwoTone',],
  [Maximize,'Maximize',],
  [MaximizeOutlined,'MaximizeOutlined',],
  [MaximizeRounded,'MaximizeRounded',],
  [MaximizeSharp,'MaximizeSharp',],
  [MaximizeTwoTone,'MaximizeTwoTone',],
  [MeetingRoom,'MeetingRoom',],
  [MeetingRoomOutlined,'MeetingRoomOutlined',],
  [MeetingRoomRounded,'MeetingRoomRounded',],
  [MeetingRoomSharp,'MeetingRoomSharp',],
  [MeetingRoomTwoTone,'MeetingRoomTwoTone',],
  [Memory,'Memory',],
  [MemoryOutlined,'MemoryOutlined',],
  [MemoryRounded,'MemoryRounded',],
  [MemorySharp,'MemorySharp',],
  [MemoryTwoTone,'MemoryTwoTone',],
  [Menu,'Menu',],
  [MenuBook,'MenuBook',],
  [MenuBookOutlined,'MenuBookOutlined',],
  [MenuBookRounded,'MenuBookRounded',],
  [MenuBookSharp,'MenuBookSharp',],
  [MenuBookTwoTone,'MenuBookTwoTone',],
  [MenuOpen,'MenuOpen',],
  [MenuOpenOutlined,'MenuOpenOutlined',],
  [MenuOpenRounded,'MenuOpenRounded',],
  [MenuOpenSharp,'MenuOpenSharp',],
  [MenuOpenTwoTone,'MenuOpenTwoTone',],
  [MenuOutlined,'MenuOutlined',],
  [MenuRounded,'MenuRounded',],
  [MenuSharp,'MenuSharp',],
  [MenuTwoTone,'MenuTwoTone',],
  [MergeType,'MergeType',],
  [MergeTypeOutlined,'MergeTypeOutlined',],
  [MergeTypeRounded,'MergeTypeRounded',],
  [MergeTypeSharp,'MergeTypeSharp',],
  [MergeTypeTwoTone,'MergeTypeTwoTone',],
  [Message,'Message',],
  [MessageOutlined,'MessageOutlined',],
  [MessageRounded,'MessageRounded',],
  [MessageSharp,'MessageSharp',],
  [MessageTwoTone,'MessageTwoTone',],
  [Mic,'Mic',],
  [MicNone,'MicNone',],
  [MicNoneOutlined,'MicNoneOutlined',],
  [MicNoneRounded,'MicNoneRounded',],
  [MicNoneSharp,'MicNoneSharp',],
  [MicNoneTwoTone,'MicNoneTwoTone',],
  [MicOff,'MicOff',],
  [MicOffOutlined,'MicOffOutlined',],
  [MicOffRounded,'MicOffRounded',],
  [MicOffSharp,'MicOffSharp',],
  [MicOffTwoTone,'MicOffTwoTone',],
  [MicOutlined,'MicOutlined',],
  [MicRounded,'MicRounded',],
  [MicSharp,'MicSharp',],
  [MicTwoTone,'MicTwoTone',],
  [Minimize,'Minimize',],
  [MinimizeOutlined,'MinimizeOutlined',],
  [MinimizeRounded,'MinimizeRounded',],
  [MinimizeSharp,'MinimizeSharp',],
  [MinimizeTwoTone,'MinimizeTwoTone',],
  [MissedVideoCall,'MissedVideoCall',],
  [MissedVideoCallOutlined,'MissedVideoCallOutlined',],
  [MissedVideoCallRounded,'MissedVideoCallRounded',],
  [MissedVideoCallSharp,'MissedVideoCallSharp',],
  [MissedVideoCallTwoTone,'MissedVideoCallTwoTone',],
  [Mms,'Mms',],
  [MmsOutlined,'MmsOutlined',],
  [MmsRounded,'MmsRounded',],
  [MmsSharp,'MmsSharp',],
  [MmsTwoTone,'MmsTwoTone',],
  [MobileFriendly,'MobileFriendly',],
  [MobileFriendlyOutlined,'MobileFriendlyOutlined',],
  [MobileFriendlyRounded,'MobileFriendlyRounded',],
  [MobileFriendlySharp,'MobileFriendlySharp',],
  [MobileFriendlyTwoTone,'MobileFriendlyTwoTone',],
  [MobileOff,'MobileOff',],
  [MobileOffOutlined,'MobileOffOutlined',],
  [MobileOffRounded,'MobileOffRounded',],
  [MobileOffSharp,'MobileOffSharp',],
  [MobileOffTwoTone,'MobileOffTwoTone',],
  [MobileScreenShare,'MobileScreenShare',],
  [MobileScreenShareOutlined,'MobileScreenShareOutlined',],
  [MobileScreenShareRounded,'MobileScreenShareRounded',],
  [MobileScreenShareSharp,'MobileScreenShareSharp',],
  [MobileScreenShareTwoTone,'MobileScreenShareTwoTone',],
  [ModeComment,'ModeComment',],
  [ModeCommentOutlined,'ModeCommentOutlined',],
  [ModeCommentRounded,'ModeCommentRounded',],
  [ModeCommentSharp,'ModeCommentSharp',],
  [ModeCommentTwoTone,'ModeCommentTwoTone',],
  [MonetizationOn,'MonetizationOn',],
  [MonetizationOnOutlined,'MonetizationOnOutlined',],
  [MonetizationOnRounded,'MonetizationOnRounded',],
  [MonetizationOnSharp,'MonetizationOnSharp',],
  [MonetizationOnTwoTone,'MonetizationOnTwoTone',],
  [Money,'Money',],
  [MoneyOff,'MoneyOff',],
  [MoneyOffOutlined,'MoneyOffOutlined',],
  [MoneyOffRounded,'MoneyOffRounded',],
  [MoneyOffSharp,'MoneyOffSharp',],
  [MoneyOffTwoTone,'MoneyOffTwoTone',],
  [MoneyOutlined,'MoneyOutlined',],
  [MoneyRounded,'MoneyRounded',],
  [MoneySharp,'MoneySharp',],
  [MoneyTwoTone,'MoneyTwoTone',],
  [MonochromePhotos,'MonochromePhotos',],
  [MonochromePhotosOutlined,'MonochromePhotosOutlined',],
  [MonochromePhotosRounded,'MonochromePhotosRounded',],
  [MonochromePhotosSharp,'MonochromePhotosSharp',],
  [MonochromePhotosTwoTone,'MonochromePhotosTwoTone',],
  [Mood,'Mood',],
  [MoodBad,'MoodBad',],
  [MoodBadOutlined,'MoodBadOutlined',],
  [MoodBadRounded,'MoodBadRounded',],
  [MoodBadSharp,'MoodBadSharp',],
  [MoodBadTwoTone,'MoodBadTwoTone',],
  [MoodOutlined,'MoodOutlined',],
  [MoodRounded,'MoodRounded',],
  [MoodSharp,'MoodSharp',],
  [MoodTwoTone,'MoodTwoTone',],
  [More,'More',],
  [MoreHoriz,'MoreHoriz',],
  [MoreHorizOutlined,'MoreHorizOutlined',],
  [MoreHorizRounded,'MoreHorizRounded',],
  [MoreHorizSharp,'MoreHorizSharp',],
  [MoreHorizTwoTone,'MoreHorizTwoTone',],
  [MoreOutlined,'MoreOutlined',],
  [MoreRounded,'MoreRounded',],
  [MoreSharp,'MoreSharp',],
  [MoreTwoTone,'MoreTwoTone',],
  [MoreVert,'MoreVert',],
  [MoreVertOutlined,'MoreVertOutlined',],
  [MoreVertRounded,'MoreVertRounded',],
  [MoreVertSharp,'MoreVertSharp',],
  [MoreVertTwoTone,'MoreVertTwoTone',],
  [Motorcycle,'Motorcycle',],
  [MotorcycleOutlined,'MotorcycleOutlined',],
  [MotorcycleRounded,'MotorcycleRounded',],
  [MotorcycleSharp,'MotorcycleSharp',],
  [MotorcycleTwoTone,'MotorcycleTwoTone',],
  [Mouse,'Mouse',],
  [MouseOutlined,'MouseOutlined',],
  [MouseRounded,'MouseRounded',],
  [MouseSharp,'MouseSharp',],
  [MouseTwoTone,'MouseTwoTone',],
  [MoveToInbox,'MoveToInbox',],
  [MoveToInboxOutlined,'MoveToInboxOutlined',],
  [MoveToInboxRounded,'MoveToInboxRounded',],
  [MoveToInboxSharp,'MoveToInboxSharp',],
  [MoveToInboxTwoTone,'MoveToInboxTwoTone',],
  [Movie,'Movie',],
  [MovieCreation,'MovieCreation',],
  [MovieCreationOutlined,'MovieCreationOutlined',],
  [MovieCreationRounded,'MovieCreationRounded',],
  [MovieCreationSharp,'MovieCreationSharp',],
  [MovieCreationTwoTone,'MovieCreationTwoTone',],
  [MovieFilter,'MovieFilter',],
  [MovieFilterOutlined,'MovieFilterOutlined',],
  [MovieFilterRounded,'MovieFilterRounded',],
  [MovieFilterSharp,'MovieFilterSharp',],
  [MovieFilterTwoTone,'MovieFilterTwoTone',],
  [MovieOutlined,'MovieOutlined',],
  [MovieRounded,'MovieRounded',],
  [MovieSharp,'MovieSharp',],
  [MovieTwoTone,'MovieTwoTone',],
  [MultilineChart,'MultilineChart',],
  [MultilineChartOutlined,'MultilineChartOutlined',],
  [MultilineChartRounded,'MultilineChartRounded',],
  [MultilineChartSharp,'MultilineChartSharp',],
  [MultilineChartTwoTone,'MultilineChartTwoTone',],
  [Museum,'Museum',],
  [MuseumOutlined,'MuseumOutlined',],
  [MuseumRounded,'MuseumRounded',],
  [MuseumSharp,'MuseumSharp',],
  [MuseumTwoTone,'MuseumTwoTone',],
  [MusicNote,'MusicNote',],
  [MusicNoteOutlined,'MusicNoteOutlined',],
  [MusicNoteRounded,'MusicNoteRounded',],
  [MusicNoteSharp,'MusicNoteSharp',],
  [MusicNoteTwoTone,'MusicNoteTwoTone',],
  [MusicOff,'MusicOff',],
  [MusicOffOutlined,'MusicOffOutlined',],
  [MusicOffRounded,'MusicOffRounded',],
  [MusicOffSharp,'MusicOffSharp',],
  [MusicOffTwoTone,'MusicOffTwoTone',],
  [MusicVideo,'MusicVideo',],
  [MusicVideoOutlined,'MusicVideoOutlined',],
  [MusicVideoRounded,'MusicVideoRounded',],
  [MusicVideoSharp,'MusicVideoSharp',],
  [MusicVideoTwoTone,'MusicVideoTwoTone',],
  [MyLocation,'MyLocation',],
  [MyLocationOutlined,'MyLocationOutlined',],
  [MyLocationRounded,'MyLocationRounded',],
  [MyLocationSharp,'MyLocationSharp',],
  [MyLocationTwoTone,'MyLocationTwoTone',],
  [Nature,'Nature',],
  [NatureOutlined,'NatureOutlined',],
  [NaturePeople,'NaturePeople',],
  [NaturePeopleOutlined,'NaturePeopleOutlined',],
  [NaturePeopleRounded,'NaturePeopleRounded',],
  [NaturePeopleSharp,'NaturePeopleSharp',],
  [NaturePeopleTwoTone,'NaturePeopleTwoTone',],
  [NatureRounded,'NatureRounded',],
  [NatureSharp,'NatureSharp',],
  [NatureTwoTone,'NatureTwoTone',],
  [NavigateBefore,'NavigateBefore',],
  [NavigateBeforeOutlined,'NavigateBeforeOutlined',],
  [NavigateBeforeRounded,'NavigateBeforeRounded',],
  [NavigateBeforeSharp,'NavigateBeforeSharp',],
  [NavigateBeforeTwoTone,'NavigateBeforeTwoTone',],
  [NavigateNext,'NavigateNext',],
  [NavigateNextOutlined,'NavigateNextOutlined',],
  [NavigateNextRounded,'NavigateNextRounded',],
  [NavigateNextSharp,'NavigateNextSharp',],
  [NavigateNextTwoTone,'NavigateNextTwoTone',],
  [Navigation,'Navigation',],
  [NavigationOutlined,'NavigationOutlined',],
  [NavigationRounded,'NavigationRounded',],
  [NavigationSharp,'NavigationSharp',],
  [NavigationTwoTone,'NavigationTwoTone',],
  [NearMe,'NearMe',],
  [NearMeOutlined,'NearMeOutlined',],
  [NearMeRounded,'NearMeRounded',],
  [NearMeSharp,'NearMeSharp',],
  [NearMeTwoTone,'NearMeTwoTone',],
  [NetworkCell,'NetworkCell',],
  [NetworkCellOutlined,'NetworkCellOutlined',],
  [NetworkCellRounded,'NetworkCellRounded',],
  [NetworkCellSharp,'NetworkCellSharp',],
  [NetworkCellTwoTone,'NetworkCellTwoTone',],
  [NetworkCheck,'NetworkCheck',],
  [NetworkCheckOutlined,'NetworkCheckOutlined',],
  [NetworkCheckRounded,'NetworkCheckRounded',],
  [NetworkCheckSharp,'NetworkCheckSharp',],
  [NetworkCheckTwoTone,'NetworkCheckTwoTone',],
  [NetworkLocked,'NetworkLocked',],
  [NetworkLockedOutlined,'NetworkLockedOutlined',],
  [NetworkLockedRounded,'NetworkLockedRounded',],
  [NetworkLockedSharp,'NetworkLockedSharp',],
  [NetworkLockedTwoTone,'NetworkLockedTwoTone',],
  [NetworkWifi,'NetworkWifi',],
  [NetworkWifiOutlined,'NetworkWifiOutlined',],
  [NetworkWifiRounded,'NetworkWifiRounded',],
  [NetworkWifiSharp,'NetworkWifiSharp',],
  [NetworkWifiTwoTone,'NetworkWifiTwoTone',],
  [NewReleases,'NewReleases',],
  [NewReleasesOutlined,'NewReleasesOutlined',],
  [NewReleasesRounded,'NewReleasesRounded',],
  [NewReleasesSharp,'NewReleasesSharp',],
  [NewReleasesTwoTone,'NewReleasesTwoTone',],
  [NextWeek,'NextWeek',],
  [NextWeekOutlined,'NextWeekOutlined',],
  [NextWeekRounded,'NextWeekRounded',],
  [NextWeekSharp,'NextWeekSharp',],
  [NextWeekTwoTone,'NextWeekTwoTone',],
  [Nfc,'Nfc',],
  [NfcOutlined,'NfcOutlined',],
  [NfcRounded,'NfcRounded',],
  [NfcSharp,'NfcSharp',],
  [NfcTwoTone,'NfcTwoTone',],
  [NightsStay,'NightsStay',],
  [NightsStayOutlined,'NightsStayOutlined',],
  [NightsStayRounded,'NightsStayRounded',],
  [NightsStaySharp,'NightsStaySharp',],
  [NightsStayTwoTone,'NightsStayTwoTone',],
  [NoEncryption,'NoEncryption',],
  [NoEncryptionOutlined,'NoEncryptionOutlined',],
  [NoEncryptionRounded,'NoEncryptionRounded',],
  [NoEncryptionSharp,'NoEncryptionSharp',],
  [NoEncryptionTwoTone,'NoEncryptionTwoTone',],
  [NoMeetingRoom,'NoMeetingRoom',],
  [NoMeetingRoomOutlined,'NoMeetingRoomOutlined',],
  [NoMeetingRoomRounded,'NoMeetingRoomRounded',],
  [NoMeetingRoomSharp,'NoMeetingRoomSharp',],
  [NoMeetingRoomTwoTone,'NoMeetingRoomTwoTone',],
  [NoSim,'NoSim',],
  [NoSimOutlined,'NoSimOutlined',],
  [NoSimRounded,'NoSimRounded',],
  [NoSimSharp,'NoSimSharp',],
  [NoSimTwoTone,'NoSimTwoTone',],
  [Note,'Note',],
  [NoteAdd,'NoteAdd',],
  [NoteAddOutlined,'NoteAddOutlined',],
  [NoteAddRounded,'NoteAddRounded',],
  [NoteAddSharp,'NoteAddSharp',],
  [NoteAddTwoTone,'NoteAddTwoTone',],
  [NoteOutlined,'NoteOutlined',],
  [NoteRounded,'NoteRounded',],
  [Notes,'Notes',],
  [NoteSharp,'NoteSharp',],
  [NotesOutlined,'NotesOutlined',],
  [NotesRounded,'NotesRounded',],
  [NotesSharp,'NotesSharp',],
  [NotesTwoTone,'NotesTwoTone',],
  [NoteTwoTone,'NoteTwoTone',],
  [NotificationImportant,'NotificationImportant',],
  [NotificationImportantOutlined,'NotificationImportantOutlined',],
  [NotificationImportantRounded,'NotificationImportantRounded',],
  [NotificationImportantSharp,'NotificationImportantSharp',],
  [NotificationImportantTwoTone,'NotificationImportantTwoTone',],
  [Notifications,'Notifications',],
  [NotificationsActive,'NotificationsActive',],
  [NotificationsActiveOutlined,'NotificationsActiveOutlined',],
  [NotificationsActiveRounded,'NotificationsActiveRounded',],
  [NotificationsActiveSharp,'NotificationsActiveSharp',],
  [NotificationsActiveTwoTone,'NotificationsActiveTwoTone',],
  [NotificationsNone,'NotificationsNone',],
  [NotificationsNoneOutlined,'NotificationsNoneOutlined',],
  [NotificationsNoneRounded,'NotificationsNoneRounded',],
  [NotificationsNoneSharp,'NotificationsNoneSharp',],
  [NotificationsNoneTwoTone,'NotificationsNoneTwoTone',],
  [NotificationsOff,'NotificationsOff',],
  [NotificationsOffOutlined,'NotificationsOffOutlined',],
  [NotificationsOffRounded,'NotificationsOffRounded',],
  [NotificationsOffSharp,'NotificationsOffSharp',],
  [NotificationsOffTwoTone,'NotificationsOffTwoTone',],
  [NotificationsOutlined,'NotificationsOutlined',],
  [NotificationsPaused,'NotificationsPaused',],
  [NotificationsPausedOutlined,'NotificationsPausedOutlined',],
  [NotificationsPausedRounded,'NotificationsPausedRounded',],
  [NotificationsPausedSharp,'NotificationsPausedSharp',],
  [NotificationsPausedTwoTone,'NotificationsPausedTwoTone',],
  [NotificationsRounded,'NotificationsRounded',],
  [NotificationsSharp,'NotificationsSharp',],
  [NotificationsTwoTone,'NotificationsTwoTone',],
  [NotInterested,'NotInterested',],
  [NotInterestedOutlined,'NotInterestedOutlined',],
  [NotInterestedRounded,'NotInterestedRounded',],
  [NotInterestedSharp,'NotInterestedSharp',],
  [NotInterestedTwoTone,'NotInterestedTwoTone',],
  [NotListedLocation,'NotListedLocation',],
  [NotListedLocationOutlined,'NotListedLocationOutlined',],
  [NotListedLocationRounded,'NotListedLocationRounded',],
  [NotListedLocationSharp,'NotListedLocationSharp',],
  [NotListedLocationTwoTone,'NotListedLocationTwoTone',],
  [OfflineBolt,'OfflineBolt',],
  [OfflineBoltOutlined,'OfflineBoltOutlined',],
  [OfflineBoltRounded,'OfflineBoltRounded',],
  [OfflineBoltSharp,'OfflineBoltSharp',],
  [OfflineBoltTwoTone,'OfflineBoltTwoTone',],
  [OfflinePin,'OfflinePin',],
  [OfflinePinOutlined,'OfflinePinOutlined',],
  [OfflinePinRounded,'OfflinePinRounded',],
  [OfflinePinSharp,'OfflinePinSharp',],
  [OfflinePinTwoTone,'OfflinePinTwoTone',],
  [OndemandVideo,'OndemandVideo',],
  [OndemandVideoOutlined,'OndemandVideoOutlined',],
  [OndemandVideoRounded,'OndemandVideoRounded',],
  [OndemandVideoSharp,'OndemandVideoSharp',],
  [OndemandVideoTwoTone,'OndemandVideoTwoTone',],
  [Opacity,'Opacity',],
  [OpacityOutlined,'OpacityOutlined',],
  [OpacityRounded,'OpacityRounded',],
  [OpacitySharp,'OpacitySharp',],
  [OpacityTwoTone,'OpacityTwoTone',],
  [OpenInBrowser,'OpenInBrowser',],
  [OpenInBrowserOutlined,'OpenInBrowserOutlined',],
  [OpenInBrowserRounded,'OpenInBrowserRounded',],
  [OpenInBrowserSharp,'OpenInBrowserSharp',],
  [OpenInBrowserTwoTone,'OpenInBrowserTwoTone',],
  [OpenInNew,'OpenInNew',],
  [OpenInNewOutlined,'OpenInNewOutlined',],
  [OpenInNewRounded,'OpenInNewRounded',],
  [OpenInNewSharp,'OpenInNewSharp',],
  [OpenInNewTwoTone,'OpenInNewTwoTone',],
  [OpenWith,'OpenWith',],
  [OpenWithOutlined,'OpenWithOutlined',],
  [OpenWithRounded,'OpenWithRounded',],
  [OpenWithSharp,'OpenWithSharp',],
  [OpenWithTwoTone,'OpenWithTwoTone',],
  [OutdoorGrill,'OutdoorGrill',],
  [OutdoorGrillOutlined,'OutdoorGrillOutlined',],
  [OutdoorGrillRounded,'OutdoorGrillRounded',],
  [OutdoorGrillSharp,'OutdoorGrillSharp',],
  [OutdoorGrillTwoTone,'OutdoorGrillTwoTone',],
  [OutlinedFlag,'OutlinedFlag',],
  [OutlinedFlagOutlined,'OutlinedFlagOutlined',],
  [OutlinedFlagRounded,'OutlinedFlagRounded',],
  [OutlinedFlagSharp,'OutlinedFlagSharp',],
  [OutlinedFlagTwoTone,'OutlinedFlagTwoTone',],
  [Pages,'Pages',],
  [PagesOutlined,'PagesOutlined',],
  [PagesRounded,'PagesRounded',],
  [PagesSharp,'PagesSharp',],
  [PagesTwoTone,'PagesTwoTone',],
  [Pageview,'Pageview',],
  [PageviewOutlined,'PageviewOutlined',],
  [PageviewRounded,'PageviewRounded',],
  [PageviewSharp,'PageviewSharp',],
  [PageviewTwoTone,'PageviewTwoTone',],
  [Palette,'Palette',],
  [PaletteOutlined,'PaletteOutlined',],
  [PaletteRounded,'PaletteRounded',],
  [PaletteSharp,'PaletteSharp',],
  [PaletteTwoTone,'PaletteTwoTone',],
  [Panorama,'Panorama',],
  [PanoramaFishEye,'PanoramaFishEye',],
  [PanoramaFishEyeOutlined,'PanoramaFishEyeOutlined',],
  [PanoramaFishEyeRounded,'PanoramaFishEyeRounded',],
  [PanoramaFishEyeSharp,'PanoramaFishEyeSharp',],
  [PanoramaFishEyeTwoTone,'PanoramaFishEyeTwoTone',],
  [PanoramaHorizontal,'PanoramaHorizontal',],
  [PanoramaHorizontalOutlined,'PanoramaHorizontalOutlined',],
  [PanoramaHorizontalRounded,'PanoramaHorizontalRounded',],
  [PanoramaHorizontalSharp,'PanoramaHorizontalSharp',],
  [PanoramaHorizontalTwoTone,'PanoramaHorizontalTwoTone',],
  [PanoramaOutlined,'PanoramaOutlined',],
  [PanoramaRounded,'PanoramaRounded',],
  [PanoramaSharp,'PanoramaSharp',],
  [PanoramaTwoTone,'PanoramaTwoTone',],
  [PanoramaVertical,'PanoramaVertical',],
  [PanoramaVerticalOutlined,'PanoramaVerticalOutlined',],
  [PanoramaVerticalRounded,'PanoramaVerticalRounded',],
  [PanoramaVerticalSharp,'PanoramaVerticalSharp',],
  [PanoramaVerticalTwoTone,'PanoramaVerticalTwoTone',],
  [PanoramaWideAngle,'PanoramaWideAngle',],
  [PanoramaWideAngleOutlined,'PanoramaWideAngleOutlined',],
  [PanoramaWideAngleRounded,'PanoramaWideAngleRounded',],
  [PanoramaWideAngleSharp,'PanoramaWideAngleSharp',],
  [PanoramaWideAngleTwoTone,'PanoramaWideAngleTwoTone',],
  [PanTool,'PanTool',],
  [PanToolOutlined,'PanToolOutlined',],
  [PanToolRounded,'PanToolRounded',],
  [PanToolSharp,'PanToolSharp',],
  [PanToolTwoTone,'PanToolTwoTone',],
  [PartyMode,'PartyMode',],
  [PartyModeOutlined,'PartyModeOutlined',],
  [PartyModeRounded,'PartyModeRounded',],
  [PartyModeSharp,'PartyModeSharp',],
  [PartyModeTwoTone,'PartyModeTwoTone',],
  [Pause,'Pause',],
  [PauseCircleFilled,'PauseCircleFilled',],
  [PauseCircleFilledOutlined,'PauseCircleFilledOutlined',],
  [PauseCircleFilledRounded,'PauseCircleFilledRounded',],
  [PauseCircleFilledSharp,'PauseCircleFilledSharp',],
  [PauseCircleFilledTwoTone,'PauseCircleFilledTwoTone',],
  [PauseCircleOutline,'PauseCircleOutline',],
  [PauseCircleOutlineOutlined,'PauseCircleOutlineOutlined',],
  [PauseCircleOutlineRounded,'PauseCircleOutlineRounded',],
  [PauseCircleOutlineSharp,'PauseCircleOutlineSharp',],
  [PauseCircleOutlineTwoTone,'PauseCircleOutlineTwoTone',],
  [PauseOutlined,'PauseOutlined',],
  [PausePresentation,'PausePresentation',],
  [PausePresentationOutlined,'PausePresentationOutlined',],
  [PausePresentationRounded,'PausePresentationRounded',],
  [PausePresentationSharp,'PausePresentationSharp',],
  [PausePresentationTwoTone,'PausePresentationTwoTone',],
  [PauseRounded,'PauseRounded',],
  [PauseSharp,'PauseSharp',],
  [PauseTwoTone,'PauseTwoTone',],
  [Payment,'Payment',],
  [PaymentOutlined,'PaymentOutlined',],
  [PaymentRounded,'PaymentRounded',],
  [PaymentSharp,'PaymentSharp',],
  [PaymentTwoTone,'PaymentTwoTone',],
  [People,'People',],
  [PeopleAlt,'PeopleAlt',],
  [PeopleAltOutlined,'PeopleAltOutlined',],
  [PeopleAltRounded,'PeopleAltRounded',],
  [PeopleAltSharp,'PeopleAltSharp',],
  [PeopleAltTwoTone,'PeopleAltTwoTone',],
  [PeopleOutline,'PeopleOutline',],
  [PeopleOutlined,'PeopleOutlined',],
  [PeopleOutlineOutlined,'PeopleOutlineOutlined',],
  [PeopleOutlineRounded,'PeopleOutlineRounded',],
  [PeopleOutlineSharp,'PeopleOutlineSharp',],
  [PeopleOutlineTwoTone,'PeopleOutlineTwoTone',],
  [PeopleRounded,'PeopleRounded',],
  [PeopleSharp,'PeopleSharp',],
  [PeopleTwoTone,'PeopleTwoTone',],
  [PermCameraMic,'PermCameraMic',],
  [PermCameraMicOutlined,'PermCameraMicOutlined',],
  [PermCameraMicRounded,'PermCameraMicRounded',],
  [PermCameraMicSharp,'PermCameraMicSharp',],
  [PermCameraMicTwoTone,'PermCameraMicTwoTone',],
  [PermContactCalendar,'PermContactCalendar',],
  [PermContactCalendarOutlined,'PermContactCalendarOutlined',],
  [PermContactCalendarRounded,'PermContactCalendarRounded',],
  [PermContactCalendarSharp,'PermContactCalendarSharp',],
  [PermContactCalendarTwoTone,'PermContactCalendarTwoTone',],
  [PermDataSetting,'PermDataSetting',],
  [PermDataSettingOutlined,'PermDataSettingOutlined',],
  [PermDataSettingRounded,'PermDataSettingRounded',],
  [PermDataSettingSharp,'PermDataSettingSharp',],
  [PermDataSettingTwoTone,'PermDataSettingTwoTone',],
  [PermDeviceInformation,'PermDeviceInformation',],
  [PermDeviceInformationOutlined,'PermDeviceInformationOutlined',],
  [PermDeviceInformationRounded,'PermDeviceInformationRounded',],
  [PermDeviceInformationSharp,'PermDeviceInformationSharp',],
  [PermDeviceInformationTwoTone,'PermDeviceInformationTwoTone',],
  [PermIdentity,'PermIdentity',],
  [PermIdentityOutlined,'PermIdentityOutlined',],
  [PermIdentityRounded,'PermIdentityRounded',],
  [PermIdentitySharp,'PermIdentitySharp',],
  [PermIdentityTwoTone,'PermIdentityTwoTone',],
  [PermMedia,'PermMedia',],
  [PermMediaOutlined,'PermMediaOutlined',],
  [PermMediaRounded,'PermMediaRounded',],
  [PermMediaSharp,'PermMediaSharp',],
  [PermMediaTwoTone,'PermMediaTwoTone',],
  [PermPhoneMsg,'PermPhoneMsg',],
  [PermPhoneMsgOutlined,'PermPhoneMsgOutlined',],
  [PermPhoneMsgRounded,'PermPhoneMsgRounded',],
  [PermPhoneMsgSharp,'PermPhoneMsgSharp',],
  [PermPhoneMsgTwoTone,'PermPhoneMsgTwoTone',],
  [PermScanWifi,'PermScanWifi',],
  [PermScanWifiOutlined,'PermScanWifiOutlined',],
  [PermScanWifiRounded,'PermScanWifiRounded',],
  [PermScanWifiSharp,'PermScanWifiSharp',],
  [PermScanWifiTwoTone,'PermScanWifiTwoTone',],
  [Person,'Person',],
  [PersonAdd,'PersonAdd',],
  [PersonAddDisabled,'PersonAddDisabled',],
  [PersonAddDisabledOutlined,'PersonAddDisabledOutlined',],
  [PersonAddDisabledRounded,'PersonAddDisabledRounded',],
  [PersonAddDisabledSharp,'PersonAddDisabledSharp',],
  [PersonAddDisabledTwoTone,'PersonAddDisabledTwoTone',],
  [PersonAddOutlined,'PersonAddOutlined',],
  [PersonAddRounded,'PersonAddRounded',],
  [PersonAddSharp,'PersonAddSharp',],
  [PersonAddTwoTone,'PersonAddTwoTone',],
  [PersonalVideo,'PersonalVideo',],
  [PersonalVideoOutlined,'PersonalVideoOutlined',],
  [PersonalVideoRounded,'PersonalVideoRounded',],
  [PersonalVideoSharp,'PersonalVideoSharp',],
  [PersonalVideoTwoTone,'PersonalVideoTwoTone',],
  [PersonOutline,'PersonOutline',],
  [PersonOutlined,'PersonOutlined',],
  [PersonOutlineOutlined,'PersonOutlineOutlined',],
  [PersonOutlineRounded,'PersonOutlineRounded',],
  [PersonOutlineSharp,'PersonOutlineSharp',],
  [PersonOutlineTwoTone,'PersonOutlineTwoTone',],
  [PersonPin,'PersonPin',],
  [PersonPinCircle,'PersonPinCircle',],
  [PersonPinCircleOutlined,'PersonPinCircleOutlined',],
  [PersonPinCircleRounded,'PersonPinCircleRounded',],
  [PersonPinCircleSharp,'PersonPinCircleSharp',],
  [PersonPinCircleTwoTone,'PersonPinCircleTwoTone',],
  [PersonPinOutlined,'PersonPinOutlined',],
  [PersonPinRounded,'PersonPinRounded',],
  [PersonPinSharp,'PersonPinSharp',],
  [PersonPinTwoTone,'PersonPinTwoTone',],
  [PersonRounded,'PersonRounded',],
  [PersonSharp,'PersonSharp',],
  [PersonTwoTone,'PersonTwoTone',],
  [Pets,'Pets',],
  [PetsOutlined,'PetsOutlined',],
  [PetsRounded,'PetsRounded',],
  [PetsSharp,'PetsSharp',],
  [PetsTwoTone,'PetsTwoTone',],
  [Phone,'Phone',],
  [PhoneAndroid,'PhoneAndroid',],
  [PhoneAndroidOutlined,'PhoneAndroidOutlined',],
  [PhoneAndroidRounded,'PhoneAndroidRounded',],
  [PhoneAndroidSharp,'PhoneAndroidSharp',],
  [PhoneAndroidTwoTone,'PhoneAndroidTwoTone',],
  [PhoneBluetoothSpeaker,'PhoneBluetoothSpeaker',],
  [PhoneBluetoothSpeakerOutlined,'PhoneBluetoothSpeakerOutlined',],
  [PhoneBluetoothSpeakerRounded,'PhoneBluetoothSpeakerRounded',],
  [PhoneBluetoothSpeakerSharp,'PhoneBluetoothSpeakerSharp',],
  [PhoneBluetoothSpeakerTwoTone,'PhoneBluetoothSpeakerTwoTone',],
  [PhoneCallback,'PhoneCallback',],
  [PhoneCallbackOutlined,'PhoneCallbackOutlined',],
  [PhoneCallbackRounded,'PhoneCallbackRounded',],
  [PhoneCallbackSharp,'PhoneCallbackSharp',],
  [PhoneCallbackTwoTone,'PhoneCallbackTwoTone',],
  [PhoneDisabled,'PhoneDisabled',],
  [PhoneDisabledOutlined,'PhoneDisabledOutlined',],
  [PhoneDisabledRounded,'PhoneDisabledRounded',],
  [PhoneDisabledSharp,'PhoneDisabledSharp',],
  [PhoneDisabledTwoTone,'PhoneDisabledTwoTone',],
  [PhoneEnabled,'PhoneEnabled',],
  [PhoneEnabledOutlined,'PhoneEnabledOutlined',],
  [PhoneEnabledRounded,'PhoneEnabledRounded',],
  [PhoneEnabledSharp,'PhoneEnabledSharp',],
  [PhoneEnabledTwoTone,'PhoneEnabledTwoTone',],
  [PhoneForwarded,'PhoneForwarded',],
  [PhoneForwardedOutlined,'PhoneForwardedOutlined',],
  [PhoneForwardedRounded,'PhoneForwardedRounded',],
  [PhoneForwardedSharp,'PhoneForwardedSharp',],
  [PhoneForwardedTwoTone,'PhoneForwardedTwoTone',],
  [PhoneInTalk,'PhoneInTalk',],
  [PhoneInTalkOutlined,'PhoneInTalkOutlined',],
  [PhoneInTalkRounded,'PhoneInTalkRounded',],
  [PhoneInTalkSharp,'PhoneInTalkSharp',],
  [PhoneInTalkTwoTone,'PhoneInTalkTwoTone',],
  [PhoneIphone,'PhoneIphone',],
  [PhoneIphoneOutlined,'PhoneIphoneOutlined',],
  [PhoneIphoneRounded,'PhoneIphoneRounded',],
  [PhoneIphoneSharp,'PhoneIphoneSharp',],
  [PhoneIphoneTwoTone,'PhoneIphoneTwoTone',],
  [Phonelink,'Phonelink',],
  [PhonelinkErase,'PhonelinkErase',],
  [PhonelinkEraseOutlined,'PhonelinkEraseOutlined',],
  [PhonelinkEraseRounded,'PhonelinkEraseRounded',],
  [PhonelinkEraseSharp,'PhonelinkEraseSharp',],
  [PhonelinkEraseTwoTone,'PhonelinkEraseTwoTone',],
  [PhonelinkLock,'PhonelinkLock',],
  [PhonelinkLockOutlined,'PhonelinkLockOutlined',],
  [PhonelinkLockRounded,'PhonelinkLockRounded',],
  [PhonelinkLockSharp,'PhonelinkLockSharp',],
  [PhonelinkLockTwoTone,'PhonelinkLockTwoTone',],
  [PhonelinkOff,'PhonelinkOff',],
  [PhonelinkOffOutlined,'PhonelinkOffOutlined',],
  [PhonelinkOffRounded,'PhonelinkOffRounded',],
  [PhonelinkOffSharp,'PhonelinkOffSharp',],
  [PhonelinkOffTwoTone,'PhonelinkOffTwoTone',],
  [PhonelinkOutlined,'PhonelinkOutlined',],
  [PhonelinkRing,'PhonelinkRing',],
  [PhonelinkRingOutlined,'PhonelinkRingOutlined',],
  [PhonelinkRingRounded,'PhonelinkRingRounded',],
  [PhonelinkRingSharp,'PhonelinkRingSharp',],
  [PhonelinkRingTwoTone,'PhonelinkRingTwoTone',],
  [PhonelinkRounded,'PhonelinkRounded',],
  [PhonelinkSetup,'PhonelinkSetup',],
  [PhonelinkSetupOutlined,'PhonelinkSetupOutlined',],
  [PhonelinkSetupRounded,'PhonelinkSetupRounded',],
  [PhonelinkSetupSharp,'PhonelinkSetupSharp',],
  [PhonelinkSetupTwoTone,'PhonelinkSetupTwoTone',],
  [PhonelinkSharp,'PhonelinkSharp',],
  [PhonelinkTwoTone,'PhonelinkTwoTone',],
  [PhoneLocked,'PhoneLocked',],
  [PhoneLockedOutlined,'PhoneLockedOutlined',],
  [PhoneLockedRounded,'PhoneLockedRounded',],
  [PhoneLockedSharp,'PhoneLockedSharp',],
  [PhoneLockedTwoTone,'PhoneLockedTwoTone',],
  [PhoneMissed,'PhoneMissed',],
  [PhoneMissedOutlined,'PhoneMissedOutlined',],
  [PhoneMissedRounded,'PhoneMissedRounded',],
  [PhoneMissedSharp,'PhoneMissedSharp',],
  [PhoneMissedTwoTone,'PhoneMissedTwoTone',],
  [PhoneOutlined,'PhoneOutlined',],
  [PhonePaused,'PhonePaused',],
  [PhonePausedOutlined,'PhonePausedOutlined',],
  [PhonePausedRounded,'PhonePausedRounded',],
  [PhonePausedSharp,'PhonePausedSharp',],
  [PhonePausedTwoTone,'PhonePausedTwoTone',],
  [PhoneRounded,'PhoneRounded',],
  [PhoneSharp,'PhoneSharp',],
  [PhoneTwoTone,'PhoneTwoTone',],
  [Photo,'Photo',],
  [PhotoAlbum,'PhotoAlbum',],
  [PhotoAlbumOutlined,'PhotoAlbumOutlined',],
  [PhotoAlbumRounded,'PhotoAlbumRounded',],
  [PhotoAlbumSharp,'PhotoAlbumSharp',],
  [PhotoAlbumTwoTone,'PhotoAlbumTwoTone',],
  [PhotoCamera,'PhotoCamera',],
  [PhotoCameraOutlined,'PhotoCameraOutlined',],
  [PhotoCameraRounded,'PhotoCameraRounded',],
  [PhotoCameraSharp,'PhotoCameraSharp',],
  [PhotoCameraTwoTone,'PhotoCameraTwoTone',],
  [PhotoFilter,'PhotoFilter',],
  [PhotoFilterOutlined,'PhotoFilterOutlined',],
  [PhotoFilterRounded,'PhotoFilterRounded',],
  [PhotoFilterSharp,'PhotoFilterSharp',],
  [PhotoFilterTwoTone,'PhotoFilterTwoTone',],
  [PhotoLibrary,'PhotoLibrary',],
  [PhotoLibraryOutlined,'PhotoLibraryOutlined',],
  [PhotoLibraryRounded,'PhotoLibraryRounded',],
  [PhotoLibrarySharp,'PhotoLibrarySharp',],
  [PhotoLibraryTwoTone,'PhotoLibraryTwoTone',],
  [PhotoOutlined,'PhotoOutlined',],
  [PhotoRounded,'PhotoRounded',],
  [PhotoSharp,'PhotoSharp',],
  [PhotoSizeSelectActual,'PhotoSizeSelectActual',],
  [PhotoSizeSelectActualOutlined,'PhotoSizeSelectActualOutlined',],
  [PhotoSizeSelectActualRounded,'PhotoSizeSelectActualRounded',],
  [PhotoSizeSelectActualSharp,'PhotoSizeSelectActualSharp',],
  [PhotoSizeSelectActualTwoTone,'PhotoSizeSelectActualTwoTone',],
  [PhotoSizeSelectLarge,'PhotoSizeSelectLarge',],
  [PhotoSizeSelectLargeOutlined,'PhotoSizeSelectLargeOutlined',],
  [PhotoSizeSelectLargeRounded,'PhotoSizeSelectLargeRounded',],
  [PhotoSizeSelectLargeSharp,'PhotoSizeSelectLargeSharp',],
  [PhotoSizeSelectLargeTwoTone,'PhotoSizeSelectLargeTwoTone',],
  [PhotoSizeSelectSmall,'PhotoSizeSelectSmall',],
  [PhotoSizeSelectSmallOutlined,'PhotoSizeSelectSmallOutlined',],
  [PhotoSizeSelectSmallRounded,'PhotoSizeSelectSmallRounded',],
  [PhotoSizeSelectSmallSharp,'PhotoSizeSelectSmallSharp',],
  [PhotoSizeSelectSmallTwoTone,'PhotoSizeSelectSmallTwoTone',],
  [PhotoTwoTone,'PhotoTwoTone',],
  [PictureAsPdf,'PictureAsPdf',],
  [PictureAsPdfOutlined,'PictureAsPdfOutlined',],
  [PictureAsPdfRounded,'PictureAsPdfRounded',],
  [PictureAsPdfSharp,'PictureAsPdfSharp',],
  [PictureAsPdfTwoTone,'PictureAsPdfTwoTone',],
  [PictureInPicture,'PictureInPicture',],
  [PictureInPictureAlt,'PictureInPictureAlt',],
  [PictureInPictureAltOutlined,'PictureInPictureAltOutlined',],
  [PictureInPictureAltRounded,'PictureInPictureAltRounded',],
  [PictureInPictureAltSharp,'PictureInPictureAltSharp',],
  [PictureInPictureAltTwoTone,'PictureInPictureAltTwoTone',],
  [PictureInPictureOutlined,'PictureInPictureOutlined',],
  [PictureInPictureRounded,'PictureInPictureRounded',],
  [PictureInPictureSharp,'PictureInPictureSharp',],
  [PictureInPictureTwoTone,'PictureInPictureTwoTone',],
  [PieChart,'PieChart',],
  [PieChartOutlined,'PieChartOutlined',],
  [PieChartRounded,'PieChartRounded',],
  [PieChartSharp,'PieChartSharp',],
  [PieChartTwoTone,'PieChartTwoTone',],
  [PinDrop,'PinDrop',],
  [PinDropOutlined,'PinDropOutlined',],
  [PinDropRounded,'PinDropRounded',],
  [PinDropSharp,'PinDropSharp',],
  [PinDropTwoTone,'PinDropTwoTone',],
  [Pinterest,'Pinterest',],
  [Place,'Place',],
  [PlaceOutlined,'PlaceOutlined',],
  [PlaceRounded,'PlaceRounded',],
  [PlaceSharp,'PlaceSharp',],
  [PlaceTwoTone,'PlaceTwoTone',],
  [PlayArrow,'PlayArrow',],
  [PlayArrowOutlined,'PlayArrowOutlined',],
  [PlayArrowRounded,'PlayArrowRounded',],
  [PlayArrowSharp,'PlayArrowSharp',],
  [PlayArrowTwoTone,'PlayArrowTwoTone',],
  [PlayCircleFilled,'PlayCircleFilled',],
  [PlayCircleFilledOutlined,'PlayCircleFilledOutlined',],
  [PlayCircleFilledRounded,'PlayCircleFilledRounded',],
  [PlayCircleFilledSharp,'PlayCircleFilledSharp',],
  [PlayCircleFilledTwoTone,'PlayCircleFilledTwoTone',],
  [PlayCircleFilledWhite,'PlayCircleFilledWhite',],
  [PlayCircleFilledWhiteOutlined,'PlayCircleFilledWhiteOutlined',],
  [PlayCircleFilledWhiteRounded,'PlayCircleFilledWhiteRounded',],
  [PlayCircleFilledWhiteSharp,'PlayCircleFilledWhiteSharp',],
  [PlayCircleFilledWhiteTwoTone,'PlayCircleFilledWhiteTwoTone',],
  [PlayCircleOutline,'PlayCircleOutline',],
  [PlayCircleOutlineOutlined,'PlayCircleOutlineOutlined',],
  [PlayCircleOutlineRounded,'PlayCircleOutlineRounded',],
  [PlayCircleOutlineSharp,'PlayCircleOutlineSharp',],
  [PlayCircleOutlineTwoTone,'PlayCircleOutlineTwoTone',],
  [PlayForWork,'PlayForWork',],
  [PlayForWorkOutlined,'PlayForWorkOutlined',],
  [PlayForWorkRounded,'PlayForWorkRounded',],
  [PlayForWorkSharp,'PlayForWorkSharp',],
  [PlayForWorkTwoTone,'PlayForWorkTwoTone',],
  [PlaylistAdd,'PlaylistAdd',],
  [PlaylistAddCheck,'PlaylistAddCheck',],
  [PlaylistAddCheckOutlined,'PlaylistAddCheckOutlined',],
  [PlaylistAddCheckRounded,'PlaylistAddCheckRounded',],
  [PlaylistAddCheckSharp,'PlaylistAddCheckSharp',],
  [PlaylistAddCheckTwoTone,'PlaylistAddCheckTwoTone',],
  [PlaylistAddOutlined,'PlaylistAddOutlined',],
  [PlaylistAddRounded,'PlaylistAddRounded',],
  [PlaylistAddSharp,'PlaylistAddSharp',],
  [PlaylistAddTwoTone,'PlaylistAddTwoTone',],
  [PlaylistPlay,'PlaylistPlay',],
  [PlaylistPlayOutlined,'PlaylistPlayOutlined',],
  [PlaylistPlayRounded,'PlaylistPlayRounded',],
  [PlaylistPlaySharp,'PlaylistPlaySharp',],
  [PlaylistPlayTwoTone,'PlaylistPlayTwoTone',],
  [PlusOne,'PlusOne',],
  [PlusOneOutlined,'PlusOneOutlined',],
  [PlusOneRounded,'PlusOneRounded',],
  [PlusOneSharp,'PlusOneSharp',],
  [PlusOneTwoTone,'PlusOneTwoTone',],
  [Policy,'Policy',],
  [PolicyOutlined,'PolicyOutlined',],
  [PolicyRounded,'PolicyRounded',],
  [PolicySharp,'PolicySharp',],
  [PolicyTwoTone,'PolicyTwoTone',],
  [Poll,'Poll',],
  [PollOutlined,'PollOutlined',],
  [PollRounded,'PollRounded',],
  [PollSharp,'PollSharp',],
  [PollTwoTone,'PollTwoTone',],
  [Polymer,'Polymer',],
  [PolymerOutlined,'PolymerOutlined',],
  [PolymerRounded,'PolymerRounded',],
  [PolymerSharp,'PolymerSharp',],
  [PolymerTwoTone,'PolymerTwoTone',],
  [Pool,'Pool',],
  [PoolOutlined,'PoolOutlined',],
  [PoolRounded,'PoolRounded',],
  [PoolSharp,'PoolSharp',],
  [PoolTwoTone,'PoolTwoTone',],
  [PortableWifiOff,'PortableWifiOff',],
  [PortableWifiOffOutlined,'PortableWifiOffOutlined',],
  [PortableWifiOffRounded,'PortableWifiOffRounded',],
  [PortableWifiOffSharp,'PortableWifiOffSharp',],
  [PortableWifiOffTwoTone,'PortableWifiOffTwoTone',],
  [Portrait,'Portrait',],
  [PortraitOutlined,'PortraitOutlined',],
  [PortraitRounded,'PortraitRounded',],
  [PortraitSharp,'PortraitSharp',],
  [PortraitTwoTone,'PortraitTwoTone',],
  [PostAdd,'PostAdd',],
  [PostAddOutlined,'PostAddOutlined',],
  [PostAddRounded,'PostAddRounded',],
  [PostAddSharp,'PostAddSharp',],
  [PostAddTwoTone,'PostAddTwoTone',],
  [Power,'Power',],
  [PowerInput,'PowerInput',],
  [PowerInputOutlined,'PowerInputOutlined',],
  [PowerInputRounded,'PowerInputRounded',],
  [PowerInputSharp,'PowerInputSharp',],
  [PowerInputTwoTone,'PowerInputTwoTone',],
  [PowerOff,'PowerOff',],
  [PowerOffOutlined,'PowerOffOutlined',],
  [PowerOffRounded,'PowerOffRounded',],
  [PowerOffSharp,'PowerOffSharp',],
  [PowerOffTwoTone,'PowerOffTwoTone',],
  [PowerOutlined,'PowerOutlined',],
  [PowerRounded,'PowerRounded',],
  [PowerSettingsNew,'PowerSettingsNew',],
  [PowerSettingsNewOutlined,'PowerSettingsNewOutlined',],
  [PowerSettingsNewRounded,'PowerSettingsNewRounded',],
  [PowerSettingsNewSharp,'PowerSettingsNewSharp',],
  [PowerSettingsNewTwoTone,'PowerSettingsNewTwoTone',],
  [PowerSharp,'PowerSharp',],
  [PowerTwoTone,'PowerTwoTone',],
  [PregnantWoman,'PregnantWoman',],
  [PregnantWomanOutlined,'PregnantWomanOutlined',],
  [PregnantWomanRounded,'PregnantWomanRounded',],
  [PregnantWomanSharp,'PregnantWomanSharp',],
  [PregnantWomanTwoTone,'PregnantWomanTwoTone',],
  [PresentToAll,'PresentToAll',],
  [PresentToAllOutlined,'PresentToAllOutlined',],
  [PresentToAllRounded,'PresentToAllRounded',],
  [PresentToAllSharp,'PresentToAllSharp',],
  [PresentToAllTwoTone,'PresentToAllTwoTone',],
  [Print,'Print',],
  [PrintDisabled,'PrintDisabled',],
  [PrintDisabledOutlined,'PrintDisabledOutlined',],
  [PrintDisabledRounded,'PrintDisabledRounded',],
  [PrintDisabledSharp,'PrintDisabledSharp',],
  [PrintDisabledTwoTone,'PrintDisabledTwoTone',],
  [PrintOutlined,'PrintOutlined',],
  [PrintRounded,'PrintRounded',],
  [PrintSharp,'PrintSharp',],
  [PrintTwoTone,'PrintTwoTone',],
  [PriorityHigh,'PriorityHigh',],
  [PriorityHighOutlined,'PriorityHighOutlined',],
  [PriorityHighRounded,'PriorityHighRounded',],
  [PriorityHighSharp,'PriorityHighSharp',],
  [PriorityHighTwoTone,'PriorityHighTwoTone',],
  [Public,'Public',],
  [PublicOutlined,'PublicOutlined',],
  [PublicRounded,'PublicRounded',],
  [PublicSharp,'PublicSharp',],
  [PublicTwoTone,'PublicTwoTone',],
  [Publish,'Publish',],
  [PublishOutlined,'PublishOutlined',],
  [PublishRounded,'PublishRounded',],
  [PublishSharp,'PublishSharp',],
  [PublishTwoTone,'PublishTwoTone',],
  [QueryBuilder,'QueryBuilder',],
  [QueryBuilderOutlined,'QueryBuilderOutlined',],
  [QueryBuilderRounded,'QueryBuilderRounded',],
  [QueryBuilderSharp,'QueryBuilderSharp',],
  [QueryBuilderTwoTone,'QueryBuilderTwoTone',],
  [QuestionAnswer,'QuestionAnswer',],
  [QuestionAnswerOutlined,'QuestionAnswerOutlined',],
  [QuestionAnswerRounded,'QuestionAnswerRounded',],
  [QuestionAnswerSharp,'QuestionAnswerSharp',],
  [QuestionAnswerTwoTone,'QuestionAnswerTwoTone',],
  [Queue,'Queue',],
  [QueueMusic,'QueueMusic',],
  [QueueMusicOutlined,'QueueMusicOutlined',],
  [QueueMusicRounded,'QueueMusicRounded',],
  [QueueMusicSharp,'QueueMusicSharp',],
  [QueueMusicTwoTone,'QueueMusicTwoTone',],
  [QueueOutlined,'QueueOutlined',],
  [QueuePlayNext,'QueuePlayNext',],
  [QueuePlayNextOutlined,'QueuePlayNextOutlined',],
  [QueuePlayNextRounded,'QueuePlayNextRounded',],
  [QueuePlayNextSharp,'QueuePlayNextSharp',],
  [QueuePlayNextTwoTone,'QueuePlayNextTwoTone',],
  [QueueRounded,'QueueRounded',],
  [QueueSharp,'QueueSharp',],
  [QueueTwoTone,'QueueTwoTone',],
  [Radio,'Radio',],
  [RadioButtonChecked,'RadioButtonChecked',],
  [RadioButtonCheckedOutlined,'RadioButtonCheckedOutlined',],
  [RadioButtonCheckedRounded,'RadioButtonCheckedRounded',],
  [RadioButtonCheckedSharp,'RadioButtonCheckedSharp',],
  [RadioButtonCheckedTwoTone,'RadioButtonCheckedTwoTone',],
  [RadioButtonUnchecked,'RadioButtonUnchecked',],
  [RadioButtonUncheckedOutlined,'RadioButtonUncheckedOutlined',],
  [RadioButtonUncheckedRounded,'RadioButtonUncheckedRounded',],
  [RadioButtonUncheckedSharp,'RadioButtonUncheckedSharp',],
  [RadioButtonUncheckedTwoTone,'RadioButtonUncheckedTwoTone',],
  [RadioOutlined,'RadioOutlined',],
  [RadioRounded,'RadioRounded',],
  [RadioSharp,'RadioSharp',],
  [RadioTwoTone,'RadioTwoTone',],
  [RateReview,'RateReview',],
  [RateReviewOutlined,'RateReviewOutlined',],
  [RateReviewRounded,'RateReviewRounded',],
  [RateReviewSharp,'RateReviewSharp',],
  [RateReviewTwoTone,'RateReviewTwoTone',],
  [Receipt,'Receipt',],
  [ReceiptOutlined,'ReceiptOutlined',],
  [ReceiptRounded,'ReceiptRounded',],
  [ReceiptSharp,'ReceiptSharp',],
  [ReceiptTwoTone,'ReceiptTwoTone',],
  [RecentActors,'RecentActors',],
  [RecentActorsOutlined,'RecentActorsOutlined',],
  [RecentActorsRounded,'RecentActorsRounded',],
  [RecentActorsSharp,'RecentActorsSharp',],
  [RecentActorsTwoTone,'RecentActorsTwoTone',],
  [RecordVoiceOver,'RecordVoiceOver',],
  [RecordVoiceOverOutlined,'RecordVoiceOverOutlined',],
  [RecordVoiceOverRounded,'RecordVoiceOverRounded',],
  [RecordVoiceOverSharp,'RecordVoiceOverSharp',],
  [RecordVoiceOverTwoTone,'RecordVoiceOverTwoTone',],
  [Reddit,'Reddit',],
  [Redeem,'Redeem',],
  [RedeemOutlined,'RedeemOutlined',],
  [RedeemRounded,'RedeemRounded',],
  [RedeemSharp,'RedeemSharp',],
  [RedeemTwoTone,'RedeemTwoTone',],
  [Redo,'Redo',],
  [RedoOutlined,'RedoOutlined',],
  [RedoRounded,'RedoRounded',],
  [RedoSharp,'RedoSharp',],
  [RedoTwoTone,'RedoTwoTone',],
  [Refresh,'Refresh',],
  [RefreshOutlined,'RefreshOutlined',],
  [RefreshRounded,'RefreshRounded',],
  [RefreshSharp,'RefreshSharp',],
  [RefreshTwoTone,'RefreshTwoTone',],
  [Remove,'Remove',],
  [RemoveCircle,'RemoveCircle',],
  [RemoveCircleOutline,'RemoveCircleOutline',],
  [RemoveCircleOutlined,'RemoveCircleOutlined',],
  [RemoveCircleOutlineOutlined,'RemoveCircleOutlineOutlined',],
  [RemoveCircleOutlineRounded,'RemoveCircleOutlineRounded',],
  [RemoveCircleOutlineSharp,'RemoveCircleOutlineSharp',],
  [RemoveCircleOutlineTwoTone,'RemoveCircleOutlineTwoTone',],
  [RemoveCircleRounded,'RemoveCircleRounded',],
  [RemoveCircleSharp,'RemoveCircleSharp',],
  [RemoveCircleTwoTone,'RemoveCircleTwoTone',],
  [RemoveFromQueue,'RemoveFromQueue',],
  [RemoveFromQueueOutlined,'RemoveFromQueueOutlined',],
  [RemoveFromQueueRounded,'RemoveFromQueueRounded',],
  [RemoveFromQueueSharp,'RemoveFromQueueSharp',],
  [RemoveFromQueueTwoTone,'RemoveFromQueueTwoTone',],
  [RemoveOutlined,'RemoveOutlined',],
  [RemoveRedEye,'RemoveRedEye',],
  [RemoveRedEyeOutlined,'RemoveRedEyeOutlined',],
  [RemoveRedEyeRounded,'RemoveRedEyeRounded',],
  [RemoveRedEyeSharp,'RemoveRedEyeSharp',],
  [RemoveRedEyeTwoTone,'RemoveRedEyeTwoTone',],
  [RemoveRounded,'RemoveRounded',],
  [RemoveSharp,'RemoveSharp',],
  [RemoveShoppingCart,'RemoveShoppingCart',],
  [RemoveShoppingCartOutlined,'RemoveShoppingCartOutlined',],
  [RemoveShoppingCartRounded,'RemoveShoppingCartRounded',],
  [RemoveShoppingCartSharp,'RemoveShoppingCartSharp',],
  [RemoveShoppingCartTwoTone,'RemoveShoppingCartTwoTone',],
  [RemoveTwoTone,'RemoveTwoTone',],
  [Reorder,'Reorder',],
  [ReorderOutlined,'ReorderOutlined',],
  [ReorderRounded,'ReorderRounded',],
  [ReorderSharp,'ReorderSharp',],
  [ReorderTwoTone,'ReorderTwoTone',],
  [Repeat,'Repeat',],
  [RepeatOne,'RepeatOne',],
  [RepeatOneOutlined,'RepeatOneOutlined',],
  [RepeatOneRounded,'RepeatOneRounded',],
  [RepeatOneSharp,'RepeatOneSharp',],
  [RepeatOneTwoTone,'RepeatOneTwoTone',],
  [RepeatOutlined,'RepeatOutlined',],
  [RepeatRounded,'RepeatRounded',],
  [RepeatSharp,'RepeatSharp',],
  [RepeatTwoTone,'RepeatTwoTone',],
  [Replay,'Replay',],
  [Replay10,'Replay10',],
  [Replay10Outlined,'Replay10Outlined',],
  [Replay10Rounded,'Replay10Rounded',],
  [Replay10Sharp,'Replay10Sharp',],
  [Replay10TwoTone,'Replay10TwoTone',],
  [Replay30,'Replay30',],
  [Replay30Outlined,'Replay30Outlined',],
  [Replay30Rounded,'Replay30Rounded',],
  [Replay30Sharp,'Replay30Sharp',],
  [Replay30TwoTone,'Replay30TwoTone',],
  [Replay5,'Replay5',],
  [Replay5Outlined,'Replay5Outlined',],
  [Replay5Rounded,'Replay5Rounded',],
  [Replay5Sharp,'Replay5Sharp',],
  [Replay5TwoTone,'Replay5TwoTone',],
  [ReplayOutlined,'ReplayOutlined',],
  [ReplayRounded,'ReplayRounded',],
  [ReplaySharp,'ReplaySharp',],
  [ReplayTwoTone,'ReplayTwoTone',],
  [Reply,'Reply',],
  [ReplyAll,'ReplyAll',],
  [ReplyAllOutlined,'ReplyAllOutlined',],
  [ReplyAllRounded,'ReplyAllRounded',],
  [ReplyAllSharp,'ReplyAllSharp',],
  [ReplyAllTwoTone,'ReplyAllTwoTone',],
  [ReplyOutlined,'ReplyOutlined',],
  [ReplyRounded,'ReplyRounded',],
  [ReplySharp,'ReplySharp',],
  [ReplyTwoTone,'ReplyTwoTone',],
  [Report,'Report',],
  [ReportOff,'ReportOff',],
  [ReportOffOutlined,'ReportOffOutlined',],
  [ReportOffRounded,'ReportOffRounded',],
  [ReportOffSharp,'ReportOffSharp',],
  [ReportOffTwoTone,'ReportOffTwoTone',],
  [ReportOutlined,'ReportOutlined',],
  [ReportProblem,'ReportProblem',],
  [ReportProblemOutlined,'ReportProblemOutlined',],
  [ReportProblemRounded,'ReportProblemRounded',],
  [ReportProblemSharp,'ReportProblemSharp',],
  [ReportProblemTwoTone,'ReportProblemTwoTone',],
  [ReportRounded,'ReportRounded',],
  [ReportSharp,'ReportSharp',],
  [ReportTwoTone,'ReportTwoTone',],
  [Restaurant,'Restaurant',],
  [RestaurantMenu,'RestaurantMenu',],
  [RestaurantMenuOutlined,'RestaurantMenuOutlined',],
  [RestaurantMenuRounded,'RestaurantMenuRounded',],
  [RestaurantMenuSharp,'RestaurantMenuSharp',],
  [RestaurantMenuTwoTone,'RestaurantMenuTwoTone',],
  [RestaurantOutlined,'RestaurantOutlined',],
  [RestaurantRounded,'RestaurantRounded',],
  [RestaurantSharp,'RestaurantSharp',],
  [RestaurantTwoTone,'RestaurantTwoTone',],
  [Restore,'Restore',],
  [RestoreFromTrash,'RestoreFromTrash',],
  [RestoreFromTrashOutlined,'RestoreFromTrashOutlined',],
  [RestoreFromTrashRounded,'RestoreFromTrashRounded',],
  [RestoreFromTrashSharp,'RestoreFromTrashSharp',],
  [RestoreFromTrashTwoTone,'RestoreFromTrashTwoTone',],
  [RestoreOutlined,'RestoreOutlined',],
  [RestorePage,'RestorePage',],
  [RestorePageOutlined,'RestorePageOutlined',],
  [RestorePageRounded,'RestorePageRounded',],
  [RestorePageSharp,'RestorePageSharp',],
  [RestorePageTwoTone,'RestorePageTwoTone',],
  [RestoreRounded,'RestoreRounded',],
  [RestoreSharp,'RestoreSharp',],
  [RestoreTwoTone,'RestoreTwoTone',],
  [RingVolume,'RingVolume',],
  [RingVolumeOutlined,'RingVolumeOutlined',],
  [RingVolumeRounded,'RingVolumeRounded',],
  [RingVolumeSharp,'RingVolumeSharp',],
  [RingVolumeTwoTone,'RingVolumeTwoTone',],
  [Room,'Room',],
  [RoomOutlined,'RoomOutlined',],
  [RoomRounded,'RoomRounded',],
  [RoomService,'RoomService',],
  [RoomServiceOutlined,'RoomServiceOutlined',],
  [RoomServiceRounded,'RoomServiceRounded',],
  [RoomServiceSharp,'RoomServiceSharp',],
  [RoomServiceTwoTone,'RoomServiceTwoTone',],
  [RoomSharp,'RoomSharp',],
  [RoomTwoTone,'RoomTwoTone',],
  [Rotate90DegreesCcw,'Rotate90DegreesCcw',],
  [Rotate90DegreesCcwOutlined,'Rotate90DegreesCcwOutlined',],
  [Rotate90DegreesCcwRounded,'Rotate90DegreesCcwRounded',],
  [Rotate90DegreesCcwSharp,'Rotate90DegreesCcwSharp',],
  [Rotate90DegreesCcwTwoTone,'Rotate90DegreesCcwTwoTone',],
  [RotateLeft,'RotateLeft',],
  [RotateLeftOutlined,'RotateLeftOutlined',],
  [RotateLeftRounded,'RotateLeftRounded',],
  [RotateLeftSharp,'RotateLeftSharp',],
  [RotateLeftTwoTone,'RotateLeftTwoTone',],
  [RotateRight,'RotateRight',],
  [RotateRightOutlined,'RotateRightOutlined',],
  [RotateRightRounded,'RotateRightRounded',],
  [RotateRightSharp,'RotateRightSharp',],
  [RotateRightTwoTone,'RotateRightTwoTone',],
  [RoundedCorner,'RoundedCorner',],
  [RoundedCornerOutlined,'RoundedCornerOutlined',],
  [RoundedCornerRounded,'RoundedCornerRounded',],
  [RoundedCornerSharp,'RoundedCornerSharp',],
  [RoundedCornerTwoTone,'RoundedCornerTwoTone',],
  [Router,'Router',],
  [RouterOutlined,'RouterOutlined',],
  [RouterRounded,'RouterRounded',],
  [RouterSharp,'RouterSharp',],
  [RouterTwoTone,'RouterTwoTone',],
  [Rowing,'Rowing',],
  [RowingOutlined,'RowingOutlined',],
  [RowingRounded,'RowingRounded',],
  [RowingSharp,'RowingSharp',],
  [RowingTwoTone,'RowingTwoTone',],
  [RssFeed,'RssFeed',],
  [RssFeedOutlined,'RssFeedOutlined',],
  [RssFeedRounded,'RssFeedRounded',],
  [RssFeedSharp,'RssFeedSharp',],
  [RssFeedTwoTone,'RssFeedTwoTone',],
  [RvHookup,'RvHookup',],
  [RvHookupOutlined,'RvHookupOutlined',],
  [RvHookupRounded,'RvHookupRounded',],
  [RvHookupSharp,'RvHookupSharp',],
  [RvHookupTwoTone,'RvHookupTwoTone',],
  [Satellite,'Satellite',],
  [SatelliteOutlined,'SatelliteOutlined',],
  [SatelliteRounded,'SatelliteRounded',],
  [SatelliteSharp,'SatelliteSharp',],
  [SatelliteTwoTone,'SatelliteTwoTone',],
  [Save,'Save',],
  [SaveAlt,'SaveAlt',],
  [SaveAltOutlined,'SaveAltOutlined',],
  [SaveAltRounded,'SaveAltRounded',],
  [SaveAltSharp,'SaveAltSharp',],
  [SaveAltTwoTone,'SaveAltTwoTone',],
  [SaveOutlined,'SaveOutlined',],
  [SaveRounded,'SaveRounded',],
  [SaveSharp,'SaveSharp',],
  [SaveTwoTone,'SaveTwoTone',],
  [Scanner,'Scanner',],
  [ScannerOutlined,'ScannerOutlined',],
  [ScannerRounded,'ScannerRounded',],
  [ScannerSharp,'ScannerSharp',],
  [ScannerTwoTone,'ScannerTwoTone',],
  [ScatterPlot,'ScatterPlot',],
  [ScatterPlotOutlined,'ScatterPlotOutlined',],
  [ScatterPlotRounded,'ScatterPlotRounded',],
  [ScatterPlotSharp,'ScatterPlotSharp',],
  [ScatterPlotTwoTone,'ScatterPlotTwoTone',],
  [Schedule,'Schedule',],
  [ScheduleOutlined,'ScheduleOutlined',],
  [ScheduleRounded,'ScheduleRounded',],
  [ScheduleSharp,'ScheduleSharp',],
  [ScheduleTwoTone,'ScheduleTwoTone',],
  [School,'School',],
  [SchoolOutlined,'SchoolOutlined',],
  [SchoolRounded,'SchoolRounded',],
  [SchoolSharp,'SchoolSharp',],
  [SchoolTwoTone,'SchoolTwoTone',],
  [Score,'Score',],
  [ScoreOutlined,'ScoreOutlined',],
  [ScoreRounded,'ScoreRounded',],
  [ScoreSharp,'ScoreSharp',],
  [ScoreTwoTone,'ScoreTwoTone',],
  [ScreenLockLandscape,'ScreenLockLandscape',],
  [ScreenLockLandscapeOutlined,'ScreenLockLandscapeOutlined',],
  [ScreenLockLandscapeRounded,'ScreenLockLandscapeRounded',],
  [ScreenLockLandscapeSharp,'ScreenLockLandscapeSharp',],
  [ScreenLockLandscapeTwoTone,'ScreenLockLandscapeTwoTone',],
  [ScreenLockPortrait,'ScreenLockPortrait',],
  [ScreenLockPortraitOutlined,'ScreenLockPortraitOutlined',],
  [ScreenLockPortraitRounded,'ScreenLockPortraitRounded',],
  [ScreenLockPortraitSharp,'ScreenLockPortraitSharp',],
  [ScreenLockPortraitTwoTone,'ScreenLockPortraitTwoTone',],
  [ScreenLockRotation,'ScreenLockRotation',],
  [ScreenLockRotationOutlined,'ScreenLockRotationOutlined',],
  [ScreenLockRotationRounded,'ScreenLockRotationRounded',],
  [ScreenLockRotationSharp,'ScreenLockRotationSharp',],
  [ScreenLockRotationTwoTone,'ScreenLockRotationTwoTone',],
  [ScreenRotation,'ScreenRotation',],
  [ScreenRotationOutlined,'ScreenRotationOutlined',],
  [ScreenRotationRounded,'ScreenRotationRounded',],
  [ScreenRotationSharp,'ScreenRotationSharp',],
  [ScreenRotationTwoTone,'ScreenRotationTwoTone',],
  [ScreenShare,'ScreenShare',],
  [ScreenShareOutlined,'ScreenShareOutlined',],
  [ScreenShareRounded,'ScreenShareRounded',],
  [ScreenShareSharp,'ScreenShareSharp',],
  [ScreenShareTwoTone,'ScreenShareTwoTone',],
  [SdCard,'SdCard',],
  [SdCardOutlined,'SdCardOutlined',],
  [SdCardRounded,'SdCardRounded',],
  [SdCardSharp,'SdCardSharp',],
  [SdCardTwoTone,'SdCardTwoTone',],
  [SdStorage,'SdStorage',],
  [SdStorageOutlined,'SdStorageOutlined',],
  [SdStorageRounded,'SdStorageRounded',],
  [SdStorageSharp,'SdStorageSharp',],
  [SdStorageTwoTone,'SdStorageTwoTone',],
  [Search,'Search',],
  [SearchOutlined,'SearchOutlined',],
  [SearchRounded,'SearchRounded',],
  [SearchSharp,'SearchSharp',],
  [SearchTwoTone,'SearchTwoTone',],
  [Security,'Security',],
  [SecurityOutlined,'SecurityOutlined',],
  [SecurityRounded,'SecurityRounded',],
  [SecuritySharp,'SecuritySharp',],
  [SecurityTwoTone,'SecurityTwoTone',],
  [SelectAll,'SelectAll',],
  [SelectAllOutlined,'SelectAllOutlined',],
  [SelectAllRounded,'SelectAllRounded',],
  [SelectAllSharp,'SelectAllSharp',],
  [SelectAllTwoTone,'SelectAllTwoTone',],
  [Send,'Send',],
  [SendOutlined,'SendOutlined',],
  [SendRounded,'SendRounded',],
  [SendSharp,'SendSharp',],
  [SendTwoTone,'SendTwoTone',],
  [SentimentDissatisfied,'SentimentDissatisfied',],
  [SentimentDissatisfiedOutlined,'SentimentDissatisfiedOutlined',],
  [SentimentDissatisfiedRounded,'SentimentDissatisfiedRounded',],
  [SentimentDissatisfiedSharp,'SentimentDissatisfiedSharp',],
  [SentimentDissatisfiedTwoTone,'SentimentDissatisfiedTwoTone',],
  [SentimentSatisfied,'SentimentSatisfied',],
  [SentimentSatisfiedAlt,'SentimentSatisfiedAlt',],
  [SentimentSatisfiedAltOutlined,'SentimentSatisfiedAltOutlined',],
  [SentimentSatisfiedAltRounded,'SentimentSatisfiedAltRounded',],
  [SentimentSatisfiedAltSharp,'SentimentSatisfiedAltSharp',],
  [SentimentSatisfiedAltTwoTone,'SentimentSatisfiedAltTwoTone',],
  [SentimentSatisfiedOutlined,'SentimentSatisfiedOutlined',],
  [SentimentSatisfiedRounded,'SentimentSatisfiedRounded',],
  [SentimentSatisfiedSharp,'SentimentSatisfiedSharp',],
  [SentimentSatisfiedTwoTone,'SentimentSatisfiedTwoTone',],
  [SentimentVeryDissatisfied,'SentimentVeryDissatisfied',],
  [SentimentVeryDissatisfiedOutlined,'SentimentVeryDissatisfiedOutlined',],
  [SentimentVeryDissatisfiedRounded,'SentimentVeryDissatisfiedRounded',],
  [SentimentVeryDissatisfiedSharp,'SentimentVeryDissatisfiedSharp',],
  [SentimentVeryDissatisfiedTwoTone,'SentimentVeryDissatisfiedTwoTone',],
  [SentimentVerySatisfied,'SentimentVerySatisfied',],
  [SentimentVerySatisfiedOutlined,'SentimentVerySatisfiedOutlined',],
  [SentimentVerySatisfiedRounded,'SentimentVerySatisfiedRounded',],
  [SentimentVerySatisfiedSharp,'SentimentVerySatisfiedSharp',],
  [SentimentVerySatisfiedTwoTone,'SentimentVerySatisfiedTwoTone',],
  [Settings,'Settings',],
  [SettingsApplications,'SettingsApplications',],
  [SettingsApplicationsOutlined,'SettingsApplicationsOutlined',],
  [SettingsApplicationsRounded,'SettingsApplicationsRounded',],
  [SettingsApplicationsSharp,'SettingsApplicationsSharp',],
  [SettingsApplicationsTwoTone,'SettingsApplicationsTwoTone',],
  [SettingsBackupRestore,'SettingsBackupRestore',],
  [SettingsBackupRestoreOutlined,'SettingsBackupRestoreOutlined',],
  [SettingsBackupRestoreRounded,'SettingsBackupRestoreRounded',],
  [SettingsBackupRestoreSharp,'SettingsBackupRestoreSharp',],
  [SettingsBackupRestoreTwoTone,'SettingsBackupRestoreTwoTone',],
  [SettingsBluetooth,'SettingsBluetooth',],
  [SettingsBluetoothOutlined,'SettingsBluetoothOutlined',],
  [SettingsBluetoothRounded,'SettingsBluetoothRounded',],
  [SettingsBluetoothSharp,'SettingsBluetoothSharp',],
  [SettingsBluetoothTwoTone,'SettingsBluetoothTwoTone',],
  [SettingsBrightness,'SettingsBrightness',],
  [SettingsBrightnessOutlined,'SettingsBrightnessOutlined',],
  [SettingsBrightnessRounded,'SettingsBrightnessRounded',],
  [SettingsBrightnessSharp,'SettingsBrightnessSharp',],
  [SettingsBrightnessTwoTone,'SettingsBrightnessTwoTone',],
  [SettingsCell,'SettingsCell',],
  [SettingsCellOutlined,'SettingsCellOutlined',],
  [SettingsCellRounded,'SettingsCellRounded',],
  [SettingsCellSharp,'SettingsCellSharp',],
  [SettingsCellTwoTone,'SettingsCellTwoTone',],
  [SettingsEthernet,'SettingsEthernet',],
  [SettingsEthernetOutlined,'SettingsEthernetOutlined',],
  [SettingsEthernetRounded,'SettingsEthernetRounded',],
  [SettingsEthernetSharp,'SettingsEthernetSharp',],
  [SettingsEthernetTwoTone,'SettingsEthernetTwoTone',],
  [SettingsInputAntenna,'SettingsInputAntenna',],
  [SettingsInputAntennaOutlined,'SettingsInputAntennaOutlined',],
  [SettingsInputAntennaRounded,'SettingsInputAntennaRounded',],
  [SettingsInputAntennaSharp,'SettingsInputAntennaSharp',],
  [SettingsInputAntennaTwoTone,'SettingsInputAntennaTwoTone',],
  [SettingsInputComponent,'SettingsInputComponent',],
  [SettingsInputComponentOutlined,'SettingsInputComponentOutlined',],
  [SettingsInputComponentRounded,'SettingsInputComponentRounded',],
  [SettingsInputComponentSharp,'SettingsInputComponentSharp',],
  [SettingsInputComponentTwoTone,'SettingsInputComponentTwoTone',],
  [SettingsInputComposite,'SettingsInputComposite',],
  [SettingsInputCompositeOutlined,'SettingsInputCompositeOutlined',],
  [SettingsInputCompositeRounded,'SettingsInputCompositeRounded',],
  [SettingsInputCompositeSharp,'SettingsInputCompositeSharp',],
  [SettingsInputCompositeTwoTone,'SettingsInputCompositeTwoTone',],
  [SettingsInputHdmi,'SettingsInputHdmi',],
  [SettingsInputHdmiOutlined,'SettingsInputHdmiOutlined',],
  [SettingsInputHdmiRounded,'SettingsInputHdmiRounded',],
  [SettingsInputHdmiSharp,'SettingsInputHdmiSharp',],
  [SettingsInputHdmiTwoTone,'SettingsInputHdmiTwoTone',],
  [SettingsInputSvideo,'SettingsInputSvideo',],
  [SettingsInputSvideoOutlined,'SettingsInputSvideoOutlined',],
  [SettingsInputSvideoRounded,'SettingsInputSvideoRounded',],
  [SettingsInputSvideoSharp,'SettingsInputSvideoSharp',],
  [SettingsInputSvideoTwoTone,'SettingsInputSvideoTwoTone',],
  [SettingsOutlined,'SettingsOutlined',],
  [SettingsOverscan,'SettingsOverscan',],
  [SettingsOverscanOutlined,'SettingsOverscanOutlined',],
  [SettingsOverscanRounded,'SettingsOverscanRounded',],
  [SettingsOverscanSharp,'SettingsOverscanSharp',],
  [SettingsOverscanTwoTone,'SettingsOverscanTwoTone',],
  [SettingsPhone,'SettingsPhone',],
  [SettingsPhoneOutlined,'SettingsPhoneOutlined',],
  [SettingsPhoneRounded,'SettingsPhoneRounded',],
  [SettingsPhoneSharp,'SettingsPhoneSharp',],
  [SettingsPhoneTwoTone,'SettingsPhoneTwoTone',],
  [SettingsPower,'SettingsPower',],
  [SettingsPowerOutlined,'SettingsPowerOutlined',],
  [SettingsPowerRounded,'SettingsPowerRounded',],
  [SettingsPowerSharp,'SettingsPowerSharp',],
  [SettingsPowerTwoTone,'SettingsPowerTwoTone',],
  [SettingsRemote,'SettingsRemote',],
  [SettingsRemoteOutlined,'SettingsRemoteOutlined',],
  [SettingsRemoteRounded,'SettingsRemoteRounded',],
  [SettingsRemoteSharp,'SettingsRemoteSharp',],
  [SettingsRemoteTwoTone,'SettingsRemoteTwoTone',],
  [SettingsRounded,'SettingsRounded',],
  [SettingsSharp,'SettingsSharp',],
  [SettingsSystemDaydream,'SettingsSystemDaydream',],
  [SettingsSystemDaydreamOutlined,'SettingsSystemDaydreamOutlined',],
  [SettingsSystemDaydreamRounded,'SettingsSystemDaydreamRounded',],
  [SettingsSystemDaydreamSharp,'SettingsSystemDaydreamSharp',],
  [SettingsSystemDaydreamTwoTone,'SettingsSystemDaydreamTwoTone',],
  [SettingsTwoTone,'SettingsTwoTone',],
  [SettingsVoice,'SettingsVoice',],
  [SettingsVoiceOutlined,'SettingsVoiceOutlined',],
  [SettingsVoiceRounded,'SettingsVoiceRounded',],
  [SettingsVoiceSharp,'SettingsVoiceSharp',],
  [SettingsVoiceTwoTone,'SettingsVoiceTwoTone',],
  [Share,'Share',],
  [ShareOutlined,'ShareOutlined',],
  [ShareRounded,'ShareRounded',],
  [ShareSharp,'ShareSharp',],
  [ShareTwoTone,'ShareTwoTone',],
  [Shop,'Shop',],
  [ShopOutlined,'ShopOutlined',],
  [ShoppingBasket,'ShoppingBasket',],
  [ShoppingBasketOutlined,'ShoppingBasketOutlined',],
  [ShoppingBasketRounded,'ShoppingBasketRounded',],
  [ShoppingBasketSharp,'ShoppingBasketSharp',],
  [ShoppingBasketTwoTone,'ShoppingBasketTwoTone',],
  [ShoppingCart,'ShoppingCart',],
  [ShoppingCartOutlined,'ShoppingCartOutlined',],
  [ShoppingCartRounded,'ShoppingCartRounded',],
  [ShoppingCartSharp,'ShoppingCartSharp',],
  [ShoppingCartTwoTone,'ShoppingCartTwoTone',],
  [ShopRounded,'ShopRounded',],
  [ShopSharp,'ShopSharp',],
  [ShopTwo,'ShopTwo',],
  [ShopTwoOutlined,'ShopTwoOutlined',],
  [ShopTwoRounded,'ShopTwoRounded',],
  [ShopTwoSharp,'ShopTwoSharp',],
  [ShopTwoTone,'ShopTwoTone',],
  [ShopTwoTwoTone,'ShopTwoTwoTone',],
  [ShortText,'ShortText',],
  [ShortTextOutlined,'ShortTextOutlined',],
  [ShortTextRounded,'ShortTextRounded',],
  [ShortTextSharp,'ShortTextSharp',],
  [ShortTextTwoTone,'ShortTextTwoTone',],
  [ShowChart,'ShowChart',],
  [ShowChartOutlined,'ShowChartOutlined',],
  [ShowChartRounded,'ShowChartRounded',],
  [ShowChartSharp,'ShowChartSharp',],
  [ShowChartTwoTone,'ShowChartTwoTone',],
  [Shuffle,'Shuffle',],
  [ShuffleOutlined,'ShuffleOutlined',],
  [ShuffleRounded,'ShuffleRounded',],
  [ShuffleSharp,'ShuffleSharp',],
  [ShuffleTwoTone,'ShuffleTwoTone',],
  [ShutterSpeed,'ShutterSpeed',],
  [ShutterSpeedOutlined,'ShutterSpeedOutlined',],
  [ShutterSpeedRounded,'ShutterSpeedRounded',],
  [ShutterSpeedSharp,'ShutterSpeedSharp',],
  [ShutterSpeedTwoTone,'ShutterSpeedTwoTone',],
  [SignalCellular0Bar,'SignalCellular0Bar',],
  [SignalCellular0BarOutlined,'SignalCellular0BarOutlined',],
  [SignalCellular0BarRounded,'SignalCellular0BarRounded',],
  [SignalCellular0BarSharp,'SignalCellular0BarSharp',],
  [SignalCellular0BarTwoTone,'SignalCellular0BarTwoTone',],
  [SignalCellular1Bar,'SignalCellular1Bar',],
  [SignalCellular1BarOutlined,'SignalCellular1BarOutlined',],
  [SignalCellular1BarRounded,'SignalCellular1BarRounded',],
  [SignalCellular1BarSharp,'SignalCellular1BarSharp',],
  [SignalCellular1BarTwoTone,'SignalCellular1BarTwoTone',],
  [SignalCellular2Bar,'SignalCellular2Bar',],
  [SignalCellular2BarOutlined,'SignalCellular2BarOutlined',],
  [SignalCellular2BarRounded,'SignalCellular2BarRounded',],
  [SignalCellular2BarSharp,'SignalCellular2BarSharp',],
  [SignalCellular2BarTwoTone,'SignalCellular2BarTwoTone',],
  [SignalCellular3Bar,'SignalCellular3Bar',],
  [SignalCellular3BarOutlined,'SignalCellular3BarOutlined',],
  [SignalCellular3BarRounded,'SignalCellular3BarRounded',],
  [SignalCellular3BarSharp,'SignalCellular3BarSharp',],
  [SignalCellular3BarTwoTone,'SignalCellular3BarTwoTone',],
  [SignalCellular4Bar,'SignalCellular4Bar',],
  [SignalCellular4BarOutlined,'SignalCellular4BarOutlined',],
  [SignalCellular4BarRounded,'SignalCellular4BarRounded',],
  [SignalCellular4BarSharp,'SignalCellular4BarSharp',],
  [SignalCellular4BarTwoTone,'SignalCellular4BarTwoTone',],
  [SignalCellularAlt,'SignalCellularAlt',],
  [SignalCellularAltOutlined,'SignalCellularAltOutlined',],
  [SignalCellularAltRounded,'SignalCellularAltRounded',],
  [SignalCellularAltSharp,'SignalCellularAltSharp',],
  [SignalCellularAltTwoTone,'SignalCellularAltTwoTone',],
  [SignalCellularConnectedNoInternet0Bar,'SignalCellularConnectedNoInternet0Bar',],
  [SignalCellularConnectedNoInternet0BarOutlined,'SignalCellularConnectedNoInternet0BarOutlined',],
  [SignalCellularConnectedNoInternet0BarRounded,'SignalCellularConnectedNoInternet0BarRounded',],
  [SignalCellularConnectedNoInternet0BarSharp,'SignalCellularConnectedNoInternet0BarSharp',],
  [SignalCellularConnectedNoInternet0BarTwoTone,'SignalCellularConnectedNoInternet0BarTwoTone',],
  [SignalCellularConnectedNoInternet1Bar,'SignalCellularConnectedNoInternet1Bar',],
  [SignalCellularConnectedNoInternet1BarOutlined,'SignalCellularConnectedNoInternet1BarOutlined',],
  [SignalCellularConnectedNoInternet1BarRounded,'SignalCellularConnectedNoInternet1BarRounded',],
  [SignalCellularConnectedNoInternet1BarSharp,'SignalCellularConnectedNoInternet1BarSharp',],
  [SignalCellularConnectedNoInternet1BarTwoTone,'SignalCellularConnectedNoInternet1BarTwoTone',],
  [SignalCellularConnectedNoInternet2Bar,'SignalCellularConnectedNoInternet2Bar',],
  [SignalCellularConnectedNoInternet2BarOutlined,'SignalCellularConnectedNoInternet2BarOutlined',],
  [SignalCellularConnectedNoInternet2BarRounded,'SignalCellularConnectedNoInternet2BarRounded',],
  [SignalCellularConnectedNoInternet2BarSharp,'SignalCellularConnectedNoInternet2BarSharp',],
  [SignalCellularConnectedNoInternet2BarTwoTone,'SignalCellularConnectedNoInternet2BarTwoTone',],
  [SignalCellularConnectedNoInternet3Bar,'SignalCellularConnectedNoInternet3Bar',],
  [SignalCellularConnectedNoInternet3BarOutlined,'SignalCellularConnectedNoInternet3BarOutlined',],
  [SignalCellularConnectedNoInternet3BarRounded,'SignalCellularConnectedNoInternet3BarRounded',],
  [SignalCellularConnectedNoInternet3BarSharp,'SignalCellularConnectedNoInternet3BarSharp',],
  [SignalCellularConnectedNoInternet3BarTwoTone,'SignalCellularConnectedNoInternet3BarTwoTone',],
  [SignalCellularConnectedNoInternet4Bar,'SignalCellularConnectedNoInternet4Bar',],
  [SignalCellularConnectedNoInternet4BarOutlined,'SignalCellularConnectedNoInternet4BarOutlined',],
  [SignalCellularConnectedNoInternet4BarRounded,'SignalCellularConnectedNoInternet4BarRounded',],
  [SignalCellularConnectedNoInternet4BarSharp,'SignalCellularConnectedNoInternet4BarSharp',],
  [SignalCellularConnectedNoInternet4BarTwoTone,'SignalCellularConnectedNoInternet4BarTwoTone',],
  [SignalCellularNoSim,'SignalCellularNoSim',],
  [SignalCellularNoSimOutlined,'SignalCellularNoSimOutlined',],
  [SignalCellularNoSimRounded,'SignalCellularNoSimRounded',],
  [SignalCellularNoSimSharp,'SignalCellularNoSimSharp',],
  [SignalCellularNoSimTwoTone,'SignalCellularNoSimTwoTone',],
  [SignalCellularNull,'SignalCellularNull',],
  [SignalCellularNullOutlined,'SignalCellularNullOutlined',],
  [SignalCellularNullRounded,'SignalCellularNullRounded',],
  [SignalCellularNullSharp,'SignalCellularNullSharp',],
  [SignalCellularNullTwoTone,'SignalCellularNullTwoTone',],
  [SignalCellularOff,'SignalCellularOff',],
  [SignalCellularOffOutlined,'SignalCellularOffOutlined',],
  [SignalCellularOffRounded,'SignalCellularOffRounded',],
  [SignalCellularOffSharp,'SignalCellularOffSharp',],
  [SignalCellularOffTwoTone,'SignalCellularOffTwoTone',],
  [SignalWifi0Bar,'SignalWifi0Bar',],
  [SignalWifi0BarOutlined,'SignalWifi0BarOutlined',],
  [SignalWifi0BarRounded,'SignalWifi0BarRounded',],
  [SignalWifi0BarSharp,'SignalWifi0BarSharp',],
  [SignalWifi0BarTwoTone,'SignalWifi0BarTwoTone',],
  [SignalWifi1Bar,'SignalWifi1Bar',],
  [SignalWifi1BarLock,'SignalWifi1BarLock',],
  [SignalWifi1BarLockOutlined,'SignalWifi1BarLockOutlined',],
  [SignalWifi1BarLockRounded,'SignalWifi1BarLockRounded',],
  [SignalWifi1BarLockSharp,'SignalWifi1BarLockSharp',],
  [SignalWifi1BarLockTwoTone,'SignalWifi1BarLockTwoTone',],
  [SignalWifi1BarOutlined,'SignalWifi1BarOutlined',],
  [SignalWifi1BarRounded,'SignalWifi1BarRounded',],
  [SignalWifi1BarSharp,'SignalWifi1BarSharp',],
  [SignalWifi1BarTwoTone,'SignalWifi1BarTwoTone',],
  [SignalWifi2Bar,'SignalWifi2Bar',],
  [SignalWifi2BarLock,'SignalWifi2BarLock',],
  [SignalWifi2BarLockOutlined,'SignalWifi2BarLockOutlined',],
  [SignalWifi2BarLockRounded,'SignalWifi2BarLockRounded',],
  [SignalWifi2BarLockSharp,'SignalWifi2BarLockSharp',],
  [SignalWifi2BarLockTwoTone,'SignalWifi2BarLockTwoTone',],
  [SignalWifi2BarOutlined,'SignalWifi2BarOutlined',],
  [SignalWifi2BarRounded,'SignalWifi2BarRounded',],
  [SignalWifi2BarSharp,'SignalWifi2BarSharp',],
  [SignalWifi2BarTwoTone,'SignalWifi2BarTwoTone',],
  [SignalWifi3Bar,'SignalWifi3Bar',],
  [SignalWifi3BarLock,'SignalWifi3BarLock',],
  [SignalWifi3BarLockOutlined,'SignalWifi3BarLockOutlined',],
  [SignalWifi3BarLockRounded,'SignalWifi3BarLockRounded',],
  [SignalWifi3BarLockSharp,'SignalWifi3BarLockSharp',],
  [SignalWifi3BarLockTwoTone,'SignalWifi3BarLockTwoTone',],
  [SignalWifi3BarOutlined,'SignalWifi3BarOutlined',],
  [SignalWifi3BarRounded,'SignalWifi3BarRounded',],
  [SignalWifi3BarSharp,'SignalWifi3BarSharp',],
  [SignalWifi3BarTwoTone,'SignalWifi3BarTwoTone',],
  [SignalWifi4Bar,'SignalWifi4Bar',],
  [SignalWifi4BarLock,'SignalWifi4BarLock',],
  [SignalWifi4BarLockOutlined,'SignalWifi4BarLockOutlined',],
  [SignalWifi4BarLockRounded,'SignalWifi4BarLockRounded',],
  [SignalWifi4BarLockSharp,'SignalWifi4BarLockSharp',],
  [SignalWifi4BarLockTwoTone,'SignalWifi4BarLockTwoTone',],
  [SignalWifi4BarOutlined,'SignalWifi4BarOutlined',],
  [SignalWifi4BarRounded,'SignalWifi4BarRounded',],
  [SignalWifi4BarSharp,'SignalWifi4BarSharp',],
  [SignalWifi4BarTwoTone,'SignalWifi4BarTwoTone',],
  [SignalWifiOff,'SignalWifiOff',],
  [SignalWifiOffOutlined,'SignalWifiOffOutlined',],
  [SignalWifiOffRounded,'SignalWifiOffRounded',],
  [SignalWifiOffSharp,'SignalWifiOffSharp',],
  [SignalWifiOffTwoTone,'SignalWifiOffTwoTone',],
  [SimCard,'SimCard',],
  [SimCardOutlined,'SimCardOutlined',],
  [SimCardRounded,'SimCardRounded',],
  [SimCardSharp,'SimCardSharp',],
  [SimCardTwoTone,'SimCardTwoTone',],
  [SingleBed,'SingleBed',],
  [SingleBedOutlined,'SingleBedOutlined',],
  [SingleBedRounded,'SingleBedRounded',],
  [SingleBedSharp,'SingleBedSharp',],
  [SingleBedTwoTone,'SingleBedTwoTone',],
  [SkipNext,'SkipNext',],
  [SkipNextOutlined,'SkipNextOutlined',],
  [SkipNextRounded,'SkipNextRounded',],
  [SkipNextSharp,'SkipNextSharp',],
  [SkipNextTwoTone,'SkipNextTwoTone',],
  [SkipPrevious,'SkipPrevious',],
  [SkipPreviousOutlined,'SkipPreviousOutlined',],
  [SkipPreviousRounded,'SkipPreviousRounded',],
  [SkipPreviousSharp,'SkipPreviousSharp',],
  [SkipPreviousTwoTone,'SkipPreviousTwoTone',],
  [Slideshow,'Slideshow',],
  [SlideshowOutlined,'SlideshowOutlined',],
  [SlideshowRounded,'SlideshowRounded',],
  [SlideshowSharp,'SlideshowSharp',],
  [SlideshowTwoTone,'SlideshowTwoTone',],
  [SlowMotionVideo,'SlowMotionVideo',],
  [SlowMotionVideoOutlined,'SlowMotionVideoOutlined',],
  [SlowMotionVideoRounded,'SlowMotionVideoRounded',],
  [SlowMotionVideoSharp,'SlowMotionVideoSharp',],
  [SlowMotionVideoTwoTone,'SlowMotionVideoTwoTone',],
  [Smartphone,'Smartphone',],
  [SmartphoneOutlined,'SmartphoneOutlined',],
  [SmartphoneRounded,'SmartphoneRounded',],
  [SmartphoneSharp,'SmartphoneSharp',],
  [SmartphoneTwoTone,'SmartphoneTwoTone',],
  [SmokeFree,'SmokeFree',],
  [SmokeFreeOutlined,'SmokeFreeOutlined',],
  [SmokeFreeRounded,'SmokeFreeRounded',],
  [SmokeFreeSharp,'SmokeFreeSharp',],
  [SmokeFreeTwoTone,'SmokeFreeTwoTone',],
  [SmokingRooms,'SmokingRooms',],
  [SmokingRoomsOutlined,'SmokingRoomsOutlined',],
  [SmokingRoomsRounded,'SmokingRoomsRounded',],
  [SmokingRoomsSharp,'SmokingRoomsSharp',],
  [SmokingRoomsTwoTone,'SmokingRoomsTwoTone',],
  [Sms,'Sms',],
  [SmsFailed,'SmsFailed',],
  [SmsFailedOutlined,'SmsFailedOutlined',],
  [SmsFailedRounded,'SmsFailedRounded',],
  [SmsFailedSharp,'SmsFailedSharp',],
  [SmsFailedTwoTone,'SmsFailedTwoTone',],
  [SmsOutlined,'SmsOutlined',],
  [SmsRounded,'SmsRounded',],
  [SmsSharp,'SmsSharp',],
  [SmsTwoTone,'SmsTwoTone',],
  [Snooze,'Snooze',],
  [SnoozeOutlined,'SnoozeOutlined',],
  [SnoozeRounded,'SnoozeRounded',],
  [SnoozeSharp,'SnoozeSharp',],
  [SnoozeTwoTone,'SnoozeTwoTone',],
  [Sort,'Sort',],
  [SortByAlpha,'SortByAlpha',],
  [SortByAlphaOutlined,'SortByAlphaOutlined',],
  [SortByAlphaRounded,'SortByAlphaRounded',],
  [SortByAlphaSharp,'SortByAlphaSharp',],
  [SortByAlphaTwoTone,'SortByAlphaTwoTone',],
  [SortOutlined,'SortOutlined',],
  [SortRounded,'SortRounded',],
  [SortSharp,'SortSharp',],
  [SortTwoTone,'SortTwoTone',],
  [Spa,'Spa',],
  [SpaceBar,'SpaceBar',],
  [SpaceBarOutlined,'SpaceBarOutlined',],
  [SpaceBarRounded,'SpaceBarRounded',],
  [SpaceBarSharp,'SpaceBarSharp',],
  [SpaceBarTwoTone,'SpaceBarTwoTone',],
  [SpaOutlined,'SpaOutlined',],
  [SpaRounded,'SpaRounded',],
  [SpaSharp,'SpaSharp',],
  [SpaTwoTone,'SpaTwoTone',],
  [Speaker,'Speaker',],
  [SpeakerGroup,'SpeakerGroup',],
  [SpeakerGroupOutlined,'SpeakerGroupOutlined',],
  [SpeakerGroupRounded,'SpeakerGroupRounded',],
  [SpeakerGroupSharp,'SpeakerGroupSharp',],
  [SpeakerGroupTwoTone,'SpeakerGroupTwoTone',],
  [SpeakerNotes,'SpeakerNotes',],
  [SpeakerNotesOff,'SpeakerNotesOff',],
  [SpeakerNotesOffOutlined,'SpeakerNotesOffOutlined',],
  [SpeakerNotesOffRounded,'SpeakerNotesOffRounded',],
  [SpeakerNotesOffSharp,'SpeakerNotesOffSharp',],
  [SpeakerNotesOffTwoTone,'SpeakerNotesOffTwoTone',],
  [SpeakerNotesOutlined,'SpeakerNotesOutlined',],
  [SpeakerNotesRounded,'SpeakerNotesRounded',],
  [SpeakerNotesSharp,'SpeakerNotesSharp',],
  [SpeakerNotesTwoTone,'SpeakerNotesTwoTone',],
  [SpeakerOutlined,'SpeakerOutlined',],
  [SpeakerPhone,'SpeakerPhone',],
  [SpeakerPhoneOutlined,'SpeakerPhoneOutlined',],
  [SpeakerPhoneRounded,'SpeakerPhoneRounded',],
  [SpeakerPhoneSharp,'SpeakerPhoneSharp',],
  [SpeakerPhoneTwoTone,'SpeakerPhoneTwoTone',],
  [SpeakerRounded,'SpeakerRounded',],
  [SpeakerSharp,'SpeakerSharp',],
  [SpeakerTwoTone,'SpeakerTwoTone',],
  [Speed,'Speed',],
  [SpeedOutlined,'SpeedOutlined',],
  [SpeedRounded,'SpeedRounded',],
  [SpeedSharp,'SpeedSharp',],
  [SpeedTwoTone,'SpeedTwoTone',],
  [Spellcheck,'Spellcheck',],
  [SpellcheckOutlined,'SpellcheckOutlined',],
  [SpellcheckRounded,'SpellcheckRounded',],
  [SpellcheckSharp,'SpellcheckSharp',],
  [SpellcheckTwoTone,'SpellcheckTwoTone',],
  [Sports,'Sports',],
  [SportsBaseball,'SportsBaseball',],
  [SportsBaseballOutlined,'SportsBaseballOutlined',],
  [SportsBaseballRounded,'SportsBaseballRounded',],
  [SportsBaseballSharp,'SportsBaseballSharp',],
  [SportsBaseballTwoTone,'SportsBaseballTwoTone',],
  [SportsBasketball,'SportsBasketball',],
  [SportsBasketballOutlined,'SportsBasketballOutlined',],
  [SportsBasketballRounded,'SportsBasketballRounded',],
  [SportsBasketballSharp,'SportsBasketballSharp',],
  [SportsBasketballTwoTone,'SportsBasketballTwoTone',],
  [SportsCricket,'SportsCricket',],
  [SportsCricketOutlined,'SportsCricketOutlined',],
  [SportsCricketRounded,'SportsCricketRounded',],
  [SportsCricketSharp,'SportsCricketSharp',],
  [SportsCricketTwoTone,'SportsCricketTwoTone',],
  [SportsEsports,'SportsEsports',],
  [SportsEsportsOutlined,'SportsEsportsOutlined',],
  [SportsEsportsRounded,'SportsEsportsRounded',],
  [SportsEsportsSharp,'SportsEsportsSharp',],
  [SportsEsportsTwoTone,'SportsEsportsTwoTone',],
  [SportsFootball,'SportsFootball',],
  [SportsFootballOutlined,'SportsFootballOutlined',],
  [SportsFootballRounded,'SportsFootballRounded',],
  [SportsFootballSharp,'SportsFootballSharp',],
  [SportsFootballTwoTone,'SportsFootballTwoTone',],
  [SportsGolf,'SportsGolf',],
  [SportsGolfOutlined,'SportsGolfOutlined',],
  [SportsGolfRounded,'SportsGolfRounded',],
  [SportsGolfSharp,'SportsGolfSharp',],
  [SportsGolfTwoTone,'SportsGolfTwoTone',],
  [SportsHandball,'SportsHandball',],
  [SportsHandballOutlined,'SportsHandballOutlined',],
  [SportsHandballRounded,'SportsHandballRounded',],
  [SportsHandballSharp,'SportsHandballSharp',],
  [SportsHandballTwoTone,'SportsHandballTwoTone',],
  [SportsHockey,'SportsHockey',],
  [SportsHockeyOutlined,'SportsHockeyOutlined',],
  [SportsHockeyRounded,'SportsHockeyRounded',],
  [SportsHockeySharp,'SportsHockeySharp',],
  [SportsHockeyTwoTone,'SportsHockeyTwoTone',],
  [SportsKabaddi,'SportsKabaddi',],
  [SportsKabaddiOutlined,'SportsKabaddiOutlined',],
  [SportsKabaddiRounded,'SportsKabaddiRounded',],
  [SportsKabaddiSharp,'SportsKabaddiSharp',],
  [SportsKabaddiTwoTone,'SportsKabaddiTwoTone',],
  [SportsMma,'SportsMma',],
  [SportsMmaOutlined,'SportsMmaOutlined',],
  [SportsMmaRounded,'SportsMmaRounded',],
  [SportsMmaSharp,'SportsMmaSharp',],
  [SportsMmaTwoTone,'SportsMmaTwoTone',],
  [SportsMotorsports,'SportsMotorsports',],
  [SportsMotorsportsOutlined,'SportsMotorsportsOutlined',],
  [SportsMotorsportsRounded,'SportsMotorsportsRounded',],
  [SportsMotorsportsSharp,'SportsMotorsportsSharp',],
  [SportsMotorsportsTwoTone,'SportsMotorsportsTwoTone',],
  [SportsOutlined,'SportsOutlined',],
  [SportsRounded,'SportsRounded',],
  [SportsRugby,'SportsRugby',],
  [SportsRugbyOutlined,'SportsRugbyOutlined',],
  [SportsRugbyRounded,'SportsRugbyRounded',],
  [SportsRugbySharp,'SportsRugbySharp',],
  [SportsRugbyTwoTone,'SportsRugbyTwoTone',],
  [SportsSharp,'SportsSharp',],
  [SportsSoccer,'SportsSoccer',],
  [SportsSoccerOutlined,'SportsSoccerOutlined',],
  [SportsSoccerRounded,'SportsSoccerRounded',],
  [SportsSoccerSharp,'SportsSoccerSharp',],
  [SportsSoccerTwoTone,'SportsSoccerTwoTone',],
  [SportsTennis,'SportsTennis',],
  [SportsTennisOutlined,'SportsTennisOutlined',],
  [SportsTennisRounded,'SportsTennisRounded',],
  [SportsTennisSharp,'SportsTennisSharp',],
  [SportsTennisTwoTone,'SportsTennisTwoTone',],
  [SportsTwoTone,'SportsTwoTone',],
  [SportsVolleyball,'SportsVolleyball',],
  [SportsVolleyballOutlined,'SportsVolleyballOutlined',],
  [SportsVolleyballRounded,'SportsVolleyballRounded',],
  [SportsVolleyballSharp,'SportsVolleyballSharp',],
  [SportsVolleyballTwoTone,'SportsVolleyballTwoTone',],
  [SquareFoot,'SquareFoot',],
  [SquareFootOutlined,'SquareFootOutlined',],
  [SquareFootRounded,'SquareFootRounded',],
  [SquareFootSharp,'SquareFootSharp',],
  [SquareFootTwoTone,'SquareFootTwoTone',],
  [Star,'Star',],
  [StarBorder,'StarBorder',],
  [StarBorderOutlined,'StarBorderOutlined',],
  [StarBorderRounded,'StarBorderRounded',],
  [StarBorderSharp,'StarBorderSharp',],
  [StarBorderTwoTone,'StarBorderTwoTone',],
  [StarHalf,'StarHalf',],
  [StarHalfOutlined,'StarHalfOutlined',],
  [StarHalfRounded,'StarHalfRounded',],
  [StarHalfSharp,'StarHalfSharp',],
  [StarHalfTwoTone,'StarHalfTwoTone',],
  [StarOutlined,'StarOutlined',],
  [StarRate,'StarRate',],
  [StarRateOutlined,'StarRateOutlined',],
  [StarRateRounded,'StarRateRounded',],
  [StarRateSharp,'StarRateSharp',],
  [StarRateTwoTone,'StarRateTwoTone',],
  [StarRounded,'StarRounded',],
  [Stars,'Stars',],
  [StarSharp,'StarSharp',],
  [StarsOutlined,'StarsOutlined',],
  [StarsRounded,'StarsRounded',],
  [StarsSharp,'StarsSharp',],
  [StarsTwoTone,'StarsTwoTone',],
  [StarTwoTone,'StarTwoTone',],
  [StayCurrentLandscape,'StayCurrentLandscape',],
  [StayCurrentLandscapeOutlined,'StayCurrentLandscapeOutlined',],
  [StayCurrentLandscapeRounded,'StayCurrentLandscapeRounded',],
  [StayCurrentLandscapeSharp,'StayCurrentLandscapeSharp',],
  [StayCurrentLandscapeTwoTone,'StayCurrentLandscapeTwoTone',],
  [StayCurrentPortrait,'StayCurrentPortrait',],
  [StayCurrentPortraitOutlined,'StayCurrentPortraitOutlined',],
  [StayCurrentPortraitRounded,'StayCurrentPortraitRounded',],
  [StayCurrentPortraitSharp,'StayCurrentPortraitSharp',],
  [StayCurrentPortraitTwoTone,'StayCurrentPortraitTwoTone',],
  [StayPrimaryLandscape,'StayPrimaryLandscape',],
  [StayPrimaryLandscapeOutlined,'StayPrimaryLandscapeOutlined',],
  [StayPrimaryLandscapeRounded,'StayPrimaryLandscapeRounded',],
  [StayPrimaryLandscapeSharp,'StayPrimaryLandscapeSharp',],
  [StayPrimaryLandscapeTwoTone,'StayPrimaryLandscapeTwoTone',],
  [StayPrimaryPortrait,'StayPrimaryPortrait',],
  [StayPrimaryPortraitOutlined,'StayPrimaryPortraitOutlined',],
  [StayPrimaryPortraitRounded,'StayPrimaryPortraitRounded',],
  [StayPrimaryPortraitSharp,'StayPrimaryPortraitSharp',],
  [StayPrimaryPortraitTwoTone,'StayPrimaryPortraitTwoTone',],
  [Stop,'Stop',],
  [StopOutlined,'StopOutlined',],
  [StopRounded,'StopRounded',],
  [StopScreenShare,'StopScreenShare',],
  [StopScreenShareOutlined,'StopScreenShareOutlined',],
  [StopScreenShareRounded,'StopScreenShareRounded',],
  [StopScreenShareSharp,'StopScreenShareSharp',],
  [StopScreenShareTwoTone,'StopScreenShareTwoTone',],
  [StopSharp,'StopSharp',],
  [StopTwoTone,'StopTwoTone',],
  [Storage,'Storage',],
  [StorageOutlined,'StorageOutlined',],
  [StorageRounded,'StorageRounded',],
  [StorageSharp,'StorageSharp',],
  [StorageTwoTone,'StorageTwoTone',],
  [Store,'Store',],
  [Storefront,'Storefront',],
  [StorefrontOutlined,'StorefrontOutlined',],
  [StorefrontRounded,'StorefrontRounded',],
  [StorefrontSharp,'StorefrontSharp',],
  [StorefrontTwoTone,'StorefrontTwoTone',],
  [StoreMallDirectory,'StoreMallDirectory',],
  [StoreMallDirectoryOutlined,'StoreMallDirectoryOutlined',],
  [StoreMallDirectoryRounded,'StoreMallDirectoryRounded',],
  [StoreMallDirectorySharp,'StoreMallDirectorySharp',],
  [StoreMallDirectoryTwoTone,'StoreMallDirectoryTwoTone',],
  [StoreOutlined,'StoreOutlined',],
  [StoreRounded,'StoreRounded',],
  [StoreSharp,'StoreSharp',],
  [StoreTwoTone,'StoreTwoTone',],
  [Straighten,'Straighten',],
  [StraightenOutlined,'StraightenOutlined',],
  [StraightenRounded,'StraightenRounded',],
  [StraightenSharp,'StraightenSharp',],
  [StraightenTwoTone,'StraightenTwoTone',],
  [Streetview,'Streetview',],
  [StreetviewOutlined,'StreetviewOutlined',],
  [StreetviewRounded,'StreetviewRounded',],
  [StreetviewSharp,'StreetviewSharp',],
  [StreetviewTwoTone,'StreetviewTwoTone',],
  [StrikethroughS,'StrikethroughS',],
  [StrikethroughSOutlined,'StrikethroughSOutlined',],
  [StrikethroughSRounded,'StrikethroughSRounded',],
  [StrikethroughSSharp,'StrikethroughSSharp',],
  [StrikethroughSTwoTone,'StrikethroughSTwoTone',],
  [Style,'Style',],
  [StyleOutlined,'StyleOutlined',],
  [StyleRounded,'StyleRounded',],
  [StyleSharp,'StyleSharp',],
  [StyleTwoTone,'StyleTwoTone',],
  [SubdirectoryArrowLeft,'SubdirectoryArrowLeft',],
  [SubdirectoryArrowLeftOutlined,'SubdirectoryArrowLeftOutlined',],
  [SubdirectoryArrowLeftRounded,'SubdirectoryArrowLeftRounded',],
  [SubdirectoryArrowLeftSharp,'SubdirectoryArrowLeftSharp',],
  [SubdirectoryArrowLeftTwoTone,'SubdirectoryArrowLeftTwoTone',],
  [SubdirectoryArrowRight,'SubdirectoryArrowRight',],
  [SubdirectoryArrowRightOutlined,'SubdirectoryArrowRightOutlined',],
  [SubdirectoryArrowRightRounded,'SubdirectoryArrowRightRounded',],
  [SubdirectoryArrowRightSharp,'SubdirectoryArrowRightSharp',],
  [SubdirectoryArrowRightTwoTone,'SubdirectoryArrowRightTwoTone',],
  [Subject,'Subject',],
  [SubjectOutlined,'SubjectOutlined',],
  [SubjectRounded,'SubjectRounded',],
  [SubjectSharp,'SubjectSharp',],
  [SubjectTwoTone,'SubjectTwoTone',],
  [Subscriptions,'Subscriptions',],
  [SubscriptionsOutlined,'SubscriptionsOutlined',],
  [SubscriptionsRounded,'SubscriptionsRounded',],
  [SubscriptionsSharp,'SubscriptionsSharp',],
  [SubscriptionsTwoTone,'SubscriptionsTwoTone',],
  [Subtitles,'Subtitles',],
  [SubtitlesOutlined,'SubtitlesOutlined',],
  [SubtitlesRounded,'SubtitlesRounded',],
  [SubtitlesSharp,'SubtitlesSharp',],
  [SubtitlesTwoTone,'SubtitlesTwoTone',],
  [Subway,'Subway',],
  [SubwayOutlined,'SubwayOutlined',],
  [SubwayRounded,'SubwayRounded',],
  [SubwaySharp,'SubwaySharp',],
  [SubwayTwoTone,'SubwayTwoTone',],
  [SupervisedUserCircle,'SupervisedUserCircle',],
  [SupervisedUserCircleOutlined,'SupervisedUserCircleOutlined',],
  [SupervisedUserCircleRounded,'SupervisedUserCircleRounded',],
  [SupervisedUserCircleSharp,'SupervisedUserCircleSharp',],
  [SupervisedUserCircleTwoTone,'SupervisedUserCircleTwoTone',],
  [SupervisorAccount,'SupervisorAccount',],
  [SupervisorAccountOutlined,'SupervisorAccountOutlined',],
  [SupervisorAccountRounded,'SupervisorAccountRounded',],
  [SupervisorAccountSharp,'SupervisorAccountSharp',],
  [SupervisorAccountTwoTone,'SupervisorAccountTwoTone',],
  [SurroundSound,'SurroundSound',],
  [SurroundSoundOutlined,'SurroundSoundOutlined',],
  [SurroundSoundRounded,'SurroundSoundRounded',],
  [SurroundSoundSharp,'SurroundSoundSharp',],
  [SurroundSoundTwoTone,'SurroundSoundTwoTone',],
  [SwapCalls,'SwapCalls',],
  [SwapCallsOutlined,'SwapCallsOutlined',],
  [SwapCallsRounded,'SwapCallsRounded',],
  [SwapCallsSharp,'SwapCallsSharp',],
  [SwapCallsTwoTone,'SwapCallsTwoTone',],
  [SwapHoriz,'SwapHoriz',],
  [SwapHorizontalCircle,'SwapHorizontalCircle',],
  [SwapHorizontalCircleOutlined,'SwapHorizontalCircleOutlined',],
  [SwapHorizontalCircleRounded,'SwapHorizontalCircleRounded',],
  [SwapHorizontalCircleSharp,'SwapHorizontalCircleSharp',],
  [SwapHorizontalCircleTwoTone,'SwapHorizontalCircleTwoTone',],
  [SwapHorizOutlined,'SwapHorizOutlined',],
  [SwapHorizRounded,'SwapHorizRounded',],
  [SwapHorizSharp,'SwapHorizSharp',],
  [SwapHorizTwoTone,'SwapHorizTwoTone',],
  [SwapVert,'SwapVert',],
  [SwapVerticalCircle,'SwapVerticalCircle',],
  [SwapVerticalCircleOutlined,'SwapVerticalCircleOutlined',],
  [SwapVerticalCircleRounded,'SwapVerticalCircleRounded',],
  [SwapVerticalCircleSharp,'SwapVerticalCircleSharp',],
  [SwapVerticalCircleTwoTone,'SwapVerticalCircleTwoTone',],
  [SwapVertOutlined,'SwapVertOutlined',],
  [SwapVertRounded,'SwapVertRounded',],
  [SwapVertSharp,'SwapVertSharp',],
  [SwapVertTwoTone,'SwapVertTwoTone',],
  [SwitchCamera,'SwitchCamera',],
  [SwitchCameraOutlined,'SwitchCameraOutlined',],
  [SwitchCameraRounded,'SwitchCameraRounded',],
  [SwitchCameraSharp,'SwitchCameraSharp',],
  [SwitchCameraTwoTone,'SwitchCameraTwoTone',],
  [SwitchVideo,'SwitchVideo',],
  [SwitchVideoOutlined,'SwitchVideoOutlined',],
  [SwitchVideoRounded,'SwitchVideoRounded',],
  [SwitchVideoSharp,'SwitchVideoSharp',],
  [SwitchVideoTwoTone,'SwitchVideoTwoTone',],
  [Sync,'Sync',],
  [SyncAlt,'SyncAlt',],
  [SyncAltOutlined,'SyncAltOutlined',],
  [SyncAltRounded,'SyncAltRounded',],
  [SyncAltSharp,'SyncAltSharp',],
  [SyncAltTwoTone,'SyncAltTwoTone',],
  [SyncDisabled,'SyncDisabled',],
  [SyncDisabledOutlined,'SyncDisabledOutlined',],
  [SyncDisabledRounded,'SyncDisabledRounded',],
  [SyncDisabledSharp,'SyncDisabledSharp',],
  [SyncDisabledTwoTone,'SyncDisabledTwoTone',],
  [SyncOutlined,'SyncOutlined',],
  [SyncProblem,'SyncProblem',],
  [SyncProblemOutlined,'SyncProblemOutlined',],
  [SyncProblemRounded,'SyncProblemRounded',],
  [SyncProblemSharp,'SyncProblemSharp',],
  [SyncProblemTwoTone,'SyncProblemTwoTone',],
  [SyncRounded,'SyncRounded',],
  [SyncSharp,'SyncSharp',],
  [SyncTwoTone,'SyncTwoTone',],
  [SystemUpdate,'SystemUpdate',],
  [SystemUpdateAlt,'SystemUpdateAlt',],
  [SystemUpdateAltOutlined,'SystemUpdateAltOutlined',],
  [SystemUpdateAltRounded,'SystemUpdateAltRounded',],
  [SystemUpdateAltSharp,'SystemUpdateAltSharp',],
  [SystemUpdateAltTwoTone,'SystemUpdateAltTwoTone',],
  [SystemUpdateOutlined,'SystemUpdateOutlined',],
  [SystemUpdateRounded,'SystemUpdateRounded',],
  [SystemUpdateSharp,'SystemUpdateSharp',],
  [SystemUpdateTwoTone,'SystemUpdateTwoTone',],
  [Tab,'Tab',],
  [TableChart,'TableChart',],
  [TableChartOutlined,'TableChartOutlined',],
  [TableChartRounded,'TableChartRounded',],
  [TableChartSharp,'TableChartSharp',],
  [TableChartTwoTone,'TableChartTwoTone',],
  [Tablet,'Tablet',],
  [TabletAndroid,'TabletAndroid',],
  [TabletAndroidOutlined,'TabletAndroidOutlined',],
  [TabletAndroidRounded,'TabletAndroidRounded',],
  [TabletAndroidSharp,'TabletAndroidSharp',],
  [TabletAndroidTwoTone,'TabletAndroidTwoTone',],
  [TabletMac,'TabletMac',],
  [TabletMacOutlined,'TabletMacOutlined',],
  [TabletMacRounded,'TabletMacRounded',],
  [TabletMacSharp,'TabletMacSharp',],
  [TabletMacTwoTone,'TabletMacTwoTone',],
  [TabletOutlined,'TabletOutlined',],
  [TabletRounded,'TabletRounded',],
  [TabletSharp,'TabletSharp',],
  [TabletTwoTone,'TabletTwoTone',],
  [TabOutlined,'TabOutlined',],
  [TabRounded,'TabRounded',],
  [TabSharp,'TabSharp',],
  [TabTwoTone,'TabTwoTone',],
  [TabUnselected,'TabUnselected',],
  [TabUnselectedOutlined,'TabUnselectedOutlined',],
  [TabUnselectedRounded,'TabUnselectedRounded',],
  [TabUnselectedSharp,'TabUnselectedSharp',],
  [TabUnselectedTwoTone,'TabUnselectedTwoTone',],
  [TagFaces,'TagFaces',],
  [TagFacesOutlined,'TagFacesOutlined',],
  [TagFacesRounded,'TagFacesRounded',],
  [TagFacesSharp,'TagFacesSharp',],
  [TagFacesTwoTone,'TagFacesTwoTone',],
  [TapAndPlay,'TapAndPlay',],
  [TapAndPlayOutlined,'TapAndPlayOutlined',],
  [TapAndPlayRounded,'TapAndPlayRounded',],
  [TapAndPlaySharp,'TapAndPlaySharp',],
  [TapAndPlayTwoTone,'TapAndPlayTwoTone',],
  [Telegram,'Telegram',],
  [Terrain,'Terrain',],
  [TerrainOutlined,'TerrainOutlined',],
  [TerrainRounded,'TerrainRounded',],
  [TerrainSharp,'TerrainSharp',],
  [TerrainTwoTone,'TerrainTwoTone',],
  [TextFields,'TextFields',],
  [TextFieldsOutlined,'TextFieldsOutlined',],
  [TextFieldsRounded,'TextFieldsRounded',],
  [TextFieldsSharp,'TextFieldsSharp',],
  [TextFieldsTwoTone,'TextFieldsTwoTone',],
  [TextFormat,'TextFormat',],
  [TextFormatOutlined,'TextFormatOutlined',],
  [TextFormatRounded,'TextFormatRounded',],
  [TextFormatSharp,'TextFormatSharp',],
  [TextFormatTwoTone,'TextFormatTwoTone',],
  [TextRotateUp,'TextRotateUp',],
  [TextRotateUpOutlined,'TextRotateUpOutlined',],
  [TextRotateUpRounded,'TextRotateUpRounded',],
  [TextRotateUpSharp,'TextRotateUpSharp',],
  [TextRotateUpTwoTone,'TextRotateUpTwoTone',],
  [TextRotateVertical,'TextRotateVertical',],
  [TextRotateVerticalOutlined,'TextRotateVerticalOutlined',],
  [TextRotateVerticalRounded,'TextRotateVerticalRounded',],
  [TextRotateVerticalSharp,'TextRotateVerticalSharp',],
  [TextRotateVerticalTwoTone,'TextRotateVerticalTwoTone',],
  [TextRotationAngledown,'TextRotationAngledown',],
  [TextRotationAngledownOutlined,'TextRotationAngledownOutlined',],
  [TextRotationAngledownRounded,'TextRotationAngledownRounded',],
  [TextRotationAngledownSharp,'TextRotationAngledownSharp',],
  [TextRotationAngledownTwoTone,'TextRotationAngledownTwoTone',],
  [TextRotationAngleup,'TextRotationAngleup',],
  [TextRotationAngleupOutlined,'TextRotationAngleupOutlined',],
  [TextRotationAngleupRounded,'TextRotationAngleupRounded',],
  [TextRotationAngleupSharp,'TextRotationAngleupSharp',],
  [TextRotationAngleupTwoTone,'TextRotationAngleupTwoTone',],
  [TextRotationDown,'TextRotationDown',],
  [TextRotationDownOutlined,'TextRotationDownOutlined',],
  [TextRotationDownRounded,'TextRotationDownRounded',],
  [TextRotationDownSharp,'TextRotationDownSharp',],
  [TextRotationDownTwoTone,'TextRotationDownTwoTone',],
  [TextRotationNone,'TextRotationNone',],
  [TextRotationNoneOutlined,'TextRotationNoneOutlined',],
  [TextRotationNoneRounded,'TextRotationNoneRounded',],
  [TextRotationNoneSharp,'TextRotationNoneSharp',],
  [TextRotationNoneTwoTone,'TextRotationNoneTwoTone',],
  [Textsms,'Textsms',],
  [TextsmsOutlined,'TextsmsOutlined',],
  [TextsmsRounded,'TextsmsRounded',],
  [TextsmsSharp,'TextsmsSharp',],
  [TextsmsTwoTone,'TextsmsTwoTone',],
  [Texture,'Texture',],
  [TextureOutlined,'TextureOutlined',],
  [TextureRounded,'TextureRounded',],
  [TextureSharp,'TextureSharp',],
  [TextureTwoTone,'TextureTwoTone',],
  [Theaters,'Theaters',],
  [TheatersOutlined,'TheatersOutlined',],
  [TheatersRounded,'TheatersRounded',],
  [TheatersSharp,'TheatersSharp',],
  [TheatersTwoTone,'TheatersTwoTone',],
  [ThreeDRotation,'ThreeDRotation',],
  [ThreeDRotationOutlined,'ThreeDRotationOutlined',],
  [ThreeDRotationRounded,'ThreeDRotationRounded',],
  [ThreeDRotationSharp,'ThreeDRotationSharp',],
  [ThreeDRotationTwoTone,'ThreeDRotationTwoTone',],
  [ThreeSixty,'ThreeSixty',],
  [ThreeSixtyOutlined,'ThreeSixtyOutlined',],
  [ThreeSixtyRounded,'ThreeSixtyRounded',],
  [ThreeSixtySharp,'ThreeSixtySharp',],
  [ThreeSixtyTwoTone,'ThreeSixtyTwoTone',],
  [ThumbDown,'ThumbDown',],
  [ThumbDownAlt,'ThumbDownAlt',],
  [ThumbDownAltOutlined,'ThumbDownAltOutlined',],
  [ThumbDownAltRounded,'ThumbDownAltRounded',],
  [ThumbDownAltSharp,'ThumbDownAltSharp',],
  [ThumbDownAltTwoTone,'ThumbDownAltTwoTone',],
  [ThumbDownOutlined,'ThumbDownOutlined',],
  [ThumbDownRounded,'ThumbDownRounded',],
  [ThumbDownSharp,'ThumbDownSharp',],
  [ThumbDownTwoTone,'ThumbDownTwoTone',],
  [ThumbsUpDown,'ThumbsUpDown',],
  [ThumbsUpDownOutlined,'ThumbsUpDownOutlined',],
  [ThumbsUpDownRounded,'ThumbsUpDownRounded',],
  [ThumbsUpDownSharp,'ThumbsUpDownSharp',],
  [ThumbsUpDownTwoTone,'ThumbsUpDownTwoTone',],
  [ThumbUp,'ThumbUp',],
  [ThumbUpAlt,'ThumbUpAlt',],
  [ThumbUpAltOutlined,'ThumbUpAltOutlined',],
  [ThumbUpAltRounded,'ThumbUpAltRounded',],
  [ThumbUpAltSharp,'ThumbUpAltSharp',],
  [ThumbUpAltTwoTone,'ThumbUpAltTwoTone',],
  [ThumbUpOutlined,'ThumbUpOutlined',],
  [ThumbUpRounded,'ThumbUpRounded',],
  [ThumbUpSharp,'ThumbUpSharp',],
  [ThumbUpTwoTone,'ThumbUpTwoTone',],
  [Timelapse,'Timelapse',],
  [TimelapseOutlined,'TimelapseOutlined',],
  [TimelapseRounded,'TimelapseRounded',],
  [TimelapseSharp,'TimelapseSharp',],
  [TimelapseTwoTone,'TimelapseTwoTone',],
  [Timeline,'Timeline',],
  [TimelineOutlined,'TimelineOutlined',],
  [TimelineRounded,'TimelineRounded',],
  [TimelineSharp,'TimelineSharp',],
  [TimelineTwoTone,'TimelineTwoTone',],
  [Timer,'Timer',],
  [Timer10,'Timer10',],
  [Timer10Outlined,'Timer10Outlined',],
  [Timer10Rounded,'Timer10Rounded',],
  [Timer10Sharp,'Timer10Sharp',],
  [Timer10TwoTone,'Timer10TwoTone',],
  [Timer3,'Timer3',],
  [Timer3Outlined,'Timer3Outlined',],
  [Timer3Rounded,'Timer3Rounded',],
  [Timer3Sharp,'Timer3Sharp',],
  [Timer3TwoTone,'Timer3TwoTone',],
  [TimerOff,'TimerOff',],
  [TimerOffOutlined,'TimerOffOutlined',],
  [TimerOffRounded,'TimerOffRounded',],
  [TimerOffSharp,'TimerOffSharp',],
  [TimerOffTwoTone,'TimerOffTwoTone',],
  [TimerOutlined,'TimerOutlined',],
  [TimerRounded,'TimerRounded',],
  [TimerSharp,'TimerSharp',],
  [TimerTwoTone,'TimerTwoTone',],
  [TimeToLeave,'TimeToLeave',],
  [TimeToLeaveOutlined,'TimeToLeaveOutlined',],
  [TimeToLeaveRounded,'TimeToLeaveRounded',],
  [TimeToLeaveSharp,'TimeToLeaveSharp',],
  [TimeToLeaveTwoTone,'TimeToLeaveTwoTone',],
  [Title,'Title',],
  [TitleOutlined,'TitleOutlined',],
  [TitleRounded,'TitleRounded',],
  [TitleSharp,'TitleSharp',],
  [TitleTwoTone,'TitleTwoTone',],
  [Toc,'Toc',],
  [TocOutlined,'TocOutlined',],
  [TocRounded,'TocRounded',],
  [TocSharp,'TocSharp',],
  [TocTwoTone,'TocTwoTone',],
  [Today,'Today',],
  [TodayOutlined,'TodayOutlined',],
  [TodayRounded,'TodayRounded',],
  [TodaySharp,'TodaySharp',],
  [TodayTwoTone,'TodayTwoTone',],
  [ToggleOff,'ToggleOff',],
  [ToggleOffOutlined,'ToggleOffOutlined',],
  [ToggleOffRounded,'ToggleOffRounded',],
  [ToggleOffSharp,'ToggleOffSharp',],
  [ToggleOffTwoTone,'ToggleOffTwoTone',],
  [ToggleOn,'ToggleOn',],
  [ToggleOnOutlined,'ToggleOnOutlined',],
  [ToggleOnRounded,'ToggleOnRounded',],
  [ToggleOnSharp,'ToggleOnSharp',],
  [ToggleOnTwoTone,'ToggleOnTwoTone',],
  [Toll,'Toll',],
  [TollOutlined,'TollOutlined',],
  [TollRounded,'TollRounded',],
  [TollSharp,'TollSharp',],
  [TollTwoTone,'TollTwoTone',],
  [Tonality,'Tonality',],
  [TonalityOutlined,'TonalityOutlined',],
  [TonalityRounded,'TonalityRounded',],
  [TonalitySharp,'TonalitySharp',],
  [TonalityTwoTone,'TonalityTwoTone',],
  [TouchApp,'TouchApp',],
  [TouchAppOutlined,'TouchAppOutlined',],
  [TouchAppRounded,'TouchAppRounded',],
  [TouchAppSharp,'TouchAppSharp',],
  [TouchAppTwoTone,'TouchAppTwoTone',],
  [Toys,'Toys',],
  [ToysOutlined,'ToysOutlined',],
  [ToysRounded,'ToysRounded',],
  [ToysSharp,'ToysSharp',],
  [ToysTwoTone,'ToysTwoTone',],
  [TrackChanges,'TrackChanges',],
  [TrackChangesOutlined,'TrackChangesOutlined',],
  [TrackChangesRounded,'TrackChangesRounded',],
  [TrackChangesSharp,'TrackChangesSharp',],
  [TrackChangesTwoTone,'TrackChangesTwoTone',],
  [Traffic,'Traffic',],
  [TrafficOutlined,'TrafficOutlined',],
  [TrafficRounded,'TrafficRounded',],
  [TrafficSharp,'TrafficSharp',],
  [TrafficTwoTone,'TrafficTwoTone',],
  [Train,'Train',],
  [TrainOutlined,'TrainOutlined',],
  [TrainRounded,'TrainRounded',],
  [TrainSharp,'TrainSharp',],
  [TrainTwoTone,'TrainTwoTone',],
  [Tram,'Tram',],
  [TramOutlined,'TramOutlined',],
  [TramRounded,'TramRounded',],
  [TramSharp,'TramSharp',],
  [TramTwoTone,'TramTwoTone',],
  [TransferWithinAStation,'TransferWithinAStation',],
  [TransferWithinAStationOutlined,'TransferWithinAStationOutlined',],
  [TransferWithinAStationRounded,'TransferWithinAStationRounded',],
  [TransferWithinAStationSharp,'TransferWithinAStationSharp',],
  [TransferWithinAStationTwoTone,'TransferWithinAStationTwoTone',],
  [Transform,'Transform',],
  [TransformOutlined,'TransformOutlined',],
  [TransformRounded,'TransformRounded',],
  [TransformSharp,'TransformSharp',],
  [TransformTwoTone,'TransformTwoTone',],
  [TransitEnterexit,'TransitEnterexit',],
  [TransitEnterexitOutlined,'TransitEnterexitOutlined',],
  [TransitEnterexitRounded,'TransitEnterexitRounded',],
  [TransitEnterexitSharp,'TransitEnterexitSharp',],
  [TransitEnterexitTwoTone,'TransitEnterexitTwoTone',],
  [Translate,'Translate',],
  [TranslateOutlined,'TranslateOutlined',],
  [TranslateRounded,'TranslateRounded',],
  [TranslateSharp,'TranslateSharp',],
  [TranslateTwoTone,'TranslateTwoTone',],
  [TrendingDown,'TrendingDown',],
  [TrendingDownOutlined,'TrendingDownOutlined',],
  [TrendingDownRounded,'TrendingDownRounded',],
  [TrendingDownSharp,'TrendingDownSharp',],
  [TrendingDownTwoTone,'TrendingDownTwoTone',],
  [TrendingFlat,'TrendingFlat',],
  [TrendingFlatOutlined,'TrendingFlatOutlined',],
  [TrendingFlatRounded,'TrendingFlatRounded',],
  [TrendingFlatSharp,'TrendingFlatSharp',],
  [TrendingFlatTwoTone,'TrendingFlatTwoTone',],
  [TrendingUp,'TrendingUp',],
  [TrendingUpOutlined,'TrendingUpOutlined',],
  [TrendingUpRounded,'TrendingUpRounded',],
  [TrendingUpSharp,'TrendingUpSharp',],
  [TrendingUpTwoTone,'TrendingUpTwoTone',],
  [TripOrigin,'TripOrigin',],
  [TripOriginOutlined,'TripOriginOutlined',],
  [TripOriginRounded,'TripOriginRounded',],
  [TripOriginSharp,'TripOriginSharp',],
  [TripOriginTwoTone,'TripOriginTwoTone',],
  [Tune,'Tune',],
  [TuneOutlined,'TuneOutlined',],
  [TuneRounded,'TuneRounded',],
  [TuneSharp,'TuneSharp',],
  [TuneTwoTone,'TuneTwoTone',],
  [TurnedIn,'TurnedIn',],
  [TurnedInNot,'TurnedInNot',],
  [TurnedInNotOutlined,'TurnedInNotOutlined',],
  [TurnedInNotRounded,'TurnedInNotRounded',],
  [TurnedInNotSharp,'TurnedInNotSharp',],
  [TurnedInNotTwoTone,'TurnedInNotTwoTone',],
  [TurnedInOutlined,'TurnedInOutlined',],
  [TurnedInRounded,'TurnedInRounded',],
  [TurnedInSharp,'TurnedInSharp',],
  [TurnedInTwoTone,'TurnedInTwoTone',],
  [Tv,'Tv',],
  [TvOff,'TvOff',],
  [TvOffOutlined,'TvOffOutlined',],
  [TvOffRounded,'TvOffRounded',],
  [TvOffSharp,'TvOffSharp',],
  [TvOffTwoTone,'TvOffTwoTone',],
  [TvOutlined,'TvOutlined',],
  [TvRounded,'TvRounded',],
  [TvSharp,'TvSharp',],
  [TvTwoTone,'TvTwoTone',],
  [Twitter,'Twitter',],
  [Unarchive,'Unarchive',],
  [UnarchiveOutlined,'UnarchiveOutlined',],
  [UnarchiveRounded,'UnarchiveRounded',],
  [UnarchiveSharp,'UnarchiveSharp',],
  [UnarchiveTwoTone,'UnarchiveTwoTone',],
  [Undo,'Undo',],
  [UndoOutlined,'UndoOutlined',],
  [UndoRounded,'UndoRounded',],
  [UndoSharp,'UndoSharp',],
  [UndoTwoTone,'UndoTwoTone',],
  [UnfoldLess,'UnfoldLess',],
  [UnfoldLessOutlined,'UnfoldLessOutlined',],
  [UnfoldLessRounded,'UnfoldLessRounded',],
  [UnfoldLessSharp,'UnfoldLessSharp',],
  [UnfoldLessTwoTone,'UnfoldLessTwoTone',],
  [UnfoldMore,'UnfoldMore',],
  [UnfoldMoreOutlined,'UnfoldMoreOutlined',],
  [UnfoldMoreRounded,'UnfoldMoreRounded',],
  [UnfoldMoreSharp,'UnfoldMoreSharp',],
  [UnfoldMoreTwoTone,'UnfoldMoreTwoTone',],
  [Unsubscribe,'Unsubscribe',],
  [UnsubscribeOutlined,'UnsubscribeOutlined',],
  [UnsubscribeRounded,'UnsubscribeRounded',],
  [UnsubscribeSharp,'UnsubscribeSharp',],
  [UnsubscribeTwoTone,'UnsubscribeTwoTone',],
  [Update,'Update',],
  [UpdateOutlined,'UpdateOutlined',],
  [UpdateRounded,'UpdateRounded',],
  [UpdateSharp,'UpdateSharp',],
  [UpdateTwoTone,'UpdateTwoTone',],
  [Usb,'Usb',],
  [UsbOutlined,'UsbOutlined',],
  [UsbRounded,'UsbRounded',],
  [UsbSharp,'UsbSharp',],
  [UsbTwoTone,'UsbTwoTone',],
  [VerifiedUser,'VerifiedUser',],
  [VerifiedUserOutlined,'VerifiedUserOutlined',],
  [VerifiedUserRounded,'VerifiedUserRounded',],
  [VerifiedUserSharp,'VerifiedUserSharp',],
  [VerifiedUserTwoTone,'VerifiedUserTwoTone',],
  [VerticalAlignBottom,'VerticalAlignBottom',],
  [VerticalAlignBottomOutlined,'VerticalAlignBottomOutlined',],
  [VerticalAlignBottomRounded,'VerticalAlignBottomRounded',],
  [VerticalAlignBottomSharp,'VerticalAlignBottomSharp',],
  [VerticalAlignBottomTwoTone,'VerticalAlignBottomTwoTone',],
  [VerticalAlignCenter,'VerticalAlignCenter',],
  [VerticalAlignCenterOutlined,'VerticalAlignCenterOutlined',],
  [VerticalAlignCenterRounded,'VerticalAlignCenterRounded',],
  [VerticalAlignCenterSharp,'VerticalAlignCenterSharp',],
  [VerticalAlignCenterTwoTone,'VerticalAlignCenterTwoTone',],
  [VerticalAlignTop,'VerticalAlignTop',],
  [VerticalAlignTopOutlined,'VerticalAlignTopOutlined',],
  [VerticalAlignTopRounded,'VerticalAlignTopRounded',],
  [VerticalAlignTopSharp,'VerticalAlignTopSharp',],
  [VerticalAlignTopTwoTone,'VerticalAlignTopTwoTone',],
  [VerticalSplit,'VerticalSplit',],
  [VerticalSplitOutlined,'VerticalSplitOutlined',],
  [VerticalSplitRounded,'VerticalSplitRounded',],
  [VerticalSplitSharp,'VerticalSplitSharp',],
  [VerticalSplitTwoTone,'VerticalSplitTwoTone',],
  [Vibration,'Vibration',],
  [VibrationOutlined,'VibrationOutlined',],
  [VibrationRounded,'VibrationRounded',],
  [VibrationSharp,'VibrationSharp',],
  [VibrationTwoTone,'VibrationTwoTone',],
  [VideoCall,'VideoCall',],
  [VideoCallOutlined,'VideoCallOutlined',],
  [VideoCallRounded,'VideoCallRounded',],
  [VideoCallSharp,'VideoCallSharp',],
  [VideoCallTwoTone,'VideoCallTwoTone',],
  [Videocam,'Videocam',],
  [VideocamOff,'VideocamOff',],
  [VideocamOffOutlined,'VideocamOffOutlined',],
  [VideocamOffRounded,'VideocamOffRounded',],
  [VideocamOffSharp,'VideocamOffSharp',],
  [VideocamOffTwoTone,'VideocamOffTwoTone',],
  [VideocamOutlined,'VideocamOutlined',],
  [VideocamRounded,'VideocamRounded',],
  [VideocamSharp,'VideocamSharp',],
  [VideocamTwoTone,'VideocamTwoTone',],
  [VideogameAsset,'VideogameAsset',],
  [VideogameAssetOutlined,'VideogameAssetOutlined',],
  [VideogameAssetRounded,'VideogameAssetRounded',],
  [VideogameAssetSharp,'VideogameAssetSharp',],
  [VideogameAssetTwoTone,'VideogameAssetTwoTone',],
  [VideoLabel,'VideoLabel',],
  [VideoLabelOutlined,'VideoLabelOutlined',],
  [VideoLabelRounded,'VideoLabelRounded',],
  [VideoLabelSharp,'VideoLabelSharp',],
  [VideoLabelTwoTone,'VideoLabelTwoTone',],
  [VideoLibrary,'VideoLibrary',],
  [VideoLibraryOutlined,'VideoLibraryOutlined',],
  [VideoLibraryRounded,'VideoLibraryRounded',],
  [VideoLibrarySharp,'VideoLibrarySharp',],
  [VideoLibraryTwoTone,'VideoLibraryTwoTone',],
  [ViewAgenda,'ViewAgenda',],
  [ViewAgendaOutlined,'ViewAgendaOutlined',],
  [ViewAgendaRounded,'ViewAgendaRounded',],
  [ViewAgendaSharp,'ViewAgendaSharp',],
  [ViewAgendaTwoTone,'ViewAgendaTwoTone',],
  [ViewArray,'ViewArray',],
  [ViewArrayOutlined,'ViewArrayOutlined',],
  [ViewArrayRounded,'ViewArrayRounded',],
  [ViewArraySharp,'ViewArraySharp',],
  [ViewArrayTwoTone,'ViewArrayTwoTone',],
  [ViewCarousel,'ViewCarousel',],
  [ViewCarouselOutlined,'ViewCarouselOutlined',],
  [ViewCarouselRounded,'ViewCarouselRounded',],
  [ViewCarouselSharp,'ViewCarouselSharp',],
  [ViewCarouselTwoTone,'ViewCarouselTwoTone',],
  [ViewColumn,'ViewColumn',],
  [ViewColumnOutlined,'ViewColumnOutlined',],
  [ViewColumnRounded,'ViewColumnRounded',],
  [ViewColumnSharp,'ViewColumnSharp',],
  [ViewColumnTwoTone,'ViewColumnTwoTone',],
  [ViewComfy,'ViewComfy',],
  [ViewComfyOutlined,'ViewComfyOutlined',],
  [ViewComfyRounded,'ViewComfyRounded',],
  [ViewComfySharp,'ViewComfySharp',],
  [ViewComfyTwoTone,'ViewComfyTwoTone',],
  [ViewCompact,'ViewCompact',],
  [ViewCompactOutlined,'ViewCompactOutlined',],
  [ViewCompactRounded,'ViewCompactRounded',],
  [ViewCompactSharp,'ViewCompactSharp',],
  [ViewCompactTwoTone,'ViewCompactTwoTone',],
  [ViewDay,'ViewDay',],
  [ViewDayOutlined,'ViewDayOutlined',],
  [ViewDayRounded,'ViewDayRounded',],
  [ViewDaySharp,'ViewDaySharp',],
  [ViewDayTwoTone,'ViewDayTwoTone',],
  [ViewHeadline,'ViewHeadline',],
  [ViewHeadlineOutlined,'ViewHeadlineOutlined',],
  [ViewHeadlineRounded,'ViewHeadlineRounded',],
  [ViewHeadlineSharp,'ViewHeadlineSharp',],
  [ViewHeadlineTwoTone,'ViewHeadlineTwoTone',],
  [ViewList,'ViewList',],
  [ViewListOutlined,'ViewListOutlined',],
  [ViewListRounded,'ViewListRounded',],
  [ViewListSharp,'ViewListSharp',],
  [ViewListTwoTone,'ViewListTwoTone',],
  [ViewModule,'ViewModule',],
  [ViewModuleOutlined,'ViewModuleOutlined',],
  [ViewModuleRounded,'ViewModuleRounded',],
  [ViewModuleSharp,'ViewModuleSharp',],
  [ViewModuleTwoTone,'ViewModuleTwoTone',],
  [ViewQuilt,'ViewQuilt',],
  [ViewQuiltOutlined,'ViewQuiltOutlined',],
  [ViewQuiltRounded,'ViewQuiltRounded',],
  [ViewQuiltSharp,'ViewQuiltSharp',],
  [ViewQuiltTwoTone,'ViewQuiltTwoTone',],
  [ViewStream,'ViewStream',],
  [ViewStreamOutlined,'ViewStreamOutlined',],
  [ViewStreamRounded,'ViewStreamRounded',],
  [ViewStreamSharp,'ViewStreamSharp',],
  [ViewStreamTwoTone,'ViewStreamTwoTone',],
  [ViewWeek,'ViewWeek',],
  [ViewWeekOutlined,'ViewWeekOutlined',],
  [ViewWeekRounded,'ViewWeekRounded',],
  [ViewWeekSharp,'ViewWeekSharp',],
  [ViewWeekTwoTone,'ViewWeekTwoTone',],
  [Vignette,'Vignette',],
  [VignetteOutlined,'VignetteOutlined',],
  [VignetteRounded,'VignetteRounded',],
  [VignetteSharp,'VignetteSharp',],
  [VignetteTwoTone,'VignetteTwoTone',],
  [Visibility,'Visibility',],
  [VisibilityOff,'VisibilityOff',],
  [VisibilityOffOutlined,'VisibilityOffOutlined',],
  [VisibilityOffRounded,'VisibilityOffRounded',],
  [VisibilityOffSharp,'VisibilityOffSharp',],
  [VisibilityOffTwoTone,'VisibilityOffTwoTone',],
  [VisibilityOutlined,'VisibilityOutlined',],
  [VisibilityRounded,'VisibilityRounded',],
  [VisibilitySharp,'VisibilitySharp',],
  [VisibilityTwoTone,'VisibilityTwoTone',],
  [VoiceChat,'VoiceChat',],
  [VoiceChatOutlined,'VoiceChatOutlined',],
  [VoiceChatRounded,'VoiceChatRounded',],
  [VoiceChatSharp,'VoiceChatSharp',],
  [VoiceChatTwoTone,'VoiceChatTwoTone',],
  [Voicemail,'Voicemail',],
  [VoicemailOutlined,'VoicemailOutlined',],
  [VoicemailRounded,'VoicemailRounded',],
  [VoicemailSharp,'VoicemailSharp',],
  [VoicemailTwoTone,'VoicemailTwoTone',],
  [VoiceOverOff,'VoiceOverOff',],
  [VoiceOverOffOutlined,'VoiceOverOffOutlined',],
  [VoiceOverOffRounded,'VoiceOverOffRounded',],
  [VoiceOverOffSharp,'VoiceOverOffSharp',],
  [VoiceOverOffTwoTone,'VoiceOverOffTwoTone',],
  [VolumeDown,'VolumeDown',],
  [VolumeDownOutlined,'VolumeDownOutlined',],
  [VolumeDownRounded,'VolumeDownRounded',],
  [VolumeDownSharp,'VolumeDownSharp',],
  [VolumeDownTwoTone,'VolumeDownTwoTone',],
  [VolumeMute,'VolumeMute',],
  [VolumeMuteOutlined,'VolumeMuteOutlined',],
  [VolumeMuteRounded,'VolumeMuteRounded',],
  [VolumeMuteSharp,'VolumeMuteSharp',],
  [VolumeMuteTwoTone,'VolumeMuteTwoTone',],
  [VolumeOff,'VolumeOff',],
  [VolumeOffOutlined,'VolumeOffOutlined',],
  [VolumeOffRounded,'VolumeOffRounded',],
  [VolumeOffSharp,'VolumeOffSharp',],
  [VolumeOffTwoTone,'VolumeOffTwoTone',],
  [VolumeUp,'VolumeUp',],
  [VolumeUpOutlined,'VolumeUpOutlined',],
  [VolumeUpRounded,'VolumeUpRounded',],
  [VolumeUpSharp,'VolumeUpSharp',],
  [VolumeUpTwoTone,'VolumeUpTwoTone',],
  [VpnKey,'VpnKey',],
  [VpnKeyOutlined,'VpnKeyOutlined',],
  [VpnKeyRounded,'VpnKeyRounded',],
  [VpnKeySharp,'VpnKeySharp',],
  [VpnKeyTwoTone,'VpnKeyTwoTone',],
  [VpnLock,'VpnLock',],
  [VpnLockOutlined,'VpnLockOutlined',],
  [VpnLockRounded,'VpnLockRounded',],
  [VpnLockSharp,'VpnLockSharp',],
  [VpnLockTwoTone,'VpnLockTwoTone',],
  [Wallpaper,'Wallpaper',],
  [WallpaperOutlined,'WallpaperOutlined',],
  [WallpaperRounded,'WallpaperRounded',],
  [WallpaperSharp,'WallpaperSharp',],
  [WallpaperTwoTone,'WallpaperTwoTone',],
  [Warning,'Warning',],
  [WarningOutlined,'WarningOutlined',],
  [WarningRounded,'WarningRounded',],
  [WarningSharp,'WarningSharp',],
  [WarningTwoTone,'WarningTwoTone',],
  [Watch,'Watch',],
  [WatchLater,'WatchLater',],
  [WatchLaterOutlined,'WatchLaterOutlined',],
  [WatchLaterRounded,'WatchLaterRounded',],
  [WatchLaterSharp,'WatchLaterSharp',],
  [WatchLaterTwoTone,'WatchLaterTwoTone',],
  [WatchOutlined,'WatchOutlined',],
  [WatchRounded,'WatchRounded',],
  [WatchSharp,'WatchSharp',],
  [WatchTwoTone,'WatchTwoTone',],
  [Waves,'Waves',],
  [WavesOutlined,'WavesOutlined',],
  [WavesRounded,'WavesRounded',],
  [WavesSharp,'WavesSharp',],
  [WavesTwoTone,'WavesTwoTone',],
  [WbAuto,'WbAuto',],
  [WbAutoOutlined,'WbAutoOutlined',],
  [WbAutoRounded,'WbAutoRounded',],
  [WbAutoSharp,'WbAutoSharp',],
  [WbAutoTwoTone,'WbAutoTwoTone',],
  [WbCloudy,'WbCloudy',],
  [WbCloudyOutlined,'WbCloudyOutlined',],
  [WbCloudyRounded,'WbCloudyRounded',],
  [WbCloudySharp,'WbCloudySharp',],
  [WbCloudyTwoTone,'WbCloudyTwoTone',],
  [WbIncandescent,'WbIncandescent',],
  [WbIncandescentOutlined,'WbIncandescentOutlined',],
  [WbIncandescentRounded,'WbIncandescentRounded',],
  [WbIncandescentSharp,'WbIncandescentSharp',],
  [WbIncandescentTwoTone,'WbIncandescentTwoTone',],
  [WbIridescent,'WbIridescent',],
  [WbIridescentOutlined,'WbIridescentOutlined',],
  [WbIridescentRounded,'WbIridescentRounded',],
  [WbIridescentSharp,'WbIridescentSharp',],
  [WbIridescentTwoTone,'WbIridescentTwoTone',],
  [WbSunny,'WbSunny',],
  [WbSunnyOutlined,'WbSunnyOutlined',],
  [WbSunnyRounded,'WbSunnyRounded',],
  [WbSunnySharp,'WbSunnySharp',],
  [WbSunnyTwoTone,'WbSunnyTwoTone',],
  [Wc,'Wc',],
  [WcOutlined,'WcOutlined',],
  [WcRounded,'WcRounded',],
  [WcSharp,'WcSharp',],
  [WcTwoTone,'WcTwoTone',],
  [Web,'Web',],
  [WebAsset,'WebAsset',],
  [WebAssetOutlined,'WebAssetOutlined',],
  [WebAssetRounded,'WebAssetRounded',],
  [WebAssetSharp,'WebAssetSharp',],
  [WebAssetTwoTone,'WebAssetTwoTone',],
  [WebOutlined,'WebOutlined',],
  [WebRounded,'WebRounded',],
  [WebSharp,'WebSharp',],
  [WebTwoTone,'WebTwoTone',],
  [Weekend,'Weekend',],
  [WeekendOutlined,'WeekendOutlined',],
  [WeekendRounded,'WeekendRounded',],
  [WeekendSharp,'WeekendSharp',],
  [WeekendTwoTone,'WeekendTwoTone',],
  [WhatsApp,'WhatsApp',],
  [Whatshot,'Whatshot',],
  [WhatshotOutlined,'WhatshotOutlined',],
  [WhatshotRounded,'WhatshotRounded',],
  [WhatshotSharp,'WhatshotSharp',],
  [WhatshotTwoTone,'WhatshotTwoTone',],
  [WhereToVote,'WhereToVote',],
  [WhereToVoteOutlined,'WhereToVoteOutlined',],
  [WhereToVoteRounded,'WhereToVoteRounded',],
  [WhereToVoteSharp,'WhereToVoteSharp',],
  [WhereToVoteTwoTone,'WhereToVoteTwoTone',],
  [Widgets,'Widgets',],
  [WidgetsOutlined,'WidgetsOutlined',],
  [WidgetsRounded,'WidgetsRounded',],
  [WidgetsSharp,'WidgetsSharp',],
  [WidgetsTwoTone,'WidgetsTwoTone',],
  [Wifi,'Wifi',],
  [WifiLock,'WifiLock',],
  [WifiLockOutlined,'WifiLockOutlined',],
  [WifiLockRounded,'WifiLockRounded',],
  [WifiLockSharp,'WifiLockSharp',],
  [WifiLockTwoTone,'WifiLockTwoTone',],
  [WifiOff,'WifiOff',],
  [WifiOffOutlined,'WifiOffOutlined',],
  [WifiOffRounded,'WifiOffRounded',],
  [WifiOffSharp,'WifiOffSharp',],
  [WifiOffTwoTone,'WifiOffTwoTone',],
  [WifiOutlined,'WifiOutlined',],
  [WifiRounded,'WifiRounded',],
  [WifiSharp,'WifiSharp',],
  [WifiTethering,'WifiTethering',],
  [WifiTetheringOutlined,'WifiTetheringOutlined',],
  [WifiTetheringRounded,'WifiTetheringRounded',],
  [WifiTetheringSharp,'WifiTetheringSharp',],
  [WifiTetheringTwoTone,'WifiTetheringTwoTone',],
  [WifiTwoTone,'WifiTwoTone',],
  [Work,'Work',],
  [WorkOff,'WorkOff',],
  [WorkOffOutlined,'WorkOffOutlined',],
  [WorkOffRounded,'WorkOffRounded',],
  [WorkOffSharp,'WorkOffSharp',],
  [WorkOffTwoTone,'WorkOffTwoTone',],
  [WorkOutline,'WorkOutline',],
  [WorkOutlined,'WorkOutlined',],
  [WorkOutlineOutlined,'WorkOutlineOutlined',],
  [WorkOutlineRounded,'WorkOutlineRounded',],
  [WorkOutlineSharp,'WorkOutlineSharp',],
  [WorkOutlineTwoTone,'WorkOutlineTwoTone',],
  [WorkRounded,'WorkRounded',],
  [WorkSharp,'WorkSharp',],
  [WorkTwoTone,'WorkTwoTone',],
  [WrapText,'WrapText',],
  [WrapTextOutlined,'WrapTextOutlined',],
  [WrapTextRounded,'WrapTextRounded',],
  [WrapTextSharp,'WrapTextSharp',],
  [WrapTextTwoTone,'WrapTextTwoTone',],
  [YouTube,'YouTube',],
  [YoutubeSearchedFor,'YoutubeSearchedFor',],
  [YoutubeSearchedForOutlined,'YoutubeSearchedForOutlined',],
  [YoutubeSearchedForRounded,'YoutubeSearchedForRounded',],
  [YoutubeSearchedForSharp,'YoutubeSearchedForSharp',],
  [YoutubeSearchedForTwoTone,'YoutubeSearchedForTwoTone',],
  [ZoomIn,'ZoomIn',],
  [ZoomInOutlined,'ZoomInOutlined',],
  [ZoomInRounded,'ZoomInRounded',],
  [ZoomInSharp,'ZoomInSharp',],
  [ZoomInTwoTone,'ZoomInTwoTone',],
  [ZoomOut,'ZoomOut',],
  [ZoomOutMap,'ZoomOutMap',],
  [ZoomOutMapOutlined,'ZoomOutMapOutlined',],
  [ZoomOutMapRounded,'ZoomOutMapRounded',],
  [ZoomOutMapSharp,'ZoomOutMapSharp',],
  [ZoomOutMapTwoTone,'ZoomOutMapTwoTone',],
  [ZoomOutOutlined,'ZoomOutOutlined',],
  [ZoomOutRounded,'ZoomOutRounded',],
  [ZoomOutSharp,'ZoomOutSharp',],
  [ZoomOutTwoTone,'ZoomOutTwoTone',],
];

export default icons;
