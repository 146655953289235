const LayerLabelStyle = theme => ({
  layerLabelRoot: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    cursor: 'pointer',

    //TODO: FIX THIS
    '&:hover': {
      '& + $menuButton': {
        display: "block"
      }
    },
  },

  isDragging: {
    opacity: '0.8'
  },

  layerLabelInner: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    padding: '1px 4px',
    //borderRadius: '5px',

    marginLeft: ({level}) =>
      `${level * 16}px`,
  },

  title: {
    paddingRight: '5px'
  },

  selectedLayerLabel: {
    backgroundColor: theme.palette.editor.selected,
    borderRadius: '5px'
  },

  iconNodeContainer: {
    height: '28px',
    width: '28px',
    marginRight: '0px',
    marginLeft: '4px',
    position: 'relative',
    top: '3px',
  },

  arrowContainer: {
    position: 'relative',
    top: '3px',
    marginRight: 'auto',
    cursor: 'pointer'
  },

  menuButton: {
    visibility: "hidden",
  },

  selectedMenuButton: {
    visibility: "visible",
  },

  hoveredContainer: {
    backgroundColor: theme.palette.gridLines.main
  },

  hoveredBetweenContainers: {
    borderColor: `solid 1px ${theme.palette.gridLines.main}`
  },

  statusIcon: {
    width: '19px',
    height: '19px',
    margin: '1px',
    cursor: 'pointer',
    transition: '0.3s',
    position: 'relative',
    top: '3.5px',

    '&:hover': {
      opacity: '.75'
    }
  },

  notVisibleIcon: {

  }
});

export default LayerLabelStyle;
