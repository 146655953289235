import Typography from "@material-ui/core/Typography";
import {useEditorDialog} from "hooks/useEditorDialog";
import React from 'react';

const HowToMoveText = () => {

  const helpDialog = useEditorDialog('help')

  const handleContainerAlignment = () => {
    helpDialog.appendDialogData({
      category: 'container',
      resetAccordion: true
    });
    helpDialog.openDialog();
  }

  return (
    <>
      <Typography>
        To move your text around, simply drag it on your page and position it inside of a container utilizing the alignment widget.
        To learn more about aligning content inside of containers, click <span onClick={handleContainerAlignment} style={{color: '#8A56FF', cursor: 'pointer'}}>here</span>.
      </Typography>
    {/*  TODO: CHANGE LINK TO ALIGNEMNT SETTING HELP OPTION ONCE CREATED*/}
    </>
  );
};

HowToMoveText.propTypes = {

};

export default HowToMoveText;