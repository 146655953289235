import {Tooltip, withStyles} from '@material-ui/core';
import InfoIcon from '@material-ui/icons/Info';
import PropTypes from 'prop-types';
import React from 'react';
import InformationIconStyle from './InformationIconStyle';

const InformationIcon = props => {
  const {
    classes,
    tooltipTitle
  } = props;

  return (
    <Tooltip title={tooltipTitle}>
      <InfoIcon className={classes.info}/>
    </Tooltip>
  );
};

InformationIcon.propTypes = {
  classes: PropTypes.object,
  tooltipTitle: PropTypes.string.isRequired
};

export default withStyles(InformationIconStyle)(InformationIcon);