import {Button, Divider, Drawer, IconButton, List, withStyles} from "@material-ui/core";
import MenuIcon from '@material-ui/icons/Menu';
import MobileUserMenu from "components/MobileUserMenu/MobileUserMenu";
import NavBarStyle from "components/NavBar/NavBarStyle";
import NimbusLogo from "components/NimbusLogo/NimbusLogo";
import UserMenu from "components/UserMenu/UserMenu";
import {UserContext} from "context/UserContext";
import PropTypes from 'prop-types';
import React, {useContext, useEffect, useRef, useState} from 'react';
import {NavLink, useHistory, useLocation} from "react-router-dom";

const NavBar = props => {
  const {classes} = props;
  const userContextData = useContext(UserContext)

  const [open, setOpen] = useState(false);

  const navbar = useRef(null)

  let history = useHistory()

  const internalLinks = [
    {
      text: 'Home',
      to: '/',
    },
    {
      text: 'Editor',
      to: '/templates'
    },
    {
      text: 'Dashboard',
      to: userContextData.userData ? '/dashboard' : '/login'
    },
    {
      text: 'Pricing',
      to: '/pricing',
    },
    {
      text: 'Blog',
      to: '/blog',
    },
    {
      text: 'Contact',
      to: '/contact',
    },
  ];

  const handleScroll = () => {
    if (!navbar.current) return

    if (window.scrollY > 100) {
      navbar.current.style.backgroundColor="rgba(0,0,0,0.8)"
    } else {
      navbar.current.style.backgroundColor="rgba(0,0,0,0)"
    }
  }

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  const logojsx =
    <NavLink to={'/'} className={classes.logoContainer}>
     <NimbusLogo/>
    </NavLink>

  const links = internalLinks.map((data, index) => (
    <NavLink
      key={index}
      className={classes.internalLinks}
      {...data}
    >
      {data.text}
    </NavLink>
  ))

  const mobileLinks = internalLinks.map((data, index) => (
    <div
      onClick={() => {
        history.push(data.to);
        setOpen(false);
      }}
      className={classes.internalLinks}
    >
      {data.text}
    </div>
  ))

  const onClose = () => {
    setOpen(false)
  }

  const onOpen = () => {
    setOpen(true)
  }

  return (
    <div className={classes.root} ref={navbar}>
      <div className={classes.desktop}>
        {logojsx}
        <div className={classes.internalLinksContainer}>
          {links}
        </div>

        {userContextData.userData ? (
          <UserMenu/>
        ) : (
          <div>
            <NavLink
              className={classes.loginLink}
              to={'/login'}
            >
              <Button
                variant={'outlined'}
                className={classes.login}
              >
                Login
              </Button>
            </NavLink>
          </div>
        )}
      </div>

      <div className={classes.mobile}>
        <IconButton
          className={classes.drawerButton}
          onClick={onOpen}
        >
          <MenuIcon/>
        </IconButton>
        {logojsx}
      </div>

      <Drawer
        open={open}
        onClose={onClose}
      >
        <List
          className={classes.mobileList}
        >
          {mobileLinks}
          <Divider className={classes.divider}/>

          {userContextData.userData ? (
            <MobileUserMenu/>
          ) : (
            <NavLink
              className={classes.loginLink}
              to={'/login'}
            >
              <Button
                variant={'contained'}
                className={classes.loginMobile}
              >
                Login
              </Button>
            </NavLink>
          )}
        </List>
      </Drawer>
    </div>
  );
};

NavBar.propTypes = {
  classes: PropTypes.object,
};

export default withStyles(NavBarStyle)(NavBar);
