import {Popper, withStyles} from '@material-ui/core';
import MenuItem from "@material-ui/core/MenuItem";
import cn from "classnames";
import PopperDropDownMenuStyle from 'components/PopperDropDownMenu/PopperDropDownMenuStyle';
import PropTypes from 'prop-types';
import React from 'react';

const PopperDropDownMenu = props => {
  const {
    classes,
    actions,
    ...rest
  } = props;

  return (
    <Popper
      {...rest}
    >
      {actions.map((data, index) => (
        <MenuItem
          className={cn(classes.menuItem, !data.disableHighlight && classes.highlight)}
          key={data.title}
          onClick={data.action ? () => data.action() : () => {}}
        >
          {data.title}
        </MenuItem>
      ))}
    </Popper>
  );
};

PopperDropDownMenu.propTypes = {
  classes: PropTypes.object,
  actions: PropTypes.array.isRequired
};

export default withStyles(PopperDropDownMenuStyle)(PopperDropDownMenu);
