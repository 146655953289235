import {withStyles} from "@material-ui/core";
import DraggableWrapper from "editor/draggables/DraggableWrapper/DraggableWrapper";
import formatStyles from "editor/draggables/formatStyles";
import MediaDraggableStyle from "editor/draggables/MediaDraggable/MediaDraggableStyle";
import {EditorContext} from "editor/Editor";
import {useEditorDialog} from "hooks/useEditorDialog";
import PropTypes from "prop-types";
import React, {useContext, useEffect, useMemo, useRef, useState} from 'react';

const MediaDraggable = props => {
  const {
    classes,
    style,
    editorId,
    selectedMedia,
    draggableProps,
    absolutePositioning,

    // universally provided to all assets
    isChildElem
  } = props;

  const editorData = useContext(EditorContext);
  const {editorReducerState, dispatch} = editorData;
  const editorActions = editorReducerState.editorActions;
  const [hasOpened, setHasOpened] = useState(false);
  const [renderedMedia, setRenderedMedia] = useState(null);
  const mediaDraggableDialog = useEditorDialog('mediaDraggable');

  const selfRef = useRef(null);

  const {draggableStyles, elementStyles} =
    useMemo(() => formatStyles(style), [style]);

  useEffect(() => {
    if(mediaDraggableDialog && !hasOpened && !selectedMedia) {
      handleOpen()
      setHasOpened(true)
    }
  }, [mediaDraggableDialog]);

  // TODO: delete this
  useEffect(() => {
    console.log('element styles changed media', {draggableStyles, elementStyles});
  }, [elementStyles, draggableStyles])

  useEffect(() => {
    if(selectedMedia) {
      setRenderedMedia(
        <img
          src={selectedMedia}
          onDragStart={preventDragHandler}
          style={{
            height: '100%',
            width: '100%',
            cursor: 'pointer'
          }}
        />
      );
    }
  }, [selectedMedia])

  useEffect(() => {
    if(renderedMedia) {
      let newBounds = editorActions.measureElementBounds(editorId);

      let naturalWidth = renderedMedia.naturalWidth;
      let naturalHeight = renderedMedia.naturalHeight;

      console.log('new bounds', {newBounds, naturalWidth, naturalHeight});

      dispatch({
        type: 'updateElementSelectedBounds',
        payload: {
          bounds: newBounds
        }
      });

      dispatch({
        type: 'updateAssetBounds',
        payload: {
          editorId,
          heightHasChanged: true,
          widthHasChanged: true,
          bounds: {
            ...newBounds,
          },
        }
      })
    }
  }, [renderedMedia])

  const handleOpen = () => {
    mediaDraggableDialog.openDialog();
    mediaDraggableDialog.setDialogData({editorId});
  }

  const preventDragHandler = (e) => {
    // HTML 5 adds a weird drag and drop system for images that is interfering with our system
    // disable it using this
    e.preventDefault()
  }

  return (
    <>
      <DraggableWrapper
        resizeable
        editorId={props.editorId}
        style={{
          width: '300px',
          height: '300px',
          ...draggableStyles,
          ...draggableProps?.style,
          ...elementStyles,
          position: absolutePositioning ? 'absolute' : 'static'
        }}
        isChildElem={isChildElem}
      >
        <div
          style={{
            width: '100%',
            height: '100%',
            ...elementStyles
        }}
          data-editorid={editorId}
          data-asset={true}
          className={classes.root}
          ref={selfRef}
        >
          {renderedMedia}

          {/*<img*/}
          {/*  src={selectedMedia}*/}
          {/*  onDragStart={preventDragHandler}*/}
          {/*  className={classes.selectedImage}*/}
          {/*/>*/}
        </div>
      </DraggableWrapper>
    </>
  );
};

MediaDraggable.editorSettings = [
  {
    type: 'prop',
    key: 'breakpoints',
    tooltip: 'Make an asset visible at the chosen breakpoints: desktop, tablet, and/or mobile.',
    default: {
      visibility: {
        desktop: true,
        tablet: true,
        mobile: true
      },
      styleLink: {
        tablet: true,
        mobile: true,
      }
    },
    label: null,
    inputType: 'breakpoint'
  },

  {
    type: 'layout',
    key: 'twoCol',
    tooltip: 'Accepted units: px, %, em, rem, vw, vh',
    label: 'Position/Size',
    elements: [
      {type: 'style', key: 'left', default: '0', label: 'X'},
      {type: 'style', key: 'top', default: '0', label: 'Y'},
      {type: 'style', key: 'width', default: '50px', label: 'W'},
      {type: 'style', key: 'height', default: '50px', label: 'H'},
    ]
  },

  {
    type: 'prop',
    key: 'absolutePositioning',
    tooltip: 'If this is checked, your asset\'s CSS will be written with "position: absolute"',
    label: 'Absolute Positioning',
    default: false,
    inputType: 'boolean'
  },

  {type: 'prop', key: 'alt', default: '', label: 'Alt Text'},

  {
    type: 'collection',
    label: 'Border',
    children: [
      {type: 'style', key: 'border', default: 'none', label: null, inputType: 'border'},
      {type: 'style', key: 'borderRadius', default: '0px', label: 'Border Radius'},

      {
        type: 'collection',
        label: 'Detailed Border',
        children: [
          {type: 'style', key: 'borderTop', default: 'none', label: 'Top', inputType: 'border'},
          {type: 'style', key: 'borderBottom', default: 'none', label: 'Bottom', inputType: 'border'},
          {type: 'style', key: 'borderLeft', default: 'none', label: 'Left', inputType: 'border'},
          {type: 'style', key: 'borderRight', default: 'none', label: 'Right', inputType: 'border'},
        ]
      }
    ]
  },
  {
    type: 'collection',
    label: 'Padding',
    children: [
      {type: 'style', key: 'paddingTop', default: '0px', label: 'Top', horizontal: false},
      {type: 'style', key: 'paddingBottom', default: '0px', label: 'Bottom', horizontal: false},
      {type: 'style', key: 'paddingLeft', default: '0px', label: 'Left', horizontal: false},
      {type: 'style', key: 'paddingRight', default: '0px', label: 'Right', horizontal: false},
    ]
  },

  {
    type: 'collection',
    label: 'Margin',
    children: [
      {type: 'style', key: 'marginTop', default: '0px', label: 'Top', horizontal: false},
      {type: 'style', key: 'marginBottom', default: '0px', label: 'Bottom', horizontal: false},
      {type: 'style', key: 'marginLeft', default: '0px', label: 'Left', horizontal: false},
      {type: 'style', key: 'marginRight', default: '0px', label: 'Right', horizontal: false},
    ]
  },
  {
    type: 'collection',
    label: 'Effects',
    children: [
      {type: 'style', key: 'opacity', default: '1', label: 'Opacity'},
      {type: 'style', key: 'boxShadow', default: 'none', label: 'Box Shadow', inputType: 'boxShadow'},
      // TODO: we need to fix this on the translation
      //{type: 'prop', key: 'hoverColor', default: null, label: 'Hover Color', inputType: 'color'},
      {type: 'prop', key: 'cursor', default: null, label: null, inputType: 'cursor'},
      //{type: 'prop', key: 'link', default: null, label: null, inputType: 'link'},
      {type: 'style', key: 'transition', default: 'none', label: 'Transition', inputType: 'transition'},
      {type: 'style', key: 'transform', default: 'none', label: 'Rotation', inputType: 'rotation'},
    ]
  },
];

MediaDraggable.helpQuestionData = {
  category: 'media',
  helpAssetHeader: {
    assetType: 'Media',
    assetShortDescription: 'Upload your own image or choose from our curated collection to customize both its visual and functional aspects to your preference.'
  }
}

MediaDraggable.serializedName = 'MediaDraggable';

MediaDraggable.propTypes = {
  classes: PropTypes.object,
  style: PropTypes.object.isRequired,
  draggableProps: PropTypes.object,
  editorId: PropTypes.string.isRequired,
  isChildElem: PropTypes.object,
  selectedMedia: PropTypes.object
};

export default withStyles(MediaDraggableStyle)(MediaDraggable);
