const StyledTextFieldStyle = theme => ({
  formControl: {
    display: 'flex',
    flexDirection: ({horizontal=false}) =>
      horizontal ? 'row' : 'column',
    width: '100%',
    height: 'auto',
    borderRadius: '6px',
    justifyContent: 'flex-start',
    alignItems: ({horizontal=false}) =>
      horizontal ? 'center' : 'flex-start',
    marginLeft: props => props.label ? '0' : '8px',

    '& > .MuiTypography-body1': {
      display: props => props.label ? 'block' : 'none'
    },

    '&:first-of-type': {
      marginLeft: '0'
    }
  },

  styledTextFieldRoot: {
    borderRadius: '6px',
    width: props => props.fullWidth ? '100%' : 'auto',

    '& .MuiSelect-icon': {
      color: "white",
    },

  },

  notchedOutline: {
    display: 'none'
  },

  input: {
    padding: '4px 9px',
    boxSizing: 'border-box',
    background: 'rgba(43, 43, 55, 0.6)',
    height: '27px',
    fontSize: '14px',
    borderRadius: '6px',
    width: '100%'
  },

  label: {
    marginRight: '10px',
    minWidth: '10px',
    fontSize: '15px',
    marginBottom: ({horizontal}) =>
      horizontal ? '0' : '10px'
  }
});

export default StyledTextFieldStyle;
