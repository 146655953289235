import ToolboxIcon from "editor/components/ToolboxIcon/ToolboxIcon";
import {EditorContext} from "editor/Editor";
import React, {useContext, useEffect, useState} from 'react';

import {
  Typography,
  withStyles
} from '@material-ui/core';
import PropTypes from 'prop-types';
import {User} from "services";
import {handleNetworkError} from "utils/utils";

import EditorSavedComponentsStyle from './EditorSavedComponentsStyle';

const EditorSavedComponents = props => {
  const {
    classes,
    publicComponents,

    // because this makes a network request on mount, we want to just hide it instead of unmounting it
    show
  } = props;

  const {editorReducerState, dispatch} = useContext(EditorContext);
  const savedCategories = editorReducerState.editorState.savedComponentCategories;

  const [categories, setCategories] = useState([]);

  useEffect(() => {
    console.log('public components', publicComponents);

    if(publicComponents) {
      User.listPublicComponentCategories().then(response => {
        setCategories(response.data);

        dispatch({
          type: 'setPublicComponents',
          payload: {
            componentCategories: response.data
          }
        })
      }).catch(handleNetworkError)
    }
    else {
      setCategories(savedCategories);
    }
  }, [publicComponents, savedCategories])


  return (
    <div className={classes.root} style={{display: show ? 'flex' : 'none'}}>
      <Typography variant={'h5'} className={classes.title}>
        {publicComponents ? 'Public Components' : 'Your Components'}
      </Typography>

      <div className={classes.innerContainer}>
        {categories && Object.keys(categories).map((key, index) => {
          let category = {
            name: key,
            variants: categories[key]
          }

          console.log('categories', {categories, publicComponents})

          if (category.variants.length === 0) {
            return null
          }

          return (
            <ToolboxIcon
              component={category}
              className={classes.componentRoot}
              key={category.id}
              publicComponents={publicComponents}
            />
          )
        })}
      </div>
    </div>
  );
};

EditorSavedComponents.propTypes = {
  classes: PropTypes.object,
  publicComponents: PropTypes.bool,
  show: PropTypes.bool.isRequired
};

export default withStyles(EditorSavedComponentsStyle)(EditorSavedComponents);
