const LoadingStyle = {
 root: {
    position: 'relative',
    width: '100%',
    height: '100%',
    zIndex: '10000',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    background: props => props.background === 'dark' ? '#0F0F10' : 'white'
  },
};

export default LoadingStyle;
