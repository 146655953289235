const FitToTextInputStyle = theme => ({
  root: {
    width: '100%',
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: 'flex-start',
  },

  buttonContainer: {
    marginTop: '4px'
  }
});

export default FitToTextInputStyle;
