import {FormDraggableDialogContext} from "editor/context/FormDraggableDialogContext";
import React, {useContext} from 'react';

import {
  Button,
  DialogContent, Menu, MenuItem, MenuList,
  Typography,
  withStyles
} from '@material-ui/core';
import PropTypes from 'prop-types';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';

import FormDraggableAddInputStyle from 'editor/components/dialogs/FormDraggableDialog/FormDraggableDialogAddInput/FormDraggableAddInputStyle';

const FormDraggableAddInput = props => {
  const {
    classes,
  } = props;

  const {
    formDraggableReducerState,
    formDraggableDispatch
  } = useContext(FormDraggableDialogContext);

  const {
    inputTypes
  } = formDraggableReducerState;

  const goBack = () => {
    formDraggableDispatch({
      type: 'changePage',
      payload: {
        page: 'main'
      }
    })
  }

  const handleInputClick = (inputData) => {
    formDraggableDispatch({
      type: 'setInputBeingAdded',
      payload: {
        component: inputData.component
      }
    })

    formDraggableDispatch({
      type: 'changePage',
      payload: {
        page: 'inputDetails',
      }
    })
  }

  return (
    <div className={classes.root}>
      <DialogContent>
        <div className={classes.backButtonContainer}>
          <Button onClick={goBack}>
            <ChevronLeftIcon className={classes.backIcon}/>
            Back
          </Button>
        </div>

        <Typography variant={'subtitle1'} className={classes.formDescription}>
          What input would you like to add?
        </Typography>

        <div className={classes.content}>
          <MenuList className={classes.menu}>
            {inputTypes.map((data, index) => (
              <MenuItem key={data.label} onClick={() => handleInputClick(data)}>
                {data.label}
              </MenuItem>
            ))}
          </MenuList>
        </div>
      </DialogContent>
    </div>
  );
};

FormDraggableAddInput.propTypes = {
  classes: PropTypes.object,
};

export default withStyles(FormDraggableAddInputStyle)(FormDraggableAddInput);
