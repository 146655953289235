const CanvasSizeBannerStyle = theme => ({
  root: {
    background: '#1D2328',
    width: '200px',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    marginRight: '8px',
    position: 'fixed',
    bottom: '70px',
    right: '280px',
    transform: 'translateX(-50%)',
    zIndex: '1000',
    borderRadius: '8px',
    boxShadow: '5px 5px 10px rgba(0,0,0,0.2)',
  },

  icon: {
    color: theme.palette.common.white,
    transition: "0.3s"
  },

  active: {
    color: theme.palette.secondary.main
  },

  iconButton: {
    '&:hover': {
      background: "rgba(255,255,255,0.1)",
      transition: "0.3s"
    }
  },

  inputContainer: {
    width: "100%",
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
});

export default CanvasSizeBannerStyle;
