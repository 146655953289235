import {withStyles} from '@material-ui/core';
import LayerLabel from "editor/components/LayerLabel/LayerLabel";
import EditorLayersProvider from "editor/context/EditorLayersContext";
import {EditorContext} from "editor/Editor";
import PropTypes from 'prop-types';
import React, {useContext, useEffect, useMemo, useRef, useState} from 'react';
import {generateId} from "utils/utils";

import EditorLayersStyle from './EditorLayersStyle';

const EditorLayers = props => {
  const { classes } = props;
  const editorData = useContext(EditorContext);
  const { elementTree } = editorData.editorReducerState?.editorState;
  const [renderedTree, setRenderedTree] = useState(null);
  const branchRefs = useRef([]);

  useEffect(() => {
    let renderedBranches = [];

    // iterate through each child of the editor-root
    for(let topLevelBranch of elementTree.value["editor-root"].children) {
      renderedBranches.push(renderNextLevel(topLevelBranch, 0));
    }

    setRenderedTree(renderedBranches);
  }, [elementTree])

  const renderNextLevel = (treeBranch, level, parentGeneratedId=undefined, siblingCount=0) => {
    // generate an id, but overwrite it with the existing id if it exists
    let generatedId = `Layer-${generateId()}`;

    let newLayerLabelId;
    let foundBranchRef = branchRefs.current.find(elem => elem.elementEditorId === treeBranch.id);

    if(foundBranchRef) {
      // this branch has already been rendered before, so look up it's layer id and assign that
      generatedId = foundBranchRef.layerLabelId;
    }
    else {
      // this branch has never been rendered before and so should have a new random id
      // and it should be registered with branchRefs

      branchRefs.current.push({
        elementEditorId: treeBranch.id,
        layerLabelId: generatedId
      })
    }

    // base case
    if(treeBranch.children.length === 0) {
      return (
        // render this if the label has no children, which doesn't include the collapse
        <LayerLabel
          parentLayerId={parentGeneratedId}
          layerLabelId={generatedId}
          key={treeBranch.id}
          level={level}
          hasSiblings={level > 0 && siblingCount > 1}
          icon={treeBranch.props.layerIconNode || null}
          label={treeBranch.props.layerLabel || 'Unnamed Layer'}
          elementTreeBranch={treeBranch}
          elementEditorId={treeBranch.id}
          branchRefs={branchRefs}
        />
      );
    }
    else {
      let renderedChildBranches = [];
      for(let childBranch of treeBranch.children) {
        let nextLevel = renderNextLevel(childBranch, level + 1, generatedId, treeBranch.children.length);
        renderedChildBranches.push(nextLevel);
      }

      return (
        <LayerLabel
          parentLayerId={parentGeneratedId}
          layerLabelId={generatedId}
          level={level}
          hasSiblings={level > 0 && siblingCount > 1}
          icon={treeBranch.props.layerIconNode || null}
          label={treeBranch.props.layerLabel || 'Unnamed Layer'}
          elementTreeBranch={treeBranch}
          elementEditorId={treeBranch.id}
          branchRefs={branchRefs}
        >
          {renderedChildBranches.map((jsx, index) => jsx)}
        </LayerLabel>
      );
    }
  }

  return (
    <EditorLayersProvider>
      <div className={classes.layersRoot} id={'layers-root'}>
        {useMemo(() => !!renderedTree ? (
          renderedTree.map((jsx, index) => jsx)
        ) : 'Loading...', [renderedTree])}
      </div>
    </EditorLayersProvider>
  );
};

EditorLayers.propTypes = {
  classes: PropTypes.object
};

export default withStyles(EditorLayersStyle)(EditorLayers);
