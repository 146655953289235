import axios from 'axios';
import Cookies from 'universal-cookie';
import APIConfig from 'settings';
import snakecaseKeys from 'snakecase-keys';
import camelcaseKeys from 'camelcase-keys';
import APPLICATION_SETTINGS from "settings";


const responseBody = (response, camelCase=true) => camelCase ? camelcaseKeys(response, {deep: true}) : response;
const responseError = (error) => {
  console.error(error);
  throw error;
};


axios.defaults.baseURL = APPLICATION_SETTINGS.DJANGO_API_URL;
// token interceptor
axios.interceptors.request.use(
  (config) => {
    const cookies = new Cookies();
    const token = cookies.get('token');
    if (token) config.headers.Authorization = `Token ${token}`;
    return config;
  },
  (error) => {
    console.log('response rejected', error)
    return Promise.reject(error);
  },
);


// error interceptors
axios.interceptors.response.use(undefined, (error) => {
  const cookies = new Cookies();
  const status = error.response ? error.response.status : null;
  const data = error.response ? error.response.data : null;
  if (status === 403 && data && data['detail'] === 'Invalid token.') {
    console.log('cookie removed due to invalid token');
    cookies.remove('token', { path: '/' });
    // this was causing infinite loops with stale cookies
    //window.location.reload();
  }
  throw error;
});

const requests = {
  get: (url, camelCase=true) => axios.get(url).then(response => responseBody(response, camelCase)).catch(responseError),
  post: (url, body={}, headers={}, snakeCase=true, camelCase=true) =>
    axios.post(url, snakeCase ? snakecaseKeys(body, { deep: true }) : body, {...headers})
      .then(response => responseBody(response, camelCase)).catch(responseError),
  put: (url, body) => axios.put(url, snakecaseKeys(body, { deep: true })).then(responseBody).catch(responseError),
  delete: (url, camelCase=true) => axios.delete(url).then(response => responseBody(response, camelCase)).catch(responseError),
  patch: (url, body) => axios.patch(url, snakecaseKeys(body, { deep: true })).then(responseBody).catch(responseError)
};

const wordpressURL = APIConfig.WORDPRESS_API_URL;

const Blog = {
  getBlog: (id, isPreview=false) =>
    axios.get(wordpressURL + `wp-json/wp/v2/posts/${id}/`)
      .then(response => responseBody(response, true))
      .catch(responseError),
  getAllPosts: (pageNumber=1, query='', sort='desc') =>
    axios.get(wordpressURL + `wp-json/wp/v2/posts/?page=${pageNumber}&per_page=8&search=${query}&order=${sort}`)
      .then(response => responseBody(response, true))
      .catch(responseError),
  getRelatedPosts: (id) =>
    axios.get(wordpressURL + `wp-json/custom/v1/random-posts/${id}/`)
      .then(response => responseBody(response, true))
      .catch(responseError),
}

const User = {
  register: (values) => requests.post('/users/', {
    ...values
  }),
  login: (email, password) => requests.post('/users/authenticate/', {
    email,
    password
  }),
  getMe: () => requests.get('/users/me/'),
  requestPasswordReset: (email) => requests.post('/users/me/request-password-reset/', {
    email
  }),
  verifyPasswordResetToken: (passwordResetToken) => requests.post('/users/me/verify-password-token/', {
    token: passwordResetToken
  }),
  confirmPasswordReset: (password, passwordResetToken) => requests.post('/users/me/confirm-password/', {
    token: passwordResetToken,
    password
  }),
  authenticatedPasswordChange: (currentPassword, newPassword) =>
    requests.post('/users/me/authenticated-change-password/', {currentPassword, newPassword}),
  update: (values) => requests.patch('/users/me/', {
    ...values
  }),
  logout: (history) => {
    const cookies = new Cookies();
    cookies.remove('token');
    cookies.remove('user');
    delete axios.defaults.headers.common['Authorization'];
    history.replace('/login')
  },
  uploadMedia: (formData, headers) => requests.post('/users/me/media/', formData, headers, false),
  getMedia: () => requests.get('/users/me/media/', false),
  deleteMedia: (id) => requests.delete(`/users/me/media/${id}/`),

  // snakecased manually for django
  saveProject: (projectData, project_id=null, version_id=null) =>
    requests.post('/users/me/projects/', {...projectData, version_id, project_id}, {}, false),

  listAllTemplates: () => requests.get(`/templates/`),
  getTemplateById: (id) => requests.get(`/templates/${id}/`),
  listAllProjects: (pageNumber=1) => requests.get(`/users/me/projects/?page=${pageNumber}`),
  getProjectById: (id) => requests.get(`/users/me/projects/${id}/`, false),
  getVersionById: (projectId, versionId) => requests.get(`/users/me/projects/${projectId}/versions/${versionId}/`),
  getProjectVersions: (id, pageNumber=1) => requests.get(`/users/me/projects/${id}/versions/?page=${pageNumber}`),
  renameProject: (data, id) => requests.post(`/users/me/projects/${id}/rename/`, data),
  deleteProject: (id) => requests.delete(`/users/me/projects/${id}/`),
  deleteProjectVersion: (projectId, versionId) => requests.delete(`/users/me/projects/${projectId}/versions/${versionId}/`),

  // should these move?
  deleteComponent: (id) => requests.delete(`/users/me/components/${id}/`, false),
  createComponent: (data, name, category, screenshot, isPublic=false) => requests.post('/users/me/components/', {data, name, category, screenshot, public: isPublic}, {}, false, false),
  listComponentCategories: () => requests.get('/users/me/components/', false),

  deletePublicComponent: (id) => requests.delete(`public-components/${id}/`, false),
  listPublicComponents: () => requests.get('/public-components/', false),
  listPublicComponentCategories: () => requests.get('/public-components/categories/', false),
  listLayouts: () => requests.get('/public-components/categories/?layouts_only=true', false),

  createSubscription: (data) => requests.post('/users/me/subscription/', {...data}),
  changePaymentMethod: (data) => requests.post('/users/me/change-payment-method/', {...data}),
  cancelSubscription: () => requests.post('/users/me/cancel-subscription/', {}),
  exportProject: (projectData, project_id=null, version_id=null, is_preview=false) => requests.post(
    '/users/me/projects/export-project/',
    {...projectData, version_id, project_id, is_preview},
    {},
    false
  ),
  disableTutorial: () => requests.post('/users/me/disable-first-time-user/'),
  checkCurrentExports: () => requests.get('/users/me/check-current-user-exports/'),
  acceptCookiePolicy: () => requests.post('/users/me/accept-cookie-policy/'),
  changeNotificationStatus: (shouldNotify) => requests.post('/users/me/change-notification-status/', {shouldNotify})
};

const ContactUs = {
  submitFeedback: (name, email, message) => requests.post('/feedback/', {name, email, message}),
  submitBugReport: (name, email, message) => requests.post('/bug-report/', {name, email, message}),
  contactUs: (name, email, message) => requests.post('/contact-us/', {name, email, message})
}

const Unsplash = {
  getAllPhotos: (pageNumber=1) => requests.get(
    '/unsplash/get-all-photos/?page=' + pageNumber
  ),
  searchPhotos: (query, pageNumber=1) => requests.get(
    '/unsplash/search-photos/?q=' + query + '&page=' + pageNumber
  ),
  triggerDownload: (downloadLink) => requests.post(
    '/unsplash/trigger-unsplash-download/',
    {
      link: downloadLink
    },
    {},
    false
  )
}

const CountryData = {
  getStatesInCountry: (country) => requests.post('/country-data/', {
    country
  })
}

// const Blog = {
//   getBlog: (id, isPreview=false) => requests.get(`/blog-posts/${id}/?preview=${isPreview}`),
//   getAllPosts: (pageNumber=1, query='', sort='desc') => requests.get(`/blog-posts/?page=${pageNumber}&sort=${sort}&q=${query}`),
// }

export {
  User,
  Unsplash,
  ContactUs,
  Blog,
  CountryData
};
