import Typography from "@material-ui/core/Typography";
import React from 'react';

const HowToChangeButtonText = () => {
  return (
    <>
      <Typography style={{marginBottom: '8px'}}>
        To change the text inside of the button, follow these steps:
      </Typography>
      <Typography>
        1. Select the button, which will bring up its settings in the right-hand panel.
      </Typography>
      <Typography>
        2. Locate the setting that says 'Button Text'.
      </Typography>
      <Typography>
        3. Input the new text you desire for the button.
      </Typography>
    </>
  );
};

HowToChangeButtonText.propTypes = {

};

export default HowToChangeButtonText;