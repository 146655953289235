// utility functions for draggables
import {getElementsAtClientCoordinates} from "../../utils/utils";

export const getTopmostEditorElementFromPath = (elemPath, idsToExclude=[], containerOnly=false) => {
  /**
   * @param {Array} elemPath - An array of elements
   */

  for(let elem of elemPath) {
    // iterate over elements in the path
    // filter out things like window and document and only get actual nodes
    if(
      elem.nodeType
      && elem.hasAttribute
      && elem.hasAttribute('data-editorid')
      && elem.getAttribute('data-editorid') !== 'editor-root'
      && !idsToExclude.includes(elem.getAttribute('data-editorid'))
    ) {

      if(
        containerOnly
        && elem.hasAttribute('data-container')
        && elem.getAttribute('data-container') === 'true'
      ) {
        console.log('TEST', elem.getAttribute('data-container'))
        // stop once we find one so we only get the top element
        return elem;
      }
      else if(containerOnly) {
        continue;
      }
      else {
        return elem;
      }
    }
  }

  return null;
}

export const getElementEditorId = elem => {
  if(
    elem.nodeType
    && elem.hasAttribute
    && elem.hasAttribute('data-editorid')
  ) {

    // stop once we find one so we only get the top element
    return elem.getAttribute('data-editorid');
  }

  return null;
}

export const getTopmostEditorElementFromCoordinates = (x, y, self, containerOnly=false) => {
  // get the self element's id so we can exclude it
  let selfEditorId = self.getAttribute('data-editorid');

  let elemsAtPoint;
  try {
    elemsAtPoint = getElementsAtClientCoordinates(x, y);
  }
  catch (e) {
    return null;
  }


  return getTopmostEditorElementFromPath(elemsAtPoint, [selfEditorId], containerOnly);
}
