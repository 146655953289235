const ManageSubscriptionStyle = theme => ({
  root: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: 'flex-start',
    width: "100%",
    height: "100%",
  },
});

export default ManageSubscriptionStyle;