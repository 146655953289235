import {IconButton, Typography, withStyles} from '@material-ui/core';
import MenuItem from "@material-ui/core/MenuItem";
import Popover from "@material-ui/core/Popover";
import AddIcon from "@material-ui/icons/Add";
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import PageContextMenu from "editor/components/contextMenus/PageContextMenu/PageContextMenu";
import {DEFAULT_TREE} from "editor/constants/editorConstants";
import DraggablePageLabel from "editor/draggables/DraggablePageLabel/DraggablePageLabel";
import {EditorContext} from "editor/Editor";
import {useEditorDialog} from "hooks/useEditorDialog";
import PropTypes from 'prop-types';
import React, {useCallback, useContext, useEffect, useMemo, useState} from 'react';
import {generateId, getElementsAtClientCoordinates} from "utils/utils";

import EditorPagesStyle from './EditorPagesStyle';

const EditorPages = React.forwardRef((props, ref) => {
  const { classes, show } = props;

  const editorData = useContext(EditorContext);
  const {editorReducerState, dispatch} = editorData;
  const pages = editorReducerState.editorState.editorPages.value;
  const elementTree = editorReducerState.editorState.elementTree.value;
  const defaults = editorReducerState.editorState.defaults;

  const [addButtonPopoverAnchor, setAddButtonPopoverAnchor] = React.useState(null);
  const [routingButtonPopoverAnchor, setRoutingButtonPopoverAnchor] = React.useState(null);
  const [contextMenuCoords, setContextMenuCoords] = useState({
    x: null,
    y: null
  })
  const [contextMenuPageObj, setContextMenuPageObj] = useState(null)
  const [lastClickedPage, setLastClickedPage] = useState(null)
  const routingDialog = useEditorDialog('routing');


  const openAddPopover = !!addButtonPopoverAnchor;
  const openRoutingPopover = !!routingButtonPopoverAnchor;

  const addPage = useCallback(() => {

    // no payload needed
    dispatch({
      type: 'addPage',
    })

    setAddButtonPopoverAnchor(null);
  }, [])

  const openRoutingDialog = () => {
    routingDialog.openDialog();
  }

  // TODO: this is unused AND OUT OF DATE
  // const addFolder = useCallback((folderChildren) => {
  //   let newEditorPages = [...pages];
  //
  //   let folderCount = 0;
  //   for(let i of newEditorPages) {
  //     if(i.tree === undefined) {
  //       folderCount++;
  //     }
  //   }
  //
  //   if(folderChildren) {
  //     let foundChildren = [];
  //
  //     for(let child of folderChildren) {
  //       let foundChild = newEditorPages.find(elem => elem.labelId === child.labelId)
  //       foundChildren.push(newEditorPages.splice(newEditorPages.indexOf(foundChild), 1)[0])
  //     }
  //
  //     newEditorPages.push({
  //       pageName: `Folder ${folderCount + 1}`,
  //       labelId: `Folder-${generateId()}`,
  //       children: foundChildren,
  //       ref: null,
  //     })
  //   }
  //   else {
  //     newEditorPages.push({
  //       pageName: `Folder ${folderCount + 1}`,
  //       labelId: `Folder-${generateId()}`,
  //       children: [],
  //       ref: null,
  //     })
  //   }
  //
  //   console.log('new editor pages', newEditorPages);
  //
  //   dispatch({
  //     type: 'setPages',
  //     payload: {
  //       newPages: newEditorPages
  //     }
  //   })
  //
  //   setAddButtonPopoverAnchor(null);
  // }, [pages])

  const selectPage = (labelId) => {
    dispatch({
      type: 'changePage',
      payload: {
        newSelectedPageLabelId: labelId
      }
    })

    let editorRoot = document.getElementById('zoom-container');
    editorRoot.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
      inline: 'center'
    })
  }

  // const deselectAllPages = () => {
  //   let newPages = [...pages];
  //   for(let page of newPages) {
  //     delete page.selected;
  //   }
  //
  //   dispatch({
  //     type: 'setPages',
  //     payload: {
  //       newPages: newPages
  //     }
  //   })
  // }

  const findPageByLabelId = (labelId) => {
    let newPages = [...pages];
    return newPages.find(elem => elem.labelId === labelId)
  }

  const openAddButtonPopover = (event) => {
    setAddButtonPopoverAnchor(event.currentTarget);
  }

  const closeAddButtonPopover = () => {
    setAddButtonPopoverAnchor(null);
  }

  const closeContextMenu = () => {
    setContextMenuCoords({
      x: null,
      y: null,
    })

    // deselectAllPages();
  }

  const openRoutingButtonPopover = (event) => {
    setRoutingButtonPopoverAnchor(event.currentTarget)
  }

  const closeRoutingButtonPopover = () => {
    setRoutingButtonPopoverAnchor(null)
  }

  const openContextMenu = (e) => {
    e.preventDefault()

    const clickedElemPath = getElementsAtClientCoordinates(e.clientX, e.clientY);
    let pageObjWasFound = false;

    for(let elem of clickedElemPath) {
      try {
        if(elem.hasAttribute('data-labelid')) {
          let labelId = elem.getAttribute('data-labelid');
          let foundPageObj = findPageByLabelId(labelId);
          setLastClickedPage(foundPageObj)
          setContextMenuPageObj(foundPageObj);
          selectPage(labelId);
          pageObjWasFound = true;
          break;
        }
      }
      catch (err) {
        setContextMenuPageObj(null);
        continue;
      }
    }

    if(!pageObjWasFound) {
      // no page was right clicked on
      setContextMenuPageObj(null)
    }

    setContextMenuCoords({
      x: e.clientX - 2,
      y: e.clientY - 4,
    })
  }

  const contextMenu = useMemo(() => (
    <PageContextMenu
      onClose={closeContextMenu}
      contextMenuCoords={contextMenuCoords}
      selectedPage={contextMenuPageObj}
      lastClickedPage={lastClickedPage}
    />
  ), [contextMenuCoords, lastClickedPage])

  return (
    <>
      {/*Add button popover*/}
      <Popover
        open={openAddPopover}
        anchorEl={addButtonPopoverAnchor}
        onClose={closeAddButtonPopover}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <div className={classes.buttonPopoverMenu}>
          <MenuItem
            className={classes.buttonPopoverItem}
            onClick={addPage}
          >
            Add Page
          </MenuItem>
          {/*<MenuItem*/}
          {/*  className={classes.addButtonPopoverItem}*/}
          {/*  onClick={addFolder}*/}
          {/*>*/}
          {/*  Add Folder*/}
          {/*</MenuItem>*/}
        </div>
      </Popover>

      {/*Routing Button Popover*/}
      <Popover
        open={openRoutingPopover}
        anchorEl={routingButtonPopoverAnchor}
        onClose={closeRoutingButtonPopover}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <div className={classes.buttonPopoverMenu}>
          <MenuItem
            className={classes.buttonPopoverItem}
            onClick={openRoutingDialog}
          >
            Set Page Routes
          </MenuItem>
        </div>
      </Popover>

      <div className={classes.pagesContainer} ref={ref} style={{display: show ? 'block' : 'none'}}>
        <div className={classes.pageHeaderContainer}>
          <Typography variant={'h5'} className={classes.pageHeaderTitle}>
            Pages
          </Typography>

          <IconButton
            color={"primary"}
            size={"small"}
            className={classes.addButtonContainer}
            onClick={openAddButtonPopover}
            id={'add-page'}
          >
            <AddIcon/>
          </IconButton>

          <IconButton
            color={"primary"}
            size={"small"}
            onClick={openRoutingButtonPopover}
          >
            <MoreHorizIcon/>
          </IconButton>
        </div>

        <div
          className={classes.pageDataContainer}
          onContextMenu={openContextMenu}
        >
          {useMemo(() => pages.map(({pageName, labelId, children, selected}, index) => (
            <DraggablePageLabel
              name={pageName}
              labelId={labelId}
              childPages={children}
              //addFolder={addFolder}
              key={labelId}
              selected={selected}
              selectPage={selectPage}
            />
          )), [pages])}

          {contextMenu}
        </div>
      </div>
    </>
  );
});

EditorPages.propTypes = {
  classes: PropTypes.object
};

export default withStyles(EditorPagesStyle)(EditorPages);
