const PopperDropDownMenuStyle = theme => ({
  menuItem: {
    color: "white",
    background: theme.palette.popperMenu.background,
    transition: "0.3s",
    border: "none",
  },

  highlight: {
    '&:hover': {
      color: theme.palette.secondary.main,
      background: theme.palette.popperMenu.background,
    }
  },
});

export default PopperDropDownMenuStyle;
