import {withStyles} from '@material-ui/core';
import DraggableWrapper from 'editor/draggables/DraggableWrapper/DraggableWrapper';
import formatStyles from "editor/draggables/formatStyles";
import TextDraggableStyle from 'editor/draggables/TextDraggable/TextDraggableStyle';
import PropTypes from 'prop-types';
import React, {useEffect, useMemo, useState} from 'react';
import ContentEditable from 'react-contenteditable';
import APPLICATION_SETTINGS from "settings";

const TextDraggable = props => {
  const {
    classes,
    editable,
    text,
    contentTag,
    editorId,
    style,
    draggableProps,
    absolutePositioning,
    // universally provided to all assets
    isChildElem
  } = props;

  const [innerText, setInnerText] = useState('')

  const {draggableStyles, elementStyles} =
    useMemo(() => formatStyles(style), [style]);

  useEffect(() => {
    setInnerText(text);
  }, [text])

  return (
    <DraggableWrapper
      testId={'textDraggable'}
      editorId={editorId}
      resizeableElementProps={{
        minHeight: '25px',
      }}
      style={{
        ...draggableStyles,
        ...draggableProps?.style,
        position: absolutePositioning ? 'absolute' : 'static'
      }}
      isChildElem={isChildElem}
    >
      {/*
      Fit to text expects a span inside a p, so make sure to change that if
      we change the HTML here
      */}
      <p
        className={classes.editable}
        data-editorid={editorId}
        data-asset={true}
        style={{
          ...elementStyles,
          position: 'static',
        }}
      >
        <span className={classes.text}>
          {innerText}
        </span>
      </p>
    </DraggableWrapper>
  );
};

TextDraggable.serializedName = 'TextDraggable'

TextDraggable.whyDidYouRender = true;

TextDraggable.editorSettings = [
  {
    type: 'prop',
    key: 'breakpoints',
    tooltip: 'Make an asset visible at the chosen breakpoints: desktop, tablet, and/or mobile.',
    default: {
      visibility: {
        desktop: true,
        tablet: true,
        mobile: true
      },
      styleLink: {
        tablet: true,
        mobile: true,
      }
    },
    label: null,
    inputType: 'breakpoint'
  },

  {
    type: 'layout',
    key: 'twoCol',
    tooltip: 'Accepted units: px, %, em, rem, vw, vh',
    label: 'Position/Size',
    elements: [
      {type: 'style', key: 'left', default: '0', label: 'X'},
      {type: 'style', key: 'top', default: '0', label: 'Y'},
      {type: 'style', key: 'width', default: '50px', label: 'W'},
      {type: 'style', key: 'height', default: '50px', label: 'H'},
    ]
  },

  {
    type: 'layout',
    key: 'font',
    elements: [
      {type: 'style', key: 'fontFamily', default: 'Roboto',inputType: 'select', options: APPLICATION_SETTINGS.CONSTANTS.SUPPORTED_FONTS},
      {type: 'style', key: 'fontWeight', default: '400', inputType: 'select', options: APPLICATION_SETTINGS.CONSTANTS.SUPPORTED_FONT_WEIGHTS},
      {type: 'style', key: 'fontSize', default: '16px'},
    ]
  },

  {
    type: 'prop',
    key: 'absolutePositioning',
    tooltip: 'If this is checked, your asset\'s CSS will be written with "position: absolute"',
    label: 'Absolute Positioning',
    default: false,
    inputType: 'boolean'
  },

  {type: 'prop', key: 'text', default: '', label: 'Text'},

  {type: 'prop', key: 'fitToText', default: false, label: null, inputType: 'fitToText'},

  {
    type: 'style',
    key: 'color',
    default: '#000000',
    label: 'Text Color',
    inputType: 'color',
    inputOptions: {
      defaultColor: '#000000'
    }
  },

  {type: 'prop', key: 'textStyle', default: null, label: 'Text Style', inputType: 'textStyle'},

  {type: 'style', key: 'textAlign', default: 'left', label: 'Text Align', inputType: 'textAlign'},

  // {type: 'prop', key: 'classList', default: null, label: null, inputType: 'class'},

  {
    type: 'collection',
    label: 'Padding',
    children: [
      {type: 'style', key: 'paddingTop', default: '0px', label: 'Top', horizontal: false},
      {type: 'style', key: 'paddingBottom', default: '0px', label: 'Bottom', horizontal: false},
      {type: 'style', key: 'paddingLeft', default: '0px', label: 'Left', horizontal: false},
      {type: 'style', key: 'paddingRight', default: '0px', label: 'Right', horizontal: false},
    ]
  },

  {
    type: 'collection',
    label: 'Margin',
    children: [
      {type: 'style', key: 'marginTop', default: '0px', label: 'Top', horizontal: false},
      {type: 'style', key: 'marginBottom', default: '0px', label: 'Bottom', horizontal: false},
      {type: 'style', key: 'marginLeft', default: '0px', label: 'Left', horizontal: false},
      {type: 'style', key: 'marginRight', default: '0px', label: 'Right', horizontal: false},
    ]
  },

  {
    type: 'collection',
    label: 'Effects',
    children: [
      {type: 'style', key: 'opacity', default: '1', label: 'Opacity'},
      {type: 'prop', key: 'link', default: null, label: null, inputType: 'link'},
      {type: 'prop', key: 'cursor', default: null, label: null, inputType: 'cursor'},
      {type: 'style', key: 'transition', default: 'none', label: 'Transition', inputType: 'transition'},
      {type: 'style', key: 'transform', default: 'none', label: 'Rotation', inputType: 'rotation'},

    ]
  },
]

TextDraggable.helpQuestionData = {
  category: 'text',
  helpAssetHeader: {
    assetType: 'Text',
    assetShortDescription: 'Construct your website\'s content by inputting text and customizing its appearance to your preference.'
  }
}

TextDraggable.propTypes = {
  editable: PropTypes.bool.isRequired,
  text: PropTypes.string,
  contentTag: PropTypes.string.isRequired,
  classes: PropTypes.object,
  style: PropTypes.object.isRequired,
  draggableProps: PropTypes.object,
  editorId: PropTypes.string.isRequired,
  isChildElem: PropTypes.bool,
};

export default withStyles(TextDraggableStyle)(TextDraggable);
