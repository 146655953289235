import Button from "@material-ui/core/Button";
import CloseIcon from "@material-ui/icons/Close";
import {EditorContext} from "editor/Editor";
import {Form, Formik} from "formik";
import React, {useContext, useEffect, useState} from 'react';
import {
  Dialog,
  DialogContent,
  DialogTitle, IconButton, TextField,
  withStyles
} from '@material-ui/core';
import PropTypes from 'prop-types';
import {toast} from "react-toastify";
import {User} from "services";
import RenameProjectDialogStyle from './RenameProjectDialogStyle';

const RenameProjectDialog = props => {
  const {
    classes,
    open,
    setOpen,
    data,
  } = props;

  const editorData = useContext(EditorContext);
  const {editorReducerState, dispatch} = editorData;
  const {editorActions} = editorData;
  const currentProjectData = editorReducerState.editorState.currentProjectData.value;

  const [projectName, setProjectName] = useState(currentProjectData?.name || '')
  const [busy, setBusy] = useState(false)

  useEffect(() => {
    setProjectName(currentProjectData?.name || '')
  }, [currentProjectData])

  const handleClose = () => {
    setOpen(false)
  }

  const submitRenameProject = async (values, {setErrors}) => {
    if (busy || !values.projectName.trim()) return;

    const data = {...values};

    let projectId =
      editorReducerState.editorSettings.projectId
      ||
      window.localStorage.getItem('projectId');

    // rename it locally in the editor state and exit if the project
    // is new and hasn't been saved
    if(!projectId) {
      dispatch({
        type: 'renameProject',
        payload: {
          name: data.projectName
        }
      })

      setOpen(false);
      setBusy(false)
      return;
    }

    setBusy(true);

    User.renameProject(data, projectId).then(response => {
      console.log('Success', response);

      dispatch({
        type: 'renameProject',
        payload: {
          name: data.projectName
        }
      })

      //window.localStorage.setItem('projectName', data.projectName);

      toast.success('Project successfully renamed to: ' + data.projectName)
      setOpen(false);
    }).catch(error => {
      console.log('error', error);
      toast.error('An error occurred renaming your project.')
    }).finally(() => {
      setBusy(false);
    })
  }

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      maxWidth={'md'}
      classes={{paper: classes.dialog}}
    >
      <DialogTitle className={classes.dialogTitle}>
        Rename Project

        <IconButton
          onClick={handleClose}
          className={classes.closeIcon}
          size={'small'}
        >
          <CloseIcon/>
        </IconButton>
      </DialogTitle>

      <DialogContent>
        <Formik
          initialValues={{
            'projectName': projectName
          }}
          enableReinitialize
          onSubmit={submitRenameProject}
        >
          {({
              values,
              errors,
              handleChange
            }) => (
            <Form className={classes.form}>
              <TextField
                fullWidth
                color={"primary"}
                variant={"outlined"}
                name={'projectName'}
                autoFocus
                value={values.projectName}
                onChange={handleChange}
                error={!!errors.projectName}
                helperText={errors.projectName}
                InputProps={{
                  classes: {
                    notchedOutline: classes.notchedOutline,
                  },
                }}
              />

              <Button
                className={classes.purpleButton}
                variant={'contained'}
                color={'secondary'}
                disabled={busy}
                type={'submit'}
              >
                Submit
              </Button>
            </Form>
          )}
        </Formik>
      </DialogContent>
    </Dialog>
  );
};

RenameProjectDialog.propTypes = {
  classes: PropTypes.object,
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
  data: PropTypes.object,
};

export default React.memo(withStyles(RenameProjectDialogStyle)(RenameProjectDialog));
