import MenuItem from "@material-ui/core/MenuItem";
import ElementSettingsGroupLabel from "components/ElementSettingGroupLabel/ElementSettingsGroupLabel";
import StyledTextField from "components/StyledTextField/StyledTextField";
import React, {useEffect, useRef, useState} from 'react';
import {withStyles} from '@material-ui/core';
import PropTypes from 'prop-types';
import TransitionInputStyle from './TransitionInputStyle';

const TransitionInput = props => {
  const {
    classes,
    setting,
    onChange,
    onBlur,
    onFocus,
    value
  } = props;

  const [unit, setUnit] = useState('s');
  const [transition, setTransition] = useState('0');
  const mounted = useRef(false);

  const generateTransitionCSS = () => {
    let generatedCSS = `${transition}${unit}`;

    if(generatedCSS !== value) {
      onChange(generatedCSS);
    }
  }

  useEffect(() => {
    if(mounted.current) {
      generateTransitionCSS();
    }
    else {
      mounted.current = true;
    }
  }, [transition, unit]);

  useEffect(() => {
    if(value && value !== 'none') {
      let newUnit = value.replace(/[^A-Za-z]/g, "");
      let newTransition = value.replace(/[^\d.-]/g, "");

      setUnit(newUnit);
      setTransition(newTransition);
    }

    console.log('test', value)
  }, [value])

  // we need a separate useEffect because this only needs to run  unmount
  useEffect(() => {
    return () => {
      mounted.current = false;
    }
  }, [])

  return (
    <div>
      <ElementSettingsGroupLabel setting={setting}>
        Transition
      </ElementSettingsGroupLabel>

      <div className={classes.root}>
        <StyledTextField
          formControlClassName={classes.input}
          value={transition}
          onChange={e => setTransition(e.target.value)}
        />

        <StyledTextField
          select
          formControlClassName={classes.input}
          fullWidth
          value={unit}
          onChange={e => setUnit(e.target.value)}
          onBlur={onBlur}
          onFocus={onFocus}
        >
          <MenuItem value={'s'}>
            Seconds
          </MenuItem>
          <MenuItem value={'ms'}>
            Milliseconds
          </MenuItem>
        </StyledTextField>
      </div>
    </div>
  );
};

TransitionInput.propTypes = {
  classes: PropTypes.object,
  setting: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  onFocus: PropTypes.func.isRequired,
  onBlur: PropTypes.func.isRequired
};

export default withStyles(TransitionInputStyle)(TransitionInput);
