import {withStyles, IconButton} from '@material-ui/core';
import Paper from "@material-ui/core/Paper";
import Popover from "@material-ui/core/Popover";
import PropTypes from 'prop-types';
import React, {useCallback, useMemo, useRef, useState} from 'react';
import {SketchPicker} from "react-color";
import CancelIcon from '@material-ui/icons/Cancel';

import ColorPickerBoxStyle from './ColorPickerBoxStyle';

const ColorPickerBox = props => {
  const {
    classes,
    anchorEl,
    setAnchorEl,
    onChange,
  } = props;


  const [color, setColor] = useState('#ffffff')

  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleChange = useCallback((color) => {
    // check for alpha
    if(color?.rgb?.a !== 1) {
      // use rgba instead of hex code
      // create an rgba value from data
      let rgbaColor = `rgba(${color.rgb.r}, ${color.rgb.g}, ${color.rgb.b}, ${color.rgb.a})`

      setColor(rgbaColor);
      onChange(rgbaColor);
    }
    else {
      setColor(color.hex);
      onChange(color.hex);
    }
  }, [])

  const renderedPicker = useMemo(() => (
    <SketchPicker
      color={color}
      onChangeComplete={handleChange}
      width={300}
      //disableAlpha
    />
  ), [color])

  return (
    <div>
      <Popover
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <Paper>
          {renderedPicker}
        </Paper>
      </Popover>
    </div>
  );
};

ColorPickerBox.propTypes = {
  classes: PropTypes.object,
  anchorEl: PropTypes.object,
  setAnchorEl: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
};

ColorPickerBox.whyDidYouRender = true;

export default withStyles(ColorPickerBoxStyle)(ColorPickerBox);
