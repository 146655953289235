import cn from "classnames";
import DraggableToolboxAsset from "editor/draggables/DraggableToolboxAsset/DraggableToolboxAsset";
import {EditorContext} from "editor/Editor";
import AssetPanel from "editor/layout/AssetPanel/AssetPanel";
import {useAssetVariants} from "hooks/useAssetVariants";
import {useComponentVariants} from "hooks/useComponentVariants";
import React, {useContext, useEffect, useRef, useState} from 'react';
import {withStyles, Collapse} from '@material-ui/core';
import PropTypes from 'prop-types';

import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';

import ToolboxIconStyle from './ToolboxIconStyle';

const ToolboxIcon = props => {
  const {
    classes,
    asset,
    component,
    publicComponents,
    isLayout,
  } = props;

  const rootRef = useRef(null);
  const {editorReducerState, dispatch} = useContext(EditorContext);

  const [shouldShow, setShouldShow] = useState(false);

  const assetVariants = useAssetVariants(asset?.tagName);
  const componentVariants = useComponentVariants(component, isLayout ? 'layouts' : publicComponents ? 'public' : 'saved');


  const handleClick = (e) => {
    setShouldShow(!shouldShow);
    // dispatch({
    //   type: 'toggleAssetPanel',
    //   payload: {
    //     showAssetPanel: true,
    //     assetPanelSelectedAsset: asset || component
    //   }
    // })
  }

  if(asset) {
    return (
      <>
        <div className={classes.root} ref={rootRef} onClick={handleClick}>
          <div className={classes.button}>
            <div className={classes.icon}>
              {asset.toolboxSettings.iconNode}
            </div>

            {asset.toolboxSettings.textNode}
          </div>

          <div className={classes.dropdownIconContainer}>
            {shouldShow ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </div>
        </div>

        <Collapse
          in={shouldShow}
          classes={{
            wrapper: classes.innerCollapse
          }}
        >
          {assetVariants.map((data, index) => {
            const variant = assetVariants[index];
            return (
              <DraggableToolboxAsset
                publicComponents={publicComponents}
                key={data.variantName}
                iconNode={
                  variant?.image
                }
                textNode={
                  data.formattedVariantName
                }
                variantData={variant}
                elementToCreate={{...data}}
              />
            )
          })}
        </Collapse>
      </>

    );
  }
  else {
    return (
      <>
        <div className={classes.root} ref={rootRef} onClick={handleClick}>
          <div className={classes.button}>
            {component.name}
          </div>

          <div className={classes.dropdownIconContainer}>
            {shouldShow ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </div>
        </div>

        <Collapse
          in={shouldShow}
          classes={{
            wrapper: classes.innerCollapse
          }}
        >
          {componentVariants.map((data, index) => {
            const variant = componentVariants[index];
            return (
              <DraggableToolboxAsset
                publicComponents={publicComponents}
                isLayout={isLayout}
                key={data.variantName}
                iconNode={
                  variant?.image
                }
                textNode={
                  data.formattedVariantName
                }
                variantData={variant}
                isComponent={true}
                elementToCreate={{...variant.elementToCreate}}
              />
            )
          })}
        </Collapse>
      </>
    )
  }
};

ToolboxIcon.propTypes = {
  classes: PropTypes.object
};

export default withStyles(ToolboxIconStyle)(ToolboxIcon);
