const IconDraggableStyle = {
  root: (props) => ({
    '& svg': {
      '&:hover': {
        'color': props.hoverColor,
      }
    },
  }),

  iconContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: props => props?.style?.width || '50px',
    height: props => props?.style?.height || '50px',

    '& svg': {
      fill: props => props.style?.color || 'white',
      width: props => props?.style?.width || '50px',
      height: props => props?.style?.height || '50px',
    }
  },
};

export default IconDraggableStyle;
