const RadioDraggableStyle = theme => ({
  radio: (props) => ({
    position: 'static',
    padding: '0',

    '&.MuiRadio-colorSecondary.Mui-disabled': {
      color: "white",
    }
  }),

  radioIcon: {
    width: '40px',
    height: '40px'
  }
});

export default RadioDraggableStyle;
