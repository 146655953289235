import Button from "@material-ui/core/Button";
import DialogContent from "@material-ui/core/DialogContent";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import ArrowDropUpIcon from "@material-ui/icons/ArrowDropUp";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import {EditorContext} from "editor/Editor";
import ColorPickerInput from "editor/layout/ElementSettings/Inputs/ColorPickerInput/ColorPickerInput";
import {Form, Formik} from "formik";
import React, {useContext, useMemo, useState} from 'react';
import {
  Collapse, DialogActions,
  Typography,
  withStyles
} from '@material-ui/core';
import PropTypes from 'prop-types';
import {splitCamelCaseToString} from "utils/utils";
import ThemePanelStyle from './ThemePanelStyle';
import { debounce } from 'lodash';

const ThemePanel = props => {
  const {
    classes,
    show
  } = props;

  const editorData = useContext(EditorContext);
  const {editorReducerState, dispatch} = editorData;

  const initialValues = {
    ...editorReducerState.editorState.themeSettings.palette
  };

  // these are the keys we iterate through below
  // NOTE: new colors will not show up unless also added to this
  // I would love to have this just read from the settings so we don't need to
  // also change these, but django stores out project data in variable order
  // so if we don't use these the colors and inner color types get rearranged
  const colorTypes = ['primary', 'secondary', 'error', 'warning', 'success'];
  const innerColorTypes = ['main', 'light', 'dark', 'contrastText'];

  const initialCollapseState = useMemo(() => {
    let baseState = {};
    let valuesToUncollapseByDefault = ['primary', 'secondary'];

    for(let key of Object.keys(editorReducerState.editorState.themeSettings.palette)) {
      baseState[key] = valuesToUncollapseByDefault.includes(key);
    }

    return baseState;
  }, [editorReducerState.editorState.themeSettings])

  const [collapseState, setCollapseState] = useState(initialCollapseState);

  const formatColorName = (colorName) => {
    return splitCamelCaseToString(colorName);
  }

  const handleColorBoxChange = debounce((value, name, setFieldValue, submitForm) => {
    setFieldValue(name, value);
    submitForm();
  }, 500); // Adjust delay as needed

  const collapseLabel = (name) => {
    setCollapseState({
      ...collapseState,
      [name]: !collapseState[name]
    })
  }

  const onSubmit = async (values, {setErrors}) => {
    dispatch({
      type: 'changeTheme',
      payload: {
        newTheme: {
          ...editorReducerState.editorState.themeSettings,
          palette: {
            ...values
          }
        }
      }
    });
  };

  return (
    <div className={classes.root}
      style={{
        display: show ? 'flex' : 'none'
      }}
    >
      <Typography variant={'h5'} className={classes.title}>
        Theme
      </Typography>

      <div className={classes.innerContainer}>

        <Formik
          initialValues={initialValues}
          onSubmit={onSubmit}
        >
          {({
              values,
              errors,
              handleChange,
              submitForm,
              setFieldValue
            }) => (
            <>
              <div className={classes.dialogContent}>
                <Form className={classes.form}>
                  {colorTypes.map((key, index) => (
                    <div className={classes.colorPickerSection}>
                      <div
                        className={classes.colorPickerSectionHeader}
                        onClick={e => collapseLabel(key)}
                      >
                        <div className={classes.button}>
                          <Typography>
                            {formatColorName(key)}
                          </Typography>
                        </div>

                        <div className={classes.dropdownIconContainer}>
                          {collapseState[key] ? (
                            <KeyboardArrowDownIcon/>
                          ) : (
                            <KeyboardArrowUpIcon/>
                          )}
                        </div>
                      </div>

                      <div className={classes.formInner}>
                        {innerColorTypes.map((innerKey, index) => (
                          <Collapse
                            in={collapseState[key]}
                            classes={{
                              wrapper: classes.colorPickerSection,
                              wrapperInner: classes.colorPickerSection
                            }}
                          >
                            <Typography className={classes.colorPickerSectionInnerHeader}>
                              {formatColorName(innerKey)}
                            </Typography>

                            <ColorPickerInput
                              onChange={
                                value => handleColorBoxChange(
                                  value,
                                  `${key}.${innerKey}`,
                                  setFieldValue,
                                  submitForm
                                )
                              }
                              className={classes.colorPickerInput}
                              defaultColor={initialValues[key][innerKey]}
                              showTitle={false}
                              showTextField
                              value={values[key][innerKey]}
                              name={`${key}.${innerKey}`}
                              removable={false}
                              forceHexcode={true}
                              setting={{
                                type: 'none',
                                key: `${key}.${innerKey}`,
                                default: initialValues[key][innerKey],
                                label: innerKey,
                                inputType: 'color'
                              }}
                            />
                          </Collapse>
                        ))}
                      </div>
                    </div>
                  ))}
                </Form>
              </div>
            </>
          )}
        </Formik>

      </div>
    </div>
  );
};

ThemePanel.propTypes = {
  classes: PropTypes.object
};

export default withStyles(ThemePanelStyle)(ThemePanel);