import Typography from "@material-ui/core/Typography";
import React from 'react';

const HowToAddFormFields = () => {
  return (
    <>
      <Typography style={{marginBottom: '8px', fontSize: '22px'}}>
        Adding Fields Before Creating Form:
      </Typography>
      <Typography style={{marginBottom: '8px'}}>
        To add fields to the form, follow these steps:
      </Typography>
      <Typography>
        1. Drag and drop the form asset to your preferred location on the canvas.
      </Typography>
      <Typography>
        2. A form dialog will appear with an 'Add Input' button.
      </Typography>
      <Typography>
        3. Click on the 'Add Input' button to introduce a new input to the form.
      </Typography>
      <Typography>
        4. Choose from Text Field, Radio Button, Checkbox, or Submit Button to add to your form.
      </Typography>
      <Typography>
        5. Fill in the required details for your chosen input and click 'Submit'.
      </Typography>
      <div style={{marginLeft: '16px'}}>
        <Typography style={{marginTop: '8px', marginButton: '8px', fontSize: '18px'}}>
          What Each Input Requires:
        </Typography>
        <Typography style={{marginTop: '8px', marginBottom: '8px'}}>
          <strong>Text Field:</strong>
        </Typography>
        <Typography>
          <strong>Name:</strong> This is the unique identifier for the text field. It's what your system uses to recognize this specific field.
        </Typography>
        <Typography style={{marginTop: '8px', marginButton: '8px'}}>
          <strong>Label:</strong> This is the text that appears above the text box, telling users what information they should enter.
        </Typography>
        <Typography>
          <strong>Placeholder:</strong> This is sample text inside the text box that gives users a hint about what type of information they should input.
        </Typography>
        <Typography style={{marginTop: '8px', marginBottom: '8px'}}>
          <strong>Radio Button:</strong>
        </Typography>
        <Typography>
          <strong>Name:</strong> This is the unique identifier for each radio button in a group.
        </Typography>
        <Typography style={{marginTop: '8px'}}>
          <strong>Label:</strong> This is the text next to the radio button, indicating what the option represents.
        </Typography>
        <Typography style={{marginTop: '8px', marginBottom: '8px'}}>
          <strong>Checkbox:</strong>
        </Typography>
        <Typography>
          <strong>Name:</strong> This is the unique identifier for the checkbox.
        </Typography>
        <Typography style={{marginTop: '8px'}}>
          <strong>Label:</strong> This is the text next to the checkbox, indicating what the option is for.
        </Typography>
        <Typography style={{marginTop: '8px', marginBottom: '8px'}}>
          <strong>Submit Button:</strong>
        </Typography>
        <Typography>
          <strong>Text:</strong> This is the text that will appear on the button users click to submit the form.
        </Typography>
      </div>
      <Typography style={{marginTop: '8px', marginBottom: '8px'}}>
        6. Once you've filled out the necessary details for each input, you have two options:
      </Typography>
      <Typography style={{marginLeft: '16px'}}>
        1. Add More Fields: Click 'Add Input' again to continue adding more fields to the form.
      </Typography>
      <Typography style={{marginLeft: '16px'}}>
        2. Finalize the Form: Click 'Submit' to complete the form creation process and close the form dialog.
      </Typography>
      <Typography style={{marginTop: '16px', marginBottom: '8px', fontSize: '22px'}}>
        Adding Fields After Finalizing the Form:
      </Typography>
      <Typography>
        If you decide you want to add more fields after you've closed the form dialog, it's no problem!
        Simply drag the input type you want—like a button or input—from the asset panel and drop it into the existing form.
      </Typography>

    </>
  );
};

HowToAddFormFields.propTypes = {

};

export default HowToAddFormFields;