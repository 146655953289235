import Typography from "@material-ui/core/Typography";
import React from 'react';

const HowToPositionAssetsInContainer = () => {
  return (
    <>
      {/*TODO: TALK ABOUT THIS ONCE ADDED: You can also find the alignment widget on the action bar above the container when it is selected.*/}
      <Typography>
        Utilize our alignment widget to position assets within a container. This widget becomes accessible on the right-hand settings panel when a
        container is selected. It enables precise positioning of all assets, whether you aim for a top-left alignment or centered arrangement.
        The widget also lets you set the container's layout as either a row or a column. This structured approach ensures that Nimbus generates high-quality code.
      </Typography>
      <Typography style={{marginTop: '8px'}}>
        <strong>Note for Developers:</strong> Nimbus employs flexbox for container styling instead of using position: relative in the CSS.
      </Typography>
    </>
  );
};

export default HowToPositionAssetsInContainer;