const ImageFillInputStyle = theme => ({
  root: {
    width: '100%',
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: 'flex-start',
  },

  uploadButton: {
    width: "75px",
    marginTop: '8px'
  },

  rowContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
    alignItems: "center"
  },

  cancelIcon: {
    width: '25px',
    height: '25px',
    color: theme.palette.error.main
  },

});

export default ImageFillInputStyle;
