const SaveComponentDialogStyle = theme => ({
  content: {
    minWidth: '400px',
    padding: '16px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'flex-start'
  },

  textFieldContainer: {
    margin: '8px 0',
    width: '100%'
  },

  notchedOutline: {
    borderColor: "white !important",
  },

  autocompleteRoot: {
    marginTop: "10px",
    width: "100%",

    '& .MuiSvgIcon-root': {
      color: "white"
    }
  },
});

export default SaveComponentDialogStyle;
