const ChevronCountPanelStyle = theme => ({
  container: {
    borderTop: `2px solid ${theme.palette.background.default}`,
    borderBottom: `2px solid ${theme.palette.background.default}`,
    height: 50
  },
  arrowContainer: {
    border: `2px solid ${theme.palette.background.default}`,
    height: 50,
    cursor: 'pointer',
    '&:hover': {
      background: theme.palette.background.default
    },
  },
  text: {
    fontFamily: ['Montserrat', 'Yantramanav', 'Roboto', 'Open Sans'],
    fontSize: '19px'
  },
  [theme.breakpoints.down('xs')]: {
    text: {
      fontSize: '19px'
    }
  }
})

export default ChevronCountPanelStyle;
