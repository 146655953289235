import BaseDropDownMenu from "components/BaseDropDownMenu/BaseDropDownMenu";
import {EditorContext} from "editor/Editor";
import React, {useContext} from 'react';
import PropTypes from 'prop-types';
import {generateId} from "utils/utils";


const EditDropDownMenu = props => {
  const {
    classes,
    ...rest
  } = props;

  const editorData = useContext(EditorContext);
  const {editorReducerState, dispatch} = editorData;
  const {copyFromTree, pasteFromClipboard} = editorReducerState.editorActions

  const startCopy = () => {
    let elemToCopy = copyFromTree(editorReducerState.editorState.elementSelected.value)

    dispatch({
      type: 'setUserClipboard',
      payload: {
        clipboard: elemToCopy
      }
    })
  }

  const startPaste = () => {
    dispatch({
      type: 'pasteClipboard'
    })
  }

  const startCut = () => {
    let elemToCopy = copyFromTree(editorReducerState.editorState.elementSelected.value)

    if(elemToCopy) {
      dispatch({
        type: 'setUserClipboard',
        payload: {
          clipboard: elemToCopy
        }
      })

      dispatch({
        type: 'deleteAsset',
        payload: {
          editorId: elemToCopy.id
        }
      })
    }
  }

  const startDelete = () => {
    dispatch({
      type: 'deleteAsset',
      payload: {
        editorId: editorReducerState.editorState.elementSelected.value
      }
    })
  }

  const undo = () => {
    dispatch({
      type: 'undo'
    })
  }

  const redo = () => {
    dispatch({
      type: 'redo'
    })
  }

  const editMenuActions = [
    {
      title: 'Undo (Ctrl+Z)',
      action: undo
    },
    {
      title: 'Redo (Ctrl+Y)',
      action: redo
    },
    {
      title: 'Cut',
      action: startCut
    },
    {
      title: 'Copy (Ctrl+C)',
      action: startCopy
    },
    {
      title: 'Paste (Ctrl+V)',
      action: startPaste
    },
    {
      title: 'Delete',
      action: startDelete
    },
    // {
    //   title: 'Select All'
    // },
  ]

  return (
    <BaseDropDownMenu
      actions={editMenuActions}
      {...rest}
    />
  );
};

EditDropDownMenu.propTypes = {
  classes: PropTypes.object
};

export default EditDropDownMenu;
