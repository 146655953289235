import Typography from "@material-ui/core/Typography";
import React from 'react';

const HowToExportProject = () => {
  return (
    <>
      <Typography>
        You can export a project by clicking on the export button at the top-right corner of the editor. A dialog will appear and
        ask if you would like to export your project. After a few minutes, the code will be available to export. You can click
        on the completed link to download your code, or you can click on the link in the email we have sent to you!
      </Typography>
    </>
  );
};

HowToExportProject.propTypes = {

};

export default HowToExportProject;