import {Button, TextField, Typography, withStyles} from "@material-ui/core";
import cn from "classnames";
import {UserContext} from "context/UserContext";
import {Form, Formik} from "formik";
import {useEditorDialog} from "hooks/useEditorDialog";
import PropTypes from "prop-types";
import React, {useContext, useState} from 'react';
import {Link, useHistory} from "react-router-dom";
import {User} from "services";
import Cookies from "universal-cookie";
import {handleNetworkError} from "utils/utils";
import * as yup from "yup";

import LoginFormStyle from './LoginFormStyle';

const LoginForm = props => {
  const { classes, onSuccess, inEditor, switchDialogPages } = props;

  const {userData, setUserData} = useContext(UserContext);
  const history = useHistory();
  const [error, setError] = useState("")
  const [busy, setBusy] = useState(false)
  const registerDialog = useEditorDialog('loginRegister')

  const handleLoginSuccess = (userData) => {
    setUserData(userData.user)
    const cookies = new Cookies();
    cookies.set('token', userData.user.token, {path: '/'})
    console.log("response", userData.user)

    if(onSuccess) {
      onSuccess();
    }
    else {
      history.push('/dashboard')
    }
  }

  const onSubmit = async (values, {setErrors}) => {
    console.log("values", values)

    setBusy(true)
    User.login(values.email, values.password)
      .then(response => {
        handleLoginSuccess(response.data)
      })
      .catch(error => {
        let errorMsg = handleNetworkError(error, true, false);
        setError(errorMsg);
      })
      .finally(() => {
        setBusy(false)
      })

  };

  const schema = yup.object().shape({
    email: yup.string()
      .email('Must be a valid email')
      .required('Email is a required field'),
    password: yup.string()
      .min(8, 'Password must be at least ${min} characters')
      .required('Password is a required field'),
  });

  const googleResponseSuccess = (response) => {
    console.log("response", response)

    let dataToPost = {
      name: response.profileObj.name,
      email: response.profileObj.email,
      googleId: response.profileObj.googleId,
      googleIdToken: response.tokenId
    }

    console.log('post to django', dataToPost)
    setBusy(true)
    User.register(dataToPost)
      .then(response => {
        console.log('google user success!', response);
        handleLoginSuccess(response.data)
      })
      .catch(error => {
        let errorMsg = handleNetworkError(error, true, false);
        setError(errorMsg);
      })
      .finally(() => {
        setBusy(false)
      })
  }

  const googleResponseFailure = (error) => {
    console.error('error', error);
  }

  const openRegisterDialog = () => {

  }

  return (
    <div className={classes.content}>
      <div className={classes.title}>
        <Typography variant="h3">
          Login to Your Account
        </Typography>
      </div>

      <div className={classes.subtitle}>
        <Typography variant="h6">
          Access the future
        </Typography>
      </div>

      {error && (
        <Typography className={classes.errorText}>
          {error}
        </Typography>
      )}

      <Formik
        initialValues={{
          email: "",
          password: "",
        }}
        onSubmit={onSubmit}
        validationSchema={schema}
        validateOnChange={false}
      >
        {({
            values,
            errors,
            handleChange,
          }) => (
          <Form>
            <div className={classes.formContainer}>

              {/*<GoogleLogin*/}
              {/*  clientId="747391998207-g66q0a0rn18t71490ijs7qg0u6h26ked.apps.googleusercontent.com"*/}
              {/*  buttonText="Sign up with Google"*/}
              {/*  onSuccess={googleResponseSuccess}*/}
              {/*  onFailure={googleResponseFailure}*/}
              {/*  cookiePolicy={'single_host_origin'}*/}
              {/*  render={renderProps => (*/}
              {/*    <Button*/}
              {/*      onClick={renderProps.onClick}*/}
              {/*      disabled={renderProps.disabled || busy}*/}
              {/*      className={classes.googleAccountButton}*/}
              {/*      variant="outlined"*/}
              {/*    >*/}
              {/*      <img src={googleIcon} className={classes.googleIcon}/>*/}
              {/*      Login with Google*/}
              {/*    </Button>*/}
              {/*  )}*/}
              {/*/>*/}


              {/*<div>*/}
              {/*  <Typography variant="body1">*/}
              {/*    or*/}
              {/*  </Typography>*/}
              {/*</div>*/}
              <div className={classes.formInputContainer}>
                <TextField
                  className={classes.formInput}
                  placeholder="Email"
                  name="email"
                  error={!!errors.email}
                  helperText={errors.email}
                  onChange={handleChange}
                  value={values.email}
                  variant="outlined"
                  fullWidth
                  InputProps={{
                    classes: {input: classes.input},
                  }}
                />
              </div>
              <div className={classes.formInputContainer}>
                <TextField
                  className={classes.formInput}
                  placeholder="Password"
                  name="password"
                  type="password"
                  error={!!errors.password}
                  helperText={errors.password}
                  value={values.password}
                  onChange={handleChange}
                  variant="outlined"
                  fullWidth
                  InputProps={{
                    classes: {input: classes.input},
                  }}
                />
              </div>

              <Button
                variant="outlined"
                size="medium"
                type="submit"
                disabled={busy}
                className={classes.loginAccountButton}
              >
                Login to Your Account
              </Button>
            </div>
          </Form>
        )}
      </Formik>

      <div className={classes.registerContainer}>
        {!inEditor && (
          <Typography variant="body1">
            <Link
              to="/forgot-password"
              className={cn(classes.purpleText, classes.forgotPassword)}
            >
              Forgot password?
            </Link>
          </Typography>
        )}

        <Typography variant="body1">
          Not a member yet?
          {' '}

          {inEditor ? (
            <span
              className={classes.purpleText}
              onClick={() => switchDialogPages('register')}
            >
              Register Now
            </span>
          ) : (
            <Link to="/register" className={classes.purpleText}>
              Register now
            </Link>
          )}

        </Typography>
      </div>
    </div>
  );
};

LoginForm.propTypes = {
  classes: PropTypes.object
};

export default withStyles(LoginFormStyle)(LoginForm);
