import {withStyles} from '@material-ui/core';
import Typography from "@material-ui/core/Typography";
import ChangePasswordCard from "components/AccountSettings/Profile/ChangePasswordCard/ChangePasswordCard";
import ContactInfoCard from "components/AccountSettings/Profile/ContactInfoCard/ContactInfoCard";
import NotificationCard from "components/AccountSettings/Profile/NotificationCard/NotificationCard";
import PropTypes from 'prop-types';
import React from 'react';
import ProfileStyle from './ProfileStyle';

const Profile = props => {
  const {
    classes,
    pageClasses,
    style,
  } = props;

  return (
    <div className={classes.root} style={{...style}}>
      <Typography
        variant={'h6'}
        className={pageClasses.pageContentTitle}
      >
        Profile
      </Typography>

      <div className={pageClasses.divider}/>

      <div className={pageClasses.pageContent}>
        <ContactInfoCard pageClasses={pageClasses}/>

        <ChangePasswordCard pageClasses={pageClasses}/>

        <NotificationCard pageClasses={pageClasses}/>
      </div>
    </div>
  );
};

Profile.propTypes = {
  classes: PropTypes.object,
  pageClasses: PropTypes.object,
  style: PropTypes.object,
};

export default withStyles(ProfileStyle)(Profile);