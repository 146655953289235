import ElementSettingsGroupLabel from "components/ElementSettingGroupLabel/ElementSettingsGroupLabel";
import StyledTextField from "components/StyledTextField/StyledTextField";
import {EditorContext} from "editor/Editor";
import ColorPickerInput from "editor/layout/ElementSettings/Inputs/ColorPickerInput/ColorPickerInput";
import React, {useContext, useEffect, useRef, useState} from 'react';

import {
  Tooltip,
  withStyles
} from '@material-ui/core';
import PropTypes from 'prop-types';

import BoxShadowInputStyle from './BoxShadowInputStyle';

const BoxShadowInput = props => {
  const {
    classes,
    onChange,
    onBlur,
    onFocus,
    value,
    setting,
  } = props;

  const [shadowColor, setShadowColor] = useState('#000000');
  const [horizontalShadowLength, setHorizontalShadowLength] = useState('0px');
  const [verticalShadowLength, setVerticalShadowLength] = useState('0px');
  const [blurRadius, setBlurRadius] = useState('0px')
  const [spreadRadius, setSpreadRadius] = useState('0px')
  const [touched, setTouched] = useState(false);
  const [innerBoxShadowCSS, setInnerBoxShadowCSS] = useState(null);
  const mounted = useRef(false);

  const editorData = useContext(EditorContext)
  const {editorReducerState, dispatch} = editorData
  const { editorState, editorActions } = editorReducerState;
  const { elementTree } = editorState;
  const elementSelectedId = editorState.elementSelected.value;

  const generateBoxShadowCSS = () => {
    let generatedCSS =
      `${shadowColor} ${horizontalShadowLength} ${verticalShadowLength} ${blurRadius} ${spreadRadius}`;

    console.log('generated CSS', generatedCSS)
    console.log('inner box shadow CSS', innerBoxShadowCSS)
    if(generatedCSS !== innerBoxShadowCSS) {
      onChange(generatedCSS);
    }

    setInnerBoxShadowCSS(generatedCSS);
  }

  useEffect(() => {
    setTouched(false)
  }, [elementSelectedId])

  useEffect(() => {
    if(mounted.current) {
      if(touched) {
        generateBoxShadowCSS();
      }
    }
    else {
      mounted.current = true;
    }
  }, [
    horizontalShadowLength,
    verticalShadowLength,
    blurRadius,
    spreadRadius,
    shadowColor,
    // do not add touched here or it will crash the entire site when used
  ]);

  useEffect(() => {
    if(value) {
      console.log('set border picker values', value)
      let splitValue = value.split(' ');
      console.log('box shadow split value', splitValue)
      if(splitValue.length === 5) {
        setShadowColor(splitValue[0])
        setHorizontalShadowLength(splitValue[1]);
        setVerticalShadowLength(splitValue[2]);
        setBlurRadius(splitValue[3]);
        setSpreadRadius(splitValue[4])
      }
    }
  }, [value])

  const handleChange = (newValue, setFunc) => {
    setTouched(true)
    setFunc(newValue);
  }

  // we need a separate useEffect because this only needs to run  unmount
  useEffect(() => {
    return () => {
      mounted.current = false;
    }
  }, [])

  return (
    <div>
      <ElementSettingsGroupLabel setting={setting}>
        Box Shadow
      </ElementSettingsGroupLabel>

      <div className={classes.root}>
        <ColorPickerInput
          showTitle={false}
          showTextField={false}
          className={classes.colorPicker}
          value={shadowColor}
          onChange={color => handleChange(color, setShadowColor)}
          onBlur={onBlur}
          onFocus={onFocus}
          defaultColor={'#000000'}
          removable
        />

        <Tooltip
          title={'X'}
          arrow
        >
          <StyledTextField
            formControlClassName={classes.input}
            value={horizontalShadowLength}
            onBlur={onBlur}
            onFocus={onFocus}
            onChange={e => handleChange(e.target.value, setHorizontalShadowLength)}
          />
        </Tooltip>

        <Tooltip
          title={'Y'}
          arrow
        >
          <StyledTextField
            formControlClassName={classes.input}
            value={verticalShadowLength}
            onBlur={onBlur}
            onFocus={onFocus}
            onChange={e => handleChange(e.target.value, setVerticalShadowLength)}
          />
        </Tooltip>

        <Tooltip
          title={'Blur'}
          arrow
        >
          <StyledTextField
            formControlClassName={classes.input}
            value={blurRadius}
            onBlur={onBlur}
            onFocus={onFocus}
            onChange={e => handleChange(e.target.value, setBlurRadius)}
          />
        </Tooltip>


        <Tooltip
          title={'Spread'}
          arrow
        >
          <StyledTextField
            formControlClassName={classes.input}
            value={spreadRadius}
            onBlur={onBlur}
            onFocus={onFocus}
            onChange={e => handleChange(e.target.value, setSpreadRadius)}
          />
        </Tooltip>
      </div>
    </div>
  );
};

BoxShadowInput.propTypes = {
  classes: PropTypes.object,
  onChange: PropTypes.func.isRequired,
  onBlur: PropTypes.func,
  onFocus: PropTypes.func,
  setting: PropTypes.object.isRequired,
  value: PropTypes.string,
};

export default withStyles(BoxShadowInputStyle)(BoxShadowInput);
