const DocumentDialogStyle = theme => ({
  dialogTitle: {
    width: "100%",
    paddingBottom: '14px',

    '& .MuiTypography-root': {
      display: "flex",
      flexDirection: "row",
      width: "100%",
      justifyContent: "space-between",
    },
  },

  closeIcon: {
    color: "white",

    '&:hover':{
      background: "#3a3a4a",
      transition: "0.3s",
    },
  },

  dialogRoot: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'center',
    padding: '0 30px',

    '&::-webkit-scrollbar': {
      height: '100%',
      width: '2px',
      position: 'relative',
      marginRight: '2px',
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: 'white',
    },
    '&::-webkit-scrollbar-track': {
      backgroundColor: '#262626',
      opacity: '0.48'
    },

    [theme.breakpoints.down('500')]: {
      padding: '0 20px',
    },
  },

  document: {
    border: 'solid 1px black',
  },

  paginationPanel: {
    width: '100%',
    marginTop: '15px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  }
});

export default DocumentDialogStyle;
