import Typography from "@material-ui/core/Typography";
import Canvas_Breakpoint_Styles from "assets/education/videos/canvas_breakpoint_styles.mp4";
import Canvas_Visibility from "assets/education/videos/canvas_visibility.mp4";
import React from 'react';
import PropTypes from 'prop-types';

const HowToMakeResponsiveSite = props => {
  const { classes } = props;

  return (
    <>
      <Typography>
        To make a site responsive on all devices, you first need to select what type of device you want to work on. In the bottom-right corner of the canvas there is a device
        widget that let's you select between desktop, tablet, and mobile devices. Upon selecting a device, the canvas will change in size to reflect the screen size of that device.
      </Typography>
      <Typography style={{marginTop: '8px'}}>
        The next step is making assets on your site responsive. For instance, say on desktop I wanted an image to appear in the header of my site, but on mobile I want
        the image to be removed. I would select the image asset, and at the top of the right-hand settings panel there is a widget that controls visibility on devices
        and whether or not you want to share styles between devices. If I wanted an image to appear on desktop and tablet devices, but not on mobile, I would click on the
        mobile device visibility icon to make the image not visible on that device. The icon will appear white and have a no visibility icon next to it to represent that
        the image will not be visible on that device.
      </Typography>
      <video
        width="534"
        height="315"
        loop
        autoPlay
        muted
        controls={false}
      >
        <source src={Canvas_Visibility} type="video/mp4" />
      </video>
      <Typography style={{marginTop: '8px', marginBottom: '8px'}}>
        Now, say that I want the image to appear on desktop and tablet devices, but I want the image to be smaller on tablet. I would click on the link icon between the
        desktop and tablet, breaking the link between these devices and making it so that styles applied to the image on desktop are not shared between the styles applied
        on tablet devices. The link icon will appear white and have a line over the link, meaning the link is broken. I can then make my image bigger on desktop and smaller
        on tablet sizes.
      </Typography>
      <div style={{width: '528px', height: '297px'}}>
        <video
          style={{width: '100%', height: '100%'}}
          loop
          autoPlay
          muted
          controls={false}
        >
          <source src={Canvas_Breakpoint_Styles} type="video/mp4" />
        </video>
      </div>

      {/*<div style={{padding:'56.25% 0 0 0', position:'relative', marginTop: '8px'}}>*/}
      {/*  <iframe*/}
      {/*    src="https://player.vimeo.com/video/844915184?autoplay=1&loop=1&controls=0&title=0&byline=0&portrait=0&badge=0"*/}
      {/*    frameBorder="0"*/}
      {/*    allow="autoplay; fullscreen; picture-in-picture"*/}
      {/*    allowFullScreen*/}
      {/*    style={{position:'absolute', top:0, left:0, width:'100%', height:'100%'}}*/}
      {/*    title="canvas_breakpoint_styles">*/}
      {/*  </iframe>*/}
      {/*</div>*/}
    </>
  );
};

HowToMakeResponsiveSite.propTypes = {
  classes: PropTypes.object
};

export default HowToMakeResponsiveSite;