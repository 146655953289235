import Typography from "@material-ui/core/Typography";
import React from 'react';

const WhatIsExporting = () => {
  return (
    <>
      <Typography>
        Exporting a project allows you to turn what you built inside of the editor into high-quality React code. You are then free to take this code and use it as you please!
      </Typography>
    </>
  );
};

WhatIsExporting.propTypes = {

};

export default WhatIsExporting;