import {Dialog, DialogActions, DialogContent, DialogTitle, withStyles} from '@material-ui/core';
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import PropTypes from 'prop-types';
import React from 'react';
import ConfirmationDialogStyle from './ConfirmationDialogStyle';

const ConfirmationDialog = props => {
  const {
    classes,
    open,
    setOpen,
    data,
  } = props;

  const handleClose = () => {
    setOpen(false)
  }

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      maxWidth={'md'}
    >
      <DialogTitle>
        Confirmation
      </DialogTitle>

      <DialogContent>
        <Typography
          variant={'body1'}
          className={classes.text}
        >
          {data.text}
        </Typography>
      </DialogContent>

      <DialogActions>
        <Button
          onClick={data.onSuccess}
          variant={'contained'}
          className={classes.purpleButton}
        >
          Yes
        </Button>
        <Button
          onClick={handleClose}
          variant={'contained'}
          className={classes.outlinedButton}
        >
          No
        </Button>
      </DialogActions>
    </Dialog>
  );
};

ConfirmationDialog.propTypes = {
  classes: PropTypes.object,
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
  data: PropTypes.object.isRequired
};

export default withStyles(ConfirmationDialogStyle)(ConfirmationDialog);
