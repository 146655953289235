import {purpleButton} from "assets/theme";

const AccountSettingsStyle = theme => ({
  root: {
    width: "100%",
    height: "100%",
    display: "flex",
    backgroundColor: theme.palette.primary.main,

    [theme.breakpoints.down('sm')]: {
      width: "90%",
      flexDirection: "column"
    },
    [theme.breakpoints.down('xs')]: {
      width: "95%",
    },
  },

  listOuterContainer: {
    background: "rgba(138, 86, 255, 0.2)",
    flex: 1,
    width: "200px",
    flexGrow: 0,

    [theme.breakpoints.down('sm')]: {
      display: "none"
    },
  },

  mobileListOuterContainer: {
    display: "none",

    [theme.breakpoints.down('sm')]: {
      display: "flex",
      background: "rgba(138, 86, 255, 0.2)",

      '& .MuiList-padding': {
        padding: 0,
      }
    },
  },

  mobileListContainer: {
    display: "flex",
    flexDirection: "row"
  },

  listContainer: {
    width: "200px",
    padding: 0,
    color: "black",

    [theme.breakpoints.down('md')]: {
      width:"150px",
    },
    [theme.breakpoints.down('xs')]: {
      width:"125px",
    },
  },

  activeListItem: {
    background: theme.palette.secondary.main,
    color: "white",
  },

  pageContainer: {
    flex: "1",
    display: "flex",
    padding: "20px",
    height: "100%",

    [theme.breakpoints.down('sm')]: {
      padding: "10px 8px",
      height: "90%"
    },
  },

  pageContentTitle: {
    color: "#404040",
    [theme.breakpoints.down('sm')]: {
      fontSize: "1.0rem"
    },
  },

  divider: {
    borderBottom: "1px solid rgba(125, 125, 125, 0.6)",
    width: "100%",
  },

  pageContent: {
    marginTop: "20px",
    width: "100%",
    height: "100%",
    overflowY: "auto",

    '&::-webkit-scrollbar': {
      height: '100px',
      width: '2px',
      position: 'relative',
      marginRight: '2px',
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: '#262626',
    },
    '&::-webkit-scrollbar-track': {
      backgroundColor: 'white',
      opacity: '0.48'
    },
  },

  pageCard: {
    backgroundColor: "#F8F8F8",
    color: theme.palette.common.black,
    width: "100%",
    maxWidth: "750px",
    marginBottom: "20px",
  },


  cardContent: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: 'flex-start',
    width: "100%",
    height: "100%",
  },

  colContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: 'flex-start',
    width: "100%",
    height: "100%",
    marginBottom: "10px",
    padding: 0,
  },

  textfield: {
    '& .MuiInputBase-input': {
      color: "black",
    },
  },

  notchedOutline: {
    borderColor: "black !important",
  },

  buttonContainer: {
    width: "100%",
    padding: 0,
  },

  purpleButton: {
    ...purpleButton,
    marginLeft: "auto",
  },

  colTitle: {
    marginBottom: "5px",
    [theme.breakpoints.down('sm')]: {
      fontSize: "0.9rem"
    },
  }

});

export default AccountSettingsStyle;
