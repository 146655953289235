import {withStyles} from '@material-ui/core';
import {UserContext} from "context/UserContext";
import {EditorContext} from "editor/Editor";
import {useAssetConfig} from "hooks/useAssetConfig";
import PropTypes from 'prop-types';
import React, {useContext, useEffect, useState} from 'react';
import Joyride, { ACTIONS, EVENTS, STATUS } from 'react-joyride';
import {User} from "services";
import {generateId, handleNetworkError} from "utils/utils";
import ReactJoyrideStyle from './ReactJoyrideStyle';

const ReactJoyride = props => {
  const {
    classes,
    showJoyrideOverride=false
  } = props;

  const [selectedAssetId, setSelectedAssetId] = useState(null);
  const [run, setRun] = useState(false)
  const [stepIndex, setStepIndex] = useState(0)
  const [steps, setSteps] = useState([])
  const [userDisabled, setUserDisabled] = useState(false);
  const [shouldShowTutorial, setShouldShowTutorial] = useState(false);

  // used to reset asset names to what they were at the start of this joyride
  // we don't want to always reset to no asset names, in the case of a user
  // clicking this in the middle of a project.
  const [assetNamesBeforeJoyride, setAssetNamesBeforeJoyride] = useState([]);

  const editorData = useContext(EditorContext);
  const {userData, setUserData} = useContext(UserContext);
  const {editorReducerState, dispatch} = editorData;
  const assetConfig = useAssetConfig();

  useEffect(() => {
    console.log('user data', userData)

    if(userData === false || (userData?.firstTimeUser && !userDisabled) || showJoyrideOverride) {

      setAssetNamesBeforeJoyride([...editorReducerState.editorState.layerLabelsInUse]);

      const assetId = generateId()
      dispatch({
        type: 'createAsset',
        payload: {
          parentElem: document.getElementById('editor-root'),
          elementToCreate: {
            ...assetConfig.ContainerDraggable,
            id: assetId,
            props: {
              ...assetConfig.ContainerDraggable.sharedProps,
              id: assetId,
              'data-editorid': assetId,
              htmlId: assetId,
              key: assetId,
            },
            draggableProps: {
              ...assetConfig.ContainerDraggable.draggableProps,
              id: assetId
            }
          }
        }
      })

      console.log('asset id', assetId)
      setSelectedAssetId(assetId)
      setShouldShowTutorial(true);
    }
  },[userData, showJoyrideOverride])

  useEffect(() => {
    console.log('selectedAssetId', selectedAssetId)
    if(selectedAssetId && shouldShowTutorial) {
      setRun(true)
      setSteps([
        {
          //target name is based off of index in step array
          target: '#project-name',
          content: 'Welcome to Nimbus! Start by giving your project a name!',
          disableBeacon: true,
        },
        {
          target: '#canvas-size',
          content: 'You can change the size of your project by clicking on the canvas!',
          disableBeacon: true,
        },
        {
          target: '#canvas-theme',
          content: 'Give the canvas settings, including a theme!',
          disableBeacon: true,
        },
        {
          target: '#add-page',
          content: 'Add pages to build your website. Right click on a page to set up routing.',
          disableBeacon: true,
          tab: 'pages'
        },
        // {
        //   target: '#page-label',
        //   content: 'Right click to rename the page and set up routing by changing the page URL.',
        //   disableBeacon: true,
        // },
        {
          target: '#assets',
          content: 'Choose from our collection of assets.',
          disableBeacon: true,
          shouldSelect: true,
          tab: 'assets'
        },
        {
          //index 5
          target: `[data-editorid="${selectedAssetId}"]`,
          content: 'Start building your website by dragging and dropping the desired asset onto the canvas.',
          disableBeacon: true,
        },
        {
          target: '#asset-style',
          content: 'Customize the asset to match your style.',
          disableBeacon: true,
        },
        {
          target: '#joyride-visibility',
          content: 'Make your asset responsive by setting its visibility on all screen sizes.',
          disableBeacon: true,
        },
        {
          target: '#joyride-style',
          content: 'Make your asset share styles between screen sizes. Unlink this to customize styles by breakpoint.',
          disableBeacon: true,
        },
        {
          //index 7
          target: '#layers-root',
          content: 'Once an asset is on the canvas, it will be added to Layers. Here is where you control how assets interact with each other.',
          disableBeacon: true,
        },
        {
          target: '#export',
          content: 'When you are finished building, export your creation and you will receive a link to your code!',
          disableBeacon: true,
        },

      ])
    }
  },[selectedAssetId, shouldShowTutorial])

  const joyrideCallback = (callbackProps) => {
    const { action, index, status, type } = callbackProps;

    if ([EVENTS.STEP_AFTER, EVENTS.TARGET_NOT_FOUND].includes(type)) {
      if((index + 1 < steps.length) && steps[index + 1]?.tab) {
        dispatch({
          type: 'setCurrentTab',
          payload: {
            currentTab: steps[index + 1].tab
          }
        })
      }

      // Update state to advance the tour
      setStepIndex( index + (action === ACTIONS.PREV ? -1 : 1));
    }
    else if ([STATUS.FINISHED, STATUS.SKIPPED].includes(status)) {
      // Need to set our running state to false, so we can restart if we click start again.
      setRun(false);
      setUserDisabled(true);
      setShouldShowTutorial(false);

      dispatch({
        type: 'setCurrentTab',
        payload: {
          currentTab: null
        }
      })

      try {
        dispatch({
          type: 'deleteAsset',
          payload: {
            editorId: selectedAssetId
          }
        })
      }
      catch (e) {}

      try {
        dispatch({
          type: 'setEditorState',
          payload: {
            layerLabelsInUse: []
          }
        })
      }
      catch (e) {}

      if(!showJoyrideOverride) {
        User.disableTutorial()
          .then(response => {
            console.log('tutorial disabled')
            const newUserData = {
              ...userData,
              firstTimeUser: false
            }
            setUserData(newUserData)
          })
          .catch(error => {
            // don't notify the user here
            handleNetworkError(error, false, false)
          })
      }
      else {
        dispatch({
          type: 'endJoyrideOverride',
          payload: {
            layerLabelsInUseBeforeJoyride: assetNamesBeforeJoyride
          }
        });
      }
    }

    if(callbackProps.action === 'next') {
      console.log('callback Props', callbackProps)

      if(callbackProps.step.shouldSelect && callbackProps.type === 'step:after') {
        dispatch({
          type: 'selectAssetById',
          payload: {
            editorId: selectedAssetId
          }
        })
      }

      if(callbackProps.step.tab  && callbackProps.type === 'step:before') {
        dispatch({
          type: 'setCurrentTab',
          payload: {
            currentTab: callbackProps.step.tab
          }
        })
      }
    }
  }

  if(!selectedAssetId || steps.length === 0) return null

  return (
    <Joyride
      steps={steps}
      continuous
      showSkipButton
      disableOverlayClose
      run={run}
      stepIndex={stepIndex}
      disableScrolling
      callback={joyrideCallback}
      hideCloseButton
      styles={{
        options: {
          primaryColor: '#8A56FF',
          textColor: "white",
          backgroundColor: "#222229",
          arrowColor: "#222229",
          zIndex: 9999,
        },
        tooltip: {
          fontSize: "22px",
          fontFamily: "Roboto"
        },
        buttonNext: {
          fontSize: "18px",
          fontFamily: "Roboto",
        },
        buttonSkip: {
          fontSize: "18px",
          fontFamily: "Roboto"
        },
        buttonBack: {
          color: "white"
        },
        buttonClose: {
          display: 'none'
        }
      }}
    />
  );
};

ReactJoyride.propTypes = {
  classes: PropTypes.object,
};

export default withStyles(ReactJoyrideStyle)(ReactJoyride);
