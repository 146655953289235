const FontLayoutStyle = theme => ({
  fontLayoutRoot: {
    width: '100%',
  },

  fontLayoutInner: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'stretch',
    margin: '8px 0',
  },

  selectFormControl: {
    width: '100%'
  },

  row: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'flex-start',

    '& > *:first-of-type': {
      flex: 6
    },

    '& > *:last-of-type': {
      flex: 4
    }
  },

  select: {
    width: '100%'
  },

  fontSizeContainer: {
    marginBottom: '8px',

    '& .MuiMenu-paper': {
      maxHeight: "300px"
    },


  }
});

export default FontLayoutStyle;
