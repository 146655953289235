//special variable provided by MaterialUI to override their default colors
//we use this in index.js
const MuiTheme = {
  palette: {
    common: {
      black: '#000',
      white: '#fff',
      gray: '#878787'
    },
    background: {
      paper: '#222229',
      default: '#222229',
    },
    primary: {
      main: '#F3F3F3',
      hover: '#8A56FF',
      contrastText: '#000'
    },
    secondary: {
      main: '#8A56FF',
      hover: '#5C3CA4',
      contrastText: '#F3F3F3',
    },
    gridLines: {
      main: '#4AFCE4'
    },
    popperMenu: {
      background: "#1B1C20"
    },
    error: {
      light: '#e57373',
      main: '#ED3E3B',
      dark: '#d32f2f',
      contrastText: '#fff'
    },
    text: {
      primary: '#F5F5F5',
      secondary: '#878787',
      disabled: '#878787',
      hint: '#878787'
    },
    editor: {
      selected: '#353544'
    },
    card: {
      divider: '#d5d5d5'
    }
  },
  typography: {
    fontFamily: [
      'Roboto',
      'Arial',
      'sans-serif',
    ].join(','),
  },

  overrides: {
    MuiButton: {
      root: {
        fontFamily: "Roboto",
        fontSize: "16px",
        textTransform: "none",
      },
    },
  },
};

const root = {
  margin: '0px',
  padding: '0px',
  height: `100vh`,
  overflow: 'auto',
  width: '100%'
};

const purpleButton = {
  borderColor: MuiTheme.palette.secondary.main,
  padding: "5px 35px",
  transition: "0.3s",
  backgroundColor: MuiTheme.palette.secondary.main,
  color: MuiTheme.palette.primary.main,

  '&:hover': {
    backgroundColor: MuiTheme.palette.secondary.hover,
    color: MuiTheme.palette.primary.main,
    borderColor: MuiTheme.palette.secondary.main,
  },
};

const outlinedButton = {
  borderColor: MuiTheme.palette.primary.main,
  padding: "5px 35px",
  transition: "0.3s",

  '&:hover': {
    backgroundColor: MuiTheme.palette.primary.main,
    color: MuiTheme.palette.primary.contrastText
  },
};

const outlinedPurpleHoverButton = {
  borderColor: MuiTheme.palette.primary.main,
  padding: "5px 35px",
  transition: "0.3s",

  '&:hover': {
    backgroundColor: MuiTheme.palette.primary.hover,
    color: MuiTheme.palette.primary.main,
    borderColor: MuiTheme.palette.primary.hover,
  },
};

const outlinedBlackBorderButton = {
  borderColor: MuiTheme.palette.common.black,
  color: MuiTheme.palette.common.black,
  padding: "5px 35px",
  transition: "0.3s",

  '&:hover': {
    backgroundColor: MuiTheme.palette.primary.main,
    color: MuiTheme.palette.common.black
  },
}

const purpleText = {
  color: MuiTheme.palette.secondary.main,
  fontWeight: "500",
};

export { root, MuiTheme, purpleButton, outlinedButton, purpleText, outlinedPurpleHoverButton, outlinedBlackBorderButton};
