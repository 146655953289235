import ButtonDraggable from "editor/draggables/ButtonDraggable/ButtonDraggable";
import BaseComponentSerializer from "editor/serializers/BaseComponentSerializer";
import {useAssetConfig} from "hooks/useAssetConfig";
import React from "react";

class ButtonDraggableSerializer extends BaseComponentSerializer {
  constructor(elem, editorData) {
    super(elem, editorData);
    this.propsToRemove = ['layerIconNode', 'key'];
    this.assetConfig = useAssetConfig();
    console.log('test', this.assetConfig.ButtonDraggable)
  }

  deserialize() {
    return {
      tag: ButtonDraggable,
      tagName: 'ButtonDraggable',
      props: {
        //layerLabel: this.assetConfig.ButtonDraggable.props.layerLabel,
        layerIconNode: this.assetConfig.ButtonDraggable.sharedProps.layerIconNode
      },
      baseData: {
        ...this.elem?.baseData,
        layerIconNode: this.assetConfig.ButtonDraggable.sharedProps.layerIconNode
      }
    }
  }

  serialize() {
    return {
      tag: 'ButtonDraggable',
      id: this.elem.id,
      draggableProps: {
        style: {
          ...this.getDraggablePosition()
        }
      },
      props: {
        ...this.formatProps({...this.elem.props}),
        //style: {...this.getElementStyles()}
      },
      baseData: {
        ...this.formatProps(this.elem.baseData)
      },
      breakpointData: {
        ...this.formatProps(this.elem.breakpointData)
      }
    };
  }
}

export default ButtonDraggableSerializer;
