import {
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  TextField,
  withStyles
} from '@material-ui/core';
import Button from "@material-ui/core/Button";
import {Autocomplete} from "@material-ui/lab";
import {EditorContext} from "editor/Editor";
import {Form, Formik} from "formik";
import PropTypes from 'prop-types';
import React, {useContext, useEffect, useState} from 'react';
import {User} from "services";
import {handleNetworkError} from "utils/utils";

import SaveComponentDialogStyle from './SaveComponentDialogStyle';

const SaveComponentDialog = props => {
  const {classes, open, setOpen, data} = props;

  const editorData = useContext(EditorContext);
  const {editorReducerState, dispatch} = editorData;
  const savedComponentCategories = editorReducerState.editorState.savedComponentCategories;
  const {editorState, editorActions} = editorReducerState;

  const [categoryOptions, setCategoryOptions] = useState([]);
  const [isPublic, setIsPublic] = useState(true);
  const [busy, setBusy] = useState(false);

  const handleClose = () => {
    setOpen(false);
  }

  const handleCategoryChange = (e, value, reason, setFieldValue, isInputChange) => {
    console.log('handleCategoryChange', {value, reason, setFieldValue, isInputChange})

    setFieldValue('category', value)
  }

  const onSubmit = async (values, {setErrors}) => {
    console.log('values!', {values, data})

    setBusy(true);

    const onSuccess = () => {
      setBusy(false);
      data.onSuccess();
      handleClose();
    }

    const onFail = () => {
      setBusy(false);
    }

    editorActions.saveComponentToLibrary(
      data.elementEditorId,
      values.name,
      values.category,
      isPublic,
      onSuccess,
      onFail
    );
  };

  // useEffect(() => {
  //   let formattedOptions = [];
  //   for(let [key, value] of Object.entries(savedComponentCategories)) {
  //     formattedOptions.push({label: key, value: key})
  //   }
  //
  //   setCategoryOptions([...formattedOptions])
  // }, [savedComponentCategories])

  useEffect(() => {
    // get the available options for categories from server
    User.listPublicComponentCategories().then(response => {
      console.log('res', response)

      setCategoryOptions(Object.keys(response.data));
    }).catch(handleNetworkError)
  }, [])

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      maxWidth={'md'}
    >
      <DialogTitle>
        Save Component
      </DialogTitle>

      <Formik
        initialValues={{
          name: '',
          category: ''
        }}

        onSubmit={onSubmit}
      >
        {({
            values,
            errors,
            handleChange,
            setFieldValue,
          }) => (
          <Form>
            <DialogContent className={classes.content}>
              <div className={classes.textFieldContainer}>
                <TextField
                  name={'name'}
                  label={'Name'}
                  onChange={handleChange}
                  value={values.name}
                  error={!!errors.name}
                  helperText={errors.name}
                  variant={'outlined'}
                  InputProps={{
                    classes: {
                      notchedOutline: classes.notchedOutline,
                    },
                  }}
                  fullWidth
                />
              </div>

              <div className={classes.textFieldContainer}>
                <Autocomplete
                  fullWidth
                  //freeSolo
                  name={'category'}
                  options={categoryOptions}
                  classes={{root: classes.autocompleteRoot}}
                  // onInputChange={
                  //   (
                  //     e,
                  //     value,
                  //     reason
                  //   ) => handleCategoryChange(e, value, reason, setFieldValue, true)}
                  onChange={
                    (
                      e,
                      value,
                      reason
                    ) => handleCategoryChange(e, value, reason, setFieldValue, false)}
                  renderOption={(option) => (
                    <>
                      {option}
                    </>
                  )}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label={'Category'}
                      variant={'outlined'}
                      fullWidth
                      inputProps={{
                        ...params.inputProps,
                      }}
                      InputProps={{
                        ...params.InputProps,
                        classes: {
                          notchedOutline: classes.notchedOutline,
                        },
                      }}
                    />
                  )}
                />
              </div>

              <div className={classes.textFieldContainer}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={isPublic}
                      onChange={() => setIsPublic(!isPublic)}
                      name={'isPublic'}
                    />
                  }
                  label={'Save this as a public component'}
                />
              </div>
          </DialogContent>

            <DialogActions>
              <div>
                <Button
                  type={'submit'}
                  variant={'contained'}
                  color={'secondary'}
                  disabled={busy}
                >
                  Submit
                </Button>
              </div>

              <div>
                <Button
                  variant={'contained'}
                  onClick={handleClose}
                  disabled={busy}
                >
                  Cancel
                </Button>
              </div>
            </DialogActions>
          </Form>
        )}
      </Formik>
    </Dialog>
  );
};

SaveComponentDialog.propTypes = {
  classes: PropTypes.object
};

export default withStyles(SaveComponentDialogStyle)(SaveComponentDialog);
