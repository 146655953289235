import DraggableWrapper from "editor/draggables/DraggableWrapper/DraggableWrapper";
import formatStyles from "editor/draggables/formatStyles";
import React, {useMemo} from 'react';
import {Checkbox, withStyles} from '@material-ui/core';
import PropTypes from 'prop-types';
import CheckboxDraggableStyle from './CheckboxDraggableStyle';
import CheckBoxIcon from "@material-ui/icons/CheckBox";

const CheckboxDraggable = props => {
  const {
    classes,
    style,
    editorId,
    draggableProps,
    isChildElem,
    absolutePositioning,
  } = props;

  const {draggableStyles, elementStyles} =
    useMemo(() => formatStyles(style), [style]);

  return (
    <>
      <DraggableWrapper
        resizeable
        editorId={editorId}
        isChildElem={isChildElem}
        style={{
          ...draggableStyles,
          ...draggableProps?.style,
          ...elementStyles,
          position: absolutePositioning ? 'absolute' : 'static'
        }}
      >
        <div>
          <Checkbox
            style={{
              // color needs to be above elementStyles so it can be overwritten
              color: '#000000',
              ...elementStyles,
              position: 'static'
            }}
            className={classes.checkbox}
            checkedIcon={
              <CheckBoxIcon
                className={classes.checkboxIcon}
                style={{
                  width: elementStyles?.width,
                  height: elementStyles?.height
                }}
              />
            }
            disabled
            checked
            data-editorid={editorId}
            data-asset={true}
          />
        </div>
      </DraggableWrapper>
    </>
  );
};

CheckboxDraggable.propTypes = {
  classes: PropTypes.object
};


CheckboxDraggable.serializedName = 'CheckboxDraggable'

CheckboxDraggable.editorSettings = [
  {
    type: 'prop',
    key: 'breakpoints',
    tooltip: 'Make an asset visible at the chosen breakpoints: desktop, tablet, and/or mobile.',
    default: {
      visibility: {
        desktop: true,
        tablet: true,
        mobile: true
      },
      styleLink: {
        tablet: true,
        mobile: true,
      }
    },
    label: null,
    inputType: 'breakpoint'
  },

  {
    type: 'layout',
    key: 'twoCol',
    tooltip: 'Accepted units: px, %, em, rem, vw, vh',
    label: 'Position/Size',
    elements: [
      {type: 'style', key: 'left', default: '0', label: 'X'},
      {type: 'style', key: 'top', default: '0', label: 'Y'},
      {type: 'style', key: 'width', default: '300px', label: 'W'},
      {type: 'style', key: 'height', default: '300px', label: 'H'},
    ]
  },

  {
    type: 'prop',
    key: 'absolutePositioning',
    tooltip: 'If this is checked, your asset\'s CSS will be written with "position: absolute"',
    label: 'Absolute Positioning',
    default: false,
    inputType: 'boolean'
  },

  {
    type: 'style',
    key: 'color',
    default: '#000000',
    label: 'Fill',
    inputType: 'color',
    inputOptions: {
      defaultColor: '#000000'
    }
  },

  {
      type: 'collection',
      label: 'Margin',
      children: [
        {type: 'style', key: 'marginTop', default: '0px', label: 'Top', horizontal: false},
        {type: 'style', key: 'marginBottom', default: '0px', label: 'Bottom', horizontal: false},
        {type: 'style', key: 'marginLeft', default: '0px', label: 'Left', horizontal: false},
        {type: 'style', key: 'marginRight', default: '0px', label: 'Right', horizontal: false},
      ]
  },

  {
    type: 'collection',
     label: 'Effects',
      children: [
        {type: 'style', key: 'opacity', default: '1', label: 'Opacity'},
       {type: 'style', key: 'transform', default: 'none', label: 'Rotation', inputType: 'rotation'},
    ]
  },
];

CheckboxDraggable.propTypes = {
  classes: PropTypes.object,
  style: PropTypes.object.isRequired,
  draggableProps: PropTypes.object,
  editorId: PropTypes.string.isRequired,
  isChildElem: PropTypes.object,
};

export default withStyles(CheckboxDraggableStyle)(CheckboxDraggable);
