import {diff} from "deep-object-diff";
import generateNewAssetLayerLabel from "editor/actions/assets/generateAssetLayerLabel";
import initializeElementTreeActions from "editor/elementTree/ElementTreeActions";
import {cloneDeep} from "lodash-es";
import {generateId} from "utils/utils";

const createAsset = (state, treeActions, parentElem, elementToCreate, selectAssetAfterCreation=true) => {
  // then get it's editor id
  let topMostElemEditorId = treeActions.current.getNodeEditorId(parentElem);

  // exit if the icon was dragged out of the editor
  if (!topMostElemEditorId) return;

  // find in the element tree and append the node this icon represents to the editor
  let searchResult = treeActions.current.findElementInTree(
    topMostElemEditorId,
    state.editorState.elementTree.value['editor-root']
  );

  // add isChildElem = true to elementToCreate
  let clonedElemToCreate = cloneDeep(elementToCreate);

  let originalElemName = elementToCreate?.props?.layerLabel || elementToCreate?.baseData?.layerLabel

  // generate the id of the new elem here so we can select it after it's created
  let newElemId = generateId();

  if(searchResult.id !== 'editor-root') {
    // the parent is not the editor root, so remove position absolute
    clonedElemToCreate = {
      ...elementToCreate,
      id: newElemId,
      props: {
        ...elementToCreate.props,
        isChildElem: true
      },
      draggableProps: {
        ...elementToCreate?.draggableProps,
        style: {
          ...elementToCreate?.draggableProps?.style,
          //position: 'static'
        }
      }
    }
  }
  else {
    // give position absolute to this since it's parent is the editor root
    clonedElemToCreate = {
      ...elementToCreate,
      id: newElemId,
      props: {
        ...elementToCreate.props,
        isChildElem: false
      },
      draggableProps: {
        ...elementToCreate?.draggableProps,
        style: {
          ...elementToCreate?.draggableProps?.style,
          //position: 'absolute'
        }
      }
    }
  }

  let newElemName = generateNewAssetLayerLabel(
    originalElemName,
    elementToCreate.tagName,
    0,
    state.editorState.layerLabelsInUse
  )

  clonedElemToCreate.props.layerLabel = newElemName;

  // add it to the tree to be rendered (this causes a re-render)
  let newTreeElements = treeActions.current.addToTree(
    searchResult,
    {
      ...clonedElemToCreate,
      children: []
    },
    undefined,
    state.editorState.elementTree.value['editor-root'],
    false
  );

  console.log('reducer action test', {clonedElemToCreate})

  let newElementTreeValue = {
    "editor-root": newTreeElements
  }

  let return_val = {
    ...state,
    editorState: {
      ...state.editorState,
      layerLabelsInUse: [...state.editorState.layerLabelsInUse, newElemName],
      elementTree: {
        ...state.editorState.elementTree,
        value: newElementTreeValue,
        actions: {
          ...state.editorState.elementTree.actions,
          ...initializeElementTreeActions(newElementTreeValue)
        }
      }
    }
  }

  if(selectAssetAfterCreation) {
    return_val = {
      ...return_val,
      editorState: {
        ...return_val.editorState,
        elementSelected: {
          ...return_val.editorState.elementSelected,
          value: newElemId,
          data: clonedElemToCreate
        },
        layerLabelsInUse: [...state.editorState.layerLabelsInUse, newElemName],
        elementTree: {
          ...state.editorState.elementTree,
          value: newElementTreeValue,
          actions: {
            ...state.editorState.elementTree.actions,
            ...initializeElementTreeActions(newElementTreeValue)
          }
        }
      }
    }
  }

  //let state_diff = diff(state, return_val);
  return_val.actionStack.push({
    action: 'createAsset',
    elementId: newElemId,
    layerLabel: newElemName,
    elementToCreate: clonedElemToCreate,
    parentElem: parentElem,
    selectAssetAfterCreation: selectAssetAfterCreation,
    //state_diff: state_diff
  });

  return return_val;
}

export default createAsset;