import {Button, withStyles} from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import {UserContext} from "context/UserContext";
import UploadMediaStyle from "editor/components/dialogs/MediaDraggableDialog/UploadMedia/UploadMediaStyle";
import React, {useContext, useEffect, useState} from 'react';
import { StyledDropZone } from 'react-drop-zone'
import {toast} from "react-toastify";
import {User} from "services";
import Cookies from "universal-cookie";
import {handleNetworkError} from "utils/utils";
import PropTypes from "prop-types";

const UploadMedia = props => {
  const {
    classes,
    dialogClasses,
    style,
    setImageList
  } = props;

  const [file, setFile] = useState(null);
  const [busy, setBusy] = useState(false);

  const {userData} = useContext(UserContext);

  useEffect(() => {
    if(file && !userData) {
      toast.error('Please login to use this feature.');
      return;
    }

    if (file) {
      let formData = new FormData()
      const cookies = new Cookies();
      const token = cookies.get('token');
      formData.append('img', file)

      const headers = {
        "Content-Type": "multipart/formdata",
        "Authorization": `Token ${token}`
      }
      console.log('headers', headers)

      setBusy(true)
      User.uploadMedia(formData, headers).then((response) => {
        console.log('sucess!', response)

        // update "Your Images"
        setImageList(response.data.library)
        console.log('pizza', response.data.library)
        toast.success("Successfully uploaded!")
      })
      .catch(handleNetworkError)
      .finally(() => {
        setBusy(false)
      })
    }
  },[file])

  return (
    <div className={classes.root} style={{...style}}>
      <Typography
        variant={'subtitle1'}
        className={dialogClasses.dialogContentTitle}
      >
        Upload Your Media
      </Typography>

      <div className={dialogClasses.divider}/>

      <div className={classes.dropZoneContainer}>
        <StyledDropZone
          onDrop={setFile}
          className={classes.dropZone}
          accept={"image/*"}
        >
          <Typography className={classes.dropZoneText}>
            Click or drop your file here
          </Typography>
        </StyledDropZone>
      </div>

      <Button
        color={'secondary'}
        variant={'contained'}
        className={classes.uploadButton}
        disabled={busy}
      >
        Upload
      </Button>
    </div>
  );
};

UploadMedia.propTypes = {
  classes: PropTypes.object,
  dialogClasses: PropTypes.object,
  style: PropTypes.object,
  setImageList: PropTypes.func.isRequired
};

export default withStyles(UploadMediaStyle)(UploadMedia);
