const CheckboxTOSAndPPStyle = theme => ({
  root: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    marginBottom: "20px"
  },

  text: ({error}) => ({
    color: error ? theme.palette.error.main : 'inherit',

    [theme.breakpoints.down('sm')]: {
      maxWidth: '300px'
    }
  }),

  checkboxContainer: {
    color: theme.palette.common.white,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center'
  },

  checkboxIcon: {
    width: '24px',
    height: '24px',
    color: theme.palette.common.white
  },

  checkedIcon: {
    color: theme.palette.secondary.main
  },

  pseudoLink: {
    textDecoration: 'underline',
    transition: '0.3s',
    cursor: 'pointer',

    '&:hover': {
      color: theme.palette.secondary.main
    }
  }
});

export default CheckboxTOSAndPPStyle;
