import Typography from "@material-ui/core/Typography";
import Drag_Asset from "assets/education/videos/drag_asset.mp4";
import React from 'react';
import PropTypes from 'prop-types';

const HowToAddAssetToSite = props => {
  const { classes } = props;

  return (
    <>
      <Typography>
        Assets are accessible via the tab located on the left side of the canvas. Click the 'plus' button at the top of this tab to reveal
        a menu of available assets. To place an asset onto the canvas—your site's building area—simply drag and drop it.
      </Typography>

      <video
        width="534"
        height="315"
        loop
        autoPlay
        muted
        controls={false}
      >
        <source src={Drag_Asset} type="video/mp4" />
      </video>
    </>
  );
};

HowToAddAssetToSite.propTypes = {
  classes: PropTypes.object
};

export default HowToAddAssetToSite;