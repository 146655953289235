const FormDraggableStyle = theme => ({
  formRoot: {
    display: 'flex',
    width: 'auto',
    height: 'auto',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'flex-start'
  }
});

export default FormDraggableStyle;
