import EditorDialogsProvider from "editor/context/EditorDialogsProvider";
import {EditorContext} from "editor/Editor";
import {useEditorDialog} from "hooks/useEditorDialog";
import useKeyPress from "hooks/useKeyPress";
import React, {createContext, useContext, useEffect} from 'react';
import PropTypes from "prop-types";

export const EditorHotkeysContext = createContext();

const EditorHotkeysProvider = props => {
  const {
    children
  } = props;

  const editorData = useContext(EditorContext);
  const { dispatch, editorReducerState } = editorData;
  const { editorState } = editorReducerState;
  const {copyFromTree, pasteFromClipboard} = editorReducerState.editorActions;
  const elementSelected = editorReducerState.editorState.elementSelected.value

  const ctrlKeyPressed = useKeyPress('Control');
  const spaceBarPressed = useKeyPress(' ');
  const vKeyPressed = useKeyPress('v');
  const zKeyPressed = useKeyPress('z');
  const yKeyPressed = useKeyPress('y');
  const cKeyPressed = useKeyPress('c');
  const delKeyPressed = useKeyPress('Delete');
  const shiftKeyPressed = useKeyPress('Shift');
  const confirmationDialog = useEditorDialog('confirmation');

  useEffect(() => {
    console.log('ctrl key pressed', ctrlKeyPressed)
  }, [ctrlKeyPressed]);

  // TODO: could we condense these useEffects?

  // Ctrl + V (Paste)
  useEffect(() => {
    console.log('paste test', {ctrlKeyPressed, vKeyPressed, userIdTyping: editorState.userIsTyping})

    if(ctrlKeyPressed && vKeyPressed && !editorState.userIsTyping) {
      dispatch({
        type: 'pasteClipboard'
      })
    }
  }, [ctrlKeyPressed, vKeyPressed, editorState.userIsTyping]);

  // Ctrl + C (Copy)
  useEffect(() => {
    if(ctrlKeyPressed && cKeyPressed) {
      let elemToCopy = copyFromTree(editorReducerState.editorState.elementSelected.value)

      dispatch({
        type: 'setUserClipboard',
        payload: {
          clipboard: elemToCopy
        }
      })
    }
  }, [ctrlKeyPressed, cKeyPressed])

  // Ctrl + Y (Redo)
  useEffect(() => {
    if(ctrlKeyPressed && yKeyPressed) {
      console.log('execute redo')
      dispatch({
        type: 'redo'
      })
    }
  }, [ctrlKeyPressed, yKeyPressed])

  // Ctrl + Z (Undo)
  useEffect(() => {
    if(ctrlKeyPressed && zKeyPressed) {
      console.log('execute undo')
      dispatch({
        type: 'undo'
      })
    }
  }, [ctrlKeyPressed, zKeyPressed])

  // Delete (Delete Asset)
  useEffect(() => {
    console.log('del key pressed');
    if(delKeyPressed) {
      confirmationDialog.appendDialogData({
        onSuccess: () => {
          dispatch({type: 'deleteAsset', payload: {editorId: elementSelected}});

          confirmationDialog.closeDialog();
        },
        text: 'Are you sure you want to delete this asset?'
      });

      confirmationDialog.openDialog();
    }
  }, [delKeyPressed])

  // transmit to other components so they can hook into the hotkeys
  // for example the draggableWrapper listens for ctrlKeyPressed
  const keysPressed = {
    ctrlKeyPressed,
    spaceBarPressed,
  }

  return (
    <EditorHotkeysContext.Provider value={keysPressed}>
      {children}
    </EditorHotkeysContext.Provider>
  );
};

EditorHotkeysProvider.propTypes = {
  children: PropTypes.array.isRequired
};

export default EditorHotkeysProvider;
