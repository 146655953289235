const LayoutPanelStyle = theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    overflowY: "auto",
    height: '100%',

    '&::-webkit-scrollbar': {
      height: '2px',
      width: '2px',
      position: 'relative',
      marginLeft: '9.33px',
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: 'white',
    },
    '&::-webkit-scrollbar-track': {
      backgroundColor: '#262626',
      opacity: '0.48'
    },
  },

  title: {
    marginTop: '6px',
    marginBottom: '10px',
    marginLeft: '6px',
  },

  innerContainer: {
    backgroundColor: "#181A1E",
    margin: '6px',
    padding: "6px",
    borderRadius: "5px",

    display: 'flex',
    flexDirection: 'column',
    flex: 1,

    '& > *': {
      margin: '3px'
    }
  }
});

export default LayoutPanelStyle;