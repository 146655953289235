const BorderPickerInputStyle = theme => ({
  row: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    justifyContent: 'flex-start',
    alignItems: 'center'
  },

  colorPicker: {
    position: 'relative',
    bottom: '2px',
    width: '100%',
  },

  borderTypeSelect: {
    minWidth: '80px',
    width: '100%',
  },

  input: {
    width: '100%',
  },

  borderWidth: {
    width: '100%',
    maxWidth: "55px",
    marginLeft: "8px",
  },

});

export default BorderPickerInputStyle;
