import {withStyles} from '@material-ui/core';
import ContextMenu from "components/ContextMenu/ContextMenu";
import PageContextMenuStyle from 'editor/components/contextMenus/PageContextMenu/PageContextMenuStyle';
import {EditorContext} from "editor/Editor";
import {useEditorDialog} from "hooks/useEditorDialog";
import PropTypes from 'prop-types';
import React, {useContext, useEffect, useMemo} from 'react';

const PageContextMenu = props => {
  const {
    classes,
    contextMenuCoords,
    onClose,
    selectedPage,
    lastClickedPage,
    ...rest
  } = props;

  const editorData = useContext(EditorContext);
  const {editorReducerState, dispatch} = editorData;
  const routingDialog = useEditorDialog('routing');
  const renamePageDialog = useEditorDialog('renamePage')

  const handleChangePageUrl = () => {
    routingDialog.openDialog();
    onClose()
  }

  const handleRenamePage = () => {
    renamePageDialog.appendDialogData({lastClickedPage})
    renamePageDialog.openDialog()
    onClose()
  }

  const handleDeletePage = () => {
    dispatch({
      type: 'deletePage',
      payload: {
        lastClickedPage: lastClickedPage
      }
    })
    onClose()
  }

  const contextMenuActions = useMemo(() => [
    [
      {
        title: 'Change Page URL',
        icon: null,
        action: handleChangePageUrl
      },
      {
        title: 'Rename',
        icon: null, //<FileCopyIcon/>,
        action: handleRenamePage
      },
      {
        title: 'Delete',
        icon: null,
        className: classes.deleteButton,
        action: handleDeletePage
      }
    ],
  ], [lastClickedPage])

  return (
    <ContextMenu
      actions={contextMenuActions}
      contextMenuCoords={contextMenuCoords}
      customClasses={classes}
      onClose={onClose}
    />
  );
};

PageContextMenu.propTypes = {
  classes: PropTypes.object,
  contextMenuCoords: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired,
  selectedPage: PropTypes.object,
};

export default React.memo(withStyles(PageContextMenuStyle)(PageContextMenu));
