import {purpleButton} from "assets/theme";

const RenameProjectDialogStyle = theme => ({
  root: {
    display: "flex",
    flexDirection: "column",
  },

  closeIcon: {
    color: "white",

    '&:hover':{
      background: "#3a3a4a",
      transition: "0.3s",
    },
  },

  dialog: {
    height: "85%",
    maxHeight: "200px",
    minHeight: "200px",
    minWidth: "350px",
  },

  dialogTitle: {
    width: "100%",


    '& .MuiTypography-root': {
      display: "flex",
      flexDirection: "row",
      width: "100%",
      justifyContent: "space-between",
    },
  },

  form: {
    height: "100%",
    width: "100%",
    display: "flex",
    flexDirection: "column",
  },

  purpleButton: {
    width: "100px",
    marginLeft: "auto",
    marginTop: "10px",
  },

  notchedOutline: {
    borderColor: "white",
  },

});

export default RenameProjectDialogStyle;
