import {purpleButton} from "assets/theme";

const EditorSettingsStyle = theme => ({
  root: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",
    color: "white",
    width: "100%",
    height: "55px",
    paddingLeft: "24px",
    paddingRight: "24px",
    position: "relative",
    backgroundColor: "rgba(37, 39, 50, 0.65)",
    boxShadow: "0px 1px 5px 0px rgba(0,0,0,0.45)",
    zIndex: 1000,
  },

  settingOuterContainer: {
    marginLeft: "20px",
  },

  settingContainer: {
    width: "40px",
    height: "20px",
    color: "white",
    marginLeft: "16px",
  },

  projectTitleContainer: {
    position: "absolute",
    transform: "translate(-50%, -50%)",
    left: "50%",
    top: "50%",
  },

  projectTitle: {
    justifySelf: "center",
  },

  endRow: {
    marginLeft: 'auto',
    width: '300px',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'center'
  },

  exportContainer: {
    marginRight: '20px',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },

  exportButton: {
    ...purpleButton,
    transition: "0.3s",

    [theme.breakpoints.down('1400')]: {
      '&.MuiButtonBase-root': {
        width: '0px'
      }
    },
  },

  purpleButton: {
    ...purpleButton,
    transition: "0.3s",
  },

  logo: {
    cursor: 'pointer'
  },

  exportText: {
    fontFamily: 'Roboto',
    fontWeight: "500",
  },

  exportIcon: {
    marginRight: "8px",
    position: "relative",
    bottom: "1px",

    [theme.breakpoints.down('1400')]: {
      marginRight: "0px",
    },
  },

  zoomLevelContainer: {
    marginRight: '20px',
    fontFamily: 'Roboto',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },

  zoomLevelText: {
    marginLeft: '8px',
    width: '40px'
  },

  zoomButton: {
    color: 'white',

    '&.MuiIconButton-root': {
      padding: '4px'
    }
  },

  editIconButton: {
    color: 'white',
  },

  editIcon: {
    color: 'white',
    fontSize: '18px',
    position: 'relative',
    bottom: '2px'
  }
});

export default EditorSettingsStyle;
