const replaceAsset = (state, treeActions, originalElemId, newTreeElem) => {
  const rootTreeElem = state.editorState.elementTree.value['editor-root'];
  // TODO: can we move this or change this function to get rid of this?
  const originalElem = treeActions.current.findElementInTree(originalElemId);
  const originalElemName = originalElem?.props?.layerLabel

  const currentCanvasSize = state.editorState.canvasSettings.currentCanvasBreakpoint

  let newElementTree = treeActions.current.replaceElementInTree(
    originalElemId,
    newTreeElem,
    rootTreeElem,
    currentCanvasSize
  );

  let newEditorPages = [...state.editorState.editorPages.value]

  let selectedPage = newEditorPages.find(
    page => page.labelId === state.editorState.editorPages.selectedPage
  )

  selectedPage.tree = state.editorState.elementTree.value;

  let newLayerLabelsInUse = [
    ...state.editorState.layerLabelsInUse.filter(
      label => label !== originalElemName
    ),
    newTreeElem?.props?.layerLabel
  ]

  let return_val = {
    ...state,
    editorState: {
      ...state.editorState,
      elementSelected: {
        ...state.editorState.elementSelected,
        value: newTreeElem.id,
        data: newTreeElem,
      },
      layerLabelsInUse: newLayerLabelsInUse,
      elementTree: {
        ...state.editorState.elementTree,
        value: {'editor-root': newElementTree}
      },
      editorPages: {
        ...state.editorState.editorPages,
        value: newEditorPages
      }
    }
  }

  return_val.actionStack.push({
    action: 'replaceAsset',
    element: newTreeElem,
    originalElem: originalElem,
    //state_diff: state_diff
  });

  return return_val;
}

export default replaceAsset;