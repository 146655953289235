import Typography from "@material-ui/core/Typography";
import React from 'react';

const HowToStyleOrChangeText = () => {
  return (
    <>
      <Typography style={{marginBottom: '8px'}}>
        To change what the text says, follow these steps:
      </Typography>
      <Typography>
        1. Select the text to pull up text settings on the right-hand side settings panel.
      </Typography>
      <Typography>
        2. Locate the setting that says 'Text'.
      </Typography>
      <Typography>
        3. Type in what you want the text to be.
      </Typography>
      <Typography style={{marginTop: '8px'}}>
        You can also use the text settings panel to alter the font, text style, text size, color, alignment, and more.
      </Typography>
    </>
  );
};

HowToStyleOrChangeText.propTypes = {

};

export default HowToStyleOrChangeText;