import cn from "classnames";
import {EditorContext} from "editor/Editor";
import {useEditorDialog} from "hooks/useEditorDialog";
import React, {useContext} from 'react';
import {
  Fab, IconButton,
  Tooltip,
  withStyles
} from '@material-ui/core';
import PropTypes from 'prop-types';
import {faLayerGroup, faFile, faObjectGroup, faPalette} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import SearchIcon from '@material-ui/icons/Search';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import HelpIcon from '@material-ui/icons/Help';
import ViewWeekIcon from '@material-ui/icons/ViewWeek';
import TabBarStyle from './TabBarStyle';

const TabBar = props => {
  const { classes, editorId } = props;

  const {editorReducerState, dispatch} = useContext(EditorContext);
  const {
    findElementInTree
  } = editorReducerState.editorActions;
  const helpDialog = useEditorDialog('help');

  const currentTab = editorReducerState.editorState.currentTab;

  const tabs = [
    {
      name: 'assets',
      icon: <AddCircleIcon />,
      tooltip: 'Assets',
      active: currentTab === 'assets',
    },
    {
      name: 'layouts',
      icon: <ViewWeekIcon style={{fontSize: '34px', width: '34px', height: '34px'}} />,
      tooltip: 'Layouts',
      active: currentTab === 'layouts',
    },
    {
      name: 'layers',
      icon: <FontAwesomeIcon icon={faLayerGroup} />,
      tooltip: 'Layers',
      active: currentTab === 'layers',
    },
    {
      name: 'pages',
      icon: <FontAwesomeIcon icon={faFile} style={{position: 'relative', 'left': '4px'}}/>,
      tooltip: 'Pages',
      active: currentTab === 'pages',
    },
    {
      name: 'public components',
      icon: <SearchIcon style={{fontSize: '34px', width: '34px', height: '34px'}} />,
      tooltip: 'Public Components',
      active: currentTab === 'public components',
    },
    {
      name: 'your components',
      icon: <FontAwesomeIcon icon={faObjectGroup} />,
      tooltip: 'Your Components',
      active: currentTab === 'your components',
    },
    {
      name: 'theme',
      icon: <FontAwesomeIcon icon={faPalette} />,
      tooltip: 'Theme',
      active: currentTab === 'theme',
    },
  ]

  const handleClick = (clickedTab) => {
    // dispatch here
    if(currentTab !== clickedTab) {
      dispatch({
        type: 'setCurrentTab',
        payload: {
          currentTab: clickedTab
        }
      })
    }
    else {
      dispatch({
        type: 'setCurrentTab',
        payload: {
          currentTab: null
        }
      })
    }
  }

  const handleHelpClick = () => {
    helpDialog.openDialog();
  }

  return (
    <div className={classes.root}>
      {tabs.map((data, index) => (
        <div className={classes.tab} key={index} onClick={() => handleClick(data.name)}>
          <Tooltip title={data.tooltip}>
            <div className={cn(classes.icon, data.active ? classes.selected : null)}>
              {data.icon}
            </div>
          </Tooltip>
        </div>
      ))}


      <div onClick={handleHelpClick} className={classes.icon}>
        <Tooltip title={'Need Help?'}>
          <HelpIcon style={{fontSize: '34px', width: '34px', height: '34px'}} />
        </Tooltip>
      </div>

    </div>
  );
};

TabBar.propTypes = {
  classes: PropTypes.object
};

export default withStyles(TabBarStyle)(TabBar);
