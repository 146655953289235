const PageContextMenuStyle = theme => ({
  deleteButton: {
    backgroundColor: '#E64745',

    '&:hover': {
      backgroundColor: '#AA3634'
    }
  }
});

export default PageContextMenuStyle;
