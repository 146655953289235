import ElementSettingsGroupLabel from "components/ElementSettingGroupLabel/ElementSettingsGroupLabel";
import {EditorContext} from "editor/Editor";
import React, {useContext, useState} from 'react';

import {
  Button,
  withStyles
} from '@material-ui/core';
import PropTypes from 'prop-types';

import FitToTextInputStyle from 'editor/layout/ElementSettings/Inputs/FitToTextInput/FitToTextInputStyle';

const FitToTextInput = props => {
  const {
    classes,
    onChange,
    setting,
  } = props;

  const editorData = useContext(EditorContext);
  const {editorReducerState, dispatch} = editorData;
  const elementSelectedId = editorReducerState.editorState.elementSelected.value;
  const zoomLevel = editorReducerState.editorSettings.zoomLevel;

  const handleClick = (e) => {
    //onChange(e.target.checked);
    console.log('test', props);

    let elemTextNode = document.querySelector(`[data-editorId="${elementSelectedId}"] p > span`);
    console.log('node found', elemTextNode);

    if(!elemTextNode) return;

    let bounds = elemTextNode.getBoundingClientRect();

    let resultStyles = {
      width: Math.round(((bounds.width + 2) * (1/zoomLevel)) * 10) / 10 + 'px',
      height:  Math.round(((bounds.height + 2) * (1/zoomLevel)) * 10) / 10 + 'px',
    };

    onChange(null, resultStyles)

    console.log('dispatch ended')
  }

  return (
    <div className={classes.root}>
      <ElementSettingsGroupLabel setting={setting}>
        {setting.label}
      </ElementSettingsGroupLabel>

      <div className={classes.buttonContainer}>
        <Button
          variant={'contained'}
          color={'primary'}
          onClick={handleClick}
          size={'small'}
        >
          Resize to Text
        </Button>
      </div>
    </div>
  );
};

FitToTextInput.propTypes = {
  classes: PropTypes.object
};

export default withStyles(FitToTextInputStyle)(FitToTextInput);
