import {faDiscord} from "@fortawesome/free-brands-svg-icons";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {Typography, withStyles} from "@material-ui/core";
import FacebookIcon from '@material-ui/icons/Facebook';
import InstagramIcon from '@material-ui/icons/Instagram';
import LinkedInIcon from '@material-ui/icons/LinkedIn';
import TwitterIcon from '@material-ui/icons/Twitter';
import YouTubeIcon from '@material-ui/icons/YouTube';
import ContactFooterStyle from "components/ContactFooter/ContactFooterStyle";
import NimbusLogo from "components/NimbusLogo/NimbusLogo";
import {useGlobalDialog} from "hooks/useGlobalDialog";
import PropTypes from "prop-types";
import React from 'react';
import {NavLink} from "react-router-dom";

const ContactFooter = props => {
  const {classes} = props;

  const documentDialog = useGlobalDialog('document');

  const openDocumentDialog = (fileName) => {
    documentDialog.appendDialogData({
      fileName
    });

    documentDialog.openDialog();
  }

  const nimbusLinks = [
    {
      text: 'Pricing',
      to: '/pricing'
    },
    {
      text: 'Blog',
      to: '/blog'
    }
  ];

  const supportLinks = [
    {
      text: 'Contact Us',
      to: '/contact',
    },
    {
      text: 'Submit Feedback',
      to: '/submit-feedback'
    },
    {
      text: 'Submit Bug',
      to: '/submit-bug'
    }

  ];

  const legalLinks = [
    {
      text: 'Privacy Policy',
      document: 'privacy_policy',
      to: '/',
    },
    {
      text: 'Terms of Service',
      document: 'terms_of_service',
      to: '/'
    },
  ];

  const socialLinks = [
    {
      logo: <TwitterIcon/>,
      to: 'https://twitter.com/nimbuseditor'
    },
    {
      logo: <FacebookIcon/>,
      to: 'https://www.facebook.com/profile.php?id=100074377373188',
    },
    {
      logo: <InstagramIcon/>,
      to: 'https://www.instagram.com/nimbuseditor/',
    },
    {
      logo: <FontAwesomeIcon icon={faDiscord} className={classes.discord}/>,
      to: 'https://discord.gg/bUU2awjvNS',
    },
    {
      logo: <YouTubeIcon/>,
      to: 'https://www.youtube.com/channel/UCj_5s8XkryCB5vhu3ldbZXQ',
    },
    {
      logo: <LinkedInIcon/>,
      to: 'https://www.linkedin.com/company/nimbus-editor/'
    }
  ];

  const nimbusLink = nimbusLinks.map((data, index) => (
    <NavLink
      key={index}
      className={classes.internalLinks}
      to={data.to}
    >
      {data.text}
    </NavLink>
  ))

  const supportLink = supportLinks.map((data, index) => (
    <NavLink
      key={index}
      className={classes.internalLinks}
      to={data.to}
    >
      {data.text}
    </NavLink>
  ))

  const legalLink = legalLinks.map((data, index) => (
    <div
      key={index}
      className={classes.internalLinks}
      onClick={() => openDocumentDialog(data.document)}
    >
      {data.text}
    </div>
  ))

  const socialLink = socialLinks.map((data, index) => (
    <a
      key={index}
      className={classes.socialLogo}
      href={data.to}
    >
      {data.logo}
    </a>
  ))

  return (
    <div className={classes.root}>
      <div className={classes.rowContainer}>
        <div className={classes.colContainer}>
          <Typography className={classes.title}>
            Nimbus
          </Typography>

          {nimbusLink}
        {/*  docs for education go here*/}
        </div>
        <div className={classes.colContainer}>
          <Typography className={classes.title}>
            Support
          </Typography>

          {supportLink}
        </div>
        <div className={classes.colContainer}>
          <Typography className={classes.title}>
            Legal
          </Typography>

          {legalLink}
        </div>
        <div className={classes.colContainer}>
          <div className={classes.logoContainer}>
            <NimbusLogo/>
          </div>

          <div className={classes.socialContainer}>
            {socialLink}
          </div>
        </div>
      </div>
    </div>
  );
};

ContactFooter.propTypes = {
  classes: PropTypes.object,
};

export default withStyles(ContactFooterStyle)(ContactFooter)
