import {makeStyles} from "@material-ui/core";

const negativePositionAmount = '-5px';
const handleWidth = '11px';
const handleHeight = '11px';

const ResizeableElementStyle = theme => ({
  root: props => ({
    width: '100%',
    height: '100%',
    minWidth: props.minWidth ? props.minWidth + 'px' : '10px',
    minHeight: props.minHeight ? props.minHeight + 'px' : '10px',
    //width: props.style?.width ? props.style?.width : 'auto',
    //height: props.style?.height ? props.style?.height : 'auto',
    position: 'relative',
  }),
  selected: {
    //border: `solid 3px #8A56FF`
    boxShadow: `0 0 0 3px #8A56FF`
  },
  handle: {
    height: `${handleHeight} !important`,
    width: `${handleWidth} !important`,
    borderRadius: '50%',
    background: 'white',
    border: 'solid 1px black',
    position: 'absolute',
    zIndex: '10000',
    transform: 'translate(-50%, -50%)',
  },
  handleLeft: {
    top: `calc(50% - ${handleHeight}/2)`,
    left: negativePositionAmount,

    '&:hover': {
      cursor: 'w-resize'
    }
  },
  handleRight: {
    top: `calc(50% - ${handleHeight}/2)`,
    right: negativePositionAmount,

    '&:hover': {
      cursor: 'e-resize'
    }
  },
  handleTop: {
    top: negativePositionAmount,
    // subtract half of the width
    left: 'calc(50% - 5.5px) !important',

    '&:hover': {
      cursor: 'n-resize'
    }
  },
  handleBottom: {
    bottom: negativePositionAmount,
    // subtract half of the width
    left: 'calc(50% - 5.5px) !important',

    '&:hover': {
      cursor: 's-resize'
    }
  },
  handleTopLeft: {
    top: negativePositionAmount,
    left: negativePositionAmount,

    '&:hover': {
      cursor: 'nw-resize'
    }
  },
  handleTopRight: {
    top: negativePositionAmount,
    right: negativePositionAmount,

    '&:hover': {
      cursor: 'ne-resize'
    }
  },
  handleBottomLeft: {
    bottom: negativePositionAmount,
    left: negativePositionAmount,

    '&:hover': {
      cursor: 'sw-resize'
    }
  },
  handleBottomRight: {
    bottom: negativePositionAmount,
    right: negativePositionAmount,

    '&:hover': {
      cursor: 'se-resize'
    }
  },
});

export default ResizeableElementStyle;
