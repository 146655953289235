import InformationIcon from "components/InformationIcon/InformationIcon";
import React, {Children} from 'react';

import {
  withStyles
} from '@material-ui/core';
import PropTypes from 'prop-types';

import ElementSettingsGroupLabelStyle from './ElementSettingsGroupLabelStyle';
import Typography from "@material-ui/core/Typography";

const ElementSettingsGroupLabel = props => {
  const {
    classes,
    children,
    setting,
  } = props;

  return (
    <Typography
      variant={'subtitle1'}
      align={'left'}
      className={classes.label}
    >
      {children}

      {setting?.tooltip &&
        (
          <InformationIcon
            tooltipTitle={setting?.tooltip}
          />
        )
      }
    </Typography>
  );
};

ElementSettingsGroupLabel.propTypes = {
  classes: PropTypes.object,
  children: PropTypes.string,
  setting: PropTypes.object,
};

export default withStyles(ElementSettingsGroupLabelStyle)(ElementSettingsGroupLabel);
