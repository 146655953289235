import Typography from "@material-ui/core/Typography";
import React from 'react';

const HowToStyleForm = () => {
  return (
    <>
      <Typography>
        When having a form selected, a settings panel will appear on the right-hand side of the editor. In this panel, you can customize the form to match your design.
        You can change settings on the form itself and/or any of the assets inside of the form, such as inputs or buttons.
      </Typography>
    </>
  );
};

HowToStyleForm.propTypes = {

};

export default HowToStyleForm;