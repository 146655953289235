const ColorPickerInputStyle = theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'center',
    width: '100%'
  },

  removeColor: {
    color: theme.palette.error.main,
    width: '19px',
    height: '19px',
    position: 'absolute',
    bottom: '-10px',
    right: '-10px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },

  removeColorInner: {
    background: '#1E2229',
    borderRadius: '50%',
    width: '19px',
    height: '19px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },

  removeColorIcon: {
    width: '19px',
    height: '19px',
  },

  innerContainer: {
    margin: '8px 0'
  },

  colorButtonContainer: {
    position: 'relative',
    bottom: '2px'
  },

  formControl: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    position: 'relative',
    top: '2px'
  },

  editable: {
    width: '100%',
    height: '100%',
    display: 'block'
  },

  startAdornment: {
    marginRight: '12px'
  },

  colorPickerText: {
    minWidth: '75px'
  },

  colorButton: {
    width: '29px',
    height: '29px',
    background: 'white',
    borderRadius: '5px',
    cursor: 'pointer',
    position: 'relative',
    border: 'solid 1px #111111'
  }
});

export default ColorPickerInputStyle;
