import BaseDropDownMenu from "components/BaseDropDownMenu/BaseDropDownMenu";
import {UserContext} from "context/UserContext";
import {EditorContext} from "editor/Editor";
import {useProjectSerializer} from "hooks/serializerHooks/useProjectSerializer";
import {useEditorDialog} from "hooks/useEditorDialog";
import PropTypes from 'prop-types';
import React, {useContext} from 'react';
import {useHistory, useLocation} from 'react-router-dom';
import {toast} from "react-toastify";
import {User} from "services";

const FileDropDownMenu = props => {
  const {
    classes,
    ...rest
  } = props;

  const editorData = useContext(EditorContext);
  const {userData} = useContext(UserContext);
  const {dispatch, editorReducerState} = editorData;
  const {editorActions} = editorData;
  const projectSerializer = useProjectSerializer(editorReducerState, dispatch);
  const history = useHistory();

  const renameProjectDialog = useEditorDialog('renameProject');
  const confirmationDialog = useEditorDialog('confirmation');
  const loginRegisterDialog = useEditorDialog('loginRegister');

  const saveProject = () => {
    if(userData) {
      editorActions.saveProject(projectSerializer)
    }
    else {
      loginRegisterDialog.appendDialogData({
        onSuccess: () => {
          editorActions.saveProject(projectSerializer)
        }
      })

      loginRegisterDialog.openDialog();
    }
  }

  const loadProject = () => {
    if(userData) {
      history.push('/dashboard');
    }
    else {
      loginRegisterDialog.openDialog();
    }
  }

  const newProject = () => {
    window.localStorage.removeItem('versionId');
    window.localStorage.removeItem('projectId');

    window.history.replaceState({}, document.title);
    window.location.reload(true);
  }

  const renameProject = () => {
    renameProjectDialog.openDialog()
  }

  const openConfirmation = (onSuccess, type) => {
    let dataToAppend = {onSuccess};

    if(type === 'new') {
      dataToAppend.text = 'Are you sure you want to make a new project? ' +
        'Any unsaved progress will be lost.';
    }
    else if(type === 'open') {
      dataToAppend.text = 'Are you sure you want to open a different project? ' +
        'Any unsaved progress will be lost.';
    }

    console.log('confirmation dialog', confirmationDialog)

    confirmationDialog.appendDialogData(dataToAppend);
    confirmationDialog.openDialog();
  }


  const fileMenuActions = [
    {
      title: 'New',
      action: () => openConfirmation(newProject, 'new')
    },
    {
      title: 'Open',
      action: () => openConfirmation(loadProject, 'open')
    },
    {
      title: 'Save',
      action: saveProject
    },
    {
      title: 'Rename',
      action: renameProject
    }
  ]

  return (
    <BaseDropDownMenu
      actions={fileMenuActions}
      {...rest}
    />
  );
};

FileDropDownMenu.propTypes = {
  classes: PropTypes.object
};

export default FileDropDownMenu;
