import {useEditorDialog} from "hooks/useEditorDialog";
import React, {cloneElement, useContext, useEffect, useRef, useState} from 'react';
import DraggableWrapper from 'editor/draggables/DraggableWrapper/DraggableWrapper';
import { EditorContext } from 'editor/Editor';
import {User} from "services";
import {
  generateId,
  getCSSAbsolutePosition,
  getElementsAtClientCoordinates,
  handleNetworkError,
  isFunction
} from 'utils/utils';
import {
  withStyles,
  Button, Typography, IconButton,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import DraggableToolboxAssetStyle from './DraggableToolboxAssetStyle';
import DeleteForeverSharpIcon from '@material-ui/icons/DeleteForeverSharp';
import CloseIcon from '@material-ui/icons/Close';

const DraggableToolboxAsset = props => {
  const {
    classes,
    iconNode,
    textNode,
    elementToCreate,
    isComponent,
    variantData,
    publicComponents,
    isLayout,

    onDragStart,
    onDrag,
    onDragEnd,

    testId
  } = props;

  const rootRef = useRef(null);
  const positionOnMount = useRef({
    top: 0,
    left: 0,
    right: 0,
    bottom: 0
  });

  // a node created to keep the place of the icon when dragged since the icon gets removed from page flow
  const placeholderNode = useRef(null);

  const editorData = useContext(EditorContext);
  const { dispatch, editorReducerState } = editorData;
  const editorActions = editorReducerState.editorActions;
  const treeActions = editorReducerState.editorState.elementTree.actions;
  const editorTree = editorReducerState.editorState.elementTree.elements;

  const assetPanelSelectedAsset = editorReducerState.editorState.assetPanelSelectedAsset;

  const confirmationDialog = useEditorDialog('confirmation');

  const [placeholderCreated, setPlaceholderCreated] = useState(false);

  useEffect(() => {
    //calculate current position on mount for snapping back after drag
    let node = rootRef.current;
    let boundingRect = node.getBoundingClientRect();

    positionOnMount.current = {
      top: `${boundingRect.top}px`,
      left: `${boundingRect.left}px`,
      right: `${boundingRect.right}px`,
      bottom: `${boundingRect.bottom}px`
    }

    console.log('position on mount of icon:', positionOnMount.current);


    console.log('draggable toolbox test', props)
  }, []);

  const findTopMostEditorElem = (clientX, clientY) => {
    const editorRootElem = document.getElementById('editor-root')

    let elementsInDropCoordinates = getElementsAtClientCoordinates(clientX, clientY);
    let topMostElement = null;
    for(let node of elementsInDropCoordinates) {
      if(editorRootElem.contains(node) === true) {
        let editorId = treeActions.getNodeEditorId(node);

        if(!editorId) continue;

        let treeElem = treeActions.findElementInTree(editorId);

        if(editorId === 'editor-root' || editorActions.isElementAContainer(treeElem)) {
          topMostElement = node;
          return topMostElement;
        }
      }
    }
  };

  const handleClick = (value) => {
    // to be implemented?
  };

  const snapBackToOriginalPosition = (toolboxIconNode) => {
    toolboxIconNode.style.position = 'static';
  };

  const removePlaceholder = () => {
    if(placeholderNode.current) {
      placeholderNode.current.remove()
      placeholderNode.current = null;
    }

    setPlaceholderCreated(false);
  }

  const handleStart = (eventHandler, data) => {
    // not used currently

    if(onDragStart && isFunction(onDragStart)) {
      onDragStart(eventHandler, data);
    }
  }

  const handleDrag = (eventHandler, data) => {
    // create placeholder
    if(!placeholderCreated) {
      let node = data.node;
      let {width, height} = node.getBoundingClientRect();

      let newPlaceholderNode = document.createElement('DIV');
      console.log('placeholder node', newPlaceholderNode)

      newPlaceholderNode.style.width = `${width}px`;
      newPlaceholderNode.style.height = `${height}px`;
      node.after(newPlaceholderNode);
      placeholderNode.current = newPlaceholderNode;

      setPlaceholderCreated(true)
    }

    if(onDrag && isFunction(onDrag)) {
      onDrag(eventHandler, data);
    }
  }

  const handleDrop = (eventHandler, data) => {
    // data.node is the original node of the icon
    snapBackToOriginalPosition(data.node);
    removePlaceholder()

    if(onDragEnd && isFunction(onDragEnd)) {
      onDragEnd(eventHandler, data);
    }

    // TODO: we might need to check if the topMostElem is also a container
    // so we can't insert tables into buttons etc.
    let topMostElem = findTopMostEditorElem(eventHandler.clientX, eventHandler.clientY);

    // pass the variant data but remove elementToCreate because it's huge and unnecessary
    let modifiedVariantData = {...variantData};
    delete modifiedVariantData.elementToCreate;

    console.log('modified variant data', modifiedVariantData)

    let idOfElemToCreate = generateId();
    elementToCreate.props.editorId = idOfElemToCreate;

    // top most elem will be undefined if it is not a valid target
    if(topMostElem) {
      if(isComponent) {
        dispatch({
          type: 'createComponent',
          payload: {
            parentElem: topMostElem,
            elementToCreate: {...elementToCreate, id: idOfElemToCreate},
          }
        });
      }
      else {
        dispatch({
          type: 'createAsset',
          payload: {
            parentElem: topMostElem,
            elementToCreate: {...elementToCreate, id: idOfElemToCreate}
          }
        });
      }
    }
  };

  const handleDeleteButtonClick = () => {
    confirmationDialog.appendDialogData({
      onSuccess: () => {
        handleDeleteComponent();
        confirmationDialog.closeDialog();
      },
      text: 'Are you sure you want to delete this component?',
    })
    confirmationDialog.openDialog();
  }

  const handleDeleteComponent = () => {
    if(publicComponents) {
      User.deletePublicComponent(variantData?.id)
        .then(response => {
          console.log('component deleted!', response)

          dispatch({
            type: 'setComponentCategories',
            payload: {
              savedComponentCategories: response.data
            }
          })
        })
        .catch(handleNetworkError)
    }
    else {
      User.deleteComponent(variantData?.id)
        .then(response => {
          console.log('component deleted!', response)

          dispatch({
            type: 'setComponentCategories',
            payload: {
              savedComponentCategories: response.data
            }
          })
        })
        .catch(handleNetworkError)
    }
  }

  return (
    <DraggableWrapper
      onClick={handleClick}
      onStart={handleStart}
      onDrag={handleDrag}
      onStop={handleDrop}
      canBeDraggedOutOfEditor
      doesNotRenderGridLines
      testId={testId}
      resizeable={false}
      editorDraggable={false}
      absolutePositioning
      style={{
        // the icon is being put under the canvas without this
        // since this is for the icon being dragged

        // NOTE: if this is greater than 9999 it will overlap the joyride
        zIndex: 999
      }}
    >
      <div className={classes.root} ref={rootRef}>
        <div className={classes.button}>

          <div className={classes.titleRow}>
            <Typography variant="h6" className={classes.text}>
              {textNode}
            </Typography>

            {isComponent && ((!publicComponents && !isLayout )|| (publicComponents && elementToCreate?.baseData?.ownedByUser)) && (
              <IconButton className={classes.deleteButton} onClick={handleDeleteButtonClick}>
                <CloseIcon fontSize={'small'}/>
              </IconButton>
            )}
          </div>

          <div className={classes.imageContainer}>
            <img src={iconNode} className={classes.image} draggable={false}/>
          </div>
        </div>
      </div>
    </DraggableWrapper>

  );
};

DraggableToolboxAsset.propTypes = {
  classes: PropTypes.object,
  iconNode: PropTypes.element.isRequired,
  textNode: PropTypes.element.isRequired,
  variantData: PropTypes.object.isRequired,
  onDragStart: PropTypes.func,
  onDrag: PropTypes.func,
  onDragEnd: PropTypes.func,
  elementToCreate: PropTypes.shape({
    tag: PropTypes.oneOfType([PropTypes.string, PropTypes.elementType]).isRequired,
    props: PropTypes.object.isRequired,
  }).isRequired,
  testId: PropTypes.string.isRequired
};

export default withStyles(DraggableToolboxAssetStyle)(DraggableToolboxAsset);
