const AlignmentInputStyle = theme => ({
  row: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",
    width: "100%",
    paddingLeft: "10px",
    paddingRight: "10px",
    paddingTop: "10px"
  },

  column: {
    display: "flex",
    flexDirection: "column",
    width: "100%"
  },

  button: {
    width: "20px",
    height: "20px",

    '&.MuiButton-root': {
      border: "1px solid white",
      borderRadius: 0,
      margin: "4px",
      minWidth: 0,
    },
  },

  gridContainer: {
    display: "flex",
    flexWrap: "wrap",
    width: "84px",
    height: "84px"
  },

  flexDirectionContainer: {
    display: "flex",
    flex: 1,
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center"
  },

  icon: {
    color: "white",
    fontSize: "36px"
  },

  selected: {
    '& $icon': {
      color: theme.palette.secondary.main,
    }
  },

  selectedAlignment: {
    background: theme.palette.secondary.main,

    '&:hover': {
      background: theme.palette.secondary.main,
    }
  }
});

export default AlignmentInputStyle;
