import {withStyles} from '@material-ui/core';
import ContactFooter from "components/ContactFooter/ContactFooter";
import CookieBar from "components/CookieBar/CookieBar";
import NavBar from "components/NavBar/NavBar";
import MainPageLayoutStyle from 'layout/MainPageLayout/MainPageLayoutStyle';
import PropTypes from 'prop-types';
import React, {useState} from 'react';

const MainPageLayout = props => {
  const {
    classes,
    children,
  } = props;

  const [open, setOpen] = useState(true)

  return (
    <div className={classes.root}>
      <NavBar/>
      <div className={classes.contentRoot}>
        {children}
      </div>
      <ContactFooter/>
      {/*<CookieBar*/}
      {/*  open={open}*/}
      {/*  setOpen={setOpen}*/}
      {/*/>*/}
    </div>
  );
};

MainPageLayout.propTypes = {
  classes: PropTypes.object,
  children: PropTypes.element.isRequired
};

export default withStyles(MainPageLayoutStyle)(MainPageLayout);
